import React, { useMemo } from "react";
import { styled } from '@mui/system';

import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import { Button } from '@mui/material';
import {
    Link,
    Grid2,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import RTT from './RTT';
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing)

const NoGroupFound = () => {
    return <Grid2 container spacing={6}>
        <Grid2 size={12}>
            <Typography variant="h6">
                No Group found!
            </Typography>
        </Grid2>
        <Grid2 size={12}>
            <NavLink
                to="/settings/devices"
                style={{ textDecoration: "none" }}
            >
                <Button variant="contained" color="primary">
                    Create Group
                </Button>
            </NavLink>
        </Grid2>
    </Grid2>
}

const NoSettingsFound = () => {
    return <Grid2 container spacing={6}>
        <Grid2 size={12}>
            <Typography variant="h6">
                No Setting found!
            </Typography>
        </Grid2>
        <Grid2 size={12}>
            <NavLink
                to="/settings/devices"
                style={{ textDecoration: "none" }}
            >
                <Button variant="contained" color="primary">
                    Create  or select Indoor Location Settings
                </Button>
            </NavLink>
        </Grid2>
    </Grid2>
}

function RTTGroups() {
    const selectedGroupID = useSelector((state) => state.groups.selectedGroup);
    const groups = useSelector((state) => state.groups.groups || []).sort((a, b) => b.devices.length - a.devices.length);
    const settings = useSelector((state) => state.settings);
    const selectedGroup = useMemo(() => {
        if (!selectedGroupID) return;
        const _selectedGroup = groups.find(el => el._id === selectedGroupID);
        if (!_selectedGroup) return;
        const rTTSetting = settings.find(el => el._id === _selectedGroup.rTTSetting);
        return { ..._selectedGroup, rtt: rTTSetting };
    }, [groups, settings, selectedGroupID]);
    return (
        <React.Fragment>
            <Typography variant="h3" gutterBottom display="inline">
                Indoor Tracking
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" sx={{ mt: 2 }}>
                <Link component={NavLink} to="/">
                    Dashboard
                </Link>
                <Typography>Positioning</Typography>
                <Typography>Indoor</Typography>
            </Breadcrumbs>
            <Divider sx={{ my: 6 }} />
            {groups.length === 0 && <NoGroupFound />}
            {!selectedGroupID && <Typography variant="h5">Please select a group</Typography>}
            {selectedGroupID && !selectedGroup && <Typography variant="h5">Selected Group not found</Typography>}
            {selectedGroup && !selectedGroup.rtt && <NoSettingsFound />}
            {selectedGroup && selectedGroup.rtt && <RTT groupRTT={selectedGroup} />}
        </React.Fragment>
    );
}

export default RTTGroups;
