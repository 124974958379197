import dayjs from 'dayjs';
import dayjsRelativeTimePlugin from 'dayjs/plugin/relativeTime';
import dayjsminMaxPlugin from 'dayjs/plugin/minMax';
import dayjsdurationPlugin from 'dayjs/plugin/duration'
const dayjsExt = dayjs;

dayjs.extend(dayjsRelativeTimePlugin);
dayjs.extend(dayjsminMaxPlugin);
dayjs.extend(dayjsdurationPlugin);

export default dayjsExt;
