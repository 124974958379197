import React from "react";
import { Outlet } from "react-router-dom";
import { styled } from '@mui/system';
import { CssBaseline, Box } from "@mui/material";

const Root = styled(Box)`
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background: ${(props) => props.theme.palette.background.default};
  color:  ${(props) => props.theme.palette.text.primary};
`;

const Auth = () => {
  return (
    <Root>
      <CssBaseline />
      <Outlet />
    </Root>
  );
};

export default Auth;
