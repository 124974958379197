import { useMemo, useState } from "react";
import { 
    DataGridPro, 
    useGridApiRef, 
    gridClasses,
    GridToolbar
} from '@mui/x-data-grid-pro';
import { styled } from '@mui/system';
import { spacing } from "@mui/system";
import {
    Card as MuiCard,
    CardContent,
    ClickAwayListener,
    Box,
    Typography,
    Tooltip
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux"
import { toast } from 'react-toastify';
import { rgba } from 'polished';
import EditIcon from '@mui/icons-material/Edit';
import { updateGroupAction } from 'redux/groups/groups.actions';
import { http } from 'utils/http';
import useAuthorization from "hooks/useAuthorization";
import Authorized from "components/Authorized";
import settingType from "constants/settingType";

const HighlightSelectedItemDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.selected`]: {
        backgroundColor: rgba(theme.palette.secondary.main, 0.5),
        '&:hover, &.Mui-hovered': {
            backgroundColor: rgba(theme.palette.secondary.main, 0.3),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: rgba(theme.palette.secondary.main, 0.7),
            '&:hover, &.Mui-hovered': {
                backgroundColor: rgba(theme.palette.secondary.main, 0.8),
                '@media (hover: none)': {
                    backgroundColor: rgba(theme.palette.secondary.main, 0.8),
                },
            },
        },
    },
}));

const settingCellvalueFormatter = (value, row, column, apiRef) => {
    const field = column.field;
    const colDef = apiRef.current.getColumn(field);
    const option = colDef.valueOptions.find(
        ({ value: optionValue }) => value === optionValue
    );
    return option ? option.label : '* deleted Setting'
}

const EditableCell = ({ text }) => {
    return <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    }}>
        <Tooltip title={text || '-'}>
            <Typography noWrap>
                {text || '-'}
            </Typography>
        </Tooltip>
        <Authorized permission="group.update">
            <EditIcon color="primary" fontSize="small" sx={{ pl: 1 }} />
        </Authorized>
    </Box>
}

const Card = styled(MuiCard)(spacing);
const GroupSettingTable = () => {
    const selectedGroupID = useSelector((state) => state.groups.selectedGroup || null);
    const { isAuthorized } = useAuthorization();
    const canUpdateGroup = isAuthorized("group.update");
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false);
    const groups = useSelector((state) => state.groups.groups || []).sort((a, b) => b.devices.length - a.devices.length);
    const settings = useSelector((state) => state.settings || []);
    const settingsByType = useMemo(() => {
        const _settingsByType = {};
        settings.forEach(setting => {
            if (!_settingsByType[setting.type]) _settingsByType[setting.type] = [];
            _settingsByType[setting.type].push(setting);
        });
        return _settingsByType;
    }, [settings]);
    const settingsMap = useMemo(() => {
        return settings.reduce((a, c) => ({ ...a, [c._id]: c }), {})
    }, [settings]);

    const groupSetting = useMemo(() => {
        return groups.map(el => ({
            _id: el._id,
            name: el.name,
            nDevices: el.devices.length,
            scanSetting: el.scanSetting || '',
            keyboardSetting: el.keyboardSetting || '',
            geofencing: el.geofencingSetting || '',
            geofencingSetting: el.geofencingSetting || '',
            rTTSetting: el.rTTSetting || '',
            alertsSetting: el.alertsSetting || '',
            badgeSetting: el.badgeSetting || '',
            tunnelSetting: el.tunnelSetting || ''
        }))
    }, [groups]);
    const apiRef = useGridApiRef();
    const handleClickAway = () => {
        if (apiRef.current && apiRef.current.setRowSelectionModel) {
            apiRef.current.setRowSelectionModel([])
        }
    }

    const updateSettings = async (groupID, updates) => {
        setFetching(true);
        try {
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/group/group`,
                {
                    groupID,
                    ...updates,
                });
            if (resp && resp.data) {

                dispatch(updateGroupAction(resp.data));
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || "Something went wrong");
        }
        setFetching(false);
    }
    
    const columns = [
        {
            field: "_id",
            headerName: "REAL_ID",
            width: 160,
            editable: false,
            align: 'center'
        },
        {
            field: "name",
            headerName: "Name",
            width: 150,
            editable: canUpdateGroup,
            renderCell: (params) => <EditableCell text={params.value} />
        },
        {
            field: "nDevices",
            headerName: "# of Devices",
            width: 130,
            editable: false,
            align: 'center'
        },
        {
            field: "scanSetting",
            headerName: "Scan",
            width: 150,
            editable: canUpdateGroup,
            type: 'singleSelect',
            renderCell: (params) => <EditableCell text={settingsMap[params.value]?.name} />,
            valueFormatter: settingCellvalueFormatter,
            valueOptions: settingsByType[settingType.SCAN] ? [{ value: '', label: '-' }, ...settingsByType[settingType.SCAN].map(el => ({ value: el._id || '', label: el.name || '' }))] : [{ value: '', label: '-' }]
        },
        {
            field: "alertsSetting",
            headerName: "Alerts",
            width: 150,
            editable: canUpdateGroup,
            type: 'singleSelect',
            renderCell: (params) => <EditableCell text={settingsMap[params.value]?.name} />,
            valueFormatter: settingCellvalueFormatter,
            valueOptions: settingsByType[settingType.ALERTS] ? [{ value: '', label: '-' }, ...settingsByType[settingType.ALERTS].map(el => ({ value: el._id || '', label: el.name || '' }))] : [{ value: '', label: '-' }]
        },
        {
            field: "keyboardSetting",
            headerName: "Keyboard",
            width: 150,
            editable: canUpdateGroup,
            type: 'singleSelect',
            renderCell: (params) => <EditableCell text={settingsMap[params.value]?.name} />,
            valueFormatter: settingCellvalueFormatter,
            valueOptions: settingsByType[settingType.KEYBOARD] ? [{ value: '', label: '-' }, ...settingsByType[settingType.KEYBOARD].map(el => ({ value: el._id || '', label: el.name || '' }))] : [{ value: '', label: '-' }]
        }
        ,
        {
            field: "geofencingSetting",
            headerName: "GPS",
            width: 150,
            editable: canUpdateGroup,
            type: 'singleSelect',
            renderCell: (params) => <EditableCell text={settingsMap[params.value]?.name} />,
            valueFormatter: settingCellvalueFormatter,
            valueOptions: settingsByType[settingType.GEOFENCING] ? [{ value: '', label: '-' }, ...settingsByType[settingType.GEOFENCING].map(el => ({ value: el._id || '', label: el.name || '' }))] : [{ value: '', label: '-' }]
        }
        ,
        {
            field: "rTTSetting",
            headerName: "RTT / BLE",
            width: 150,
            editable: canUpdateGroup,
            align: 'center',
            type: 'singleSelect',
            renderCell: (params) => <EditableCell text={settingsMap[params.value]?.name} />,
            valueFormatter: settingCellvalueFormatter,
            valueOptions: settingsByType[settingType.RTT] ? [{ value: '', label: '-' }, ...settingsByType[settingType.RTT].map(el => ({ value: el._id || '', label: el.name || '' }))] : [{ value: '', label: '-' }]
        }
        ,
        {
            field: "badgeSetting",
            headerName: "Badge",
            width: 150,
            editable: canUpdateGroup,
            align: 'center',
            type: 'singleSelect',
            renderCell: (params) => <EditableCell text={settingsMap[params.value]?.name} />,
            valueFormatter: settingCellvalueFormatter,
            valueOptions: settingsByType[settingType.BADGE] ? [{ value: '', label: '-' }, ...settingsByType[settingType.BADGE].map(el => ({ value: el._id || '', label: el.name || '' }))] : [{ value: '', label: '-' }]
        },
        {
            field: "tunnelSetting",
            headerName: "Tunnel",
            width: 150,
            editable: canUpdateGroup,
            align: 'center',
            type: 'singleSelect',
            renderCell: (params) => <EditableCell text={settingsMap[params.value]?.name} />,
            valueFormatter: settingCellvalueFormatter,
            valueOptions: settingsByType[settingType.TUNNEL] ? [{ value: '', label: '-' }, ...settingsByType[settingType.TUNNEL].map(el => ({ value: el._id || '', label: el.name || '' }))] : [{ value: '', label: '-' }]
        }
    ];

    const handleOnCellEditStop = (params) => {
        const groupID = params.id;
        const field = params.field;
        const oldValue = params.row[field]
        const updates = {};
        setTimeout(() => {
            const newValue = apiRef.current.getCellValue(groupID, field);
            if(newValue === oldValue) return;
            const value = field === 'name' ? (newValue || '-') : settings.find(el => el._id === newValue)?._id;
            updates[field] = "string" === typeof value && value.length > 0 ? value : null;
            updateSettings(groupID, updates);    
        }, 50)
    }

    return (
        <Card sx={{ mb: 6 }}>
            <CardContent>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div style={{ height: 350, width: "100%" }}>
                        <HighlightSelectedItemDataGridPro
                            initialState={{
                                density: "compact",
                                sorting: {
                                    sortModel: [{ field: '_id', sort: 'desc' }],
                                },
                                columns:{
                                    columnVisibilityModel:{
                                        _id: false,
                                    }
                                }
                            }}
                            apiRef={apiRef}
                            rows={groupSetting}
                            getRowId={(row) => row._id}
                            columns={columns}
                            loading={fetching}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            disableColumnSelector
                            disableColumnMenu
                            disableColumnFilter
                            getRowClassName={(params) => params.id === selectedGroupID ? 'selected' : ''}
                            onCellEditStop={handleOnCellEditStop}
                        />
                    </div>
                </ClickAwayListener>
            </CardContent>
        </Card>
    );
}

export default GroupSettingTable;