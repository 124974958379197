import React, { useMemo } from 'react';
import { styled } from '@mui/system';
import { rgba } from 'polished';
import { orange, red, blue, pink, purple, grey } from '@mui/material/colors';
import {
    Box,
    Typography,
    Grid2,
    Card,
    CardContent,
    Badge,
} from '@mui/material';
import BatteryLevel from 'components/BatteryLevel';
import TemperatureLevel from 'components/TemperatureLevel';
import Router from '@mui/icons-material/Router';
import HtmlTooltip from 'components/HtmlTooltip';
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';

const CENTER_POINTER_SIZE = 8;
const ICON_SIZE = 40;
const WarningContainer = styled(Grid2)`
    position: absolute;
    padding: 0;
    margin: 0;   
    z-index: 1003;
    cursor: pointer;
    width: auto;
`;
const PointerWrapper = styled(Box)`
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    position: absolute;
    padding: 0;
    margin: 0;
`
const AccessPointCenter = styled(Box)`
    position: absolute;
    padding: 0;
    margin: 0;   
    z-index: 1003;
    cursor: pointer;
    width: ${CENTER_POINTER_SIZE}px;
    height: ${CENTER_POINTER_SIZE}px;
    border-radius: ${CENTER_POINTER_SIZE}px;
    background-color: white;
    border: 1px solid black;
`
const AccessPointIcon = styled(Router)`
    position: absolute;
    padding: 0;
    margin: 0;   
    z-index: 1002;
    cursor: pointer;
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
`;
const Indicator = styled(Badge)`
    position: absolute;
    z-index: 1004;
    padding: 0;
    margin: 0;   
    user-select: none;
    height: ${ICON_SIZE}px;
    min-width: ${ICON_SIZE}px;
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
    padding: 0;
    margin: 0;
  }
`;
const APInfos = ({ mac, temperature, type, batteryLevel }) => {
    return <Grid2 container spacing={2} justifyContent="space-around">
        <Grid2 size={12}>
            <Typography variant="body1" align="center">
                MAC: <strong>{mac}</strong>
            </Typography>
        </Grid2>
        {Number.isFinite(temperature) && <Grid2 size={4} container direction="column" alignItems="center" justifyContent="center">
            <Grid2>
                <TemperatureLevel level={temperature} />
            </Grid2>
            <Grid2>
                <Typography variant="body2">
                    {temperature || '?'}°C
                </Typography>
            </Grid2>
        </Grid2>}
        {type && <Grid2 size={4} container direction="column" alignItems="center" justifyContent="center">
            <Grid2>
                {type === "RTT" ? <WifiIcon color="primary" /> : <BluetoothIcon color="primary" />}
            </Grid2>
            <Grid2>
                <Typography variant="body2">
                    {type}
                </Typography>
            </Grid2>
        </Grid2>}
        {Number.isFinite(batteryLevel) && <Grid2 size={4} container direction="column" alignItems="center" justifyContent="center">
            <Grid2>
                <BatteryLevel level={batteryLevel} />
            </Grid2>
            <Grid2>
                <Typography variant="body2">
                    {batteryLevel || '?'}%
                </Typography>
            </Grid2>
        </Grid2>}
    </Grid2>

}

const AccessPoints = ({ x, y, aps, mac }) => {
    const { batteryLevel, temperature } = useMemo(() => {
        let _batteryLevel = 100;
        let _temperature = 37;
        aps.forEach(el => {
            _batteryLevel = Number.isFinite(el.battery_percentage_left) ? Math.min(_batteryLevel, el.battery_percentage_left) : _batteryLevel;
            if (Number.isFinite(el.temperature) && (el.temperature <= 10 || el.temperature >= 45)) _temperature = el.temperature;
        });
        return { batteryLevel: _batteryLevel, temperature: _temperature }
    }, [aps])

    const fillColor = useMemo(() => {
        return getColorFromMac(mac)
    }, [mac]);
    const apIgnored = useMemo(() => aps.every(el => el.ignored), [aps]);
    return <div>
        <HtmlTooltip maxWidth={250} backgroundColor='transparent' border='0px solid transparent' title={<div>
            <Card variant="outlined" sx={{ boxShadow: '0 3px 14px rgba(0,0,0,0.4)' }}>
                <CardContent>
                    {aps.length === 1 && <APInfos
                        mac={aps[0].mac}
                        batteryLevel={aps[0].battery_percentage_left}
                        type={aps[0].type}
                        temperature={aps[0].temperature}
                    />}
                    {aps.length > 1 && <Grid2 container>
                        {aps.map(ap => <Grid2 size={12} key={ap.mac}
                            sx={{ border: '1px solid #00000022', mt: 2, p: 2, borderRadius: 2 }}>
                            <APInfos
                                mac={ap.mac}
                                batteryLevel={ap.battery_percentage_left}
                                type={ap.type}
                                temperature={ap.temperature}
                            />
                        </Grid2>)}
                    </Grid2>}
                </CardContent>
            </Card>
        </div>}>
            <PointerWrapper sx={{
                top: y - ICON_SIZE / 2,
                left: x - ICON_SIZE / 2,
            }}>
                <AccessPointIcon style={{ color: fillColor, opacity: apIgnored ? 0.1 : 1 }} />
                {aps.length > 1 && <Indicator badgeContent={aps.length} max={9} />}
                <AccessPointCenter
                    style={{
                        top: ICON_SIZE / 2 - CENTER_POINTER_SIZE / 2,
                        left: ICON_SIZE / 2 - CENTER_POINTER_SIZE / 2,
                        backgroundColor: rgba(fillColor, 0.1),
                    }}
                />
                <WarningContainer container justifyContent="space-between" sx={{
                    top: ICON_SIZE / 2,
                    left: 0,
                }}>
                    {Number.isFinite(temperature) && (temperature <= 10 || temperature >= 45) && <Grid2 size={4} container direction="column" alignItems="center" justifyContent="center">
                        <Grid2>
                            <TemperatureLevel level={temperature} />
                        </Grid2>
                        <Grid2>
                            <Typography variant="body2">
                                {temperature || '?'}°C
                            </Typography>
                        </Grid2>
                    </Grid2>}
                    {Number.isFinite(batteryLevel) && batteryLevel < 20 && <Grid2 size={4} container direction="column" alignItems="center" justifyContent="center">
                        <Grid2>
                            <BatteryLevel level={batteryLevel} />
                        </Grid2>
                        <Grid2>
                            <Typography variant="body2">
                                {batteryLevel || '?'}%
                            </Typography>
                        </Grid2>
                    </Grid2>}
                </WarningContainer>
            </PointerWrapper>
        </HtmlTooltip>
    </div>
}




const DIVIDER = 40;
const round = (val) => {
    // for now
    return Math.round(Math.round(val / DIVIDER) * DIVIDER);
}
const colors = [blue[500], orange[500], red[500], grey[500], pink[500], purple[500], blue[200], orange[200], red[200], grey[200], pink[200], purple[200]];
const getColorFromMac = (mac) => {
    if (!mac) return '#000000';
    let i = 0;
    `${mac}`.split('').forEach((el) => {
        i += el.charCodeAt(0);
    })
    return colors[i % colors.length];
}

const AccessPointGroup = ({ apInfos, accessPointsInfosMap, disableGrouping }) => {
    const apMap = {};
    apInfos.forEach(el => {
        const gx = disableGrouping ? el.x : round(el.x);
        const gy = disableGrouping ? el.y : round(el.y);
        if (!apMap[`${gx}-${gy}`]) apMap[`${gx}-${gy}`] = {
            x: el.x,
            y: el.y,
            mac: el.mac,
            aps: [],
        }
        apMap[`${gx}-${gy}`].aps.push({
            ...el,
            ...(accessPointsInfosMap[el.mac] || {})
        });
    });
    return Object.values(apMap).map((group, index) => <AccessPoints key={index} mac={group.mac} x={group.x} y={group.y} aps={group.aps} accessPointsInfosMap={accessPointsInfosMap} />)
}
export default AccessPointGroup;
