import merge from "deepmerge";
import { green, grey, red } from "@mui/material/colors";
import THEMES from "constants/themes";
///#e53935
const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: green[800],
      contrastText: "#FFF",
    },
    secondary: {
      main: green[500],
      contrastText: "#FFF",
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.95)",
      secondary: "rgba(0, 0, 0, 0.5)",
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: green[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: "#FFF",
    background: green[700],
    header: {
      color: "#FFF",
      background: green[800],
      brand: {
        color: "#FFFFFF",
        logoTextTop: '#000',
        logoTextBottom: '#45bc5a'
      }
    },
    footer: {
      color: "#FFF",
      background: green[800],
      online: {
        background: green[300],
      },
      offline: {
        background: red[600],
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
};

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: "dark",
    primary: {
      main: green[800],
      contrastText: "#FFF",
    },
    secondary: {
      main: green[500],
      contrastText: "#FFF",
    },
    background: {
      default: "#1B2635",
      paper: "#233044",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
    },
  },
  header: {
    color: grey[300],
    background: "#1B2635",
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: "#233044",
  },
  sidebar: {
    color: "#FFF",
    background: "#233044",
    header: {
      color: "#FFF",
      background: "#18212e",
      brand: {
        color: "#FFFFFF",
        logoTextTop: '#FFFFFF',
        logoTextBottom: '#45bc5a'
      }
    },
    footer: {
      color: "#FFF",
      background: "#18212e",
      online: {
        background: green[300],
      },
      offline: {
        background: red[600],
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const lightVariant = merge(defaultVariant, {
  name: THEMES.LIGHT,
  palette: {
    mode: "light",
  },
  header: {
    color: grey[200],
    background: green[800],
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: grey[900],
    background: "#FFF",
    header: {
      color: "#FFF",
      background: green[800],
      brand: {
        color: "#FFFFFF",
        logoTextTop: '#FFF',
        logoTextBottom: '#45bc5a'
      }
    },
    footer: {
      color: grey[800],
      background: "#F7F7F7",
      online: {
        background: green[500],
      },
      offline: {
        background: red[600],
      },
    },
  },
});

const blueVariant = merge(defaultVariant, {
  name: THEMES.BLUE,
  palette: {
    mode: "light",
    primary: {
      main: "#022975",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#022975",
      contrastText: "#FFF",
    },
    text: {
      primary: "rgba(120, 120, 120, 0.95)",
      secondary: "rgba(77, 77, 77, 0.5)",
    },
  },
  header: {
    color: "#022975",
    background: "#FFF",
    search: {
      color: "#022975",
    },
    indicator: {
      background: "#ffbd08",
    },
  },
  sidebar: {
    color: "#FFF",
    background: "#022975",
    header: {
      color: "#FFF",
      background: "#011d54",
      brand: {
        color: "#FFFFFF",
        logoTextTop: '#FFFFFF',
        logoTextBottom: '#45bc5a'
      }
    },
    footer: {
      color: "#FFF",
      background: "#022975",
      online: {
        background: green[300],
      },
      offline: {
        background: red[600],
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const variants = [defaultVariant, darkVariant, lightVariant, blueVariant];

export default variants;
