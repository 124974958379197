import React, { useState, useEffect } from "react";
import { styled } from '@mui/system';
import {
  Paper,
  Typography,
  Alert,
  Button
} from "@mui/material";
import { Link } from "react-router-dom";
import Logo from 'components/Logo';
import { useParams } from "react-router-dom";
import Loader from 'components/Loader';
const Brand = styled(Logo)`
  width: 300px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
function VerifyEmail() {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    setMessage("");
    setError("");
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/verify-my-email`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
      .then(res => res.json())
      .then(res => {
        setLoading(false);
        if ("string" === typeof res?.message) {
          setMessage(res.message);
        }
        if ("string" === typeof res?.error) {
          setError(res.error);
        }
      })
      .catch(error => {
        setLoading(false);
        setError("Something went wrong");
      });
  }, [token])

  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Email Verification
        </Typography>
        {error && <Alert severity="error">
          {error}
        </Alert>}
        {message && <Alert severity="info">
          {message}
        </Alert>}
        {loading && <Loader />}
        <Button
          sx={{ mt: 2 }}
          component={Link}
          variant="outlined"
          to="/"
          fullWidth
          color="primary"
        >
          Return Home
        </Button>
      </Wrapper>
    </React.Fragment>
  );
}

export default VerifyEmail;
