import * as React from "react";
import { styled } from '@mui/system';
import {
  Grid2,
  List,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
  Box
} from "@mui/material";

const Wrapper = styled(Box)`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Grid2 container spacing={0}>
        <Grid2
          sx={{ display: { xs: "none", md: "block" } }}
          container
          size={{ xs: 12, md: 6 }}
        >
          <List>
            <ListItemButton component="a" href="https://www.georgiasoftworks.net/support_gsw/">
              <ListItemText primary="Support" />
            </ListItemButton>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Help Center" />
            </ListItemButton>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Privacy" />
            </ListItemButton>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Terms of Service" />
            </ListItemButton>
          </List>
        </Grid2>
        <Grid2 container size={{ xs: 12, md: 6 }} justifyContent="flex-end">
          <List>
            <ListItemButton>
              <ListItemText
                primary={`© ${new Date().getFullYear()} - Georgia SoftWorks`}
              />
            </ListItemButton>
          </List>
        </Grid2>
      </Grid2>
    </Wrapper>
  );
}

export default Footer;
