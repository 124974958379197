const codes = {
  KEY_CODE: 1000,
  KEY_OUTPUT: 1001,
  KEYBOARD_SET: 1100,
  TE_KEYBOARD_EVENT: 1400,
  TE_SCAN_EVENT: 1401,
  WEB_KEYBOARD_EVENT: 1402,
  TE_SCAN_TRACKED: 1410,
  WEB_SCAN_TRACKED: 1411,
  HOST_LAUNCHED: 1200,
  TE_CONNECTION: 1201,
  CONNECTION_TO_BACKEND_LOST: 1202,
  WEBPAGE_LOADED: 1300,
  WEBPAGE_BLOCKED: 1301,
  TOP_URL_BLOCKED: 1302,
  SCREEN_RECOGNIZED: 1500,
  HIGH_PRIORITY_MESSAGE_RESPONSE: 1600,
  BATT_LEVEL: 2000,
  WIFI_LEVEL: 2100,
  DEVICE_DROP: 2101,
  APP_START: 3000,
  APP_STOPPED: 3001,
  ACT_RESUMED: 3002,
  ACT_DESTROYED: 3003,
  DUP_LICENSE_REM: 4000,
  GSW_SCANNED: 5000,

  LICENSE_COUNT: 100000,
  LICENSE_OBTAINED: 100001,
  LICENSE_RELEASED: 100002,
  LADS_INSTANCE_ID: 100101,
  DEVICE_VARS_CHANGED: 100100,

  DEVICE_BI_SETT: 200000,
  RELEASE_LICENSE: 200100,
  MESSAGE_DEVICE: 200200,
  ADV_BATTERY: 202000,
  UTS_EVENT: 400000,
  UTS_AGENT_LOG: 400001,
  // List of Commands (Specifically Post to Devices)

  COMM_DETAILED_BATT: 10000,
  COMM_DEVICE_BI_SETT: 10001,
  COMM_DEVICE_NOTIFICATION: 10002,
  COMM_RELEASE_LICENSE: 10003,
  COMM_DEVICE_PING: 10004,
  COMM_LADS_PORT_IP: 10005,
  COMM_HIGH_PRIORITY_MESSAGE: 10006,

  // commands to lads
  COMM_LADS_RELEASE_LICENSE: 20000,
  COMM_LADS_SET_REGISTRY_KEY_VALUE: 40000,
  COMM_LADS_RESTART_BI_THREAD: 20001,
  COMM_LADS_GET_REGISTRY_KEY_VALUE: 40001,
  COMM_LADS_GET_AVAILABLE_CONFIG: 50000,
  COMM_LADS_GET_DIRECTORY_CONTENT: 50001,
  // additional custom events
  DEVICE_DELETE_EVENT: 200001,
  DEVICE_RENAME_EVENT: 200002,
  DEVICE_GEOFENCE_CROSSING: 200003,
  SCAN_SUMMARY: 200004,
  ERROR_POSTING_EVENT: 200006,
  UPDATE_LADS_IP_PORT: 200007,
  DEVICE_PING: 200008,
  DEVICE_RELEASE_LICENSE: 200009,
  DEVICES_MERGE: 200011,
  LICENSE_COUNT_IP_HOSTNAME: 200012,
  USER_BADGE_SCAN: 200013,
  USER_BADGE_KEYBOARD: 200014,
  USER_BADGE_UNASSIGN: 200015,
  USER_BADGE_PASSWORD_SCAN: 200016,
  USER_BADGE_PASSWORD_KEYBOARD: 200017,
}

export default codes;