import React from "react"
import { styled } from '@mui/system';
import { toast } from "react-toastify"
import {
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import usePreferences from "hooks/usePreferences";

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

function ResetUserPreferences() {
    const { clearPreferences } = usePreferences();
    async function handleResetPrefrences() {
        clearPreferences();
        toast.success("Default preferences restored!");
    }

    return (
        <Card sx={{ mb: 6 }}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Rest Perferences (table filters, table sorting...)
                </Typography>
                <Button variant="contained" color="primary" onClick={handleResetPrefrences}>
                    Reset Perferences
                </Button>
            </CardContent>
        </Card >
    );
}
export default ResetUserPreferences;