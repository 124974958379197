import React, { useEffect } from 'react';
import {
    Typography,
    CardMedia,
    Box,
    Button,
    Tooltip,
    IconButton
} from '@mui/material';
import { styled } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';

const Wrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;


const ProductHeader = ({ id, name, images, description, selectedProduct, setSelectedProduct, isDefault, disabled, price, unit, currency, interval }) => {
    useEffect(() => {
        if (isDefault && setSelectedProduct) setSelectedProduct({
            productID: id,
            interval
        });
    }, [id, setSelectedProduct, isDefault, interval])
    return <Box>
        <Wrapper>
            <CardMedia
                component="img"
                alt={name}
                height="80"
                image={images[0] || "/favicon.png"}
                sx={{ width: 80, backgroundColor: "whitesmoke" }}
            />
            <Typography variant="h5" component="h5" sx={{ mt: 1 }}>
                {name}
            </Typography>
            <Typography sx={{ p: 2 }} variant="body2" component="h6">
                {description}
            </Typography>
            <Typography variant="h3" component="h3" color="primary" sx={{ mt: 1, fontWeight: 900 }}>
                {currency} {price.unit_amount / 100}
            </Typography>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h6" component="h6">
                {unit}
            </Typography>
            <Button
                color="secondary"
                variant={selectedProduct && selectedProduct.productID === id ? "contained" : "outlined"}
                onClick={() => setSelectedProduct ? setSelectedProduct({
                    productID: id,
                    interval
                }) : null}
                disabled={disabled}
            >
                Select
            </Button>
            <Tooltip title={
                <Typography sx={{ p: 2 }} variant="body2" component="h6">
                    {description}
                </Typography>
            }>
                <IconButton>
                    <InfoIcon color={selectedProduct === id ? "primary" : ""} />
                </IconButton>
            </Tooltip>
        </Wrapper>
    </Box>
}
export default ProductHeader;