import axios from "axios"
import { getAuthToken } from "utils/dataStorage"

const baseURL = process.env.REACT_APP_SERVER_URL
const http = axios.create();

http.interceptors.request.use(function (config) {
    // always append bearer token
    const authToken = getAuthToken()
    if (!config.headers.authorization && authToken) {
        config.headers.authorization = "Bearer " + authToken
    }
    return config
})

const imgURL = baseURL + "/public"

export { http, baseURL, imgURL }
