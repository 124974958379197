import React, { useEffect, useState, useMemo } from 'react';
import { styled } from '@mui/system';
import {
    Card as MuiCard,
    CardHeader,
    CardContent,
    Grid2,
    Typography,
    Switch,
    Box,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from "@mui/material";
import moment from 'moment';
import { toast } from "react-toastify";
import { spacing } from "@mui/system";
import useAuth from "hooks/useAuth";
import { formatTimeDate } from 'utils/timeFormat';
const periods = [
    { value: 'DAILY', name: "Daily" },
    { value: 'WEEKLY', name: "Weekly" },
    { value: 'BIWEEKLY', name: "Biweekly" },
    { value: 'MONTHLY', name: "Monthly" }
]

const Card = styled(MuiCard)(spacing);
const SummaryReportSettings = () => {
    const [summaryReportEnabled, setSummaryReportEnabled] = useState(false);
    const [summaryReportLastReport, setSummaryReportLastReport] = useState(null);
    const [summaryReportPeriod, setSummaryReportPeriod] = useState(null);
    const [fetching, setFetching] = useState(false);
    const {
        userID,
        userSummaryReportEnabled,
        userSummaryReportLastReport,
        userSummaryReportPeriod,
        updateUser
    } = useAuth();
    useEffect(() => {
        if (userID) {
            if (userSummaryReportEnabled) setSummaryReportEnabled(true);
            if (userSummaryReportLastReport) setSummaryReportLastReport(userSummaryReportLastReport);
            if (userSummaryReportPeriod) setSummaryReportPeriod(userSummaryReportPeriod);
        }
    }, [userID, userSummaryReportEnabled, userSummaryReportLastReport, userSummaryReportPeriod]);
    const summaryReportNextReport = useMemo(() => {
        if (!summaryReportPeriod) return null;
        if (!periods.find(el => el.value === summaryReportPeriod)) return null;
        const start = summaryReportLastReport ? moment(summaryReportLastReport) : moment().subtract(2, 'month');
        switch (summaryReportPeriod) {
            case 'DAILY':
                return moment.max(moment(), start.add(1, 'day')).format("YYYY-MM-DD ddd");
            case 'WEEKLY':
                return moment.max(moment().add(6, 'days').startOf('isoWeek'), start.add(1, 'week').startOf('isoWeek')).format("YYYY-MM-DD ddd");
            case 'BIWEEKLY':
                return moment.max(moment().add(6, 'days').startOf('isoWeek'), start.add(2, 'week').startOf('isoWeek')).format("YYYY-MM-DD ddd");
            case 'MONTHLY':
                return moment.max(moment().startOf('month').add(6, 'days').startOf('isoWeek'), start.add(1, 'month').startOf('month').add(6, 'days').startOf('isoWeek')).format("YYYY-MM-DD ddd");
            default:
                return start.format("YYYY-MM-DD ddd")
        }
    }, [summaryReportLastReport, summaryReportPeriod]);
    async function handleUpdateSummarySettings(
        {
            summaryReportEnabled,
            summaryReportPeriod,
        }
    ) {
        try {
            const userUpdateData = {
                summaryReportEnabled,
                summaryReportPeriod,
            }
            const results = await updateUser(userUpdateData);
            if (results && results.message) {
                toast.success(results.message);
            }
            setFetching(false);
        } catch (err) {
            setFetching(false);
            if (err && err.message) {
                toast.error(err.message);
            }
            else toast.error("Could not update settings");
        }
    }
    const handleSummaryReportEnabledUpdate = (e) => {
        setSummaryReportEnabled(e.target.checked)
        handleUpdateSummarySettings({
            summaryReportEnabled: e.target.checked,
            summaryReportPeriod: summaryReportPeriod,
        });
    }
    const handleSummaryReportPeriodUpdate = (e) => {
        setSummaryReportPeriod(e.target.value);
        handleUpdateSummarySettings({
            summaryReportEnabled: summaryReportEnabled,
            summaryReportPeriod: e.target.value,
        });
    }


    return <Card sx={{ mb: 6 }}>
        <CardHeader title={
            <Typography variant="h6">
                Summary Report Settings
            </Typography>
        } />
        <CardContent>
            <Grid2 container spacing={6}>
                <Box sx={{ mx: 8, width: 320 }}>
                    <Grid2 container alignItems="center" justifyContent="space-between">
                        <Grid2>
                            <Typography variant="body1">
                                Enable
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <Switch disabled={fetching || !userID} onChange={handleSummaryReportEnabledUpdate} checked={summaryReportEnabled} color="primary" />
                        </Grid2>
                    </Grid2>
                    <Grid2 container alignItems="center" justifyContent="space-between">
                        <Grid2>
                            <Typography variant="body1">
                                SummaryReportPeriod
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <FormControl>
                                <InputLabel id="period-select-label">Period</InputLabel>
                                <Select
                                    labelId="period-select-label"
                                    value={summaryReportPeriod || '-'}
                                    label="Period"
                                    onChange={handleSummaryReportPeriodUpdate}
                                    size="small"
                                    sx={{ width: 140 }}
                                    disabled={fetching || !userID || !summaryReportEnabled}
                                >
                                    <MenuItem value='-' disabled>Select Period</MenuItem>
                                    {periods.map(period => <MenuItem key={period.value} value={period.value}>{period.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid2>
                    </Grid2>
                    {summaryReportLastReport && <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
                        <Grid2>
                            <Typography variant="body1">
                                Last Report
                            </Typography>
                        </Grid2>
                        <Grid2>
                            {formatTimeDate(summaryReportLastReport)}
                        </Grid2>
                    </Grid2>}
                    {summaryReportNextReport && <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
                        <Grid2>
                            <Typography variant="body1">
                                Next Report
                            </Typography>
                        </Grid2>
                        <Grid2>
                            {summaryReportNextReport}
                        </Grid2>
                    </Grid2>}
                </Box>
            </Grid2>
        </CardContent>
    </Card >
}
export default SummaryReportSettings;