import React, {
    useState,
    useEffect
} from 'react';
import { styled } from '@mui/system';
import { NavLink } from 'react-router-dom';
import {
    Grid2,
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Box,
    Typography,
    Switch
} from '@mui/material';
import { spacing } from '@mui/system';
import Loader from 'components/Loader';
import { getAllEventsCount } from 'utils/api';
import EventLogByDate from './EventLogByDate';
import EventLogPagination from './EventLogPagination';
import HtmlTooltip from 'components/HtmlTooltip';
import InfoIcon from '@mui/icons-material/Info';
import Authorized from 'components/Authorized';

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Header = styled(Box)`
  padding: ${(props) => props.theme.spacing(6)} 0;
`;

const PageContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
`;
const EventLog = () => {
    const [byDateMode, setByDateMode] = useState(true);
    const [eventsCount, setEventsCount] = useState(null);
    useEffect(() => {
        const fetchCall = getAllEventsCount()
            .then(res => {
                if (res && Number.isInteger(res.count)) setEventsCount(res.count)
            });
        return () => {
            fetchCall.cancel();
        }
    }, [])
    return <React.Fragment>
        <Typography variant="h3" gutterBottom display="inline">
            Event Log
        </Typography>
        <Breadcrumbs aria-label="Breadcrumb" sx={{ mt: 2 }}>
            <Link component={NavLink} to="/">
                Logs
            </Link>
            <Typography>Event Log</Typography>
        </Breadcrumbs>
        <Divider sx={{ my: 6 }} />
        <Header>
            <Typography variant="h3" gutterBottom align="center">
                Complete Event Log of all Business Intelligence Data from Devices
                &amp; LADS
            </Typography>
        </Header>
        <Grid2 container spacing={6}>
            <Grid2 size={12} container alignItems="center">
                <Grid2>
                    <Authorized permission="event.read">
                        <HtmlTooltip title={byDateMode ? (
                            <Box>
                                <Typography variant="h6">Date/Group Filters are <strong>Enabled</strong></Typography>
                                <Typography variant="body1">
                                    Only the events in the selected range/group loaded. In this mode you can sort, search and use filters in the table.
                                </Typography>
                                <Typography variant="body2">
                                    *You can disable the Date Filter to switch to "all events view mode".
                                </Typography>
                            </Box>) : (
                            <Box>
                                <Typography variant="h6">Date/Group Filters are <strong>Disabled</strong></Typography>
                                <Typography variant="body1">
                                    All Events are loaded, Filters Sorting are disabled in this mode
                                </Typography>
                                <Typography variant="body2">
                                    *You can Enable the Date Filter to switch the Date/Group filters and the Table Filtering/Sorting features.
                                </Typography>
                            </Box>
                        )
                        }>
                            <InfoIcon color="primary" />
                        </HtmlTooltip>
                    </Authorized>
                </Grid2>
                <Grid2>
                    <Authorized permission="event.read">
                        <Typography variant="body1">
                            Enable Filters
                        </Typography>
                    </Authorized>
                </Grid2>
                <Grid2>
                    <Authorized permission="event.read">
                        <Switch checked={byDateMode} onChange={(e) => setByDateMode(e.target.checked)} color="primary" />
                    </Authorized>
                </Grid2>
            </Grid2>
            <Grid2 size={12}>
                <Authorized permission="event.read">
                    {!Number.isInteger(eventsCount) && !byDateMode ? <PageContainer>
                        <Box>
                            <Loader />
                        </Box>
                    </PageContainer> : null}
                    {byDateMode && <EventLogByDate />}
                    {/* > 300000 scroll bar does not behave correctly */}
                    {!byDateMode && Number.isInteger(eventsCount) && <EventLogPagination eventsCount={eventsCount} />}
                </Authorized>
            </Grid2>
        </Grid2>
    </React.Fragment >
}

export default EventLog;
