import SettingsActionTypes from "./settings.types";
const initialState = [];


const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SettingsActionTypes.UPDATE_SETTINGS:
            return action.payload;
        case SettingsActionTypes.NEW_SETTING:
            return [action.payload, ...state];
        case SettingsActionTypes.REMOVE_SETTING:
            return state.filter(setting => setting._id !== action.payload);
        case SettingsActionTypes.UPDATE_SETTING:
            const _settingsIndex = state.findIndex(setting => setting._id === action.payload._id);
            if (_settingsIndex < 0) return state;
            const _settings = [...state]
            _settings.splice(_settingsIndex, 1, action.payload);
            return _settings;
        default:
            return state;
    }
}

export default settingsReducer;
