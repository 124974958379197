import React, { useState } from "react";
import { http } from "utils/http";
import { toast } from "react-toastify";
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';

const Tunnel = () => {
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setLoading(true);
        http.get(`${process.env.REACT_APP_SERVER_URL}/api/v2/setup/tunnel/`,
            { responseType: 'blob' }
        )
            .then(res => {
                setLoading(false);
                const link = document.createElement("a");
                link.download = 'Tunnel.zip';
                link.href = window.URL.createObjectURL(res.data);
                link.dataset.downloadurl = ["zip", link.download, link.href].join(":");
                const evt = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                });
                link.dispatchEvent(evt);
                link.remove()
            })
            .catch(error => {
                setLoading(false);
                if(error?.response?.status === 403) return toast.error("not authorized");
                if("function" === typeof error?.response?.data?.text) {
                    error.response.data.text().then(results => {
                        const resp = JSON.parse(results);
                        toast.error(resp?.message || "Something went wrong");        
                    })
                    .catch(error => {
                        console.error("error", error);
                        toast.error("Something went wrong");        
                    });
                    return;
                }
                toast.error("Something went wrong");
            })
    };
    return (
        <LoadingButton
            size="normal"
            onClick={handleClick}
            fullWidth
            loading={loading}
            loadingPosition="end"
            variant="contained"
            endIcon={<DownloadIcon />}
        >
            SSH Tunnel
        </LoadingButton>
    );
};

export default Tunnel;
