import React, {
    useMemo,
    useState
} from 'react';
import {
    Grid2,
    Typography,
    Card,
    CardHeader,
    CardContent,
    Tooltip,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    IconButton,
    Switch,
    TextField,
    Divider
} from '@mui/material';
import { useSelector } from "react-redux"
import alertEventsTypes from 'constants/alertEventsTypes';
import operations from 'constants/operations';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WarningIcon from '@mui/icons-material/Warning';
import BoltIcon from '@mui/icons-material/Bolt';

const AlertEdit = ({ _id, disabled, severity, eventID, property, operation, value, name, description, androidID, dashboardEnabled, emailEnabled, chatEnabled, dashboardMessage, emailMessage, chatMessage, highPriority, handleUpdate, handleDelete, isNew, textFlags }) => {
    const [deleting, setDeleting] = useState(false);
    const devices = useSelector((state) => state.devices || []);
    const selectedAlertEvent = useMemo(() => alertEventsTypes.find(el => el.id === eventID), [eventID]);
    const selectedProperty = useMemo(() => {
        if (!selectedAlertEvent) return null;
        if (!selectedAlertEvent.properties) return null;
        if (!selectedAlertEvent.properties.length) return null;
        return selectedAlertEvent.properties.find(el => el.name === property);
    }, [selectedAlertEvent, property]);
    const error = useMemo(() => {
        if (!eventID) return "You must select an Event";
        if (selectedAlertEvent && selectedAlertEvent.properties && selectedAlertEvent.properties.length && !property) return "You must select a Property";
        if (selectedProperty && selectedProperty.operations && selectedProperty.operations.length) {
            if (value === null || value === undefined) return "You must define a value";
            if (selectedProperty.type === 'text' && !value.length) return "You must define a value";
            if (selectedProperty.type === 'number' && Number.isNaN(parseFloat(value))) return "You must define a valid value";
        }
        if (dashboardEnabled && !dashboardMessage) return "You must Enter a message for Dashboard Messages";
        if (emailEnabled && !emailMessage) return "You must Enter a message for Email Messages";
        if (chatEnabled && !chatMessage) return "You must Enter a message for Chat Messages";
        if (disabled) return "Alerts are disabled";
        if (!disabled && !emailEnabled && !dashboardEnabled && !chatEnabled) return "All notifications for this event are disabled!";
        return ""
    }, [eventID, property, disabled, value, dashboardEnabled, emailEnabled, chatEnabled, dashboardMessage, emailMessage, chatMessage, selectedProperty, selectedAlertEvent])
    const { insistent, big_text, big_text_uses_html } = useMemo(() => {
        const _textFlags = Array.isArray(textFlags) ? textFlags : [];
        const _insistent = _textFlags.includes('insistent');
        const _big_text = _textFlags.includes('big_text');
        const _big_text_uses_html = _textFlags.includes('big_text_uses_html');
        return { insistent: _insistent, big_text: _big_text, big_text_uses_html: _big_text_uses_html }
    }, [textFlags]);
    const handleTextFlagsUpdate = (flag) => {
        const flagName = Object.keys(flag)[0];
        if (!flagName) return;
        let _textFlags = Array.isArray(textFlags) ? textFlags : [];
        if (flag[flagName]) _textFlags.push(flagName);
        else _textFlags = _textFlags.filter(el => el !== flagName);
        _textFlags = _textFlags.filter(el => ["insistent", "big_text", "big_text_uses_html"].includes(el));
        handleUpdate({ textFlags: _textFlags });
    }
    return (
        <Card variant="outlined">
            <CardHeader title={
                <Grid2 container>
                    <Grid2 size={1}>
                        {error && <Tooltip title={error}><WarningIcon color="warning" /></Tooltip>}
                    </Grid2>
                    <Grid2 size={11}>
                        <Typography variant="h6" gutterBottom>
                            {highPriority && <Tooltip title="High Priority"><BoltIcon color="error" /></Tooltip>}  {name}
                        </Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        <Typography variant="body2" gutterBottom>
                            <strong>Description: </strong>{description}
                        </Typography>
                    </Grid2>
                </Grid2>
            }
            />
            <CardContent>
                <Grid2 container spacing={4} alignItems="center">
                    <Grid2 size={12}>
                        <TextField
                            type="text"
                            name="name"
                            label="Name"
                            value={name || ''}
                            onChange={(e) => handleUpdate({ name: e.target.value })}
                            fullWidth
                            size="small"
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <TextField
                            type="text"
                            name="description"
                            label="Description"
                            value={description || ''}
                            onChange={(e) => handleUpdate({ description: e.target.value })}
                            fullWidth
                            size="small"
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <FormControl sx={{ minWidth: 200 }}>
                            <InputLabel id="severity-select-label">Severity</InputLabel>
                            <Select
                                labelId="severity-select-label"
                                id="severity-select"
                                value={severity || ''}
                                label="Severity"
                                size="small"
                                onChange={(e) => handleUpdate({ severity: e.target.value })}
                            >
                                {['CRITICAL', 'HIGH', 'MEDIUM', 'LOW', 'INFO'].map(el => <MenuItem id={el} key={el} value={el}>{el}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid2>

                    <Grid2 size={12}>
                        <Divider />
                    </Grid2>
                    <Grid2 container spacing={4}>
                        <Grid2>
                            <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel id="event-select-label">Event</InputLabel>
                                <Select
                                    disabled={!isNew && !!eventID}
                                    labelId="event-select-label"
                                    id="event-select"
                                    value={eventID || '-'}
                                    label="Event"
                                    size="small"
                                    onChange={(e) => handleUpdate({ eventID: e.target.value })}
                                >
                                    <MenuItem id="disabled" value={'-'} disabled>Select an Event</MenuItem>
                                    {alertEventsTypes.map(event => <MenuItem id={event.id} key={event.id} value={event.id}>{event.name || event.messageID}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid2>
                        {selectedAlertEvent && selectedAlertEvent.properties && selectedAlertEvent.properties.length > 0 && <Grid2>
                            <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel id="property-select-label">Property</InputLabel>
                                <Select
                                    disabled={!isNew && !!property}
                                    labelId="property-select-label"
                                    id="property-select"
                                    value={property || '-'}
                                    label="Property"
                                    size="small"
                                    onChange={(e) => handleUpdate({ property: e.target.value })}
                                >
                                    <MenuItem id="disabled" value={'-'} disabled>Select an Property</MenuItem>
                                    {selectedAlertEvent.properties.map(el => <MenuItem id={el.name} key={el.name} value={el.name}>{el.description || el.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid2>}
                        {selectedProperty && <React.Fragment>
                            <Grid2>
                                <FormControl sx={{ minWidth: 200 }}>
                                    <InputLabel id="operation-select-label">{selectedProperty.description || "Operation"}</InputLabel>
                                    <Select
                                        labelId="operation-select-label"
                                        id="operation-select"
                                        value={operation || '-'}
                                        size="small"
                                        label={selectedProperty.description || "Operation"}
                                        onChange={(e) => handleUpdate({ operation: e.target.value })}
                                    >
                                        <MenuItem id="disabled" value={'-'} disabled>Select an Operation</MenuItem>
                                        {selectedProperty.operations.map(op => <MenuItem key={op} value={op}>{operations[op] || op}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid2>
                            <Grid2 flexGrow="1">
                                {selectedProperty.type === 'boolean' ? (<FormControl sx={{ minWidth: 200 }}>
                                    <InputLabel id="value-select-label">Value</InputLabel>
                                    <Select
                                        labelId="value-select-label"
                                        id="value-select"
                                        value={value || 0}
                                        size="small"
                                        label="value"
                                        onChange={(e) => handleUpdate({ value: e.target.value })}
                                    >
                                        <MenuItem value={0}>False</MenuItem>
                                        <MenuItem value={1}>True</MenuItem>
                                    </Select>
                                </FormControl>)
                                    :
                                    (<TextField
                                        type={selectedProperty.type}
                                        name="value"
                                        label="Value"
                                        value={value || ''}
                                        fullWidth={selectedProperty.type === 'text'}
                                        sx={{ minWidth: 200 }}
                                        onChange={(e) => handleUpdate({ value: e.target.value })}
                                        size="small"
                                    />)}
                            </Grid2>
                        </React.Fragment>}
                    </Grid2>
                    <Grid2>
                        <FormControl sx={{ minWidth: 200 }}>
                            <InputLabel id="device-select-label">Device</InputLabel>
                            <Select
                                labelId="device-select-label"
                                id="value-select"
                                value={androidID || '-'}
                                size="small"
                                label="Device"
                                onChange={(e) => handleUpdate({ androidID: e.target.value === '-' ? '' : e.target.value })}
                            >
                                <MenuItem value='-'>ALL</MenuItem>
                                {devices.map(device => <MenuItem key={device.androidID} value={device.androidID}>{device.name || device.androidID}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid2>
                    <Grid2 size={12}>
                        <Divider />
                    </Grid2>
                    {selectedAlertEvent && selectedAlertEvent.canDashboard && <React.Fragment>
                        <Grid2 size={12} container justifyContent="space-between" alignItems="center">
                            <Grid2>
                                <Typography variant="body1">
                                    Dashboard Notification
                                </Typography>
                            </Grid2>
                            <Grid2>
                                <Switch checked={dashboardEnabled || false} onChange={(e) => handleUpdate({ dashboardEnabled: e.target.checked })} />
                            </Grid2>
                        </Grid2>
                        <Grid2 size={12}>
                            <TextField
                                type="text"
                                name="dahsboardMessage"
                                label="Dashboard Notification Message"
                                value={dashboardMessage || ''}
                                onChange={(e) => handleUpdate({ dashboardMessage: e.target.value })}
                                fullWidth
                                size="small"
                                disabled={!dashboardEnabled}
                            />
                        </Grid2>
                    </React.Fragment>}
                    {selectedAlertEvent && selectedAlertEvent.canEmail && <React.Fragment>
                        <Grid2 size={12} container justifyContent="space-between" alignItems="center">
                            <Grid2>
                                <Typography variant="body1">
                                    Admin Email
                                </Typography>
                            </Grid2>
                            <Grid2>
                                <Switch checked={emailEnabled || false} onChange={(e) => handleUpdate({ emailEnabled: e.target.checked })} />
                            </Grid2>
                        </Grid2>
                        <Grid2 size={12}>
                            <TextField
                                type="text"
                                name="adminEmail"
                                label="Admin Email Message"
                                value={emailMessage || ''}
                                onChange={(e) => handleUpdate({ emailMessage: e.target.value })}
                                fullWidth
                                size="small"
                                disabled={!emailEnabled}
                            />
                        </Grid2>
                    </React.Fragment>}
                    {selectedAlertEvent && selectedAlertEvent.canChat && <React.Fragment>
                        <Grid2 size={12} container justifyContent="space-between" alignItems="center">
                            <Grid2>
                                <Typography variant="body1">
                                    Device Message
                                </Typography>
                            </Grid2>
                            <Grid2>
                                <Switch checked={chatEnabled || false} onChange={(e) => handleUpdate({ chatEnabled: e.target.checked })} />
                            </Grid2>
                        </Grid2>
                        <Grid2 size={12}>
                            <TextField
                                type="text"
                                name="deviceMessage"
                                label="Device Message"
                                value={chatMessage || ''}
                                onChange={(e) => handleUpdate({ chatMessage: e.target.value })}
                                fullWidth
                                size="small"
                                disabled={!chatEnabled}
                            />
                        </Grid2>
                    </React.Fragment>}
                    <Grid2 size={12} container spacing={2} alignItems="center">
                        <Grid2>
                            <Switch color="error" checked={disabled || false} onChange={(e) => handleUpdate({ disabled: e.target.checked })} />
                        </Grid2>
                        <Grid2>
                            <Typography variant="body1">
                                Disable Alerts For this Event
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <Switch color="error" checked={highPriority || false} onChange={(e) => handleUpdate({ highPriority: e.target.checked })} />
                        </Grid2>
                        <Grid2>
                            <Typography variant="body1">
                                High Priority
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <Switch color="primary" checked={insistent || false} onChange={(e) => handleTextFlagsUpdate({ insistent: e.target.checked })} />
                        </Grid2>
                        <Grid2>
                            <Typography variant="body1">
                                Insistent
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <Switch color="primary" checked={big_text || false} onChange={(e) => handleTextFlagsUpdate({ big_text: e.target.checked })} />
                        </Grid2>
                        <Grid2>
                            <Typography variant="body1">
                                Big text
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <Switch color="primary" checked={big_text_uses_html || false} onChange={(e) => handleTextFlagsUpdate({ big_text_uses_html: e.target.checked })} />
                        </Grid2>
                        <Grid2>
                            <Typography variant="body1">
                                is HTML
                            </Typography>
                        </Grid2>
                        <Grid2 flexGrow="1" />
                        <Grid2>
                            {!deleting && <Tooltip title="Delete Alert">
                                <IconButton color="error" onClick={() => setDeleting(true)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>}
                            {deleting && <Tooltip title="Delete">
                                <IconButton color="error" onClick={() => handleDelete(_id)}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Tooltip>}
                            {deleting && <Tooltip title="Cancel">
                                <IconButton color="primary" onClick={() => setDeleting(false)}>
                                    <HighlightOffIcon />
                                </IconButton>
                            </Tooltip>}
                        </Grid2>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}
export default AlertEdit