import BatteryAlertOutlined from '@mui/icons-material/BatteryAlertOutlined';
import Battery20Outlined from '@mui/icons-material/Battery20Outlined';
import Battery30Outlined from '@mui/icons-material/Battery30Outlined';
import Battery50Outlined from '@mui/icons-material/Battery50Outlined';
import Battery60Outlined from '@mui/icons-material/Battery60Outlined';
import Battery90Outlined from '@mui/icons-material/Battery90Outlined';
import BatteryFullOutlined from '@mui/icons-material/BatteryFullOutlined';
import BatteryUnknownOutlined from '@mui/icons-material/BatteryUnknownOutlined';

const BatteryLevel = (props) => {
    const { level } = props;
    const levels = [
        BatteryAlertOutlined,//0
        Battery20Outlined,//1
        Battery20Outlined,//2
        Battery30Outlined,//3
        Battery30Outlined,//4
        Battery50Outlined,//5
        Battery60Outlined,//6
        Battery60Outlined,//7
        Battery60Outlined,//8
        Battery90Outlined,//9
        BatteryFullOutlined,//10
    ];
    const BatteryIcon = levels[Math.ceil(level / 10)] || BatteryUnknownOutlined;
    return <BatteryIcon color={Number.isFinite(level) ? (level < 20 ? "error": "primary"): ''} {...props} />;
}
export default BatteryLevel;