import moment from 'moment';
import AlertsActionTypes from "./alerts.types";

const initialState = {
    allAlerts: [],
    activeAlerts: [],
    alertEventsPeriodCount: {},
    alertEventsPeriodGroupCount: {},
    alertEventsPeriodBadgeCount: {},  
    last24hDevicesAlertsCount: {},
    alertsBySettingID: {}
}

const alertsReducer = (state = initialState, action) => {
    let alertEventsPeriodCount;
    let alertEventsPeriodGroupCount;
    let alertEventsPeriodBadgeCount;  
    let allAlerts;
    let activeAlerts;
    let alertsBySettingID;
    switch (action.type) {
        case AlertsActionTypes.NEW_ALERT: {
            alertEventsPeriodCount = { ...state.alertEventsPeriodCount };
            Object.keys(alertEventsPeriodCount).forEach(key => {
                const today = moment().format('YYYY-MM-DD');
                if (`${key}`.endsWith(`_${today}`)) {
                    alertEventsPeriodCount[key]++;
                }
            });

            alertEventsPeriodGroupCount = { ...state.alertEventsPeriodGroupCount};
            Object.keys(alertEventsPeriodGroupCount).forEach(key => {
                const today = moment().format('YYYY-MM-DD');
                if (`${key}`.endsWith(`_${today}`)) {
                        if(!action.payload.groupID) return;
                        if (!alertEventsPeriodGroupCount[key][action.payload.groupID]) {
                            alertEventsPeriodGroupCount[key][action.payload.groupID] = 0;
                        }
                        alertEventsPeriodGroupCount[key][action.payload.groupID]++;
                }
            });
            alertEventsPeriodBadgeCount = { ...state.alertEventsPeriodBadgeCount};
            Object.keys(alertEventsPeriodBadgeCount).forEach(key => {
                const today = moment().format('YYYY-MM-DD');
                if (`${key}`.endsWith(`_${today}`)) {
                        if(!action.payload.badge_id) return;
                        if (!alertEventsPeriodBadgeCount[key][action.payload.badge_id]) {
                            alertEventsPeriodBadgeCount[key][action.payload.badge_id] = 0;
                        }
                        alertEventsPeriodBadgeCount[key][action.payload.badge_id]++;
                }
            });

            allAlerts = [...state.allAlerts];
            if (!allAlerts.find(alert => alert.alertID === action.payload.alertID)) {
                allAlerts = [...allAlerts, action.payload];
            }
            activeAlerts = [...state.activeAlerts];
            if (action.payload.active) {
                activeAlerts = activeAlerts.filter(alert => alert.alertID !== action.payload.alertID)
                activeAlerts = [action.payload, ...activeAlerts];
            }
            alertsBySettingID = { ...state.alertsBySettingID };
            if (!alertsBySettingID[action.payload.alertID.replace(/-.*/, "")]) alertsBySettingID[action.payload.alertID.replace(/-.*/, "")] = { curr: 0, prev: 0 }
            alertsBySettingID[action.payload.alertID.replace(/-.*/, "")].curr++;
            return {
                ...state,
                alertEventsPeriodCount,
                alertEventsPeriodGroupCount,
                alertEventsPeriodBadgeCount,
                allAlerts,
                activeAlerts,
                last24hDevicesAlertsCount: {
                    ...state.last24hDevicesAlertsCount,
                    [action.payload.androidID]:
                        state.last24hDevicesAlertsCount[action.payload.androidID] ?
                            state.last24hDevicesAlertsCount[action.payload.androidID] + 1 : 1
                },
                alertsBySettingID
            }
        }
        case AlertsActionTypes.REMOVE_ALERT: {
            return {
                ...state,
                allAlerts: state.allAlerts.filter(alert => alert.alertID !== action.payload),
                activeAlerts: state.activeAlerts.filter(alert => alert.alertID !== action.payload),
            }
        }
        case AlertsActionTypes.UPDATE_ACTIVE_ALERTS: {
            return { ...state, activeAlerts: action.payload }
        }
        case AlertsActionTypes.UPDATE_ALERT_PERIOD_COUNT_EVENTS:
            alertEventsPeriodCount = { ...state.alertEventsPeriodCount };
            alertEventsPeriodGroupCount = {...state.alertEventsPeriodGroupCount};
            alertEventsPeriodBadgeCount = {...state.alertEventsPeriodBadgeCount};
            alertEventsPeriodCount[`${action.payload.periode.start}_${action.payload.periode.end}`] = action.payload.nEvents;
            if(action.payload.group){
                if(!alertEventsPeriodGroupCount[`${action.payload.periode.start}_${action.payload.periode.end}`]) alertEventsPeriodGroupCount[`${action.payload.periode.start}_${action.payload.periode.end}`] = {};
                alertEventsPeriodGroupCount[`${action.payload.periode.start}_${action.payload.periode.end}`][action.payload.group] = action.payload.nEvents;
            }
            if(action.payload.badge){
                if(!alertEventsPeriodBadgeCount[`${action.payload.periode.start}_${action.payload.periode.end}`]) alertEventsPeriodBadgeCount[`${action.payload.periode.start}_${action.payload.periode.end}`] = {};
                alertEventsPeriodBadgeCount[`${action.payload.periode.start}_${action.payload.periode.end}`][action.payload.badge] = action.payload.nEvents;
            }
            return { ...state, alertEventsPeriodCount, alertEventsPeriodGroupCount, alertEventsPeriodBadgeCount }
        case AlertsActionTypes.UPDATE_LAST_24H_DEVICE_ALERT_COUNT:
            return { ...state, last24hDevicesAlertsCount: { ...state.last24hDevicesAlertsCount, [action.payload.androidID]: action.payload.count } }
        case AlertsActionTypes.UPDATE_CURRENT_ALERTS_BY_SETTINGS_ID:
            alertsBySettingID = { ...state.alertsBySettingID };
            if (!alertsBySettingID[action.payload.settingID]) alertsBySettingID[action.payload.settingID] = { curr: 0, prev: 0 };
            alertsBySettingID[action.payload.settingID].curr = action.payload.count;
            return { ...state, alertsBySettingID };
        case AlertsActionTypes.UPDATE_PREVIOUS_ALERTS_BY_SETTINGS_ID:
            alertsBySettingID = { ...state.alertsBySettingID };
            if (!alertsBySettingID[action.payload.settingID]) alertsBySettingID[action.payload.settingID] = { curr: 0, prev: 0 };
            alertsBySettingID[action.payload.settingID].prev = action.payload.count;
            return { ...state, alertsBySettingID };
        default:
            return state;
    }
}

export default alertsReducer;
