import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useMemo, memo } from 'react';

const Wrapper = styled(Box)`
    border: 1px solid ${props => props.$isInvalidValue ? props.theme.palette.warning.light : props.theme.palette.divider};
    position: relative;
    overflow: hidden;
    width: calc(100% - 2px);
    min-height: 20px;
    height: calc(100% - 2px);
    borderRadius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Bar = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: ${props => props.width}%;
    height: 100%;
    background-color: ${props => props.color};
`;

const ValueWrapper = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;


const defaultRanges = [
    {
        value: 30,
        color: "#E53935"
    },
    {
        value: 50,
        color: "#FFCC02"
    },
    {
        value: 100,
        color: "#4caf50"
    },
]

const defaultMin = 0;
const defaultMax = 100;

const ProgressBarLevel = ({ value, min: _min, max: _max, ranges: _ranges, unit = "" }) => {
    const {
        min,
        max,
        ranges
    } = useMemo(() => ({
        min: Number.isFinite(_min) ? _min : defaultMin,
        max: Number.isFinite(_max) ? _max : defaultMax,
        ranges: Array.isArray(_ranges) ? _ranges : defaultRanges,
    }), [_min, _max, _ranges])
    const color = useMemo(() => {
        const range = ranges.find(el => el.value >= value)
        return range?.color || "transparent";
    }, [value, ranges])
    const barWidth = useMemo(() => {
        if (!Number.isFinite(value)) return 100;
        if (value < min || value > max) return 100;
        return 100 * ((value - min) / (max - min))
    }, [value, min, max])
    const isValidValue = useMemo(() => {
        return Number.isFinite(value) && value >= min && value <= max;
    }, [value, min, max])

    return <Wrapper $isInvalidValue={!isValidValue && Number.isFinite(parseFloat(value))}>
        <Bar color={color} width={barWidth} />
        <ValueWrapper>
            {isValidValue ? <span> {value} {unit}</span> : <span> {Number.isFinite(parseFloat(value)) ? value : "-"} </span>}
        </ValueWrapper>
    </Wrapper>
}

export default memo(ProgressBarLevel);