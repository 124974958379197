const setAuthToken = (token) => {
    localStorage.setItem("accessToken", token)
}
const getAuthToken = () => {
    return localStorage.getItem("accessToken")
}
const clearAuthToken = () => {
    return localStorage.removeItem("accessToken")
}
export { setAuthToken, getAuthToken, clearAuthToken }
