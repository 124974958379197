import { useMemo } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Switch,
    FormControlLabel,
    Grid2,
    Badge,
    Tooltip,
    IconButton,
} from '@mui/material';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from "react-router-dom";

const Page = ({ name, id, href, requiredPermission, pageFullAccessRequiredPermissions, updatePagePermissions, readOnly, permissionsMap }) => {
    const hasPagePermission = useMemo(() => {
        return !!permissionsMap[`admin.page.${requiredPermission}`];
    }, [permissionsMap, requiredPermission]);

    const { nPermissions, nTotalPermissions } = useMemo(() => {
        return {
            nTotalPermissions: pageFullAccessRequiredPermissions.length,
            nPermissions: pageFullAccessRequiredPermissions.reduce((p, c) => p + (permissionsMap[c] ? 1 : 0), 0),
        }
    }, [pageFullAccessRequiredPermissions, permissionsMap]);
    return <Card>
        <CardContent>
            <Grid2 container spacing={2} alignItems="center" justifyContent="space-between">
                <Grid2>
                    <Tooltip title="Open">
                        <Link
                            to={`${href || '/'}`}
                            target="_blank"
                            style={{ textDecoration: "none" }}
                        >
                            <IconButton color="primary">
                                <OpenInNewIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </Grid2>
                <Grid2 flexGrow={1}>
                    <Typography gutterBottom variant="body1">
                        Page:<strong> {name}</strong>
                    </Typography>
                </Grid2>
                <Grid2>
                    <Tooltip title={`Page has ${nPermissions} of ${nTotalPermissions} for full access`}>
                        <Badge badgeContent={`${nPermissions}/${nTotalPermissions}`} showZero color="secondary">
                            <AddModeratorIcon color="primary" />
                        </Badge>
                    </Tooltip>
                </Grid2>
                <Grid2>
                    <FormControlLabel
                        size="small"
                        control={<Switch
                            disabled={readOnly}
                            checked={hasPagePermission}
                            onChange={(e) => updatePagePermissions(id, pageFullAccessRequiredPermissions, e.target.checked)}
                        />}
                        label="Enable"
                    />
                </Grid2>
            </Grid2>
        </CardContent>
    </Card>
}
export default Page;