import { useState, useMemo } from 'react';
import {
    TextField,
    Box
} from '@mui/material';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import { rgba } from 'polished';
import ChatRoomCard from '../ChatRoomCard';
import chatRoomType from 'constants/chatRoomType';
import useAuth from 'hooks/useAuth';

const Wrapper = styled(Box)`
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color ${(props) => props.theme.palette.background.paper};
`
const RoomsWrapper = styled(Box)`
    overflow: auto;
    height: 100%;
    padding: 0 10px;
    border-right: 1px solid ${(props) => rgba(props.theme.palette.text.secondary, 0.2)};
`

const getRoomTypePriority = (type) => {
    switch (type) {
        case chatRoomType.PRIVATE:
        case chatRoomType.SUPPORT:
            return 2
        case chatRoomType.GROUP:
            return 1;
        case chatRoomType.DEVICE:
            return 0;
        default:
            return -1;
    }
}

const getOnlineUsersPriority = (participants) => {
    return 4 * (participants.filter(el => el.isOnline).length / participants.length)
}

const getRoomScore = (room) => {
    return getRoomTypePriority(room.type) + getOnlineUsersPriority(room._participants);
}

const ChatRooms = ({ updateSelectedRoom, usersMap, roomID }) => {
    const { userID } = useAuth();
    const users = useSelector((state) => state.chat.users);
    const convs = useSelector((state) => state.chat.convs || []);
    const roomConvsMap = useMemo(() => {
        return convs
            .reduce((a, c) => ({ ...a, [c._id]: c.message || {} }), {});
    }, [convs]);
    const [searchStr, setSearchStr] = useState("")
    const chatRooms = useSelector(state => state.chat.chatRooms);
    const roomList = useMemo(() => chatRooms
        .filter(el => el.participants.includes(userID))
        .map(el => ({
            ...el,
            _participants: el.participants
                .filter(el => usersMap[el])
                .map(el => usersMap[el])
        })).map(el => ({ ...el, s: getRoomScore(el) }))
        .sort((a, b) => getRoomScore(b) - getRoomScore(a))
        , [chatRooms, usersMap, userID]);

    const filtredChatRooms = useMemo(() => {
        const str = searchStr.toLocaleLowerCase().trim();
        if (searchStr.length > 0) {
            const filtredUsersMap = users
                .filter(user => user.displayName?.toLocaleLowerCase().includes(str))
                .reduce((a, c) => ({ ...a, [c._id]: true }), {})
            return roomList.filter(el => (el.name || '').toLocaleLowerCase().includes(str) || el.participants.some(el => filtredUsersMap[el]))
        }
        return roomList;
    }, [searchStr, users, roomList]);

    return <Wrapper p={1}>
        <TextField
            label="Search contacts..."
            variant="outlined"
            size="small"
            fullWidth
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value)}
            sx={{ my: 1 }}

        />
        <RoomsWrapper>
            {filtredChatRooms.map(room => <ChatRoomCard
                key={room._id}
                room={room}
                participants={room._participants}
                lastMessage={roomConvsMap[room._id]}
                onClick={() => updateSelectedRoom(room._id)}
                selected={room._id === roomID}
            />)}
        </RoomsWrapper>
    </Wrapper>
}

export default ChatRooms;
