import React, { useEffect, useMemo } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid2,
    Divider,
    List,
    ListItem,
    ListItemText,
    Button,
    Checkbox,
    Box
} from '@mui/material';
import { Link as ReactLink } from 'react-router-dom';
//import { orange, red, blue, pink, purple, grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import BatteryLevel from 'components/BatteryLevel';
import WifiLevel from 'components/WifiLevel';
import { formatTimeDate } from 'utils/timeFormat';
import { getEvents } from 'utils/api';
import { useSelector, useDispatch } from 'react-redux';
import { updateDeviceAllEventsAction } from 'redux/events/events.actions';
import moment from 'moment';
import codes from 'constants/codes';

const Phone = ({ color }) => {
    return <Box
        sx={{ position: 'relative' }}>
        <PhoneAndroidIcon sx={{ color }} />
    </Box>
}

//const colors = [blue[500], orange[500], red[500], grey[500], pink[500], purple[500], blue[200], orange[200], red[200], grey[200], pink[200], purple[200]];
const getAndroidColorFromID = (androidID) => {
    return "#2e7d32";
    /*
    create colors depending on androidID - disable because of visibilty issues with background
    if (!androidID) return '#000000';
    let i = 0;
    `${androidID}`.split('').forEach((el) => {
        i += el.charCodeAt(0);
    })
    return colors[i % colors.length];
    */
}

const DeviceInfos = ({
    androidID,
    battery_level,
    wifi_level,
    name,
    x,
    y,
    z,
    ax,
    ay,
    az,
    date,
    t,
    err,
    rttOffsetX,
    rttOffsetY,
    mapDevice,
    updateMapDevice
}) => {
    const dispatch = useDispatch();
    const devicesEvents = useSelector((state) => state.events.devicesEvents);
    const events = useMemo(() => devicesEvents[androidID] || [], [devicesEvents, androidID]);
    useEffect(() => {
        const edate = moment().toISOString();
        const sdate = moment().subtract(1, 'days').endOf('day').toISOString();
        const fetchCall = getEvents({ androidID, sdate, edate })
            .then((events) => dispatch(updateDeviceAllEventsAction({ androidID, events: events || {} })));
        return () => {
            fetchCall.cancel();
        }
    }, [dispatch, androidID]);
    const scanEvents = useMemo(() => events.filter(el => parseInt(el.messageID) === codes.GSW_SCANNED), [events]);
    const latestScanEvents = useMemo(() => {
        if(scanEvents.length === 0) return [];
        if(scanEvents.length < 20){
            const _sortedEvents = [...scanEvents].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).slice(-3);
            _sortedEvents.reverse();
            return _sortedEvents;
        }
        const firstEvent = scanEvents[0];
        const lastEvent = scanEvents[scanEvents.length - 1];
        const isRealTimeOrdered = new Date(firstEvent.date).getTime() < new Date(lastEvent.date).getTime();
        if(isRealTimeOrdered) {
            const _lastEventsRealtimeOrdered = scanEvents.slice(-3);
            _lastEventsRealtimeOrdered.reverse();
            return _lastEventsRealtimeOrdered;
        }
        const _latestEventsAPIOrdered = scanEvents.slice(0, 3);
        
        return _latestEventsAPIOrdered; 
    }, [scanEvents])
    
    useEffect(() => {
        if (Object.keys(mapDevice).length === 0) {
            updateMapDevice(androidID, {
                showAPSignals: false,
                showName: true,
                showPointer: true
            });
        }
    }, [mapDevice, androidID, updateMapDevice])

    const handleShowAPSignals = (e) => {
        const _mapDevice = { ...mapDevice };
        _mapDevice.showAPSignals = e.target.checked;
        updateMapDevice(androidID, _mapDevice);
    }
    const handleShowName = (e) => {
        const _mapDevice = { ...mapDevice };
        _mapDevice.showName = e.target.checked;
        updateMapDevice(androidID, _mapDevice);
    }
    const handleShowPointer = (e) => {
        const _mapDevice = { ...mapDevice };
        _mapDevice.showPointer = e.target.checked;
        updateMapDevice(androidID, _mapDevice);
    }
    return <Accordion key={androidID}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Grid2 size={12} container alignItems="center" justifyContent="space-between">
                <Grid2 size={1}>
                    <Phone
                        lastContact={(new Date(date)).getTime()}
                        lastRtt={t}
                        color={getAndroidColorFromID(androidID)}
                    />
                </Grid2>
                <Grid2 size={9}>
                    <Typography variant="body2">
                        {name || androidID || ''}
                    </Typography>
                </Grid2>
                <Grid2 size={1}>
                    <Grid2 container direction="column" alignItems="center" justifyContent="center">
                        <Grid2>
                            <BatteryLevel level={battery_level} />
                        </Grid2>
                        <Grid2>
                            <Typography variant="body2">
                                {battery_level || '?'}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Grid2 size={1}>
                    <Grid2 container direction="column" alignItems="center" justifyContent="center">
                        <Grid2>
                            <WifiLevel level={wifi_level} />
                        </Grid2>
                        <Grid2>
                            <Typography variant="body2">
                                {wifi_level || '?'}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Grid2 sx={{ display: { xs: 'block', lg: 'none', xl: 'block' } }}>
                    <ReactLink
                        to={`/devices/${androidID}`}
                        style={{ textDecoration: "none" }}
                    >
                        <Button variant="contained" color="primary" size="small">
                            Device Profile
                        </Button>
                    </ReactLink>
                </Grid2>
            </Grid2>
        </AccordionSummary>
        <AccordionDetails>
            <Grid2 container>
                <Grid2 size={12}>
                    <Grid2 sx={{ display: { xs: 'none', lg: 'block', xl: 'none' }, mb: 3 }}>
                        <ReactLink
                            to={`/devices/${androidID}`}
                            style={{
                                textDecoration: "none"
                            }}
                        >
                            <Button variant="contained" color="primary" size="small">
                                Device Profile
                            </Button>
                        </ReactLink>
                    </Grid2>
                </Grid2>
                <Grid2 size={4}>
                    <Checkbox
                        onChange={handleShowPointer}
                        checked={mapDevice.showPointer || false}
                    />
                    <Typography variant="body2" display="inline">
                        Pointer
                    </Typography>
                </Grid2>
                <Grid2 size={4}>
                    <Checkbox
                        onChange={handleShowName}
                        checked={mapDevice.showName || false}
                    />
                    <Typography variant="body2" display="inline">
                        Name
                    </Typography>
                </Grid2>
                <Grid2 size={4}>
                    <Checkbox
                        onChange={handleShowAPSignals}
                        checked={mapDevice.showAPSignals || false}
                    />
                    <Typography variant="body2" display="inline">
                        AP signal
                    </Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Typography variant="h6" display="inline">
                        Absolute Position
                    </Typography>
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="body1" display="inline" sx={{ mr: 1 }}>
                        X:
                    </Typography>
                    <Typography variant="h6" display="inline">
                        {Number.isFinite(x) ? Math.round(x) : ''}
                    </Typography>
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="body1" display="inline" sx={{ mr: 1 }}>
                        Y:
                    </Typography>
                    <Typography variant="h6" display="inline">
                        {Number.isFinite(y) ? Math.round(y) : ''}
                    </Typography>
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="body1" display="inline" sx={{ mr: 1 }}>
                        Z:
                    </Typography>
                    <Typography variant="h6" display="inline">
                        {Number.isFinite(z) ? Math.round(z) : ''}
                    </Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Divider />
                </Grid2>
                <Grid2 size={12}>
                    <Typography variant="h6" display="inline">
                        Relative Position
                    </Typography>
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="body1" display="inline" sx={{ mr: 1 }}>
                        X:
                    </Typography>
                    <Typography variant="h6" display="inline">
                        {Number.isFinite(x) && Number.isFinite(rttOffsetX) ? Math.round(x) + rttOffsetX : '?'}
                    </Typography>
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="body1" display="inline" sx={{ mr: 1 }}>
                        Y:
                    </Typography>
                    <Typography variant="h6" display="inline">
                        {Number.isFinite(y) && Number.isFinite(rttOffsetY) ? Math.round(y) + rttOffsetY : ''}
                    </Typography>
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="body1" display="inline" sx={{ mr: 1 }}>
                        Z:
                    </Typography>
                    <Typography variant="h6" display="inline">
                        {Number.isFinite(z) ? Math.round(z) : ''}
                    </Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Divider />
                </Grid2>
                <Grid2 size={12} sx={{ display: 'none' }}>
                    <Typography variant="h6" display="inline">
                        Acceleration
                    </Typography>
                </Grid2>
                <Grid2 size={4} sx={{ display: 'block' }}>
                    <Typography variant="body1" display="inline" sx={{ mr: 1 }}>
                        AX:
                    </Typography>
                    <Typography variant="h6" display="inline">
                        {Number.isFinite(ax) ? (Math.round(ax * 1000) / 1000) : ''}
                    </Typography>
                </Grid2>
                <Grid2 size={4} sx={{ display: 'block' }}>
                    <Typography variant="body1" display="inline" sx={{ mr: 1 }}>
                        AY:
                    </Typography>
                    <Typography variant="h6" display="inline">
                        {Number.isFinite(ay) ? (Math.round(ay * 1000) / 1000) : ''}
                    </Typography>
                </Grid2>
                <Grid2 size={4} sx={{ display: 'block' }}>
                    <Typography variant="body1" display="inline" sx={{ mr: 1 }}>
                        AZ:
                    </Typography>
                    <Typography variant="h6" display="inline">
                        {Number.isFinite(az) ? (Math.round(az * 1000) / 1000) : ''}
                    </Typography>
                </Grid2>
                <Grid2 size={12} sx={{ display: "block" }}>
                    <Typography variant="body1" display="inline" sx={{ mr: 1 }}>
                        Error:
                    </Typography>
                    <Typography variant="h6" display="inline">
                        {Number.isFinite(err) ? `${Math.round(err)}` : ''}
                    </Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Divider />
                </Grid2>
                <Grid2 size={12}>
                    <Typography variant="body1" display="inline" sx={{ mr: 1 }}>
                        Inactive Since:
                    </Typography>
                    <Typography variant="h6" display="inline">
                        {t ? formatTimeDate(t) : ''}
                    </Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Divider />
                </Grid2>
                <Grid2 size={12}>
                    <Typography variant="body1" display="inline" sx={{ mr: 1 }}>
                        Total Scan (Daily)
                    </Typography>
                    <Typography variant="h6" display="inline">
                        {scanEvents && scanEvents.length ? scanEvents.length : '-'}
                    </Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Divider />
                </Grid2>
                {latestScanEvents?.length > 0 ? <React.Fragment>
                    <Grid2 size={12}>
                        <Typography variant="h6" display="inline" sx={{ mr: 1 }}>
                            Most Recent Scanned Items
                        </Typography>
                        <List dense={true} >
                            {latestScanEvents.map((el, index) => (
                                <ListItem key={`${index}-${el._id}`}>
                                    <ListItemText
                                        primary={el.word}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Grid2>
                    <Grid2 size={12}>
                        <Divider />
                    </Grid2>
                </React.Fragment> : null}
                <Grid2 size={12}>
                    <Divider />
                </Grid2>
            </Grid2>
        </AccordionDetails>
    </Accordion>

}
export default DeviceInfos;