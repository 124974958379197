import { useEffect, useState, useMemo } from 'react';
import {
    TextField,
    Grid2,
    Typography,
    Chip,
    Button
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const Scan = ({ properties, setProperties, editing, setIsValid }) => {
    const [stringToTrim, setStringToTrim] = useState('');
    const scanTrim = useMemo(() => Array.isArray(properties.scanTrim) && properties.scanTrim.every(el => typeof el === "string") ? properties.scanTrim : [], [properties.scanTrim]);
    const canAddString = useMemo(() => {
        return stringToTrim.length > 0 && !scanTrim.includes(stringToTrim);
    }, [stringToTrim, scanTrim])

    useEffect(() => {
        setIsValid(
            Number.isInteger(parseInt(properties.scanGoal))
            && Array.isArray(properties.scanTrim) &&
            properties.scanTrim.every(el => typeof el === "string")
        );
    }, [properties, scanTrim, setIsValid]);

    const handleAddString = () => {
        if (stringToTrim.length === 0 || scanTrim.includes(stringToTrim)) return;
        setProperties({ ...properties, scanTrim: [...scanTrim, stringToTrim] })
        setStringToTrim("");
    }

    const deleteString = (str) => {
        setProperties({ ...properties, scanTrim: scanTrim.filter(el => el !== str) });
    }

    return editing ? <Grid2 container spacing={2}>
        <Grid2 size={6}>
            <Typography variant="h6" >
                Scan Goal
            </Typography>
        </Grid2>
        <Grid2 size={6}>
            <TextField
                label="Scan Goal"
                value={properties.scanGoal || ''}
                onChange={(e) => setProperties({ ...properties, scanGoal: e.target.value })}
                variant="outlined"
                size="small"
                min={0}
                type="number"
                sx={{ width: 200 }}
            />
        </Grid2>
        <Grid2 size={6}>
            <Typography variant="h6" >
                Scan Trim
            </Typography>
        </Grid2>
        <Grid2 size={6} container spacing={2} alignItems="center">
            <Grid2>
                <TextField
                    sx={{ width: 200 }}
                    label="Trim"
                    value={stringToTrim}
                    onChange={(e) => setStringToTrim(e.target.value)}
                    variant="outlined"
                    size="small"
                />
            </Grid2>
            <Grid2>
                <Button
                    variant="contained"
                    size="small"
                    endIcon={<AddCircleIcon />}
                    disabled={!canAddString}
                    onClick={handleAddString}
                >
                    Add
                </Button>
            </Grid2>
        </Grid2>
        <Grid2 size={12} container spacing={2}>
            {scanTrim.map(el => <Grid2 key={el}>
                <Chip
                    size="small"
                    color="secondary"
                    label={el}
                    onDelete={() => deleteString(el)}
                />
            </Grid2>)}
        </Grid2>
    </Grid2>
        :
        <Grid2 container spacing={6} alignItems="center">
            <Grid2 size={6}>
                <Typography variant="h6" >
                    Scan Goal
                </Typography>
            </Grid2>
            <Grid2 size={6}>
                <Typography variant="h6">
                    {properties.scanGoal || '-'}
                </Typography>
            </Grid2>
            <Grid2 size={12} container spacing={1} alignItems="center">
                <Grid2>
                    <Typography variant="h6">
                        Scan Trim
                    </Typography>
                </Grid2>
                {scanTrim.map(el => <Grid2 key={el}>
                    <Chip
                        size="small"
                        color="secondary"
                        label={el}
                    />
                </Grid2>)}
            </Grid2>
        </Grid2>
}

export default Scan;