import {
    useState,
    useEffect,
    useMemo,
    useRef
} from 'react';
import { Box } from '@mui/material';
import { baseURL } from "utils/http";
import { useSelector } from 'react-redux';
import DevicePointer from '../Components/DevicePointer';
import AccessPointGroup from '../Components/AccessPointGroup';
import { RTT_HEATMAP_ZONE_SIZE_IN_MM } from 'constants/rtt';
import HeatLayer from './Heatlayer';

const getZoneSignalLevelFromAdjustentZones = ({ zones, x, y, nCellInterpolation }) => {
    let weights = 0;
    let sum = 0;
    for (let i = -nCellInterpolation; i <= nCellInterpolation; i++) {
        for (let j = -nCellInterpolation; j <= nCellInterpolation; j++) {
            const zone = zones[`${x + i * RTT_HEATMAP_ZONE_SIZE_IN_MM}_${y + j * RTT_HEATMAP_ZONE_SIZE_IN_MM}`]
            if (zone) {
                const weight = 1 / Math.sqrt(Math.pow(i, 2) + Math.pow(j, 2));
                weights += weight;
                sum = sum + zone.level * weight;
            }
        }
    }
    if (!weights) return;
    return sum / weights;
}

function HeatMap({ rttSettings, groupDevices, mapOptions, userUISettings }) {
    const [mapWidth, setMapWidth] = useState(0);
    const wifiLevelsLocations = useSelector(state => state.rtt.wifiLevelsLocations || []);
    const mapRef = useRef();
    const resizingTimer = useRef(null);
    useEffect(() => {
        if (!ResizeObserver) return;
        let observer = null;
        const mapRefCurrent = mapRef.current;
        if (mapRefCurrent) {
            observer = new ResizeObserver(() => {
                clearTimeout(resizingTimer.current);
                resizingTimer.current = setTimeout(() => {
                    if (mapRefCurrent?.clientWidth) {
                        setMapWidth(mapRefCurrent.clientWidth)
                    }
                }, 100)

            });
            observer.observe(mapRefCurrent);
        }
        return () => {
            if (observer && mapRefCurrent) observer.unobserve(mapRefCurrent);
        }
    }, []);

    useEffect(() => {
        if (ResizeObserver) return;
        const mapRefCurrent = mapRef.current;
        if (mapRefCurrent && mapRefCurrent.clientWidth && mapRefCurrent.clientWidth !== mapWidth) {
            setMapWidth(mapRefCurrent.clientWidth);
        }
    }, [mapRef, mapWidth]);
    const devices = useSelector((state) => state.rtt.devices || {});
    const accessPoints = useSelector((state) => state.rtt.accessPoints || []);
    const mapHeight = useMemo(() => {
        if (rttSettings && rttSettings.rttMapWidth && rttSettings.rttMapHeight) {
            return Math.round(mapWidth * parseInt(rttSettings.rttMapHeight) / parseInt(rttSettings.rttMapWidth));
        }
        return Math.round(mapWidth);
    }, [mapWidth, rttSettings]);
    const devicesInfos = useSelector((state) => state.devices || {});
    const devicesMap = useMemo(() => {
        return devicesInfos.reduce((p, c) => ({ ...p, [c.androidID]: c }), {});
    }, [devicesInfos]);

    const groupDevicesMap = useMemo(() => {
        return groupDevices.reduce((p, c) => ({ ...p, [c]: c }), {});
    }, [groupDevices]);

    const devicesLocation = useMemo(() => {
        const _devices = Object.values(devices);
        const offsetX = rttSettings.rttOffsetX || 0;
        const offsetY = rttSettings.rttOffsetY || 0;
        if (_devices.length === 0) return [];
        if (!rttSettings.rttMapWidth || !rttSettings.rttMapHeight) return [];
        if (!mapWidth || !mapHeight) return [];
        return _devices
            .filter(el => groupDevicesMap[el.androidID] &&
                el.x + offsetX >= -2000 &&
                el.x + offsetX <= rttSettings.rttMapWidth + 2000 &&
                el.y + offsetY >= -2000 &&
                el.y + offsetY <= rttSettings.rttMapHeight + 2000
            )
            .map(el => ({
                x: Math.round(mapWidth * (el.x + offsetX) / rttSettings.rttMapWidth),
                y: Math.round(mapHeight * (el.y + offsetY) / rttSettings.rttMapHeight),
                t: el.t,
                id: el.androidID,
                lastContact: (devicesMap[el.androidID] && devicesMap[el.androidID].date) ? (new Date(devicesMap[el.androidID].date)).getTime() : 0,
                wifi_level: (devicesMap[el.androidID] && Number.isInteger(devicesMap[el.androidID].wifi_level)) ? devicesMap[el.androidID].wifi_level : '-',
                androidID: el.androidID,
                ipaddr: devicesMap[el.androidID] ? devicesMap[el.androidID].ipaddr : '',
                name: devicesMap[el.androidID] ? (devicesMap[el.androidID].name || devicesMap[el.androidID].ipaddr || el.androidID) : el.androidID,
                accessPoints: el.accessPoints,
            }));
    }, [devices, rttSettings, mapWidth, mapHeight, devicesMap, groupDevicesMap]);
    const apInfos = useMemo(() => {
        if (!accessPoints?.length || !mapWidth || !mapHeight) return [];
        const offsetX = rttSettings.rttOffsetX || 0;
        const offsetY = rttSettings.rttOffsetY || 0;
        if (!rttSettings.rttMapWidth || !rttSettings.rttMapHeight) return [];
        return accessPoints
            .filter(el => el.type === "RTT")
            .map(el => ({
                x: Math.round(mapWidth * (el.x + offsetX) / rttSettings.rttMapWidth),
                y: Math.round(mapHeight * (el.y + offsetY) / rttSettings.rttMapHeight),
                mac: el.mac,
                id: el.mac,
                disabled: el.disabled,
                ignored: !mapOptions.accessPoints[el.mac],
                type: el.type,
                temperature: el.temperature,
                battery_percentage_left: el.battery_percentage_left
            }))

    }, [rttSettings, mapWidth, mapHeight, accessPoints, mapOptions.accessPoints]);

    const maxMapSizeMM = useMemo(() => Math.max(rttSettings?.rttMapWidth, rttSettings?.rttMapHeight) || 0, [rttSettings]);
    const wifiSignalHeatmap = useMemo(() => {
        if (!mapWidth || !mapHeight) return [];
        const offsetX = rttSettings.rttOffsetX || 0;
        const offsetY = rttSettings.rttOffsetY || 0;
        if (!rttSettings.rttMapWidth || !rttSettings.rttMapHeight) return [];
        const _wifiSignalHeatmap = [];
        const zones = {};
        const apFilteringEnabled = apInfos.some(el => el.ignored);
        if (apFilteringEnabled && Object.values(mapOptions.accessPoints || {}).filter(el => el).length === 0) return [];
        const deviceFilterEnabled = Object.values(mapOptions.devices || {}).filter(el => el).length !== groupDevices.length;
        wifiLevelsLocations.forEach(el => {
            if (!groupDevicesMap[el.aID]) return;
            if (deviceFilterEnabled && !mapOptions.devices[el.aID]) return;
            let level = el.maxWifi || 0;
            if (apFilteringEnabled) {
                level = -1;
                Object.keys(mapOptions.accessPoints).forEach(mac => {
                    if (!mapOptions.accessPoints[mac]) return;
                    if (el.apWifiLevel[`${mac}`] > level) level = el.apWifiLevel[`${mac}`];
                })
                if (!Number.isInteger(level) || level < 0) return;
            }
            zones[`${el.x}_${el.y}`] = {
                x: Math.round(mapWidth * (el.x + offsetX) / rttSettings.rttMapWidth),
                y: Math.round(mapHeight * (el.y + offsetY) / rttSettings.rttMapHeight),
                level,
            };
        })
        _wifiSignalHeatmap.push(...Object.values(zones));
        if (mapOptions.showWiFiLevels) return _wifiSignalHeatmap;
        const _wifiSignalHeatmapInterpolated = [];
        const roundedOffsetX = RTT_HEATMAP_ZONE_SIZE_IN_MM * Math.round(offsetX / RTT_HEATMAP_ZONE_SIZE_IN_MM);
        const roundedOffsetY = RTT_HEATMAP_ZONE_SIZE_IN_MM * Math.round(offsetY / RTT_HEATMAP_ZONE_SIZE_IN_MM);
        const nCellInterpolation = Number.isInteger(rttSettings.rttWifiLevelInterpolation) ? rttSettings.rttWifiLevelInterpolation : 3;
        for (let i = - roundedOffsetX; i < rttSettings.rttMapWidth - roundedOffsetX; i = i + RTT_HEATMAP_ZONE_SIZE_IN_MM) {
            for (let j = -roundedOffsetY; j < rttSettings.rttMapHeight - roundedOffsetY; j = j + RTT_HEATMAP_ZONE_SIZE_IN_MM) {
                if (!zones[`${i}_${j}`]) {
                    const level = getZoneSignalLevelFromAdjustentZones({ zones, x: i, y: j, nCellInterpolation });
                    if (Number.isFinite(level)) {
                        _wifiSignalHeatmapInterpolated.push({
                            x: i,
                            y: j,
                            level
                        })
                    }
                }
            }
        }
        _wifiSignalHeatmapInterpolated.forEach((el) => {
            _wifiSignalHeatmap.push({
                x: Math.round(mapWidth * (el.x + offsetX) / rttSettings.rttMapWidth),
                y: Math.round(mapHeight * (el.y + offsetY) / rttSettings.rttMapHeight),
                level: el.level,
                interpolated: true
            })
        })

        return _wifiSignalHeatmap
    }, [wifiLevelsLocations, mapWidth, mapHeight, rttSettings, mapOptions, groupDevicesMap, apInfos, groupDevices.length])

    return (
        <Box style={{
            width: '100%'
        }}>
            <Box
                ref={mapRef}
                sx={{
                    position: 'relative',
                    border: 1,
                    borderColor: 'grey.500',
                    backgroundSize: '100% auto',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top left',
                    backgroundImage: rttSettings && rttSettings.rttImageUrl ? `url(${baseURL}${rttSettings.rttImageUrl})` : '',
                    height: mapHeight,
                    width: 'calc(100% - 10px)',
                    m: 2,
                    overflow: 'hidden'
                }}
            >
                <HeatLayer
                    wifiSignalHeatmap={wifiSignalHeatmap}
                    mapWidth={mapWidth}
                    mapHeight={mapHeight}
                    maxMapSizeMM={maxMapSizeMM}
                    mapOptions={mapOptions}
                />
                <AccessPointGroup apInfos={apInfos} accessPointsInfosMap={{}} />
                {devicesLocation.map((el) => <DevicePointer
                    key={el.id}
                    x={el.x}
                    y={el.y}
                    t={el.t}
                    showName={true}
                    smoothing={rttSettings.smoothness}
                    androidID={el.androidID}
                    name={el.name}
                    accessPoints={el.accessPoints || []}
                    iconSize={userUISettings.iconSize}
                    lastContact={el.lastContact}
                    wifi_level={el.wifi_level}
                />)}
            </Box>
        </Box>
    );
}

export default HeatMap;
