import { useEffect } from 'react';
import { updateGroupsAction } from "redux/groups/groups.actions";
import { updateSettingsAction } from "redux/settings/settings.actions";
import { useDispatch } from "react-redux";
import {
    getChatUsers,
    getAllRoles,
    getBadges,
    getAllAdmins,
    getAdmins,
    getConversationsList,
    getDevices,
    getGroups,
    getChatRooms,
    getSettings,
    getAllCompanies,
    getDuplicatedDevices
} from 'utils/api';
import {
    setChatUsersAction,
    setChatDevicesAction,
    setConvsationsAction,
    setMyChatIDAction,
    updateChatRoomsAction,
} from 'redux/chat/chat.actions';
import {
    updateAdminsAction,
    updateRolesAction,
} from 'redux/admins/admins.actions';
import { updateBadgesAction } from 'redux/badges/badges.actions';
import { supportUpdateCompaniesAction, supportUpdateDuplicatedDevices } from 'redux/support/support.actions';
import { updateDevicesAction } from 'redux/devices/devices.actions';
import useAuth from "hooks/useAuth";
import USER_TYPE from 'constants/userType';
const AppSharedStateReduxLoader = () => {
    const { isAuthenticated, type, userID } = useAuth();
    const dispatch = useDispatch();
    useEffect(() => {
        let fetchCalls = [];
        if (isAuthenticated && userID && [USER_TYPE.ADMIN, USER_TYPE.SUPPORT, USER_TYPE.ADMIN_DEMO].includes(type)) {
            dispatch(setMyChatIDAction(userID));
            if (type === USER_TYPE.SUPPORT) fetchCalls = [
                getAllRoles().then(roles => dispatch(updateRolesAction(roles || []))), // can remove
                getChatUsers().then(users => dispatch(setChatUsersAction(users || []))),
                getConversationsList().then(convs => dispatch(setConvsationsAction(convs || []))),
                getAllAdmins().then(admins => dispatch(updateAdminsAction(admins || []))),
                getAllCompanies().then(companies => dispatch(supportUpdateCompaniesAction(companies || []))),
                getChatRooms().then(rooms => dispatch(updateChatRoomsAction(rooms))),
                getDuplicatedDevices().then(devices => dispatch(supportUpdateDuplicatedDevices(devices)))
            ];
        }
        if ([USER_TYPE.ADMIN, USER_TYPE.ADMIN_DEMO].includes(type)) fetchCalls = [
            getGroups()
                .then(groups => dispatch(updateGroupsAction(groups || []))),
            getSettings()
                .then((settings) => dispatch(updateSettingsAction(settings))),
            getChatUsers().then(users => dispatch(setChatUsersAction(users || []))),
            getDevices({ androidID: true, name: true, date: true, badgeID: true, gswcb_version: true }).then(devices => {
                dispatch(setChatDevicesAction(devices || []));
                dispatch(updateDevicesAction(devices || []));
            }),
            getConversationsList().then(convs => dispatch(setConvsationsAction(convs || []))),
            getBadges().then(badges => dispatch(updateBadgesAction(badges))),
            getChatRooms().then(rooms => dispatch(updateChatRoomsAction(rooms))),
            getAdmins().then(admins => dispatch(updateAdminsAction(admins)))
        ];
        return () => {
            fetchCalls.forEach(fetchCall => fetchCall.cancel());
        }
    }, [dispatch, isAuthenticated, type, userID])
    return null;
}
export default AppSharedStateReduxLoader;