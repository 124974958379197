import React from 'react';
import DeviceInfos from './DeviceInfos';

const Devices = ({ deviceList, mapDevices, updateMapDevices }) => {
    const updateMapDevice = (androidID, updates) => {
        updateMapDevices({ ...mapDevices, [androidID]: updates });
    }
    return (
        <div>
            {deviceList.map(device => <DeviceInfos
                key={device.androidID}
                {...device}
                mapDevice={mapDevices[device.androidID] || {}}
                updateMapDevice={updateMapDevice}
            />)}
        </div>
    );
}

export default Devices;