import React from 'react';
import { styled } from '@mui/system';
import { NavLink } from "react-router-dom";
import { spacing } from "@mui/system";
import {
    Link,
    Grid2,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography
} from "@mui/material";
import MyAccount from './MyAccount';
import UpdateMyEmail from './UpdateMyEmail';
import NotificationsSettings from './NotificationsSettings';
import RequestEmailVerification from './RequestEmailVerification';
import ThemeSelect from './ThemeSelect';
import ResetUserPreferences from './ResetUserPreferences';
import SummaryReportSettings from './SummaryReportSettings';
import SessionDuration from './SessionDuration';
import SupportLogon from './SupportLogon';
import Authorized from 'components/Authorized';
import useAuth from 'hooks/useAuth';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);
function Account() {
    const {
        emailVerified
    } = useAuth();
    return (
        <React.Fragment>
            <Typography variant="h3" gutterBottom display="inline">
                Account
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" sx={{ mt: 2 }}>
                <Link component={NavLink} to="/">
                    Settings
                </Link>
                <Typography>Account</Typography>
            </Breadcrumbs>
            <Divider sx={{ my: 6 }} />
            <Grid2 container spacing={6}>
                <Grid2 size={12}>
                    <MyAccount />
                </Grid2>
            </Grid2>
            <Grid2 container spacing={6}>
                <Grid2 size={12}>
                    <Authorized permission="myAccount.write">
                        <UpdateMyEmail />
                    </Authorized>
                </Grid2>
                <Grid2 size={12}>
                    <Authorized permission="myAccount.write">
                        <SessionDuration />
                    </Authorized>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                    <Authorized some={["admin.read", "chat.read", "alert.read"]}>
                        <NotificationsSettings />
                    </Authorized>
                </Grid2>
                {!emailVerified && <Grid2 size={{ xs: 12, md: 6 }}>
                    <RequestEmailVerification />
                </Grid2>}
                <Grid2 size={{ xs: 12, md: 6 }}>
                    <Authorized every={["myAccount.write", "event.read"]}>
                        <SummaryReportSettings />
                    </Authorized>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                    <Authorized permission="myAccount.write">
                        <SupportLogon />
                    </Authorized>
                </Grid2>
                <Grid2 size={12}>
                    <ThemeSelect />
                </Grid2>
                <Grid2 size={12}>
                    <ResetUserPreferences />
                </Grid2>
            </Grid2>
        </React.Fragment>
    );
}

export default Account;
