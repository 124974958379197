import AdminsActionTypes from "./admins.types"


const updateAdminsAction = (admins) => {
    return { type: AdminsActionTypes.UPDATE_ADMINS_ACTION, payload: admins }
}

const updateAdminAction = (admin) => {
    return { type: AdminsActionTypes.UPDATE_ADMIN_ACTION, payload: admin }
}
const deleteAdminAction = (adminID) => {
    return { type: AdminsActionTypes.DELETE_ADMIN_ACTION, payload: adminID }
}

const updateRolesAction = (roles) => {
    return { type: AdminsActionTypes.UPDATE_ROLES_ACTION, payload: roles }
}

const updateRoleAction = (role) => {
    return { type: AdminsActionTypes.UPDATE_ROLE_ACTION, payload: role }
}

const deleteRoleAction = (roleID) => {
    return { type: AdminsActionTypes.DELETE_ROLE_ACTION, payload: roleID }
}

const updatePermissionsAction = (permissions) => {
    return { type: AdminsActionTypes.UPDATE_PERMISSIONS_ACTION, payload: permissions }
}


export {
    updateAdminsAction,
    updateAdminAction,
    deleteAdminAction,
    updateRolesAction,
    updateRoleAction,
    deleteRoleAction,
    updatePermissionsAction
}
