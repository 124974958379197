import { useMemo } from 'react';
import { styled } from '@mui/system';
import {
    Badge,
    Avatar,
    Typography,
    Grid2,
    Tooltip
} from "@mui/material";
import { rgba } from 'polished';
import EmailIcon from '@mui/icons-material/Email';
import chatRoomType from 'constants/chatRoomType';
import HtmlTooltip from 'components/HtmlTooltip';
import ChatRoomDetails from '../ChatRoomDetails';
import useAuth from 'hooks/useAuth';
import { socketEmitters } from 'utils/socket'

const OnlineBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) => props.color === 'primary' ?
        props.theme.sidebar.footer.online.background :
        props.theme.sidebar.footer.offline.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;
const Wrapper = styled(Grid2)`
    background-color: ${(props) => props.$selected ? rgba(props.theme.palette.secondary.main, 0.2) : ''};
    padding-top: 5px;
    padding-bottom: 2px;
`

const ChatRoomCard = ({ participants, room, lastMessage, onClick, selected }) => {
    const { userID } = useAuth();
    const { name, profilePicture, isOnline } = useMemo(() => {
        switch (room.type) {
            case chatRoomType.DEVICE: {
                const device = participants.find(el => /^[0-9a-f]{16}$/i.test(el._id))
                return {
                    name: device?.displayName || "Device",
                    profilePicture: "/static/img/avatars/android.png",
                    isOnline: !!device?.isOnline
                }
            }
            case chatRoomType.GROUP:
                return {
                    name: room.name || "Group",
                    profilePicture: "/static/img/avatars/group.png",
                    isOnline: "N/A"
                }
            case chatRoomType.PRIVATE: {
                const participant = participants.find(el => el._id !== userID);
                return {
                    name: participant?.displayName || participant?._id || "-",
                    profilePicture: participant?.profilePicture,
                    isOnline: !!participant?.isOnline
                }
            }
            case chatRoomType.SUPPORT: {
                const participant = participants.find(el => el._id !== userID);
                return {
                    name: participant?.displayName || participant?._id || "-",
                    profilePicture: participant?.profilePicture,
                    isOnline: !!participant?.isOnline
                }
            }
            default:
                return {
                    name: room.name || "*Group",
                    profilePicture: "/static/img/avatars/group.png",
                    isOnline: "N/A"
                }
        }
    }, [participants, room, userID]);
    const newUnseenMessage = useMemo(() => {
        if (!lastMessage?.seenBy) return false;
        return lastMessage.from !== userID && !lastMessage.seenBy.includes(userID);
    }, [lastMessage, userID]);
    const handleMarkSeen = (e) => {
        e.stopPropagation();
        e.preventDefault();
        socketEmitters.emitMarkSeen({
            roomID: room._id
        })
    }
    if (!room) return null;
    return <Wrapper container alignItems="center" wrap="nowrap" $selected={selected} spacing={1}>
        <Grid2>
            <OnlineBadge
                overlap="circular"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                variant="dot"
                invisible={isOnline === "N/A"}
                color={isOnline ? 'primary' : 'secondary'}
            >
                <HtmlTooltip
                    border="1px solid transparent"
                    backgroundColor="transparent"
                    maxWidth={300}
                    title={<ChatRoomDetails participants={participants} room={room} />}
                >
                    <Avatar
                        role={onClick ? "button" : ""}
                        alt={name}
                        src={profilePicture}
                        onClick={onClick}
                    />
                </HtmlTooltip>
            </OnlineBadge>
        </Grid2>
        <Grid2 flexGrow={1} sx={{ minWidth: 0 }}>
            <Typography variant="h6" noWrap color={newUnseenMessage ? "secondary" : "inherit"}>{name}</Typography>
        </Grid2>
        {newUnseenMessage > 0 && <Grid2>
            <Tooltip title={lastMessage?.text || "New message"}>
                <EmailIcon color="secondary" role="button" onClick={handleMarkSeen} />
            </Tooltip>
        </Grid2>}
    </Wrapper>
}

export default ChatRoomCard;