import React,
{
    useEffect,
    useState
} from 'react';
import { styled } from '@mui/system';
import {
    Card as MuiCard,
    CardHeader,
    CardContent,
    Grid2,
    Typography,
    Switch,
    Box
} from '@mui/material';
import { toast } from 'react-toastify';
import { spacing } from '@mui/system';
import useAuth from 'hooks/useAuth';
import useAuthorization from 'hooks/useAuthorization';
import Authorized from 'components/Authorized';

const Card = styled(MuiCard)(spacing);
const NotificationsSettings = () => {
    const { isAuthorized } = useAuthorization();
    const canUpdateAccountSettings = isAuthorized("myAccount.write");
    const [notificationOnNewChatMessage, setNotificationOnNewChatMessage] = useState(false);
    const [notificationOnNewAlert, setNotificationOnNewAlert] = useState(false);
    const [notificationOnNewAdminAdded, setNotificationOnNewAdminAdded] = useState(false);
    const [notificationWhileConnected, setNotificationWhileConnected] = useState(false);
    const [fetching, setFetching] = useState(false);
    const {
        notificationOnNewAlert: userNotificationOnNewAlert,
        notificationOnNewChatMessage: userNotificationOnNewChatMessage,
        notificationOnNewAdminAdded: userNotificationOnNewAdminAdded,
        notificationWhileConnected: userNotificationWhileConnected,
        userID,
        emailNotConfirmed,
        updateUser
    } = useAuth();
    useEffect(() => {
        if (userID) {
            if (userNotificationOnNewAlert) setNotificationOnNewAlert(true);
            if (userNotificationOnNewChatMessage) setNotificationOnNewChatMessage(true);
            if (userNotificationOnNewAdminAdded) setNotificationOnNewAdminAdded(true);
            if (userNotificationWhileConnected) setNotificationWhileConnected(true);
        }
    }, [userID, userNotificationOnNewAdminAdded, userNotificationOnNewAlert, userNotificationOnNewChatMessage, userNotificationWhileConnected]);

    async function handleUpdateNotificationsSettings(
        {
            onNewChatMessage,
            onNewAlert,
            onNewAdminAdded,
            whileConnected
        }
    ) {
        try {
            const userUpdateData = {
                notificationOnNewAlert: onNewAlert,
                notificationOnNewChatMessage: onNewChatMessage,
                notificationOnNewAdminAdded: onNewAdminAdded,
                notificationWhileConnected: whileConnected,
            }
            const results = await updateUser(userUpdateData);
            if (results && results.message) {
                toast.success(results.message);
            }
            setFetching(false);
        } catch (err) {
            setFetching(false);
            if (err && err.message) {
                toast.error(err.message);
            }
            else toast.error("Could not update settings");
        }
    }
    const handleNotificationOnNewAlertUpdate = (e) => {
        setNotificationOnNewAlert(e.target.checked)
        handleUpdateNotificationsSettings({
            onNewChatMessage: notificationOnNewChatMessage,
            onNewAlert: e.target.checked,
            onNewAdminAdded: notificationOnNewAdminAdded,
            whileConnected: notificationWhileConnected,
        });
    }
    const handleNotificationOnNewChatMessageUpdate = (e) => {
        setNotificationOnNewChatMessage(e.target.checked);
        handleUpdateNotificationsSettings({
            onNewChatMessage: e.target.checked,
            onNewAlert: notificationOnNewAlert,
            onNewAdminAdded: notificationOnNewAdminAdded,
            whileConnected: notificationWhileConnected,
        });
    }
    const handleNotificationOnNewAdminAddedUpdate = (e) => {
        setNotificationOnNewAdminAdded(e.target.checked);
        handleUpdateNotificationsSettings({
            onNewChatMessage: notificationOnNewChatMessage,
            onNewAlert: notificationOnNewAlert,
            onNewAdminAdded: e.target.checked,
            whileConnected: notificationWhileConnected,
        });
    }
    const handleNotificationWhileConnected = (e) => {
        setNotificationWhileConnected(e.target.checked);
        handleUpdateNotificationsSettings({
            onNewChatMessage: notificationOnNewChatMessage,
            onNewAlert: notificationOnNewAlert,
            onNewAdminAdded: notificationOnNewAdminAdded,
            whileConnected: e.target.checked,
        });
    }

    return <Card sx={{ mb: 6 }}>
        <CardHeader title={<Typography variant="h6">
            Email Notifications Settings
        </Typography>
        } />
        <CardContent>
            <Box sx={{ mx: 8, width: 280 }}>
                <Authorized permission="chatMessage.read">
                    <Grid2 container alignItems="center" justifyContent="space-between">
                        <Grid2>
                            <Typography variant="body1">
                                New chat messages
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <Switch disabled={!canUpdateAccountSettings || fetching || !userID || emailNotConfirmed} onChange={handleNotificationOnNewChatMessageUpdate} checked={notificationOnNewChatMessage} color="primary" />
                        </Grid2>
                    </Grid2>
                </Authorized>
                <Authorized permission="alert.read">
                    <Grid2 container alignItems="center" justifyContent="space-between">
                        <Grid2>
                            <Typography variant="body1">
                                Alerts
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <Switch disabled={!canUpdateAccountSettings || fetching || !userID || emailNotConfirmed} onChange={handleNotificationOnNewAlertUpdate} checked={notificationOnNewAlert} color="primary" />
                        </Grid2>
                    </Grid2>
                </Authorized>
                <Authorized permission="admin.read">
                    <Grid2 container alignItems="center" justifyContent="space-between">
                        <Grid2>
                            <Typography variant="body1">
                                New Admin added
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <Switch disabled={!canUpdateAccountSettings || fetching || !userID || emailNotConfirmed} onChange={handleNotificationOnNewAdminAddedUpdate} checked={notificationOnNewAdminAdded} color="primary" />
                        </Grid2>
                    </Grid2>
                </Authorized>
                <Authorized permission="chatMessage.read">
                    <Grid2 container alignItems="center" justifyContent="space-between">
                        <Grid2>
                            <Typography variant="body1">
                                Notify me while beeing logged in
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <Switch disabled={!canUpdateAccountSettings || fetching || !userID || emailNotConfirmed} onChange={handleNotificationWhileConnected} checked={notificationWhileConnected} color="primary" />
                        </Grid2>
                    </Grid2>
                </Authorized>
            </Box>
        </CardContent>
    </Card >
}
export default NotificationsSettings;