import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { styled } from '@mui/system';
import { useParams } from "react-router-dom";
import {
  Button,
  Grid2,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grow
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from "react-toastify";
import useAuth from "hooks/useAuth";

const Wrapper = styled(Box)`
  padding: ${(props) => props.theme.spacing(6)};
`;

function UpdateEmail() {
  const { updateEmail } = useAuth();
  const [successMessage, setSuccessMessage] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const { uuid } = useParams();
  const navigate = useNavigate();
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, []);
  const handleUpdateEmail = async () => {
    setLoading(true);
    try {
      const results = await updateEmail(uuid);
      setLoading(false);
      if (typeof results === "string") {
        setSuccessMessage(results);
        toast.success(results);
        setTimeout(() => {
          navigate("/");
        }, 3000)
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      if (error &&error.message && typeof error.message === "string") {
        toast.error(error.message);
      }
      else toast.error("Something went wrong");
    }
  }
  return (
    <Grid2 container justifyContent="center">
      <Wrapper>
        {!successMessage ?
          (
            <Box>
              <Typography component="h2" variant="body1">
                By updating you will no longer be able to
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Login to your account using your old email"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Receive notifications on your old email"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CloseIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Receive Update Newsletter"
                  />
                </ListItem>
              </List>
              <FormControlLabel
                control={<Checkbox onChange={(e) => setAgreed(e.target.checked)} name="rememberMe" checked={agreed} color="primary" />}
                label="I understand"
              />
              <Grow
                in={agreed}
                style={{ transformOrigin: '0 0 0' }}
                {...(agreed ? { timeout: 1000 } : {})}
              >
                <LoadingButton
                  onClick={handleUpdateEmail}
                  endIcon={<SaveIcon />}
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                >
                  Update
                </LoadingButton>
              </Grow>

            </Box>
          )
          :
          (
            <React.Fragment >
              <Box m={3}>
                <Typography component="h2" variant="h5" align="center">
                  {successMessage}
                </Typography>
              </Box>
              <Button
                component={Link}
                to="/"
                fullWidth
                variant="contained"
                color="primary"
              >
                Home
              </Button>
            </React.Fragment >
          )}
      </Wrapper>
    </Grid2>
  );
}

export default UpdateEmail;