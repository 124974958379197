import React, {
    useState,
    useEffect,
    useMemo,
} from 'react';
import {
    DataGridPro as DataGridProMui,
    useGridApiRef,
    GridToolbar,
    gridClasses
} from '@mui/x-data-grid-pro';
import {
    Grid2,
    Card,
    CardContent,
    Tooltip,
    IconButton,
    ClickAwayListener,
} from '@mui/material';
import { styled } from '@mui/system';
import { rgba } from 'polished';
import CancelIcon from '@mui/icons-material/Cancel';
import { updateSettingAction } from 'redux/settings/settings.actions';
import { useDispatch } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';
import { http } from 'utils/http';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyboardEventViewer from './KeyboardEventViewer';
import Authorized from 'components/Authorized';


const DataGridPro = styled(DataGridProMui)(({ theme }) => ({
    [`.${gridClasses.cell}.has-errors`]: {
        backgroundColor: rgba(theme.palette.error.main, 0.2),
    }
}));

const Keyboard = ({ properties, setProperties, editing, setIsValid, settingID }) => {
    const dispatch = useDispatch();
    const [reseting, setReseting] = useState(false);
    const [fetching, setFetching] = useState(false);
    useEffect(() => {
        if (!properties.keyList) {
            if (properties.keys && properties.keys.length) {
                setProperties({ ...properties, keyList: [...properties.keys] });
            }
            else {
                setProperties({ ...properties, keys: [], keyList: [] });
            }
        }
    }, [properties, setProperties]);
    const _keyList = useMemo(() => {
        if (!properties.keys) return [];
        if (!editing) return [...properties.keys];
        return [
            ...properties.keys,
            {
                code: '',
                disableDelete: true
            }
        ];
    }, [properties.keys, editing]);

    const apiRef = useGridApiRef();
    const handleKeyHideToggle = (code) => {
        const _keys = [...properties.keys];
        const key = _keys.find(el => el.code === code);
        if (!key) return;
        key.hide = !key.hide;
        setProperties({ ...properties, keys: _keys });
    }

    const handleCancelKeyEdit = (id) => {
        const keyIndex = properties.keys.findIndex(el => el.code === id);
        const orgKey = properties.keyList.find(el => el.code === id);
        if (keyIndex !== -1) {
            const _keys = [...properties.keys];
            _keys.splice(keyIndex, 1, orgKey);
            setProperties({ ...properties, keys: _keys });
        } else {
            console.error("editing key not foud! id:", id)
        }
    }
    const handleDeleteKey = (id) => {
        if (apiRef.current) {
            apiRef.current.setRowSelectionModel([])
        }
        setTimeout(() => {
            if (apiRef.current) {
                apiRef.current.setRowSelectionModel([])
            }
            setProperties({ ...properties, keys: properties.keys.filter(el => el.code !== id) });
        }, 10);
    }
    const handleOnAddKey = () => {
        if (apiRef.current && editing) {
            const mode = apiRef.current.getCellMode({ id: "", field: "code" })
            if (mode !== "view") return;
            apiRef.current.startCellEditMode({ id: "", field: "code" });
        }
    }

    const handleResetKeyboardKeys = async () => {

        try {
            setReseting(false);
            setFetching(true);
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/setting/setting/reset-keyboard`,
                { settingID });
            dispatch(updateSettingAction(resp.data));
            if (resp.data && resp.data.keys && Array.isArray(resp.data.keys)) {
                setProperties({ keys: resp.data.keys, keyList: [] });
            }
            setFetching(false);
            toast.success("Keyboard reset to default");
        } catch (error) {
            setFetching(false);
            if (error &&
                error.response &&
                error.response.data &&
                error.response.data.message) {
                toast.error(error.response.data.message)
            }
            else toast.error("Could not reset keyboard");
        }
    }

    const columns = [
        {
            field: "code",
            headerName: "Code",
            width: 80,
            editable: editing,
            renderCell: (params) => params.row.code === '' ? <Tooltip title="New Key"><AddIcon role="button" color="primary" onClick={handleOnAddKey} /></Tooltip> : params.row.char,
            preProcessEditCellProps: (params) => {
                const newCode = params.props.value;
                const hasError = !/^\s*-?[0-9]{1,20}\s*$/.test(newCode);
                return { ...params.props, error: hasError };
            }
        },
        {
            field: "char",
            headerName: "Char",
            width: 80,
            editable: false,
            renderCell: (params) => params.row.code === '' ? '-' : String.fromCharCode(params.row.code),
        },
        {
            field: "description",
            headerName: "Description",
            width: 300,
            editable: editing,
            renderCell: (params) => params.row.code === '' ? "Add New Key" : params.row.description
        },
        {
            field: "key",
            headerName: "Key",
            width: 80,
            editable: editing,
        },
        {
            field: "shift",
            headerName: "SHIFT",
            width: 80,
            editable: editing
        },
        {
            field: "ctrl",
            headerName: "CTRL",
            width: 80,
            editable: editing,
        },
        {
            field: "alt",
            headerName: "ALT",
            width: 80,
            editable: editing
        },
        {
            field: "altgr",
            headerName: "ALT+CTRL",
            width: 100,
            editable: editing
        },
        {
            field: "hide",
            headerName: "Hide",
            description: "Show/Hide this key in the user interface",
            width: 100,
            editable: false,
            renderCell: (params) => params.row.code !== '' ? (
                params.row.hide ? <IconButton
                    disabled={!editing}
                    onClick={() => handleKeyHideToggle(params.row.code)}
                    color="error">
                    <VisibilityOffIcon />
                </IconButton> :
                    <IconButton
                        disabled={!editing}
                        onClick={() => handleKeyHideToggle(params.row.code)}
                        color="primary" >
                        <VisibilityIcon />
                    </IconButton>) : '',
        },
        {
            field: "edited",
            headerName: "",
            width: 50,
            editable: false,
            renderCell: (params) => params.row.edited && !params.row.new && editing ? <Tooltip title="Cancel Modifications"><CancelIcon role="button" onClick={() => handleCancelKeyEdit(params.row.code)} color="primary" /></Tooltip> : "",
        },
        {
            field: "delete",
            headerName: "",
            width: 50,
            editable: false,
            renderCell: (params) => !params.row.disableDelete && editing ? <Tooltip title="Delete Key"><DeleteForeverIcon role="button" onClick={() => handleDeleteKey(params.row.code)} color="error" /></Tooltip> : "",
        }
    ];

    const handleClickAway = () => {
        if (apiRef.current && apiRef.current.setRowSelectionModel) {
            apiRef.current.setRowSelectionModel([])
        }
    }

    useEffect(() => {
        if (fetching) {
            setIsValid(false);
            return;
        }
        if (!properties.keyList || !properties.keys) {
            setIsValid(false);
            return;
        }
        if (properties.keyList.length !== properties.keys.length) {
            setIsValid(true);
            return;
        }
        if (JSON.stringify(properties.keyList) !== JSON.stringify(properties.keys)) {
            setIsValid(true);
            return;
        }
        setIsValid(true);
    }, [properties, setIsValid, properties.keyList, properties.keys, fetching])


    const handleOnCodeCellEditStop = (params) => {
        const code = params.id;
        const field = params.field;
        const oldValue = params.row[field]
        setTimeout(() => {
            const newValue = apiRef.current.getCellValue(code, field);
            if (newValue === oldValue || newValue === "" || properties.keys.find(el => `${el.code}` === newValue)) {
                return;
            }
            const _keys = [...properties.keys];
            const newKey = {
                code: parseInt(newValue),
                description: '',
                new: true,
            }
            _keys.push(newKey);
            setProperties({ ...properties, keys: _keys });
        }, 50);
    }

    const handleOnKeyDataCellEditStop = (params) => {
        const code = params.id;
        const field = params.field;
        const oldValue = params.row[field]
        setTimeout(() => {
            const newValue = apiRef.current.getCellValue(code, field);
            const keyIndex = properties.keys.findIndex(el => el.code === code);
            const _keys = [...properties.keys];
            if (keyIndex === -1 || newValue === oldValue) return;
            const key = {
                ...properties.keys[keyIndex],
                edited: true,
            };
            key[field] = newValue;
            _keys.splice(keyIndex, 1, key);
            setProperties({ ...properties, keys: _keys });
        }, 50);
    }

    const handleOnCellEditStop = (params) => {
        const field = params.field;
        switch (field) {
            case "code":
                handleOnCodeCellEditStop(params);
                break;
            default:
                handleOnKeyDataCellEditStop(params);
        }
    }


    return (
        (<Card>
            <CardContent>
                <Grid2 container spacing={6}>
                    {editing && <Grid2 size={12}>
                        <Authorized permission="event.read">
                            <KeyboardEventViewer keyboardKeys={properties.keyList || []} />
                        </Authorized>
                    </Grid2>}
                    <Grid2 size={12}>
                        <div style={{ height: 500, width: "100%" }}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <DataGridPro
                                    apiRef={apiRef}
                                    rows={_keyList}
                                    getRowId={(row) => row.code}
                                    columns={columns}
                                    isCellEditable={(params) => !(params.field === 'code' && /^\s*-?[0-9]{1,20}\s*$/.test(params.row.code))}
                                    slots={{
                                        toolbar: GridToolbar,
                                    }}
                                    initialState={{
                                        density: "compact",
                                        columns: {
                                            columnVisibilityModel: {
                                                char: false

                                            }
                                        }
                                    }}
                                    onCellEditStop={handleOnCellEditStop}
                                    getCellClassName={(params) => {
                                        if (params.field !== 'code') return "";
                                        const value = params.value;
                                        if (value === "") return "";
                                        const hasError = !/^\s*-?[0-9]{1,20}\s*$/.test(value);
                                        if (hasError) return "has-errors";
                                        return ""
                                    }}
                                />
                            </ClickAwayListener>
                        </div>
                    </Grid2>
                    {editing && <Grid2>
                        <Authorized permission="setting.resetKeyboard">
                            {reseting ? <>
                                <Tooltip title="Cancel">
                                    <IconButton
                                        color="primary"
                                        aria-label="Confirm"
                                        onClick={() => setReseting(false)}
                                    >
                                        <HighlightOffIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Reset to default">
                                    <IconButton
                                        color="warning"
                                        aria-label="Cancel"
                                        onClick={handleResetKeyboardKeys}
                                    >
                                        <CheckCircleIcon />
                                    </IconButton>
                                </Tooltip>
                            </> : <LoadingButton
                                onClick={() => setReseting(true)}
                                size="small"
                                endIcon={<RestartAltIcon />}
                                loading={fetching}
                                loadingPosition="end"
                                variant="contained"
                                color="warning"
                            >
                                Reset to default
                            </LoadingButton>}
                        </Authorized>
                    </Grid2>}
                </Grid2>
            </CardContent>
        </Card>)
    );
};

export default Keyboard;