import React, { useState, useEffect, useMemo } from "react";
import { styled } from '@mui/system';
import {
    Grid2,
    Card as MuiCard,
    CardContent
} from "@mui/material";
import { spacing } from "@mui/system";
import { useSelector, useDispatch } from 'react-redux';
import usePreferences from "hooks/usePreferences";
import { getRttWifiLevels } from 'utils/api';
import { updateRttWifiLevelsLocationsAction } from 'redux/rtt/rtt.actions';
import MapOptions from "./MapOptions";
import HeatMap from "./HeatMap";

const Card = styled(MuiCard)(spacing);

const defaultMapOptions = {
    showWiFiLevels: false,
    accessPoints: {},
    devices: {},
    opacity: 70
}


function Map({ rttSettings, groupDevices, fullScreenModeEnabled }) {
    const preferencesID = "rtt-settings-uisettings";
    const selectedGroupID = useSelector((state) => state.groups.selectedGroup);
    const { preferences } = usePreferences();
    const userUISettings = useMemo(() => preferences[preferencesID] || { iconSize: 32, heatMapOpacity: defaultMapOptions.opacity }, [preferences]);
    const [mapOptions, setMapOptions] = useState({ ...defaultMapOptions, opacity: userUISettings.heatMapOpacity || defaultMapOptions.opacity });
    const dispatch = useDispatch();
    const allAccessPoints = useSelector((state) => state.rtt.accessPoints || []);
    const wifiAccessPoints = useMemo(() => allAccessPoints.filter(el => el.type === "RTT").sort((a, b) => (a.y - b.y)).sort((a, b) => (a.x - b.x)), [allAccessPoints]);

    useEffect(() => {
        if (!selectedGroupID) return;
        getRttWifiLevels({ group: selectedGroupID })
            .then(locations => dispatch(updateRttWifiLevelsLocationsAction(locations)));
    }, [dispatch, selectedGroupID])

    useEffect(() => {
        const _accessPointsMacList = Object.keys(mapOptions.accessPoints || {});
        if (
            _accessPointsMacList.length !== wifiAccessPoints.length ||
            !wifiAccessPoints.every(el => _accessPointsMacList.includes(el.mac))
        ) {
            const _accessPoints = wifiAccessPoints.reduce((a, c) => ({ ...a, [c.mac]: true }), {});
            setMapOptions(mapOptions => ({ ...mapOptions, accessPoints: _accessPoints }));
        }

    }, [wifiAccessPoints, mapOptions.accessPoints])

    useEffect(() => {
        const _devicesList = Object.keys(mapOptions.devices || {});
        if (
            _devicesList.length !== groupDevices.length ||
            !groupDevices.every(el => _devicesList.includes(el))
        ) {
            const _devices = groupDevices.reduce((a, c) => ({ ...a, [c]: true }), {});
            setMapOptions(mapOptions => ({ ...mapOptions, devices: _devices }))
        }

    }, [groupDevices, mapOptions.devices])


    return (
        <React.Fragment>
            <Grid2 container spacing={6}>
                <Grid2 size={{ xs: 12, lg: fullScreenModeEnabled ? 12 : 8 }} order={{ xs: 1, lg: 0 }}>
                    <HeatMap
                        rttSettings={rttSettings}
                        groupDevices={groupDevices}
                        mapOptions={mapOptions}
                        userUISettings={userUISettings}
                    />
                </Grid2>
                {!fullScreenModeEnabled && <Grid2 size={{ xs: 12, lg: 4 }} order={{ xs: 0, lg: 1 }}>
                    <Card sx={{ maxHeight: "100vh", overflow: 'auto' }}>
                        <CardContent>
                            <MapOptions
                                mapOptions={mapOptions}
                                updateMapOptions={setMapOptions}
                                groupDevices={groupDevices}
                            />
                        </CardContent>
                    </Card>
                </Grid2>}
            </Grid2>
        </React.Fragment>
    );
}

export default Map;
