import React, { useState, useMemo } from "react"
import {
    Card,
    CardContent,
    Grid2,
    TextField,
    IconButton,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RouterIcon from '@mui/icons-material/Router';
import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOff';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

const AccessPointConfig = ({
    accessPointMac,
    accessPointGain,
    accessPointX,
    accessPointY,
    accessPointZ,
    accessPointDisabled,
    accessPointOffset,
    accessPointType,
    updateAccessPointConfig,
    accessPointID,
    deleteAccessPoint,
    cancelCreating,
    readOnly
}) => {
    const [deletingConfirmation, setDeletingConfirmation] = useState(false);
    const [mac, setMac] = useState(accessPointMac);
    const [gain, setGain] = useState(accessPointGain);
    const [offset, setOffset] = useState(accessPointOffset)
    const [x, setX] = useState(accessPointX);
    const [y, setY] = useState(accessPointY);
    const [z, setZ] = useState(accessPointZ);
    const [type, setType] = useState(accessPointType);
    const [disabled, setDisabled] = useState(accessPointDisabled);
    const isFormValid = useMemo(() => {
        if (
            accessPointMac === mac &&
            parseInt(accessPointX) === parseInt(x) &&
            parseInt(accessPointY) === parseInt(y) &&
            parseInt(accessPointZ) === parseInt(z) &&
            parseFloat(accessPointGain) === parseFloat(gain) &&
            parseFloat(accessPointOffset) === parseFloat(offset) &&
            parseFloat(accessPointOffset) === parseFloat(offset) &&
            type === accessPointType &&
            accessPointDisabled === disabled
        ) return false;
        if (parseFloat(gain) < 0) return false;
        return /(^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$)|([0-9A-Fa-f]{12})/.test(mac);
    }, [accessPointMac, accessPointX, accessPointY, accessPointZ, accessPointType, x, y, z, mac, gain, type, accessPointGain, accessPointDisabled, disabled, offset, accessPointOffset]);
    return <Card variant="outlined" sx={{ my: 0, py: 0, borderColor: cancelCreating ? 'primary.main' : '' }}>
        <CardContent sx={{ my: 0, py: 0 }}>
            <Grid2 container spacing={2}
                alignItems="center"
                justifyContent="center"
                sx={{ mt: 2 }}
                columns={18}
            >
                <Grid2 size={{ xs: 3, md: 1 }}>
                    <Tooltip title={disabled ? "Enabled (Disable?)" : "Disabled (Enable?)"}>
                        <span>
                            <IconButton disabled={readOnly} color={disabled ? "default" : "secondary"} onClick={() => setDisabled(!disabled)}>
                                {disabled ? <PortableWifiOffIcon fontSize="large" /> : <RouterIcon fontSize="large" />}
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid2>
                <Grid2 size={{ xs: 15, md: 3 }} >
                    <TextField
                        label="Mac"
                        value={mac || ''}
                        onChange={(e) => setMac(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ my: 2 }}
                        disabled={readOnly}
                    />
                </Grid2>
                <Grid2 size={{ xs: 9, md: 2 }}>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel id="accessPointType-select-label">Type</InputLabel>
                        <Select
                            labelId="accessPointType-select-label"
                            id="accessPointType-select"
                            value={type || 'RTT'}
                            label="Type"
                            size="small"
                            fullWidth
                            onChange={(e) => setType(e.target.value)}
                            disabled={readOnly}
                        >
                            {['RTT', 'BLE'].map(el => <MenuItem id={el} key={el} value={el}>{el}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid2>
                <Grid2 size={{ xs: 9, md: 2 }}>
                    <TextField
                        type="number"
                        label="Gain"
                        value={Number.isFinite(parseFloat(gain)) ? gain : ''}
                        onChange={(e) => setGain(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ my: 2 }}
                        slotProps={{
                            htmlInput: { step: 0.1, min: 0 }
                        }}
                        disabled={readOnly}
                    />
                </Grid2>
                <Grid2 size={{ xs: 9, md: 2 }}>
                    <TextField
                        type="number"
                        label="Offset"
                        value={Number.isFinite(parseFloat(offset)) ? offset : ''}
                        onChange={(e) => setOffset(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        min={0}
                        sx={{ my: 2 }}
                        disabled={readOnly}
                    />
                </Grid2>
                <Grid2 size={{ xs: 9, md: 2 }}>
                    <TextField
                        type="number"
                        label="X"
                        value={Number.isInteger(parseInt(x)) ? x : ''}
                        onChange={(e) => setX(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ my: 2 }}
                        min={0}
                        disabled={readOnly}
                    />
                </Grid2>
                <Grid2 size={{
                    xs: 9, md: 2
                }}>
                    <TextField
                        type="number"
                        label="Y"
                        value={Number.isInteger(parseInt(y)) ? y : ''}
                        onChange={(e) => setY(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ my: 2 }}
                        min={0}
                        disabled={readOnly}
                    />
                </Grid2 >
                <Grid2 size={{ xs: 9, md: 2 }}>
                    <TextField
                        type="number"
                        label="Z"
                        value={Number.isInteger(parseInt(z)) ? z : ''}
                        onChange={(e) => setZ(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ my: 2 }}
                        min={0}
                        disabled={readOnly}
                    />
                </Grid2 >
                {deletingConfirmation ? <Grid2 size={{ xs: 9, md: 1 }} >
                    < IconButton
                        variant="contained"
                        color="error"
                        onClick={() => deleteAccessPoint({ id: accessPointID })}
                    >
                        <DeleteForeverIcon fontSize="large" />
                    </IconButton >
                </Grid2 > :
                    <Grid2 size={{ xs: 9, md: 1 }}>
                        <IconButton
                            disabled={!isFormValid}
                            variant="contained"
                            color="primary"
                            onClick={() => updateAccessPointConfig({ id: accessPointID, mac, x, y, z, gain, disabled, offset, type })}
                        >
                            <SaveIcon fontSize="large" />
                        </IconButton>
                    </Grid2>}
                {
                    cancelCreating ? (<Grid2 size={{ xs: 9, md: 1 }}>
                        <IconButton
                            variant="contained"
                            onClick={cancelCreating}
                        >
                            <HighlightOffIcon fontSize="large" color="warning" />
                        </IconButton>
                    </Grid2>) :
                        (deletingConfirmation ? <Grid2 size={{ xs: 9, md: 1 }}>
                            <IconButton
                                variant="contained"
                                onClick={() => setDeletingConfirmation(false)}
                            >
                                <HighlightOffIcon fontSize="large" color="warning" />
                            </IconButton>
                        </Grid2> :
                            <Grid2 size={{ xs: 9, md: 1 }}>
                                <IconButton
                                    variant="contained"
                                    color="error"
                                    onClick={() => setDeletingConfirmation(true)}
                                    disabled={readOnly}
                                >
                                    <DeleteIcon fontSize="large" />
                                </IconButton>
                            </Grid2>)
                }
            </Grid2>
        </CardContent>
    </Card >
}

export default React.memo(AccessPointConfig);