import * as React from 'react';
import useAuth from 'hooks/useAuth';
import USER_TYPE from 'constants/userType';

function AuthGuard({ children }) {
  const { isAuthenticated, type } = useAuth();
  return <React.Fragment>{isAuthenticated && ([USER_TYPE.ADMIN, USER_TYPE.ADMIN_DEMO, USER_TYPE.SUPPORT, USER_TYPE.ADMIN_ACCOUNT_SETUP].includes(type)) ? children : null}</React.Fragment>;
}

export default AuthGuard;
