import { useState, useMemo } from 'react';
import {
    TextField,
    Box
} from '@mui/material';
import { styled } from '@mui/system';
import { rgba } from 'polished';
import { useSelector } from 'react-redux';
import UserCard from '../UserCard';
import { imgURL } from 'utils/http';
import useAuth from 'hooks/useAuth';
import chatRoomType from 'constants/chatRoomType';
import USER_TYPE from 'constants/userType';
import { isValidID } from 'utils/validation';

const Wrapper = styled(Box)`
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color ${(props) => props.theme.palette.background.paper};
`
const UsersWrapper = styled(Box)`
    overflow: auto;
    height: 100%;
    padding: 0 10px;
    border-right: 1px solid ${(props) => rgba(props.theme.palette.text.secondary, 0.2)};
`

const ChatUsers = ({ updateSelectedRoom, closeUserMode }) => {
    const [searchStr, setSearchStr] = useState("");
    const [selectedUserID, setSelectedUserID] = useState(null);
    const { userID } = useAuth();
    const users = useSelector((state) => state.chat.users);
    const usersMap = useMemo(() => users.reduce((a, c) => ({ ...a, [c._id]: c }), {}), [users])
    const chatRooms = useSelector(state => state.chat.chatRooms);
    const roomsList = useMemo(() => {
        return chatRooms.map(el => {
            switch (el.type) {
                case chatRoomType.DEVICE: {
                    const device = el.participants.find(el => /^[0-9a-f]{16}$/i.test(el))
                    return { ...el, roomName: usersMap[device]?.displayName || "Device" }
                }
                case chatRoomType.GROUP:
                    return { ...el, roomName: el.name || "Group" };
                case chatRoomType.PRIVATE: {
                    const participant = el.participants.find(el => el !== userID);
                    return { ...el, roomName: usersMap[participant]?.displayName || participant || "-" };
                }
                case chatRoomType.SUPPORT: {
                    const participant = el.participants.find(el => el._id !== userID);
                    return { ...el, roomName: usersMap[participant]?.displayName || participant || "-" }
                }
                default:
                    return { ...el, roomName: "*Group" }
            }
        });

    }, [chatRooms, usersMap, userID]);
    const handleChangeRoom = (roomID) => {
        updateSelectedRoom(roomID);
        closeUserMode();
    }
    const userChatRoomsMap = useMemo(() => {
        const _userChatroomsMap = {};
        roomsList.forEach(chatRoom => {
            if (!chatRoom.participants.includes(userID)) return;
            chatRoom.participants.forEach((el) => {
                if (isValidID(userID) && chatRoom.type === chatRoomType.DEVICE && isValidID(el)) return;
                if (!_userChatroomsMap[el]) _userChatroomsMap[el] = [];
                _userChatroomsMap[el].push(chatRoom);
            })
        })
        return _userChatroomsMap;
    }, [roomsList, userID]);
    const onlineUsers = useSelector((state) => state.chat.onlineUsers);
    const onlineUsersMap = useMemo(() => onlineUsers.reduce((a, c) => ({ ...a, [c]: true }), {}), [onlineUsers]);
    const usersList = useMemo(() => users.map(el => ({
        ...el,
        isOnline: !!onlineUsersMap[el._id],
        profilePicture: el.type === USER_TYPE.DEVICE ? ("/static/img/avatars/android.png") :
            (el.imageUrl ? imgURL + el.imageUrl : "/static/img/avatars/admin.png")
    }), {}), [users, onlineUsersMap]);

    const filtredUsersList = useMemo(() => {
        const str = searchStr.toLocaleLowerCase().trim();
        if (searchStr.length > 0) {
            return usersList
                .filter(user => [USER_TYPE.ADMIN, USER_TYPE.SUPPORT, USER_TYPE.DEVICE].includes(user.type) && user._id !== userID && user.displayName?.toLocaleLowerCase().includes(str))
        }
        return usersList.filter(user => [USER_TYPE.ADMIN, USER_TYPE.SUPPORT, USER_TYPE.DEVICE].includes(user.type) && user._id !== userID);
    }, [searchStr, usersList, userID]);
    return <Wrapper p={1}>
        <TextField
            label="Search contacts..."
            variant="outlined"
            size="small"
            fullWidth
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value)}
            sx={{ my: 1 }}
        />
        <UsersWrapper>
            {filtredUsersList.map(el => <UserCard
                key={el._id}
                {...el}
                selectedUserID={selectedUserID}
                setSelectedUserID={setSelectedUserID}
                updateSelectedRoom={handleChangeRoom}
                chatRooms={userChatRoomsMap[el._id] || []}
                closeUserMode={closeUserMode}
            />)}
        </UsersWrapper>
    </Wrapper>
}

export default ChatUsers;
