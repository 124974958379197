import React, { useRef, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { MessageSquare } from 'react-feather';
import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import chatMessageType from 'constants/chatMessageType';
import { socketEmitters } from 'utils/socket'
import { formatTimeDate, formatTimeDateFromNow } from 'utils/timeFormat';
import useAuth from 'hooks/useAuth';
import chatRoomType from 'constants/chatRoomType';
import { imgURL } from 'utils/http';

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;


const MessageStatusWrapper = styled(Box)`
    display: flex;
    justify-content: end;
    margin: 4px;
`
const StatusIconWrapper = styled(Box)`
    display: flex;
`

// test with dark theme
const HiddenUsersIcon = styled(Box)`
  width: 15px;
  height: 15px;
  border-radius: 15px;
  font-size: 10px;
  border: 1px solid ${(props) => props.theme.palette.text.primary};
  text-align: center;
  color: ${(props) => props.theme.palette.primary.main};
  line-height: 14px;
`
const MAX_PARTICIPANTS_AVATARS = 4;
const MessageStatus = ({ participants, seenByMap, deliveredToMap, myID, type }) => {
  const participantsList = useMemo(() => participants.length - 1 > MAX_PARTICIPANTS_AVATARS ? participants.slice(0, MAX_PARTICIPANTS_AVATARS) : participants, [participants]);
  const nExtraParticipants = useMemo(() => participants.length - 1 > MAX_PARTICIPANTS_AVATARS ? participants.length - MAX_PARTICIPANTS_AVATARS : 0, [participants]);
  return <MessageStatusWrapper>{participantsList.map(el => {
    if (myID === el._id) return null;
    if (seenByMap[el._id]) return <StatusIconWrapper key={el._id}><Tooltip title={el.displayName || el._id || "-"}><Avatar
      key={el._id}
      alt={el.displayName || el._id || "-"}
      src={el.profilePicture}
      sx={{ width: 16, height: 16 }}
    />
    </Tooltip></StatusIconWrapper>
    if (deliveredToMap[el._id]) return <StatusIconWrapper key={el._id}><Tooltip title={el.displayName || el._id || "-"}><CheckCircleIcon key={el._id} sx={{ width: 16, height: 16 }} /></Tooltip></StatusIconWrapper>
    return <StatusIconWrapper key={el._id}><Tooltip title={el.displayName || el._id || "-"}><CheckCircleOutlineIcon key={el._id} sx={{ width: 16, height: 16 }} /></Tooltip></StatusIconWrapper>
  })}
    {nExtraParticipants > 0 && <HiddenUsersIcon>+{nExtraParticipants}</HiddenUsersIcon>}
  </MessageStatusWrapper>
}



function Notification({ title = "", description = "", Icon, recieved, convID, isFile, date, seenBy, deliveredTo, senderID = '', myID = '', participants, type }) {
  console.log("convID", convID);
  const seenByMap = useMemo(() => seenBy.reduce((a, c) => ({ ...a, [c]: true }), {}), [seenBy]);
  const deliveredToMap = useMemo(() => deliveredTo.reduce((a, c) => ({ ...a, [c]: true }), {}), [deliveredTo]);
  const seen = useMemo(() => (senderID !== myID && seenByMap[myID]) || participants.length === seenBy.length, [participants, seenBy, myID, senderID, seenByMap]);
  const navigate = useNavigate();
  const handleMarkSeen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    socketEmitters.emitMarkSeen({
      roomID: convID
    })
  }
  const handleMarkUnSeen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    socketEmitters.emitMarkUnSeen({
      roomID: convID
    });
  }
  const messageText = (isFile ? (recieved ? "Sent you a File" : "You sent you a File") : '') || description;
  return (
    <ListItem divider
      secondaryAction={<React.Fragment>{!recieved && <MessageStatus
        participants={participants}
        seenBy={seenBy}
        deliveredTo={deliveredTo}
        myID={myID}
        seenByMap={seenByMap}
        deliveredToMap={deliveredToMap}
        type={type}
      />
      }
        {recieved && (seen ? (
          <DraftsOutlinedIcon role="button" onClick={handleMarkUnSeen} />
        )
          : (
            <MailOutlineOutlinedIcon role="button" onClick={handleMarkSeen} />
          ))}
      </React.Fragment>}
    >
      <ListItemAvatar onClick={() => navigate(`/support/chat/${convID}`)} role="button" title="open">
        <Avatar>
          <SvgIcon fontSize="small">
            <Icon />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
          noWrap: true,
          fontWeight: recieved && !seen ? 'bold' : 'normal'
        }}
        secondary={
          <React.Fragment>
            <Tooltip title={messageText}>
              <Typography
                variant="body1"
                component="span"
                display="block"
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: '100%',
                  whiteSpace: 'nowrap',
                  fontWeight: recieved && !seen ? 'bold' : 'normal',
                  color: recieved && !seen ? "textPrimary" : '',
                }}>
                {messageText}
              </Typography>
            </Tooltip>
            <Tooltip title={formatTimeDate(date)}>
              <Typography display="block" component="span" variant="body2" align="right">
                {formatTimeDateFromNow(date)}
              </Typography>
            </Tooltip>
          </React.Fragment>
        }
      />
    </ListItem>
  );
}

function NavbarMessagesDropdown() {
  const { userID } = useAuth();
  const convs = useSelector((state) => state.chat.convs || []);
  const convList = useMemo(() => {
    return convs
      .map(el => ({ ...el, message: el.message || {} }))
      .sort((a, b) => new Date(b.message?.createdAt).getTime() - new Date(a.message?.createdAt).getTime())
  }, [convs]);

  const nNewMessages = useMemo(() => convs.filter(el => el.message.from !== userID && Array.isArray(el.message.seenBy) && !el.message.seenBy?.includes(userID)).length, [userID, convs]);
  const chatAdmins = useSelector((state) => state.chat.admins || []);
  const chatAdminsMap = useMemo(() => chatAdmins.reduce((a, c) => ({ ...a, [c._id]: c }), {}), [chatAdmins])
  const getConvName = (conv) => {
    switch (conv.type) {
      case chatRoomType.DEVICE: {
        return "Invalid type";
      }
      case chatRoomType.PRIVATE: {
        return "Invalid type";
      }
      case chatRoomType.GROUP: {
        return "Invalid type";
      }
      case chatRoomType.SUPPORT:
        const user_id = conv.participants.find(el => el !== userID);
        return chatAdminsMap[user_id]?.displayName || conv.name || `Admin ${user_id}`;
      default:
        return conv.name || '-';
    }
  }
  const users = useSelector((state) => state.chat.users || []);
  const usersMap = useMemo(() => users
    .map(el => (
      {
        ...el,
        profilePicture: (el.imageUrl ? imgURL + el.imageUrl : "/static/img/avatars/admin.png")
      }
    ))
    .reduce((a, c) => ({
      ...a,
      [c._id]: c
    }),
      {}), [users]);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title="Messages">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={nNewMessages}>
            <MessageSquare />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {nNewMessages} New Messages
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            {convList.slice(0, 3).map((item) => {
              return (
                <Notification
                  key={item._id}
                  title={getConvName(item)}
                  convID={item._id}
                  description={item.message?.text}
                  Icon={MessageSquare}
                  type={item.type}
                  seenBy={item.message?.seenBy || []}
                  deliveredTo={item.message?.deliveredTo || []}
                  senderID={item.message?.from}
                  participants={item.participants.map(el => usersMap[el] || { _id: el })}
                  myID={userID}
                  recieved={item.message?.from !== userID}
                  date={item.message.createdAt}
                  isFile={item.type === chatMessageType.IMAGE ||
                    item.type === chatMessageType.FILE}
                />);
            }
            )}
          </List>
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" component={Link} to="/support/chat">
              Show all messages
            </Button>
          </Box>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarMessagesDropdown;


