import React from "react";
import { styled } from '@mui/system';
import { useMemo, useState, useEffect, useRef } from "react";
import {
    CardContent,
    Card as MuiCard,
    Typography,
    Grid2 as MuiGrid2,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Alert,
    Box
} from "@mui/material";
import { spacing } from "@mui/system";
import { useSelector } from "react-redux";
import LeafletMap from 'components/LeafletMap/LeafletMap';
import settingType from "constants/settingType";
import alertEventsTypes from 'constants/alertEventsTypes';
import codes from 'constants/codes';
import HelpIcon from '@mui/icons-material/Help';
import HTMLTooltip from "components/HtmlTooltip";
const Grid2 = styled(MuiGrid2)(spacing);
const Card = styled(MuiCard)(spacing);
const MapWrapper = styled(Box)`
  height: 500px;
  width: 100%;
`;

const geoFencingEventIDMap = alertEventsTypes
    .filter(el => el.messageID === codes.DEVICE_GEOFENCE_CROSSING)
    .reduce((a, c) => ({ ...a, [c.id]: true }), {});

const Geofencing = ({ properties, setProperties, editing, setIsValid, groupID }) => {
    const groups = useSelector((state) => state.groups.groups);
    const settings = useSelector((state) => state.settings);
    const [selectedGroupID, setSelectedGroupID] = useState('-');
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, [])
    useEffect(() => {
        if (groupID) setSelectedGroupID(groupID);
    }, [groupID])
    useEffect(() => {
        setIsValid(
            parseFloat(properties.lng) > -180 &&
            parseFloat(properties.lng) < 180 &&
            parseFloat(properties.lat) > -90 &&
            parseFloat(properties.lat) < 90 &&
            parseFloat(properties.radius) > 0
        );
    }, [properties, setIsValid])
    const [showRadius, setShowRadius] = useState(false);
    const [disableGrouping, setDisableGrouping] = useState(false);
    const devices = useSelector(state => state.devices || []);
    const deviceGroup = useMemo(() => {
        const deviceMap = {}
        if (selectedGroupID === '-') {
            devices.forEach(device => deviceMap[device.androidID] = device);
            groups.forEach(group => {
                if (group.devices) {
                    group.devices.forEach(androidID => {
                        delete deviceMap[androidID];
                    });
                }
            });
        }
        else {
            const group = groups.find(el => el._id === selectedGroupID);
            if (group) {
                group.devices.forEach(el => {
                    deviceMap[el] = {};
                })
                devices.forEach(el => {
                    if (deviceMap[el.androidID]) deviceMap[el.androidID] = el;
                })
            }
        }
        return Object.values(deviceMap).filter(el => el.androidID);
    }, [devices, groups, selectedGroupID]);
    const groupNamesHasNoGeofencingAlertsSettings = useMemo(() => {
        const alertsSettingsWithGeofencingMap = settings.filter(el => el.type === settingType.ALERTS &&
            el.alerts?.length &&
            el.alerts.some(el => geoFencingEventIDMap[el.eventID])
        ).reduce((a, c) => ({ ...a, [c._id]: true }), {});
        const _groupNamesHasNoGeofencingAlertsSettings = groups
            .filter(el => el.devices?.length &&
                (!groupID || el._id === groupID) &&
                el.geofencingSetting &&
                !alertsSettingsWithGeofencingMap[el.alertsSetting])
            .map(el => el.name || el._id);
        return _groupNamesHasNoGeofencingAlertsSettings;
    }, [settings, groups, groupID])

    const markers = useMemo(() => {
        const markers = [];
        deviceGroup.forEach(device => {
            if (device && device.androidID && device.radius && device.lat !== undefined && device.long !== undefined) {
                const marker = {
                    lat: device.lat,
                    lon: device.long,
                    label: <Typography variant="h6">
                        id: {device.androidID}
                    </Typography>,
                    radius: device.radius
                }
                markers.push(marker);
            }
        });
        return markers;
    }, [deviceGroup]);

    const currentGeoFencing = useMemo(() => {
        const handleGeoFencingUpdated = ({ lat, lng, radius }) => {
            if (!properties) return;
            if (parseFloat(lng) > -180 &&
                parseFloat(lng) < 180 &&
                parseFloat(lat) > -90 &&
                parseFloat(lat) < 90 &&
                parseFloat(radius) > 0
            ) {
                if (!properties ||
                    (properties &&
                        (properties.lat !== lat ||
                            properties.lng !== lng ||
                            properties.radius !== radius
                        ))
                ) {
                    setProperties((old) => {
                        if (old.lat === lat &&
                            old.lng === lng &&
                            old.radius === radius
                        ) {
                            return old;
                        }
                        else {
                            return {
                                lat,
                                lng,
                                radius
                            }
                        }
                    })
                }
            }
        }
        if (!properties) return;
        const { lat, lng, radius } = properties;
        if (parseFloat(lng) > -180 &&
            parseFloat(lng) < 180 &&
            parseFloat(lat) > -90 &&
            parseFloat(lat) < 90 &&
            parseFloat(radius) > 0
        ) {
            return {
                lng: parseFloat(lng),
                lat: parseFloat(lat),
                radius: parseFloat(radius),
                updateGeoFencing: editing ? handleGeoFencingUpdated : () => { },
            }
        }
        if (markers.length) {
            return {
                lat: markers[0].lat,
                lng: markers[0].lon,
                radius: markers[0].radius > 1 ? markers[0].radius * 2 : 30,
                updateGeoFencing: editing ? handleGeoFencingUpdated : () => { },
            }
        }
        return {
            lat: 33.767,
            lng: -84.4,
            radius: 5,
            updateGeoFencing: editing ? handleGeoFencingUpdated : () => { },
        };
    }, [properties, markers, setProperties, editing]);

    return (
        <Card sx={{ mb: 6 }}>
            <CardContent>
                <Grid2 container>
                    <Grid2 size={12}>
                        <Grid2 container spacing={6}>
                            {groupNamesHasNoGeofencingAlertsSettings.length > 0 && <Grid2 size={12}>
                                <Alert
                                    severity="warning"
                                    action={
                                        <HTMLTooltip
                                            maxWidth={300}
                                            title={
                                                <Typography>
                                                    <strong>Alerts</strong> can fire upon going in or out from the geofencing,
                                                    go to <strong>Alerts Settings</strong> and create a <strong>geofencing
                                                        alert</strong> for groups using this Geofencing Settings.
                                                </Typography>}
                                        >
                                            <HelpIcon color="primary" />
                                        </HTMLTooltip>
                                    }
                                >
                                    No geofencing alerts found for group{groupNamesHasNoGeofencingAlertsSettings.length > 1 ? "s" : ""}: {groupNamesHasNoGeofencingAlertsSettings.join(", ")}
                                </Alert>
                            </Grid2>}
                            <Grid2>
                                <Checkbox checked={showRadius} onChange={e => setShowRadius(e.target.checked)} />
                                <Typography variant="body2" display="inline">
                                    Show Radius
                                </Typography>
                            </Grid2>
                            <Grid2>
                                <Checkbox checked={disableGrouping} onChange={e => setDisableGrouping(e.target.checked)} />
                                <Typography variant="body2" display="inline">
                                    Disable Grouping
                                </Typography>
                            </Grid2>
                            {!groupID && <Grid2>
                                <FormControl fullWidth>
                                    <InputLabel id="select-group-label">Group</InputLabel>
                                    <Select
                                        labelId="select-group-label"
                                        id="select-group"
                                        value={selectedGroupID}
                                        label="Group"
                                        size="small"
                                        onChange={(e) => setSelectedGroupID(e.target.value)}
                                    >
                                        <MenuItem value={'-'}>Default Group</MenuItem>
                                        {groups.map(el => <MenuItem key={el._id} value={el._id}>{el.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid2>}
                        </Grid2>
                    </Grid2>
                    <Grid2 size={{ xs: 12, lg: 12 }}>
                        <MapWrapper>
                            <LeafletMap
                                devices={markers}
                                geoFencing={currentGeoFencing}
                                showRadius={showRadius}
                                disableGrouping={disableGrouping}
                                geoFencingEdit={editing}
                            />
                        </MapWrapper>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    );
}

export default Geofencing;
