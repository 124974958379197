const utsAgent = {
    AGENT: "agent",
    DATE: "date",
    IP: "ip",
    DEVICE_IP: "deviceIP",
    PID: "pid",
    END_IND_COUNT: "endIndCount",
    BEGIN_IND_COUNT: "beginIndCount",
    RECONNECT_SOCKET: "reconnectSocket",
    CLIENT_TYPE: "clientType",
    CONNECTION_ENDED: "connectionEnded",
    GSW_CLIENT_TIMEOUT: "GSWclientTimeout",
    READ_CHAR: "readChar",
    INVALID_REGISTRATION: "invalidRegistration",
    CONTROL_EVENT: "controlEvent",
    EXIT_CODE_200: "exitCode200",
    EXIT_CODE_100: "exitCode100",
    EXIT_CODE_255: "EXIT_CODE_255",
    NORMAL_EXIT: "normalExit",
    WINDOWS_UN: "WindowsUN",
    INACTIVITY_TIMEOUT: "inactivityTimeout",
    RECONNECT_TO_PROCESS: "reconnectToProcess",
    NUMBER_OF_SESSIONS: "session",
    TOTAL_SESSIONS: "total",
    HIGHEST_USER_COUNT: "maxUsersCount",
    LAST_DATE: "lastDate",
    FIRST_DATE: "firstDate",
    LOCATION_FILE_NAME: "fileName",
    LOCATION_LINE_NUMBER: "lineNumber",
    DISCONNECT_OR_ERROR: "disconnectOrError",
    PID_DATA: "pidData",
    STATE: "state",
    STATE_ERRORS: "stateErrors",
    SUSPEND_SESSION_COUNT: 'suspendSessionCount',
    ZERO_BYTES: "zeroBytes",
    WINSOCK: "winsock",
    WINSOCK_995: "winsock995",
    CONNECTION_TYPE: "connectionType",
    CONNECTION_TYPE_CONTROL: "control",
    CONNECTION_TYPE_DEVICE: "device",
    CONNECTION_TYPE_UNKNOWN: "unknown",
    LAST_MESSAGE: "message",
    PID_FOUND_PER_EVENTS_LOG: "pidFound",
    //USERS_COUNT: "usersCount",
    //LOGON_ATTEMPT: "logonAttempt",
    //INVALID_CREDENTIALS: "invalidCredentials",
    //NOT_FOUND_VARS: "notFoundVars",
    //FOUND_VARS: "foundVars",
    //ENV_VAR_REPLACED: "envVarReplaced",
    //IP_PEERS: "ipPeers",
    //IP_VALIDATE: "ipValidate",
    //LOAD_USERS_PREFERENCES_ATTEMPT: "loadUsersPreferencesAttempt",
    //NEW_SHELL_PROCESS: "NewShellProcess",
    //CMD_LINE: "cmdLine",
    //OPEN_PROCESS_FAILED: "openProcessFailed",
    //TERMINATION_STRING_NOT_FOUND: "terminationStringNotFound",
    //VERSION_NOTIFICATION: "versionNotificatoin",
    //CLIENT_MAC: "clientMac",
    //HOSTNAME: "hostname",
    //ALREADY_LOADED: "alreadyLoaded",
    //UNABLE_TO_RESLOVE_IP: "unableToResolveIP",
    //INVALID_IP6: "invalidIP6",
    //PUT_XFER_EVENT: "PUTxferEvent",
    //EXEC_XFER_EVENT: "EXECxferEvent",
    //FILE_SIZE: "fileSize",
    //PUT_COMPRESSION: "putCompression",
    //GET_COMPRESSION: "getCompression",
    //PRINT_JOB: "printJob",
    //CLEAN_SHELL_PROCESS: "cleanShellProcess",
    //USERNAME_START_WITH: "usernameStartWith",
    //CLIENT_STRING_RECEIVED: "clientStringReceived",
    //OPTIONS: "OPTIONS",
    //USER_FAILED_TO_LOGON: "userFailedToLogon",
    //SECURITY_SUBSYSTEM: "securitySubSystem",
    //RESUME: "RESUME",
    //COULD_NOT_OPEN: "couldNotOpen",
    //PRINT_INIT: "printInit",
    //PRINT_ABOUT_TO_EXCUTE: "PrintAboutToExecute",

};

export default utsAgent;