import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { rgba } from 'polished';
import ChatRooms from './ChatRooms';
import ChatUsers from './ChatUsers';
import ChatRoomCard from './ChatRoomCard';
import ChatRoomDetails from './ChatRoomDetails';
import Messages from './Messages';
import ChatInput from './ChatInput';
import USER_TYPE from 'constants/userType';
import ChatRoomsManage from './ChatRoomsManage';
import { imgURL } from 'utils/http';
import useAuthorization from 'hooks/useAuthorization';
import useAuth from 'hooks/useAuth';

const Wrapper = styled(Box)`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas:
    'left header right'
    'left messages right'
    'manage input right';
    gap: 10px;
    padding: 10px;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: ${props => props.$showRoomList ? '250px' : 'auto'} 1fr auto;
`

const ChatRoomHeaderContainer = styled(Box)`
    grid-area: header;
    background-color: ${(props) => props.theme.palette.background.paper};
    border: 1px solid ${(props) => rgba(props.theme.palette.text.secondary, 0.2)};
    min-width: 0px;
`
const ChatRoomsContainer = styled(Box)`
    grid-area: left;
    background-color: ${(props) => props.theme.palette.background.paper};
    border: 1px solid ${(props) => rgba(props.theme.palette.text.secondary, 0.2)};
`
const ChatRoomMessagesContainer = styled(Box)`
    min-height: 200px;
    grid-area: messages;
    background-color: ${(props) => props.theme.palette.background.paper};
    border: 1px solid ${(props) => rgba(props.theme.palette.text.secondary, 0.2)};
`
const ChatRoomMessageInputContainer = styled(Box)`
    grid-area: input;
    background-color: ${(props) => props.theme.palette.background.paper};
`

const ChatRoomInfosContainer = styled(Box)`
    grid-area: right;
    background-color: ${(props) => props.theme.palette.background.paper};
`

const RoomSelect = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`
const ChatRoomsManagement = styled(Box)`
    grid-area: manage;
    background-color: ${(props) => props.theme.palette.background.paper};
    border: 1px solid ${(props) => rgba(props.theme.palette.text.secondary, 0.2)};
`


const Desktop = ({ roomID, updateSelectedRoom, showRoomList = true, showRoomDetails = true, showRoomHeader = true }) => {
    const [showUserProfile, setShowUserProfile] = useState(false);
    const [isShowUsersMode, setIsShowUsersMode] = useState(false);
    const { isAuthorized } = useAuthorization();
    const { type } = useAuth();
    const canWriteChatMessages = isAuthorized("chatMessage.write");
    const canViewDeviceProfile = isAuthorized("page.deviceDetails");
    const users = useSelector((state) => state.chat.users);
    const onlineUsers = useSelector((state) => state.chat.onlineUsers);
    const [isManageChatRoomsOpen, setIsManageChatRoomsOpen] = useState(false);
    const onlineUsersMap = useMemo(() => onlineUsers.reduce((a, c) => ({ ...a, [c]: true }), {}), [onlineUsers]);
    const usersMap = useMemo(() => users.reduce((a, c) => ({
        ...a,
        [c._id]: {
            ...c,
            isOnline: !!onlineUsersMap[c._id],
            profilePicture: c.type === USER_TYPE.DEVICE ? ("/static/img/avatars/android.png") :
                (c.imageUrl ? imgURL + c.imageUrl : "/static/img/avatars/admin.png")
        }
    }), {}), [users, onlineUsersMap]);
    const chatRooms = useSelector(state => state.chat.chatRooms);
    const selectedRoom = useMemo(() => chatRooms.find(el => el._id === roomID), [roomID, chatRooms]);
    const toggleShowProfile = () => {
        setShowUserProfile(showUserProfile => !showUserProfile);
    }
    const selectedRoomParticipants = useMemo(() => {
        if (!selectedRoom) return []
        return selectedRoom.participants
            .filter(el => usersMap[el])
            .map(el => usersMap[el]);
    }, [usersMap, selectedRoom]);
    const selectedRoomParticipantsMap = useMemo(() => {
        if (!selectedRoom) return {}
        return selectedRoom.participants.reduce((a, c) => ({ ...a, [c]: true }), {});
    }, [selectedRoom]);
    const devices = useSelector(state => state.devices);
    const nDvicesSupportHighPriority = useMemo(() => {
        return devices.filter(device => selectedRoomParticipantsMap[device.androidID] &&
            device.gswcb_version &&
            parseInt(device.gswcb_version.split(".")[0]) >= 2 &&
            parseInt(device.gswcb_version.split(".")[1]) >= 9 &&
            parseInt(device.gswcb_version.split(".")[2]) >= 134).length;
    }, [devices, selectedRoomParticipantsMap]);
    return <Wrapper $showRoomList={showRoomList}>
        {showRoomList && <ChatRoomsContainer>
            {isShowUsersMode || chatRooms.length === 0 ? <ChatUsers updateSelectedRoom={updateSelectedRoom} closeUserMode={() => setIsShowUsersMode(false)} /> : <ChatRooms updateSelectedRoom={updateSelectedRoom} usersMap={usersMap} roomID={selectedRoom?._id} />}
        </ChatRoomsContainer>}
        {showRoomList && <ChatRoomsManagement>
            <ChatRoomsManage
                isManageChatRoomsOpen={isManageChatRoomsOpen}
                setIsManageChatRoomsOpen={setIsManageChatRoomsOpen}
                isShowUsersMode={isShowUsersMode}
                setIsShowUsersMode={setIsShowUsersMode}
                roomID={selectedRoom?._id}
            />
        </ChatRoomsManagement>}
        {showRoomHeader && selectedRoom && <ChatRoomHeaderContainer>
            <ChatRoomCard participants={selectedRoomParticipants} room={selectedRoom} onClick={toggleShowProfile} />
        </ChatRoomHeaderContainer>}
        <ChatRoomMessagesContainer>
            {selectedRoom ? (
                <Messages {...selectedRoom} participants={selectedRoomParticipants} />
            ) : (<RoomSelect>
                <Typography variant="h6">Select a ChatRoom</Typography>
            </RoomSelect>)}
        </ChatRoomMessagesContainer>
        <ChatRoomMessageInputContainer>
            {canWriteChatMessages && selectedRoom && <ChatInput roomID={selectedRoom._id} nDvicesSupportHighPriority={nDvicesSupportHighPriority} />}
        </ChatRoomMessageInputContainer>
        {showRoomDetails && <ChatRoomInfosContainer>
            {showUserProfile && selectedRoom && <ChatRoomDetails
                participants={selectedRoomParticipants}
                room={selectedRoom}
                close={toggleShowProfile}
                showDeviceProfileLink={canViewDeviceProfile && type === USER_TYPE.ADMIN}
            />}
        </ChatRoomInfosContainer>}
    </Wrapper>
}
export default Desktop;
