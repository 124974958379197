const UtsActionTypes = {
    UPDATE_UTS_AGENT_LOG_EVENTS: "UPDATE_UTS_AGENT_LOG_EVENTS",
    NEW_UTS_AGENT_LOG_EVENT: "NEW_UTS_AGENT_LOG_EVENT",
    NEW_UTS_AGENT_LOG_EVENTS: "NEW_UTS_AGENT_LOG_EVENTS",
    UPDATE_AGENT_ANALYSIS: "UPDATE_AGENT_ANALYSIS",
    UPDATE_UTS_EVENTS: "UPDATE_UTS_EVENTS",
    UPDATE_UTS_DATES: "UPDATE_UTS_DATES",
    UPDATE_UTS_STATS: "UPDATE_UTS_STATS",
    NEW_UTS_EVENT: "NEW_UTS_EVENT",
    NEW_UTS_EVENTS: "NEW_UTS_EVENTS",
    
}

export default UtsActionTypes;