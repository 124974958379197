import moment from 'moment';
import utsAgent from 'utils/utsAgent';
import UTS_AGENT from 'constants/utsAgent';
import UtsActionTypes from "./uts.types";
const initialState = {
    agentLogs: [],
    agentAnalysis: {},
    events: [],
    stats: {},
    timeFilter: "YYYY-MMM-DD-HH-00-00",
    timeUnit: 'hour',
    startDate: moment().subtract(1, "days").endOf("day").toISOString()
};
const MAX_LOGS_SIZE = 1000;
const utsReducer = (state = initialState, action) => {
    switch (action.type) {
        case UtsActionTypes.UPDATE_UTS_AGENT_LOG_EVENTS:
            return { ...state, agentLogs: action.payload };
        case UtsActionTypes.NEW_UTS_AGENT_LOG_EVENT: {
            try {
                const event = action.payload;
                const { orgMsg } = event;
                delete event.orgMsg;
                const _agentAnalysis = { ...state.agentAnalysis };
                utsAgent.parseUtsEvent(orgMsg, _agentAnalysis);
                return {
                    ...state,
                    agentAnalysis: _agentAnalysis,
                    agentLogs: [event, ...state.agentLogs].slice(0, MAX_LOGS_SIZE)
                };
            } catch (error) {
                console.log("error parsing event")
                return state;
            }
        }
        case UtsActionTypes.NEW_UTS_AGENT_LOG_EVENTS: {
            try {
                const _agentLogs = [...state.agentLogs];
                const _agentAnalysis = {...state.agentAnalysis};
                const events = action.payload;
                utsAgent.parseUtsEvents(events, _agentAnalysis);
                Array.from(_agentAnalysis[UTS_AGENT.PID_FOUND_PER_EVENTS_LOG] || []).forEach(pid => {
                    utsAgent.updateState(_agentAnalysis[UTS_AGENT.PID_DATA][pid]);
                })
                events.forEach(event => {
                    delete event.orgMsg;
                    _agentLogs.unshift(event);
                })
                return {
                    ...state,
                    agentAnalysis: _agentAnalysis,
                    agentLogs: _agentLogs.slice(0, MAX_LOGS_SIZE)
                };
            } catch (error) {
                console.log("error parsing event")
                return state;
            }
        }
        case UtsActionTypes.UPDATE_AGENT_ANALYSIS:
            return {
                ...state,
                agentAnalysis: action.payload
            }

        case UtsActionTypes.UPDATE_UTS_EVENTS:
            return { ...state, events: action.payload }
        case UtsActionTypes.UPDATE_UTS_STATS:
            return { ...state, stats: action.payload }
        case UtsActionTypes.NEW_UTS_EVENT:
            return { ...state, events: [action.payload, ...state.events].slice(0, MAX_LOGS_SIZE) }
        case UtsActionTypes.NEW_UTS_EVENTS: {
            const _stats = { ...(state.stats || {}) };
            action.payload.forEach(el => {
                if (!el.properties) return;
                const sessionID = `${el.properties.sessionID}`;
                const key = moment(el.date).format(state.timeFilter);
                if (!_stats[key]) {
                    const nIntervals = moment().diff(moment(state.startDate), state.timeUnit === "hour" ? 'hours' : "days");
                    for (let i = 0; i <= nIntervals; i++) {
                        const interval = moment(state.startDate).add(i, state.timeUnit === "hour" ? 'hours' : "days");
                        const date = interval.format(state.timeFilter);
                        if (!_stats[date]) {
                            _stats[date] = {
                                date: interval.toDate().toISOString(),
                                closedSessions: 0,
                                gswClients: 0,
                                thridPartyClients: 0,
                                nSessions: 0,
                                sessions: {}
                            }
                        }
                    }
                    if (!_stats[key]) {
                        _stats[key] = {
                            date: el.date,
                            closedSessions: 0,
                            closedSessionsAbnormally: 0,
                            gswClients: 0,
                            thridPartyClients: 0,
                            nSessions: 0,
                            sessions: {}
                        }
                    }
                }
                if ([4, 5].includes(el.properties.eventID)) {
                    _stats[key].closedSessions++;
                }
                if (el.properties.eventID === 5) {
                    _stats[key].closedSessionsAbnormally++;
                }
                if (el.properties.clientType === 1) _stats[key].gswClients++;
                if (el.properties.clientType === 0) _stats[key].thridPartyClients++;
                if ([1, 2].includes(el.properties.clientType)) {
                    _stats[key].sessions[sessionID] = true
                    _stats[key].nSessions = Object.keys(_stats[key].sessions).length;
                }
            })
            return {
                ...state,
                stats: _stats,
                events: [...action.payload, ...state.events].slice(0, MAX_LOGS_SIZE)
            }
        }
        case UtsActionTypes.UPDATE_UTS_DATES:
            return {
                ...state,
                timeFilter: action.payload.timeFilter,
                timeUnit: action.payload.timeUnit,
                startDate: action.payload.startDate,
            }
        default:
            return state;
    }
}
export default utsReducer;
