import React,
{
    useState,
    useCallback
} from 'react';
import {
    Grid2
} from '@mui/material';
import BadgesList from './BadgesList'
import CreateBadge from './CreateBadge';
import EditBadge from './EditBadge';
import UsersBadgesEvents from './UsersBadgesEvents';
import Authorized from 'components/Authorized';

function Badges() {
    const [selectedBadge, setSelectedBadge] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const handleUpdateIsCreating = (isCreating) => {
        setIsCreating(isCreating);
        if (isCreating) setSelectedBadge(null);
    }
    const handleUpdateSelectBadge = useCallback((selected) => {
        setSelectedBadge(selected);
        if (selected) setIsCreating(false);
    }, [])
    return <Grid2 container spacing={6}>
        <Grid2 size={12}>
            <Authorized permission="badge.create">
                {!selectedBadge && <CreateBadge isCreating={isCreating} handleUpdateIsCreating={handleUpdateIsCreating} />}
            </Authorized>
        </Grid2>
        <Grid2 size={12}>
            <Authorized some={["badge.update", "badge.delete"]}>
                {selectedBadge && <EditBadge {...selectedBadge} close={() => handleUpdateSelectBadge(null)} />}
            </Authorized>
        </Grid2>
        <Grid2 size={12}>
            <BadgesList handleUpdateSelectBadge={handleUpdateSelectBadge} />
        </Grid2>
        <Grid2 size={12}>
            <Authorized permission="event.read">
                <UsersBadgesEvents />
            </Authorized>
        </Grid2>
    </Grid2>
}

export default Badges;
