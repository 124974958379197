import React from 'react';
import { useRoutes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { create } from 'jss';
import 'react-toastify/dist/ReactToastify.css';
import { LicenseInfo } from '@mui/x-license-pro';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import StylesProvider from '@mui/styles/StylesProvider';
import jssPreset from '@mui/styles/jssPreset';
import URLValidator from 'components/URLValidator';
import PageTitle from 'components/PageTitle';
import AppSharedStateReduxLoader from 'components/AppSharedStateReduxLoader';
import ToastContainerThemed from 'components/ToastContainerThemed';
import PerformanceMeasurement from 'components/PerformanceMeasurement';
import './i18n';
import createTheme from './theme';
import routes from './routes';
import useTheme from 'hooks/useTheme';
import { AuthProvider } from 'contexts/JWTContext';
import { PreferencesProvider } from 'contexts/PreferencesContext';
import { AuthorizationProvider } from 'contexts/AuthorizationContext';
import URLMonitor from 'components/URLMonitor';




LicenseInfo.setLicenseKey("29dac70ba6455309604d9e608d303c4dTz05ODY2MCxFPTE3NTg3MjUzMzIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==");

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});
function App() {
  const content = useRoutes(routes);
  const { theme } = useTheme();
  return (
    <React.Fragment>
      <HelmetProvider>
        <StylesProvider jss={jss}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={createTheme(theme)}>
                <ToastContainerThemed />
                <AuthProvider>
                  <AuthorizationProvider>
                    <PerformanceMeasurement />
                    <URLMonitor />
                    <PreferencesProvider>
                      <URLValidator>
                        <PageTitle />
                        <AppSharedStateReduxLoader />
                        {content}
                      </URLValidator>
                    </PreferencesProvider>
                  </AuthorizationProvider>
                </AuthProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </StylesProvider>
      </HelmetProvider>
    </React.Fragment>
  );
}

export default App;
