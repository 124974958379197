import React, { useState, useEffect, useMemo } from "react";
import { styled } from '@mui/system';
import { spacing } from "@mui/system";
import { Send as SendIcon } from '@mui/icons-material';
import {
    Card as MuiCard,
    CardContent,
    Grid2,
    Typography,
    TextField as MuiTextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Alert,
    CardHeader
} from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { http } from "utils/http";
import LoadingButton from '@mui/lab/LoadingButton';
import ShareIcon from '@mui/icons-material/Share';
import EmailIcon from '@mui/icons-material/Email';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isValidEmail } from "utils/validation";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);


const AlertInviteLink = styled(Alert)`
  overflow-x: auto; 
  width: calc(100vw - 200px);
  ${(props) => props.theme.breakpoints.up("lg")} {
    width: calc(100vw - 200px);
  }
  ${(props) => props.theme.breakpoints.down("lg")} {
    width: calc(100vw - 80px);
  }
`
const InviteUsers = () => {
    const admins = useSelector((state) => state.admins.admins || []);
    const emails = useMemo(() => admins.map(admin => admin.email) || [], [admins]);
    const [method, setMethod] = useState('link');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [fetching, setFetching] = useState(false);
    useEffect(() => {
        let err = ''
        if (email && !isValidEmail(email)) err = 'Invalid email';
        if (emails.includes(email)) err = 'email already exist';
        setIsValid(email && !err);
        setError(err);
        setMessage('');
    }, [email, emails]);
    const handleInvitateAdmin = async () => {
        setFetching(true);
        setMessage('');
        setError('');
        try {
            const resp = await http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/invite-admin`, {
                email,
                method
            })
            setFetching(false);
            if (resp.data) {
                if (resp.data.message) return setMessage(resp.data.message);
                setMessage("Invitation sent");
                toast.success("Invitation sent");
            }
        } catch (err) {
            // Handle Error Here
            setFetching(false);
            if (err && err.response && err.response.data && err.response.data.message) {
                setError(err.response.data.message);
                return toast.error(err.response.data.message);
            }
            console.error("error", err);
            setError("Something went wrong");
            toast.error("Something went wrong");
        }
    }
    const handleUpdateMethod = (e) => {
        setMethod(e.target.value);
        setError('');
        setMessage('');
    }
    return <Card sx={{ mb: 3 }} title="Invite an Admin">
        <CardHeader title="Invite an Admin" />
        <CardContent>
            <Grid2 container spacing={2}>
                <Grid2 size={12} container spacing={6} alignItems="center">
                    <Grid2 size={{ xs: 12, md: 'auto' }}>
                        <TextField
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                            disabled={fetching}
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 'auto' }}>
                        <FormControl>
                            <RadioGroup
                                value={method}
                                name="invite-method-group"
                                row
                                onChange={handleUpdateMethod}
                            >
                                <FormControlLabel value="link" control={<Radio />} label={<Typography variant="body1">By Link <ShareIcon color="primary" /></Typography>} />
                                <FormControlLabel value="email" control={<Radio />} label={<Typography variant="body1">By Email <EmailIcon color="primary" /></Typography>} />
                            </RadioGroup>
                        </FormControl>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 'auto' }}>
                        <LoadingButton
                            loading={fetching}
                            disabled={!isValid}
                            loadingPosition="end"
                            endIcon={method === 'link' ? <AddLinkIcon /> : <SendIcon />}
                            variant="contained"
                            size="small"
                            onClick={handleInvitateAdmin}
                        >
                            {method === 'link' ? "Generate Link" : "Send Email"}
                        </LoadingButton>
                    </Grid2>
                </Grid2>
                <Grid2 size={12}>
                    {error && <Alert severity="error">{error}</Alert>}
                    {message && <AlertInviteLink severity="success">{message} </AlertInviteLink>}
                    {message && method === 'link' && <CopyToClipboard text={message} onCopy={() => toast.success("Link Copied to clipboard")}>
                        <Typography variant="body1" color="primary"><ContentCopyIcon role="button" color="primary" /> Copy</Typography>
                    </CopyToClipboard>}
                </Grid2>
            </Grid2>
        </CardContent>
    </Card>
}
export default InviteUsers;