import React, { useMemo } from 'react';
import {
    Box,
} from "@mui/material";
import { Marker, Circle, Popup as leafletPopup } from 'react-leaflet'
import { DeviceGroupMarker, DeviceMarker } from './Icons/Icons';
import { styled } from '@mui/system';
const Popup = styled(leafletPopup)`
.leaflet-popup-content-wrapper{
    background-color: ${props => props.theme.palette.background.paper};
    color: ${props => props.theme.palette.text.primary};
}
.leaflet-popup-tip {
    background-color: ${props => props.theme.palette.background.paper};
    color: ${props => props.theme.palette.text.primary};
}
.leaflet-popup-content{ 
    color: ${props => props.theme.palette.text.primary};
}
`;

const getGroupMarker = (nElements) => {
    if (nElements > 9) return DeviceGroupMarker[10];
    return DeviceGroupMarker[nElements];
}
const fillBlueOptions = { fillColor: 'blue' }
const renderLables = (labels) => {
    return (
        <Box sx={{
            maxHeight: 300,
            overflow: 'auto',
        }}>
            {labels.map((label, index) => (
                <Box
                    key={index}
                    sx={{
                        mb: 2,
                        mx: 2,
                        border: '1px solid gray',
                        borderRadius: 3,
                        padding: 3,
                    }}
                >
                    {label}
                </Box>))}
        </Box>
    )
}

const MarkersGroup = ({ devices, showRadius, disableGrouping, zoom }) => {
    const deviceGroups = useMemo(() => {
        const round = (val) => {
            const mul = Math.pow(2, 5 - zoom);
            return `${(mul * Math.round(parseFloat(val / mul))).toFixed(8)}`;
        }
        const devicesMap = {};
        devices.forEach(el => {
            const rLat = disableGrouping ? `${el.lat}` : round(el.lat);
            const rLon = disableGrouping ? `${el.lon}` : round(el.lon);
            if (!devicesMap[`${rLat}-${rLon}`]) devicesMap[`${rLat}-${rLon}`] = {
                lat: el.lat,
                lon: el.lon,
                radius: el.radius || 0,
                devices: [],
                labels: [],
            }
            devicesMap[`${rLat}-${rLon}`].radius = Math.max(0, el.radius || 0, devicesMap[`${rLat}-${rLon}`].radius);
            devicesMap[`${rLat}-${rLon}`].devices.push(el);
            devicesMap[`${rLat}-${rLon}`].labels.push(el.label);
        });
        return Object.values(devicesMap).sort((a, b) => b.devices.length - a.devices.length);
    }, [disableGrouping, zoom, devices]);
    return deviceGroups.map((group, index) => {
        return (
            <React.Fragment key={index}>
                {showRadius && <Circle center={[group.lat, group.lon]} radius={group.radius} pathOptions={fillBlueOptions} />}
                <Marker position={[group.lat, group.lon]} icon={group.labels.length > 1 ? getGroupMarker(group.labels.length) : DeviceMarker} onClose={(e) => console.log("e", e)}>
                    <Popup>
                        {group.labels.length > 1 ? renderLables(group.labels) : group.labels[0]}
                    </Popup>
                </Marker>
            </React.Fragment>
        )
    })
}
export default MarkersGroup;