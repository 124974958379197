import React from "react";
import { matchPath } from "react-router-dom";

import SidebarNavListItemMinimized from "./SidebarNavListItemMinimized";
import SidebarNavListMinimized from "./SidebarNavListMinimized";

const reduceChildRoutesMinimized = (props) => {
  const { items, page, depth, currentRoute } = props;
  const _items = [...items];
  if (page.children) {
    const open = page.href
      ? !!matchPath(
        {
          path: page.href,
          end: false,
        },
        currentRoute
      )
      : false;

    _items.push(
      <SidebarNavListItemMinimized
        depth={depth}
        icon={page.icon}
        key={`${page.title}-${page.href}`}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
      >
        <SidebarNavListMinimized depth={depth + 1} pages={page.children} />
      </SidebarNavListItemMinimized>
    );
  } else {
    _items.push(
      <SidebarNavListItemMinimized
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={`${page.title}-${page.href}`}
        badge={page.badge}
        title={page.title}
      />
    );
  }

  return _items;
};

export default reduceChildRoutesMinimized;
