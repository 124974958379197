import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Loader from 'components/Loader';
import useAuth from 'hooks/useAuth';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import USER_TYPE from 'constants/userType';
import pagesSettings from 'constants/pagesSettings';

const GUEST_REGEX = /^\/(overview)|(client\/chat)|(auth\/(validate-account)|(update-email)|(sign-in)|(sign-up)|(forgot-password)|(reset-password)|(request-demo)|(confirm-email))|(docs)/i;
const DEVICE_REGEX = /\/client\/chat/i;
const ADMIN_ACCOUNT_SETUP_REGEX = /^\/(overview)|(setup)|(docs)/i;
const ADMIN_INVALID_REGEX = /^\/(overview)|(setup)|(client\/chat)|(auth\/(validate-account)|(sign-in)|(sign-up)|(forgot-password)|(reset-password)|(request-demo))|(support)/i;
const ADMIN_DEMO_INVALID_REGEX = /^\/(overview)|(setup)|(client\/chat)|(auth\/(validate-account)|(sign-in)|(sign-up)|(forgot-password)|(reset-password)|(request-demo))|(support)|(confirm-email)/i;
const SUPPORT_REGEX = /^\/(support)|(docs)/i;


const getUserType = ({ isAuthenticated, type }) => {
    if (!type && isAuthenticated) console.error("type is unkown", type);
    if (!isAuthenticated) return USER_TYPE.GUEST;
    if (USER_TYPE[type]) return type;
    return USER_TYPE.UNKNOWN;
}


const getRedirectUrlForUser = (oldPath, type, redirectUrl = '/') => {
    switch (type) {
        case USER_TYPE.GUEST:
            return "/overview"
        case USER_TYPE.DEVICE:
            return "/client/chat";
        case USER_TYPE.ADMIN_ACCOUNT_SETUP:
            if (/^\/auth\/validate-account/i.test(oldPath)) return "/setup";
            return "/overview"
        case USER_TYPE.ADMIN:
            return redirectUrl;
        case USER_TYPE.ADMIN_DEMO:
            return "/"
        case USER_TYPE.SUPPORT:
            return "/support";
        default:
            return "/";
    }
}

const isvalidPathForUser = (path, type, regexs = []) => {
    switch (type) {
        case USER_TYPE.GUEST:
            return GUEST_REGEX.test(path)
        case USER_TYPE.DEVICE:
            return DEVICE_REGEX.test(path);
        case USER_TYPE.ADMIN_ACCOUNT_SETUP:
            return ADMIN_ACCOUNT_SETUP_REGEX.test(path)
        case USER_TYPE.ADMIN:
            return ![ADMIN_INVALID_REGEX, ...regexs].some(regex => regex.test(path))
        case USER_TYPE.ADMIN_DEMO:
            return !ADMIN_DEMO_INVALID_REGEX.test(path)
        case USER_TYPE.SUPPORT:
            return SUPPORT_REGEX.test(path);
        default:
            return false
    }
}


const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: ${(props) => props.theme.palette.background.default};
`;
const LoadingPage = () => {
    return <Container>
        <Loader />
    </Container>
}

const URLValidator = (props) => {
    const { type: _type, isAuthenticated, isInitialized, permissions } = useAuth();
    const location = useLocation();
    const { adminInvalidPathsRegex, redirectUrl } = useMemo(() => {
        if (!permissions) return {
            adminInvalidPathsRegex: [],
            redirectUrl: '/',
        };
        const pagePermissionsMap = permissions
            .filter(permission => /.*\.page\..*/.test(permission))
            .map(pagePermission => pagePermission.split(".").pop())
            .reduce((p, c) => ({ ...p, [c]: true }), {});

        const invalidPages = [];
        const validPages = [];
        pagesSettings.forEach(page => {
            if (pagePermissionsMap[page.requiredPermission]) validPages.push(page);
            else invalidPages.push(page);
        });
        return {
            adminInvalidPathsRegex: invalidPages.map(el => el.regex),
            redirectUrl: validPages[0]?.href || '/'
        }
    }, [permissions])
    if (!isInitialized) return <LoadingPage />
    const type = getUserType({ isAuthenticated, type: _type });
    if (type === USER_TYPE.UNKNOWN) {
        console.error("unkown type of user", type);
        //setTimeout(signOut, 10);
        return "Unknow Type of user";
    }
    const currentPath = location.pathname;
    if (!isvalidPathForUser(currentPath, type, adminInvalidPathsRegex)) {
        const url = getRedirectUrlForUser(currentPath, type, redirectUrl);
        if (currentPath !== url) return <Navigate to={url} />;
    }
    return props.children;
}
export default URLValidator;

