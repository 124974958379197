import React, { useState } from 'react';
import { styled } from '@mui/system';
import {
    Card as MuiCard,
    CardContent,
    Button as MuiButton,
    Grid2,
    TextField as MuiTextField,
    Typography,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { http } from "utils/http";
import { spacing } from "@mui/system";
import useAuth from "hooks/useAuth";
import { toast } from 'react-toastify';
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
const DeleteAccount = () => {
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [password, setPassword] = useState('');
    const [aggreed, setAggreed] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState('');
    const { signOut } = useAuth();
    const handleCancelDelete = () => {
        setAggreed(false);
        setPassword('');
        setDeleteConfirmation(false);
        setError('');
    }
    const handleMyAccountDelete = async () => {
        setError('');
        setFetching(true);
        try {
            const resp = await http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/delete-me`, {
                password
            })
            setFetching(false);
            if (resp.data && resp.data.success) {
                console.log("resp", resp.data);
                signOut();
            }
        } catch (err) {
            // Handle Error Here
            setFetching(false);
            if (err && err.response && err.response.data && err.response.data.message) {
                toast.error(err.response.data.message);
                return setError(err.response.data.message);
            }
            console.error("error", err);
            toast.error("Something went wrong");
            setError("Something went wrong");
        }
    }
    return <Card sx={{ mb: 6 }}>
        <CardContent>
            <Typography variant="h6" gutterBottom>
                Delete My Account
            </Typography>
            <Grid2 container spacing={6}>
                {!deleteConfirmation ?
                    (
                        <Grid2 size={12}>
                            <Button onClick={() => setDeleteConfirmation(true)} variant="contained" color="error">
                                Delete My Account
                            </Button>
                        </Grid2>
                    ) :
                    (
                        <React.Fragment>
                            <Grid2 size={12} sx={{ mt: 3 }}>
                                <Typography variant="body" gutterBottom>
                                    Deleting your account means that you'll lose all the data and files and it is impossible to recover
                                </Typography>
                            </Grid2>

                            <Grid2 size={4}>
                                <TextField
                                    label="Type your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <FormControlLabel
                                    control={<Checkbox onChange={(e) => setAggreed(e.target.checked)} checked={aggreed} color="primary" />}
                                    label="I understand and want to delete My account"
                                />
                                <Button onClick={handleMyAccountDelete} sx={{ mx: 1 }} disabled={!aggreed || password.length < 6 || fetching} variant="contained" color="error">
                                    Yes
                                </Button>
                                <Button sx={{ mx: 1 }} onClick={handleCancelDelete} variant="contained" color="primary">
                                    No
                                </Button>
                            </Grid2>
                            <Grid2 size={12}>
                                {error && <Typography variant="body" color="error" gutterBottom>
                                    {error}
                                </Typography>}
                            </Grid2>
                        </React.Fragment>

                    )}
            </Grid2>
        </CardContent>
    </Card>
}
export default DeleteAccount;