import React, { useState } from "react";
import { styled } from '@mui/system';
import {
  Grid2,
  Card as MuiCard
} from "@mui/material";
import { spacing } from "@mui/system";
import DeviceList from "./DeviceList";
import RTTMap from "./RTTMap";

const Card = styled(MuiCard)(spacing);

const Map = ({ rttSettings, groupDevices, fullScreenModeEnabled }) => {
  const [mapDevices, setMapDevices] = useState({});
  return (
    <React.Fragment>
      <Grid2 container spacing={6}>
        <Grid2 size={{ xs: 12, lg: fullScreenModeEnabled ? 12 : 8 }}>
          <RTTMap
            rttSettings={rttSettings}
            mapDevices={mapDevices}
            groupDevices={groupDevices}
          />
        </Grid2>
        {!fullScreenModeEnabled && <Grid2 size={{ xs: 12, lg: 4 }}>
          <Card sx={{ maxHeight: "100vh", overflow: 'auto' }}>
            <DeviceList
              rttSettings={rttSettings}
              mapDevices={mapDevices}
              updateMapDevices={setMapDevices}
              groupDevices={groupDevices}
            />
          </Card>
        </Grid2>}
      </Grid2>
    </React.Fragment>
  );
}

export default Map;
