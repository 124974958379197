import { useMemo } from "react";
import { Circle, Pane, Popup } from 'react-leaflet'
import { hsl } from 'polished'
const getFillColor = (intensity) => {
    const hue = parseInt(120 * intensity);
    return hsl(hue, 1, 0.5);
}

const getCirleRadius = (radius, zoom) => {
    return Math.min(Math.max(10, (radius || 10)), 30) * Math.pow(2, 16 - zoom);
}

const getFillOpacity = (nItems, AllItems, zoom) => {
    if (zoom < 16) return 1;
    return Math.min(0.9, Math.max(0.5, nItems / AllItems))
}
const getBlur = (zoom) => {
    return Math.min(5, Math.max(2, 18 - zoom));
}

const HeatLayer = ({ heatPoints, zoom }) => {
    const heatPointsGroups = useMemo(() => {
        const round = (val) => {
            const mul = 0.5 * Math.pow(2, 2 - zoom);
            return `${(mul * Math.round(parseFloat(val / mul))).toFixed(8)}`;
        }
        const heatPointsMap = {};
        heatPoints.forEach(el => {
            const rLat = round(el.lat);
            const rLon = round(el.lon);
            if (!heatPointsMap[`${rLat}-${rLon}`]) heatPointsMap[`${rLat}-${rLon}`] = {
                lat: el.lat,
                lon: el.lon,
                center: [el.lat, el.lon],
                radius: 0,
                intensity: 0,
                nItems: 0,
                label: '',
            }
            heatPointsMap[`${rLat}-${rLon}`].androidID = el.androidID; // override with last one
            heatPointsMap[`${rLat}-${rLon}`].date = el.date; // overide with last date
            heatPointsMap[`${rLat}-${rLon}`].name = el.name; // overide with last devicename
            const intensity = heatPointsMap[`${rLat}-${rLon}`].intensity;
            const radius = heatPointsMap[`${rLat}-${rLon}`].radius;
            const nItems = heatPointsMap[`${rLat}-${rLon}`].nItems;
            heatPointsMap[`${rLat}-${rLon}`].intensity = (intensity * nItems + el.intensity) / (nItems + 1);
            heatPointsMap[`${rLat}-${rLon}`].radius = (radius * nItems + el.radius || 1) / (nItems + 1);
            heatPointsMap[`${rLat}-${rLon}`].nItems++;
            heatPointsMap[`${rLat}-${rLon}`].label = el.label || '';
        });
        //console.log("heatPointsMap", heatPointsMap);
        return Object.values(heatPointsMap);
    }, [zoom, heatPoints]);
    return <><Pane key={getBlur(zoom)} name="heat-points-map-elements" style={{
        filter: `blur(${getBlur(zoom)}px)`,
        zIndex: 300,
    }}>
        {heatPointsGroups.map((el, index) => <Circle
            key={index}
            center={el.center}
            radius={getCirleRadius(el.radius, zoom)}
            pathOptions={{
                opacity: 0,
                fillColor: getFillColor(el.intensity),
                fill: true,
                fillOpacity: getFillOpacity(el.nItems, heatPoints.length, zoom),
                bubblingMouseEvents: false
            }}
        />)}</Pane>
        <Pane style={{
            zIndex: 301,
        }}>
            {heatPointsGroups.map((el, index) => <Circle
                key={index}
                center={el.center}
                radius={getCirleRadius(el.radius, zoom)}
                eventHandlers={{
                    mouseover: (event) => {
                        if (!event || !event.target || !event.target.setStyle) return;
                        event.target.setStyle({ fillOpacity: 0.1, opacity: 1 });
                    },
                    mouseout: (event) => {
                        if (!event || !event.target || !event.target.setStyle) return;
                        event.target.setStyle({ fillOpacity: 0, opacity: 0 });
                    }
                }}
                pathOptions={{
                    opacity: 0,
                    color: getFillColor(el.intensity),
                    weight: 1,
                    fillOpacity: 0,
                    fillColor: getFillColor(el.intensity),
                    bubblingMouseEvents: false,
                }}
            >
                {el.label && <Popup>
                    {el.label}
                </Popup>}
            </Circle>)}
        </Pane>
    </>
}
export default HeatLayer;