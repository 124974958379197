import * as React from 'react';
import {
    TextField,
    Box,
    Typography,
    Modal
} from '@mui/material';
import { useSelector, useDispatch } from "react-redux"
import { updateGlobalDecryptionPasswordAction } from 'redux/system/system.actions';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const EncryptionPasswordModal = ({ close, isOpen }) => {
    const globalPassword = useSelector((state) => state.system.decryptionPassword);
    const dispatch = useDispatch();
    const [password, setPassword] = React.useState(globalPassword);
    const handleClose = () => {
        dispatch(updateGlobalDecryptionPasswordAction({ password }))
        close(false);
    }
    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 4 }}>
                    Update decryption password
                </Typography>
                <TextField
                    label={"password"}
                    value={password || ''}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    type="password"
                    fullWidth
                />
            </Box>
        </Modal>
    );
}
export default EncryptionPasswordModal;
