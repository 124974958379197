import { ToastContainer } from "react-toastify";
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';

const ToastContainerStyled = styled(ToastContainer)`
--toastify-font-family: ${props => props.muiTheme.typography.fontFamily};
--toastify-color-progress-success: ${(props) => props.muiTheme.palette.primary.main};
--toastify-icon-color-success: ${(props) => props.muiTheme.palette.primary.main};
--toastify-text-color-success: ${(props) => props.muiTheme.palette.primary.main};
--toastify-color-success: ${(props) => props.muiTheme.palette.background.default};
--toastify-color-progress-error: ${(props) => props.muiTheme.palette.error.main};
--toastify-icon-color-error: ${(props) => props.muiTheme.palette.error.main};
--toastify-text-color-error: ${(props) => props.muiTheme.palette.error.main};
--toastify-color-error: ${(props) => props.muiTheme.palette.background.default};
--toastify-color-progress-warning: ${(props) => props.muiTheme.palette.warning.main};
--toastify-icon-color-warning: ${(props) => props.muiTheme.palette.warning.main};
--toastify-text-color-warning: ${(props) => props.muiTheme.palette.warning.main};
--toastify-color-warning: ${(props) => props.muiTheme.palette.background.default};
.Toastify__progress-bar--success{
  background-color: ${(props) => props.muiTheme.palette.primary.main}!important;
  background: ${(props) => props.muiTheme.palette.primary.main}!important;
}
.Toastify__progress-bar--error{
  background-color:${(props) => props.muiTheme.palette.error.main}!important;
  background:${(props) => props.muiTheme.palette.error.main}!important;
}
.Toastify__progress-bar--warning{
  background-color:${(props) => props.muiTheme.palette.warning.main}!important;
  background:${(props) => props.muiTheme.palette.warning.main}!important;
}
`;

const ToastContainerThemed = () => {
  const theme = useTheme();

  return <ToastContainerStyled muiTheme={theme} theme="colored"/>
}

export default ToastContainerThemed;