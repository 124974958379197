import GroupsActionTypes from "./groups.types"

const updateGroupsAction = (groups) => {
    return { type: GroupsActionTypes.UPDATE_GROUPS, payload: groups }
}
const newGroupAction = (group) => {
    return { type: GroupsActionTypes.NEW_GROUP, payload: group }
}
const removeGroupAction = (groupID) => {
    return { type: GroupsActionTypes.REMOVE_GROUP, payload: groupID }
}
const updateGroupAction = (group) => {
    return { type: GroupsActionTypes.UPDATE_GROUP, payload: group }
}

const updateSelectedGroup = (groupID) => {
    return { type: GroupsActionTypes.UPDATE_SELECTED_GROUP, payload: groupID }
}
export {
    updateGroupsAction,
    newGroupAction,
    removeGroupAction,
    updateGroupAction,
    updateSelectedGroup
}
