import React, { Suspense } from 'react';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
const icons = {
  AccessAlarm: React.lazy(() => import('@mui/icons-material/AccessAlarmOutlined')),
  Info: React.lazy(() => import('@mui/icons-material/InfoOutlined')),
  Code: React.lazy(() => import('@mui/icons-material/CodeOutlined')),
  ArrowCircleDown: React.lazy(() => import('@mui/icons-material/ArrowCircleDownOutlined')),
  ArrowCircleLeft: React.lazy(() => import('@mui/icons-material/ArrowCircleLeftOutlined')),
  ArrowCircleRight: React.lazy(() => import('@mui/icons-material/ArrowCircleRightOutlined')),
  ArrowCircleUp: React.lazy(() => import('@mui/icons-material/ArrowCircleUpOutlined')),
  VerifiedUser: React.lazy(() => import('@mui/icons-material/VerifiedUserOutlined')),
  Groups: React.lazy(() => import('@mui/icons-material/GroupsOutlined')),
  Person: React.lazy(() => import('@mui/icons-material/PersonOutlined')),
  Security: React.lazy(() => import('@mui/icons-material/SecurityOutlined')),
  Wifi: React.lazy(() => import('@mui/icons-material/WifiOutlined')),
  BatteryFull: React.lazy(() => import('@mui/icons-material/BatteryFullOutlined')),
  Email: React.lazy(() => import('@mui/icons-material/EmailOutlined')),
  Chat: React.lazy(() => import('@mui/icons-material/ChatOutlined')),
  Map: React.lazy(() => import('@mui/icons-material/MapOutlined')),
  Dangerous: React.lazy(() => import('@mui/icons-material/DangerousOutlined')),
  Error: React.lazy(() => import('@mui/icons-material/ErrorOutlined')),
  TimerOff: React.lazy(() => import('@mui/icons-material/TimerOffOutlined')),
  Timer: React.lazy(() => import('@mui/icons-material/TimerOutlined')),
  Article: React.lazy(() => import('@mui/icons-material/ArticleOutlined')),
}
const Loader = () => <HourglassTopOutlinedIcon />
const NotFound = () => <QuestionMarkOutlinedIcon color="error" />

const AsyncMuiIcon = ({ icon, ...otherProps }) => {
  const Component = icons[icon] || NotFound;
  return <Suspense fallback={<Loader />}>
    <Component {...otherProps} />
  </Suspense>
}


export default AsyncMuiIcon;
