import React, { useState } from "react";
import {
    Switch,
    Grid2,
    Typography,
    CircularProgress
} from "@mui/material";
import { toast } from 'react-toastify';
import { http } from "utils/http"

function SetRegistryString({ ip, port, isValid, isSzDashalyticsXUIDSet, legacyLads, onSuccess }) {
    const [loading, setLoading] = useState(false);
    const [szDashalyticsXUID, setSzDashalyticsXUID] = useState(isSzDashalyticsXUIDSet);
    const handleSetRegisteryString = async (e) => {
        const szDashalyticsXUID = e.target.checked;
        try {
            setLoading(true);
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/setup/set-registry-string`,
                {
                    ip,
                    port,
                    szDashalyticsXUID,
                    legacyLads
                });
            if(onSuccess) onSuccess();
            toast.success(resp.data.message || "updated");
            setLoading(false);
            setSzDashalyticsXUID(szDashalyticsXUID);
        } catch (err) {
            setLoading(false);
            if (err && err.response && err.response.data && err.response.data.message) {
                return toast.error(err.response.data.message);
            }
            console.error("error", err);
            toast.error("Something went wrong");
        }
    }
    return <Grid2 size={12} container alignItems='center' justifyContent="space-between" spacing={3}>
        <Grid2>
            <Typography variant="body1">
                Set DashalyticsID
            </Typography>
        </Grid2>
        <Grid2>
            {loading ? (
                <CircularProgress color="primary" size={30} />
            ) :
                (
                    <Switch
                        disabled={!isValid || loading}
                        checked={szDashalyticsXUID}
                        onChange={handleSetRegisteryString}
                        color="primary"
                    />
                )
            }
        </Grid2>
    </Grid2>
}

export default SetRegistryString;
