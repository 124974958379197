import React, {
    useState,
    useMemo,
    useEffect
} from "react";
import AlertsList from "./AlertsList";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import {
    Grid2,
    Grow,
    ClickAwayListener,
    IconButton,
    Typography
} from "@mui/material";
import AddAlertIcon from '@mui/icons-material/AddAlert';
import AlertView from './AlertView';
import AlertEdit from './AlertEdit';

const Alerts = ({ properties, setProperties, editing, setIsValid }) => {
    useEffect(() => {
        setIsValid(true);
    }, [setIsValid]);
    const [selectedAlertID, setSelectedAlertID] = useState(null);
    const apiRef = useGridApiRef();
    const handleClickAway = () => {
        if (!editing && apiRef.current && apiRef.current.setRowSelectionModel) {
            apiRef.current.setRowSelectionModel([])
        }
    }
    const selectedAlert = useMemo(() => {
        if (!selectedAlertID) return null;
        if (!properties.alerts) return null;
        if (!properties.alerts.length) return null;
        return properties.alerts.find(el => el._id === selectedAlertID);
    }, [selectedAlertID, properties.alerts]);

    const handleUpdate = (updates) => {
        if (!selectedAlert) return;
        if (!properties.alerts) return;
        if (!properties.alerts.length) return;
        const alertIndex = properties.alerts.findIndex(el => el._id === selectedAlert._id);
        const alert = properties.alerts[alertIndex];
        if (!alert) return;
        const updatedAlert = { ...alert, ...updates };
        const alerts = [...properties.alerts];
        alerts.splice(alertIndex, 1, updatedAlert);
        setProperties({ ...properties, alerts })
    }
    const handleDelete = (_id) => {
        if (!selectedAlert) return;
        if (!properties.alerts) return;
        if (!properties.alerts.length) return;
        const alerts = properties.alerts.filter(el => el._id !== _id);
        setProperties({ ...properties, alerts });
    }
    const handleCreateAlert = () => {
        const alerts = properties.alerts ? [...properties.alerts] : [];
        alerts.unshift({
            isNew: true,
            _id: new Date().getTime(),
            eventID: '',
            disabled: true,
            severity: 'INFO',
            name: '',
            description: '',
            androidID: '',
            operation: '=',
            property: '',
            dashboardEnabled: false,
            emailEnabled: false,
            chatEnabled: false,
            dashboardMessage: '',
            emailMessage: '',
            chatMessage: '',
            canChat: false,
            canDashboard: false,
            canEmail: false,
            highPriority: false,
            textFlags: [],
        })
        setProperties({ ...properties, alerts })
    }
    return (
        <ClickAwayListener onClickAway={handleClickAway} mouseEvent='onMouseUp'>
            <Grid2 container spacing={6}>
                {editing && <Grid2 size={12}>
                    <IconButton size="large" color="primary" onClick={handleCreateAlert}>
                        <AddAlertIcon fontSize="large" />
                    </IconButton>
                </Grid2>}
                {properties.alerts && properties.alerts.length === 0 && <Grid2 size={12}>
                    <Typography variant="h4" gutterBottom>
                        No Alerts Was Found!
                    </Typography>
                    <Typography variant="body2">
                        Click Edit then Add button to create your First Alert
                    </Typography>
                </Grid2>}
                <Grid2 size={12} display={properties.alerts && properties.alerts.length > 0 ? 'block' : "none"}>
                    <AlertsList alerts={properties.alerts || []} setSelectedAlertID={setSelectedAlertID} apiRef={apiRef} />
                </Grid2>
                <Grid2 size={12}>
                    {selectedAlert && <Grow
                        in={!!selectedAlert}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(!!selectedAlert ? { timeout: 1000 } : {})}
                    >
                        <div>
                            {editing ? <AlertEdit {...selectedAlert} handleUpdate={handleUpdate} handleDelete={handleDelete} /> : <AlertView {...selectedAlert} />}
                        </div>
                    </Grow>}
                </Grid2>
            </Grid2>
        </ClickAwayListener>
    );
}

export default Alerts;