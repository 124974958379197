import React, { useState } from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { http } from "utils/http"
import CableIcon from '@mui/icons-material/Cable';
import { toast } from 'react-toastify';

function TestLadsConnection({ ip, port, isValid, legacyLads, onSuccess }) {
    const [loading, setLoading] = useState(false);
    const handleTestLadsConnection = async () => {
        try {
            setLoading(true);
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/setup/test-lads-connection`,
                {
                    ip,
                    port,
                    legacyLads
                });
            if(onSuccess) onSuccess();
            toast.success(resp.data.message || "all good");
            setLoading(false);
        } catch (err) {
            setLoading(false);
            if (err && err.response && err.response.data && err.response.data.message) {
                return toast.error(err.response.data.message);
            }
            console.error("error", err);
            toast.error("Something went wrong");
        }
    }

    return <LoadingButton
        onClick={handleTestLadsConnection}
        loading={loading}
        variant="contained"
        sx={{ textAlign: 'center' }}
        loadingPosition="end"
        endIcon={<CableIcon />}
        disabled={!isValid}
    >
        Test Connection
    </LoadingButton>

}

export default TestLadsConnection;
