import React from "react";
import { styled } from '@mui/system';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Container,
  Grid2,
  Typography,
  Box
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Spacer = styled(Box)(spacing);

const Wrapper = styled(Box)`
  ${spacing};
  text-align: center;
  background-color: ${(props) => props.theme.palette.background.default};
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const Accordion = styled(MuiAccordion)`
  border-radius: 6px;
  text-align: left;
  margin: 20px 0 !important;
  box-shadow: 0 2px 6px 0 rgba(18, 38, 63, 0.05);

  &:before {
    display: none;
  }
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0 16px;
  box-shadow: 0;
  min-height: 48px !important;

  .MuiAccordionSummary-content {
    margin: 12px 0 !important;
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  padding-left: 16px;
  padding-right: 16px;
`;

function FAQ() {
  return (
    <Wrapper pt={20} pb={16}>
      <Container>
        <TypographyOverline variant="body2" gutterBottom>
          Dashalytics
        </TypographyOverline>
        <Typography variant="h2" component="h3" gutterBottom>
          Frequently asked questions
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          The questions below have been selected from those most commonly asked
          by our customers.
        </Typography>
        <Spacer sx={{ mb: 8 }} />

        <Grid2 container alignItems="center" justifyContent="center">
          <Grid2 size={{ xs: 12, xl: 8 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="faq1-content"
                id="faq1-header"
              >
                <Typography variant="subtitle1">
                  How long does the onboarding process take?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1" >
                  The onboarding process is as easy as signing up, and updating GSW LADS.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="faq2-content"
                id="faq2-header"
              >
                <Typography variant="subtitle1">
                  Will this impact anything in production currently?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1" >
                  Dashalytics will not impact current operations and can be tested
                  in a production environment with no risk of outages.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="faq3-content"
                id="faq3-header"
              >
                <Typography variant="subtitle1">
                  How is pricing structured?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1" >
                  Dashalytics operates on a per-device basis allowing users
                  to have real-time data on each device. <br></br>
                  This process is automatic and hassle free.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid2>
        </Grid2>
      </Container>
    </Wrapper>
  );
}

export default FAQ;
