const getStoreSelections = (state) => {
    return {
        selectedGroup: state.groups.selectedGroup,
        groupDevicesMap: state.groups.groupDevicesMap,
        selectedBadge: state.badges.selectedBadge,
        selectedBadgeID: state.badges.selectedBadgeID,
        badgesIDMap: state.badges.badgesIDMap
    }
}

export default getStoreSelections;