import { useMemo, useState } from "react";
import LeafletMap from "components/LeafletMap/LeafletMap";
import { Box, Typography, Card, CardContent } from '@mui/material';
import DeviceMarkerInfos from "components/LeafletMap/DeviceMarkerInfos";
import { useSelector } from "react-redux"
import { styled } from '@mui/system';
import WifiLevel from "components/WifiLevel";
import Slider from "components/Slider";

const LabelContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const SliderContainer = styled(Box)`
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const SliderLabelFormat = ({ value }) => {
    return <LabelContainer >
        <WifiLevel level={value} color="background" />
        <Typography variant="body2">
            {value}
        </Typography>
    </LabelContainer>
}

const WifiDropMap = ({ selections, selectedGroupID }) => {
    const allDevices = useSelector((state) => state.devices || []);
    const [wifiLevel, setWifiLevel] = useState(0);
    const groups = useSelector((state) => state.groups.groups);
    const devicesGroup = useMemo(() => {
        if (selectedGroupID === '-') return allDevices;
        const group = groups.find(el => el._id === selectedGroupID);
        if (!group) return [];
        const deviceMap = group.devices.reduce((p, c) => ({ ...p, [c]: c }), {});
        return allDevices.filter(el => deviceMap[el.androidID]);
    }, [groups, allDevices, selectedGroupID])

    const validWifiDevice = useMemo(() => {
        return devicesGroup.filter(device => device &&
            device.androidID &&
            device.radius &&
            device.lat !== undefined
            && device.long !== undefined &&
            Number.isInteger(device.wifi_level)
        );
    }, [devicesGroup]);
    const markers = useMemo(() => {
        return validWifiDevice
            .filter(device => device.wifi_level <= wifiLevel)
            .map(device => ({
                lat: device.lat,
                lon: device.long,
                label: <DeviceMarkerInfos {...device} />,
                radius: device.radius
            }))
    }, [validWifiDevice, wifiLevel]);
    const selectionInfos = useMemo(() => {
        return selections.map(el => {
            const device = allDevices.find(device => device.androidID === el.androidID);
            return {
                lat: el && el.lat ? el.lat : null,
                lon: el && el.lon ? el.lon : null,
                label: <DeviceMarkerInfos {...(device || {})} />,
                radius: el && el.radius ? el.radius : null,
            }
        }).filter(el => el.lat && el.lon && el.radius);
    }, [allDevices, selections])
    const onChange = (val) => {
        setWifiLevel(val);
    }
    return <Card sx={{ mb: 1 }}>
        <CardContent>
            <div
                style={{
                    width: '100%',
                    height: 400,
                }}
            >
                <SliderContainer>
                    <Typography variant="body" sx={{ px: 5 }}>
                        Wifi Level
                    </Typography>
                    <div style={{ width: 200 }}>
                        <Slider
                            valueLabelDisplay="auto"
                            min={0}
                            max={4}
                            value={wifiLevel}
                            onChange={(e, val) => onChange(val)}
                            valueLabelFormat={(value) => <SliderLabelFormat value={value} />}
                        />
                    </div>
                </SliderContainer>
                <LeafletMap
                    devices={markers}
                    showRadius
                    selections={selectionInfos}
                />
            </div>
            <Box sx={{ p: 6 }}></Box>
        </CardContent>
    </Card>
}
export default WifiDropMap;
