import React from "react";
import { styled } from '@mui/system';
import { rgba } from "polished";
import { NavLink } from "react-router-dom";

import {
  Button,
  Box,
  Chip,
  Container,
  Grid2,
  Typography
} from "@mui/material";
import { spacing } from "@mui/system";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";

import {
  MapPin as MapPinIcon,
  Bell as BellIcon,
  BarChart2 as ChartIcon,
  MessageCircle as MessageCircleIcon,
  Smartphone as SmartphoneIcon,
  BatteryCharging as BatteryIcon,
  Power as PowerIcon
} from "react-feather";

const Wrapper = styled(Box)`
  ${spacing};
  background: ${(props) => props.theme.palette.background.paper};
  text-align: center;
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const FeatureWrapper = styled(Box)`
  display: flex;
  text-align: left;
  padding: 18px 20px;
`;

const DemoChip = styled(Chip)`
  background-color: ${(props) => props.theme.palette.header};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 55%;
  height: 18px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)};
    padding-right: ${(props) => props.theme.spacing(1.375)};
  }
`;

const FeatureIcon = styled(Box)`
  svg {
    flex-shrink: 0;
    width: auto;
    height: 48px;
    width: 48px;
    background: ${(props) => rgba(props.theme.palette.primary.main, 0.15)};
    color: ${(props) => props.theme.palette.primary.main};
    padding: 10px;
    border-radius: 50%;
  }
`;

const ArrowForward = styled(ArrowForwardIcon)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Feature = ({ Icon, title, description, isComing = false }) => {
  return (
    <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 4 }}>
      <FeatureWrapper>
        <FeatureIcon>
          <Icon />
        </FeatureIcon>
        <Box sx={{ ml: 6 }}>
          <Typography variant="h4">
            {title} {isComing && <DemoChip label="Coming Soon" />}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {description}
          </Typography>

        </Box>
      </FeatureWrapper>
    </Grid2>
  );
};


function Features() {
  return (
    <Wrapper py={20}>
      <Container>
        <TypographyOverline variant="body2" gutterBottom>
          Features
        </TypographyOverline>
        <Typography variant="h2" component="h3" gutterBottom>
          Feature-Rich &amp; Data Driven
        </Typography>
        <Box sx={{ mb: 8 }} />
        <Grid2 container spacing={6}>
          <Feature
            Icon={ChartIcon}
            title="Real-Time Analytics"
            description="Understand your work environment in real-time as production flows."
          />
          <Feature
            Icon={BellIcon}
            title="Automated Alerts"
            description="Get alerts of urgent items in your environment from anywhere."
          />
          <Feature
            Icon={MessageCircleIcon}
            title="Instant Messaging"
            description="Message workers directly for fastest response times."
          />
          <Feature
            Icon={MapPinIcon}
            title="Accurate RTLS"
            description="Know where every device is, at all times. No more lost devices."
          />
          <Feature
            Icon={SmartphoneIcon}
            title="Device Information"
            description="Visualize the various makes and models of devices currently in your fleet."
          />
          <Feature
            Icon={BatteryIcon}
            title="Battery Analytics"
            description="Use data to inspect battery usage and health over the lifetime of devices and batteries."
          />
          <Feature
            Icon={BellIcon}
            title="Advanced Alerts"
            description="Configure alerts based on highly specific events with customizeable actions."
          />
          <Feature
            Icon={PowerIcon}
            title="Monitoring / Control"
            description="Monitor and Control devices directly from Dashalytics by GSW."
          />
          <Feature
            Icon={SmartphoneIcon}
            title="Device Groups"
            description="Group Devices into the most relevent categories for the specific environment."
          />
        </Grid2>
        <Box sx={{ mt: 4 }}>
          <Button
            component={NavLink}
            to="/docs/"
            variant="contained"
            color="secondary"
            size="large"
            target="_blank"
          >
            Open Documentation
            <ArrowForward />
          </Button>
        </Box>
      </Container>
    </Wrapper>
  );
}

export default Features;
