import { useState } from 'react';
import {
    Button,
    Menu,
    MenuItem
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AdminsMenuList = ({ selectedAdmin }) => {
    const admins = useSelector(state => state.admins.admins || []);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleUpdateSelectedAdmin = (adminID) => {
        if (!adminID) return;
        navigate(`/settings/admin/${adminID}`)
    }

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'admins-list-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {selectedAdmin?.displayName || selectedAdmin?._id || "Select an Admin"}
            </Button>
            <Menu
                id="admins-list-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {admins.map((admin) => <MenuItem
                    key={admin._id}
                    selected={selectedAdmin?._id === admin._id}
                    onClick={(e) => {
                        handleClose(e);
                        handleUpdateSelectedAdmin(admin._id)
                    }}>
                    {admin.displayName || admin._id}
                </MenuItem>)}
            </Menu>
        </div>
    );
}

export default AdminsMenuList;
