import { useState, useMemo } from 'react';
import { styled } from '@mui/system';
import {
    Badge,
    Avatar,
    Typography,
    Grid2,
    IconButton,
    Button
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { rgba } from 'polished';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import chatRoomType from 'constants/chatRoomType';
import useAuth from 'hooks/useAuth';
import USER_TYPE from 'constants/userType';
import { http } from 'utils/http';
import { updateChatRoomAction } from 'redux/chat/chat.actions';

const OnlineBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) => props.color === 'primary' ?
        props.theme.sidebar.footer.online.background :
        props.theme.sidebar.footer.offline.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;
const Wrapper = styled(Grid2)`
    background-color: ${(props) => props.$selected ? rgba(props.theme.palette.secondary.main, 0.1) : ''};
`

const UserChatRoomsDetailsWrapper = styled(Grid2)`
    background-color: ${(props) => props.$selected ? rgba(props.theme.palette.secondary.main, 0.2) : ''};
    margin:  10px 0;
`

const UserCard = ({ _id, displayName, isOnline, profilePicture, chatRooms, updateSelectedRoom, type: selectedUserType, closeUserMode, selectedUserID, setSelectedUserID }) => {
    const dispatch = useDispatch();
    const { userID: currentUserID, type: currentUserType } = useAuth();
    const users = useSelector(state => state.chat.users);
    const [fetching, setFetching] = useState(false);
    const selected = useMemo(() => selectedUserID === _id, [_id, selectedUserID]);
    const shouldCreateDeviceRoom = useMemo(() =>
        (currentUserType === USER_TYPE.ADMIN && selectedUserType === USER_TYPE.DEVICE && chatRooms.every(el => el.type !== chatRoomType.DEVICE)) ||
        (currentUserType === USER_TYPE.DEVICE && selectedUserType === USER_TYPE.ADMIN && chatRooms.every(el => el.type !== chatRoomType.DEVICE)),
        [currentUserType, selectedUserType, chatRooms]);
    const shouldCreatePrivateRoom = useMemo(() => !([currentUserType, selectedUserType].includes(USER_TYPE.SUPPORT)) && chatRooms.every(el => el.type !== chatRoomType.PRIVATE),
        [currentUserType, selectedUserType, chatRooms]);
    const shouldCreateSupportRoom = useMemo(() =>
        (currentUserType === USER_TYPE.ADMIN && selectedUserType === USER_TYPE.SUPPORT && chatRooms.every(el => el.type !== chatRoomType.SUPPORT)) ||
        (currentUserType === USER_TYPE.SUPPORT && selectedUserType === USER_TYPE.ADMIN && chatRooms.every(el => el.type !== chatRoomType.SUPPORT))
        , [currentUserType, selectedUserType, chatRooms]);
    const shouldCreate = useMemo(() => shouldCreateDeviceRoom || shouldCreatePrivateRoom || shouldCreateSupportRoom
        , [shouldCreateDeviceRoom, shouldCreatePrivateRoom, shouldCreateSupportRoom])
    const handleCreateRoom = async (roomType) => {
        setFetching(true);
        let participants = [];
        let name = "";
        try {
            switch (roomType) {
                case chatRoomType.DEVICE:
                    const androidID = currentUserType === USER_TYPE.DEVICE ? currentUserID :
                        selectedUserType === USER_TYPE.DEVICE ? _id :
                            null;
                    if (!androidID) throw new Error("Device chat room must have one device");
                    name = users.find(el => el._id === androidID)?.displayName || `Device ${androidID}`;
                    participants = [androidID, ...users.filter(el => el.type === USER_TYPE.ADMIN).map(el => el._id)];
                    break;
                case chatRoomType.PRIVATE:
                    name = users.find(el => el._id === _id)?.displayName || "Private Chat";
                    participants = [currentUserID, _id];
                    break;
                case chatRoomType.SUPPORT:
                    name = currentUserType === USER_TYPE.ADMIN ? "Support" : (users.find(el => el._id === _id)?.displayName || "Admin Chat");
                    participants = [currentUserID, _id];
                    break;
                default:
                    throw new Error("Invalid room type");
            }
            const resp = await http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/chat/room`,
                {
                    name,
                    participants,
                    type: roomType
                });
            if (resp?.data?._id) {
                dispatch(updateChatRoomAction(resp.data));
                setFetching(false);
                updateSelectedRoom(resp.data._id);
                closeUserMode();
                toast.success("Room Created");
            }
        } catch (error) {
            console.log("error", error.message);
            setFetching(false);
            if (error?.response?.data?.message) toast.error(error.response.data.message);
            else toast.error(error.message || "Something went wrong");
        }
    }
    const handleSwitchToSelectedUserRoom = () => {
        if (!chatRooms[0]?._id) return;
        updateSelectedRoom(chatRooms[0]?._id);
    }
    if (selected) return <UserChatRoomsDetailsWrapper container alignItems="center" spacing={1} $selected={selected} p={1}>
        <Grid2 container spacing={1} wrap="nowrap" alignItems="center">
            <Grid2>
                <OnlineBadge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    variant="dot"
                    invisible={isOnline === "N/A"}
                    color={isOnline ? 'primary' : 'secondary'}
                >
                    <Avatar
                        alt={displayName}
                        src={profilePicture}
                        sx={{ height: 24, width: 24 }}
                    />
                </OnlineBadge>
            </Grid2>
            <Grid2 flexGrow={1} sx={{ minWidth: 0 }}>
                <Typography variant="body1" noWrap><strong>{displayName}</strong></Typography>
            </Grid2>
            <Grid2>
                <IconButton color="primary" onClick={() => setSelectedUserID(null)}>
                    <CancelIcon />
                </IconButton>
            </Grid2>
        </Grid2>
        {chatRooms.map(el => <Grid2 key={el._id} size={12} container columnSpacing={1}>
            <Grid2>
                <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    endIcon={<ChatBubbleIcon />}
                    onClick={() => updateSelectedRoom(el._id)}>
                    {el.type?.toLocaleLowerCase() || "chat"}
                </Button>
            </Grid2>
        </Grid2>)}
        {shouldCreate && <Grid2 size={12}>
            <Typography variant="caption">Create a new Chat Room?</Typography>
        </Grid2>}
        {shouldCreateDeviceRoom && <Grid2>
            <LoadingButton
                loading={fetching}
                loadingPosition="end"
                endIcon={<AddCircleIcon />}
                variant="contained"
                size="small"
                onClick={() => handleCreateRoom(chatRoomType.DEVICE)}>
                Device
            </LoadingButton>
        </Grid2>}
        {shouldCreatePrivateRoom && <Grid2>
            <LoadingButton
                loading={fetching}
                loadingPosition="end"
                endIcon={<AddCircleIcon />}
                variant="contained"
                size="small"
                onClick={() => handleCreateRoom(chatRoomType.PRIVATE)}>
                Private
            </LoadingButton>
        </Grid2>}
        {shouldCreateSupportRoom && <Grid2>
            <LoadingButton
                loading={fetching}
                loadingPosition="end"
                endIcon={<AddCircleIcon />}
                variant="contained"
                size="small"
                onClick={() => handleCreateRoom(chatRoomType.SUPPORT)}>
                Support
            </LoadingButton>
        </Grid2>}
    </UserChatRoomsDetailsWrapper>

    return <Wrapper container alignItems="center" wrap="nowrap" spacing={1}>
        <Grid2>
            <OnlineBadge
                overlap="circular"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                variant="dot"
                invisible={isOnline === "N/A"}
                color={isOnline ? 'primary' : 'secondary'}
            >
                <Avatar
                    alt={displayName}
                    src={profilePicture}
                />
            </OnlineBadge>
        </Grid2>
        <Grid2 flexGrow={1} sx={{ minWidth: 0 }}>
            <Typography variant="h6" noWrap>{displayName}</Typography>
        </Grid2>
        <Grid2>
            {shouldCreate && !selected &&
                <IconButton color="primary" onClick={() => setSelectedUserID(_id)}>
                    <ArrowCircleLeftIcon />
                </IconButton>}
            {!shouldCreate && <IconButton color="primary" onClick={handleSwitchToSelectedUserRoom}>
                <ChatBubbleIcon />
            </IconButton>}
        </Grid2>
    </Wrapper>
}

export default UserCard;
