import { io } from 'socket.io-client'
import { toast } from 'react-toastify';
import store from 'redux/store';
import SOCKET_EVENT from "constants/socket-events";
import {
    newMessageAction,
    updateOnlineUsersAction,
    setChatConnectedAction,
    updateChatDeviceNameAction,
    updateChatRoomStatusAction
} from 'redux/chat/chat.actions'
import { getAuthToken } from './dataStorage'
import {
    newAlertAction,
    removeAlertAction
} from 'redux/alerts/alerts.actions';
import {
    updateDevicesAction,
    deleteDeviceEventAction
} from 'redux/devices/devices.actions';
import {
    updateRttDeviceLocationAction,
    updateRttWifiLevelsLocationAction
} from 'redux/rtt/rtt.actions';

import {
    updateLogEventAction,
    newScansEventAction,
    newKeyboardEventAction,
    newUserBadgeEventAction
} from 'redux/events/events.actions';
import { updateLadsServerAction } from 'redux/ladsServers/ladsServers.actions';
import { updateBadgeAction } from 'redux/badges/badges.actions';
import {
    newUtsAgentLogEventsAction,
    //newUtsAgentLogEventAction,
    newUtsEventsAction,
} from 'redux/uts/uts.actions';
import { updateClientSettingsAction } from 'redux/clientSettings/clientSettings.actions';


let socketMessagesQueue = [];
let socket = {
    connected: false,
    emit() {
        //console.log("socket called before init (put in queue)", [...arguments]);
        socketMessagesQueue.push(arguments);
    },
    disconnect() {
        //console.log("socket disconnect called before init!");
    },
    connect() {
        // console.log("socket connect called before init!");
    }
}
const socketUri = process.env.REACT_APP_SOCKET_URL || window.location.protocol + "//" + window.location.host;
const dispatch = store.dispatch
// move to socket!


const DEBOUNCE_SCAN_TIMER = 500;
let debouncedEventsScan = [];
let timer_scan_debounce = null;
function debouncedScanEvents(event) {
    debouncedEventsScan.unshift({ ...event });
    if (timer_scan_debounce) return;
    timer_scan_debounce = setTimeout(() => {
        const _events = [...debouncedEventsScan];
        timer_scan_debounce = null;
        debouncedEventsScan = [];
        dispatch(newScansEventAction(_events))
    }, DEBOUNCE_SCAN_TIMER);
}


const DEBOUNCE_UTS_AGENT_LOG_TIMER = 1000;
const DEBOUNCE_UTS_AGENT_LOG_MAX_QUEUE_SIZE = 50;
let debouncedUtsAgentLogEventsScan = [];
let timer_uts_log_agent_debounce = null;
function debouncedUtsAgentLogEvents(event) {
    let timeout = DEBOUNCE_UTS_AGENT_LOG_TIMER;
    debouncedUtsAgentLogEventsScan.push({ ...event });
    if (debouncedUtsAgentLogEventsScan.length >= DEBOUNCE_UTS_AGENT_LOG_MAX_QUEUE_SIZE) {
        timeout = 0;
        clearTimeout(timer_uts_log_agent_debounce);
        timer_uts_log_agent_debounce = null;
    }
    if (timer_uts_log_agent_debounce) return;
    timer_uts_log_agent_debounce = setTimeout(() => {
        const _events = [...debouncedUtsAgentLogEventsScan];
        timer_uts_log_agent_debounce = null;
        debouncedUtsAgentLogEventsScan = [];
        dispatch(newUtsAgentLogEventsAction(_events))
    }, timeout);
}


const DEBOUNCE_UTS_TIMER = 1000;
const DEBOUNCE_UTS_MAX_QUEUE_SIZE = 50;
let debouncedUtsEventsScan = [];
let timer_uts_debounce = null;
function debouncedUtsEvents(event) {
    let timeout = DEBOUNCE_UTS_TIMER;
    debouncedUtsEventsScan.unshift({ ...event });
    if (debouncedUtsEventsScan.length >= DEBOUNCE_UTS_MAX_QUEUE_SIZE) {
        timeout = 0;
        clearTimeout(timer_uts_debounce);
        timer_uts_debounce = null;
    }
    if (timer_uts_debounce) return;
    timer_uts_debounce = setTimeout(() => {
        const _events = [...debouncedUtsEventsScan];
        timer_uts_debounce = null;
        debouncedUtsEventsScan = [];
        dispatch(newUtsEventsAction(_events))
    }, timeout);
}






const DEBOUNCE_DEVICE_TIMER = 500;
let debouncedDevicesUpdates = [];
let timer_device_debounce = null;
function debouncedDeviceUpdate(device) {
    debouncedDevicesUpdates.push({ ...device });
    if (timer_device_debounce) return;
    timer_device_debounce = setTimeout(() => {
        const _devices = [...debouncedDevicesUpdates];
        timer_device_debounce = null;
        debouncedDevicesUpdates = [];
        dispatch(updateDevicesAction(_devices))
    }, DEBOUNCE_DEVICE_TIMER);
}

function connectSocket(namespace) {
    //console.log("connecting", namespace);
    //console.log("sock")
    if (!namespace) return;
    if (socket.auth?.token) socket.auth.token = "";
    if (socket.removeAllListeners) socket.removeAllListeners();
    if (socket.offAny) socket.offAny();
    if (socket.disconnect) socket.disconnect();
    if (socket.close) socket.close();

    if (socket.destroy) socket.destroy();
    socket = io(`${socketUri}/${namespace}`, {
        reconnection: true,
        //pingTimeout: 5000,
        auth: {
            token: getAuthToken()
        },
        forceNew: true,
        multiplex: false
    });
    setTimeout(() => {
        //console.log("checking connection");
        if (!socket.connected) {
            //console.log("socket is not connected?");
            if (socket.disconnect) socket.disconnect();
            if (socket.close) socket.close();
            if (socket.connect) socket.connect();
        }
    }, 1000);
    socket.on("connect_error", (err) => {
        // logout user if token is expired
        //console.log(`connect_error due to ${err.message}`);
    });
    socket.on('connect', function () {
        socket.emit(SOCKET_EVENT.SOCKET_CLIENT_READY, {});
        if (socketMessagesQueue && socketMessagesQueue.length) console.log(`socket queue is no empty! ${socketMessagesQueue.length} messages`);
        socketMessagesQueue.forEach(args => socket.emit.apply(socket, args))
        socketMessagesQueue = [];
        dispatch(setChatConnectedAction(true));
    });
    socket.on('disconnect', function (reason) {
        // console.log("disconnected", reason);
        dispatch(setChatConnectedAction(false));
    });
    socket.io.engine.on("drain", () => {
        // this could happens if user start importing events
        // should we disable connection ?
        //console.error("socket drained");
    });
    //socket.on("debug", (msg) => console.log("socket->", msg));
    socket.on(SOCKET_EVENT.SOCKET_SERVER_UPDATE_ONLINE_USERS, socketListeners.onUpdateOnlineUsers);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_EMIT_MESSAGE, socketListeners.onNewMessage);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_UPDATE_CHATROOM_STATUS, socketListeners.onUpdateChatRoomStatus);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_DISCONNECT_DEVICE, socketListeners.onDisconnectDevice);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_DEVICE_UPDATE, socketListeners.onDeviceUpdate);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_DEVICE_DELETE, socketListeners.onDeviceDelete)
    socket.on(SOCKET_EVENT.SOCKET_SERVER_UPDATE_LICENSE_COUNT, socketListeners.onLicenceCountUpdate);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_UPDATE_DEVICE_NAME, socketListeners.onUpdateDeviceName);

    socket.on(SOCKET_EVENT.SOCKET_SERVER_NEW_SYSTEM_NOTIFICATION, socketListeners.onNewSystemNotification);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_NEW_RTT_LOCATION, socketListeners.onNewRttDeviceLocation);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_NEW_RTT_WIFI_LEVELS, socketListeners.onNewRTTWIFILevel);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_UPDATE_LOG_EVENT, socketListeners.onUpdateLogEvent);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_NEW_SCAN_EVENT, socketListeners.onNewScanEvent);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_USER_BADGE_EVENT, socketListeners.onUserBadgeEvent);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_UPDATE_BADGE, socketListeners.onBadgeUpdate)
    socket.on(SOCKET_EVENT.SOCKET_SERVER_NEW_UTS_EVENT, socketListeners.onNewUTSEvent);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_NEW_UTS_AGENT_LOG_EVENT, socketListeners.onNewUTSAgentLogEvent);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_NEW_ALERT, socketListeners.onNewAlert);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_REMOVE_ALERT, socketListeners.onRemoveAlert);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_NEW_KEYBOARD_EVENT, socketListeners.onNewKeyboardEvent);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_UPDATE_CLIENT_SETTINGS, socketListeners.onClientSettingsUpdate);
    socket.on(SOCKET_EVENT.SOCKET_SERVER_NOT_READY, () => {
        //        console.log("server is not ready");
        setTimeout(() => {
            //    console.log("connecting socket again");
            socket.disconnect();
            socket.close();
            socket.connect();
        }, 100);
    });
}
function disconnectSocket() {
    console.log("disconnecting client");
    if (socket) {
        //socket.listeners("debug").forEach(listener => socket.off("debug", listener));
        if (socket._opts?.reconnection) socket._opts.reconnection = false
        if (socket.auth?.token) socket.auth.token = "";
        if (socket.removeAllListeners) socket.removeAllListeners();
        if (socket.offAny) socket.offAny();
        if (socket.disconnect) socket.disconnect();
        if (socket.close) socket.close();
        if (socket.destroy) socket.destroy();
    }
    dispatch(setChatConnectedAction(false));
    socketMessagesQueue = [];
}

const socketEmitters = {
    emitMessage(messageData) {
        if (!socket.connected) {
            socket.disconnect();
            socket.close();
            socket.connect();
        }
        // require roomID
        const {
            roomID,
            chatMessage,
            fileType,
            resourceUrl,
            resourceName,
            resourceSize,
            resourceMimeType,
            highPriority
        } = messageData;
        socket.emit(SOCKET_EVENT.SOCKET_CLIENT_EMIT_MESSAGE, {
            text: chatMessage || resourceName,
            fileType,
            resourceUrl,
            resourceName,
            resourceSize,
            resourceMimeType,
            type: fileType,
            roomID,
            highPriority
        })
    },
    emitMarkSeen({ roomID }) {
        if (!socket.connected) socket.connect();
        socket.emit(SOCKET_EVENT.SOCKET_CLIENT_MARK_CONV_SEEN, { roomID });
    },
    emitMarkUnSeen({ roomID }) {
        if (!socket.connected) socket.connect();
        socket.emit(SOCKET_EVENT.SOCKET_CLIENT_MARK_CONV_UNSEEN, { roomID });
    },
    emitDeviceReleaseLicense({ androidID }) {
        if (!socket.connected) socket.connect();
        socket.emit(SOCKET_EVENT.SOCKET_CLIENT_DEVICE_RELEASE_LICENSE, { androidID });
    },
    emitDeviceDevicePing({ androidID }) {
        if (!socket.connected) socket.connect();
        socket.emit(SOCKET_EVENT.SOCKET_CLIENT_DEVICE_PING, { androidID });
    },
    emitGetClientSettings() {
        if (!socket.connected) socket.connect();
        socket.emit(SOCKET_EVENT.SOCKET_CLIENT_GET_CLIENT_SETTINGS);
    },
    emitUpdateClientSettings(updates) {
        if (!socket.connected) socket.connect();
        socket.emit(SOCKET_EVENT.SOCKET_CLIENT_UPDATE_CLIENT_SETTINGS, updates);
    }
}

const socketListeners = {
    onNewMessage(message) {
        dispatch(newMessageAction(message));
    },
    onUpdateChatRoomStatus(chatRoom) {
        dispatch(updateChatRoomStatusAction(chatRoom));
    },
    onUpdateOnlineUsers(users) {
        dispatch(updateOnlineUsersAction(users));
    },
    onNewAlert(alert) {
        dispatch(newAlertAction(alert));
    },
    onRemoveAlert(alertID) {
        dispatch(removeAlertAction(alertID));
    },
    onUpdateLogEvent(event) {
        dispatch(updateLogEventAction(event));
    },
    onNewScanEvent(event) {
        debouncedScanEvents(event);
        dispatch(updateLogEventAction(event));
    },
    onUserBadgeEvent(event) {
        dispatch(newUserBadgeEventAction(event))
    },
    onBadgeUpdate(badge) {
        dispatch(updateBadgeAction(badge));
    },
    onNewUTSEvent(event) {
        // add debouce to this event
        if (window.location.pathname !== '/uts') return;
        debouncedUtsEvents(event);
    },
    onNewUTSAgentLogEvent(event) {
        if (window.location.pathname !== '/uts') return;
        //console.error("debouncing is not enabled");
        debouncedUtsAgentLogEvents(event);
        //dispatch(newUtsAgentLogEventAction(event));
    },
    onDisconnectDevice() {
        socket.disconnect();
        // should be enought for now
        window.location.reload();
    },
    onDeviceUpdate(device) {
        if (device.androidID === "0000000000000000") return;
        debouncedDeviceUpdate(device);
    },
    onDeviceDelete(device) {
        if (device.androidID === "0000000000000000") return;
        dispatch(deleteDeviceEventAction(device.androidID));
    },
    onNewRttDeviceLocation(deviceLocation) {
        if (window.location.pathname !== '/pages/map' && window.location.pathname !== '/pages/map-canvas') return;
        dispatch(updateRttDeviceLocationAction(deviceLocation));
    },
    onNewRTTWIFILevel(locationWifiLevels) {
        if (window.location.pathname !== '/pages/map' && window.location.pathname !== '/pages/map-canvas') return;
        dispatch(updateRttWifiLevelsLocationAction(locationWifiLevels));
    },
    onLicenceCountUpdate(event) {
        dispatch(updateLadsServerAction(event));
    },
    onUpdateDeviceName(event) {
        dispatch(updateChatDeviceNameAction(event));
    },
    onNewSystemNotification({ text, error }) {
        if (text) toast.success(text);
        if (error) toast.error(error);
    },
    onNewKeyboardEvent(event) {
        dispatch(newKeyboardEventAction(event));
    },
    onClientSettingsUpdate(updates) {
        dispatch(updateClientSettingsAction(updates))
    }
}

export { socket, connectSocket, disconnectSocket, socketEmitters }

