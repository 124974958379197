import {
    useMemo,
    useEffect,
    useState
} from 'react';
import {
    Grid2,
    TableCell as MuiTableCell,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    ButtonGroup,
    Button
} from '@mui/material';

import ProductHeader from './ProductHeader';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { rgba } from "polished";
import { styled } from '@mui/system';
import useAuthorization from 'hooks/useAuthorization';

const renderProperty = (val) => {
    if (val === undefined) return "-";
    if (val === "true") return <CheckIcon color="primary" />;
    if (val === "false") return <CloseIcon />;
    return val;
}
const TableCell = styled(MuiTableCell)`
  background-color: ${(props) => props.$selected ? rgba(props.theme.palette.secondary.main, 0.1) : ''};
`

const Products = ({ products, selectedProduct, setSelectedProduct, disabled }) => {
    const { isAuthorized } = useAuthorization();
    const canCreateCheckout = isAuthorized("payment.createCheckout");
    const [billingInterval, setBillingInterval] = useState(null);
    const itervals = useMemo(() => {
        const intervalMap = {};
        products.forEach(el => {
            el.prices?.forEach(el => {
                if (el.type === "recurring") {
                    intervalMap[el.recurring.interval] = true;
                }
            })
        })
        return Object.keys(intervalMap);
    }, [products]);
    useEffect(() => {
        if (!billingInterval && itervals.length) {
            setBillingInterval(itervals[0]);
        }
    }, [itervals, billingInterval]);

    const availableProducts = useMemo(() => {
        return products.map(el => ({
            ...el,
            price: el.prices?.find(el => el.type === "recurring" && el.recurring.interval === billingInterval)
        }))
            .filter(el => el.price)
            .sort((a, b) => parseInt(a.properties.order) - parseInt(b.properties.order));
    }, [products, billingInterval]);
    const properties = availableProducts &&
        availableProducts[0] &&
        availableProducts[0].properties ?
        Object.keys(availableProducts[0].properties).filter(el => !/isDefault|order|price|unit|currency|trial/.test(el)) : [];
    return (
        <Grid2 container justifyContent="center">
            <Grid2>
                <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                    {itervals.map((interval, index) => <Button
                        key={index}
                        onClick={() => setBillingInterval(interval)}
                        sx={{ textTransform: 'uppercase' }}
                        variant={interval === billingInterval ? "contained" : "outlined"}
                    >
                        Per {interval}
                    </Button>)
                    }
                </ButtonGroup>
            </Grid2>
            <Grid2 size={12}>
                <TableContainer component={Paper}>
                    <Table sx={{
                        minWidth: 650,
                    }}>
                        <TableHead>
                            <TableRow>
                                {availableProducts.map(product => (
                                    <TableCell $selected={selectedProduct && selectedProduct.productID === product.id} align="center" key={product.id}>
                                        <ProductHeader
                                            id={product.id}
                                            interval={billingInterval}
                                            selectedProduct={selectedProduct}
                                            setSelectedProduct={setSelectedProduct}
                                            {...product}
                                            disabled={disabled || !canCreateCheckout}
                                            unit={product.properties.unit}
                                            currency={product.properties.currency}
                                            isDefault={availableProducts.length === 1 ? true : product.properties.isDefault}
                                        />
                                    </TableCell>))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {properties.map((property) => (
                                <TableRow
                                    key={property}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {property}
                                    </TableCell>
                                    {availableProducts.map((product, index) => <TableCell selected={selectedProduct === product.id} key={product.id} align="center">{renderProperty(product.properties[property])}</TableCell>)}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid2>
        </Grid2>
    );
}

export default Products;