import DevicesActionTypes from "./devices.types"
import moment from 'moment';
const initialState = [];
const nullableKeys = ["badgeID"];

function removeEmptyKeys(object) {
    for (const key in object) {
        if ((object[key] === null || object[key] === undefined)
            &&
            (!nullableKeys.includes(key))) delete object[key];
    }
}
const devicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case DevicesActionTypes.UPDATE_DEVICES: {
            const devices = state.reduce((a, c) => ({ ...a, [c.androidID]: c }), {});
            action.payload.forEach(device => {
                if (!device || !device.androidID) return;
                if (!devices[device.androidID]) devices[device.androidID] = { id: Math.random(), _id: Math.random() };
                removeEmptyKeys(device);
                devices[device.androidID] = { ...devices[device.androidID], ...device };
                devices[device.androidID].date = moment(new Date(devices[device.androidID].date)).format('YYYY-MM-DD HH:mm:ss');
            })
            return Object.values(devices);
        }
        case DevicesActionTypes.DELETE_DEVICE_EVENT:
            return state.filter(el => el.androidID !== action.payload);
        default:
            return state;
    }
}
export default devicesReducer;