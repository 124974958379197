
export const numberOfColumns = 10;
export const numberOfRows = 4;



export const aspectRatio = (18 / 9) * 2; // 50% screen for 18/9
export const orientation = "landscape"

export const settings = {
    alignment: "alignParentBottom,centerHorizontal",
    handleColor: "#c1c1c1",
    horizontalGap: 0,
    horizontalGapUnit: "%pw",
    horizontalScale: 1.0,
    keyBackgroundAlpha: 255,
    keyHeight: 100 / numberOfColumns, // 10% of total width same as gsw connect bot
    keyHeightUnit: "%ph",
    keyTextFontFlags: "normal",// italic | bold | underline | normal
    keyBackground: "@drawable/key_vista_black",
    keyboardBackgroundColor: "#c1c1c1",
    keyboardOrientation: undefined,
    keyTextFontName: "Vera.ttf",
    keyTextSize: 3,
    keyTextSizeUnit: "%pw",
    keyTextColor: "#ffffff",
    keyTint: "",
    keyWidth: 100 / numberOfColumns, //  px | dp | sp | in | mm | % | %p
    keyWidthUnit: "%pw",
    labelTextFontFlags: "normal",
    labelTextFontName: "Vera.ttf",
    labelTextSize: 2, //  px | dp | sp | in | mm | % | %p
    labelTextSizeUnit: "%pw",
    playClickSounds: true,
    rightAlign: false,
    verticalGap: 0,
    verticalGapUnit: "%pw",
    verticalScale: 1.0,
    keyboardAlpha: 255,
    skinnable: false,
    vibrateDuration: 0,
}

export const defaultKeysLabel = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const MAX_SCREEN_HEIGHT_TO_WINDOW_HEIGHT = 0.8;

export const defaultKeyboardFileName = "qwerty_portrait.xml";



export const keyboardLayoutCreatorDefaultState =  { 
    cells: [], 
    gridSize: { 
        nCols: numberOfColumns, 
        nRows: numberOfRows 
    } 
}