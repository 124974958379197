import adminPermissions from './adminPermissions';

//console.log(`${(Math.round(Math.random() * 256)).toString("16")}${(Math.round(Math.random() * 256)).toString("16")}${(Math.round(Math.random() * 256)).toString("16")}${(Math.round(Math.random() * 256)).toString("16")}${(Math.round(Math.random() * 256)).toString("16")}${(Math.round(Math.random() * 256)).toString("16")}`);
const pagesSettings = [
    {
        id: "67863d74183a",
        name: "System Overview",//*
        requiredPermission: "systemOverview",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.systemOverview,
            adminPermissions.group.read,
            adminPermissions.device.read,
            adminPermissions.event.read,
            adminPermissions.notification.read,
            adminPermissions.notification.markAsRead,
            adminPermissions.badge.read,
        ],
        regex: /^\/$/,
        href: "/",
    },
    {
        id: "923ca6296209",
        name: "Productivity Dashboard",//*
        requiredPermission: "productivity",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.productivity,
            adminPermissions.device.read,
            adminPermissions.group.read,
            adminPermissions.event.read,
            adminPermissions.alert.read,
            adminPermissions.setting.read
        ],
        regex: /^\/productivity$/,
        href: "/productivity",
    },
    {
        id: "e07610cbdace",
        name: "Alerts Dashboard", //*
        requiredPermission: "alerts",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.alerts,
            adminPermissions.alert.read,
            adminPermissions.device.read,
        ],
        regex: /^\/alerts$/,
        href: "/alerts",
    },
    {
        id: "4e054e0f424a",
        name: "UTS Dasboard",//*
        requiredPermission: "uts",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.uts,
            adminPermissions.event.read,
        ],
        regex: /^\/uts$/,
        href: "/uts",
    },
    {
        id: "02c19d326c61",
        name: "Users", //*
        requiredPermission: "users",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.users,
            adminPermissions.badge.read,
            adminPermissions.device.read,
            adminPermissions.event.read,
        ],
        regex: /^\/users$/,
        href: "/users",
    },
    {
        id: "18fd267bd7b1",
        name: "Device List",//*
        requiredPermission: "deviceList",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.deviceList,
            adminPermissions.device.read,
            adminPermissions.group.read
        ],
        regex: /^\/devices$/,
        href: "/devices"
    },
    {
        id: "10a7f92d8a0c",
        name: "Outdoor Positioning",
        requiredPermission: "outdoorPositioning",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.outdoorPositioning,
            adminPermissions.group.read,
            adminPermissions.device.read,
            adminPermissions.event.read,
        ],
        regex: /^\/pages\/gps$/,
        href: "/pages/gps"
    },
    {
        id: "1206ba417de4",
        name: "Indoor Positioning",
        requiredPermission: "indoorPositioning",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.indoorPositioning,
            adminPermissions.group.read,
            adminPermissions.setting.read,
            adminPermissions.device.read,
            adminPermissions.event.read,
        ],
        regex: /^\/pages\/map$/,
        href: "/pages/map"
    },
    {
        id: "9f40b43b6193",
        name: "Chat",
        requiredPermission: "chat", //*
        pageFullAccessRequiredPermissions: [
            adminPermissions.page.chat,
            adminPermissions.myAccount.read,
            adminPermissions.chatUser.read,
            adminPermissions.chatRoom.read,
            adminPermissions.chatRoom.update,
            adminPermissions.chatRoom.delete,
            adminPermissions.chatRoom.create,
            adminPermissions.chatMessage.read,
            adminPermissions.chatMessage.write,
            adminPermissions.chatConversationsList.read,
            adminPermissions.device.read,
            adminPermissions.file.uploadFile,
            adminPermissions.file.uploadImage,
        ],
        regex: /^\/chat(\/([a-f0-9]){16,24}){0,1}$/,
        href: "/chat"
    },
    {
        id: "1cd0dcc262a1",
        name: "Alert Log",//*
        requiredPermission: "alertLog",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.alertLog,
            adminPermissions.alert.read
        ],
        regex: /^\/logs\/alertlog$/,
        href: "/logs/alertlog"
    },
    {
        id: "70c76fe4c817",
        name: "Event Log",//*
        requiredPermission: "eventLog",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.eventLog,
            adminPermissions.event.read,
            adminPermissions.group.read,
        ],
        regex: /^\/logs\/eventlog$/,
        href: "/logs/eventlog"
    },
    {
        id: "2a81ffcf7962",
        name: "Scan Log",//*
        requiredPermission: "scanLog",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.scanLog,
            adminPermissions.event.read,
            adminPermissions.myCompany.read,
            adminPermissions.group.read,
        ],
        regex: /^\/logs\/scanlog$/,
        href: "/logs/scanlog",
    },
    {
        id: "95c7daa52409",
        name: "Wi-Fi Drop Log",//*
        requiredPermission: "wiFiDropLog",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.wiFiDropLog,
            adminPermissions.group.read,
            adminPermissions.device.read,
            adminPermissions.event.read,
        ],
        regex: /^\/logs\/wifidroplog$/,
        href: "/logs/wifidroplog"
    },
    {
        id: "630194a9d0ce",
        name: "Groups Settings",//*
        requiredPermission: "groupsSettings",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.groupsSettings,
            adminPermissions.device.read,
            adminPermissions.group.read,
            adminPermissions.group.delete,
            adminPermissions.setting.read,
            adminPermissions.group.moveDevice,
            adminPermissions.group.create,
            adminPermissions.group.update,
            adminPermissions.setting.create,
            adminPermissions.setting.update,
            adminPermissions.setting.delete,
            adminPermissions.device.ping,
            adminPermissions.device.renameDevice,
            adminPermissions.badge.read,
            adminPermissions.badge.create,
            adminPermissions.badge.delete,
            adminPermissions.badge.update,
            adminPermissions.event.read,
            adminPermissions.tunnel.readStatus,
	        adminPermissions.tunnel.download,
        	adminPermissions.tunnel.createSecondary
        ],
        regex: /^\/settings\/devices$/,
        href: "/settings/devices",
    },
    {
        id: "23089a9c58a7",
        name: "Admins", //*
        requiredPermission: "admins",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.admins,
            adminPermissions.admin.read,
            adminPermissions.admin.invite,
            adminPermissions.role.read,
            adminPermissions.chatRoom.read,
        ],
        regex: /^\/settings\/admins$/,
        href: "/settings/admins",
    },
    {
        id: "96345a22a261",
        name: "Company",//*
        requiredPermission: "company",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.company,
            adminPermissions.myCompany.read,
            adminPermissions.lads.readPostEventUUID,
            adminPermissions.device.read,
            adminPermissions.payment.readStatus,
            adminPermissions.payment.readRportedUsage,
            adminPermissions.payment.downloadInvoice,
            adminPermissions.authLog.read,
            adminPermissions.event.updateIgnoreScan,
            adminPermissions.myAccount.write,
            adminPermissions.tunnel.download,
            adminPermissions.tunnel.readStatus,
            adminPermissions.admin.read,
            adminPermissions.lads.restartBiThread,
            adminPermissions.lads.testConnection,
            adminPermissions.lads.writeRegistryKeyValue,
            adminPermissions.lads.readRegistryKeyValue,
            adminPermissions.payment.createCheckout,
            adminPermissions.payment.createPortal,
        ],
        regex: /^\/company$/,
        href: "/company",
    },

    // sub pages
    {
        id: "a11ea2754d28",
        name: "My Account",//*
        requiredPermission: "myAccount",
        pageFullAccessRequiredPermissions: [
            adminPermissions.page.myAccount,
            adminPermissions.myAccount.read,
            adminPermissions.myAccount.write,
            adminPermissions.myAccount.verifyMyEmail
        ],
        regex: /^\/settings\/account$/,
        href: "/settings/account",
    },
    {
        id: "79635dcc4c24",
        name: "Device Details",//*
        requiredPermission: "deviceDetails",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.deviceDetails,
            adminPermissions.group.read,
            adminPermissions.badge.read,
            adminPermissions.device.read,
            adminPermissions.chatUser.read,
            adminPermissions.chatMessage.read,
            adminPermissions.chatMessage.write,
            adminPermissions.chatConversationsList.read,
            adminPermissions.chatRoom.create,
            adminPermissions.chatRoom.read,
            adminPermissions.event.read,
            adminPermissions.alert.read,
            adminPermissions.device.reloadVars,
            adminPermissions.device.delete,
            adminPermissions.device.ping,
            adminPermissions.device.releaseLicense,
            adminPermissions.device.renameDevice,
        ],
        regex: /^\/devices\/[a-f0-9]{16}$/i,
        href: "/devices/1234567890abcdef"
    },
    {
        id: "4bf9916cfe8",
        name: "Merge Devices", //*
        requiredPermission: "mergeDevices",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.mergeDevices,
            adminPermissions.device.read,
            adminPermissions.device.reloadVars,
            adminPermissions.device.mergeDevices,
            adminPermissions.event.read,
            adminPermissions.group.read
        ],
        regex: /^\/pages\/merge-devices/i,
        href: "/pages/merge-devices"
    },
    {
        id: "d627d71dbf38",
        name: "User Details",
        requiredPermission: "userDetails", //*
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.userDetails,
            adminPermissions.device.read,
            adminPermissions.event.read,
            adminPermissions.badge.read,
            adminPermissions.chatUser.read,
            adminPermissions.chatMessage.read,
            adminPermissions.chatMessage.write,
            adminPermissions.chatConversationsList.read,
            adminPermissions.chatRoom.create,
            adminPermissions.chatRoom.read,
            adminPermissions.alert.read,
        ],
        regex: /^\/users\/[a-f0-9]{24}$/i,
        href: "/users/1234567890abcdef12345678"
    },
    {
        id: "d0fcbf8fb99",
        name: "Remote Management",
        requiredPermission: "remoteManagement",
        pageFullAccessRequiredPermissions: [
            adminPermissions.page.remoteManagement,
            adminPermissions.myAccount.read,
            adminPermissions.device.read,
            adminPermissions.tunnel.readStatus,
            adminPermissions.remoteManagement.read,
            adminPermissions.remoteManagement.downloadFile,
            adminPermissions.remoteManagement.downloadLogFile,
            adminPermissions.remoteManagement.uploadFile,
            adminPermissions.remoteManagement.listFiles,
            adminPermissions.remoteManagement.pathKitting,
            adminPermissions.remoteManagement.pathSDCard,
            adminPermissions.remoteManagement.createFolder,
            adminPermissions.remoteManagement.deleteFile,
            adminPermissions.remoteManagement.viewScreen,
            adminPermissions.remoteManagement.controlScreen,
            adminPermissions.remoteManagement.hardwareKeys,
            adminPermissions.remoteManagement.rebootDevice,
            adminPermissions.remoteManagement.clearProxyCache
        ],
        regex: /^\/pages\/remote-management$/,
        href: "/pages/remote-management"
    },
    {
        id: "8c9bbf748ae5",
        name: "Keyboard",
        requiredPermission: "keyboard",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.keyboard,
            adminPermissions.lads.readDirectory,
            adminPermissions.keyboard.read,
            adminPermissions.keyboard.update,
            adminPermissions.lads.downloadFile,
            adminPermissions.lads.uploadFile,
            adminPermissions.keyboard.delete,
            adminPermissions.device.read,
        ],
        regex: /^\/pages\/keyboard$/,
        href: "/pages/keyboard"
    },
    {
        id: "a7f3508daa66",
        name: "Keyboard Creator",
        requiredPermission: "keyboardCreator",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.keyboard,
            adminPermissions.keyboard.read,
            adminPermissions.keyboard.update,
            adminPermissions.device.read,
        ],
        regex: /^\/pages\/keyboard\/express(\/[a-f0-9]{24})?$/,
        href: "/pages/keyboard/express"
    },
    {
        id: "e5253835abf2",
        name: "Keyboard Advanced",
        requiredPermission: "keyboardAdvanced",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.keyboard,
            adminPermissions.lads.readDirectory,
            adminPermissions.keyboard.read,
            adminPermissions.keyboard.update,
            adminPermissions.lads.downloadFile,
            adminPermissions.lads.uploadFile,
            adminPermissions.keyboard.delete,
            adminPermissions.device.read,
        ],
        regex: /^\/pages\/keyboard\/advanced\/[a-f0-9]{24}$/,
        href: "/pages/keyboard/advanced/6296672f0029006106b53692"
    },
    {
        id: "a75421f4b28",
        name: "Keyboards LADS",
        requiredPermission: "keyboardLadsManagement",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.keyboard,
            adminPermissions.lads.readDirectory,
            adminPermissions.keyboard.read,
            adminPermissions.keyboard.update,
            adminPermissions.lads.downloadFile,
            adminPermissions.lads.uploadFile,
        ],
        regex: /^\/pages\/keyboard\/lads(\/[a-f0-9]{24})?$/,
        href: "/pages/keyboard/advanced/lads"
    },
    {
        id: "b1c2caf5b795",
        name: "Keyboard Management",
        requiredPermission: "keyboardsManagement",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.keyboard,
            adminPermissions.keyboard.read,
            adminPermissions.keyboard.delete,
        ],
        regex: /^\/pages\/keyboard\/projects$/,
        href: "/pages/keyboard/projects"
    },
    {
        id: "c7c415be8246",
        name: "Admins Permissions",
        requiredPermission: "adminsPermissions",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.adminsPermissions,
            adminPermissions.role.read,
            adminPermissions.admin.read,
            adminPermissions.permission.read,
        ],
        regex: /^\/settings\/admin\/[a-f0-9]{24}$/i,
        href: "/settings/admin/1234567890abcdef12345678"
    },
    {
        id: "3983e0fe6547",
        name: "Reset Post Event UID",
        requiredPermission: "generateNewUUID",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.generateNewUUID,
            adminPermissions.lads.resetPostEventUUID,
        ],
        regex: /^\/settings\/generate-new-uuid$/,
        href: "/settings/generate-new-uuid"
    },
    {
        id: "f6b7262c8c1d",
        name: "Encryption",
        requiredPermission: "encryption",
        pageFullAccessRequiredPermissions: [
            adminPermissions.myAccount.read,
            adminPermissions.page.encryption
        ],
        regex: /^\/settings\/encryption$/,
        href: "/settings/encryption"
    },
]


export default pagesSettings;