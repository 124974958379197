import React, {
  useEffect,
  useMemo
} from 'react';
import {
  Grid2,
  ClickAwayListener,
  LinearProgress,
  Box,
  Tooltip,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import { DataGridPro, useGridApiRef, GridToolbar } from '@mui/x-data-grid-pro';
import { useState, useRef } from 'react';
import { formatTimeDate } from 'utils/timeFormat';
import { getAllEventsByPage } from 'utils/api';
import usePreferences from 'hooks/usePreferences';
import HtmlTooltip from 'components/HtmlTooltip';
import RenderObject from 'components/RenderObject';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/system';

// hide total loaded in table
const FooterContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 20px;
  min-height: 52px;
`;

const CustomFooter = ({ rowCount }) => {
  return <FooterContainer>{rowCount ? `Total Rows: ${rowCount}` : ''} </FooterContainer>
}

const PAGE_SIZE = 100;
const preferencesID = "pages-logs-EventLogPagination";

export default function Eventlog({ eventsCount }) {
  const { preferences, updatePreferences } = usePreferences();
  const initialState = React.useMemo(() => preferences[preferencesID] || {}, [preferences]);
  const ROW_COUNT = eventsCount;
  const dataHolder = useRef([]);
  const allData = useRef([]);
  const ismouted = useRef(false);
  const apiRef = useGridApiRef();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    apiRef.current.subscribeEvent("scrollPositionChange", (params) => {
      const loadRows = (page) => {
        if (page * PAGE_SIZE > ROW_COUNT || page < 0) {
          return;
        }
        if (!allData.current[page]) {
          allData.current[page] = true;
          setIsLoading(true);
          getAllEventsByPage({ page: page + 1, limit: PAGE_SIZE })
            .then(events => {
              if (!dataHolder.current.length) {
                dataHolder.current = new Array(ROW_COUNT).fill({ word: 'loading...' }).map((el, index) => ({ ...el, id: index, date: Date.now() - index * 1000 }))
              }
              const res = events.map((event, index) => ({ ...event, id: index + page * PAGE_SIZE }))
              if (res.length > 0) {
                //console.log("got elemnts from backend:", res.length);
                dataHolder.current.splice(page * PAGE_SIZE, res.length, ...res);
              }
              else console.log("Error data wasn't loaded! page:", page, res.length);
              if (ismouted.current) {
                setRows([...dataHolder.current]);
              }
            })
            .finally(() => {
              if (ismouted.current) {
                setIsLoading(false)
              }
            })
        }
      }
      const firstVisible = Math.floor(params.renderContext.firstRowIndex / PAGE_SIZE);
      const lastVisible = Math.floor(params.renderContext.lastRowIndex / PAGE_SIZE);
      const pagesToLoad = Array.from(new Set([firstVisible, lastVisible])).map(el => el === -1 ? 0 : el);
      pagesToLoad.forEach(el => loadRows(el));
    });
  }, [apiRef, ROW_COUNT])
  useEffect(() => {
    ismouted.current = true;
    return () => {
      ismouted.current = true;
    }
  }, [])
  const handleClickAway = () => {
    if (apiRef.current && apiRef.current.setRowSelectionModel) {
      apiRef.current.setRowSelectionModel([])
    }
  }
  const groups = useSelector((state) => state.groups.groups);
  const groupMap = useMemo(() => {
    const _groupMap = {};
    groups.forEach(group => {
      group.devices.forEach(el => _groupMap[el] = group.name);
    })
    return _groupMap;
  }, [groups])
  const _columns = useMemo(() => {
    return [
      {
        field: "date",
        headerName: "Date",
        width: 175,
        valueFormatter: (value) => formatTimeDate(value),
        editable: false,
        sortable: false
      },
      {
        field: "ipaddr",
        headerName: "IP Address",
        width: 200,
        editable: false,
        sortable: false,
      },
      {
        field: "word",
        headerName: "Message",
        width: 200,
        editable: false,
        sortable: false,
        renderCell: (params) => <Tooltip title={params.value || ""}><Typography>{params.value || ""}</Typography></Tooltip>
      },
      {
        field: 'group',
        headerName: "Group",
        width: 130,
        valueGetter: (value, row) => groupMap[row.androidID] || '',
        editable: false,
        sortable: false,
      },
      {
        field: "messageID",
        headerName: "Message ID",
        width: 200,
        editable: false,
        sortable: false,
      },
      {
        field: "level",
        headerName: "Level",
        width: 200,
        editable: false,
        sortable: false,
      },
      {
        field: "url",
        headerName: "URL",
        width: 200,
        editable: false,
        sortable: false,
      },
      {
        field: "hostname",
        headerName: "Host Name",
        width: 200,
        editable: false,
        sortable: false,
      },
      {
        field: "value",
        headerName: "License Count",
        width: 200,
        editable: false,
        sortable: false,
      },
      {
        field: "androidID",
        headerName: "Android ID",
        width: 200,
        editable: false,
        sortable: false,
      },
      {
        field: "isConnected",
        headerName: "Connected to Wifi",
        width: 200,
        editable: false,
        sortable: false,
      },
      {
        field: "id",
        headerName: "ID",
        width: 250,
        editable: false,
        sortable: false,
      },
      {
        field: "details",
        headerName: "Details",
        width: 140,
        editable: false,
        sortable: false,
        align: "center",
        valueGetter: (value, row) => row.properties ? 1 : 0,
        renderCell: (params) => params.row.properties ? <HtmlTooltip title={<RenderObject object={params.row.properties} name="Details" />}><InfoIcon color="primary" /></HtmlTooltip> : ''
      },
    ];
  }, [groupMap])

  const columns = React.useMemo(() => {
    let error = false;
    if (initialState &&
      initialState.columns &&
      initialState.columns.cols) {
      const cols = new Array(_columns.length);
      _columns.forEach((el) => {
        if (!initialState.columns.cols[el.field]) {
          error = true;
          return;
        }
        cols[initialState.columns.cols[el.field].order] = { ...el, width: initialState.columns.cols[el.field].width }
      })
      if (!error) return cols;
    }
    return _columns;
  }, [initialState, _columns]);

  return (<Grid2 container justifyContent="center">
    <Grid2 size={12}>
      <div style={{ height: 1200, width: "100%" }}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <DataGridPro
            onStateChange={(state) => {
              updatePreferences(preferencesID, {
                density: state.density,
                columns: {
                  columnVisibilityModel: state.columns.columnVisibilityModel,
                  cols: apiRef.current.getAllColumns().map(({ field, width }, index) => ({ field, order: index, width })).reduce((p, c) => ({ ...p, [c.field]: c }), {}),
                }
              })
            }}
            initialState={initialState}
            apiRef={apiRef}
            slots={{
              toolbar: GridToolbar,
              loadingOverlay: LinearProgress,
              footer: CustomFooter
            }}
            slotProps={{
              footer: { rowCount: ROW_COUNT },
            }}
            columns={columns}
            rows={rows.slice(0, 340000)}
            rowCount={ROW_COUNT}
            loading={isLoading}
            disableChildrenFiltering={true}
            disableChildrenSorting={true}
            disableColumnFilter={true}
            disableMultipleColumnsSorting={true}
            paginationMode="server"
          />
        </ClickAwayListener>
      </div>
    </Grid2>
  </Grid2>
  );
}
