import { useState, useEffect, useMemo, useCallback } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { updateChatRoomAction, deleteChatRoomAction } from 'redux/chat/chat.actions';
import { imgURL } from "utils/http";
import { http } from 'utils/http';
import USER_TYPE from 'constants/userType';
import chatRoomType from 'constants/chatRoomType';
import useAuth from 'hooks/useAuth';
import useAuthorization from 'hooks/useAuthorization';
import Users from './Users';
import Infos from './Infos';
import RoomSelect from './RoomSelect';

const Wrapper = styled(Box)`
    height: 100%;
`

const Container = styled(Box)`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas:
    'header header header'
    'roomInfos availableUsers roomUsers';
    gap: 10px;
    padding: 10px;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr 1fr 1fr;
`

const RoomsSelect = styled(Box)`
    grid-area: header;
    background-color: ${(props) => props.theme.palette.background.paper};
    padding: 10px 0;
`

const AvailableUsers = styled(Box)`
    grid-area: availableUsers;
    background-color: ${(props) => props.theme.palette.background.paper};
    padding: 10px 4px;
    overflow: auto;
`
const RoomUsers = styled(Box)`
    grid-area: roomUsers;
    background-color: ${(props) => props.theme.palette.background.paper};
    padding: 10px 4px;
    padding: 0;
    overflow: auto;
`
const RoomInfos = styled(Box)`
    grid-area: roomInfos;
    padding: 10px 4px;
    background-color: ${(props) => props.theme.palette.background.paper};
    overflow: auto;
`

const ManageChatRooms = ({ selectedRoomID, handleClose }) => {
    const { type: currentUserType } = useAuth();
    const { isAuthorized } = useAuthorization();
    const canCreateChatRoom = isAuthorized("chatRoom.create");
    const canUpdateChatRoom = isAuthorized("chatRoom.update");
    const dispatch = useDispatch();
    const chatRooms = useSelector(state => state.chat.chatRooms);
    const [roomID, setRoomID] = useState(selectedRoomID);
    const selectedRoom = useMemo(() => chatRooms.find(el => el._id === roomID), [chatRooms, roomID])
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [participants, setParticipants] = useState(selectedRoom?.participants || []);

    const [name, setName] = useState(selectedRoom?.name || '');
    const [type, setType] = useState(selectedRoom?.type || chatRoomType.PRIVATE)
    const [fetching, setFetching] = useState(false);

    const resetForm = useCallback(() => {
        setRoomID(null);
        setParticipants([]);
        setName("new chat room");
        switch (currentUserType) {
            case USER_TYPE.ADMIN:
                setType(chatRoomType.PRIVATE);
                break;
            case USER_TYPE.SUPPORT:
                setType(chatRoomType.SUPPORT);
                break;
            case USER_TYPE.DEVICE:
                setType(chatRoomType.DEVICE);
                break;
            default:
                setType(chatRoomType.GROUP);
        }
    }, [currentUserType])
    useEffect(() => {
        const selectedRoom = chatRooms.find(el => el._id === selectedRoomID);
        if (selectedRoom) setRoomID(selectedRoomID);
        else if (chatRooms.length > 0) setRoomID(chatRooms[0]?._id);
        else {
            setIsCreateMode(true);
            resetForm();
        }
    }, [selectedRoomID, chatRooms, resetForm])
    const onlineUsers = useSelector((state) => state.chat.onlineUsers);
    const onlineUsersMap = useMemo(() => onlineUsers.reduce((a, c) => ({ ...a, [c]: true }), {}), [onlineUsers]);
    const users = useSelector(state => state.chat.users);
    const chatUsers = useMemo(() => users
        .map(el => ({
            ...el,
            isOnline: !!onlineUsersMap[el._id],
            profilePicture: el.type === USER_TYPE.DEVICE ? ("/static/img/avatars/android.png") :
                (el.imageUrl ? imgURL + el.imageUrl : "/static/img/avatars/admin.png")
        }))
        .filter(el => currentUserType !== USER_TYPE.DEVICE || [USER_TYPE.ADMIN, USER_TYPE.DEVICE].includes(el.type))
        , [users, onlineUsersMap, currentUserType]);

    const participantsMap = useMemo(() => participants.reduce((a, c) => ({ ...a, [c]: true }), {}), [participants]);
    useEffect(() => {
        setParticipants(selectedRoom?.participants || []);
        setName(selectedRoom?.name || '');
        switch (currentUserType) {
            case USER_TYPE.ADMIN:
                setType(selectedRoom?.type || chatRoomType.PRIVATE);
                break;
            case USER_TYPE.SUPPORT:
                setType(selectedRoom?.type || chatRoomType.SUPPORT);
                break;
            case USER_TYPE.DEVICE:
                setType(selectedRoom?.type || chatRoomType.DEVICE);
                break;
            default:
                setType(selectedRoom?.type || chatRoomType.GROUP);
        }
    }, [selectedRoom, currentUserType]);
    const roomUsers = useMemo(() => chatUsers.filter(el => participantsMap[el._id] && [USER_TYPE.ADMIN, USER_TYPE.DEVICE, USER_TYPE.SUPPORT].includes(el.type)), [participantsMap, chatUsers]);
    const availableUsers = useMemo(() => chatUsers.filter(el => !participantsMap[el._id] && [USER_TYPE.ADMIN, USER_TYPE.DEVICE, USER_TYPE.SUPPORT].includes(el.type)), [participantsMap, chatUsers]);
    const handleAddUser = (userID) => {
        setParticipants([...participants, userID]);
    }
    const handleRemoveUser = (userID) => {
        setParticipants(participants.filter(el => el !== userID));
    }

    const handleUpdateRoom = async () => {
        setFetching(true);
        try {
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/chat/room`,
                {
                    id: selectedRoom?._id,
                    name,
                    participants,
                    type
                });
            if (resp?.data?._id) {
                dispatch(updateChatRoomAction(resp.data));
                toast.success("Room Updated");
                setFetching(false);
            }
        } catch (error) {
            console.log("error", error);
            setFetching(false);
            if (error?.response?.data?.message) toast.error(error.response.data.message);
            else toast.error("Something went wrong");
        }
    }
    const handleCreateRoom = async () => {
        setFetching(true);
        try {
            const resp = await http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/chat/room`,
                {
                    name,
                    participants,
                    type
                });
            if (resp?.data?._id) {
                dispatch(updateChatRoomAction(resp.data));
                setRoomID(resp.data._id);
                setIsCreateMode(false);
                toast.success("Room Created");
                setFetching(false);
            }
        } catch (error) {
            console.log("error", error);
            setFetching(false);
            if (error?.response?.data?.message) toast.error(error.response.data.message);
            else toast.error("Something went wrong");
        }
    }


    const handleDeleteRoom = async () => {
        setFetching(true);
        try {
            const resp = await http.delete(`${process.env.REACT_APP_SERVER_URL}/api/v2/chat/room/${selectedRoom?._id}`)
            if (resp?.data?._id) {
                dispatch(deleteChatRoomAction(resp.data._id));
                toast.success("Room Deleted");
                setFetching(false);
            }
        } catch (error) {
            console.log("error", error);
            setFetching(false);
            if (error?.response?.data?.message) toast.error(error.response.data.message);
            else toast.error("Something went wrong");
        }
    }
    const updated = useMemo(() => {
        return name !== selectedRoom?.name ||
            participants.length !== selectedRoom?.participants?.length ||
            participants.join(",") !== selectedRoom?.participants.join(",")
    }, [participants, name, selectedRoom])
    const chatUsersMap = useMemo(() => chatUsers.reduce((a, c) => ({ ...a, [c._id]: c }), {}), [chatUsers]);
    const chatRoomsWithParticipantsMap = useMemo(() => {
        return chatRooms.map(el => ({ ...el, participantsMap: el.participants.reduce((a, c) => ({ ...a, [c]: true }), {}) }))
    }, [chatRooms]);


    const updateFormValidationError = useMemo(() => {
        if (isCreateMode) return "";
        if (!selectedRoom) return "Selected Room no longer exist"
        if (!name) return "Must provide a valid name";
        if (participants.length < 2) return "Room must have a least two participants";
        if (selectedRoom.type === chatRoomType.PRIVATE && participants.length !== 2) return "Private room can have only two participants";
        if (selectedRoom.type === chatRoomType.DEVICE) {
            if (participants.filter(el => /^([a-f0-9]){16}$/.test(el)).length !== 1) return "Private room must contain one device";
            const androidID = participants.find(el => /^([a-f0-9]){16}$/.test(el))
            if (chatRoomsWithParticipantsMap.filter(el => el._id !== selectedRoom._id && el.type === chatRoomType.DEVICE).filter(el => el.participants.includes(androidID)).length > 0) return "Device Room for this device already exist";
        }
        if (selectedRoom.type === chatRoomType.SUPPORT) {
            if (participants.length !== 2) return "Support room must contain two participants";
            if (participants.some(el => /^([a-f0-9]){16}$/.test(el))) return "Support room can not contain devices";
            if (participants.filter(el => chatUsersMap[el]?.type === USER_TYPE.SUPPORT).length !== 1) return "Support room must have a support member";
        }
        if (selectedRoom.type !== chatRoomType.SUPPORT && participants.some(el => chatUsersMap[el]?.type === USER_TYPE.SUPPORT)) return "Only Support rooms can have a memeber from support";
        if (chatRoomsWithParticipantsMap.find(el => el._id !== selectedRoom._id && el.type === selectedRoom.type && el.participants.length === participants.length && participants.every(participant => el.participantsMap[participant]))) return "A Room with same type and participants already exist";
        return null;
    }, [selectedRoom, name, participants, chatRoomsWithParticipantsMap, chatUsersMap, isCreateMode])

    const createFormValidationError = useMemo(() => {
        if (!isCreateMode) return "";
        if (!name) return "Must provide a valid name";
        if (participants.length < 2) return "Room must have a least two participants";
        if (type === chatRoomType.PRIVATE && participants.length !== 2) return "Private room can have only two participants";
        if (type === chatRoomType.DEVICE) {
            if (participants.filter(el => /^([a-f0-9]){16}$/.test(el)).length !== 1) return "Private room must contain one device";
            const androidID = participants.find(el => /^([a-f0-9]){16}$/.test(el))
            if (chatRoomsWithParticipantsMap.filter(el => el.type === chatRoomType.DEVICE).filter(el => el.participants.includes(androidID)).length > 0) return "Device Room for this device already exist";
        }
        if (type === chatRoomType.SUPPORT) {
            if (participants.length !== 2) return "Support room must contain two participants";
            if (participants.some(el => /^([a-f0-9]){16}$/.test(el))) return "Support room can not contain devices";
            if (participants.filter(el => chatUsersMap[el]?.type === USER_TYPE.SUPPORT).length !== 1) return "Support room must have a support member";
        }
        if (type !== chatRoomType.SUPPORT && participants.some(el => chatUsersMap[el]?.type === USER_TYPE.SUPPORT)) return "Only Support rooms can have a memeber from support";
        if (chatRoomsWithParticipantsMap.find(el => el.type === type && el.participants.length === participants.length && participants.every(participant => el.participantsMap[participant]))) return "A Room with same type and participants already exist";
        return null;
    }, [type, name, participants, chatRoomsWithParticipantsMap, chatUsersMap, isCreateMode])
    const canAddAdmin = useMemo(() => {
        if (!canUpdateChatRoom && !canCreateChatRoom) return false;
        if (type === chatRoomType.DEVICE) return true;
        if (type === chatRoomType.GROUP) return true;
        if (type === chatRoomType.PRIVATE) return participants.length < 2;
        if (type === chatRoomType.SUPPORT) return participants.filter(el => chatUsersMap[el]?.type === USER_TYPE.ADMIN).length === 0;
        return false;
    }, [type, participants, chatUsersMap, canUpdateChatRoom, canCreateChatRoom]);

    const canAddDevice = useMemo(() => {
        if (!canUpdateChatRoom && !canCreateChatRoom) return false;
        if (type === chatRoomType.SUPPORT) return false;
        if (type === chatRoomType.DEVICE) return participants.filter(el => /^([a-f0-9]){16}$/.test(el)).length === 0;
        if (type === chatRoomType.GROUP) return true;
        if (type === chatRoomType.PRIVATE) return participants.length < 2;
        return false;
    }, [participants, type, canUpdateChatRoom, canCreateChatRoom]);

    const canAddSupport = useMemo(() => {
        if (!canUpdateChatRoom && !canCreateChatRoom) return false;
        if (type === chatRoomType.SUPPORT) return participants.filter(el => chatUsersMap[el]?.type === USER_TYPE.SUPPORT).length === 0;
        return false;
    }, [type, participants, chatUsersMap, canUpdateChatRoom, canCreateChatRoom]);


    return <Wrapper>
        <Container>
            <RoomsSelect>
                <RoomSelect
                    roomID={roomID}
                    setRoomID={setRoomID}
                    defaultRoom={selectedRoomID}
                    isCreateMode={isCreateMode}
                    setIsCreateMode={setIsCreateMode}
                    resetForm={resetForm}
                    handleClose={handleClose}
                />
            </RoomsSelect>
            <RoomInfos>
                <Infos
                    name={name}
                    setName={setName}
                    type={type}
                    setType={setType}
                    createdAt={selectedRoom?.createdAt}
                    handleUpdateRoom={handleUpdateRoom}
                    handleDeleteRoom={handleDeleteRoom}
                    handleCreateRoom={handleCreateRoom}
                    isCreateMode={isCreateMode}
                    fetching={fetching}
                    updated={updated}
                    updateFormValidationError={updateFormValidationError}
                    createFormValidationError={createFormValidationError}
                    createdBy={selectedRoom?.createdBy}
                />
            </RoomInfos>
            <AvailableUsers>
                <Users
                    users={availableUsers}
                    addUser={(canUpdateChatRoom || canCreateChatRoom) ? handleAddUser: null}
                    title={`Available Users (${availableUsers.length})`}
                    canAddAdmin={canAddAdmin}
                    canAddDevice={canAddDevice}
                    canAddSupport={canAddSupport}
                />
            </AvailableUsers>
            <RoomUsers>
                <Users
                    users={roomUsers}
                    removeUser={(canUpdateChatRoom || canCreateChatRoom) ? handleRemoveUser : null}
                    title={`Room Users (${roomUsers.length})`}
                />
            </RoomUsers>
        </Container>
    </Wrapper>
}
export default ManageChatRooms;
