import React, { useState, useMemo } from 'react';
import { styled } from '@mui/system';
import { spacing } from '@mui/system';
import {
    Card as MuiCard,
    CardContent,
    Grid2,
    Avatar as MuiAvatar,
    Typography,
    Badge,
    IconButton,
    Button,
    Box,
    TextField
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { imgURL, http } from 'utils/http';
import useAuth from 'hooks/useAuth';
import { deleteAdminAction } from 'redux/admins/admins.actions';
import { deleteChatUserAction } from 'redux/chat/chat.actions';
import { formatTimeDate } from 'utils/timeFormat';
import Authorized from 'components/Authorized';
import useAuthorization from 'hooks/useAuthorization';
import chatRoomType from 'constants/chatRoomType';

const Avatar = styled(MuiAvatar)`
  width: 64px;
  height: 64px;
  outline:1px solid gray;
`;
const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) => props.color === 'primary' ?
        props.theme.sidebar.footer.online.background :
        props.theme.sidebar.footer.offline.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const CustomLink = (props) => {
    return <a href={props.href} {...props} >{props.children}</a>
}
const DeleteConfirmation = ({ firstName, lastName, cancel, deleteUser, password, setPassword, fetching }) => {
    return <Grid2 sx={{ flexGrow: 1 }}>
        <Grid2 container sx={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Typography variant="h5" color="textPrimary">
                Are you sure you want to delete Admin {firstName} {lastName}
            </Typography>
            <Box container sx={{ my: 2 }}>
                <TextField
                    label="Type your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    type="password"
                    size="small"
                />
                <Button
                    variant="contained"
                    color="error"
                    sx={{ mx: 1 }}
                    onClick={deleteUser}
                    disabled={password.length < 6 || fetching}
                >
                    Yes
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mx: 1 }}
                    onClick={cancel}
                >
                    No
                </Button>
            </Box>
        </Grid2>
    </Grid2>
}
const AdminsInfos = ({ firstName, lastName, email, designation, address, phone, id, myID, role, lastLogin }) => {
    return <Grid2 container size={12}>
        <Grid2 container size={{ xs: 12, md: 6 }}>
            <Grid2 size={12}>
                <PersonIcon sx={{ mr: 1 }} />
                <Typography variant="h5" component="span" color="textPrimary">
                    {firstName} {lastName} {myID === id ? "(You)" : null}
                </Typography>
            </Grid2>
            <Grid2 size={12}>
                <EmailIcon sx={{ mr: 1 }} />
                <Typography variant="subtitle1" color="textPrimary" component={CustomLink} to={`mailto:${email}`} style={{ textDecoration: 'none' }}>
                    {email}
                </Typography>
            </Grid2>
            {designation && <Grid2 size={12}>
                <AssignmentIcon sx={{ mr: 1 }} />
                <Typography variant="subtitle1" color="textPrimary" component="span">
                    {designation}
                </Typography>
            </Grid2>}
            <Grid2 size={12}>
                <Authorized permission="page.adminsPermissions">
                    <Link to={`/settings/admin/${id}`} style={{ textDecoration: 'none' }}>
                        <AssignmentIndIcon sx={{ mr: 1 }} color="primary" />
                        {role?.name && <Typography sx={{ textTransform: 'lowercase' }} variant="subtitle1" color="textPrimary" component="span">
                            {role.name}
                        </Typography>}
                    </Link>
                </Authorized>
            </Grid2>
        </Grid2>
        <Grid2 container size={{ xs: 12, md: 6 }}>
            {address && <Grid2 size={12}>
                <HomeIcon sx={{ mr: 1 }} />
                <Typography variant="subtitle1" color="textPrimary" component="span">
                    {address}
                </Typography>
            </Grid2>}
            {phone && <Grid2 size={12}>
                <LocalPhoneIcon sx={{ mr: 1 }} />
                <Typography variant="subtitle1" color="textPrimary" component="span">
                    {phone}
                </Typography>
            </Grid2>}
            {lastLogin && <Grid2 size={12}>
                <AccessTimeIcon sx={{ mr: 1 }} />
                <Typography variant="subtitle1" color="textPrimary" component="span">
                    {formatTimeDate(lastLogin)}
                </Typography>
            </Grid2>}
        </Grid2>
    </Grid2>
}
const AdminInfoContainer = ({ firstName, lastName, email, designation, address, phone, id, myID, role, lastLogin }) => {
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [password, setPassword] = useState(process.env.REACT_APP_DEFAULT_DEV_PASSWORD || '');
    const dispatch = useDispatch();
    const handDeleteUser = async () => {
        setFetching(true);
        try {
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/delete-user`, {
                password,
                userID: id
            })
            setFetching(false);
            if (resp.data && resp.data.success) {
                toast.success(resp.data.message);
                setFetching(false);
                setPassword('');
                dispatch(deleteChatUserAction(id));
                dispatch(deleteAdminAction(id));
            }
        } catch (err) {
            setFetching(false);
            if (err && err.response && err.response.data && err.response.data.message) {
                return toast.error(err.response.data.message);
            }
            console.error("error", err);
            toast.error("Something went wrong");
        }
    }
    const handleCancel = () => {
        setDeleteConfirmation(false);
        setPassword('');
    }
    return <React.Fragment>
        {deleteConfirmation ? (
            <DeleteConfirmation
                firstName={firstName}
                lastName={lastName}
                cancel={handleCancel}
                deleteUser={handDeleteUser}
                password={password}
                setPassword={setPassword}
                fetching={fetching}
            />
        ) : (
            <AdminsInfos
                firstName={firstName}
                lastName={lastName}
                email={email}
                designation={designation}
                address={address}
                phone={phone}
                id={id}
                myID={myID}
                role={role}
                lastLogin={lastLogin}
            />
        )}
        <React.Fragment>
            {id !== myID && (deleteConfirmation ? (
                <IconButton
                    aria-label="cancel"
                    sx={{ color: 'primary.main' }}
                    onClick={() => setDeleteConfirmation(false)}>
                    <CancelIcon />
                </IconButton>
            ) : (
                <Authorized permission="admin.deleteAdmin">
                    <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => setDeleteConfirmation(true)}>
                        <DeleteIcon />
                    </IconButton>
                </Authorized>
            ))}
        </React.Fragment>
    </React.Fragment>
}
const Card = styled(MuiCard)(spacing);

const AdminsList = () => {
    const { isAuthorized } = useAuthorization();
    const { userID } = useAuth();
    const canChat = isAuthorized("page.chat");
    const admins = useSelector((state) => state.admins.admins || []);
    const chatRooms = useSelector((state) => state.chat.chatRooms || []);

    const adminsPrivateChatRoomMap = useMemo(() => {
        return chatRooms
            .filter(el => el.type === chatRoomType.PRIVATE && el.participants.includes(userID))
            .reduce((a, c) => ({ ...a, [c.participants.filter(el => el !== userID)]: c._id }), {});
    }, [userID, chatRooms]);
    const allRoles = useSelector((state) => state.admins.roles || []);
    const allRolesMap = useMemo(() => {
        return allRoles.reduce((a, c) => ({ ...a, [c._id]: c }), {});
    }, [allRoles])
    const onlineUsers = useSelector((state) => state.chat.onlineUsers || []);
    const onlineUsersMap = useMemo(() => onlineUsers.reduce((p, c) => ({ ...p, [c]: c }), {}), [onlineUsers]);

    return (
        <React.Fragment>
            {admins
                .map(({
                    _id,
                    firstName,
                    lastName,
                    displayName,
                    email,
                    phone,
                    address,
                    designation,
                    imageUrl,
                    roles,
                    lastLogin
                }) => (
                    <Card sx={{ mb: 3 }} key={_id}>
                        <CardContent>
                            <Grid2 container spacing={1}>
                                <Grid2 size={{ xs: 12, sm: 3, md: 2, xl: 1 }}>
                                    <Button
                                        disabled={!adminsPrivateChatRoomMap[_id]}
                                        component={(userID === _id || !canChat || !adminsPrivateChatRoomMap[_id]) ? null : Link}
                                        to={`/chat/${adminsPrivateChatRoomMap[_id]}`}
                                    >
                                        <FooterBadge
                                            overlap="circular"
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "right",
                                            }}
                                            variant="dot"
                                            color={onlineUsersMap[_id] ? 'primary' : 'secondary'}
                                        >
                                            <Avatar
                                                alt={displayName}
                                                src={imageUrl ? `${imgURL}${imageUrl}` : `/static/img/avatars/admin.png`}
                                            />
                                        </FooterBadge>
                                    </Button>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 9, md: 10, xl: 11 }}>
                                    <AdminInfoContainer
                                        firstName={firstName}
                                        lastName={lastName}
                                        email={email}
                                        designation={designation}
                                        address={address}
                                        phone={phone}
                                        id={_id}
                                        myID={userID}
                                        role={roles && roles[0] ? allRolesMap[roles[0]] : {}}
                                        lastLogin={lastLogin}
                                    />
                                </Grid2>
                            </Grid2>
                        </CardContent>
                    </Card>
                ))
            }
        </React.Fragment>
    );
}
export default AdminsList;