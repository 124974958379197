import { styled } from '@mui/system';
import { ReactComponent as APPLogo } from '../../vendor/logo-small.svg';
const Container = styled(APPLogo)`
height: auto;
width: 100%;
.logo-text-bottom{
    fill: ${(props) => props.theme.sidebar.header.brand.logoTextBottom};
}
.logo-text-top{
    fill: ${(props) => props.theme.sidebar.header.brand.logoTextTop};
}
.logo-part-1{
    fill:#63c660;
}
.logo-part-2{
    fill:#a3dd9e;
}
.logo-part-3{
    fill:#8ed8be;
}
.logo-part-4{
    fill:#45bc5a;
}

`;
const LogoSmall = (props) => {
    return <>
        <Container
            component="img"
            alt="Dashalytics by GSW"
            {...props}
        />
    </>
}
export default LogoSmall;
