import { Tooltip } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';

import { isValidUserBadge } from 'utils/badge';
const BadgeValid = ({ badgeID, badgesSettings }) => {
    if (badgesSettings.length === 0) return <Tooltip title="Badge setting is missing"><WarningIcon color="warning" /></Tooltip>
    if (!badgesSettings.some(el => isValidUserBadge({ ...el, testCode: badgeID }))) return <Tooltip title="Badge ID does not match any of your badge settings"><WarningIcon color="warning" /></Tooltip>
    return <Tooltip title="Badge ID matched at least one Badge Setting"><CheckCircleOutlineIcon color="secondary" /></Tooltip>
}

export default BadgeValid;