import UTS_AGENT from 'constants/utsAgent';

const IPV4_REGEX = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
const parseUtsEvents = (events, logData = {}) => {
    logData[UTS_AGENT.PID_FOUND_PER_EVENTS_LOG] = new Set();
    events.forEach((event) => {
        try {
            parseUtsEvent(event.orgMsg, logData);
        } catch (error) {
            console.log("error", error)
        }
    });
    return logData;
}

const parseUtsEvent = (msg, oldState = {}) => {
    const logData = oldState;
    try {
        const decoded = atob(msg);
        const lines = decoded.split("\n");
        const dateLine = lines.find(el => el.startsWith("Date:"));
        if (!dateLine) return {};
        const strDate = dateLine.replace("Date:", "").trim();
        if (isNaN((new Date(strDate)).getTime())) return {};
        const date = (new Date(strDate)).toISOString();
        const pidLine = lines.find(el => el.startsWith("PID:"));
        if (!pidLine) return {};
        const strPid = pidLine.match(/\d+\(\d+\)/);
        const pid = strPid && strPid[0] ? strPid[0] : undefined;
        if (logData[UTS_AGENT.PID_FOUND_PER_EVENTS_LOG]) logData[UTS_AGENT.PID_FOUND_PER_EVENTS_LOG].add(pid);
        logData[UTS_AGENT.PID_DATA] = logData[UTS_AGENT.PID_DATA] || {};
        logData[UTS_AGENT.PID_DATA][pid] = logData[UTS_AGENT.PID_DATA][pid] || {};
        const eventData = logData[UTS_AGENT.PID_DATA][pid];
        eventData[UTS_AGENT.PID] = pid;
        eventData[UTS_AGENT.DATE] = date;
        eventData[UTS_AGENT.LAST_DATE] = date;
        eventData[UTS_AGENT.FIRST_DATE] = eventData[UTS_AGENT.FIRST_DATE] || eventData[UTS_AGENT.DATE] || date;
        logData[UTS_AGENT.LAST_DATE] = date;
        logData[UTS_AGENT.FIRST_DATE] = logData[UTS_AGENT.FIRST_DATE] || eventData[UTS_AGENT.FIRST_DATE] || eventData[UTS_AGENT.DATE] || date;
        const userMessageLineIndex = lines.findIndex(el => el.startsWith("User message"));
        if (userMessageLineIndex >= 0) {
            const userMessageLine = lines.splice(userMessageLineIndex, 1);
            eventData[UTS_AGENT.LAST_MESSAGE] = userMessageLine[0];
            lines.push(...userMessageLine);
        }
        lines.forEach(line => {
            if (line.trim().length === 0) {
                return;
            }
            if (line.startsWith("***")) {
                const matchedStrings = line.match(/\*\*\*GS_Agnt(.+)->Info\*\*\*/);
                const agent = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (agent) eventData[UTS_AGENT.AGENT] = agent;
                return;
            }

            if (line.startsWith("Location: file ")) {
                const str = line.replace(/(Location: file )|( line )/g, "").trim();
                const fileName = str.split(",")[0];
                const lineNumber = parseInt(str.split(",")[1]);
                eventData[UTS_AGENT.LOCATION_FILE_NAME] = fileName;
                eventData[UTS_AGENT.LOCATION_LINE_NUMBER] = lineNumber;
                return;
            }
            if (!line.startsWith("User message:")) return;
            const str = line.replace("User message:", "").trim();
            const message = str || undefined;
            //User message: Initializing events table/
            if (message.startsWith("Initializing events table")) {
                eventData[UTS_AGENT.BEGIN_IND_COUNT] = eventData[UTS_AGENT.BEGIN_IND_COUNT] ? eventData[UTS_AGENT.BEGIN_IND_COUNT] + 1 : 1;
                return;
            }

            // User message: appUnInitApplication\(\) completed
            if (message.startsWith("appUnInitApplication() completed")) {
                eventData[UTS_AGENT.END_IND_COUNT] = eventData[UTS_AGENT.END_IND_COUNT] ? eventData[UTS_AGENT.END_IND_COUNT] + 1 : 1;
                return;
            }

            if (message.startsWith("Peer address is <")) {
                const matchedStrings = message.match(/Peer address is <(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})>/);
                const ip = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (IPV4_REGEX.test(ip)) {
                    if (!logData[UTS_AGENT.IP]) logData[UTS_AGENT.IP] = [];
                    logData[UTS_AGENT.IP].push({
                        ip,
                        date,
                        pid
                    })
                    if (!eventData[UTS_AGENT.IP]) eventData[UTS_AGENT.IP] = [];
                    eventData[UTS_AGENT.IP].push({
                        ip,
                        date,
                        pid
                    })
                    eventData[UTS_AGENT.DEVICE_IP] = ip;
                    if (!logData[UTS_AGENT.DEVICE_IP]) logData[UTS_AGENT.DEVICE_IP] = [];
                    logData[UTS_AGENT.DEVICE_IP].push(ip);
                }
                return;
            }
            // Reconnect, socket is 0x0000000000000ED8, was 0x0000000000000CE4.
            if (message.startsWith("Reconnect, socket is ")) {
                const matchedStrings = message.match(/Reconnect, socket is (.+), was (.+)./);
                const oldID = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                const newID = matchedStrings && matchedStrings[2] ? matchedStrings[2] : undefined;
                if (!eventData[UTS_AGENT.RECONNECT_SOCKET]) eventData[UTS_AGENT.RECONNECT_SOCKET] = []
                eventData[UTS_AGENT.RECONNECT_SOCKET].push({
                    oldID,
                    newID,
                    date,
                });
                return;
            }
            //User message: Attempting to logon <\rfgun>
            if (message.startsWith("Attempting to logon <\\")) {
                const username = message.replace("Attempting to logon <\\", "").replace(">", "").trim();
                eventData[UTS_AGENT.WINDOWS_UN] = username;
                /*
                if (!eventData[UTS_AGENT.LOGON_ATTEMPT]) eventData[UTS_AGENT.LOGON_ATTEMPT] = []
                eventData[UTS_AGENT.LOGON_ATTEMPT].push({
                    date,
                    username,
                });
                */
                return;
            }
            // Autologon match found in 
            if (message.startsWith("Autologon match found in  <\\")) {
                if (message.includes("gs_auto.txt")) eventData[UTS_AGENT.CLIENT_TYPE] = "GSW Client";
                if (message.includes("gs_logon.txt")) eventData[UTS_AGENT.CLIENT_TYPE] = "3rd Party";
                const username = message.replace("Autologon match found in  <\\", "").replace(">", "").trim();
                if (eventData[UTS_AGENT.AUTO_LOGON]) eventData[UTS_AGENT.AUTO_LOGON] = []
                eventData[UTS_AGENT.AUTO_LOGON].push({
                    date,
                    username, // regex not tested
                });
                return;
            }


            // "User message: Variable <XXXXXX> found in <XXXXXX>, val is <> \n";
            // "User message: Variable <XXXXXX> not found in <XXXXXX> \n"

            // Variable <gwtn_job_control> not found in <C:\GS_UTS\scripts\localusers\rfgun\k_start.bat>
            /*
            if (message.startsWith("Variable <") && message.includes("> not found in <")) {
                const matchedStrings = message.match(/Variable <(.+)> not found in <(.+)>/);
                const name = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                const location = matchedStrings && matchedStrings[2] ? matchedStrings[2] : undefined;
                if (!eventData[UTS_AGENT.NOT_FOUND_VARS]) eventData[UTS_AGENT.NOT_FOUND_VARS] = [];
                if (name && location) eventData[UTS_AGENT.NOT_FOUND_VARS].push({
                    name,
                    location
                })
                return;
            }
            */


            // /User message: Variable <(.*)> .* <.*>, val is <(.*)>/
            // example: Variable <gwtn_reconnect> found in <C:\GS_UTS\scripts\localusers\RF\c_start.bat>, val is <auto_by_user_and_ip_always>
            /*
            if (message.startsWith("Variable <") && message.includes("> found in <")) {
                const matchedStrings = message.match(/Variable <(.+)> found in <(.+)>, val is <(.+)>/);
                const name = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                const location = matchedStrings && matchedStrings[2] ? matchedStrings[2] : undefined;
                const value = matchedStrings && matchedStrings[3] ? matchedStrings[3] : undefined;
                if (!eventData[UTS_AGENT.FOUND_VARS]) eventData[UTS_AGENT.FOUND_VARS] = [];
                if (name && location) eventData[UTS_AGENT.FOUND_VARS].push({
                    name,
                    location,
                    value
                })
                return;
            }
            */

            // User message: Shell process terminated, exit code 0
            if (message.startsWith("Shell process terminated, exit code 0")) {
                eventData[UTS_AGENT.NORMAL_EXIT] = true;
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }


            // Inactivity timeout occurred, about to exit
            if (message.startsWith("Inactivity timeout occurred, about to exit")) {
                eventData[UTS_AGENT.INACTIVITY_TIMEOUT] = true;
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            // User message: Shell process terminated, exit code 0(dec) 00000000(hex).

            // Async read from client returned error, completion code = 10053
            if (message.startsWith("Async read from client returned error, completion code = 10053")) {
                eventData[UTS_AGENT.WINSOCK] = true;
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }
            // User message: Connection to client closed/
            if (message.startsWith("Connection to client closed")) {
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }


            // User message: appSafeRecv timeout
            if (message.startsWith("appSafeRecv timeout")) {
                eventData[UTS_AGENT.GSW_CLIENT_TIMEOUT] = true;
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }


            // not validated
            // /User message: ReadChar\(\) failed/
            if (message.startsWith("ReadChar() failed")) {
                eventData[UTS_AGENT.READ_CHAR] = true;
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }
            // not validated
            // User message: TelnetLogon\(\) failed
            if (message.startsWith("TelnetLogon") && message.includes("failed")) {
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            ///User message: Client disconnected./
            if (message.startsWith("Client disconnected.")) {
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            // /User message: HeartBeat not received, about to exit/
            if (message.startsWith("HeartBeat not received, about to exit")) {
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            // User message: Unable to receive after shutdown.
            if (message.startsWith("Unable to receive after shutdown")) {
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            // User message: recv\(\) failed, error 10053/
            if (message.startsWith("recv() failed, error 10053")) {
                eventData[UTS_AGENT.WINSOCK] = true;
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            ///User message: recv\(\) failed, error 10054/
            if (message.startsWith("recv() failed, error 10054")) {
                eventData[UTS_AGENT.WINSOCK] = true;
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            //User message: shutdown OK, about to start the receive loop
            if (message.startsWith("shutdown OK, about to start the receive loop")) {
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            //User message: appUnInitApplication\(\) called
            if (message.startsWith("appUnInitApplication() called")) {
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            // User message: Failed to write prompt./
            if (message.startsWith("Failed to write prompt.")) {
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            //User message: appAnalysePreferences\(\) failed
            if (message.startsWith("appAnalysePreferences() failed")) {
                eventData[UTS_AGENT.INVALID_REGISTRATION] = message;
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            //User message: HeartBeat delayed by more than 20 secs
            if (message.startsWith("HeartBeat delayed by more than 20 secs")) {
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            // User message: Client closed connection
            if (message.startsWith("Client closed connection")) {
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            //User message: Failed to read first char of user name.
            if (message.startsWith("Failed to read first char of user name.")) {
                eventData[UTS_AGENT.READ_CHAR] = true;
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            //User message: Control event
            if (message.startsWith("Control event")) {
                eventData[UTS_AGENT.CONTROL_EVENT] = true;
                return;
            }

            //User message: Reconnect timeout expired
            if (message.startsWith("Reconnect timeout expired")) {
                eventData[UTS_AGENT.SUSPEND_SESSION_COUNT] = eventData[UTS_AGENT.SUSPEND_SESSION_COUNT] ? eventData[UTS_AGENT.SUSPEND_SESSION_COUNT] + 1 : 1;
                // this could be a bug (same code exit in old tool)
                eventData[UTS_AGENT.CONTROL_EVENT] = true;
            }

            // User message: Shell process terminated, exit code 200
            if (message.startsWith("Shell process terminated, exit code 200")) {
                eventData[UTS_AGENT.EXIT_CODE_200] = true;
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            // User message: Shell process terminated, exit code 100
            if (message.startsWith("Shell process terminated, exit code 100")) {
                eventData[UTS_AGENT.EXIT_CODE_100] = true;
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            //User message: Shell process terminated, exit code 255
            if (message.startsWith("Shell process terminated, exit code 255")) {
                eventData[UTS_AGENT.EXIT_CODE_255] = true;
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }

            // leave as last parser if non matches ?
            if (message.startsWith("Shell process terminated, exit code ")) {
                if (!eventData[UTS_AGENT.CONNECTION_ENDED]) eventData[UTS_AGENT.CONNECTION_ENDED] = []
                eventData[UTS_AGENT.CONNECTION_ENDED].push({
                    reason: message,
                    date
                });
                return;
            }
            /*
            //User message: Validating <192.168.1.49>
            if (message.startsWith("Validating <")) {
                const matchedStrings = message.match(/Validating <(.+)>/);
                const ip = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.IP_VALIDATE]) eventData[UTS_AGENT.IP_VALIDATE] = [];
                if (IPV4_REGEX.test(ip)) eventData[UTS_AGENT.IP_VALIDATE].push({
                    ip,
                    date,
                });
                return;
            }
            */
            //User message: not loaded, attempting to LoadUsersPreferences().
            /*
            if (message.startsWith("not loaded, attempting to LoadUsersPreferences().")) {
                if (!eventData[UTS_AGENT.LOAD_USERS_PREFERENCES_ATTEMPT]) eventData[UTS_AGENT.LOAD_USERS_PREFERENCES_ATTEMPT] = [];
                eventData[UTS_AGENT.LOAD_USERS_PREFERENCES_ATTEMPT].push({
                    date
                });
                return;
            }
            */
            /*
            //NEW_SHELL_PROCESS
            // User message: New shell process created, id = 4930(hex)18736(dec)
            if (message.startsWith("New shell process created,")) {
                const matchedStrings = message.match(/New shell process created, id = (.+)\(hex\)(.+)\(dec\)/);
                const idHex = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                const idDec = matchedStrings && matchedStrings[2] ? matchedStrings[2] : undefined;
                if (!eventData[UTS_AGENT.NEW_SHELL_PROCESS]) eventData[UTS_AGENT.NEW_SHELL_PROCESS] = [];
                if (idHex && idDec) eventData[UTS_AGENT.NEW_SHELL_PROCESS].push({
                    idHex,
                    idDec,
                    date
                });
                return;
            }
            */
            /*
             // User message: CMDLINE=<cmd /C "C:\GS_UTS\scripts\localusers\RF\c_start.bat">
             if (message.startsWith("CMDLINE=<")) {
                 const matchedStrings = message.match(/CMDLINE=<(.+)>/);
                 const cmdLine = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                 if (!eventData[UTS_AGENT.CMD_LINE]) eventData[UTS_AGENT.CMD_LINE] = [];
                 if (cmdLine) eventData[UTS_AGENT.CMD_LINE].push({
                     cmdLine,
                     date
                 });
                 return;
             }
             */
            //User message: OpenProcess failed for PID.          
            /*
            if (message.startsWith("OpenProcess failed for PID")) {
                if (!eventData[UTS_AGENT.OPEN_PROCESS_FAILED]) eventData[UTS_AGENT.OPEN_PROCESS_FAILED] = [];
                eventData[UTS_AGENT.OPEN_PROCESS_FAILED].push({
                    message, // extract pid once having a valid message
                    date
                });
                return;
            }
            */
            /*
             // User message: Termination string not found in
             if (message.startsWith("Termination string not found in <")) {
                 if (!eventData[UTS_AGENT.TERMINATION_STRING_NOT_FOUND]) eventData[UTS_AGENT.TERMINATION_STRING_NOT_FOUND] = [];
                 const matchedStrings = message.match(/Termination string not found in <(.+)>/);
                 const file = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                 eventData[UTS_AGENT.TERMINATION_STRING_NOT_FOUND].push({
                     file,
                     date
                 });
                 return;
             }
             */
            // User message: Failed to open 
            // example: User message: Failed to open <C:\GS_UTS\thosts>
            /*
            if (message.startsWith("Failed to open <")) {
                if (!eventData[UTS_AGENT.FAILED_TO_OPEN]) eventData[UTS_AGENT.FAILED_TO_OPEN] = [];
                const matchedStrings = message.match(/Failed to open <(.+)>/);
                const file = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                eventData[UTS_AGENT.FAILED_TO_OPEN].push({
                    file,
                    date
                });
                return;
            }
            */
            // Version notification received from the client
            /*
            if (message.startsWith("Version notification received from the client")) {
                if (!eventData[UTS_AGENT.VERSION_NOTIFICATION]) eventData[UTS_AGENT.VERSION_NOTIFICATION] = [];
                eventData[UTS_AGENT.VERSION_NOTIFICATION].push({
                    date
                });
                return;
            }
            */

            // Client MAC is <XXXXX> 
            /*
            if (message.startsWith("Client MAC is <")) {
                const matchedStrings = message.match(/Client MAC is <(.+)>/);
                const mac = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.CLIENT_MAC]) eventData[UTS_AGENT.CLIENT_MAC] = [];
                eventData[UTS_AGENT.CLIENT_MAC].push({
                    mac,
                    date
                });
                return;
            }
            */
            //User message: Host <Hostame> 
            /*
            if (message.startsWith("Host <")) {
                const matchedStrings = message.match(/Host <(.+)>/);
                const hostname = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.HOSTNAME]) eventData[UTS_AGENT.HOSTNAME] = [];
                eventData[UTS_AGENT.HOSTNAME].push({
                    hostname,
                    date
                });
                return;
            }
            */
            //<XXXXXX> was already loaded
            /*
            if (message.endsWith("> was already loaded")) {
                const matchedStrings = message.match(/<(.+)> was already loaded/);
                const sid = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.ALREADY_LOADED]) eventData[UTS_AGENT.ALREADY_LOADED] = [];
                eventData[UTS_AGENT.ALREADY_LOADED].push({
                    sid,
                    date
                });
                return;
            }
            */
            //Unable to resolve IP address 
            /*
            if (message.startsWith("Unable to resolve IP address <")) {
                const matchedStrings = message.match(/Unable to resolve IP address <(.+)>/);
                const ip = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.UNABLE_TO_RESLOVE_IP]) eventData[UTS_AGENT.UNABLE_TO_RESLOVE_IP] = [];
                if (IPV4_REGEX.test(ip)) eventData[UTS_AGENT.UNABLE_TO_RESLOVE_IP].push({
                    ip,
                    date
                });
                return;
            }
            */
            /*
             // <XXX.XXX.XXX.XXX> is not a valid IP6 address 
             if (message.endsWith("> is not a valid IP6 address")) {
                 const matchedStrings = message.match(/<(.+)> is not a valid IP6 address/);
                 const ip = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                 if (!eventData[UTS_AGENT.INVALID_IP6]) eventData[UTS_AGENT.INVALID_IP6] = [];
                 eventData[UTS_AGENT.INVALID_IP6].push({
                     ip,
                     date
                 });
                 return;
             }
             */
            /*
            // User message: File xfer event fired PUT:<file path
            if (message.startsWith("File xfer event fired PUT:<")) {
                const matchedStrings = message.match(/File xfer event fired PUT:<(.+)>/);
                const fileName = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.PUT_XFER_EVENT]) eventData[UTS_AGENT.PUT_XFER_EVENT] = [];
                eventData[UTS_AGENT.PUT_XFER_EVENT].push({
                    fileName,
                    date
                });
                return;
            }
            */
            /*
             // User message: File xfer event fired EXEC:<file path>
             if (message.startsWith("File xfer event fired EXEC:<")) {
                 const matchedStrings = message.match(/File xfer event fired EXEC:<(.+)>/);
                 const fileName = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                 if (!eventData[UTS_AGENT.EXEC_XFER_EVENT]) eventData[UTS_AGENT.EXEC_XFER_EVENT] = [];
                 eventData[UTS_AGENT.EXEC_XFER_EVENT].push({
                     fileName,
                     date
                 });
                 return;
             }
             */
            //User message: File size is XXXX bytes
            //add file location this value can come from two. ?
            /*
            if (message.startsWith("File size is ")) {
                const matchedStrings = message.match(/File size is (.+) bytes/);
                const fileSizeInBytes = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.FILE_SIZE]) eventData[UTS_AGENT.FILE_SIZE] = [];
                eventData[UTS_AGENT.FILE_SIZE].push({
                    fileSizeInBytes,
                    date
                });
                return;
            }
            */
            // User message: File compression (put) level was <percentage>, transfer speed was XXXbytes/sec.
            /*
            if (message.startsWith("File compression (put) level was <")) {
                const matchedStrings = message.match(/File compression (put) level was <(.+)>, transfer speed was (.+)bytes\/sec/);
                const level = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                const speed = matchedStrings && matchedStrings[2] ? matchedStrings[2] : undefined;
                if (!eventData[UTS_AGENT.PUT_COMPRESSION]) eventData[UTS_AGENT.PUT_COMPRESSION] = [];
                eventData[UTS_AGENT.PUT_COMPRESSION].push({
                    level,
                    speed,
                    date
                });
                return;
            }

            */
            // File compression (get) level was <percentage>, transfer speed was XXXbytes/sec.
            /*
            if (message.startsWith("File compression (get) level was <")) {
                const matchedStrings = message.match(/File compression (get) level was <(.+)>, transfer speed was (.+)bytes\/sec/);
                const level = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                const speed = matchedStrings && matchedStrings[2] ? matchedStrings[2] : undefined;
                if (!eventData[UTS_AGENT.GET_COMPRESSION]) eventData[UTS_AGENT.GET_COMPRESSION] = [];
                eventData[UTS_AGENT.GET_COMPRESSION].push({
                    level,
                    speed,
                    date
                });
                return;
            }
            */
            /*
             // User message: Cleanup shell process created id = XXX(hex)XXXX(dec). 
             if (message.startsWith("Cleanup shell process created id = ")) {
                 const matchedStrings = message.match(/Cleanup shell process created id = (.+)\(hex\)(.+)\(dec\)/);
                 const idHex = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                 const idDec = matchedStrings && matchedStrings[2] ? matchedStrings[2] : undefined;
                 if (!eventData[UTS_AGENT.CLEAN_SHELL_PROCESS]) eventData[UTS_AGENT.CLEAN_SHELL_PROCESS] = [];
                 if (idHex && idDec) eventData[UTS_AGENT.CLEAN_SHELL_PROCESS].push({
                     idHex,
                     idDec,
                     date
                 });
                 return;
             }
             */

            // User message: User name starts with ffffffd2
            /*
            if (message.startsWith("User name starts with ")) {
                const matchedStrings = message.match(/User name starts with (.+)/);
                const userNameStartWith = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.USERNAME_START_WITH]) eventData[UTS_AGENT.USERNAME_START_WITH] = [];
                if (userNameStartWith) eventData[UTS_AGENT.USERNAME_START_WITH].push({
                    userNameStartWith,
                    date
                });
                return;
            }
            */

            // User message: Client string <Long Currently Unknown String> received 
            /*
            if (message.startsWith("Client string <")) {
                const matchedStrings = message.match(/Client string <(.+)> received/);
                const string = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.CLIENT_STRING_RECEIVED]) eventData[UTS_AGENT.CLIENT_STRING_RECEIVED] = [];
                if (string) eventData[UTS_AGENT.CLIENT_STRING_RECEIVED].push({
                    string,
                    date
                });
                return;
            }
            */
            /*
             // User message: Options: <Long Currently Unknown String> 
             if (message.startsWith("Options: <")) {
                 const matchedStrings = message.match(/Options: <(.+)>/);
                 const options = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                 if (!eventData[UTS_AGENT.OPTIONS]) eventData[UTS_AGENT.OPTIONS] = [];
                 if (options) eventData[UTS_AGENT.OPTIONS].push({
                     options,
                     date
                 });
                 return;
             }
             */
            // User message: Failed to logon <Username> 
            /*
            if (message.startsWith("Failed to logon <")) {
                const matchedStrings = message.match(/Failed to logon <(.+)>/);
                const username = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.USER_FAILED_TO_LOGON]) eventData[UTS_AGENT.USER_FAILED_TO_LOGON] = [];
                if (username) eventData[UTS_AGENT.USER_FAILED_TO_LOGON].push({
                    username,
                    date
                });
                return;
            }
            */
            // User message: IP <XXX.XXX.XXX.XXX> read from security subsystem 
            /*
            if (message.startsWith("IP <") && message.endsWith("> read from security subsystem")) {
                const matchedStrings = message.match(/IP <(.+)> read from security subsystem/);
                const ip = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.SECURITY_SUBSYSTEM]) eventData[UTS_AGENT.SECURITY_SUBSYSTEM] = [];
                if (IPV4_REGEX.test(ip)) eventData[UTS_AGENT.SECURITY_SUBSYSTEM].push({
                    ip,
                    date
                });
                return;
            }
            */
            //User message: About to resume <GS_Agnt.exe path>             
            /*
            if (message.startsWith("About to resume <")) {
                const matchedStrings = message.match(/About to resume <(.+)>/);
                const agentPath = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.RESUME]) eventData[UTS_AGENT.RESUME] = [];
                if (agentPath) eventData[UTS_AGENT.RESUME].push({
                    agentPath,
                    date
                });
                return;
            }
            */
            // User message: Could not open <file path> 
            /*
            if (message.startsWith("Could not open <")) {
                const matchedStrings = message.match(/Could not open <(.+)>/);
                const file = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.COULD_NOT_OPEN]) eventData[UTS_AGENT.COULD_NOT_OPEN] = [];
                if (file) eventData[UTS_AGENT.COULD_NOT_OPEN].push({
                    file,
                    date
                });
                return;
            }
            */
            // User message: Match Found at <file path> 
            /*
            if (message.startsWith("Match Found at <")) {
                const matchedStrings = message.match(/Match Found at <(.+)>/);
                const file = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.FILE_PATH_MATCH]) eventData[UTS_AGENT.FILE_PATH_MATCH] = [];
                if (file) eventData[UTS_AGENT.FILE_PATH_MATCH].push({
                    file,
                    date
                });
                return;
            }
            */
            //User message: Error 2 opening Registry path: SOFTWARE-Microsot-Windows NT-CurrentVersion-ProfileList-(UNIQUE VALUE)
            /*
            if (message.startsWith("Error 2 opening Registry path: SOFTWARE-Microsot-Windows NT-CurrentVersion-ProfileList-")) {
                const matchedStrings = message.match(/Error 2 opening Registry path: SOFTWARE-Microsot-Windows NT-CurrentVersion-ProfileList-\((.+)\)/);
                const path = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.STRANGE_ERROR2]) eventData[UTS_AGENT.STRANGE_ERROR2] = [];
                if (path) eventData[UTS_AGENT.STRANGE_ERROR2].push({
                    path,
                    date
                });
                return;
            }
            */
            // User message: Printing support initialized, spool path is <path>
            /*
            if (message.startsWith("Printing support initialized, spool path is <")) {
                const matchedStrings = message.match(/Printing support initialized, spool path is <(.+)>/);
                const path = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.PRINT_INIT]) eventData[UTS_AGENT.PRINT_INIT] = [];
                if (path) eventData[UTS_AGENT.PRINT_INIT].push({
                    path,
                    date
                });
                return;
            }
            */
            /*

            // User message: About to execute
            if (message.startsWith("About to execute")) {
                if (!eventData[UTS_AGENT.PRINT_ABOUT_TO_EXCUTE]) eventData[UTS_AGENT.PRINT_ABOUT_TO_EXCUTE] = [];
                eventData[UTS_AGENT.PRINT_ABOUT_TO_EXCUTE].push({
                    params: message.replace("About to execute ", ""),
                    date
                });
                return;
            }
            */

            // does not start with user message ?
            // File xfer event fired GET: <file path> 
            // Unable to open source file <file path> No such file or directory. 



            // Socket 0x0000000000000004 passed for reconnect to process 5424
            if (message.startsWith("Socket") && message.includes(" passed for reconnect to process ")) {
                const matchedStrings = message.match(/Socket (.+) passed for reconnect to process (\d+)/);
                const socket = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                const pid = matchedStrings && !isNaN(parseInt(matchedStrings[2])) ? parseInt(matchedStrings[2]) : undefined;
                if (!eventData[UTS_AGENT.RECONNECT_TO_PROCESS]) eventData[UTS_AGENT.RECONNECT_TO_PROCESS] = []
                eventData[UTS_AGENT.RECONNECT_TO_PROCESS].push({
                    date,
                    socket,
                    pid
                });
                return;
            }


            if (message.startsWith("User count") && message.includes("of")) {
                const matchedStrings = message.match(/User count (\d+) of (\d+)/);
                const sessions = matchedStrings && !isNaN(parseInt(matchedStrings[1])) ? parseInt(matchedStrings[1]) : undefined;
                const total = matchedStrings && !isNaN(parseInt(matchedStrings[2])) ? parseInt(matchedStrings[2]) : undefined;
                logData[UTS_AGENT.HIGHEST_USER_COUNT] = Math.max(sessions || 0, logData[UTS_AGENT.HIGHEST_USER_COUNT] || 0);
                logData[UTS_AGENT.TOTAL_SESSIONS] = total;
                eventData[UTS_AGENT.TOTAL_SESSIONS] = total;
                eventData[UTS_AGENT.NUMBER_OF_SESSIONS] = sessions;
                return;
            }


            /***
             * 
             *  Other data
             * 
             * 
             */

            /*
            //User message: Environment variable <LOCALAPPDATA> replaced
            if (message.startsWith("Environment variable ") && message.includes("> replaced")) {
                const matchedStrings = message.match(/Environment variable <(.+)> replaced/);
                const varName = matchedStrings && matchedStrings[1] ? matchedStrings[1] : undefined;
                if (!eventData[UTS_AGENT.ENV_VAR_REPLACED]) eventData[UTS_AGENT.ENV_VAR_REPLACED] = [];
                if (varName) eventData[UTS_AGENT.ENV_VAR_REPLACED].push({ varName })
                return;
            }
            */
            // [ Disconnections and errors ]
            // must be saved in global log under DISCONNECT_OR_ERROR
            //User message: tcpbWriteBuf() - reinit (Trying to resize Console Screen Buffer)
            if (message.startsWith("tcpbWriteBuf() - reinit")) {
                if (!eventData[UTS_AGENT.DISCONNECT_OR_ERROR]) eventData[UTS_AGENT.DISCONNECT_OR_ERROR] = [];
                eventData[UTS_AGENT.DISCONNECT_OR_ERROR].push({
                    date,
                    error: message,
                })
                return;
            }
            /*
            //User message: Console Screen Buffer 9001x120 detected. ( Reduce default number of rows and columns )
            if (message.startsWith("Console Screen Buffer ")) {
                if (!eventData[UTS_AGENT.DISCONNECT_OR_ERROR]) eventData[UTS_AGENT.DISCONNECT_OR_ERROR] = [];
                eventData[UTS_AGENT.DISCONNECT_OR_ERROR].push({
                    date,
                    error: message,
                })
                return;
            }
            */
            //User message: Zero bytes transferred. (Could not communicate with the client.)
            if (message.startsWith("Zero bytes transferred.")) {
                if (!eventData[UTS_AGENT.DISCONNECT_OR_ERROR]) eventData[UTS_AGENT.DISCONNECT_OR_ERROR] = []
                eventData[UTS_AGENT.ZERO_BYTES] = true;
                eventData[UTS_AGENT.DISCONNECT_OR_ERROR].push({
                    error: message,
                    date,
                });
                return;
            }
            //User message: Failed to logon the user <> from 120.202.177.108, error 1326. (Invalid username or password.)
            if (message.startsWith("Failed to logon the user")) {
                //eventData[UTS_AGENT.INVALID_CREDENTIALS] = true;
                if (!eventData[UTS_AGENT.DISCONNECT_OR_ERROR]) eventData[UTS_AGENT.DISCONNECT_OR_ERROR] = []
                eventData[UTS_AGENT.DISCONNECT_OR_ERROR].push({
                    error: message,
                    date,
                });
                return;
            }
            // User message: Print job found for (1)[16]:<C:\Windows\system32\spool\PRINTERS\00002.spl> (status is 0)
            // Print job found for <Username> (old tool)
            if (message.startsWith("Print job found for ")) {
                if (!eventData[UTS_AGENT.DISCONNECT_OR_ERROR]) eventData[UTS_AGENT.DISCONNECT_OR_ERROR] = []
                eventData[UTS_AGENT.DISCONNECT_OR_ERROR].push({
                    error: message,
                    date
                });
                return;
            }


            //User message: ReadFile() failed, retry scheduled.
            if (message.startsWith("ReadFile() failed")) {
                if (!eventData[UTS_AGENT.DISCONNECT_OR_ERROR]) eventData[UTS_AGENT.DISCONNECT_OR_ERROR] = []
                eventData[UTS_AGENT.DISCONNECT_OR_ERROR].push({
                    error: message,
                    date,
                });
                return;
            }
            if (message.startsWith("GetAsyncResult : GetOverlappedResult failed, error =  995")) {
                eventData[UTS_AGENT.WINSOCK_995] = true;
                if (!eventData[UTS_AGENT.DISCONNECT_OR_ERROR]) eventData[UTS_AGENT.DISCONNECT_OR_ERROR] = []
                eventData[UTS_AGENT.DISCONNECT_OR_ERROR].push({
                    error: message,
                    date,
                });
                return;
            }

            //User message: GetAsyncResult : GetOverlappedResult failed, error = 10060(dec). (Unable to detect client on the network)
            if (message.startsWith("GetAsyncResult : GetOverlappedResult failed, error =")) {
                eventData[UTS_AGENT.WINSOCK] = true;
                if (!eventData[UTS_AGENT.DISCONNECT_OR_ERROR]) eventData[UTS_AGENT.DISCONNECT_OR_ERROR] = []
                eventData[UTS_AGENT.DISCONNECT_OR_ERROR].push({
                    error: message,
                    date
                });
                return;
            }

            //User message: Session suspended
            if (message.startsWith("Session suspended")) {
                eventData[UTS_AGENT.SUSPEND_SESSION_COUNT] = eventData[UTS_AGENT.SUSPEND_SESSION_COUNT] ? eventData[UTS_AGENT.SUSPEND_SESSION_COUNT] + 1 : 1;
                if (!eventData[UTS_AGENT.DISCONNECT_OR_ERROR]) eventData[UTS_AGENT.DISCONNECT_OR_ERROR] = []
                eventData[UTS_AGENT.DISCONNECT_OR_ERROR].push({
                    error: message,
                    date,
                });
                return;
            }
            //User message: Async read from client returned error, completion code = 10054(dec).
            if (message.startsWith("Async read from client returned error, completion code =")) {
                eventData[UTS_AGENT.WINSOCK] = true;
                if (!eventData[UTS_AGENT.DISCONNECT_OR_ERROR]) eventData[UTS_AGENT.DISCONNECT_OR_ERROR] = []
                eventData[UTS_AGENT.DISCONNECT_OR_ERROR].push({
                    error: message,
                    date,
                });
                return;
            }

            //checking event data and saving what can be saved
        })
        return eventData;
    }
    catch (error) {
        console.log("error parsing event", error)
    }
    return {};

}

const updateState = (pidState) => {
    pidState[UTS_AGENT.CONNECTION_TYPE] = UTS_AGENT.CONNECTION_TYPE_UNKNOWN;
    pidState[UTS_AGENT.STATE_ERRORS] = [];
    const ip = [...(pidState[UTS_AGENT.IP] || [])].pop()?.ip;
    if (pidState[UTS_AGENT.IP]?.length > 1) {
        pidState[UTS_AGENT.STATE_ERRORS].push(`multiple ip detected: ${pidState[UTS_AGENT.IP].map(el => el.ip).join(" ")}`)
    }
    const begin = pidState[UTS_AGENT.BEGIN_IND_COUNT] > 0
    const end = pidState[UTS_AGENT.END_IND_COUNT] > 0
    const reconTo = !!((pidState[UTS_AGENT.RECONNECT_SOCKET])?.length > 0);
    const suspended = pidState[UTS_AGENT.SUSPEND_SESSION_COUNT] > 0
    const readChar = !!pidState[UTS_AGENT.READ_CHAR];
    const reconnection = !!((pidState[UTS_AGENT.RECONNECT_TO_PROCESS])?.length > 0);
    const appAnalyse = false; // value never updated in php code
    const normalExit = !!pidState[UTS_AGENT.NORMAL_EXIT];
    const zeroBytes = !!pidState[UTS_AGENT.ZERO_BYTES];
    const inactivityTimeout = !!pidState[UTS_AGENT.INACTIVITY_TIMEOUT];
    const winsock = !!pidState[UTS_AGENT.WINSOCK];
    const winsock995 = !!pidState[UTS_AGENT.WINSOCK_995];
    const initialized = false; // always false in php code
    const controlEvent = !!pidState[UTS_AGENT.CONTROL_EVENT];
    const invalidCredentials = !!pidState[UTS_AGENT.INVALID_REGISTRATION];
    const exitCode200 = !!pidState[UTS_AGENT.EXIT_CODE_200];
    const exitCode100 = !!pidState[UTS_AGENT.EXIT_CODE_100];
    const exitCode255 = !!pidState[UTS_AGENT.EXIT_CODE_255];
    const gswClientTimeout = !!pidState[UTS_AGENT.GSW_CLIENT_TIMEOUT];
    pidState[UTS_AGENT.STATE] = "Unknown (didn't match any case)";
    // 
    if ((suspended || reconTo) && end) pidState[UTS_AGENT.STATE] = "Finished Master Session";
    if ((suspended || reconTo) && !end) pidState[UTS_AGENT.STATE] = "Active Master Session";
    if (!reconTo && !suspended && end && gswClientTimeout) pidState[UTS_AGENT.STATE] = "GSW Client Heartbeat Timeout";
    if (!reconTo && !suspended && end && reconnection) pidState[UTS_AGENT.STATE] = "Reconnection to a Suspended Session";
    if (!reconTo && !suspended && end && exitCode255) pidState[UTS_AGENT.STATE] = "Exit Code 255 Huh....";
    if (!reconTo && !suspended && end && exitCode100) pidState[UTS_AGENT.STATE] = "Exit Code 100 (Doug, what is this?)";
    if (!reconTo && !suspended && end && exitCode200) pidState[UTS_AGENT.STATE] = "Exit Code 200 (Doug, what is this?)";
    if (!reconTo && !suspended && end && invalidCredentials) pidState[UTS_AGENT.STATE] = "InvalidCredentials";
    if (!reconTo && !suspended && end && winsock) pidState[UTS_AGENT.STATE] = "Winsock";
    if (!reconTo && !suspended && end && zeroBytes) pidState[UTS_AGENT.STATE] = "Zero Bytes Transferred";
    if (!reconTo && !suspended && end && normalExit) pidState[UTS_AGENT.STATE] = "Normal Exit";
    if (!reconTo && !suspended && end && inactivityTimeout) pidState[UTS_AGENT.STATE] = "Inactivity Timeout";
    if (!reconTo && !suspended && end && appAnalyse) pidState[UTS_AGENT.STATE] = "Registration Invalid";
    if (!reconTo && !suspended && end && readChar) pidState[UTS_AGENT.STATE] = "Credentials Not Received";
    if (
        !reconTo && !suspended && end &&
        !gswClientTimeout &&
        !reconnection &&
        !exitCode255 &&
        !exitCode200 &&
        !invalidCredentials &&
        !winsock &&
        !zeroBytes &&
        !normalExit &&
        !appAnalyse &&
        !readChar) {
        pidState[UTS_AGENT.STATE_ERRORS].push("Session ended for unknown reason!");
        pidState[UTS_AGENT.STATE] = "Unknown"
    }
    if (!reconTo && !end && !suspended && begin && ip && !reconnection && winsock995) pidState[UTS_AGENT.STATE] = "Ongoing Winsock 995";
    if (!reconTo && !end && !suspended && begin && ip && !reconnection && !winsock995) pidState[UTS_AGENT.STATE] = "Ongoing"; //
    if (!reconTo && !end && !suspended && begin && !ip && !reconnection) pidState[UTS_AGENT.STATE] = "Fragment";
    if (!reconTo && !end && !suspended && !begin && ip && !reconnection && winsock995) pidState[UTS_AGENT.STATE] = "Winsock995";
    if (!reconTo && !end && !suspended && !begin && ip && !reconnection && !winsock995 && initialized) pidState[UTS_AGENT.STATE] = "Fragment";
    if (!reconTo && !end && !suspended && !begin && ip && !reconnection && !winsock995 && !initialized) {
        pidState[UTS_AGENT.STATE] = UTS_AGENT.CONNECTION_TYPE_UNKNOWN;
        pidState[UTS_AGENT.STATE_ERRORS].push("No Beginning IP exists, not fully initialized, no end Why didn't this get picked up somewhere?");
    }
    if (!reconTo && !end && !suspended && !begin && !ip && !reconnection && controlEvent) pidState[UTS_AGENT.STATE] = "Control Event";
    if (!reconTo && !end && !suspended && !begin && !ip && !reconnection && !controlEvent && winsock995) pidState[UTS_AGENT.STATE] = "Winsock 995";
    if (!reconTo && !end && !suspended && !begin && !ip && !reconnection && !controlEvent && !winsock995) pidState[UTS_AGENT.STATE] = "Unknown"
    if (!reconTo && !end && !suspended && reconnection) pidState[UTS_AGENT.STATE] = "Reconnection To Suspended Session";

    // depend on old results
    if (!ip) {
        pidState[UTS_AGENT.STATE] = "No IP Address -- " + pidState[UTS_AGENT.STATE];
    }
    if (pidState[UTS_AGENT.STATE] === "No IP Address -- Control Event") pidState[UTS_AGENT.CONNECTION_TYPE] = UTS_AGENT.CONNECTION_TYPE_CONTROL;
    if (!pidState[UTS_AGENT.STATE].startsWith("No IP Address")) pidState[UTS_AGENT.CONNECTION_TYPE] = UTS_AGENT.CONNECTION_TYPE_DEVICE;
}


const utsAgent = {
    updateState,
    parseUtsEvent,
    parseUtsEvents
}

export default utsAgent;