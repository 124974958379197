import { useState, useMemo } from "react"
import { toast } from "react-toastify"
import {
    Card,
    CardContent,
    Grid2,
    Typography,
    IconButton,
} from "@mui/material";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useSelector, useDispatch } from 'react-redux';
import { updateRttAPsAction } from 'redux/rtt/rtt.actions';
import { http } from "utils/http";
import { updateSettingAction } from 'redux/settings/settings.actions';
import PrintButton from 'components/PrintButton';
import AccessPointConfig from "./AccessPointConfig";

const AccessPointsConfig = ({ rttSettings, readOnly }) => {
    const dispatch = useDispatch();
    const [accessPointCreating, setAccessPointCreating] = useState(false);
    const accessPoints = useSelector((state) => state.rtt.accessPoints || []);
    const [ref, setRef] = useState(null);
    const handleUpdateAccessPointsConfig = async (accessPointConfig) => {
        try {
            const _accessPoints = accessPoints.filter(el => el.id !== accessPointConfig.id);
            // type: RTT only for migration
            const updates = { rttAccessPoints: [..._accessPoints, accessPointConfig].map(el => ({ type: 'RTT', ...el })) };
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/setting/setting`,
                {
                    settingID: rttSettings._id,
                    ...updates
                }
            );
            dispatch(updateSettingAction(resp.data));
            if (
                resp.data.rttAccessPoints &&
                Array.isArray(resp.data.rttAccessPoints)
            ) {
                dispatch(updateRttAPsAction(resp.data.rttAccessPoints));
            }

            if (accessPointConfig.id === null) {
                setAccessPointCreating(false);
            }
            toast.success("rtt settings updated");
        } catch (error) {
            console.log("error", error);
            if (error &&
                error.response &&
                error.response.data &&
                error.response.data.message) {
                toast.error(error.response.data.message)
            }
            else toast.error("Could not update accessPoint settings");
        }
    }
    const handleDeleteAccessPoint = async ({ id }) => {
        try {
            const _accessPoints = accessPoints.filter(el => el.id !== id);
            const updates = { rttAccessPoints: [..._accessPoints] };
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/setting/setting`,
                {
                    settingID: rttSettings._id,
                    ...updates
                }
            );
            dispatch(updateSettingAction(resp.data));
            if (
                resp.data.rttAccessPoints &&
                Array.isArray(resp.data.rttAccessPoints)
            ) {
                dispatch(updateRttAPsAction(resp.data.rttAccessPoints));
            }
            toast.success("rtt settings updated");
        } catch (error) {
            console.log("error", error);
            if (error &&
                error.response &&
                error.response.data &&
                error.response.data.message) {
                toast.error(error.response.data.message)
            }
            else toast.error("Could not update accessPoint settings");
        }
    }
    const sortedAccessPoints =  useMemo(() => [...accessPoints].sort((a, b) => `${a.id}`.localeCompare(`${b.id}`)), [accessPoints]);
    return <Card sx={{ mb: 6 }}>
        <CardContent ref={ref => setRef(ref)}>
            <Grid2 container alignItems="center" justifyContent="space-between">
                <Grid2>
                    <Typography variant="h6" gutterBottom display="inline">
                        AccessPoint Config
                    </Typography>
                    <IconButton aria-label="add" color="primary" disabled={readOnly} onClick={() => setAccessPointCreating(true)}>
                        <ControlPointIcon fontSize="large" />
                    </IconButton>
                </Grid2>
                <Grid2>
                    <PrintButton
                        refPrint={ref}
                        onAfterPrint={null}
                        onBeforeGetContent={null}
                    />
                </Grid2>
            </Grid2>
            <Grid2 container spacing={3} sx={{ mt: 2 }}>
                {accessPointCreating && <Grid2 size={12}>
                    <Grid2 container spacing={6}>
                        <Grid2 size={12}>
                            <AccessPointConfig
                                accessPointMac={'00:00:00:00:00:00'}
                                accessPointX={0}
                                accessPointY={0}
                                accessPointZ={0}
                                accessPointGain={1}
                                updateAccessPointConfig={handleUpdateAccessPointsConfig}
                                deleteAccessPoint={handleDeleteAccessPoint}
                                accessPointID={null}
                                cancelCreating={() => setAccessPointCreating(false)}
                                accessPointDisabled={false}
                                accessPointOffset={0}
                                accessPointType={'RTT'}
                            />
                        </Grid2>
                    </Grid2>
                </Grid2>
                }
                {sortedAccessPoints.map(el => (<Grid2 key={el.id} size={12}>
                    <Grid2 container spacing={6}>
                        <Grid2 size={12}>
                            <AccessPointConfig
                                accessPointMac={el.mac}
                                accessPointGain={el.gain}
                                accessPointX={el.x}
                                accessPointY={el.y}
                                accessPointZ={el.z}
                                updateAccessPointConfig={handleUpdateAccessPointsConfig}
                                deleteAccessPoint={handleDeleteAccessPoint}
                                accessPointID={el.id}
                                cancelCreating={null}
                                accessPointDisabled={el.disabled}
                                accessPointOffset={el.offset}
                                accessPointType={el.type}
                                readOnly={readOnly}
                            />
                        </Grid2>
                    </Grid2>
                </Grid2>))}
            </Grid2>
        </CardContent>
    </Card>
}
export default AccessPointsConfig;