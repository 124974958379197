import { useState, useEffect, useCallback } from 'react';
import {
    Grid2
} from '@mui/material';
import BadgeRequirements from './BadgeRequirements';
import TestCode from './TestCode';
import TestLogoutSequence from './TestLogoutSequence';
import EventsMatchedCheck from './EventsMatchedCheck';
import LogoutSequence from './LogoutSequence';

const Badge = ({ properties, setProperties, editing, setIsValid, accordionExpanded }) => {
    const updateProperties = useCallback((props) => {
        setProperties({ ...properties, ...(props || {}) });
    }, [properties, setProperties]);
    const [isValidBadge, setIsValidBadge] = useState(false);
    const [isValidLogoutSequence, setIsValidLogoutSequence] = useState(false);

    useEffect(() => {
        if (isValidBadge && isValidLogoutSequence) setIsValid(true);
        else setIsValid(false);
    }, [isValidBadge, isValidLogoutSequence, setIsValid])

    return <Grid2 container spacing={6}>
        <Grid2 size={12}>
            <BadgeRequirements
                badgeLength={properties.badgeLength}
                badgeContainsLowerCase={properties.badgeContainsLowerCase}
                badgeLowercaseCount={properties.badgeLowercaseCount}
                badgeContainsUpperCase={properties.badgeContainsUpperCase}
                badgeUppercaseCount={properties.badgeUppercaseCount}
                badgeContainsNumber={properties.badgeContainsNumber}
                badgeNumberCount={properties.badgeNumberCount}
                badgeContainsSpecialChar={properties.badgeContainsSpecialChar}
                badgeSpecialCharCount={properties.badgeSpecialCharCount}
                badgePasswordLength={properties.badgePasswordLength}
                badgeAssignFromKeyboardEventsEnabled={properties.badgeAssignFromKeyboardEventsEnabled}
                updateProperties={updateProperties}
                editing={editing}
                setIsValidBadge={setIsValidBadge}

            />
        </Grid2>
        <Grid2 size={12}>
            <EventsMatchedCheck
                badgeLength={properties.badgeLength}
                badgeContainsLowerCase={properties.badgeContainsLowerCase}
                badgeLowercaseCount={properties.badgeLowercaseCount}
                badgeContainsUpperCase={properties.badgeContainsUpperCase}
                badgeUppercaseCount={properties.badgeUppercaseCount}
                badgeContainsNumber={properties.badgeContainsNumber}
                badgeNumberCount={properties.badgeNumberCount}
                badgeContainsSpecialChar={properties.badgeContainsSpecialChar}
                badgeSpecialCharCount={properties.badgeSpecialCharCount}
                accordionExpanded={accordionExpanded}
            />
        </Grid2>
        <Grid2 size={12}>
            <TestCode
                badgeLength={properties.badgeLength}
                badgeContainsLowerCase={properties.badgeContainsLowerCase}
                badgeLowercaseCount={properties.badgeLowercaseCount}
                badgeContainsUpperCase={properties.badgeContainsUpperCase}
                badgeUppercaseCount={properties.badgeUppercaseCount}
                badgeContainsNumber={properties.badgeContainsNumber}
                badgeNumberCount={properties.badgeNumberCount}
                badgeContainsSpecialChar={properties.badgeContainsSpecialChar}
                badgeSpecialCharCount={properties.badgeSpecialCharCount}
            />
        </Grid2>
        <Grid2 size={12}>
            <LogoutSequence
                badgeLogoutSequence={properties.badgeLogoutSequence}
                editing={editing}
                setIsValidLogoutSequence={setIsValidLogoutSequence}
                updateProperties={updateProperties}
            />
        </Grid2>
        {properties.badgeAssignFromKeyboardEventsEnabled && <Grid2 size={12}>
            <TestLogoutSequence
                badgeLength={properties.badgeLength}
                badgeContainsLowerCase={properties.badgeContainsLowerCase}
                badgeLowercaseCount={properties.badgeLowercaseCount}
                badgeContainsUpperCase={properties.badgeContainsUpperCase}
                badgeUppercaseCount={properties.badgeUppercaseCount}
                badgeContainsNumber={properties.badgeContainsNumber}
                badgeNumberCount={properties.badgeNumberCount}
                badgeContainsSpecialChar={properties.badgeContainsSpecialChar}
                badgeSpecialCharCount={properties.badgeSpecialCharCount}
                badgeLogoutSequence={properties.badgeLogoutSequence}
            />
        </Grid2>}
    </Grid2>
}

export default Badge;
