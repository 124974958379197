import ArticlesActionTypes from "./articles.types";

const initialState = {
    articles: []
}
const articlesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ArticlesActionTypes.UPDATE_ALL_ARTICLES:
            return { ...state, articles: action.payload }
        case ArticlesActionTypes.UPDATE_ARTICLE:
            return { ...state, articles: [action.payload, ...state.articles.filter(el => el._id !== action.payload._id)] }
        default:
            return state;
    }
}

export default articlesReducer;
