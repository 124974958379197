import DevicesActionTypes from "./devices.types"

const updateDevicesAction = (devices) => {
    return { type: DevicesActionTypes.UPDATE_DEVICES, payload: devices }
}
const deleteDeviceEventAction = (androidID) => {
    return { type: DevicesActionTypes.DELETE_DEVICE_EVENT, payload: androidID }
}
export {
    updateDevicesAction,
    deleteDeviceEventAction
}
