import React, { useCallback } from "react";
import keyboardType from "constants/keyboardType";

const initializeState = () => {
  return {
    keyboardType: window.keyboardManager ? keyboardType.GSW : keyboardType.OS,
    isKeyboardOpen: false,
    updateKeyboardType() { },
    updateKeyboardIsOpen() { },
  }
};
const ClientChatContext = React.createContext(initializeState);


function ClientChatProvider({ children }) {
  const [keyboardType, setKeyboardType] = React.useState(initializeState().keyboardType);
  const [isKeyboardOpen, setIsKeyboardOpen] = React.useState(initializeState().isKeyboardOpen);
  const updateKeyboardType = (type) => {
    setKeyboardType(type)
  }
  const updateKeyboardIsOpen = useCallback((isOpen) => {
    setIsKeyboardOpen(isOpen);
  },[])
  return (
    <ClientChatContext.Provider value={{
      keyboardType,
      isKeyboardOpen,
      updateKeyboardType,
      updateKeyboardIsOpen
    }}>
      {children}
    </ClientChatContext.Provider>
  );
}

export { ClientChatProvider, ClientChatContext };

