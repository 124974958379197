import KeyboardsActionTypes from "./keyboards.types";
import keyboardSamples from 'constants/keyboard/keyboardSamples';

const initialState = {
    keyboards: keyboardSamples,
    ladsFiles: {}
}

const keyboardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case KeyboardsActionTypes.UPDATE_ALL_KEYBOARDS:
            return { ...state, keyboards: [...keyboardSamples, ...action.payload] }
        case KeyboardsActionTypes.UPDATE_KEYBOARD:
            return { ...state, keyboards: [action.payload, ...state.keyboards.filter(el => el._id !== action.payload._id)] }
        case KeyboardsActionTypes.DELETE_KEYBOARD:
            return { ...state, keyboards: state.keyboards.filter(el => el._id !== action.payload) }
        case KeyboardsActionTypes.UPDATE_LADS_FILES_PATH:
            const folders = action.payload.path.split("/");
            if (folders[0] !== "") folders.unshift("");
            const _ladsFiles = { ...state.ladsFiles };
            let subDir = _ladsFiles;
            folders.forEach(el => {
                if (!subDir[el]) subDir[el] = {
                    type: 'dir',
                }
                subDir = subDir[el];
            });
            action.payload.dirs.forEach(dir => {
                if (!subDir[dir]) subDir[dir] = {
                    type: 'dir'
                };
            })
            action.payload.files.forEach(file => {
                subDir[file.file_name] = {
                    type: 'file',
                    lastModified: new Date(1000 * parseInt(file.last_write_time))
                }
            })

            return { ...state, ladsFiles: _ladsFiles }
        default:
            return state;
    }
}

export default keyboardsReducer;
