import React, { useEffect, useMemo } from 'react';
import { DataGridPro, useGridApiRef, GridToolbar } from '@mui/x-data-grid-pro';
import {
    Button,
    ClickAwayListener,
    Card,
    CardHeader,
    CardContent
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { updateBadgesAction } from 'redux/badges/badges.actions';
import { getBadges } from 'utils/api';
import BadgeValid from './BadgeValid';
import useAuthorization from 'hooks/useAuthorization';
import settingType from 'constants/settingType';

function BadgesList({ handleUpdateSelectBadge }) {
    const { isAuthorized } = useAuthorization();
    const canUpdateBadge = isAuthorized("badge.update");
    const canDeleteBadge = isAuthorized("badge.delete");
    const badges = useSelector(state => state.badges.badges || []);
    const settings = useSelector(state => state.settings);
    const badgesSettings = useMemo(() => settings.filter(setting => setting.type === settingType.BADGE), [settings]);
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchCall = getBadges().then(badges => dispatch(updateBadgesAction(badges)))
        return () => {
            fetchCall.cancel();
        };
    }, [dispatch]);
    const apiRef = useGridApiRef();
    const handleClickAway = () => {
        if (apiRef.current && apiRef.current.setRowSelectionModel) {
            apiRef.current.setRowSelectionModel([])
        }
    }
    const columns = useMemo(() => {
        const _columns = [
            {
                field: "details",
                headerName: "",
                renderCell: (params) => {
                    return <Button
                        variant="contained"
                        size="small" color="primary"
                        onClick={() => handleUpdateSelectBadge(params.row)}>
                        Rename|Delete
                    </Button>
                },
                width: 120,
                sortable: false,
            },
            {
                field: "badgeID",
                headerName: "ID",
                width: 200,
                editable: false,
            },
            {
                field: "userName",
                headerName: "User Name",
                width: 200,
                editable: false
            },
            {
                field: "isValid",
                headerName: "Valid",
                width: 80,
                editable: false,
                align: "center",
                renderCell: (params) => <BadgeValid badgeID={params.row.badgeID} badgesSettings={badgesSettings} />
            },
        ];
        if (canUpdateBadge || canDeleteBadge) return _columns;
        return _columns.filter(el => el.field !== "Update");
    }, [badgesSettings, handleUpdateSelectBadge, canUpdateBadge, canDeleteBadge])

    return (
        <Card>
            <CardHeader title="Badges" />
            <CardContent>
                <div style={{ height: 500, width: "100%" }}>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <DataGridPro
                            apiRef={apiRef}
                            rows={badges}
                            getRowId={(row) => row._id}
                            columns={columns}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            initialState={{
                                density: "compact"
                            }}
                        />
                    </ClickAwayListener>
                </div>
            </CardContent>
        </Card>
    )
};

export default BadgesList;
