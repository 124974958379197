import {
    Badge,
    Avatar,
    Typography,
    Grid2,
    Button
} from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';


const OnlineBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) => props.color === 'primary' ?
        props.theme.sidebar.footer.online.background :
        props.theme.sidebar.footer.offline.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width:  12px;
    border-radius: 50%;
  }
`;



const Participant = ({ _id, displayName, isOnline, profilePicture, showBadge = true, showDeviceProfileLink = false }) => {
    return <Grid2 container alignItems="center" spacing={{ sm: 0, md: 2 }} wrap="nowrap">
            {showBadge && <Grid2>
                <OnlineBadge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    variant="dot"
                    color={isOnline ? 'primary' : 'secondary'}
                >
                    <Avatar
                        alt={displayName}
                        src={profilePicture}
                        sx={{ width: 24, height: 24 }}
                    />
                </OnlineBadge>
            </Grid2>}
            <Grid2 flexGrow={1} sx={{ minWidth: 0 }}>
                <Typography variant="body2" noWrap>{displayName || _id}</Typography>
            </Grid2>
            {showDeviceProfileLink && /^[0-9a-f]{16}$/i.test(_id) && <Grid2>
                <Link
                    to={`/devices/${_id}`}
                    style={{ textDecoration: "none" }}
                >
                    <Button
                        variant="contained"
                        size="small"
                    >
                        Profile
                    </Button>
                </Link>
            </Grid2>}
        </Grid2>
}


export default Participant;