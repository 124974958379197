import React, { useState } from 'react';
import { styled } from '@mui/system';
import LoadingButton from '@mui/lab/LoadingButton';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
    Card as MuiCard,
    CardContent,
    Grid2,
    TextField as MuiTextField,
    Typography,
    CardHeader,
    FormControlLabel,
} from "@mui/material";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { spacing } from "@mui/system";
import useEncryption from 'hooks/useEncryption';
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);

const EncryptionInline = () => {
    const { encryptInline, decryptInline } = useEncryption();
    const [password, setPassword] = useState("d5f535bdaf752411534fc2598093240a");
    const [processing, setProcessing] = useState(false);
    const [input, setInput] = useState("");
    const [output, setOutput] = useState("");
    const [operationEncrypt, setOperationEncrypt] = useState(true);
    const handleEncryptMessage = async () => {
        setOutput("");
        setProcessing(true);
        try {
            const result = await encryptInline({ input, password });
            setOutput(result);
        }
        catch (error) {
            console.log("error", error);
            if (error.message) toast.error(error.message);
            else toast.error("Unable to encrypt, Please double check your inputs");
            setProcessing(false);
        }
        setProcessing(false);
    }
    const handleDecryptMessage = async () => {
        setOutput("");
        setProcessing(true);
        try {
            const result = await decryptInline({ input, password });
            setOutput(result);
        }
        catch (error) {
            console.log("error", error);
            if (error.message) toast.error(error.message);
            else toast.error("Unable to decrypt, Please double check your inputs");
            setProcessing(false);
        }
        setProcessing(false);
    }
    return <Card sx={{ my: 2 }}>
        <CardHeader title="Decrypt/Encrypt one message" />
        <CardContent>
            <Grid2 container sx={{ mb: 1 }}>
                <Grid2>
                    <TextField
                        label="Type your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        variant="outlined"
                        type="password"
                    />
                </Grid2>
                <Grid2>
                    <FormControl sx={{ mx: 2 }}>
                        <RadioGroup
                            aria-labelledby="radio-buttons-group-encryption"
                            name="radio-buttons-group"
                            value={operationEncrypt}
                            onChange={(e) => setOperationEncrypt("true" === e.target.value)}
                        >
                            <FormControlLabel value={false} control={<Radio />} label="Decrypt" />
                            <FormControlLabel value={true} control={<Radio />} label="Encrypt" />
                        </RadioGroup>
                    </FormControl>
                </Grid2>
                <Grid2>
                    <LoadingButton
                        onClick={operationEncrypt ? handleEncryptMessage : handleDecryptMessage}
                        variant="contained"
                        color="primary"
                        loading={processing}
                        loadingPosition="start"
                        startIcon={operationEncrypt ? <LockIcon /> : <LockOpenIcon />}
                        disabled={!password.length || !input.length}
                    >
                        {operationEncrypt ? "Encrypt" : "Decrypt"}
                    </LoadingButton>
                </Grid2>
            </Grid2>
            <Grid2 container sx={{ mb: 2 }}>
                <Grid2 size={12}>
                    <TextField
                        label={operationEncrypt ? "Message to Encrypt" : "Message to Decyrpt"}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        variant="outlined"
                        type="text"
                        fullWidth
                    />
                </Grid2>
            </Grid2>
            <Grid2 container sx={{ mb: 2 }}>
                <Grid2 size={output ? 11 : 12}>
                    <TextField
                        label={operationEncrypt ? "Encrypted Message" : "Decrypted Message"}
                        value={output}
                        variant="outlined"
                        type="text"
                        fullWidth
                        disabled
                        multiline
                    />
                </Grid2>
                {output && <Grid2 size={1}>
                    <Grid2 container alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
                        <CopyToClipboard text={output}
                            onCopy={() => toast.success("Copied to clipboard")}>
                            <ContentCopyIcon role="button" color="primary" />
                        </CopyToClipboard>
                    </Grid2>
                </Grid2>}
            </Grid2>
            <Typography variant="body2" gutterBottom>
                Use this tool to encrypt/decyrpt messages, if you need to decrypt all message use the global Encryption Config Tool
            </Typography>
            <Typography variant="body2" gutterBottom>
                * Your password, input and ouput aren't saved or sent anywhere.
            </Typography>
        </CardContent>
    </Card>
}
export default EncryptionInline;