import React from 'react';
import { styled } from '@mui/system';

import GenerateNewUUID from './GenerateNewUUID';
import { NavLink } from "react-router-dom";
import {
    Link,
    Grid2,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);
const UUID = () => {
    return (
        <React.Fragment>
            <Typography variant="h3" gutterBottom display="inline">
                Generate New UUID
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" sx={{ mt: 2 }}>
                <Link component={NavLink} to="/">
                    Settings
                </Link>
                <Typography>Generate New UUID</Typography>
            </Breadcrumbs>
            <Divider sx={{ my: 6 }} />
            <Grid2 container spacing={6}>
                <Grid2 size={12}>
                    <GenerateNewUUID />
                </Grid2>
            </Grid2>
        </React.Fragment>
    );
}

export default UUID;

