const SupportActionTypes = {
    SUPPORT_UPDATE_ALL_COMPANIES: "SUPPORT_UPDATE_ALL_COMPANIES",
    SUPPORT_UPDATE_ALL_ARTICLES: "SUPPORT_UPDATE_ALL_ARTICLES",
    SUPPORT_UPDATE_ARTICLE: "SUPPORT_UPDATE_ARTICLE",
    SUPPORT_DELETE_ARTICLE: "SUPPORT_DELETE_ARTICLE",
    SUPPORT_UPDATE_ALL_SAMPLE_KEYBOARDS: "SUPPORT_UPDATE_ALL_SAMPLE_KEYBOARDS",
    SUPPORT_UPDATE_SAMPLE_KEYBOARD: "SUPPORT_UPDATE_SAMPLE_KEYBOARD",
    SUPPORT_DELETE_SAMPLE_KEYBOARD: "SUPPORT_DELETE_SAMPLE_KEYBOARD",
    SUPPORT_UPDATE_ALL_NOTIFICATIONS: "SUPPORT_UPDATE_ALL_NOTIFICATIONS",
    SUPPORT_UPDATE_NOTIFICATION: "SUPPORT_UPDATE_NOTIFICATION",
    SUPPORT_DELETE_NOTIFICATION: "SUPPORT_DELETE_NOTIFICATION",
    SUPPORT_UPDATE_ALL_EMAILS: "SUPPORT_UPDATE_ALL_EMAILS",
    SUPPORT_UPDATE_DUPLICATED_DEVICES: "SUPPORT_UPDATE_DUPLICATED_DEVICES"
}

export default SupportActionTypes
