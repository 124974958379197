import GroupsActionTypes from "./groups.types";
const initialState = {
    groups: [],
    selectedGroup: null,
    groupDevicesMap: {}
}

const groupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GroupsActionTypes.UPDATE_GROUPS:
            return {
                ...state,
                groups: action.payload,
                groupDevicesMap: action.payload.reduce((a, c) => ({ ...a, ...c.devices.reduce((a1, c1) => ({ ...a1, [c1]: c._id }), {}) }), {})
            };
        case GroupsActionTypes.NEW_GROUP:
            return {
                ...state, groups:
                    [action.payload, ...state.groups],
                groupDevicesMap: [action.payload, ...state.groups].reduce((a, c) => ({ ...a, ...c.devices.reduce((a1, c1) => ({ ...a1, [c1]: c._id }), {}) }), {})
            };
        case GroupsActionTypes.REMOVE_GROUP:
            return {
                ...state,
                groups: state.groups.filter(group => group._id !== action.payload),
                groupDevicesMap: state.groups.filter(group => group._id !== action.payload).reduce((a, c) => ({ ...a, ...c.devices.reduce((a1, c1) => ({ ...a1, [c1]: c._id }), {}) }), {})
            };
        case GroupsActionTypes.UPDATE_GROUP:
            const _groups = state.groups.filter(group => group._id !== action.payload._id);
            return {
                ...state,
                groups: [..._groups, action.payload],
                groupDevicesMap: [..._groups, action.payload].reduce((a, c) => ({ ...a, ...c.devices.reduce((a1, c1) => ({ ...a1, [c1]: c._id }), {}) }), {})

            };
        case GroupsActionTypes.UPDATE_SELECTED_GROUP:
            return {
                ...state,
                selectedGroup: action.payload
            }
        default:
            return state;
    }
}

export default groupsReducer;
