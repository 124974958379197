import React, {
  useEffect,
  useMemo,
  useState
} from "react";
import {
  CardContent,
  Grid2,
  Checkbox,
  Typography,
  Divider
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { getDevices } from 'utils/api';
import Devices from "./Devices";
import { updateDevicesAction } from "redux/devices/devices.actions";

const DevicesMapSettings = ({ mapDevices, updateMapDevices }) => {
  const [allShowPointer, setAllShowPointer] = useState(true);
  const [allShowName, setAllShowName] = useState(true);
  const [allShowAPSignals, setAllShowAPSignals] = useState(false);
  const handleAllShowPointer = (e) => {
    const _mapDevices = structuredClone(mapDevices);
    Object.values(_mapDevices).forEach(el => el.showPointer = e.target.checked);
    updateMapDevices(_mapDevices)
    setAllShowPointer(e.target.checked);
  }
  const handleAllShowAPSignals = (e) => {
    const _mapDevices = structuredClone(mapDevices);
    Object.values(_mapDevices).forEach(el => el.showAPSignals = e.target.checked);
    updateMapDevices(_mapDevices)
    setAllShowAPSignals(e.target.checked);
  }
  const handleAllShowName = (e) => {
    const _mapDevices = structuredClone(mapDevices);
    Object.values(_mapDevices).forEach(el => el.showName = e.target.checked);
    updateMapDevices(_mapDevices)
    setAllShowName(e.target.checked);
  }
  return <Grid2 container>
    <Grid2 size={12}>
      <Typography variant="h6">
        All Devices Settings
      </Typography>
    </Grid2>
    <Grid2 size={4}>
      <Checkbox
        onChange={handleAllShowPointer}
        checked={allShowPointer}
      />
      <Typography variant="body2" display="inline">
        Pointer
      </Typography>
    </Grid2>
    <Grid2 size={4}>
      <Checkbox
        onChange={handleAllShowName}
        checked={allShowName}
      />
      <Typography variant="body2" display="inline">
        Name
      </Typography>
    </Grid2>
    <Grid2 size={4}>
      <Checkbox
        onChange={handleAllShowAPSignals}
        checked={allShowAPSignals}
      />
      <Typography variant="body2" display="inline">
        AP signal
      </Typography>
    </Grid2>
  </Grid2>
}


function DeviceList({ rttSettings, mapDevices, updateMapDevices, groupDevices }) {
  const devicesRTT = useSelector((state) => state.rtt.devices || {});
  const devices = useSelector((state) => state.devices);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchCall = getDevices({
      androidID: true,
      name: true,
      date: true,
      ipaddr: true,
      battery_level: true,
      wifi_level: true,
      gswcb_version: true,
      gswcb_config_name: true,
      gswcb_config_download_date: true,
      license_type: true,
      license_process: true,
      license_expiration_date: true,
      license_subscription_until: true,
      network_mac: true,
      display_dimensions: true,
      display_density: true,
      version_security_patch: true,
      version_sdk: true,
      version_release: true,
      build_model: true,
      build_brand: true,
      battery_health: true,
      battery_technology: true,
      battery_plugged: true,
    }).then(devices => dispatch(updateDevicesAction(devices || [])))
    return () => {
      fetchCall.cancel();
    };
  }, [dispatch]);
  const deviceInfoMap = useMemo(() => {
    return devices.reduce((p, c) => ({ ...p, [c.androidID]: c }), {});
  }, [devices]);
  const groupDevicesMap = useMemo(() => {
    return groupDevices.reduce((p, c) => ({ ...p, [c]: c }), {});
  }, [groupDevices]);
  const deviceList = useMemo(() => {
    return Object.values(devicesRTT)
      .map(devieRTT => {
        const _device = deviceInfoMap[devieRTT.androidID] || {};
        return { ...rttSettings, ..._device, ...devieRTT, name: _device.name };
      })
      .filter(el => el && groupDevicesMap[el.androidID]);
  }, [deviceInfoMap, devicesRTT, rttSettings, groupDevicesMap])
  return <React.Fragment>
    {deviceList.length ? <CardContent>
      <DevicesMapSettings
        mapDevices={mapDevices}
        updateMapDevices={updateMapDevices}
      />
      <Divider />
      <Devices
        deviceList={deviceList}
        mapDevices={mapDevices}
        updateMapDevices={updateMapDevices}
      />
    </CardContent> : null}
  </React.Fragment>
}

export default DeviceList;
