import { useState, useMemo, useRef } from 'react';
import {
    Box,
    Input,
    IconButton as MuiIconButton,
    LinearProgress,
    Typography,
    Tooltip
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import chatMessageType from 'constants/chatMessageType';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import BoltIcon from '@mui/icons-material/Bolt';
import { toast } from 'react-toastify';
import { rgba } from 'polished';
import { http } from 'utils/http';
import { styled } from '@mui/system';
import useAuth from 'hooks/useAuth';
import useClientChat from 'hooks/useClientChat';
import USER_TYPE from 'constants/userType';
import keyboardType from 'constants/keyboardType';
import { socketEmitters } from 'utils/socket';
import InputForCustomKeyboard from 'components/InputForCustomKeyboard';


const Wrapper = styled(Box)`
    position: relative;
    background: ${(props) => props.theme.palette.background.paper};
    border: 1px solid ${(props) => rgba(props.theme.palette.text.secondary, 0.2)};
    height: 50px;  
    width: 100%; 
`;

const ChatInputsWrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 50px;
`;



const TextInput = styled(Input)`
    color: ${(props) => props.$highPriority ? props.theme.palette.error.main : 'default'};
    flex-grow: 1;
    height: 100%;
    margin:  0 4px;
`;
const TextInputForCustomKeyboard = styled(InputForCustomKeyboard)`
    color: ${(props) => props.$highPriority ? props.theme.palette.error.main : 'default'};
    flex-grow: 1;
    height: 100%;
    margin:  0 4px;
`


const IconButton = styled(MuiIconButton)`
    margin:  0 2px;
`;

const FileUploadPreviewContainer = styled(Box)`
    position: absolute;
    top: -115px;
    width: 100%;
    height: 110px;
    overflow: hidden;
`

const FileInfosWrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${(props) => props.theme.palette.background.paper};
    border: 1px solid ${(props) => rgba(props.theme.palette.text.secondary, 0.2)};
    height: calc(100% - 4px);
`
const ImagePreview = styled(Box)`
    background-image: url(${props => props.$src});
    width: 80px;
    height: 80px;
    background-size: 100px auto;
    background-repeat: no-repeat;
    background-position: center;
`

const MAX_HIGH_PRIORITY_CHAT_MESSAGE_LENGTH = 300;
const initialFileToUploadState = null;
const FileUploadPreview = ({ processingForm, fileType, name, imageSrc, cancel }) => {
    return <FileUploadPreviewContainer>
        <FileInfosWrapper>
            {fileType === chatMessageType.IMAGE ? (
                <Box sx={{ p: 1 }}>
                    <ImagePreview $src={imageSrc} />
                    <Typography variant="body2">{name || "File"}</Typography>
                </Box>
            )
                :
                (
                    <Box sx={{ p: 1 }}>
                        <AttachFileIcon fontSize="large" />
                        <Typography>{name || "File"}</Typography>
                    </Box>
                )}
            <IconButton color="primary" onClick={cancel}>
                <CancelIcon />
            </IconButton>
        </FileInfosWrapper>
        {processingForm && <LinearProgress color="secondary" />}
    </FileUploadPreviewContainer>

}

const ChatInput = ({ roomID, nDvicesSupportHighPriority }) => {
    const { type } = useAuth();
    const { keyboardType: currentKeyboardType } = useClientChat();
    const customKeyboardEnabled = useMemo(() => type === USER_TYPE.DEVICE && currentKeyboardType === keyboardType.GSW, [type, currentKeyboardType]);
    const isDevice = useMemo(() => {
        return USER_TYPE.DEVICE === type;
    }, [type]);
    const initialFileTypeState = chatMessageType.TEXT;
    const initialImageSrcState = "";
    const [chatMessage, setChatMessage] = useState("");
    const [fileToUpload, setFileToUpload] = useState(initialFileToUploadState);
    const [fileType, setFileType] = useState(initialFileTypeState);
    const [imageSrc, setImageSrc] = useState(initialImageSrcState);
    const [processingForm, setProcessingForm] = useState(false);
    const [highPriority, setHighPriority] = useState(false);
    const fileRef = useRef();
    const handleSendMessage = async (e) => {
        e.preventDefault()
        if (!chatMessage && !fileToUpload) {
            removeFile();
            return;
        }
        setProcessingForm(true)
        let error = false;
        let fileUploadData = {}
        if (fileToUpload) {
            try {
                const res = await uploadFile(fileToUpload);
                if (!res || !res.data || !res.data.result) {
                    return
                }
                fileUploadData = res.data.result;
            } catch (err) {
                error = true;
                toast.error("Could not upload file!")
                console.log("file upload some error occured", err)
            }
        } else {
            removeFile()
        }
        setProcessingForm(false);
        if (error) return;
        socketEmitters.emitMessage({
            chatMessage,
            fileType: Object.keys(fileUploadData).length ? fileType : chatMessageType.TEXT,
            ...fileUploadData,
            roomID,
            highPriority: highPriority && !!nDvicesSupportHighPriority,
        })
        if (highPriority) setHighPriority(false);
        setChatMessage("")
        removeFile()
    }

    function takeCameraPicture() {
        const onSuccess = (imageData) => {
            setFileType(chatMessageType.IMAGE);
            fetch(`data:image/jpeg;base64,${imageData}`)
                .then(res => res.blob())
                .then(file => {
                    file.name = `Camera-${Date.now()}.jpg`;
                    const event = {
                        target: {
                            files: [file]
                        }
                    };
                    previewFile(event);
                })
                .catch(error => {
                    toast.error("error catch");
                    console.log("error catch", error);
                })
        }
        const onFail = (error) => {
            if (error === 20) {
                toast.error("Camera permissions are not enabled. Please enable camera permissions for GSW ConnectBot");
            }
            else toast.error(`Operation was not completed due to: ${error}`);
        }
        /*global Camera*/
        if ((typeof Camera === 'undefined') || !navigator || (navigator && !navigator.camera) || (navigator && navigator.camera && !navigator.camera.getPicture)) {
            toast.error("Please enable cordova")
            return;
        }
        navigator.camera.getPicture(onSuccess, onFail, {
            quality: 50,
            correctOrientation: true,
            destinationType: Camera.DestinationType.DATA_URL
        });
    }
    function openFilePicker(type) {
        if (type === chatMessageType.IMAGE) {
            setFileType(chatMessageType.IMAGE)
            fileRef.current.accept = "image/*"
        } else {
            setFileType(chatMessageType.FILE)
            fileRef.current.accept = "*"
        }
        fileRef.current.value = ""
        fileRef.current.click()
    }
    function previewFile(e) {
        const file = e.target.files[0]
        const mb = 5000000
        const maxSizeInMb = 5
        const maxSize = maxSizeInMb * mb
        // console.log("preview file running", e.target.files[0])
        // [start] file validations
        if (!file) {
            removeFile()
            return
        }
        if (file.size > maxSize) {
            return toast.error(`The uploaded file is over the 5mb limit`)
        }
        console.log("filename", file.name);
        // [end] file validations

        setFileToUpload(file)
        if (file.type && typeof file.type === 'string' && file.type.startsWith("image")) {
            const reader = new FileReader()
            reader.onload = function () {
                setImageSrc(reader.result)
            }
            reader.onloadend = function (e) {
                //console.log("reder end event", e);
                //console.log("reader end", reader);
            }
            reader.readAsDataURL(file)
        } else {
            // some other document file...
        }
    }

    function removeFile() {
        setFileToUpload(initialFileToUploadState);
        setFileType(initialFileTypeState);
        setImageSrc(initialImageSrcState);
        fileRef.current.value = ""
    }

    async function uploadFile(file) {
        const formData = new FormData()
        formData.append("file", file, file.name);
        try {
            const results = await http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/file/upload-file`,
                formData
            )
            return results;
        } catch (error) {
            error?.response?.data?.message ? toast.error(error.response.data.message) : toast.error("Error: can't upload the file");
        }
    }
    const handleTogleHighPriority = () => {
        if (!highPriority && chatMessage.length > MAX_HIGH_PRIORITY_CHAT_MESSAGE_LENGTH) setChatMessage(chatMessage.substring(0, MAX_HIGH_PRIORITY_CHAT_MESSAGE_LENGTH))
        setHighPriority(!highPriority);
    }
    const handleInputKeyDown = (e) => {
        if (e.code === 'Enter' && chatMessage) handleSendMessage(e);
    }
    return (
        <Wrapper>
            {fileToUpload && <FileUploadPreview
                processingForm={processingForm}
                fileType={fileType}
                name={fileToUpload.name}
                imageSrc={imageSrc}
                cancel={removeFile}
            />}
            <ChatInputsWrapper>
                {customKeyboardEnabled ?
                    (<TextInputForCustomKeyboard
                        disableUnderline
                        fullWidth
                        placeholder='Type a message...'
                        value={chatMessage}
                        onChange={(e) => setChatMessage(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                        $highPriority={highPriority}
                    />
                    )
                    :
                    (<TextInput
                        disableUnderline
                        fullWidth
                        placeholder='Type a message...'
                        value={chatMessage}
                        onChange={(e) => setChatMessage(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                        $highPriority={highPriority}
                    />
                    )}
                {!!nDvicesSupportHighPriority &&
                    <Tooltip title={`${nDvicesSupportHighPriority} device${nDvicesSupportHighPriority > 1 ? "s" : ""} support High Priority Messages`}><IconButton
                        onClick={handleTogleHighPriority}
                        color={highPriority ? "error" : "default"}
                    >
                        <BoltIcon />
                    </IconButton>
                    </Tooltip>}
                {isDevice && <IconButton onClick={() => {
                    try {
                        takeCameraPicture(chatMessageType.IMAGE)
                    } catch (error) {
                        console.log("something went wrong");
                    }
                }}>
                    <CameraAltIcon />
                </IconButton>}
                <IconButton onClick={() => openFilePicker(chatMessageType.IMAGE)}>
                    <InsertPhotoIcon />
                </IconButton>
                <IconButton onClick={() => openFilePicker(chatMessageType.FILE)}>
                    <AttachFileIcon />
                </IconButton>
                <IconButton onClick={handleSendMessage} disabled={!chatMessage && !fileToUpload}>
                    <SendIcon />
                </IconButton>
            </ChatInputsWrapper>
            <input onChange={previewFile} ref={fileRef} type="file" style={{ display: "none" }} />
        </Wrapper>
    )
}

export default ChatInput;