const ChatActionTypes = {
    SET_MY_ID: "SET_MY_ID",
    SET_CHAT_USERS: "SET_CHAT_USERS",
    SET_CHAT_DEVICES: "SET_CHAT_DEVICES",
    SET_CHAT_CONVS: "SET_CHAT_CONVS",
    SET_CHAT_CONV_MESSAGES: "SET_CHAT_CONV_MESSAGES",
    NEW_CHAT_CONV_MESSAGE: "NEW_CHAT_CONV_MESSAGE",
    UPDATE_CHATROOM_STATUS: "UPDATE_CHATROOM_STATUS",
    CHAT_UPDATE_ONLINE_USERS: "CHAT_UPDATE_ONLINE_USERS",
    CHAT_SET_IS_CONNECTED: "CHAT_SET_IS_CONNECTED",
    DELETE_CHAT_USER: "DELETE_CHAT_USER",
    DELETE_CHAT_DEVICE: "DELETE_CHAT_DEVICE",
    UPDATE_CHAT_DEVICE_NAME: "UPDATE_CHAT_DEVICE_NAME",
    APPEND_CHAT_CONV_MESSAGES: "APPEND_CHAT_CONV_MESSAGES",
    UPDATE_CHAT_ROOMS: "UPDATE_CHAT_ROOMS",
    UPDATE_CHAT_ROOM: "UPDATE_CHAT_ROOM",
    DELETE_CHAT_ROOM: "DELETE_CHAT_ROOM",
}

export default ChatActionTypes