import EventsActionTypes from "./events.types"
import getStoreSelections from "utils/getStoreSelections";

const updateLast24hEventsAction = (events) => {
    return { type: EventsActionTypes.UPDATE_LAST_24H_EVENTS, payload: events }
}

const updateLast24hDevicesScanCountAction = ({ androidID, count }) => {
    return { type: EventsActionTypes.UPDATE_LAST_24H_DEVICE_SCAN_COUNT, payload: { androidID, count } }
}

const updateLogEventAction = (event) => {
    return { type: EventsActionTypes.UPDATE_LOG_EVENT, payload: event }
}

const updateDeviceAllEventsAction = ({ androidID, events }) => {
    return { type: EventsActionTypes.UPDATE_DEVICE_ALL_EVENTS, payload: { androidID, events } }
}

const updateAllDevicesAllEventsAction = (events) => {
    return { type: EventsActionTypes.UPDATE_ALL_DEVICES_ALL_EVENTS, payload: events }
}

const updateScanEventsAction = (events) => {
    return { type: EventsActionTypes.UPDATE_SCAN_EVENTS, payload: events }
}

const updateUsersBadgesEventsAction = (events) => {
    return { type: EventsActionTypes.UPDATE_USERS_BADGES_EVENTS, payload: events }
}

const updateScanPerBadgeEventsAction = ({ periode, scanCountPerBadge }) => {
    return async (dispatch, getState) => {
        const {
            badgesIDMap
        } = getStoreSelections(getState());
        dispatch({
            type: EventsActionTypes.UPDATE_SCAN_PER_BDAGE_EVENTS_PERIODE,
            payload: {
                scanCountPerBadge: scanCountPerBadge.map(el => ({ ...el, badge_id: badgesIDMap[el.badgeID] })),
                periode
            }
        })
    }
}

const updateScanEventsPeriodCountAction = ({ nEvents, periode, group, badge }) => {
    return {
        type: EventsActionTypes.UPDATE_SCAN_PERIOD_COUNT_EVENTS, payload: {
            nEvents,
            periode,
            group,
            badge
        }
    }
}

const updateScanDeviceEventsPeriodCountAction = ({ events, periode }) => {
    // reduce here and create devices map (+ to the device)
    // ex: devicea +5, deviceb +2
    const devicesScanCount = {};
    events.forEach(event => {
        if (!devicesScanCount[event.androidID]) devicesScanCount[event.androidID] = 0;
        devicesScanCount[event.androidID]++;
    });
    return {
        type: EventsActionTypes.UPDATE_SCAN_PERIOD_COUNT_EVENTS_DEVICE, payload: {
            devicesScanCount,
            periode
        }
    }
}

const newScansEventAction = (events) => {
    //console.log("newScansEvents", events[0]);
    return async (dispatch, getState) => {
        // create badgeID <-> badge._id map
        // selector in UI use _ID, reducer is using badgeID
        const {
            groupDevicesMap,
            badgesIDMap
        } = getStoreSelections(getState());
        dispatch({ type: EventsActionTypes.NEW_SCANS_EVENT, payload: events.map(el => ({ ...el, groupID: groupDevicesMap[el.androidID], badge_id: badgesIDMap[el.badgeID] })) })
    }
}

const newUserBadgeEventAction = (event) => {
    return { type: EventsActionTypes.NEW_USER_BADGE_EVENT, payload: event }
}

const updateKeyboardEventsAction = (events) => {
    return { type: EventsActionTypes.UPDATE_KEYBOARD_EVENTS, payload: events }
}

const newKeyboardEventAction = (event) => {
    return { type: EventsActionTypes.NEW_KEYBOARD_EVENT, payload: event }
}

const updateGPSHistoryEvents = (events) => {
    return { type: EventsActionTypes.UPDATE_GPS_HISTORY_EVENTS, payload: events }
}

export {
    updateLast24hEventsAction,
    updateLogEventAction,
    updateDeviceAllEventsAction,
    updateAllDevicesAllEventsAction,
    updateScanEventsAction,
    updateUsersBadgesEventsAction,
    newScansEventAction,
    newUserBadgeEventAction,
    updateScanEventsPeriodCountAction,
    updateScanDeviceEventsPeriodCountAction,
    updateLast24hDevicesScanCountAction,
    updateKeyboardEventsAction,
    newKeyboardEventAction,
    updateGPSHistoryEvents,
    updateScanPerBadgeEventsAction
}
