import React, { useState, useEffect, useMemo } from "react";
import { styled } from '@mui/system';
import { NavLink } from "react-router-dom";
import {
  Grid2,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Box,
  CircularProgress,
  ClickAwayListener
} from "@mui/material";
import { spacing } from "@mui/system";
import { DataGridPro, useGridApiRef, GridToolbar } from "@mui/x-data-grid-pro";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getEvents, getCompany } from 'utils/api';
import { formatTimeDate } from 'utils/timeFormat';
import LockIcon from '@mui/icons-material/Lock';
import useEncryption from 'hooks/useEncryption';
import EncryptionPasswordModal from 'components/EncryptionPasswordModal';
import usePreferences from "hooks/usePreferences";
import codes from "constants/codes";
import Authorized from "components/Authorized";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const EncryptedMessage = ({ input, decrypt, setIsModalOpen }) => {
  const [decrypted, setDecrypted] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [output, setOutput] = useState("");
  const handleDecryptMessage = async () => {
    setOutput("");
    setProcessing(true);
    try {
      const result = await decrypt({ input });
      setOutput(result);
      setDecrypted(true);
    }
    catch (error) {
      setProcessing(false);
      console.log("error", error.message);
      setIsModalOpen(true);
      if (error.message) toast.error(error.message);
      else toast.error("Unable to decrypt, Please double check your password");
    }
    setProcessing(false);
  }
  return <Box
    sx={{
      position: 'relative',
      width: '100%',
      height: '100%'
    }}>
    <Box sx={{
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      filter: decrypted ? 'none' : 'blur(2px)'
    }}
    >
      <Typography>
        {decrypted ? output : input}
      </Typography>
    </Box>
    {!processing && !decrypted && <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    }}>
      <LockIcon color="primary" role="button" onClick={handleDecryptMessage} />
    </Box>}
    {processing && <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    }}>
      <CircularProgress color="secondary" size={18} />
    </Box>}
  </Box>
}

const preferencesID = "pages-logs-Scanlog";

export default function ScanLog() {
  const { preferences, updatePreferences } = usePreferences();
  const initialState = useMemo(() => preferences[preferencesID] || {}, [preferences]);
  const [rows, setRows] = useState([]);
  const [scanEventsIgnored, setScanEventsIgnored] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { decrypt } = useEncryption();

  useEffect(() => {
    const fetchCalls = [
      getEvents({ messageID: codes.GSW_SCANNED }).then((resp) => setRows(resp || [])),
      getCompany().then((company) => setScanEventsIgnored(!!company.ignoreScanHeaders)),
    ];
    return () => {
      fetchCalls.forEach(fechtCall => fechtCall.cancel());
    }
  }, []);
  const apiRef = useGridApiRef();
  const groups = useSelector((state) => state.groups.groups);
  const groupMap = useMemo(() => {
    const _groupMap = {};
    groups.forEach(group => {
      group.devices.forEach(el => _groupMap[el] = group.name);
    })
    return _groupMap;
  }, [groups])

  const _columns = useMemo(() => {
    return [
      {
        field: "date",
        headerName: "Date",
        width: 175,
        valueFormatter: (value) => formatTimeDate(value),
        editable: false,
      },
      {
        field: "ipaddr",
        headerName: "IP Address",
        width: 200,
        editable: false,
      },
      {
        field: "word",
        renderCell: (params) => params.row?.properties?.enc ? <EncryptedMessage decrypt={decrypt} setIsModalOpen={setIsModalOpen} input={params.row.word} /> : params.row.word,
        width: 200,
        editable: false,
      },
      {
        field: 'group',
        headerName: "Group",
        width: 130,
        valueGetter: (value, row) => groupMap[row.androidID] || '',
        editable: false,
      },
      {
        field: "messageID",
        headerName: "Message ID",
        width: 200,
        editable: false,
      },
      {
        field: "androidID",
        headerName: "AndroidID",
        width: 200,
        editable: false,
      },
      {
        field: "id",
        headerName: "ID",
        width: 250,
        editable: false,
      },
    ];
  }, [groupMap, decrypt, setIsModalOpen])



  const handleClickAway = () => {
    if (apiRef.current && apiRef.current.setRowSelectionModel) {
      apiRef.current.setRowSelectionModel([])
    }
  }
  const columns = React.useMemo(() => {
    let error = false;
    if (initialState &&
      initialState.columns &&
      initialState.columns.cols) {
      const cols = new Array(_columns.length);
      _columns.forEach((el) => {
        if (!initialState.columns.cols[el.field]) {
          error = true;
          return;
        }
        cols[initialState.columns.cols[el.field].order] = { ...el, width: initialState.columns.cols[el.field].width }
      })
      if (!error) return cols;
    }
    return _columns;
  }, [initialState, _columns]);
  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        Scan Log
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" sx={{ mt: 2 }}>
        <Link component={NavLink} to="/">
          Logs
        </Link>
        <Typography>Scan Log</Typography>
      </Breadcrumbs>
      <Divider sx={{ my: 6 }} />
      {scanEventsIgnored && <Typography variant="body2" color="error" sx={{ mb: 3 }}>
        * New incoming events are not logged, check your settings from more info
      </Typography>}
      <Grid2 container justifyContent="center">
        <Grid2 size={12}>
          <Authorized permission="event.read">
            <div style={{ height: 1200, width: "100%" }}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <DataGridPro
                  onStateChange={(state) => {
                    updatePreferences(preferencesID, {
                      density: state.density,
                      sorting: {
                        sortModel: state.sorting.sortModel,
                      },
                      filter: {
                        filterModel: state.filter.filterModel
                      },
                      columns: {
                        columnVisibilityModel: state.columns.columnVisibilityModel,
                        cols: apiRef.current.getAllColumns().map(({ field, width }, index) => ({ field, order: index, width })).reduce((p, c) => ({ ...p, [c.field]: c }), {}),
                      }
                    })
                  }}
                  initialState={initialState}
                  apiRef={apiRef}
                  rows={rows}
                  columns={columns}
                  getRowId={(row) => row._id}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                />
              </ClickAwayListener>
            </div>
          </Authorized>
        </Grid2>
      </Grid2>
      <EncryptionPasswordModal isOpen={isModalOpen} close={() => setIsModalOpen(false)} />
    </React.Fragment>
  );
}
