import {
  Typography,
  Grid2,
  Button,
  Divider as MuiDivider
} from "@mui/material";
import { styled } from '@mui/system';
import { spacing } from "@mui/system";
import { useNavigate } from "react-router-dom";
import BatteryLevel from "components/BatteryLevel";
import WifiLevel from "components/WifiLevel";
import { useMap } from 'react-leaflet'
import Authorized from "components/Authorized";

const Divider = styled(MuiDivider)(spacing);

const DeviceMarkerInfos = ({ androidID, name, connect_bot_v, ipaddr, battery_level, wifi_level }) => {
  const map = useMap();
  const navigate = useNavigate();
  const handleClick = () => {
    if (map?.remove) map.remove();
    if (map?.off) map.off();
    setTimeout(() => {
      navigate(`/devices/${androidID}`);
    }, 10)
  }

  return (
    <div>
      {name && <Typography style={{ margin: 0, padding: 0 }} variant="body2">Name: {name}</Typography>}
      {androidID && <Typography style={{ margin: 0, padding: 0 }} variant="body2">ID: {androidID}</Typography>}
      {ipaddr && <Typography style={{ margin: 0, padding: 0 }} variant="body2">IP: {ipaddr}</Typography>}
      {connect_bot_v && <Typography style={{ margin: 0, padding: 0 }} variant="body2">ConnectBot: {connect_bot_v}</Typography>}
      {(Number.isFinite(wifi_level) || Number.isFinite(battery_level)) && <Divider />}
      <Grid2 container>
        <Grid2 size={2} />
        <Grid2 size={2}>
          <BatteryLevel level={battery_level} />
          <Typography align="center" style={{ margin: 0, padding: 0 }} variant="body2">{Number.isFinite(battery_level) ? `${battery_level}%` : ''}</Typography>
        </Grid2>
        <Grid2 size={4} />
        <Grid2 size={2}>
          <WifiLevel level={wifi_level} />
          <Typography align="center" style={{ margin: 0, padding: 0 }} variant="body2">{Number.isFinite(wifi_level) ? `${wifi_level}` : ''}</Typography>
        </Grid2>
        <Grid2 size={2} />
      </Grid2>
      <div style={{ textAlign: 'center' }}>
        <Authorized permission="page.deviceDetails">
          <Button sx={{ mt: 2 }} variant="contained" size="small" color="primary" onClick={handleClick}>
            Device Profile
          </Button>
        </Authorized>
      </div>
    </div>
  )
}
export default DeviceMarkerInfos;