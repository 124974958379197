import React, { useMemo } from 'react';
import {
    Grid2,
    Typography,
    Card,
    CardHeader,
    CardContent,
    Tooltip
} from '@mui/material';
import { useSelector } from "react-redux"
import WarningIcon from '@mui/icons-material/Warning';
import alertEventsTypes from 'constants/alertEventsTypes';
import operations from 'constants/operations';
import BoltIcon from '@mui/icons-material/Bolt';

const AlertView = ({ disabled, eventID, property, severity, operation, value, name, description, dashboardEnabled, emailEnabled, chatEnabled, dashboardMessage, emailMessage, chatMessage, androidID, highPriority }) => {
    const selectedAlertEvent = useMemo(() => alertEventsTypes.find(el => el.id === eventID), [eventID]);
    const devices = useSelector((state) => state.devices || []);
    const deviceNamesMap = useMemo(() => devices.reduce((p, c) => ({ ...p, [c.androidID]: c.name }), {}), [devices]);
    const selectedProperty = useMemo(() => {
        if (!selectedAlertEvent) return null;
        if (!selectedAlertEvent.properties) return null;
        if (!selectedAlertEvent.properties.length) return null;
        return selectedAlertEvent.properties.find(el => el.name === property);
    }, [selectedAlertEvent, property]);
    const error = useMemo(() => {
        if (!eventID) return "You must select an Event";
        if (selectedAlertEvent && selectedAlertEvent.properties && selectedAlertEvent.properties.length && !property) return "You must select a Property";
        if (selectedProperty && selectedProperty.operations && selectedProperty.operations.length) {
            if (value === null || value === undefined) return "You must define a value";
            if (selectedProperty.type === 'text' && !value.length) return "You must define a value";
            if (selectedProperty.type === 'number' && Number.isNaN(parseFloat(value))) return "You must define a valid value";
        }
        if (dashboardEnabled && !dashboardMessage) return "You must Enter a message for Dashboard Messages";
        if (emailEnabled && !emailMessage) return "You must Enter a message for Email Messages";
        if (chatEnabled && !chatMessage) return "You must Enter a message for Chat Messages";
        if (disabled) return "Alerts are disabled";
        if (!disabled && !emailEnabled && !dashboardEnabled && !chatEnabled) return "All notifications for this event are disabled!";
        return ""
    }, [eventID, property, disabled, value, dashboardEnabled, emailEnabled, chatEnabled, dashboardMessage, emailMessage, chatMessage, selectedProperty, selectedAlertEvent])
    return (
        <Card variant="outlined">
            <CardHeader title={
                <Grid2 container>
                    <Grid2 size={1}>
                        {error && <Tooltip title={error}><WarningIcon color="warning" /></Tooltip>}
                    </Grid2>
                    <Grid2 size={11}>
                        <Typography variant="h6" gutterBottom>
                            {highPriority && <Tooltip title="High Priority"><BoltIcon color="error" /></Tooltip>} {name}
                        </Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        <Typography variant="body2" gutterBottom>
                            <strong>Description: </strong>{description}
                        </Typography>
                    </Grid2>
                </Grid2>
            }
            />
            <CardContent>
                <Grid2 container spacing={2} alignItems="center">
                    <Grid2 size={12}>
                        <Typography variant="body1">
                            Event: <strong>{selectedAlertEvent && selectedAlertEvent.name ? selectedAlertEvent.name : ''}</strong>
                        </Typography>
                    </Grid2>
                    {severity && <Grid2 size={12}>
                        <Typography variant="body1">
                            severity: <strong>{severity}</strong>
                        </Typography>
                    </Grid2>}
                    {selectedProperty && operation && <Grid2 size={12}>
                        <Typography variant="body1">
                            Event will occur when {selectedProperty.description} {selectedProperty.type !== 'boolean' ? (operations[operation] || '?') : 'is'} <strong>{selectedProperty.type === 'boolean' ? (!!value ? "true" : "false") : value}</strong>
                        </Typography>
                    </Grid2>}
                    {dashboardEnabled && <Grid2 size={12}>
                        <Typography variant="body1">
                            Dashboard Message: <strong>{dashboardMessage || ''}</strong>
                        </Typography>
                    </Grid2>}
                    {chatEnabled && <Grid2 size={12}>
                        <Typography variant="body1">
                            Device Message: <strong>{chatMessage || ''}</strong>
                        </Typography>
                    </Grid2>}
                    {emailEnabled && <Grid2 size={12}>
                        <Typography variant="body1">
                            Email Message: <strong>{emailMessage || ''}</strong>
                        </Typography>
                    </Grid2>}
                    {androidID && <Grid2 size={12}>
                        <Typography variant="body1" color="error">
                            * Alarms will trigger only for device: <strong>{deviceNamesMap[androidID] || androidID}</strong>
                        </Typography>
                    </Grid2>}
                </Grid2>
            </CardContent>
        </Card>
    )
}
export default AlertView;