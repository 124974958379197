import SystemActionTypes from "./system.types";

const updateAuthLogsAction = (events) => {
    return { type: SystemActionTypes.UPDATE_AUTHENTIFICATION_LOG, payload: events }
}
const closeNotificationAction = (id) => {
    return { type: SystemActionTypes.CLOSE_SYSTEM_NOTIFICATION, payload: id }
}
const updateSystemNotificationsAction = (notifications) => {
    return { type: SystemActionTypes.UPDATE_SYSTEM_NOTIFICATIONS, payload: notifications }
}
const updateGlobalDecryptionPasswordAction = ({ password, saveInLocalStorage }) => {
    return { type: SystemActionTypes.UPDATE_GLOBAL_DECRYPTION_PASSWORD, payload: { password, saveInLocalStorage } }
}
export {
    updateAuthLogsAction,
    closeNotificationAction,
    updateSystemNotificationsAction,
    updateGlobalDecryptionPasswordAction
}
