import { useState, useMemo } from 'react';
import {
    Grid2,
    Card,
    CardHeader,
    Typography,
    Grow,
    Tooltip,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LoadingButton from '@mui/lab/LoadingButton';
import SettingsIcon from '@mui/icons-material/Settings';
import CreateSetting from './CreateSetting';
import { useSelector } from 'react-redux';
import Setting from './Setting';
import settingsTypesInfos from './settingsTypesInfos';
import Authorized from 'components/Authorized';

const Settings = ({ selectedSetting, setSelectedSetting }) => {
    const [settingType, setSettingType] = useState('ALL');
    const settings = useSelector(state => state.settings || []);
    const groups = useSelector(state => state.groups.groups || []);
    const settingGroups = useMemo(() => {
        const _settingGroups = {};
        settings.forEach(setting => {
            if (!_settingGroups[setting._id]) _settingGroups[setting._id] = { ...setting, groups: [], nDevices: 0 };
        });
        groups.forEach(group => {
            if (_settingGroups[group.geofencingSetting]) {
                _settingGroups[group.geofencingSetting].groups.push(group);
                _settingGroups[group.geofencingSetting].nDevices += group.devices.length;
            }
            if (_settingGroups[group.keyboardSetting]) {
                _settingGroups[group.keyboardSetting].groups.push(group);
                _settingGroups[group.keyboardSetting].nDevices += group.devices.length;
            }
            if (_settingGroups[group.scanSetting]) {
                _settingGroups[group.scanSetting].groups.push(group);
                _settingGroups[group.scanSetting].nDevices += group.devices.length;
            }
            if (_settingGroups[group.rTTSetting]) {
                _settingGroups[group.rTTSetting].groups.push(group);
                _settingGroups[group.rTTSetting].nDevices += group.devices.length;
            }
            if (_settingGroups[group.alertsSetting]) {
                _settingGroups[group.alertsSetting].groups.push(group);
                _settingGroups[group.alertsSetting].nDevices += group.devices.length;
            }
            if (_settingGroups[group.badgeSetting]) {
                _settingGroups[group.badgeSetting].groups.push(group);
                _settingGroups[group.badgeSetting].nDevices += group.devices.length;
            }
        })
        return _settingGroups;
    }, [groups, settings]);
    const [creating, setCreating] = useState(false);
    const filtredSettings = useMemo(() => {
        if (settingType === 'ALL') return settings;
        return settings.filter(setting => setting.type === settingType);
    }, [settings, settingType])
    return <Grid2 container spacing={6}>
        <Grid2 size={12}>
            <Card>
                <CardHeader title={<Grid2 container justifyContent="space-between">
                    <Grid2>
                        <Typography component="h2" variant="h3" gutterBottom>
                            Settings
                        </Typography>
                    </Grid2>
                    <Grid2>
                        <Authorized permission="setting.create">
                            {creating ? <Tooltip title="Cancel" >
                                <HighlightOffIcon color="warning" sx={{ mx: 2 }} fontSize="large" role="button" onClick={() => setCreating(false)} />
                            </Tooltip> : <LoadingButton
                                size="small"
                                endIcon={<SettingsIcon />}
                                onClick={() => setCreating(true)}
                                loadingPosition="end"
                                variant="contained">
                                Create New Setting
                            </LoadingButton>}
                        </Authorized>
                    </Grid2>
                </Grid2>
                }
                />
            </Card>
        </Grid2>
        {creating && <Grid2 size={12}>
            <Grow in={creating}>
                <Grid2 size={12}>
                    <CreateSetting setCreating={setCreating} />
                </Grid2>
            </Grow>
        </Grid2>}
        <Grid2 size={12}>
            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="radio-button-setting-type"
                    name="row-radio-button-setting-type"
                    value={settingType}
                    onChange={(e, v) => setSettingType(v)}
                >
                    <FormControlLabel value="ALL" control={<Radio />} label="All" />
                    {Object.values(settingsTypesInfos).map(type => <FormControlLabel key={type.value} value={type.value} control={<Radio />} label={type.name} />)}
                </RadioGroup>
            </FormControl>
        </Grid2>
        {filtredSettings.map(el => <Grid2 size={12} key={el._id}>
            <Setting
                {...(settingGroups[el._id] ? settingGroups[el._id] : {})}
                selectedSetting={selectedSetting}
                setSelectedSetting={setSelectedSetting}
            />
        </Grid2>)}
    </Grid2>
}
export default Settings;