import React from "react";
import { styled } from '@mui/system';
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Grid2,
  Typography as MuiTypography,
  Box
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "hooks/useAuth";

const Spacer = styled(Box)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled(Box)`
  ${spacing};
  text-align: center;
  position: relative;
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  opacity: 0.75;
`;

function JoinUs() {
  const { userID, isInitialized, setupStep } = useAuth();
  return (
    <Wrapper pt={16} pb={16}>
      <Container>
        <Grid2 container alignItems="center" justifyContent="center">
          <Grid2 size={{ xs: 12, md: 6, lg: 6, xl: 6 }}>
            <Typography variant="h2" gutterBottom>
              Get Real-Time Analytics in Minutes.
            </Typography>
            <Subtitle variant="h5" gutterBottom>
              Don't wait, the data needs your attention.
            </Subtitle>
            <Spacer sx={{ mb: 5 }} />
            {isInitialized && userID ? (
              <Button
                sx={{ ml: 2}}
                color="secondary"
                variant="contained"
                to={setupStep ? `/setup?step=${setupStep}` : "/setup"}
                target="_self"
                component={Link}
              >
                {setupStep ? "Finish Setup" : "Get Started"}
              </Button>
            ) : (
              <Button
                to="/auth/sign-up"
                variant="contained"
                color="primary"
                size="large"
                target="_blank"
                component={Link}
              >
                Create My Account
              </Button>
            )}
          </Grid2>
        </Grid2>
      </Container>
    </Wrapper>
  );
}

export default JoinUs;
