import UtsActionTypes from "./uts.types"

const updateUtsAgentLogEventsAction = (events) => {
    return { type: UtsActionTypes.UPDATE_UTS_AGENT_LOG_EVENTS, payload: events }
}

const newUtsAgentLogEventAction = (event) => {
    return { type: UtsActionTypes.NEW_UTS_AGENT_LOG_EVENT, payload: event }
}

const newUtsAgentLogEventsAction = (events) => {
    return { type: UtsActionTypes.NEW_UTS_AGENT_LOG_EVENTS, payload: events }
}

const updateUtsAgentAnalysisAction = (analysis) => {
    return { type: UtsActionTypes.UPDATE_AGENT_ANALYSIS, payload: analysis }
}

const updateUtsEventsAction = (events) => {
    return { type: UtsActionTypes.UPDATE_UTS_EVENTS, payload: events }
}
const updateUtsStatsAction = (stats) => {
    return { type: UtsActionTypes.UPDATE_UTS_STATS, payload: stats }
}
const newUtsEventAction = (event) => {
    return { type: UtsActionTypes.NEW_UTS_EVENT, payload: event }
}
const newUtsEventsAction = (event) => {
    return { type: UtsActionTypes.NEW_UTS_EVENTS, payload: event }
}
const updateUtsDatesAction = (dates) => {
    return { type: UtsActionTypes.UPDATE_UTS_DATES, payload: dates }
}

export {
    updateUtsAgentLogEventsAction,
    newUtsAgentLogEventAction,
    newUtsAgentLogEventsAction,
    updateUtsAgentAnalysisAction,
    updateUtsEventsAction,
    updateUtsStatsAction,
    newUtsEventAction,
    newUtsEventsAction,
    updateUtsDatesAction
}
