import React, { useState } from 'react';
import {
    Grid2,
    Card,
    CardContent,
    TextField,
    Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { http } from 'utils/http';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { newGroupAction } from 'redux/groups/groups.actions';
import SettingsOptions from './SettingsOptions';
import settingType from 'constants/settingType';

const CreateGroup = ({ setCreating }) => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [fetching, setFetching] = useState(false);
    const [scanSetting, setScanSetting] = useState(null);
    const [alertsSetting, setAlertsSetting] = useState(null);
    const [geofencingSetting, setGeofencingSetting] = useState(null);
    const [keyboardSetting, setKeyboardSetting] = useState(null);
    const [rTTSetting, setRTTSetting] = useState(null);
    const [badgeSetting, setBadgeSetting] = useState(null);
    const [tunnelSetting, setTunnelSetting] = useState(null);
    const handleCreateGroup = async () => {
        setFetching(true);
        try {
            const resp = await http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/group/group`,
                {
                    name,
                    description,
                    scanSetting: scanSetting === '-' ? null : scanSetting,
                    alertsSetting: alertsSetting === '-' ? null : alertsSetting,
                    geofencingSetting: geofencingSetting === '-' ? null : geofencingSetting,
                    keyboardSetting: keyboardSetting === '-' ? null : keyboardSetting,
                    rTTSetting: rTTSetting === '-' ? null : rTTSetting,
                    badgeSetting: badgeSetting === '-' ? null : badgeSetting,
                    tunnelSetting: tunnelSetting === '-' ? null : tunnelSetting,
                });
            if (resp && resp.data) {
                dispatch(newGroupAction(resp.data));
                setFetching(false);
                setCreating(false);
            }
        } catch (error) {
            setFetching(false);
            toast.error(error?.response?.data?.message || "Something went wrong");
        }
    }

    return <Card variant="outlined">
        <CardContent>
            <Grid2 container spacing={2} alignItems="center" justifyContent="space-around">
                <Grid2 size={{ xs: 12, sm: 5 }}>
                    <TextField
                        label="Name"
                        value={name || ''}
                        onChange={(e) => setName(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ my: 2 }}
                    />
                </Grid2>
                <Grid2 size={7}>
                    <TextField
                        label="Description"
                        value={description || ''}
                        onChange={(e) => setDescription(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ my: 2 }}
                    />
                </Grid2>
                <Grid2 size={12} />
                <Grid2>
                    <Typography variant="h6" align="center">
                        Goefence:
                    </Typography>
                    <SettingsOptions
                        editing={true}
                        fetching={fetching}
                        type={settingType.GEOFENCING}
                        settingID={geofencingSetting}
                        setSettingID={setGeofencingSetting}
                    />
                </Grid2>
                <Grid2>
                    <Typography variant="h6" align="center">
                        Scan Goal:
                    </Typography>
                    <SettingsOptions
                        editing={true}
                        fetching={fetching}
                        type={settingType.SCAN}
                        settingID={scanSetting}
                        setSettingID={setScanSetting}
                    />
                </Grid2>
                <Grid2>
                    <Typography variant="h6" align="center">
                        Keyboard Settings:
                    </Typography>
                    <SettingsOptions
                        editing={true}
                        fetching={fetching}
                        type={settingType.KEYBOARD}
                        settingID={keyboardSetting}
                        setSettingID={setKeyboardSetting}
                    />
                </Grid2>
                <Grid2>
                    <Typography variant="h6" align="center">
                        Alert Settings:
                    </Typography>
                    <SettingsOptions
                        editing={true}
                        fetching={fetching}
                        type={settingType.ALERTS}
                        settingID={alertsSetting}
                        setSettingID={setAlertsSetting}
                    />
                </Grid2>
                <Grid2>
                    <Typography variant="h6" align="center">
                        RTT Settings:
                    </Typography>
                    <SettingsOptions
                        editing={true}
                        fetching={fetching}
                        type={settingType.RTT}
                        settingID={rTTSetting}
                        setSettingID={setRTTSetting}
                    />
                </Grid2>
                <Grid2>
                    <Typography variant="h6" align="center">
                        Badge Settings:
                    </Typography>
                    <SettingsOptions
                        editing={true}
                        fetching={fetching}
                        type={settingType.BADGE}
                        settingID={badgeSetting}
                        setSettingID={setBadgeSetting}
                    />
                </Grid2>
                <Grid2>
                    <Typography variant="h6" align="center">
                        Tunnel Settings:
                    </Typography>
                    <SettingsOptions
                        editing={true}
                        fetching={fetching}
                        type={settingType.TUNNEL}
                        settingID={tunnelSetting}
                        setSettingID={setTunnelSetting}
                    />
                </Grid2>
                <Grid2 size={12} />
                <Grid2 size={12}>
                    <LoadingButton
                        size="small"
                        endIcon={<SaveIcon />}
                        onClick={handleCreateGroup}
                        loadingPosition="end"
                        variant="contained"
                        loading={fetching}
                        disabled={!name}
                    >
                        Create
                    </LoadingButton>
                </Grid2>
            </Grid2>
        </CardContent>
    </Card>
}

export default CreateGroup;
