import LadsServersActionTypes from "./ladsServers.types"

const initialState = [];
const ladsServersReducer = (state = initialState, action) => {
    switch (action.type) {
        case LadsServersActionTypes.UPDATE_LADS_SERVERS:
            return Object.values([...state, ...action.payload].reduce((p, c) => ({ ...p, [c.name]: { ...(p[c.name] || {}), ...c } }), {}));
        case LadsServersActionTypes.UPDATE_LADS_SERVER:
            if (!action.payload.name) return state;
            const server = state.find(el => el.name === action.payload.name) || {};
            const servers = state.filter(el => el.name !== action.payload.name);
            servers.push({ ...server, ...action.payload })
            return servers;
        default:
            return state;
    }
}
export default ladsServersReducer;
