import { 
    useEffect,
     useState 
    } from "react";
import {
    Typography,
    Grid2,
    Paper,
} from "@mui/material";
import { useSelector } from "react-redux"
import HtmlTooltip from "components/HtmlTooltip";
import InfoIcon from '@mui/icons-material/Info';
import { http } from "utils/http";



const CompanyStats = ({ billingStatus }) => {
    const admins = useSelector((state) => state.admins.admins || []);
    const devices = useSelector((state) => state.devices || []);
    const [reportedUsage, setReportedUsage] = useState(null);
    useEffect(() => {
        if (billingStatus && ["complete", "active", "trialing"].includes(billingStatus.status)) {
            http.get(`${process.env.REACT_APP_SERVER_URL}/api/v2/payment/reported-usage`)
                .then((res) => {
                    if (res && res.data && res.data.usage) {
                        setReportedUsage(res.data.usage);
                    }
                })
                .catch((error) => console.log("error", error));
        }
    }, [billingStatus])

    return <Grid2 container spacing={8}>
        <Grid2 size={{ xs: 12, md: 4}}>
            <Paper elivation={3} sx={{ p: 4 }}>
                <Grid2 container justifyContent="center">
                    <Grid2 size={12}>
                        <Typography variant="h5" align="center">
                            Dashalytics Plan
                        </Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        {billingStatus && <Typography variant="h2" align="center" color="primary">
                            {billingStatus.planName || "-"}
                        </Typography>}
                    </Grid2>
                </Grid2>
            </Paper>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4}}>
            <Paper elivation={3} sx={{ p: 4 }}>
                <Grid2 container justifyContent="center">
                    <Grid2 size={12}>
                        <Typography variant="h5" align="center">
                            Number of Devices  {reportedUsage && <HtmlTooltip
                                placement="bottom"
                                maxWidth={500}
                                title={<>
                                    <Typography variant="h6" align="center" sx={{ p: 2 }}>
                                        Last reported usage: {reportedUsage} Devices
                                    </Typography>
                                </>}>
                                <InfoIcon color="primary" />
                            </HtmlTooltip>}
                        </Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        <Typography variant="h2" align="center" color="primary">
                            {devices.length}
                        </Typography>
                    </Grid2>
                </Grid2>
            </Paper>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4}}>
            <Paper elivation={3} sx={{ p: 4 }}>
                <Grid2 container justifyContent="center">
                    <Grid2 size={12}>
                        <Typography variant="h5" align="center">
                            Number of Administrators
                        </Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        <Typography variant="h2" align="center" color="primary">
                            {admins.length}
                        </Typography>
                    </Grid2>
                </Grid2>
            </Paper>
        </Grid2>
    </Grid2>
}
export default CompanyStats;
