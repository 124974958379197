import React, { useEffect } from "react";
import { updateRttDevicesLocationAction, updateRttAPsAction } from 'redux/rtt/rtt.actions';
import { useDispatch } from "react-redux";
import RTTMap from 'pages/rtt/Tracking/RTTMap';

const RTTMapPreView = ({ rttSettings, groupDevices }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (rttSettings && rttSettings.rttAccessPoints && Array.isArray(rttSettings.rttAccessPoints)) {
            dispatch(updateRttAPsAction(rttSettings.rttAccessPoints));
        }
        else {
            console.log("no valid accessPoints config found");
        }
    }, [dispatch, rttSettings]);

    useEffect(() => {
        dispatch(updateRttDevicesLocationAction([]));
    }, [dispatch]);

    return <RTTMap rttSettings={rttSettings} ignoredDevices={[]} groupDevices={groupDevices} />

}
export default RTTMapPreView;