import {
    useState,
    useMemo
} from 'react';
import {
    TextField as MuiTextField,
    Grid2,
    Typography,
    Card,
    CardContent,
    Chip,
    Grow,
} from '@mui/material';
import { styled } from '@mui/system';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import WarningIcon from '@mui/icons-material/Warning';
import {
    defaultBadgeSettings,
    isValidLength,
    isValidLowercase,
    isValidUppercase,
    isValidNumbers,
    isValidSpecialChar
} from 'utils/badge';

const TextField = styled(MuiTextField)`
    label.Mui-disabled {
        opacity: 0;
    }
    .Mui-disabled fieldset {
        opacity: 0;
    }
`;


const TestCode = ({
    badgeLength = defaultBadgeSettings.badgeLength,
    badgeContainsLowerCase = defaultBadgeSettings.badgeContainsLowerCase,
    badgeLowercaseCount = defaultBadgeSettings.badgeLowercaseCount,
    badgeContainsUpperCase = defaultBadgeSettings.badgeContainsUpperCase,
    badgeUppercaseCount = defaultBadgeSettings.badgeUppercaseCount,
    badgeContainsNumber = defaultBadgeSettings.badgeContainsNumber,
    badgeNumberCount = defaultBadgeSettings.badgeNumberCount,
    badgeContainsSpecialChar = defaultBadgeSettings.badgeContainsSpecialChar,
    badgeSpecialCharCount = defaultBadgeSettings.badgeSpecialCharCount,
}) => {
    const [testCode, setTestCode] = useState('');
    const {
        length,
        lowercase,
        uppercase,
        numbers,
        specialChars
    } = useMemo(() => {
        return {
            length: isValidLength(testCode, badgeLength),
            lowercase: isValidLowercase(testCode, badgeContainsLowerCase, badgeLowercaseCount),
            uppercase: isValidUppercase(testCode, badgeContainsUpperCase, badgeUppercaseCount),
            numbers: isValidNumbers(testCode, badgeContainsNumber, badgeNumberCount),
            specialChars: isValidSpecialChar(testCode, badgeContainsSpecialChar, badgeSpecialCharCount)
        }
    }, [
        testCode,
        badgeLength,
        badgeContainsLowerCase,
        badgeLowercaseCount,
        badgeContainsUpperCase,
        badgeUppercaseCount,
        badgeContainsNumber,
        badgeNumberCount,
        badgeContainsSpecialChar,
        badgeSpecialCharCount
    ]);

    const isTestCodeValid = useMemo(() => {
        return length && lowercase && uppercase && numbers && specialChars;
    }, [length, lowercase, uppercase, numbers, specialChars]);

    return <Card variant="outlined">
        <CardContent>
            <Grid2 container columnSpacing={4} rowSpacing={2}>
                <Grid2 size={4}>
                    <Typography variant="h6" >
                        Badge Test
                    </Typography>
                </Grid2>
                <Grid2 flexGrow={1}>
                    <TextField
                        value={testCode}
                        onChange={(e) => setTestCode(e.target.value)}
                        type="text"
                        size="small"
                        fullWidth
                        label="Test your code here"
                    />
                </Grid2>
                <Grid2>
                    <Chip
                        label={isTestCodeValid ? " Valid" : "Invalid"}
                        variant="outlined" color={isTestCodeValid ? "primary" : "error"}
                        sx={{
                            visibility: testCode.length ? 'visibile' : 'hidden',
                            width: 100
                        }}
                        icon={isTestCodeValid ? <CheckBoxIcon /> : <WarningIcon />}
                    />
                </Grid2>
                {testCode.length > 0 && <Grow in={testCode.length > 0}>
                    <Grid2 size={12} container>
                        <Grid2>
                            <CheckBoxIcon color={length ? "primary" : "error"} />
                            <Typography display="inline" color={length ? "primary" : "error"}>Length</Typography>
                        </Grid2>
                        <Grid2>
                            <CheckBoxIcon color={lowercase ? "primary" : "error"} />
                            <Typography display="inline" color={lowercase ? "primary" : "error"}>Lowercase</Typography>
                        </Grid2>
                        <Grid2>
                            <CheckBoxIcon color={uppercase ? "primary" : "error"} />
                            <Typography display="inline" color={uppercase ? "primary" : "error"}>Uppercase</Typography>
                        </Grid2>
                        <Grid2>
                            <CheckBoxIcon color={numbers ? "primary" : "error"} />
                            <Typography display="inline" color={numbers ? "primary" : "error"}>Numbers</Typography>
                        </Grid2>
                        <Grid2>
                            <CheckBoxIcon color={specialChars ? "primary" : "error"} />
                            <Typography display="inline" color={specialChars ? "primary" : "error"}>Special Characters</Typography>
                        </Grid2>
                    </Grid2>
                </Grow>}
            </Grid2>
        </CardContent>
    </Card>
}
export default TestCode;