import { http } from './http';
export const logEvent = async ({ type, token }) => {
    http.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v2/log/log-event`,
        {
            url: window.location.href,
            type,
            token,
        }
    )
        .catch(error => {
            console.log("error", error)
        })
}
