import ClientSettingsActionTypes from "./clientSettings.types";

const initialState = {
}

const clientSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ClientSettingsActionTypes.UPDATE_CLIENT_SETTINGS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default clientSettingsReducer;
