import React, { useState } from 'react';
import {
    Button,
    Grid2,
    Grow,
    Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';
import { toast } from 'react-toastify';
import Link from 'components/Link';

const LADS = () => {
    const [loading, setLoading] = useState(false);
    const [zipLink, setZipLink] = useState('');
    const [exeLink, setExeLink] = useState('');
    const handleGenerateLinks = () => {
        setLoading(true);
        const pZip = fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/dashalytics-api/download/lads/zip`);
        pZip.then(res => res.json())
            .then(res => {
                if (res && res.directDownloadLink) {
                    setZipLink(res.directDownloadLink);
                }
                else throw new Error("link not found");
            })
            .catch(error => {
                setLoading(false);
                console.log("error", error);
                toast.error("Something went wrong", { toastId: "download-error-message" });
            });
        const pExe = fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/dashalytics-api/download/lads/exe`);
        pExe.then(res => res.json())
            .then(res => {
                if (res && res.directDownloadLink) {
                    setExeLink(res.directDownloadLink);
                }
                else throw new Error("link not found");
            })
            .catch(error => {
                setLoading(false);
                console.log("error", error);
                toast.error("Something went wrong", { toastId: "download-error-message" });
            });

        Promise.all([pZip, pExe])
            .then(() => {
                setLoading(false);
            })
    }

    return zipLink && exeLink ? (
        <Grow
            in={Boolean(zipLink && exeLink)}
            style={{ transformOrigin: '0 0 0' }}
            {...(Boolean(zipLink && exeLink) ? { timeout: 1000 } : {})}
        >
            <Grid2 container spacing={6} alignItems="center">
                <Grid2 size={6}>
                    <Typography component="h2" variant="body2">
                        GSW LADS
                    </Typography>
                </Grid2>
                <Grid2 size={3}>
                    <Button
                        component={Link}
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ textAlign: 'center' }}
                        to={zipLink}
                        target="_blank"
                        size="normal"
                    >
                        ZIP
                    </Button >
                </Grid2>
                <Grid2 size={3}>
                    <Button
                        component={Link}
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ textAlign: 'center' }}
                        to={exeLink}
                        target="_blank"
                        size="normal"
                    >
                        EXE
                    </Button >
                </Grid2>
            </Grid2>
        </Grow>
    )
        :
        (
            <LoadingButton
                onClick={handleGenerateLinks}
                loading={loading}
                variant="contained"
                fullWidth
                loadingPosition="end"
                size="normal"
                endIcon={<DownloadIcon />}
            >
                GSW LADS
            </LoadingButton>
        );
};

export default LADS;
