import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import {
    Card as MuiCard,
    CardContent,
    Button as MuiButton,
    Grid2,
    TextField as MuiTextField,
    Typography,
    Checkbox,
    FormControlLabel,
    Box
} from "@mui/material";
import { http } from "utils/http";
import { spacing } from "@mui/system";
import { toast } from "react-toastify";
import { getCompany } from "utils/api";
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
const ScanEventsPolicy = () => {
    const [updatePolicyConfirmation, setUpdatePolicyConfirmation] = useState(false);
    const [password, setPassword] = useState('');
    const [agreed, setAgreed] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [ignoreScanHeaders, setIgnoreScanHeaders] = useState(false);
    const [ignoreScanContent, setIgnoreScanContent] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    useEffect(() => {
        const fetchCall = getCompany().then(company => {
            if (company) {
                setIgnoreScanContent(!!company.ignoreScanContent);
                setIgnoreScanHeaders(!!company.ignoreScanHeaders);
            }
        })
        return () => {
            fetchCall.cancel();
        }
    }, [])
    const handleCancelUpdate = () => {
        setAgreed(false);
        setPassword('');
        setUpdatePolicyConfirmation(false);
        setError('');
        setIgnoreScanHeaders(false);
        setIgnoreScanContent(false);
    }
    const handleCheckIgnoreHeaders = (e) => {
        const checked = e.target.checked;
        if (checked) {
            setIgnoreScanContent(true);
            setIgnoreScanHeaders(true);
        } else {
            setIgnoreScanHeaders(false);
        }
    }
    useEffect(() => {
        if (!(ignoreScanContent && ignoreScanHeaders)) {
            setAgreed(false);
        }
        if (!ignoreScanContent && ignoreScanHeaders) {
            setIgnoreScanHeaders(false);
        }
    }, [ignoreScanContent, ignoreScanHeaders])
    const handleUpdateIgnoreScanMessages = async () => {
        setError('');
        setFetching(true);
        try {
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/event/ignore-scan`, {
                password,
                ignoreScanHeaders,
                ignoreScanContent,
            })
            setFetching(false);
            if (resp.data && resp.data.success) {
                toast.success("Settings updated!");
                setMessage("Success!");
                setAgreed(false);
                setPassword('');
                setError('');
            }
        } catch (err) {
            // Handle Error Here
            setFetching(false);
            if (err && err.response && err.response.data && err.response.data.message) {
                toast.error(err.response.data.message);
                return setError(err.response.data.message);

            }
            console.error("error", err);
            setError("Something went wrong");
            toast.error("Something went wrong");
        }
    }
    return <Card sx={{ mb: 6 }}>
        <CardContent>
            <Typography variant="h6" gutterBottom>
                Ignore scan messages (5000 & 5001)
            </Typography>
            <Grid2 container spacing={6}>
                {!updatePolicyConfirmation ?
                    (
                        <Grid2 size={12}>
                            <Button onClick={() => setUpdatePolicyConfirmation(true)} variant="contained" color="error">
                                Show more
                            </Button>
                        </Grid2>
                    ) :
                    (
                        <React.Fragment>
                            <Grid2 size={12} sx={{ mt: 3 }}>
                                <Typography variant="body2" gutterBottom color={ignoreScanContent ? 'error' : ''}>
                                    * Ingoring 5000 and 5001 content means their content will not be saved, however, the headers of messages will be used for stats (we store only the date of messsage)
                                </Typography>
                                <Typography variant="body2" gutterBottom color={ignoreScanHeaders ? 'error' : ''}>
                                    * Ignoring 5000 and 5001 headers means they will never be saved in database (all scan related features will not be functional)
                                </Typography>
                                <Typography variant="body2" gutterBottom color="primary">
                                    * Other alternatives exist, such as encryption where messages are encrypted in the LADS and decrypted only in the front end
                                </Typography>
                            </Grid2>
                            {message && <Grid2 size={12}>
                                <Typography variant="body" gutterBottom color="#0d6efd">
                                    {message}
                                </Typography>
                            </Grid2>}
                            <Box sx={{ mx: 8, width: '100%' }}>
                                <Grid2 container>
                                    <Grid2 size={12}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={handleCheckIgnoreHeaders} checked={ignoreScanHeaders} color="error" />}
                                            label="Ingore 5000&5001 headers"
                                            sx={{ color: '#d32f2f' }}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={(e) => setIgnoreScanContent(e.target.checked)} checked={ignoreScanContent} color="error" />}
                                            label="Ingore 5000&5001 content"
                                            sx={{ color: '#d32f2f' }}
                                        />
                                    </Grid2>
                                </Grid2>
                            </Box>
                            <Grid2 size={4}>
                                <TextField
                                    label="Type your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <FormControlLabel
                                    control={<Checkbox onChange={(e) => setAgreed(e.target.checked)} checked={agreed} color="primary" />}
                                    label="I understand What I am doing"
                                />
                                <Button onClick={handleUpdateIgnoreScanMessages} sx={{ mx: 1 }} disabled={!agreed || password.length < 6 || fetching} variant="contained" color="error">
                                    Yes
                                </Button>
                                <Button sx={{ mx: 1 }} onClick={handleCancelUpdate} variant="contained" color="primary">
                                    No
                                </Button>
                            </Grid2>
                            <Grid2 size={12}>
                                {error && <Typography variant="body" color="error" gutterBottom>
                                    {error}
                                </Typography>}
                            </Grid2>
                        </React.Fragment>
                    )}
            </Grid2>
        </CardContent>
    </Card >
}
export default ScanEventsPolicy;