//import * as Comlink from "comlink";
import { useMemo } from 'react';
import { useSelector } from "react-redux"

const useEncryption = () => {
    const worker = useMemo(() => {
        if (window.Worker) {
            const callbacks = [];
            const _worker = new Worker(new URL("utils/encryption.worker", import.meta.url));
            _worker.onmessage = (message) => {
                console.log("message", message.data);
                if (!message.data) return;
                if (!message.data.id) return;
                const { result, id, error } = message.data;
                const callBacksIndex = callbacks.findIndex(el => el.id === id);
                if (callBacksIndex >= 0) {
                    const { resolve, reject } = callbacks[callBacksIndex];
                    callbacks.splice(callBacksIndex, 1);
                    if ("function" !== typeof resolve || "function" !== typeof reject) return;
                    if (typeof error === "string") {
                        console.log("rejecting promise");
                        reject(error);
                    }
                    if (typeof result === "string")
                        resolve(result);
                }
            }
            const encryptInline = async ({ input, password }) => {
                if (_worker) {
                    const id = `${Math.random()}-${Math.random()}-${Math.random()}`;
                    return new Promise((resolve, reject) => {
                        callbacks.push({ id, resolve, reject })
                        _worker.postMessage({
                            id,
                            operation: "ENCRYPT",
                            input,
                            password
                        });
                    })
                } else throw new Error("Browser not supported");
            }
            const decryptInline = async ({ input, password }) => {
                if (_worker) {
                    const id = `${Math.random()}-${Math.random()}-${Math.random()}`;
                    return new Promise((resolve, reject) => {
                        callbacks.push({ id, resolve, reject })
                        _worker.postMessage({
                            id,
                            operation: "DECRYPT",
                            input,
                            password
                        });
                    })
                } else throw new Error("Browser not supported");
            }
            return { encryptInline, decryptInline };
        }
        else return null;
    }, []);
    const globalPassword = useSelector((state) => state.system.decryptionPassword);
    async function encryptInline({ input, password }) {
        if (worker) {
            const result = await worker.encryptInline({ input, password });
            console.log("results got from worker", result);
            return result;
        }
        else console.error("your browser is not supported");
    }
    async function decryptInline({ input, password }) {
        console.log("decryptInline");
        if (worker) {
            const result = await worker.decryptInline({ input, password });
            console.log("results got from worker", result);
            return result;
        }
        else console.error("your browser is not supported");
    }
    async function decrypt({ input }) {
        if (!globalPassword) throw new Error('no-password');
        if (worker) {
            const result = await worker.decryptInline({ input, password: globalPassword });
            return result;
        }
    }
    return {
        encryptInline,
        decryptInline,
        decrypt,
    }
}
export default useEncryption;