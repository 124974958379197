import {
    useEffect,
    useState,
    useMemo,
    useRef
} from 'react';
import {
    Typography,
    Alert
} from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { updateScanEventsAction } from "redux/events/events.actions";
import { getEvents } from "utils/api";
import codes from "constants/codes";
import {
    defaultBadgeSettings,
    isValidUserBadge
} from 'utils/badge';

const SCAN_EVENTS_SAMPLE_SIZE = 500;
const CHECK_PERCENTAGE_DEBOUNCE = 2000;



const EventsMatchedCheck = ({
    badgeLength = defaultBadgeSettings.badgeLength,
    badgeContainsLowerCase = defaultBadgeSettings.badgeContainsLowerCase,
    badgeLowercaseCount = defaultBadgeSettings.badgeLowercaseCount,
    badgeContainsUpperCase = defaultBadgeSettings.badgeContainsUpperCase,
    badgeUppercaseCount = defaultBadgeSettings.badgeUppercaseCount,
    badgeContainsNumber = defaultBadgeSettings.badgeContainsNumber,
    badgeNumberCount = defaultBadgeSettings.badgeNumberCount,
    badgeContainsSpecialChar = defaultBadgeSettings.badgeContainsSpecialChar,
    badgeSpecialCharCount = defaultBadgeSettings.badgeSpecialCharCount,
    accordionExpanded
}) => {
    const [percentageMatch, setPercentageMatch] = useState(-1);
    const lastCheckTimestamp = useRef(0);
    const checkTimer = useRef(null);
    const mounted = useRef(false);
    const scanEvents = useSelector(state => state.events.scanEvents);
    const dispatch = useDispatch();
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, [])
    useEffect(() => {
        if (!accordionExpanded || scanEvents.length >= SCAN_EVENTS_SAMPLE_SIZE) return
        const fetchCall = getEvents({
            messageID: codes.GSW_SCANNED,
            limit: SCAN_EVENTS_SAMPLE_SIZE
        })
            .then((events) => {
                dispatch(updateScanEventsAction(events))
            });
        return () => {
            if (fetchCall) fetchCall.cancel();
        }
    }, [dispatch, scanEvents, accordionExpanded]);

    useEffect(() => {
        if (!accordionExpanded) return;
        if (Date.now() > lastCheckTimestamp.current + CHECK_PERCENTAGE_DEBOUNCE) {
            clearTimeout(checkTimer.current);
            checkTimer.current = setTimeout(() => {
                if (!mounted.current) return;
                if (scanEvents.length < SCAN_EVENTS_SAMPLE_SIZE) {
                    setPercentageMatch(-1);
                    return;
                }
                const eventsCodes = scanEvents.slice(-SCAN_EVENTS_SAMPLE_SIZE);
                const results = eventsCodes.map(el => isValidUserBadge({
                    badgeLength,
                    badgeContainsLowerCase,
                    badgeLowercaseCount,
                    badgeContainsUpperCase,
                    badgeUppercaseCount,
                    badgeContainsNumber,
                    badgeNumberCount,
                    badgeContainsSpecialChar,
                    badgeSpecialCharCount,
                    testCode: el.word || ''
                })).reduce((a, c) => a + (c ? 1 : 0), 0);
                setPercentageMatch(parseInt(results * 100 / SCAN_EVENTS_SAMPLE_SIZE));
            }, CHECK_PERCENTAGE_DEBOUNCE)
            return;
        }
    }, [
        badgeLength, badgeContainsLowerCase, badgeLowercaseCount, badgeContainsUpperCase,
        badgeUppercaseCount, badgeContainsNumber, badgeNumberCount, badgeContainsSpecialChar,
        badgeSpecialCharCount, scanEvents, accordionExpanded
    ]);
    const alertSeverity = useMemo(() => {
        if (percentageMatch === 0) return "warning";
        if (percentageMatch > 50) return "error";
        return "info";
    }, [percentageMatch])
    if (percentageMatch < 0) return <></>
    return <Alert severity={alertSeverity}>
        <Typography variant="body1">Badge ID Matched {percentageMatch}% Scan Event</Typography>
        <Typography variant="caption">* Used Sample is the last {SCAN_EVENTS_SAMPLE_SIZE} scan events</Typography>
    </Alert>
}

export default EventsMatchedCheck;
