export const isValidEmail = (email) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

export const isValidFileName = (fileName) => !/^(con|prn|aux|nul|com[1-9]|lpt[1-9])$|([<>:"/\\|?*])|(\.|\s)$/ig.test(fileName);

export const isValidID = (id) => /^([a-f0-9]){24}$/.test(id)

export const isStringable = (obj) => {
    if (!obj) return;
    if ("object" !== typeof obj) return;
    try {
        JSON.stringify(obj);
        return true;
    } catch {
        return false
    }
}

export const isValidUUID4 = (uuid) => /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}(?:\/.*)?$/i.test(uuid);