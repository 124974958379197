import codes from 'constants/codes';

const alertEventsTypes = [
    {
        id: "8218cf2b-d103-418c-9501-4e9c8ec4fdc5",
        messageID: codes.DEVICE_GEOFENCE_CROSSING,
        name: "Geofence Crossing",
        properties: [
            {
                operations: ["="],
                type: 'boolean',
                name: 'properties.crossed',
                description: 'Device Crossed Geofence',
            },
            {
                operations: ["=", ">", "<", ">=", "<=", "!"],
                type: 'number',
                name: 'properties.fromEdge',
                description: 'Distance from Edge of Geofence (in M)',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,

    },
    {
        id: "bc0dc50c-2338-4aa5-825f-e7cd8b3722fa",
        messageID: codes.BATT_LEVEL,
        name: "Battery Level Changed",
        properties: [
            {
                operations: ["=", ">", "<", ">=", "<=", "!"],
                type: 'number',
                name: 'level',
                description: 'Battery Level',
            },
            {
                operations: ["=", "<", "<=", "!"],
                type: 'number',
                name: 'properties.dischargingAndLevel',
                description: 'Not charging and level',
            },
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "55497825-0c15-40ec-b050-14a4f2c519d4",
        messageID: codes.WIFI_LEVEL,
        name: "Wi-Fi Level Changed",
        properties: [
            {
                operations: ["=", ">", "<", ">=", "<=", "!"],
                type: 'number',
                name: 'level',
                description: 'Wifi Level',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "a1bd1dd3-1642-4254-b787-85d5a52df208",
        messageID: codes.DEVICE_DROP,
        name: "Device Drop",
        properties: [
            {
                operations: ["=", ">", "<", ">=", "<=", "!"],
                type: 'number',
                name: 'level',
                description: 'Drop Distance(cm)',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "cadfca06-8e27-473a-a5ca-ae7c19ad68aa",
        messageID: codes.ADV_BATTERY,
        name: "ADV Battery",
        properties: [
            {
                operations: ["=", ">", "<", ">=", "<=", "!"],
                type: 'number',
                name: 'properties.temperature',
                description: 'Battery Temperature',
            },
            {
                operations: ["=", ">", "<", ">=", "<=", "!"],
                type: 'number',
                name: 'properties.level',
                description: 'Battery Level',
            },
            {
                operations: ["=", "!"],
                type: 'number',
                name: 'properties.health',
                description: 'Battery Health Code',
            },
            {
                operations: ["=", ">", "<", ">=", "<=", "!"],
                type: 'number',
                name: 'properties.voltage',
                description: 'Battery Voltage',
            },
            {
                operations: ["="],
                type: 'boolean',
                name: 'properties.battery_low',
                description: 'Battery Health Code',
            },
            {
                operations: ["="],
                type: 'boolean',
                name: 'properties.power_sharing',
                description: 'Power Sharing',
            },
            {
                operations: ["="],
                type: 'boolean',
                name: 'properties.invalid_charger',
                description: 'Invalid Charger',
            },
            {
                operations: ["="],
                type: 'boolean',
                name: 'properties.plugged',
                description: 'Charger Plugged',
            },
            // move to correct order
            {
                operations: ["="],
                type: 'boolean',
                name: 'properties.batteryError',
                description: 'Battery Error',
            },
            {
                operations: ["="],
                type: 'boolean',
                name: 'properties.batteryOverheat',
                description: 'Battery Overheat',
            },
            {
                operations: ["="],
                type: 'boolean',
                name: 'properties.batteryDead',
                description: 'Battery Dead',
            },
            {
                operations: ["="],
                type: 'boolean',
                name: 'properties.batteryOverVoltage',
                description: 'Battery Over Voltage',
            },
            {
                operations: ["="],
                type: 'boolean',
                name: 'properties.batteryUnspecifiedFailure',
                description: 'Battery Unspecified Failure',
            },
            {
                operations: ["="],
                type: 'boolean',
                name: 'properties.batteryTooCold',
                description: 'Battery Too Cold',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "3e61dfac-c89a-4a62-b518-7d20674c5cff",
        messageID: codes.GSW_SCANNED,
        name: "Scan",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'word',
                description: 'Scan code',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "02e1f29a-d74f-4ff5-91fe-5c22ddc85cf4",
        messageID: codes.TE_KEYBOARD_EVENT,
        name: "Keyboard",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'word',
                description: 'Key Pressed',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "8a0ed0ad-ec4a-40de-8a92-619e7ca7ed21",
        messageID: codes.KEY_CODE,
        name: "Key Code",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'word',
                description: 'Key Pressed',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "85f68908-fdf4-49a0-8118-72a648063ea2",
        messageID: codes.KEY_OUTPUT,
        name: "Key Output",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'word',
                description: 'Key code',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "a4b8bfb3-e73d-4774-8a91-f35179cabb3b",
        messageID: codes.APP_START,
        name: "App Started",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'word',
                description: 'App Name',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "19ec9549-d8a4-4f65-b264-f8d22d6d1c43",
        messageID: codes.APP_STOPPED,
        name: "App Stoped",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'word',
                description: 'App Name',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "21efd152-dfcf-4aaf-b45a-285d02af0734",
        messageID: codes.ACT_RESUMED,
        name: "Activity resumed",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'word',
                description: 'App Name',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "310bd29b-ec3e-4fb7-8e96-ec15f58dbca9",
        messageID: codes.ACT_DESTROYED,
        name: "Activity destroyed",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'word',
                description: 'App Name',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "20335a35-03b1-4520-b6cf-b41da635435b",
        messageID: codes.WEBPAGE_LOADED,
        name: "Web Page Loaded",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'url',
                description: 'URL',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "780d9819-971d-4db9-8794-1c150a1e1915",
        messageID: codes.HOST_LAUNCHED,
        name: "Host Page Loaded",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'hostname',
                description: 'Host Name',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "e3e88cc7-2757-4411-85ef-1b34f1206cc3",
        messageID: codes.DEVICE_RELEASE_LICENSE, // test - it must be possible to reset this
        name: "Release device license",
        properties: [],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "c8666c04-5a3b-4fdc-b820-d5a75ec4e9dd",
        messageID: codes.KEYBOARD_SET,
        name: "Keyboard Set",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'word',
                description: 'Keyboard',// ex: qwerty_portrait
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "4612a9e7-e39d-4ae1-8113-3b005c680a70",
        messageID: codes.TE_CONNECTION,
        name: "Terminal Connection",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'hostname',
                description: 'Host Name',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "caa211de-0b02-11ee-be56-0242ac120002",
        messageID: codes.CONNECTION_TO_BACKEND_LOST,
        name: "Connection to backend lost",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'hostname',
                description: 'Host Name',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "862bfd11-cf4f-4bbc-8dd6-9affd073dd0a",
        messageID: codes.WEBPAGE_BLOCKED,
        name: "Web Page Blocked",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'url',
                description: 'URL',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "15ceec6a-03cd-453b-a4f7-b31c2fcdb995",
        messageID: codes.SCREEN_RECOGNIZED,
        name: "Screen Recognized",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'word',
                description: 'Name',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "9ffd4cbb-15d0-4198-aaa6-56badfcba0fd",
        messageID: codes.DEVICE_RENAME_EVENT,
        name: "Device Rename",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'word',
                description: 'New Name',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "f6539a19-e810-4707-98a0-f0aa6e6510a6",
        messageID: codes.TOP_URL_BLOCKED,
        name: "Top Url Blocked",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'url',
                description: 'URL',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "aea01197-5402-48f3-8b05-1983cda280eb",
        messageID: codes.DEVICE_DELETE_EVENT,
        name: "Device Delete",
        properties: [],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "75e82377-d370-4248-a578-672b9bac04e8",
        messageID: codes.SCAN_SUMMARY,
        name: "Last 24h Device Scan Perfermance",
        properties: [
            {
                operations: ["=", ">", "<", ">=", "<=", "!"],
                type: 'number',
                name: 'level',
                description: 'Total Scan',
            },
            {
                operations: ["=", ">", "<", ">=", "<=", "!"],
                type: 'number',
                name: 'properties.percentage',
                description: 'Percentage relative to Scan Goal',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "be116539-e4ef-4d91-aaa2-258a87db91a1",
        messageID: codes.DEVICE_PING,
        name: "Device Ping",
        properties: [],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "4491bceb-bcbf-4bb5-ad7b-88d327caf4f7",
        messageID: codes.UPDATE_LADS_IP_PORT,
        name: "Update Lads IP/Port",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.ladsIP',
                description: 'Lads Server IP',
            },
            {
                operations: ["=", ">", "<", ">=", "<=", "!"],
                type: 'number',
                name: 'properties.ladsPort',
                description: 'Lads Server Port',
            },
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "c2f11f10-0e2e-4e91-98ce-d2724ba97361",
        messageID: codes.WEB_KEYBOARD_EVENT,
        name: "Web keyboard",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'hostname',
                description: 'Host Name',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'word',
                description: 'Key',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.appN',
                description: 'App Name',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.appV',
                description: 'App version',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "2a57aab4-8023-4e24-9160-67ef2b4907fe",
        messageID: codes.WEB_KEYBOARD_EVENT,
        name: "Web keyboard",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'hostname',
                description: 'Host Name',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'word',
                description: 'Key',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.appN',
                description: 'App Name',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.appV',
                description: 'App version',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "5ab0caf6-f996-4da1-bc59-937fbc5268a9",
        messageID: codes.USER_BADGE_SCAN,
        name: "User badge scan",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'badgeID',
                description: 'Badge ID',
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
    {
        id: "575738d0-75ef-454e-a066-a20d1c65b6b4",
        messageID: codes.DEVICE_VARS_CHANGED,
        name: "Device Vars Changed",
        properties: [
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.build_brand',
                description: 'Build Brand',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.build_device',
                description: 'Build Device',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.build_display',
                description: 'Build Display',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.build_hardware',//qcom
                description: 'Build Hardware',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.build_host', //3730f6...
                description: 'Build Host',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.build_id',
                description: 'Build ID',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.build_manufacturer',
                description: 'Manufacturer',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.build_model',
                description: 'Model',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.build_product',
                description: 'Product',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.build_serial',
                description: 'Build Serial',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.display_density',
                description: 'Display Density',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.display_dimensions',
                description: 'Display Dimensions',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.gswcb_build_type',
                description: 'GSWCB Build Type',
            },


            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.gswcb_config_download_date',//
                description: 'GSWCB Config Download Date',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.gswcb_config_name',
                description: 'GSWCB Config Name',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.gswcb_version',
                description: 'GSWCB Version',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.license_expiration_date',
                description: 'License Expiration Date', // ? add date type?
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.license_process',
                description: 'License Process', // ? add date type?
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.license_subscription_until',//
                description: 'Subscription Untill',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.license_type',
                description: 'Licence type',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.network_mac',
                description: 'Network MAC',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.network_clnt_side_ip',
                description: 'IP Address',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.version_codename',
                description: 'Version Code Name',
            },
            {
                operations: ["=", "!", "*"],
                type: 'text',
                name: 'properties.version_security_patch',
                description: 'Version Security Patch', // ? add date type?
            },
            {
                operations: ["=", ">", "<", ">=", "<=", "!"],
                name: 'properties.version_release',
                description: 'Rlease Version',
            },
            {
                type: 'number',
                name: 'properties.version_sdk',
                description: 'SDK Version', // ? add date type?
            }
        ],
        canChat: true,
        canDashboard: true,
        canEmail: true,
    },
];

export default alertEventsTypes;
