import {
  Grid2
} from '@mui/material';
import NavbarNotificationsDropdown from './NavbarNotificationsDropdown';
import NavbarMessagesDropdown from './NavbarMessagesDropdown';
//import NavbarLanguagesDropdown from './NavbarLanguagesDropdown';
import NavbarUserDropdown from './NavbarUserDropdown';
import NavbarSystemNotificationGSWBilling from './NavbarSystemNotificationGSWBilling';
import NavbarSystemNotificationStripeBilling from './NavbarSystemNotificationStripeBilling';
import NavbarBetaWatermark from './NavbarBetaWatermark';
import Authorized from 'components/Authorized';

const isUsingStripe = false;

const Navbar = () => {
  return (
    <>
      <Grid2 container alignItems="center">
        <Grid2>
          {process.env.REACT_APP_BETA && <NavbarBetaWatermark />}
          {isUsingStripe ? <NavbarSystemNotificationStripeBilling />: <NavbarSystemNotificationGSWBilling />}
          <Authorized permission={"chatConversationsList.read"}><NavbarMessagesDropdown /></Authorized>
          <Authorized permission={"alert.read"}><NavbarNotificationsDropdown /></Authorized>
          {/* <NavbarLanguagesDropdown /> */}
          <NavbarUserDropdown />
        </Grid2>
      </Grid2>
    </>
  );
};

export default Navbar
