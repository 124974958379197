import React, { useRef, useState } from "react";
import { styled } from '@mui/system';
import {
    Avatar as MuiAvatar,
    Badge,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover as MuiPopover,
    SvgIcon,
    Tooltip,
    Typography,
} from "@mui/material";
import { AlertOctagon, CreditCard } from "react-feather";
import useAuth from 'hooks/useAuth';
const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: #d32f2f;
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: #d32f2f;
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({ title, description, Icon }) {
    return (
        <ListItem divider>
            <ListItemAvatar>
                <Avatar>
                    <SvgIcon fontSize="small">
                        <Icon />
                    </SvgIcon>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={title}
                primaryTypographyProps={{
                    variant: "subtitle2",
                    color: "#d32f2f",
                }}
                secondary={description}
            />
        </ListItem>
    );
}

// add suport case for cancel at
function NavbarSystemNotificationGSWBilling() {
    const { userID: currentUserID, subscription } = useAuth();
    let title = "Billing issue";
    let description = "We found some issue with your billing, please contact Support";
    const ref = useRef(null);

    const [isOpen, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    if (!currentUserID) return null;
    if (!subscription) return null;
    title = `Billing ${subscription}`;
    switch (subscription) {
        case "suspended":
            description = "We found some issue with your billing, please contact Support";
            break;
        case "active":
            return null;       
        default:
            return null;
    }
    return (
        <React.Fragment>
            <Tooltip title="Iusse">
                <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
                    <Indicator badgeContent={1} color="secondary">
                        <AlertOctagon color="#d32f2f" />
                    </Indicator>
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <NotificationHeader p={2}>
                    <Typography variant="subtitle1" color="textPrimary">
                        {1} New Issue
                    </Typography>
                </NotificationHeader>
                <React.Fragment>
                    <List disablePadding>
                        <Notification
                            title={title}
                            description={description}
                            Icon={CreditCard}
                        ></Notification>
                    </List>
                </React.Fragment>
            </Popover>
        </React.Fragment>
    );
}

export default NavbarSystemNotificationGSWBilling;
