import React, { useState } from 'react';
import {
    Grid2,
    Typography,
    Switch,
    CircularProgress
} from '@mui/material';
import { toast } from 'react-toastify';
import { http } from 'utils/http';

function SetRegistryDWORD({ ip, port, isValid, isBBIUseDashalyticsByCurlSet, legacyLads, onSuccess }) {
    const [loading, setLoading] = useState(false);
    const [bBIUseDashalyticsByCurl, setBBIUseDashalyticsByCurl] = useState(isBBIUseDashalyticsByCurlSet);
    const handleSetRegisteryDWORD = async (e) => {
        const bBIUseDashalyticsByCurl = e.target.checked;
        try {
            setLoading(true);
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/setup/set-registry-dword`,
                {
                    ip,
                    port,
                    bBIUseDashalyticsByCurl,
                    legacyLads
                });
            if (onSuccess) onSuccess();
            toast.success(resp.data.message || "updated");
            setLoading(false);
            setBBIUseDashalyticsByCurl(bBIUseDashalyticsByCurl);
        } catch (err) {
            setLoading(false);
            if (err && err.response && err.response.data && err.response.data.message) {
                return toast.error(err.response.data.message);
            }
            console.error("error", err);
            toast.error("Something went wrong");
        }
    }

    return <Grid2 size={12} container alignItems='center' justifyContent="space-between" spacing={3}>
        <Grid2>
            <Typography variant="body1">
                Log Events to Dashalytics
            </Typography>
        </Grid2>
        <Grid2>
            {loading ? (
                <CircularProgress color="primary" size={30} />
            ) :
                (
                    <Switch
                        disabled={!isValid || loading}
                        checked={bBIUseDashalyticsByCurl}
                        onChange={handleSetRegisteryDWORD}
                        color="primary"
                    />
                )
            }
        </Grid2>
    </Grid2>
}

export default SetRegistryDWORD;
