import AlertsActionTypes from "./alerts.types"
import getStoreSelections from "utils/getStoreSelections";

const newAlertAction = (alert) => {
    return async (dispatch, getState) => {
        const {
            groupDevicesMap,
            badgesIDMap
        } = getStoreSelections(getState());
        dispatch({ type: AlertsActionTypes.NEW_ALERT, payload: { ...alert, groupID: groupDevicesMap[alert.androidID], badge_id: badgesIDMap[alert.badgeID] } })
    }
}
const removeAlertAction = (alertID) => {
    return { type: AlertsActionTypes.REMOVE_ALERT, payload: alertID }
}
const updateActiveAlertsAction = (activeAlerts) => {
    return { type: AlertsActionTypes.UPDATE_ACTIVE_ALERTS, payload: activeAlerts }
}
const updateAlertEventsPeriodCountAction = ({ nEvents, periode, group, badge }) => {
    return {
        type: AlertsActionTypes.UPDATE_ALERT_PERIOD_COUNT_EVENTS,
        payload: {
            nEvents,
            periode,
            group,
            badge
        }
    }
}
const updateLast24hDevicesAlertCountAction = ({ androidID, count }) => {
    return { type: AlertsActionTypes.UPDATE_LAST_24H_DEVICE_ALERT_COUNT, payload: { androidID, count } }
}
const updateCurrentAlertsBySettingsID = ({ settingID, count }) => {
    return { type: AlertsActionTypes.UPDATE_CURRENT_ALERTS_BY_SETTINGS_ID, payload: { settingID, count } }
}
const updatePreviousAlertsBySettingsID = ({ settingID, count }) => {
    return { type: AlertsActionTypes.UPDATE_PREVIOUS_ALERTS_BY_SETTINGS_ID, payload: { settingID, count } }
}

export {
    newAlertAction,
    removeAlertAction,
    updateAlertEventsPeriodCountAction,
    updateActiveAlertsAction,
    updateLast24hDevicesAlertCountAction,
    updateCurrentAlertsBySettingsID,
    updatePreviousAlertsBySettingsID
}
