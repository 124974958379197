import React, { useState, useEffect } from "react";
import { styled } from '@mui/system';
import { Paper, Typography } from "@mui/material";
import Logo from 'components/Logo';
import { useParams } from "react-router-dom";
import Loader from 'components/Loader';
import { toast } from 'react-toastify';
import useAuth from 'hooks/useAuth';
import { logEvent } from 'utils/logs';
import logEventType from 'constants/logEventType';
const Brand = styled(Logo)`
  width: 300px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
function LaunchDemo() {
  const { loadSession } = useAuth();
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  useEffect(()=>{
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/my-account`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
      .then(res => res.json())
      .then(res => {
        if (res && res.user && res.user.email) {
          loadSession(token);
          logEvent({ type: logEventType.DEMO_LINK_CLICKED });
        }
        else {
          toast.error("Link expired", {
            toastId: 'error'
          });
          setError("The link has expired, please generate a new one");
          setLoading(false);
        }
      })
      .catch(error => {
        console.log("error", error);
  
      });
  }, [loadSession, token])
  
  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        {loading && <Typography component="h1" variant="h4" align="center" gutterBottom>
          Launching Demo...
        </Typography>}
        {error && <Typography component="h1" variant="h4" color="error" align="center" gutterBottom>
          {error}
        </Typography>}
        {loading && <Loader />}
      </Wrapper>
    </React.Fragment>
  );
}

export default LaunchDemo;
