import React, { useState, useMemo } from "react";
import { styled } from '@mui/system';
import { spacing } from "@mui/system";
import {
    DataGridPro,
    useGridApiRef,
    GridToolbar
} from "@mui/x-data-grid-pro";
import {
    Typography,
    Grid2,
    Box,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Card,
    CardContent,
} from '@mui/material';
import { Link as ReactLink } from "react-router-dom";
import {
    Button as MuiButton,
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useSelector, useDispatch } from "react-redux"
import { updateGroupsAction } from "redux/groups/groups.actions";
import { http } from 'utils/http';
import { toast } from 'react-toastify';
import usePreferences from "hooks/usePreferences";
import useAuthorization from "hooks/useAuthorization";
import ProgressBarLevel from 'components/ProgressBarLevel';

const Button = styled(MuiButton)(spacing);

const _columns = [
    {
        field: "Device Profile",
        renderCell: (params) => {
            return (
                <ReactLink
                    to={`/devices/${params.row.androidID}`}
                    style={{ textDecoration: "none" }}
                >
                    <Button variant="contained" color="primary">
                        Device Profile
                    </Button>
                </ReactLink>
            );
        },
        width: 150,
        sortable: false,
    },
    {
        field: "androidID",
        headerName: "androidID",
        width: 200,
        editable: false,
    },
    {
        field: "_id",
        headerName: "REAL_ID",
        width: 250,
        editable: false
    },
    {
        field: "name",
        headerName: "Name",
        width: 130,
        editable: false,
    },
    {
        field: "ipaddr",
        headerName: "IP Address",
        width: 130,
        editable: false
    },
    {
        field: "battery_level",
        headerName: "Battery Level",
        renderCell: (params) => <ProgressBarLevel value={params.value} unit="%" />,
        type: "number",
        width: 100,
    },
    {
        field: "gswcb_version",
        headerName: "GSW CB Version",
        width: 110,
        editable: false,
    },
    {
        field: "gswcb_config_name",
        headerName: "Config Name",
        width: 150,
        editable: false,
    },
    {
        field: "gswcb_config_download_date",
        headerName: "Config Download Date",
        width: 150,
        editable: false,
    },
    {
        field: "date",
        headerName: "Last Contact",
        width: 200,
        editable: false,
    },
    {
        field: "license_type",
        headerName: "License",
        width: 150,
        editable: false,
    },
    {
        field: "license_process",
        headerName: "License Process",
        width: 150,
        editable: false
    },
    {
        field: "license_expiration_date",
        headerName: "License Expiration Date",
        width: 150,
        editable: false,
    },
    {
        field: "license_subscription_until",
        headerName: "License Subscription End",
        width: 150,
        editable: false
    },
    {
        field: "build_brand",
        headerName: "Brand",
        width: 200,
        editable: false,
    },
    {
        field: "build_model",
        headerName: "Model",
        width: 200,
        editable: false,
    },
    {
        field: "version_release",
        headerName: "Android Version",
        width: 200,
        editable: false,
    },
    {
        field: "version_sdk",
        headerName: "SDK Version",
        width: 200,
        editable: false,
    },
    {
        field: "version_security_patch",
        headerName: "Security Patch Date",
        width: 200,
        editable: false,
    },
    {
        field: "display_density",
        headerName: "Display Density",
        width: 200,
        editable: false,
    },
    {
        field: "display_dimensions",
        headerName: "Display Dimensions",
        width: 200,
        editable: false,
    },
    {
        field: "network_mac",
        headerName: "Network Mac",
        width: 200,
        editable: false,
    },
    {
        field: "id",
        headerName: "ID",
        width: 50,
        editable: false
    },
];


const preferencesID = "pages-settings-devices-in-group-table";

const DevicesInGroup = ({ selectedGroupID, deleting, creating }) => {
    const { isAuthorized } = useAuthorization();
    const canViewDeviceDetails = isAuthorized("page.deviceDetails");
    const canMoveDevice = isAuthorized("group.moveDevice");
    const dispatch = useDispatch();
    const apiRef = useGridApiRef();
    const groups = useSelector((state) => state.groups.groups);
    const [fetching, setFetching] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const { preferences, updatePreferences } = usePreferences();
    const initialState = useMemo(() => preferences[preferencesID] || {}, [preferences]);
    const [groupID, setGroupID] = useState('-');
    const devices = useSelector((state) => state.devices);
    const handleMoveDevicesToGroup = async () => {
        setFetching(true);
        try {
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/group/group/move-device`,
                {
                    groupID: selectedGroupID === '-' ? groupID : null,
                    deviceIDs: selectionModel,
                });
            setFetching(false);
            if (resp && resp.data) {
                dispatch(updateGroupsAction(resp.data));
            }
        } catch (error) {
            console.log("error", error);
            setFetching(false);
            toast.error("Something went wrong");
        }
    }
    const columns = React.useMemo(() => {
        let error = false;
        if (initialState &&
            initialState.columns &&
            initialState.columns.cols) {
            const cols = new Array(_columns.length);
            _columns.forEach((el) => {
                if (!initialState.columns.cols[el.field]) {
                    error = true;
                    return;
                }
                cols[initialState.columns.cols[el.field].order] = { ...el, width: initialState.columns.cols[el.field].width }
            })
            if (!error) return canViewDeviceDetails ? cols : cols.filter(el => el.field !== "Device Profile");
        }
        return canViewDeviceDetails ? _columns : _columns.filter(el => el.field !== "Device Profile");
    }, [initialState, canViewDeviceDetails]);
    const formInvalid = useMemo(() => {
        return groups.length === 0 || selectionModel.length === 0 || (groupID === selectedGroupID && groupID === '-');
    }, [groups, groupID, selectionModel, selectedGroupID]);
    const deviceGroup = useMemo(() => {
        const deviceMap = {}
        if (selectedGroupID === '-') {
            devices.forEach(device => deviceMap[device.androidID] = device);
            groups.forEach(group => {
                if (group.devices) {
                    group.devices.forEach(androidID => {
                        delete deviceMap[androidID];
                    });
                }
            });
        }
        else {
            const group = groups.find(el => el._id === selectedGroupID);
            if (group) {
                group.devices.forEach(el => {
                    deviceMap[el] = {};
                })
                devices.forEach(el => {
                    if (deviceMap[el.androidID]) deviceMap[el.androidID] = el;
                })
            }
        }
        return Object.values(deviceMap).filter(el => el.androidID);
    }, [devices, groups, selectedGroupID]);

    return (
        (<Card variant="outlined">
            <CardContent>
                <Grid2 container spacing={6} alignItems="center">
                    <Grid2 size={12}>
                        <Typography variant="h6">
                            Devices in Group
                        </Typography>
                    </Grid2>
                    <Grid2 size={12} sx={{ mb: 3 }}>
                        <div style={{ height: 500, width: "100%" }}>
                            <DataGridPro
                                checkboxSelection={true}
                                apiRef={apiRef}
                                onStateChange={(state) => {
                                    updatePreferences(preferencesID, {
                                        density: state.density,
                                        sorting: {
                                            sortModel: state.sorting.sortModel,
                                        },
                                        filter: {
                                            filterModel: state.filter.filterModel
                                        },
                                        columns: {
                                            columnVisibilityModel: state.columns.columnVisibilityModel,
                                            cols: apiRef.current.getAllColumns().map(({ field, width }, index) => ({ field, order: index, width })).reduce((p, c) => ({ ...p, [c.field]: c }), {}),
                                        }
                                    })
                                }}
                                onRowSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                rowSelectionModel={selectionModel}
                                initialState={initialState}
                                rows={deviceGroup}
                                getRowId={(row) => row.androidID}
                                columns={columns}
                                slots={{
                                    toolbar: GridToolbar,
                                }}
                            />
                        </div>
                    </Grid2>
                    {selectedGroupID === '-' && canMoveDevice && <Grid2 size={{ x: 12, sm: 6 }}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="select-group-label">Move to Group</InputLabel>
                                <Select
                                    labelId="select-group-label"
                                    id="select-group"
                                    value={groupID}
                                    label="Move to Group"
                                    size="small"
                                    onChange={(e) => setGroupID(e.target.value)}
                                    disabled={deleting || creating}
                                >
                                    <MenuItem value='-' disabled>Select a group</MenuItem>
                                    {groups.map(el => <MenuItem key={el._id} value={el._id}>{el.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid2>}
                    {selectedGroupID === '-' && canMoveDevice && <Grid2 size={{ xs: 12, sm: 6 }}>
                        <LoadingButton
                            size="small"
                            endIcon={<SaveIcon />}
                            onClick={handleMoveDevicesToGroup}
                            loadingPosition="end"
                            variant="contained"
                            loading={fetching}
                            fullWidth
                            disabled={formInvalid}
                        >
                            Move to group
                        </LoadingButton>
                    </Grid2>}
                    <Grid2 size={12}>
                        {selectedGroupID !== '-' && canMoveDevice && <LoadingButton
                            size="small"
                            endIcon={<SaveIcon />}
                            onClick={handleMoveDevicesToGroup}
                            loadingPosition="end"
                            variant="contained"
                            color="error"
                            loading={fetching}
                            disabled={formInvalid}
                        >
                            Remove From Group
                        </LoadingButton>}
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>)
    );
}

export default DevicesInGroup;