import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import { spacing } from '@mui/system';
import { NavLink } from 'react-router-dom';
import {
    Link,
    Grid2,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateRolesAction, updateAdminsAction } from 'redux/admins/admins.actions';
import { getRoles, getAdmins } from 'utils/api';
import AdminsList from './AdminsList';
import InviteAdmins from './InviteAdmins';
import Authorized from 'components/Authorized'

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing)

function Admins() {
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchCalls = [
            getRoles().then(roles => dispatch(updateRolesAction(roles || []))),
            getAdmins().then(admins => dispatch(updateAdminsAction(admins || [])))
        ]
        return () => {
            fetchCalls.forEach(fetchCall => fetchCall.cancel());
        }
    }, [dispatch])

    return (
        <React.Fragment>
            <Typography variant="h3" gutterBottom display="inline">
                Administrators
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" sx={{ mt: 2 }}>
                <Link component={NavLink} to="/">
                    Settings
                </Link>
                <Typography>Administrators</Typography>
            </Breadcrumbs>
            <Divider sx={{ my: 6 }} />
            <Grid2 container spacing={6}>
                <Grid2 size={12}>
                    <Authorized permission="admin.invite">
                        <InviteAdmins />
                    </Authorized>
                </Grid2>
                <Grid2 size={12}>
                    <Authorized permission="admin.read">
                        <AdminsList />
                    </Authorized>
                </Grid2>
            </Grid2>
        </React.Fragment>
    );
}

export default Admins;
