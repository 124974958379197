import React, { useState } from 'react';
import { styled } from '@mui/system';
import { NavLink } from 'react-router-dom';
import {
    useMemo,
    useEffect
} from 'react';
import LeafletMap from 'components/LeafletMap/LeafletMap';
import DeviceMarkerInfos from 'components/LeafletMap/DeviceMarkerInfos';
import {
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography,
    FormGroup,
    FormControlLabel,
    Switch,
    Grid2,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip,
} from '@mui/material';
import { spacing } from '@mui/system';
import { useSelector, useDispatch } from 'react-redux'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjsExt from 'utils/dayjsExt';
import InfoIcon from '@mui/icons-material/Info';
import { getDevices, getGPSHistory } from 'utils/api';
import { updateDevicesAction } from 'redux/devices/devices.actions';
import { updateGPSHistoryEvents } from 'redux/events/events.actions';
import { formatTimeDate, formatTimeDateFromNow } from 'utils/timeFormat';
import Authorized from 'components/Authorized';

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const HistoryInfos = ({ date, androidID, name }) => {
    return <Box>
        {name && <Typography variant="body2" align="center" display="block"><strong>{name}</strong></Typography>}
        <Typography variant="caption" display="block">AndroidID: <strong>{androidID}</strong></Typography>
        <Typography variant="caption" display="block">{formatTimeDateFromNow(date)}</Typography>
        <Typography variant="caption" display="block">{formatTimeDate(date)}</Typography>
    </Box>
}

function Gps() {
    const [dates, setDates] = useState([dayjsExt().subtract(6, 'days').startOf('day').toDate(), new Date()]);
    const [loadedDataDates, setLoadedDataDates] = useState([null, null])
    const [enableGrouping, setEnableGrouping] = useState(true);
    const [showDevicesHistory, setShowDevicesHistory] = useState(false);
    const [showImpossibleCases, setShowImpossibleCases] = useState(false);
    const [showRadius, setShowRadius] = useState(false);
    const [pathAutoZoom, setPathAutoZoom] = useState(false);
    const allDevices = useSelector((state) => state.devices || []);
    const groups = useSelector((state) => state.groups.groups || []).sort((a, b) => b.devices.length - a.devices.length);
    const selectedGroupID = useSelector((state) => state.groups.selectedGroup);
    const [androidID, setAndroidID] = useState('-');
    const devicesMap = useMemo(() => allDevices.reduce((p, c) => ({ ...p, [c.androidID]: c }), {}), [allDevices])
    const groupDevices = useMemo(() => {
        if (selectedGroupID === null) return allDevices;
        const selectedGroup = groups.find(group => group._id === selectedGroupID);
        if (!selectedGroup) return [];
        const _devices = [];
        selectedGroup.devices.forEach(androidID => {
            if (devicesMap[androidID]) _devices.push(devicesMap[androidID]);
        })
        return _devices;
    }, [allDevices, devicesMap, groups, selectedGroupID]);
    const groupDevicesMap = useMemo(() => groupDevices.reduce((p, c) => ({ ...p, [c.androidID]: c }), {}), [groupDevices]);
    const events = useSelector((state) => state.events.gpsHistoryEvents || []);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!showDevicesHistory) return;
        const [s, e] = dates;
        if (!s || !e || isNaN(s.getTime()) || isNaN(e.getTime())) return;
        const sdate = dayjsExt(s).startOf('day').toISOString();
        const edate = dayjsExt(e).endOf('day').toISOString();
        const fetchCall = getGPSHistory({ sdate, edate, group: (selectedGroupID === '-' || androidID !== '-') ? null : selectedGroupID, androidID: androidID === '-' ? null : androidID, epsilon: 10, showImpossibleCases })
            .then(resp => {
                if (resp) {
                    if (resp.startDate && resp.endDate) {
                        if (new Date(s).getTime() !== new Date(resp.startDate).getTime() || new Date(e).getTime() !== new Date(resp.endDate).getTime()) {
                            setLoadedDataDates([new Date(resp.startDate), new Date(resp.endDate)]);
                        }
                    }
                    dispatch(updateGPSHistoryEvents(Array.isArray(resp.events) ? resp.events : []));
                }
            });
        return () => {
            fetchCall.cancel();
        }
    }, [dispatch, dates, selectedGroupID, androidID, showDevicesHistory, showImpossibleCases]);
    useEffect(() => {
        const fetchCall = getDevices({
            androidID: true,
            name: true,
            date: true,
            lat: true,
            long: true,
            radius: true,
            connect_bot_v: true,
            ipaddr: true,
            battery_level: true,
            wifi_level: true,
        })
            .then((devices) => dispatch(updateDevicesAction(devices || [])));
        return () => {
            fetchCall.cancel();
        };
    }, [dispatch]);

    const historyLocations = useMemo(() => {
        if (!showDevicesHistory) return [];
        const _history = {};
        events.forEach(event => {
            if (event.androidID && (androidID === '-' ? groupDevicesMap[event.androidID] : androidID === event.androidID) && event.lat && event.lon) {
                if (!_history[event.androidID]) _history[event.androidID] = [];
                _history[event.androidID].push([parseFloat(event.lat), parseFloat(event.lon), showRadius ? parseFloat(event.radius) : 0, <HistoryInfos {...event} name={groupDevicesMap[event.androidID] ? groupDevicesMap[event.androidID].name : ''} />]);
            }
        });
        Object.keys(_history).forEach(androidID => {
            if (groupDevicesMap[androidID] && groupDevicesMap[androidID].lat && groupDevicesMap[androidID].long) {
                _history[androidID].push([parseFloat(groupDevicesMap[androidID].lat), parseFloat(groupDevicesMap[androidID].long), showRadius ? parseFloat(groupDevicesMap[androidID].radius) : 0]);
            }
        })
        return Object.values(_history)
    }, [events, groupDevicesMap, showDevicesHistory, androidID, showRadius]);
    const infos = useMemo(() => {
        const markers = [];
        const _groupDevices = androidID === '-' ? groupDevices : groupDevices.filter(el => el.androidID === androidID);
        _groupDevices.forEach(device => {
            if (device && device.androidID && device.radius && device.lat !== undefined && device.long !== undefined) {
                const marker = {
                    lat: device.lat,
                    lon: device.long,
                    label: <DeviceMarkerInfos {...device} />,
                    radius: device.radius
                }
                markers.push(marker);
            }
        })
        return { markers }
    }, [groupDevices, androidID]);
    const handleDatePickerChange = (newValue) => {
        if (!Array.isArray(newValue)) return;
        if (!dayjsExt.isDayjs(newValue[0]) || !dayjsExt.isDayjs(newValue[1])) return;
        const epochStart = newValue[0].valueOf();
        const epochEnd = newValue[1].valueOf();
        if (isNaN(epochStart) || isNaN(epochEnd) || epochStart > epochEnd) return;
        const startYear = newValue[0].toDate().getFullYear();
        setDates([
            startYear < 2010 ? new Date("2010-01-01") : newValue[0].toDate(),
            epochEnd > Date.now() ? new Date() : newValue[1].toDate()
        ]);
    }
    const datepickerDates = useMemo(() => {
        return [
            dayjsExt(dates[0] || new Date("2010-01-01")),
            dayjsExt(dates[1] || new Date()),
        ]
    }, [dates])


    return (
        (<React.Fragment>
            <Typography variant="h3" gutterBottom display="inline">
                Outdoor Tracking
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" sx={{ mt: 2 }}>
                <Link component={NavLink} to="/">
                    Dashboard
                </Link>
                <Typography>Positioning</Typography>
                <Typography>Outdoor</Typography>
            </Breadcrumbs>
            <Divider sx={{ my: 6 }} />
            <Grid2 container columnSpacing={2} rowSpacing={6} alignItems="center">
                <Grid2>
                    <FormGroup>
                        <FormControlLabel checked={enableGrouping} onChange={(e) => setEnableGrouping(e.target.checked)} control={<Switch size="small" />} label="Grouping" />
                    </FormGroup>
                </Grid2>
                <Grid2>
                    <Authorized permission="event.read">
                        <FormGroup>
                            <FormControlLabel checked={showDevicesHistory || false} onChange={(e) => setShowDevicesHistory(e.target.checked)} control={<Switch size="small" />} label="History" />
                        </FormGroup>
                    </Authorized>
                </Grid2>
                {showDevicesHistory && <>
                    <Grid2>
                        <DateRangePicker
                            onChange={handleDatePickerChange}
                            disableFuture
                            format={"MM-DD-YYYY"}
                            formatDensity="dense"
                            minDate={dayjsExt("2010-01-01")}
                            maxDate={dayjsExt()}
                            localeText={{ start: "Start", end: 'End' }}
                            value={datepickerDates}
                            slots={{
                                fieldSeparator: () => <Box sx={{ mx: 2 }}> to </Box>
                            }}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    sx: { width: 120 }
                                }
                            }}
                        />
                    </Grid2>
                    <Grid2>
                        <FormControl sx={{ width: 180 }}>
                            <InputLabel id="device-select-label">Device</InputLabel>
                            <Select
                                labelId="device-select-label"
                                value={androidID || '-'}
                                label="Device"
                                size="small"
                                onChange={(e) => setAndroidID(e.target.value)}
                            >
                                <MenuItem value="-">All</MenuItem>
                                {groupDevices.map(device => <MenuItem key={device.androidID} value={device.androidID}>{device.name || device.androidID}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid2>
                    <Grid2>
                        <FormGroup>
                            <FormControlLabel checked={pathAutoZoom || false} onChange={(e) => setPathAutoZoom(e.target.checked)} control={<Switch size="small" />} label="Path Auto Zoom" />
                        </FormGroup>
                    </Grid2>
                    <Grid2>
                        <FormGroup>
                            <FormControlLabel checked={showRadius || false} onChange={(e) => setShowRadius(e.target.checked)} control={<Switch size="small" />} label="Radius" />
                        </FormGroup>
                    </Grid2>
                    <Grid2>
                        <FormGroup>
                            <FormControlLabel checked={showImpossibleCases || false} onChange={(e) => setShowImpossibleCases(e.target.checked)} control={<Switch size='small' />} label="Impossible cases" />
                        </FormGroup>
                    </Grid2>
                    {new Date(loadedDataDates[0]).getTime() > 0 && new Date(loadedDataDates[1]).getTime() > 0 && <Grid2>
                        <Tooltip title={`Data available from ${formatTimeDate(loadedDataDates[0])} - ${formatTimeDate(loadedDataDates[1])}`}>
                            <InfoIcon color="primary" />
                        </Tooltip>
                    </Grid2>}
                </>}
                <Grid2 size={12}>
                    <Authorized permission="device.read">
                        <div
                            style={{
                                width: '100%',
                                height: 1000,
                            }}
                        >
                            <LeafletMap
                                devices={infos.markers}
                                historyLocations={historyLocations}
                                disableGrouping={!enableGrouping}
                                boundsHistory={pathAutoZoom}
                            />
                        </div>
                    </Authorized>
                </Grid2>
            </Grid2>
        </React.Fragment>)
    );
}

export default Gps;
