import React from 'react';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import './printButton.css';
const PrintButton = ({ refPrint, onAfterPrint, onBeforeGetContent }) => {

    return <ReactToPrint
        onAfterPrint={onAfterPrint ? onAfterPrint : null}
        onBeforeGetContent={onBeforeGetContent ? onBeforeGetContent : null}
        trigger={() => {
            return refPrint ? <PrintIcon className="react-to-print-print-button" role="button" /> : <div style={{ width: 0, height: 0 }} />;
        }}
        content={() => refPrint}
    />
}
export default PrintButton;