import React from "react";
import { styled } from '@mui/system';
import {
  Button,
  Container,
  Grid2,
  Typography as MuiTypography,
  Box
} from "@mui/material";
import { spacing } from "@mui/system";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import useAuth from "hooks/useAuth";
const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled(Box)`
  padding-top: 3.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const Image = styled('img')`
  max-width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 6px 18px 0 rgba(18, 38, 63, 0.075);
  border-radius: 5px;
  transform: perspective(2000px) rotateX(25deg);
  z-index: 0;
  position: relative;
  image-rendering: optimizequality;
  image-rendering: -webkit-optimize-contrast;
  margin-bottom: -100px;
  margin-top: -35px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: -50px;
  }
`;

const ImageWrapper = styled(Box)`
  &:before {
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.02));
    bottom: 0;
    left: 0;
    position: absolute;
    content: " ";
    z-index: 1;
    display: block;
    width: 100%;
    height: 75px;
    pointer-events: none;
  }
`;


const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 2rem;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.5rem;
  }
`;


const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)} 0;
`;

const Visibility = styled(VisibilityIcon)`
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const ArrowForward = styled(ArrowForwardIcon)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Introduction = () => {
  const { userID } = useAuth();
  return (
    <Wrapper>
      <Container>
        <Grid2 container alignItems="center" justifyContent="center" spacing={4} sx={{ mb: 8 }}>
          <Grid2 size={{ xs: 12, sm: 9, md: 8, lg: 8 }}>
            <Grid2 container alignItems="center" justifyContent="center">
              <Grid2 size={12}>
                <Title variant="h1">
                  Enhancing operations with{" "}
                  <Title variant="h1" color="secondary" component="span">
                    Real-Time
                  </Title>
                  {" "}business intelligence.
                </Title>
                <Title variant="h1" color="secondary" gutterBottom>
                  Dashalytics by GSW
                </Title>
              </Grid2>
              <Grid2 size={{ xs: 12, lg: 10 }} >
                <Subtitle>
                  Dashalytics enables administrators to get a complete
                  view of operations. Improving visibility and efficiency
                  in a production environment.
                </Subtitle>
              </Grid2>
              <Grid2 size={12} />
              {!userID && <><Grid2>
                <Button
                  href="/auth/sign-up"
                  variant="contained"
                  color="secondary"
                  size="large"
                  endIcon={<ArrowForward />}
                >
                  Get Started
                </Button>
              </Grid2>
                <Grid2 size={1} />
                <Grid2>
                  <Button
                    href="https://dashalytics.app/auth/request-demo"
                    variant="outlined"
                    color="secondary"
                    size="large"
                    startIcon={<Visibility />}
                    endIcon={<ArrowForward />}
                  >
                    Live Demo
                  </Button>
                </Grid2>
              </>}
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2 container alignItems="center" justifyContent="center" spacing={4}>
          <Grid2 size={{ xs: 12, sm: 10, md: 9, lg: 9 }}>
            <ImageWrapper>
              <Image
                alt="Material App - React Admin Template"
                src={`/static/img/screenshots/productivity-dashboard.png`}
              />
            </ImageWrapper>
          </Grid2>
        </Grid2>
      </Container>
    </Wrapper>
  );
}

export default Introduction;