import {
    useEffect,
    forwardRef,
    useRef,
    useCallback,
} from "react"
import { Box } from '@mui/material';
import ChatMessage from "../ChatMessage"
import { useSelector, useDispatch } from "react-redux"
import useAuth from 'hooks/useAuth'
import { socketEmitters } from 'utils/socket';
import { styled } from '@mui/system';
import { appendConversationMessages } from 'redux/chat/chat.actions';
import { getConversationMessages } from 'utils/api';

const MessagesWrapper = styled(Box)`
    background: ${(props) => props.theme.palette.background.paper};
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
`

const ChatMessagesContainer = styled(Box)`
    background: ${(props) => props.theme.palette.background.paper};
    height: 100px;
    min-width: 0;
    min-height: 0;
`

const MessageLoader = forwardRef((props, ref) => {
    return <Box ref={ref} sx={{ width: '100%', height: 50 }}>
    </Box>
});

const PAGE_SIZE = 100;
const noChatMessages = []
const MessagesContainer = ({ _id: roomID, participants }) => {
    const dispatch = useDispatch();
    const scrollFromBottomRef = useRef(0);
    const observer = useRef(null);
    const msgLoaderRef = useRef(null);
    const fetching = useRef(false);
    // load messages here, load per page
    const { androidID: currentUserAndroidID, userID } = useAuth();
    const users = useSelector((state) => state.chat.users);
    const chatMessages = useSelector(state => state.chat.convMessages[roomID] || noChatMessages);
    const conRef = useRef(null);
    const nMessages = useRef(chatMessages.length);
    useEffect(() => {
        nMessages.current = chatMessages.length;
    }, [chatMessages])

    useEffect(() => {
        if (!IntersectionObserver) {
            getConversationMessages({ roomID }).then(messages => {
                if (messages.length) {
                    dispatch(appendConversationMessages({ roomID, messages: messages || [], offset: 0 }));
                    if (conRef.current) {
                        conRef.current.scrollTop = conRef.current.scrollHeight;
                    }
                }
                fetching.current = false;
            })
        }
    }, [dispatch, roomID]);
    const loadMessages = useCallback(async (offset) => {
        if (fetching.current) return;
        fetching.current = true;
        if (conRef.current) {
            scrollFromBottomRef.current = conRef.current.scrollHeight - conRef.current.scrollTop;
        }
        getConversationMessages({ roomID, offset, pageSize: PAGE_SIZE }).then(messages => {
            if (messages.length) {
                dispatch(appendConversationMessages({ roomID, messages: messages || [], offset }));
                if (conRef.current) {
                    conRef.current.scrollTop = conRef.current.scrollHeight - scrollFromBottomRef.current;
                }
            }
            setTimeout(() => {
                fetching.current = false;
            }, 100);
        })
    }, [roomID, dispatch]);
    useEffect(() => {
        if (observer.current && nMessages.current === 0) loadMessages(nMessages.current);
        if (conRef.current && msgLoaderRef.current && IntersectionObserver && !observer.current) {
            const intersectionObserverCallback = (entries) => {
                let isIntersecting = false;
                entries.forEach(entry => {
                    isIntersecting |= entry.isIntersecting;
                });
                if (isIntersecting) loadMessages(nMessages.current);
            }
            const options = {
                root: conRef.current,
                rootMargin: '0px',
                threshold: [0.0, 0.1, 0.2, 0.5, 0.8, 1]
            }
            observer.current = new IntersectionObserver(intersectionObserverCallback, options);
            observer.current.observe(msgLoaderRef.current);
        }
    }, [chatMessages, loadMessages]);
    useEffect(() => {
        return () => {
            if (observer.current) observer.current.disconnect();
            observer.current = null;
        }
    }, [])

    useEffect(() => {
        if (chatMessages.length === 0) return;
        setTimeout(() => {
            if (conRef.current && !fetching.current) {
                conRef.current.scrollTop = conRef.current.scrollHeight;
            }
        }, 20)
    }, [chatMessages]);
    const getUserName = (userID) => {
        const user = users.find(user => user._id === userID);
        if (user && user.displayName) return user.displayName;
        else return userID;
    };
    useEffect(() => {
        if (chatMessages &&
            chatMessages.length > 0 &&
            chatMessages[chatMessages.length - 1].from !== userID &&
            !chatMessages[chatMessages.length - 1].seenBy?.includes(userID)
        ) {
            socketEmitters.emitMarkSeen({ roomID })
        }
    }, [chatMessages, roomID, userID]);
    useEffect(() => {
        if (currentUserAndroidID &&
            navigator &&
            navigator.vibrate &&
            chatMessages.length &&
            chatMessages[chatMessages.length - 1] &&
            chatMessages[chatMessages.length - 1].from !== currentUserAndroidID) {
            const duration = 1000;
            navigator.vibrate([duration, duration, duration]);
        }
    }, [chatMessages, currentUserAndroidID]);

    return (
        <MessagesWrapper ref={conRef}>
            <ChatMessagesContainer $height={conRef.current?.clientHeight} >
                <MessageLoader ref={msgLoaderRef} fetching={fetching.current} />
                {chatMessages.map((chatMessage) => (
                    <ChatMessage
                        key={chatMessage._id}
                        recieved={chatMessage.from !== userID}
                        message={chatMessage}
                        roomID={roomID}
                        userName={getUserName(chatMessage.from)}
                        from={chatMessage.from}
                        highPriority={chatMessage.highPriority}
                        myID={userID}
                        seenBy={chatMessage.seenBy}
                        participants={participants}
                        deliveredTo={chatMessage.deliveredTo}
                    />
                ))}
            </ChatMessagesContainer>
        </MessagesWrapper>
    )
}

export default MessagesContainer