import React, { useState } from "react";
import { styled } from '@mui/system';
import * as Yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import {
  Grid2,
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "hooks/useAuth";
import { isValidEmail } from "utils/validation";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function RequestDemo() {
  const { requestDemoEmail, email } = useAuth();
  const [successMessage, setSuccessMessage] = useState('');

  return !successMessage ?
    (
      <Grid2 container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid2>
          <React.Fragment>
            <Typography component="h2" variant="h5" align="center">
              Get a live demonstration of Dashalytics
            </Typography>
            <Typography component="h2" variant="body1" align="center">
              Enter your email and we will email you the instructions
            </Typography>
            <Formik
              initialValues={{
                email: email || '',
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                  const response = await requestDemoEmail(values.email);
                  setSuccessMessage(response);
                  setStatus({ success: true });
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert sx={{ mt: 2, mb: 1 }} severity="warning">
                      {errors.submit}
                    </Alert>
                  )}
                  <TextField
                    type="email"
                    name="email"
                    label="Email Address"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={{ my: 3 }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || !isValidEmail(values.email)}
                  >
                    Send Email
                  </Button>
                  <Button
                    component={Link}
                    to="/auth/sign-in"
                    fullWidth
                    color="primary"
                  >
                    Sign In
                  </Button>
                </form>
              )}
            </Formik>

          </React.Fragment>
        </Grid2>
      </Grid2>

    ) : (
      <Grid2 container>
        <Grid2>
          <React.Fragment >
            <Box m={3}>
              <Typography component="h2" variant="body1" align="center">
                {successMessage}
              </Typography>
            </Box>
            <Button
              component={Link}
              to="/"
              fullWidth
              variant="contained"
              color="primary"
            >
              Home
            </Button>
          </React.Fragment >
        </Grid2>
      </Grid2>
    )
}


export default RequestDemo;