import React, { useState } from "react";
import {
    Button,
    Grid2,
    Grow,
    Typography
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';
import Link from 'components/Link';
import { toast } from "react-toastify";


const Cbot = () => {
    const [loading, setLoading] = useState(false);
    const [zipLink, setZipLink] = useState('');
    const [apkLink, setApkLink] = useState('');
    const handleGenerateLinks = () => {
        setLoading(true);
        const pZip = fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/dashalytics-api/download/connectbot/zip`);
        pZip.then(res => res.json())
            .then(res => {
                if (res && res.directDownloadLink) {
                    setZipLink(res.directDownloadLink);
                }
                else throw new Error("link not found");
            })
            .catch(error => {
                setLoading(false)
                console.log("error", error);
                toast.error("Something went wrong", { toastId: "download-error-message" });
            });
        const pApk = fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/dashalytics-api/download/connectbot/apk`);
        pApk.then(res => res.json())
            .then(res => {
                if (res && res.directDownloadLink) {
                    setApkLink(res.directDownloadLink);
                }
                else throw new Error("link not found");
            })
            .catch(error => {
                setLoading(false)
                console.log("error", error);
                toast.error("Something went wrong", { toastId: "download-error-message" });
            });

        Promise.all([pZip, pApk])
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
            });
    }

    return zipLink && apkLink ? (
        <Grow
            in={Boolean(zipLink && apkLink)}
            style={{ transformOrigin: '0 0 0' }}
            {...(Boolean(zipLink && apkLink) ? { timeout: 1000 } : {})}
        >
            <Grid2 container spacing={6} alignItems="center">
                <Grid2 size={6}>
                    <Typography component="h2" variant="body2">
                        GSW ConnectBot
                    </Typography>
                </Grid2>
                <Grid2 size={3}>
                    <Button
                        component={Link}
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ textAlign: 'center' }}
                        to={zipLink}
                        size="normal"
                        target="_blank"
                    >
                        ZIP
                    </Button >
                </Grid2>
                <Grid2 size={3}>
                    <Button
                        component={Link}
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ textAlign: 'center' }}
                        to={apkLink}
                        target="_blank"
                        size="normal"
                    >
                        APK
                    </Button >
                </Grid2>
            </Grid2>
        </Grow>
    )
        :
        (
            <LoadingButton
                onClick={handleGenerateLinks}
                loading={loading}
                variant="contained"
                sx={{ textAlign: 'center' }}
                fullWidth
                loadingPosition="end"
                size="normal"
                endIcon={<DownloadIcon />}
            >
                GSW ConnectBot
            </LoadingButton>
        );
};

export default Cbot;
