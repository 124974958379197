import {
    Box,
    IconButton,
    Modal
} from '@mui/material';
import { styled } from '@mui/system';
import { rgba } from 'polished';
import { useSelector } from "react-redux"
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ManageChatRooms from './ManageChatRooms';

const Wrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 5px;
    height: 100%;
`

const ModalContent = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: ${(props) => props.theme.palette.background.paper};
    border: 1px solid ${(props) => props.theme.palette.text.secondary};
    color: ${(props) => props.theme.palette.text.primary};
    box-shadow: 0px 1px 10px 1px ${props => rgba(props.theme.palette.text.secondary, 0.2)};
    padding: 10px;
    border-radius: 10px;
    width: 90vw;
    height: 90vh;
`;

const ManageRoomsModal = ({ isOpen, handleClose, roomID }) => {
    return (<Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="manage-chatrooms-modal-title"
        aria-describedby="manage-chatrooms-modal-description"
    >
        <ModalContent>
            <ManageChatRooms selectedRoomID={roomID} handleClose={handleClose} />
        </ModalContent>
    </Modal>
    );
}

const ChatRoomsManage = ({ isManageChatRoomsOpen, setIsManageChatRoomsOpen, isShowUsersMode, setIsShowUsersMode, roomID }) => {
    const chatRooms = useSelector(state => state.chat.chatRooms);
    return <Wrapper>
        <IconButton color="primary" onClick={() => setIsManageChatRoomsOpen(val => !val)}>
            <SettingsIcon />
        </IconButton>
        {isShowUsersMode ? <IconButton
            color="primary"
            disabled={chatRooms.length === 0}
            onClick={() => setIsShowUsersMode(false)}
        >
            <ChatBubbleIcon />
        </IconButton> :
            <IconButton
                color="primary"
                onClick={() => setIsShowUsersMode(true)}
            >
                <PersonIcon />
            </IconButton>}
        <ManageRoomsModal
            isOpen={isManageChatRoomsOpen}
            handleClose={() => setIsManageChatRoomsOpen(false)}
            roomID={roomID}
        />
    </Wrapper>
}

export default ChatRoomsManage;