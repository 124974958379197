import React, { useMemo } from "react";
import {
    Typography,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
} from '@mui/material';
import { useSelector } from "react-redux"

const SettingsOptions = ({ editing, fetching, type, settingID, setSettingID }) => {
    const settings = useSelector(state => state.settings || []);
    const settingsAvailable = useMemo(() => {
        return settings.filter(el => el.type === type);
    }, [settings, type]);
    const selectedSetting = useMemo(() => {
        const _setting = settingsAvailable.find(el => el._id === settingID);
        return _setting ? _setting : { name: '-' };
    }, [settingsAvailable, settingID]);
    if (editing && settingsAvailable.length === 0) return <>
    <FormControl sx={{ my: 2 }}>
        <InputLabel id="select-setting-label">Setting</InputLabel>
        <Select
            labelId="select-setting-label"
            id="select-setting"
            value={'-'}
            label="Setting"
            size="small"
            disabled
        >
            <MenuItem value={'-'}>no setting found</MenuItem>
        </Select>
    </FormControl>
    </>
    
    if (settingsAvailable.length === 0) return <>
        <Typography variant="body2" align="center">
            no setting found
        </Typography>
    </>
    if (!editing && settingsAvailable.length > 0 && !settingID) return <>
        <Typography variant="body2" align="center">
            no setting selected
        </Typography>
    </>

    return editing ? <FormControl fullWidth sx={{ my: 2 }}>
        <InputLabel id="select-setting-label">Setting</InputLabel>
        <Select
            labelId="select-setting-label"
            id="select-setting"
            value={settingID || '-'}
            label="Setting"
            size="small"
            onChange={(e) => setSettingID(e.target.value)}
            disabled={fetching || settingsAvailable.length === 0}
        >
            <MenuItem value={'-'}>Not-Set</MenuItem>
            {settingsAvailable.map(el => <MenuItem key={el._id} value={el._id}>{el.name}</MenuItem>)}
        </Select>
    </FormControl>
        : <Typography variant="body2" align="center">{selectedSetting.name}</Typography>;
}

export default SettingsOptions;