import React, { useMemo } from 'react';
import {
    Typography,
    Grid2,
    Tooltip
} from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import usePreferences from 'hooks/usePreferences';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import operations from 'constants/operations';
import alertEventsTypes from 'constants/alertEventsTypes';
import HtmlTooltip from 'components/HtmlTooltip';
import BoltIcon from '@mui/icons-material/Bolt';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

const MessageInfos = ({ enabledMessage, messageContent }) => {
    return <HtmlTooltip
        title={<Grid2 container columnSpacing={6}>
            <Grid2 size={12}>
                <Typography variant="body1">Enabled : <strong>{enabledMessage ? 'Yes' : 'No'}</strong> </Typography>
            </Grid2>
            <Grid2 size={12}>
                <Typography variant="body1">Message: <strong>{messageContent ? messageContent : '-'}</strong></Typography>
            </Grid2>
        </Grid2>}>
        {enabledMessage ? <CheckCircleIcon color="primary" /> : <CancelIcon color="error" />}
    </HtmlTooltip>
}
const alertsEventsTypeMap = alertEventsTypes.reduce((p, c) => ({ ...p, [c.id]: c }), {});
const getError = ({ eventID, property, disabled, value, dashboardEnabled, dashboardMessage, chatEnabled, chatMessage, emailEnabled, emailMessage }) => {
    if (!eventID) return "You must select an Event";
    const selectedAlertEvent = alertsEventsTypeMap[eventID];
    if (!selectedAlertEvent) return "Selected Event is no longer supported";
    if (selectedAlertEvent && selectedAlertEvent.properties && selectedAlertEvent.properties.length && !property) return "You must select a Property";
    const selectedProperty = selectedAlertEvent.properties.find(el => el.name === property);
    if (selectedProperty && selectedProperty.operations && selectedProperty.operations.length) {
        if (value === null || value === undefined) return "You must define a value";
        if (selectedProperty.type === 'text' && !value.length) return "You must define a value";
        if (selectedProperty.type === 'number' && Number.isNaN(parseFloat(value))) return "You must define a valid value";
    }
    if (dashboardEnabled && !dashboardMessage) return "You must Enter a message for Dashboard Messages";
    if (emailEnabled && !emailMessage) return "You must Enter a message for Email Messages";
    if (chatEnabled && !chatMessage) return "You must Enter a message for Chat Messages";
    if (disabled) return "Alerts are disabled";
    if (!disabled && !emailEnabled && !dashboardEnabled && !chatEnabled) return "All notifications for this event are disabled!";
    return ""
}

const getPropertyDescription = ({ event, property }) => {
    if (!event) return '';
    if (event.properties && event.properties.length === 0) return 'N/A';
    if (event.properties && event.properties.length > 0) {
        const selectedProperty = event.properties.find(el => el.name === property);
        return selectedProperty && selectedProperty.description ? selectedProperty.description : '';
    }
    return '';
}
const getValue = ({ event, property, value }) => {
    if (!event) return '';
    if (event.properties && event.properties.length === 0) return 'N/A';
    if (event.properties && event.properties.length > 0) {
        const selectedProperty = event.properties.find(el => el.name === property);
        return selectedProperty && selectedProperty.type === 'boolean' ? !!value : value;
    }
    return '';
}

const preferencesID = "pages-settings-devices-settings-alerts";

const AlertsList = ({ alerts, setSelectedAlertID, apiRef }) => {    
    const { preferences, updatePreferences } = usePreferences();
    const initialState = useMemo(() => preferences[preferencesID] || {}, [preferences]);
    const devices = useSelector((state) => state.devices || []);
    const deviceNamesMap = useMemo(() => devices.reduce((p, c) => ({ ...p, [c.androidID]: c.name }), {}), [devices]);

    const handleSelectionModelChange = () => {
        if (apiRef.current) {
            const _selectedRowsMap = apiRef.current.getSelectedRows();
            const _selectedRows = [];
            _selectedRowsMap.forEach(el => {
                _selectedRows.push(el);
            });
            if (_selectedRows.length) setSelectedAlertID(_selectedRows[0]._id);
            else setSelectedAlertID(null);
        }
    }
    const _columns = useMemo(() => {
        return [
            {
                field: "disabled",
                headerName: "",
                width: 40,
                editable: false,
                align: "center",
                renderCell: (params) => <Tooltip title={`Alert are ${params.value ? "Disabled" : "Enabled"} for this Event`}>{params.value ? <CancelIcon color="error" /> : <CheckCircleIcon color="primary" />}</Tooltip>
            },
            {
                field: "name",
                headerName: "Alert Name",
                width: 150,
                editable: false,
            },
            {
                field: "eventName",
                headerName: "Event Name",
                width: 150,
                editable: false,
                valueGetter: (value, row) => {
                    return row.eventID &&
                        alertsEventsTypeMap[row.eventID] &&
                        alertsEventsTypeMap[row.eventID].name ?
                        alertsEventsTypeMap[row.eventID].name
                        : '';
                },
            },
            {
                field: "property",
                headerName: "Property",
                width: 120,
                editable: false,
                valueGetter: (value, row) => getPropertyDescription({ event: alertsEventsTypeMap[row.eventID], property: row.property })
            },
            {
                field: "severity",
                headerName: "Severity",
                width: 80,
                editable: false,
            },
            {
                field: "highPriority",
                headerName: "Priority",
                width: 80,
                editable: false,
                align: "center",
                renderCell: (params) => params.value ? <Tooltip title="High"><BoltIcon color="error" /></Tooltip> : <Tooltip title="Normal"><HistoryToggleOffIcon /></Tooltip>,
            },
            {
                field: "androidID",
                headerName: "Device",
                width: 80,
                editable: false,
                align: "center",
                renderCell: (params) => params.value ? <Tooltip title={deviceNamesMap[params.value] || params.value}><PhoneAndroidIcon color="warning" /></Tooltip> : "All",
            },
            {
                field: "messageID",
                headerName: "Message ID",
                width: 150,
                editable: false,
                valueGetter: (value, row) => {
                    return row.eventID &&
                        alertsEventsTypeMap[row.eventID] &&
                        alertsEventsTypeMap[row.eventID].messageID ?
                        alertsEventsTypeMap[row.eventID].messageID
                        : '';
                }
            },
            {
                field: "operation",
                headerName: "operation",
                width: 150,
                valueFormatter: (value) => operations[value] || value || '',
                editable: false,
            },
            {
                field: "value",
                headerName: "Value",
                width: 80,
                valueGetter: (value, row) => getValue({ event: alertsEventsTypeMap[row.eventID], property: row.property, value }),
                align: 'center',
                editable: false,
            },
            {
                field: "dashboardEnabled",
                headerName: "Dashboard",
                renderCell: (params) => <MessageInfos enabledMessage={params.value} messageContent={params.row.dashboardMessage} />,
                width: 100,
                align: 'center',
                editable: false,
            },
            {
                field: "emailEnabled",
                headerName: "Email",
                renderCell: (params) => <MessageInfos enabledMessage={params.value} messageContent={params.row.emailMessage} />,
                width: 100,
                align: 'center',
                editable: false,
            },
            {
                field: "chatEnabled",
                headerName: "Device Message",
                renderCell: (params) => <MessageInfos enabledMessage={params.value} messageContent={params.row.chatMessage} />,
                width: 130,
                align: 'center',
                editable: false,
            },
            {
                field: "errors",
                headerName: "",
                renderCell: (params) => {
                    const error = getError(params.row);
                    return error ? <Tooltip title={error}><WarningIcon color="warning" /></Tooltip> : ''
                },
                width: 80,
                align: 'center',
                editable: false,
            }

        ];
    }, [deviceNamesMap]);
    const columns = React.useMemo(() => {
        let error = false;
        if (initialState &&
            initialState.columns &&
            initialState.columns.cols) {
            const cols = new Array(_columns.length);
            _columns.forEach((el) => {
                if (!initialState.columns.cols[el.field]) {
                    error = true;
                    return;
                }
                cols[initialState.columns.cols[el.field].order] = { ...el, width: initialState.columns.cols[el.field].width }
            })
            if (!error) return cols;
        }
        return _columns;
    }, [initialState, _columns]);
    return (
        (<div style={{ height: 400, width: "100%" }}>
            <DataGridPro
                onStateChange={(state) => {
                    updatePreferences(preferencesID, {
                        density: state.density,
                        sorting: {
                            sortModel: state.sorting.sortModel,
                        },
                        filter: {
                            filterModel: state.filter.filterModel
                        },
                        columns: {
                            columnVisibilityModel: state.columns.columnVisibilityModel,
                            cols: apiRef.current.getAllColumns().map(({ field, width }, index) => ({ field, order: index, width })).reduce((p, c) => ({ ...p, [c.field]: c }), {}),
                        }
                    })
                }}
                initialState={initialState}
                apiRef={apiRef}
                rows={alerts}
                columns={columns}
                getRowId={(row) => row._id}
                slots={{
                    toolbar: GridToolbar,
                }}
                onRowSelectionModelChange={handleSelectionModelChange}
            />
        </div>)
    );
}

export default AlertsList;