import React, { useState } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {
    Box,
    ClickAwayListener,
    useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/system';
import { rgba } from 'polished';
import { useTheme } from "@mui/material/styles";

const HtmlTooltip = styled(({
    className,
    backgroundColor,
    color,
    border,
    maxWidth,
    minWidth,
    handleTooltipClose,
    isTooltipOpen,
    tooltipDisableFocusListener,
    tooltipDisableHoverListener,
    tooltipDisableTouchListener,
    tooltipPopperProps,
    ...props }) => (
    <Tooltip
        {...props}
        onClose={handleTooltipClose}
        open={isTooltipOpen}
        disableFocusListener={tooltipDisableFocusListener}
        disableHoverListener={tooltipDisableHoverListener}
        disableTouchListener={tooltipDisableTouchListener}
        PopperProps={tooltipPopperProps}
        classes={{ popper: className }}
    />
))((props) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: props.color || props.theme.palette.text.primary,
        maxWidth: Math.max(props.minWidth || 50, props.maxWidth || 600),
        minWidth: Math.min(props.minWidth || 50, props.maxWidth || 600),
        fontSize: props.theme.typography.pxToRem(12),
        border: props.border || `1px solid ${rgba(props.theme.palette.primary.main, 0.3)}`,
        backgroundColor: props.backgroundColor || props.theme.palette.background.default,
    },
}));

const ControlledHTMLTooltip = (props) => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = (onClick) => {
        if (typeof onClick === "function") onClick();
        setOpen(true);
    };

    if (!isMdDown) return <HtmlTooltip {...props} />

    return <ClickAwayListener onClickAway={handleTooltipClose}>
        <Box onClick={handleTooltipOpen}>
            <HtmlTooltip
                {...props}
                handleTooltipClose={handleTooltipClose}
                isTooltipOpen={open}
                tooltipDisableFocusListener
                tooltipDisableHoverListener
                tooltipDisableTouchListener
            />
        </Box>
    </ClickAwayListener>
}

export default ControlledHTMLTooltip;