import React,
{
  useMemo
} from 'react';
import { styled } from '@mui/system';
import { NavLink } from 'react-router-dom';
import {
  Grid2,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  ClickAwayListener
} from '@mui/material';
import { useSelector } from 'react-redux';
import { spacing } from '@mui/system';
import {
  DataGridPro,
  useGridApiRef,
  GridToolbar
} from '@mui/x-data-grid-pro';
import { useState, useEffect } from 'react';
import { getAlerts } from 'utils/api';
import { formatTimeDate } from 'utils/timeFormat';
import usePreferences from 'hooks/usePreferences';
import Authorized from 'components/Authorized';

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const preferencesID = "pages-logs-Alertlog";

export default function AlertLog() {
  const { preferences, updatePreferences } = usePreferences();
  const initialState = useMemo(() => preferences[preferencesID] || {}, [preferences]);
  const groups = useSelector((state) => state.groups.groups);
  const groupMap = useMemo(() => {
    const _groupMap = {};
    groups.forEach(group => {
      group.devices.forEach(el => _groupMap[el] = group.name);
    })
    return _groupMap;
  }, [groups])
  const _columns = useMemo(() => {
    return [
      {
        field: "date",
        headerName: "Date",
        width: 175,
        valueFormatter: (value) => formatTimeDate(value),
        editable: false,
      },
      {
        field: "androidID",
        headerName: "AndroidID",
        width: 200,
        editable: false,
      },
      {
        field: 'group',
        headerName: "Group",
        width: 130,
        valueGetter: (value, row) => groupMap[row.androidID] || '',
        editable: false,
      },
      {
        field: "messID",
        headerName: "Message ID",
        width: 100,
        editable: false,
      },
      {
        field: "severity",
        headerName: "Severity",
        width: 80,
        editable: false,
      },
      {
        field: "description",
        headerName: "Description",
        width: 350,
        editable: false,
      },
      {
        field: "id",
        headerName: "ID",
        width: 250,
        editable: false
      },
    ];
  }, [groupMap])

  const [rows, setRows] = useState([]);
  useEffect(() => {
    const fetchCall = getAlerts({}).then(res => setRows(res));
    return () => {
      fetchCall.cancel();
    }
  }, []);

  const alerts = useMemo(() => {
    return rows.map(el => ({ ...el, group: groupMap[el.androidID] || '' }))
  }, [rows, groupMap])
  const apiRef = useGridApiRef();


  const handleClickAway = () => {
    if (apiRef.current && apiRef.current.setRowSelectionModel) {
      apiRef.current.setRowSelectionModel([])
    }
  }
  const columns = React.useMemo(() => {
    let error = false;
    if (initialState &&
      initialState.columns &&
      initialState.columns.cols) {
      const cols = new Array(_columns.length);
      _columns.forEach((el) => {
        if (!initialState.columns.cols[el.field]) {
          error = true;
          return;
        }
        cols[initialState.columns.cols[el.field].order] = { ...el, width: initialState.columns.cols[el.field].width }
      })
      if (!error) return cols;
    }
    return _columns;
  }, [initialState, _columns]);

  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        Alert Log
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" sx={{ mt: 2 }}>
        <Link component={NavLink} to="/">
          Logs
        </Link>
        <Typography>Alert Log</Typography>
      </Breadcrumbs>
      <Divider sx={{ my: 6 }} />
      <Grid2 container justifyContent="center">
        <Grid2 size={12}>
          <Authorized permission="alert.read" >
            <div style={{ height: 1200, width: "100%" }}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <DataGridPro
                  onStateChange={(state) => {
                    updatePreferences(preferencesID, {
                      density: state.density,
                      sorting: {
                        sortModel: state.sorting.sortModel,
                      },
                      filter: {
                        filterModel: state.filter.filterModel
                      },
                      columns: {
                        columnVisibilityModel: state.columns.columnVisibilityModel,
                        cols: apiRef.current.getAllColumns().map(({ field, width }, index) => ({ field, order: index, width })).reduce((p, c) => ({ ...p, [c.field]: c }), {}),
                      }
                    })
                  }}
                  initialState={initialState}
                  apiRef={apiRef}
                  rows={alerts}
                  columns={columns}
                  getRowId={(row) => row._id}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                />
              </ClickAwayListener>
            </div>
          </Authorized>
        </Grid2>
      </Grid2>
    </React.Fragment>
  );
}
