const adminPermissions = {
    page: {
        myAccount: "admin.page.myAccount",
        systemOverview: "admin.page.systemOverview",
        productivity: "admin.page.productivity",
        alerts: "admin.page.alerts",
        uts: "admin.page.uts",
        users: "admin.page.users",
        chat: "admin.page.chat",
        deviceList: "admin.page.deviceList",
        outdoorPositioning: "admin.page.outdoorPositioning",
        indoorPositioning: "admin.page.indoorPositioning",
        alertLog: "admin.page.alertLog",
        eventLog: "admin.page.eventLog",
        scanLog: "admin.page.scanLog",
        wiFiDropLog: "admin.page.wiFiDropLog",
        groupsSettings: "admin.page.groupsSettings",
        admins: "admin.page.admins",
        company: "admin.page.company",
        deviceDetails: "admin.page.deviceDetails",
        mergeDevices: "admin.page.mergeDevices",
        userDetails: "admin.page.userDetails",
        remoteManagement: "admin.page.remoteManagement",
        keyboard: "admin.page.keyboard",
        keyboardCreator: "admin.page.keyboardCreator",
        keyboardAdvanced: "admin.page.keyboardAdvanced",
        keyboardsManagement: "admin.page.keyboardsManagement",
        keyboardLadsManagement: "admin.page.keyboardLadsManagement",
        adminsPermissions: "admin.page.adminsPermissions",
        badges: "admin.page.badges",
        generateNewUUID: "admin.page.generateNewUUID",
        encryption: "admin.page.encryption",
    },
    admin: {
        read: "admin.admin.read",
        invite: "admin.admin.invite",
    },
    role: {
        read: "admin.role.read",
        create: "admin.role.create",
        update: "admin.role.update",
        delete: "admin.role.delete",
    },
    event: {
        read: "admin.event.read",
        updateIgnoreScan: "admin.event.updateIgnoreScan"
    },
    alert: {
        update: "admin.alert.update",
        read: "admin.alert.read"
    },
    chatMessage: {
        read: "admin.chatMessage.read",
        write: "admin.chatMessage.write",
    },
    chatConversationsList: {
        read: "admin.chatConversationsList.read"
    },
    chatUser: {
        read: "admin.chatUser.read",
    },
    chatRoom: {
        read: "admin.chatRoom.read",
        create: "admin.chatRoom.create",
        update: "admin.chatRoom.update",
        delete: "admin.chatRoom.delete",
    },
    permission: {
        read: "admin.permission.read",
    },
    device: {
        read: "admin.device.read",
        delete: "admin.device.delete",
        renameDevice: "admin.device.renameDevice",
        mergeDevices: "admin.device.mergeDevices",
        reloadVars: "admin.device.reloadVars",
        ping: "admin.device.ping",
        releaseLicense: "admin.device.releaseLicense"
    },
    payment: {
        readStatus: "admin.payment.readStatus",
        readRportedUsage: "admin.payment.readRportedUsage",
        createCheckout: "admin.payment.createCheckout",
        createPortal: "admin.payment.createPortal",
        readClientReferenceId: "admin.payment.readClientReferenceId",
        downloadInvoice: "admin.payment.downloadInvoice"
    },
    group: {
        read: "admin.group.read",
        moveDevice: "admin.group.moveDevice",
        create: "admin.group.create",
        update: "admin.group.update",
        delete: "admin.group.delete",
    },
    setting: {
        read: "admin.setting.read",
        create: "admin.setting.create",
        resetKeyboard: "admin.setting.resetKeyboard",
        update: "admin.setting.update",
        delete: "admin.setting.delete"
    },
    notification: {
        read: "admin.notification.read",
        markAsRead: "admin.notification.markAsRead"
    },
    keyboard: {
        read: "admin.keyboard.read",
        update: "admin.keyboard.update",
        delete: "admin.keyboard.delete",
    },
    file: {
        uploadFile: "admin.file.uploadFile",
        uploadImage: "admin.file.uploadImage"
    },
    authLog: {
        read: "admin.authLog.read",
    },
    myAccount: {
        read: "admin.myAccount.read",
        write: "admin.myAccount.write",
        requestEmailUpdate: "admin.myAccount.requestEmailUpdate",
        deleteMyAccount: "admin.myAccount.deleteMyAccount",
    },
    myCompany: {
        read: "admin.myCompany.read",
        updateInfos: "admin.myCompany.updateInfos",
    },
    badge: {
        read: "admin.badge.read",
        create: "admin.badge.create",
        delete: "admin.badge.delete",
        update: "admin.badge.update"
    },
    remoteManagement: {
        read: "admin.remoteManagement.read",
        downloadFile: "admin.remoteManagement.downloadFile",
        downloadLogFile: "admin.remoteManagement.downloadLogFile",
        uploadFile: "admin.remoteManagement.uploadFile",
        listFiles: "admin.remoteManagement.listFiles",
        pathKitting: "admin.remoteManagement.pathKitting",
        pathSDCard: "admin.remoteManagement.pathSDCard",
        createFolder: "admin.remoteManagement.createFolder",
        deleteFile: "admin.remoteManagement.deleteFile",
        viewScreen: "admin.remoteManagement.viewScreen",
        controlScreen: "admin.remoteManagement.controlScreen",
        hardwareKeys: "admin.remoteManagement.hardwareKeys",
        rebootDevice: "admin.remoteManagement.rebootDevice",
        clearProxyCache: "admin.remoteManagement.clearProxyCache"
    },
    tunnel: {
        download: "admin.tunnel.download",
        readStatus: "admin.tunnel.readStatus",
        createSecondary: "admin.tunnel.createSecondary",
    },
    lads: {
        readConfig: "admin.lads.readConfig",
        readPostEventUUID: "admin.lads.readPostEventUUID",
        writeRegistryKeyValue: "admin.lads.writeRegistryKeyValue",
        testConnection: "admin.lads.testConnection",
        restartBiThread: "admin.lads.restartBiThread",
        resetPostEventUUID: "admin.lads.resetPostEventUUID",
        uploadFile: "admin.lads.uploadFile",
        downloadFile: "admin.lads.downloadFile",
        readDirectory: "admin.lads.readDirectory",
        readRegistryKeyValue: "admin.lads.readRegistryKeyValue"
    },
};

export default adminPermissions;
