import SettingsActionTypes from "./settings.types"

const updateSettingsAction = (settings) => {
    return { type: SettingsActionTypes.UPDATE_SETTINGS, payload: settings }
}
const newSettingAction = (setting) => {
    return { type: SettingsActionTypes.NEW_SETTING, payload: setting }
}
const removeSettingAction = (settingID) => {
    return { type: SettingsActionTypes.REMOVE_SETTING, payload: settingID }
}
const updateSettingAction = (setting) => {
    return { type: SettingsActionTypes.UPDATE_SETTING, payload: setting }
}

export {
    updateSettingsAction,
    newSettingAction,
    removeSettingAction,
    updateSettingAction
}
