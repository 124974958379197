import React from "react";
import { styled } from '@mui/system';
import { CircularProgress, Box } from "@mui/material";

const Root = styled(Box)`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function Loader() {
  return (
    <Root>
      <CircularProgress color="secondary" />
    </Root>
  );
}

export default Loader;
