import SystemActionTypes from "./system.types";
const initialState = {
    notifications: [],
    authLogs: [],
    decryptionPassword: localStorage.getItem("decryption-password") || null,
}

const systemReducer = (state = initialState, action) => {
    switch (action.type) {
        case SystemActionTypes.UPDATE_AUTHENTIFICATION_LOG:
            return { ...state, authLogs: action.payload };
        case SystemActionTypes.UPDATE_SYSTEM_NOTIFICATIONS:
            return { ...state, notifications: action.payload };
        case SystemActionTypes.CLOSE_SYSTEM_NOTIFICATION:
            return { ...state, notifications: state.notifications.filter(el => el._id !== action.payload) }
        case SystemActionTypes.UPDATE_GLOBAL_DECRYPTION_PASSWORD:
            localStorage.removeItem("decryption-password");
            if(action.payload.saveInLocalStorage) localStorage.setItem("decryption-password", action.payload.password);
            return { ...state, decryptionPassword: action.payload.password }
        default:
            return state;
    }
}

export default systemReducer;
