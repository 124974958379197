import React, { useEffect } from "react";
import { styled } from '@mui/system';
import { Outlet } from "react-router-dom";
import {
  CssBaseline,
  Paper as MuiPaper,
  Box
} from "@mui/material";
import { spacing } from "@mui/system";
import { ClientChatProvider } from "contexts/ClientChatContext";
import useClientChat from "hooks/useClientChat";

const Root = styled(Box)`
  display: flex;
  min-height: 100vh;
  min-height: -webkit-fill-available;
`;

const AppContent = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;


const initialWidth = window.innerWidth;
const initialHeight = window.innerHeight;

const Keyboard = () => {
  const { updateKeyboardIsOpen } = useClientChat();
  useEffect(() => {
    window.onresize = (e) => {
      const windowWidth = e.target.innerWidth;
      const windowHeight = e.target.innerHeight;
      if (initialHeight > windowHeight && initialWidth === windowWidth) {
        updateKeyboardIsOpen(true);
      } else updateKeyboardIsOpen(false);
    }
  }, [updateKeyboardIsOpen]);

  return <></>
}

const Client = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <AppContent>
        <ClientChatProvider>
          <Keyboard />
          <MainContent>
            <Outlet />
          </MainContent>
        </ClientChatProvider>
      </AppContent>
    </Root>
  );
};

export default Client;




/// check if keyboard is open (currently have some issues)
/*
---------------------
keyboardManager: getAllAvailableTriggers setAllAvailableTriggers setTriggers
{"keyboardManager":[{"getAllAvailableTriggers":"?"},{"setAllAvailableTriggers":"?"},{"setTriggers":"?"}]}

window.keyboardManager.getAllAvailableTriggers() returned nothing

---------------------------
GSW.ConnectBot : system isEventHooked addFocusEventListners onFocusEvent onBlurEvent onTouchStartEvent onInputClickEvent hide_soft_keyboard init_keyboard

{"GSW.ConnectBot":[{"system":"false"},{"isEventHooked":"?"},{"addFocusEventListners":"?"},{"onFocusEvent":"?"},{"onBlurEvent":"?"},{"onTouchStartEvent":"?"},{"onInputClickEvent":"?"},{"hide_soft_keyboard":"?"},{"init_keyboard":"?"}]}

------------------------

device: {"device":[{"uuid":"12d12475dfc33027"}]}

--------------------
plugins: {"plugins":[{"zxingPlugin":[]},{"intentShim":[]}]}

*/