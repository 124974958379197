import React, {
    useState,
    useEffect,
    useCallback,
    useRef
} from "react";
import {
    Grid2,
    Typography,
    Alert,
    IconButton,
    CircularProgress,
    Box,
    Tooltip
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';
import CachedIcon from '@mui/icons-material/Cached';
import { toast } from 'react-toastify';
import { http } from 'utils/http';
import useAuthorization from 'hooks/useAuthorization'
import { getSecondaryTunnelStatus } from 'utils/api';


const Tunnel = ({ settingID, setIsValid }) => {
    const { isAuthorized } = useAuthorization();
    const canDownloadTunnel = isAuthorized("tunnel.download");
    const canReadTunnelStatus = isAuthorized("tunnel.readStatus");
    const isMounted = useRef(false);
    useEffect(() => {
        setIsValid(true);
    }, [setIsValid]);

    const [isTunnelConnnected, setIsTunnelConnnected] = useState(false);
    const [fetchingTunnelStatus, setFetchingTunnelStatus] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleDownloadDeviceGroupTunnel = () => {
        setLoading(true);
        http.get(`${process.env.REACT_APP_SERVER_URL}/api/v2/setup/device-group-tunnel/${settingID}`,
            { responseType: 'blob' }
        )
            .then(res => {
                setLoading(false);
                const link = document.createElement("a");
                link.download = `Tunnel-devices-${settingID.substring(18, 24)}.zip`;
                link.href = window.URL.createObjectURL(res.data);
                link.dataset.downloadurl = ["zip", link.download, link.href].join(":");
                const evt = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                });
                link.dispatchEvent(evt);
                link.remove()
            })
            .catch(error => {
                setLoading(false);
                if (error?.response?.status === 403) return toast.error("not authorized");
                if ("function" === typeof error?.response?.data?.text) {
                    error.response.data.text().then(results => {
                        const resp = JSON.parse(results);
                        toast.error(resp?.message || "Something went wrong");
                    })
                        .catch(error => {
                            console.error("error", error);
                            toast.error("Something went wrong");
                        });
                    return;
                }
                toast.error("Something went wrong");
            })
    };
    const loadTunnelStatus = useCallback(() => {
        if (!settingID || !canReadTunnelStatus) return;
        setFetchingTunnelStatus(true);
        getSecondaryTunnelStatus({ id: settingID })
            .then(resp => {
                if (!isMounted.current) return;
                setIsTunnelConnnected(!!resp.isConnected)
                setFetchingTunnelStatus(false)
            })
            .catch(error => {
                if (!isMounted.current) return;
                setIsTunnelConnnected(false);
                setFetchingTunnelStatus(false)
                console.error("get tunnel status error", error);
            })
        //.finaly(() => setFetchingTunnelStatus(false));
    }, [settingID, canReadTunnelStatus])

    useEffect(() => {
        loadTunnelStatus();
    }, [loadTunnelStatus])


    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, [])

    return <Grid2 container spacing={2} alignItems="center">
        <Grid2 sx={{ my: 5 }}>
            <Typography variant="h6" >
                Download SSH Tunnel
            </Typography>
        </Grid2>
        {canReadTunnelStatus && <Grid2>
            <Tooltip title={fetchingTunnelStatus ? "Connecting..." : `Tunnel Is${isTunnelConnnected ? " " : " Not "}connected, Click to refresh status`}>
                <Box>
                    <IconButton onClick={loadTunnelStatus} disabled={fetchingTunnelStatus} color={isTunnelConnnected ? "primary" : "error"}>
                        {fetchingTunnelStatus ? <CircularProgress size={16} /> : <CachedIcon sx={{ mb: 1 }} />}
                    </IconButton>
                </Box>
            </Tooltip>
        </Grid2>}
        <Grid2 size={12}>
            <LoadingButton
                sx={{ width: 200 }}
                size="small"
                onClick={handleDownloadDeviceGroupTunnel}
                fullWidth
                disabled={!canDownloadTunnel}
                loading={loading}
                loadingPosition="end"
                variant="contained"
                endIcon={<DownloadIcon />}
            >
                Download
            </LoadingButton>
        </Grid2>
        {!canDownloadTunnel && <Grid2 size={12}>
            <Alert severity="warning" size="small">
                You do not have permission to download tunnel.
            </Alert>
        </Grid2>}
        
        <Grid2 size={12}>
            <Alert severity="warning" size="small">
                This tunnel is for devices only, it does send or receive command from LADS
            </Alert>
        </Grid2>
        <Grid2 size={12}>
            <Alert severity="info" size="small">
                Tunnel for LADS can be downloaded and managed from Company page.
            </Alert>
        </Grid2>
    </Grid2>
}

export default Tunnel;
