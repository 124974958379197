import React, { useEffect, useMemo, useState, useRef } from "react";
import { MapContainer, TileLayer, useMap, useMapEvents, Polyline, Popup, Circle } from 'react-leaflet'
import './leafletMap.css';
import 'leaflet/dist/leaflet.css';
import { useTheme } from "@mui/material/styles";
import getTheme from "hooks/useTheme";
import MarkersGroup from "./MarkersGroup";
import DraggableCircle from './DraggableCircle';
import MapSelections from './MapSelections';
import HeatLayer from './HeatLayer';
import CustomZoomControl from "./CustomZoomControl";

const pointerColors = {
    DEFAULT: 'invert(40%) sepia(74%) saturate(451%) hue-rotate(73deg) brightness(99%) contrast(81%)',
    DARK: 'invert(40%) sepia(74%) saturate(451%) hue-rotate(73deg) brightness(99%) contrast(81%)',
    LIGHT: 'invert(40%) sepia(74%) saturate(451%) hue-rotate(73deg) brightness(99%) contrast(81%)',
    BLUE: 'invert(12%) sepia(45%) saturate(5024%) hue-rotate(216deg) brightness(91%) contrast(104%)'
}
const mapTiles = {
    DEFAULT: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    DARK: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    LIGHT: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    BLUE: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
}
const mapCSSFilter = {
    DEFAULT: 'none',
    DARK: 'hue-rotate(196deg) invert(100%)',
    LIGHT: 'none',
    BLUE: 'none'
}
// consider adding group checkbox or something
const LeafletMap = ({ devices, historyLocations, boundsHistory, showRadius, geoFencing, disableGrouping, selections, heatPoints, geoFencingEdit, zoomSlider }) => {
    const { theme: themeName } = getTheme();
    const [ready, setReady] = useState(false);
    const [currentZoom, updateCurrentZoom] = useState(10);
    const theme = useTheme();
    const pointerFilter = pointerColors[themeName] || pointerColors.DEFAULT;
    const limeOptions = { color: 'red' }
    const fillBlueOptions = { fillColor: 'blue' }
    const bounds = useMemo(() => {
        if (!devices && !selections && !heatPoints && !geoFencing) return [];
        const _bounds = [];
        if (devices) {
            devices.forEach(device => _bounds.push([device.lat, device.lon]));
        }
        if (selections) {
            selections.forEach(sel => _bounds.push([sel.lat, sel.lon]));
        }
        if (heatPoints) {
            heatPoints.forEach(point => _bounds.push([point.lat, point.lon]));
        }
        if (geoFencing) {
            _bounds.push([geoFencing.lat, geoFencing.lng]);
            _bounds.push([geoFencing.lat, geoFencing.lng + (geoFencing.radius / 1000 / (111.320 * Math.cos(geoFencing.lat * (Math.PI / 180))))]);
            _bounds.push([geoFencing.lat, geoFencing.lng - (geoFencing.radius / 1000 / (111.320 * Math.cos(geoFencing.lat * (Math.PI / 180))))]);
            _bounds.push([geoFencing.lat + (geoFencing.radius / 1000 / 110.574), geoFencing.lng]);
            _bounds.push([geoFencing.lat - (geoFencing.radius / 1000 / 110.574), geoFencing.lng]);
        }
        if (historyLocations && boundsHistory) {
            historyLocations.forEach(dev => {
                _bounds.push(...dev.map(el => [el[0], el[1]]));
            })
        }
        return _bounds;
    }, [devices, historyLocations, selections, heatPoints, geoFencing, boundsHistory]);

    return (
        <>
            <style>
                {`
                .leaflet-div-icon-target{
                    filter: ${pointerFilter};
                } 
                .leaflet-div-icon-resize{
                    filter: ${pointerFilter};
                }
                .leaflet-div-icon-select{
                    filter: ${pointerFilter};
                }
                .leaflet-div-icon-pointer{
                    filter: ${pointerFilter};
                }
                .leaflet-div-icon-device {
                    filter: ${pointerFilter};
                }
                .leaflet-div-icon-group-device {
                   filter: ${pointerFilter};
                }
                .pointer-multiple-devices-number{
                   background-color: ${theme.palette.secondary.main};
                   color: ${theme.palette.secondary.contrastText};
                }
                .leaflet-pane.leaflet-tile-pane{
                    filter: ${mapCSSFilter[themeName] || mapCSSFilter.DEFAULT};
                }
                
            `}
            </style>
            <MapContainer
                zoom={10}
                center={[33.767, -84.4]}
                attributionControl={false}
                scrollWheelZoom={false}
                className="h-100"
                whenReady={() => setReady(true)}
                zoomControl={!zoomSlider}
                minZoom={3}
                maxZoom={18}
            >
                {zoomSlider && <CustomZoomControl zoom={currentZoom} />}
                {bounds.length > 0 ? <MapContent bounds={bounds} updateCurrentZoom={updateCurrentZoom} /> : null}
                <MapZoomObserver updateCurrentZoom={updateCurrentZoom} />
                {(ready && heatPoints && heatPoints.length > 0) && <HeatLayer heatPoints={heatPoints} zoom={currentZoom} />}
                <TileLayer
                    attribution=''
                    url={mapTiles[themeName] || mapTiles.DEFAULT}
                />
                {geoFencing && <DraggableCircle geoFencing={geoFencing} geoFencingEdit={geoFencingEdit} />}
                {selections && selections.length > 0 && <MapSelections selections={selections} />}
                <MarkersGroup devices={devices || []} showRadius={showRadius} disableGrouping={disableGrouping} zoom={currentZoom} />
                {historyLocations && <Polyline pathOptions={limeOptions} positions={historyLocations.map(d => d.map(el => [el[0], el[1]]))} />}
                {historyLocations && historyLocations
                    .map((device, indexDevice) => device
                        .map((loc, indexLocation) => <Circle
                            key={`${indexDevice}-${indexLocation}`}
                            center={[loc[0], loc[1]]}
                            radius={loc[2] || 0}
                            pathOptions={fillBlueOptions}
                        >
                            {loc[3] && <Popup>{loc[3]}</Popup>}
                        </Circle>
                        )
                    )
                }
            </MapContainer>
        </>
    )
}
const MapContent = ({ bounds, updateCurrentZoom }) => {
    const boundsLength = useRef(0);
    const map = useMap();
    const [mapReady, setMapReady] = useState(false);
    useMapEvents({
        click: () => {
            setMapReady(true);
        },
        zoomend: () => {
            setMapReady(true);
        },
        drag: () => {
            setMapReady(true);
        },
        dblclick: () => {
            setMapReady(true);
        },
        contextmenu: () => {
            setMapReady(true);
        }
    })
    useEffect(() => {
        if (map && bounds.length > 0 && !mapReady) {
            map.fitBounds(bounds, { padding: [10, 10] });
        }
        // reset bounds if a new device apear (this could elimniate the need for mapReady)
        if (map && bounds.length > 0 && boundsLength.current !== bounds.length) {
            boundsLength.current = bounds.length;
            map.fitBounds(bounds, { padding: [10, 10] });
            updateCurrentZoom(map.getZoom());
        }
    }, [map, bounds, mapReady, updateCurrentZoom]);
    return null;
};
const MapZoomObserver = ({ updateCurrentZoom }) => {
    const map = useMap();
    useMapEvents({
        zoomend: () => {
            updateCurrentZoom(map.getZoom());
        },
    })
    return null;
}
export default LeafletMap;