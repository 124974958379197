import React, { useEffect, useMemo } from 'react';
import { DataGridPro, useGridApiRef, GridToolbar } from '@mui/x-data-grid-pro';
import {
    ClickAwayListener,
    Card,
    CardHeader,
    CardContent,
    Tooltip,
    Chip
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import InfoIcon from '@mui/icons-material/Info';
import HtmlTooltip from 'components/HtmlTooltip';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import BadgeIcon from '@mui/icons-material/Badge';
import PasswordIcon from '@mui/icons-material/Password';
import WarningIcon from '@mui/icons-material/Warning';
import RenderObject from 'components/RenderObject';
import { updateUsersBadgesEventsAction } from 'redux/events/events.actions';
import { getUsersBadgeEvents } from 'utils/api';
import { formatTimeDate } from 'utils/timeFormat';
import codes from 'constants/codes';

const EventType = ({ messageID }) => {
    switch (messageID) {
        case codes.USER_BADGE_SCAN:
            return <Tooltip title={`User Badge Scan`}>
                <Chip size="small" color="primary" icon={<QrCodeScannerIcon />} label={<BadgeIcon fontSize="12px" />} />
            </Tooltip>
        case codes.USER_BADGE_KEYBOARD:
            return <Tooltip title={`User Badge Keyboard`}>
                <Chip size="small" color="primary" icon={<KeyboardIcon />} label={<BadgeIcon fontSize="12px" />} />
            </Tooltip>
        case codes.USER_BADGE_UNASSIGN:
            return <Tooltip title={`User Badge Unassign`}>
                <Chip size="small" color="warning" icon={<KeyboardIcon />} label={<BadgeIcon fontSize="12px" />} />
            </Tooltip>
        case codes.USER_BADGE_PASSWORD_SCAN:
            return <Tooltip title={`User Badge Password Scan`}>
                <Chip size="small" color="primary" icon={<QrCodeScannerIcon />} label={<PasswordIcon fontSize="12px" />} />
            </Tooltip>
        case codes.USER_BADGE_PASSWORD_KEYBOARD:
            return <Tooltip title={`User Badge Password Keyboard`}>
                <Chip size="small" color="primary" icon={<KeyboardIcon />} label={<PasswordIcon fontSize="12px" />} />
            </Tooltip>

        default:
            return <Tooltip title={`Unknown Event Type ${messageID}`}><WarningIcon color="warning" /></Tooltip>
    }
}


function UsersBadgesEvents() {
    const badges = useSelector(state => state.badges.badges);
    const groups = useSelector(state => state.groups.groups);
    const devices = useSelector(state => state.devices);
    const devicesMap = useMemo(() => devices.reduce((a, c) => ({ ...a, [c.androidID]: c }), {}), [devices]);
    const badgesNameMap = useMemo(() => badges.reduce((a, c) => ({ ...a, [c.badgeID]: c.userName || '-' }), {}), [badges])
    const groupsDevicesMap = useMemo(() => groups.reduce((a, c) => ({ ...a, ...c.devices.reduce((a1, c1) => ({ ...a1, [c1]: c.name || c._id }), {}) }), {}), [groups]);
    const userBadgeEvents = useSelector(state => state.events.userBadgeEvents);
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchCall = getUsersBadgeEvents().then(events => dispatch(updateUsersBadgesEventsAction(events)))
        return () => {
            fetchCall.cancel();
        };
    }, [dispatch]);
    const apiRef = useGridApiRef();
    const handleClickAway = () => {
        if (apiRef.current && apiRef.current.setRowSelectionModel) {
            apiRef.current.setRowSelectionModel([])
        }
    }
    const columns = [
        {
            field: "_id",
            headerName: "ID",
            width: 250,
            editable: false,
        },
        {
            field: "date",
            headerName: "Date",
            width: 175,
            valueFormatter: (value) => formatTimeDate(value),
            editable: false,
        },
        {
            field: "ipaddr",
            headerName: "IP Address",
            width: 200,
            editable: false,
        },
        {
            field: 'badgeID',
            headerName: "badgeID",
            width: 130,
            editable: false,
            valueGetter: (value, row) => row.badgeID || row.properties?.badgeID
        },
        {
            field: 'userName',
            headerName: "User",
            width: 130,
            editable: false,
            valueGetter: (value, row) => badgesNameMap[(row.badgeID || row.properties?.badgeID)],
        },
        {
            field: 'group',
            headerName: "Device Group",
            width: 130,
            valueGetter: (value, row) => groupsDevicesMap[row.androidID] || '',
            editable: false,
        },
        {
            field: "androidID",
            headerName: "Device ID",
            width: 150,
            editable: false
        },
        {
            field: "deviceName",
            headerName: "Device",
            width: 150,
            editable: false,
            valueGetter: (value, row) => devicesMap[row.androidID].name || row.androidID || "N/A",
        },
        {
            field: "messageID",
            headerName: "Type",
            width: 80,
            editable: false,
            align: "center",
            renderCell: (params) => <EventType messageID={params.value} />
        },
        {
            field: "properties",
            headerName: "Details",
            width: 80,
            align: "center",
            editable: false,
            renderCell: (params) => params.value ? (
                <HtmlTooltip
                    name="Details"
                    title={<RenderObject object={params.value} />}
                >
                    <InfoIcon color="primary" />
                </HtmlTooltip>
            )
                :
                '',
        }
    ];
    return (
        <Card>
            <CardHeader title="Badge Events" />
            <CardContent>
                <div style={{ height: 400, width: "100%" }}>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <DataGridPro
                            initialState={{
                                density: "compact",
                                columns: {
                                    columnVisibilityModel: {
                                        _id: false,
                                        ipaddr: false,
                                        androidID: false
                                    }
                                },
                                sorting: {
                                    sortModel: [{ field: 'date', sort: 'desc' }],
                                },
                            }}
                            apiRef={apiRef}
                            rows={userBadgeEvents}
                            getRowId={(row) => row._id}
                            columns={columns}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                        />
                    </ClickAwayListener>
                </div>
            </CardContent>
        </Card>
    )
};

export default UsersBadgesEvents;
