import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import {
    Card as MuiCard,
    CardContent,
    Button as MuiButton,
    Grid2,
    TextField as MuiTextField,
    Typography,
    Checkbox,
    FormControlLabel,
    Box
} from "@mui/material";
import { http } from "utils/http";
import { spacing } from "@mui/system";
import { toast } from "react-toastify";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
// used in case of issue with scanpshot (play back events)
const ResetSnapshots = () => {
    const [resetConfirmation, setResetConfirmation] = useState(false);
    const [password, setPassword] = useState('');
    const [agreed, setAgreed] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [resetScan, setResetScan] = useState(false);
    const [resetDevices, setResetDevices] = useState(false);
    const [resetAlarms, setResetAlarms] = useState(false);
    const [message, setMessage] = useState('');

    const [error, setError] = useState('');
    const handleCancelDelete = () => {
        setAgreed(false);
        setPassword('');
        setResetConfirmation(false);
        setError('');
        setResetScan(false);
        setResetDevices(false);
        setResetAlarms(false);
    }
    const handleCheckResetAll = (e) => {
        const checked = e.target.checked;
        setResetScan(checked);
        setResetDevices(checked);
        setResetAlarms(checked);
    }
    useEffect(() => {
        setAgreed(false);
    }, [resetScan, resetDevices, resetAlarms])
    const handleResetSnapshots = async () => {
        setError('');
        setFetching(true);
        try {
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/reset-cache`, {
                password,
                resetScan,
                resetDevices,
                resetAlarms,
            })
            setFetching(false);
            if (resp.data && resp.data.success) {
                setMessage("Success!");
                toast.info("Success!");
                setAgreed(false);
                setPassword('');
                setError('');
                setResetScan(false);
                setResetDevices(false);
                setResetAlarms(false);
            }
        } catch (err) {
            // Handle Error Here
            setFetching(false);
            if (err && err.response && err.response.data && err.response.data.message) {
                toast.error(err.response.data.message);
                return setError(err.response.data.message);
            }
            console.error("error", err);
            toast.error("Something went wrong");
            setError("Something went wrong");
        }
    }
    return <Card sx={{ mb: 6 }}>
        <CardContent>
            <Typography variant="h6" gutterBottom>
                Reset Database
            </Typography>
            <Typography variant="body2" gutterBottom>
                Use this in case of inconsistency of scans, devices, power failure...
            </Typography>
            <Grid2 container spacing={6}>
                {!resetConfirmation ?
                    (
                        <Grid2 size={12}>
                            <Button onClick={() => setResetConfirmation(true)} variant="contained" color="error">
                                Show more
                            </Button>
                        </Grid2>
                    ) :
                    (
                        <React.Fragment>
                            <Grid2 size={12} sx={{ mt: 3 }}>
                                <Typography variant="body" gutterBottom>
                                    Use this only if you think that there is inconsistency (you may lose some data doing this)
                                </Typography>
                            </Grid2>
                            {message && <Grid2 size={12}>
                                <Typography variant="body" gutterBottom color="#0d6efd">
                                    {message}
                                </Typography>
                            </Grid2>}
                            <Box sx={{ mx: 8, width: '100%' }}>
                                <Grid2 container>
                                    <Grid2 size={12}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={handleCheckResetAll} checked={resetScan && resetDevices && resetAlarms} color="error" />}
                                            label="Reset All"
                                            sx={{ color: '#d32f2f' }}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={(e) => setResetScan(e.target.checked)} checked={resetScan} color="primary" />}
                                            label="Reset Scan Events cache"
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={(e) => setResetDevices(e.target.checked)} checked={resetDevices} color="primary" />}
                                            label="Reset Devices cache"
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={(e) => setResetAlarms(e.target.checked)} disabled={true} checked={resetAlarms} color="primary" />}
                                            label="Reset Alarms cache "
                                        />
                                    </Grid2>
                                </Grid2>
                            </Box>
                            <Grid2 size={4}>
                                <TextField
                                    label="Type your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <FormControlLabel
                                    control={<Checkbox onChange={(e) => setAgreed(e.target.checked)} checked={agreed} color="primary" />}
                                    label="I understand and want to reset selected fields"
                                />
                                <Button onClick={handleResetSnapshots} sx={{ mx: 1 }} disabled={!agreed || password.length < 6 || fetching || !(resetScan || resetDevices || resetAlarms)} variant="contained" color="error">
                                    Yes
                                </Button>
                                <Button sx={{ mx: 1 }} onClick={handleCancelDelete} variant="contained" color="primary">
                                    No
                                </Button>
                            </Grid2>
                            <Grid2 size={12}>
                                {error && <Typography variant="body" color="error" gutterBottom>
                                    {error}
                                </Typography>}
                            </Grid2>
                        </React.Fragment>
                    )}
            </Grid2>
        </CardContent>
    </Card >
}
export default ResetSnapshots;