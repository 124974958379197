import { Grid2, Typography } from '@mui/material'
import { orange, lightBlue, lightGreen, brown } from "@mui/material/colors";
const getName = (name) => {
    if (typeof name !== "string") return '';
    if (name.length === 0) return;
    const letters = name.split('');
    const output = [];
    output.push(letters[0].toUpperCase());
    for (let i = 1; i < letters.length; i++) {
        const upperCase = letters[i].toUpperCase();
        const number = `${parseInt(letters[i])}`
        switch (letters[i]) {
            case '_':
            case '$': {
                output.push(' ');
                if (letters[i + 1] !== undefined) {
                    output.push(letters[i + 1].toUpperCase());
                }
                break;
            }
            case number: {
                output.push(letters[i]);
                break;
            }
            case upperCase: {
                if(`${letters[i-1]}` !== `${letters[i-1]}`.toUpperCase()) output.push(' ');
                output.push(letters[i]);
                break;
            }
            
            default:
                output.push(letters[i]);
        }

    }    
    return output.join('').replace(/\s+/g, ' ');

}
const colors = {
    string: orange[900],
    boolean: lightBlue[900],
    number: brown[500],
    object: lightGreen[700],
    notype: lightBlue[900],
};
const renderProperty = ({ value, name, type, index }) => {
    //console.log("key", `${name}-${index}`);
    const color = colors[type];
    return <Grid2 size={12} key={`${name}-${index}`}>
        <Typography variant="body2">{getName(name)}: <strong style={color ? { color } : {}}>{value}</strong></Typography>
    </Grid2>
}

const renderObject = ({ object, name, index }) => {
    if (!object) return null;    
    const keys = Object.keys(object);
    return <Grid2 size={12} key={`${JSON.stringify(object)}-${index}`} container sx={{ pl: 1, mt: 1 }}>
        {typeof name === "string" && <Typography variant="body2" sx={{ ml: -1 }}>{getName(name)}:&#x2935;</Typography>}
        {keys.map((key, index) => {
            if (typeof object[key] === "string" || typeof object[key] === "number") return renderProperty({ name: key, value: object[key], type: typeof object[key], index });
            if (typeof object[key] === "boolean") return renderProperty({ name: key, value: object[key] ? 'true' : 'false', type: 'boolean', index });
            if (object[key] === null || object[key] === undefined) return renderProperty({ name: key, value: object[key] === null ? 'null' : 'undefined', type: 'notype', index });
            if (typeof object[key] === "object") return renderObject({ name: key, object: object[key], index });
            return renderProperty({ name: key, value: '?' });
        })}
    </Grid2>
}
const RenderObject = ({ object, name }) => {
    // avoid Cyclic dependency
    if (!object) return "No Data";
    if (typeof object !== "object") return "No Data";
    try {
        JSON.stringify(object)
    } catch (error) {
        return "Error: Object contains Cyclic dependency";
    }
    const _object = structuredClone(object);
    return renderObject({ object: _object, name });
}
export default RenderObject;