const AdminsActionTypes = {
    UPDATE_ADMINS_ACTION: "UPDATE_ADMINS_ACTION",
    UPDATE_ADMIN_ACTION: "UPDATE_ADMIN_ACTION",
    DELETE_ADMIN_ACTION: "DELETE_ADMIN_ACTION",
    UPDATE_ROLES_ACTION: "UPDATE_ROLES_ACTION",
    UPDATE_ROLE_ACTION: "UPDATE_ROLE_ACTION",
    DELETE_ROLE_ACTION: "DELETE_ROLE_ACTION",
    UPDATE_PERMISSIONS_ACTION: "UPDATE_PERMISSIONS_ACTION"
}

export default AdminsActionTypes