import { useState, useMemo } from "react";
import {
    Grid2,
    TextField,
    IconButton,
    Card,
    CardContent,
    CardHeader,
    Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { updateBadgeAction } from 'redux/badges/badges.actions';
import { http } from 'utils/http'
import { toast } from 'react-toastify';
import BadgeValid from './BadgeValid';
import settingType from "constants/settingType";

const CreateBadge = ({ isCreating, handleUpdateIsCreating }) => {
    const [fetching, setFetching] = useState(false);
    const [badgeID, setBadgeID] = useState('');
    const [userName, setUserName] = useState('');
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const badgesSettings = useMemo(() => settings.filter(el => el.type === settingType.BADGE), [settings]);

    const handleCreateBadge = async () => {
        setFetching(true);
        try {
            const results = await http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/badge/badge`, {
                badgeID,
                userName
            });
            if (!results?.data?._id) throw new Error("Can't create badge");
            setFetching(false);
            setBadgeID('');
            setUserName('');
            handleUpdateIsCreating(false);
            dispatch(updateBadgeAction(results.data));
            toast.success("Saved");
        } catch (error) {
            setFetching(false);
            toast.error(error?.response?.data?.message || error?.message || "Something went wrong");
            console.log("error", error);
        }
    }
    const handleCancelCreating = () => {
        setBadgeID('');
        setUserName('');
        handleUpdateIsCreating(false);
    }
    const isValidForm = useMemo(() => badgeID && userName, [badgeID, userName]);

    return isCreating ? <Card>
        <CardHeader
            title={<Typography variant="h6">ID: <strong>{badgeID}</strong></Typography>}
            action={
                <IconButton onClick={handleCancelCreating}>
                    <CancelIcon />
                </IconButton>
            }
        />

        <CardContent>
            <Grid2 container spacing={6} alignItems="center">
                <Grid2>
                    <TextField
                        label="BadgeID"
                        value={badgeID || ''}
                        onChange={(e) => setBadgeID(e.target.value)}
                        variant="outlined"
                        size="small"
                        placeholder='abcdefg'
                        required
                        sx={{ width: 250 }}
                    />
                </Grid2>
                <Grid2>
                    <TextField
                        label="User Name"
                        value={userName || ''}
                        onChange={(e) => setUserName(e.target.value)}
                        variant="outlined"
                        size="small"
                        placeholder='John ...'
                        required
                        sx={{ width: 250 }}
                    />
                </Grid2>
                <Grid2>
                    <LoadingButton
                        loading={fetching}
                        loadingPosition="end"
                        endIcon={<SaveIcon />}
                        variant="contained"
                        size="small"
                        disabled={!isValidForm}
                        onClick={handleCreateBadge}
                    >
                        Create
                    </LoadingButton>
                </Grid2>
                {badgeID?.length > 0 && <Grid2>
                    <BadgeValid badgeID={badgeID} badgesSettings={badgesSettings} />
                </Grid2>}
            </Grid2>
        </CardContent>
    </Card> : <Grid2 container>
        <Grid2>
            <LoadingButton
                loadingPosition="end"
                endIcon={<AddCircleIcon />}
                variant="contained"
                size="small"
                onClick={() => handleUpdateIsCreating(true)}
            >
                Add New Badge
            </LoadingButton>
        </Grid2>
    </Grid2>
}
export default CreateBadge;