import React from 'react'
import {
  Card as MuiCard,
  CardContent,
  Grid2,
  Typography,
  Box
} from '@mui/material';
import { spacing, styled } from '@mui/system';
import { green, grey, indigo } from '@mui/material/colors';
import THEMES from 'constants/themes';
import useTheme from 'hooks/useTheme';

const Card = styled(MuiCard)(spacing);
const DemoButton = styled(Box)`
  cursor: pointer;
  background: ${(props) => props.theme.palette.background.paper};
  height: 80px;
  border-radius: 0.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.825rem;
  position: relative;
  border: 1px solid
    ${(props) =>
    !props.$active
      ? props.theme.palette.action.selected
      : props.theme.palette.action.active};
`;

const getBackground = (selectedTheme) => {
  switch (selectedTheme) {
    case THEMES.DEFAULT:
      return `linear-gradient(-45deg, ${green[500]} 50%, ${grey[100]} 0)`;
    case THEMES.DARK:
      return `#23303f`;
    case THEMES.LIGHT:
      return `${grey[100]}`;
    case THEMES.BLUE:
      return `linear-gradient(-45deg, #022975 50%, ${grey[100]} 0)`;
    case THEMES.GREEN:
      return `linear-gradient(-45deg, ${green[500]} 50%, ${grey[100]} 0)`;
    case THEMES.INDIGO:
      return `linear-gradient(-45deg, ${indigo[500]} 50%, ${grey[100]} 0)`;
    default:
      return "";
  }
}

const DemoButtonInner = styled(Box)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px ${(props) => props.theme.palette.action.selected};
  position: relative;
  background: ${props => getBackground(props.$selectedTheme)};
`;

const DemoTitle = styled(Typography)`
  text-align: center;
`;


function Demo({ title, themeVariant }) {
  const { theme, setTheme } = useTheme();
  return (
    <Grid2 size={6}>
      <DemoButton
        $active={themeVariant === theme}
        onClick={() => setTheme(themeVariant)}
      >
        <DemoButtonInner $selectedTheme={themeVariant} />
      </DemoButton>
      <DemoTitle variant="subtitle2" gutterBottom>
        {title}
      </DemoTitle>
    </Grid2>
  );
}


function ThemeSelect() {
  return (
    <Card sx={{ mb: 6 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
          Select your style below. Choose the ones that best fits your needs.
        </Typography>
        <Grid2 container spacing={3}>
          <Demo title="Dark" themeVariant={THEMES.DARK} />
          <Demo title="Light" themeVariant={THEMES.LIGHT} />
          <Demo title="Default" themeVariant={THEMES.DEFAULT} />
          <Demo title="GSW Blue" themeVariant={THEMES.BLUE} />
        </Grid2>
      </CardContent>
    </Card >
  );
}
export default ThemeSelect;