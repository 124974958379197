import React from "react";
import AppBar from "./AppBar";
import Introduction from "./Introduction";
import Demos from "./Demos";
// import Testimonial from "./Testimonial";
// import Integrations from "./Integrations";
import Features from "./Features";
import FAQ from "./FAQ";
import JoinUs from "./JoinUs";
import LandingPageTheme from "./LandingPageTheme";

function Presentation() {
  return (
    <LandingPageTheme>
      <AppBar />
      <Introduction />
      <Demos />
      {/* <Testimonial />
      <Integrations /> */}
      <Features />
      <FAQ />
      <JoinUs />
    </LandingPageTheme>
  );
}

export default Presentation;
