import {
    useEffect,
    useMemo,
    useRef,
    useCallback
} from 'react';
import { styled } from '@mui/system';
import { hsla } from 'polished';
import { RTT_HEATMAP_ZONE_SIZE_IN_MM } from 'constants/rtt';

const getFillColor = (level, opacity = 1) => {
    const intensity = level / 4;
    const hue = parseInt(120 * Math.min(Math.max(0, intensity || 0), 1));
    return hsla(hue, 1, 0.5, opacity)
}

const Canvas = styled('canvas')`
    position: absolute;
    z-index: 1002;
    filter: blur(${props => props.$enableBlur ? Math.max(Math.round(props.$zoneSize), 2) : 0}px);
    opacity: ${props => `${props.$opacity}%`};
`

const HeatLayer = ({ wifiSignalHeatmap, maxMapSizeMM, mapWidth, mapHeight, mapOptions }) => {
    const { showWiFiLevels, opacity } = mapOptions;
    const maxMapSizePx = useMemo(() => Math.round(Math.max(mapWidth, mapHeight)), [mapWidth, mapHeight]);
    const ref = useRef(null);
    const numberOfzones = useMemo(() => Math.ceil(maxMapSizeMM / RTT_HEATMAP_ZONE_SIZE_IN_MM), [maxMapSizeMM]);
    const zoneSize = useMemo(() => Math.round(maxMapSizePx / numberOfzones), [maxMapSizePx, numberOfzones])
    const render = useCallback(() => {
        if (ref.current) {
            if (!zoneSize) return;
            const canvas = ref.current;
            const ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            const lineWidth = 1;
            const fontSize = 12;
            ctx.textAlign = "center";
            ctx.textBaseline = 'middle';
            ctx.font = `${fontSize}px sans-serif`;
            wifiSignalHeatmap.forEach(zone => {
                const rectColor = "back";
                const textColor = "black";
                const fillColor = getFillColor(zone.level);
                if (showWiFiLevels) {
                    ctx.fillStyle = fillColor;
                    ctx.fillRect(zone.x - zoneSize / 2, zone.y - zoneSize / 2, zoneSize, zoneSize);
                    ctx.strokeStyle = rectColor;
                    ctx.lineWidth = lineWidth;
                    ctx.beginPath();
                    ctx.rect(zone.x - zoneSize / 2 + lineWidth / 2, zone.y - zoneSize / 2 + lineWidth / 2, zoneSize - lineWidth, zoneSize - lineWidth);
                    ctx.stroke();
                    ctx.fillStyle = textColor;
                    ctx.fillText(parseInt(zone.level || 0), zone.x, zone.y);

                } else {
                    ctx.fillStyle = fillColor;
                    ctx.fillRect(zone.x - zoneSize / 2, zone.y - zoneSize / 2, zoneSize, zoneSize);
                }


            })

        }
    }, [wifiSignalHeatmap, zoneSize, showWiFiLevels]);
    useEffect(() => {
        if (!ref.current) return;
        render();
    }, [render])
    return <Canvas
        ref={ref}
        $zoneSize={zoneSize}
        $opacity={opacity}
        $enableBlur={!showWiFiLevels}
        width={mapWidth}
        height={mapHeight}
    />


}
export default HeatLayer;