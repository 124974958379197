import React, { useState } from 'react';
import { styled } from '@mui/system';
import {
    Card as MuiCard,
    CardContent,
    Grid2,
    Typography,
    Switch
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { spacing } from "@mui/system";
import { toast } from "react-toastify";
import useAuth from "hooks/useAuth";
const Card = styled(MuiCard)(spacing);
const Support = () => {
    const { allowSupportLogon, updateUser } = useAuth();
    const [fetching, setFetching] = useState(false);
    const handleUpdateAllowSupportLogon = async (isAllowSupportEnabled) => {
        console.log("")
        try {
            setFetching(true);
            const results = await updateUser({ allowSupportLogon: isAllowSupportEnabled });
            setFetching(false);
            if (results && results.message) toast.success(results.message);
        } catch (error) {
            setFetching(false);
            if (error && error.message) toast.error(error.message);
            else toast.error("Something went wrong");
        }
    }
    return <Card sx={{ mb: 6 }}>
        <CardContent>
            <Grid2 container spacing={6}>
                <Grid2 size={12}>
                    <Typography variant="h6" gutterBottom>
                        Support
                    </Typography>
                </Grid2>
                <Grid2 container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid2>
                        <Switch disabled={fetching} checked={!!allowSupportLogon} onChange={(e) => handleUpdateAllowSupportLogon(e.target.checked)} />
                    </Grid2>
                    <Grid2>
                        <Typography variant="body1">
                            Allow Support Logon
                        </Typography>
                    </Grid2>
                    <Grid2>
                        {fetching && <CircularProgress color="secondary" size={18} />}
                    </Grid2>
                    <Grid2 flexGrow="1" />
                </Grid2>
            </Grid2>
        </CardContent>
    </Card >
}
export default Support;