import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import useAuth from "hooks/useAuth";
import { useSelector } from "react-redux";
import usePageVisibility from 'hooks/usePageVisibility';

const getPageTitle = (path) => {
    if (path === '/') return "System Overview";
    if (path === '/productivity') return "Productivity Dashboard";
    if (path === '/alerts') return "Alerts Dashboard";
    if (path === '/uts') return "UTS Dashboard";
    if (path === '/pages/gps') return "Outdoor Tracking";
    if (path === '/map-canvas') return "Indoor Tracking";
    if (path === '/pages/map') return "Indoor Tracking";
    if (path === '/pages/debug') return "Debug";
    if (/^\/merge-devices/.test(path)) return "Merge Devices";
    if (path === '/logs/alertlog') return "Alert Log";
    if (path === '/logs/eventlog') return "Event Log";
    if (path === '/logs/scanlog') return "Scan Log";
    if (path === '/logs/wifidroplog') return "Wi-Fi Drop Log";
    if (path === '/settings/devices') return "Devices";
    if (path === '/settings/admins') return "Administrators";
    if (/^\/settings\/admin\//.test(path)) return "Admin Profile";
    if (path === '/settings/manage-data') return "My Data";
    if (path === '/settings/generate-new-uuid') return "Generate New UUID";
    if (path === '/settings/encryption') return "Encryption";
    if (path === '/settings/badges') return "Users Badges";
    if (path === '/pages/keyboard') return "Keyboard";
    if (path === '/settings/account') return "Account";
    if (path === '/company') return "Company";
    if (path === '/devices') return "Devices";
    if (/^\/devices\//.test(path)) return "Device details";
    if (/^\/chat/.test(path)) return "Chat";
    if (path === '/client/chat') return "Chat";
    if (path === '/overview') return "Dashalytics by GSW";
    if (path === '/auth/sign-in') return "Sign In";
    if (path === '/auth/sign-up') return "Sign Up";
    if (path === '/auth/forgot-password') return "Forgot Password";
    if (path === '/auth/request-demo') return "Request Demo";
    if (/^\/reset-password\//.test(path)) return "Reset Password";
    if (/^\/validate-account\//.test(path)) return "Validate Account";
    if (/^\/update-email\//.test(path)) return "Update Email";
    if (/^\/request-demo\//.test(path)) return "Lanuch demo";
    if (path === '/support') return "Support";
    if (path === '/support/companies') return "Companies";
    if (path === '/support/users') return "Users";
    if (/^\/support\/company\//.test(path)) return "CompanyDetails";
    if (/^\/support\/user\//.test(path)) return "User Profile";
    if (path === '/404') return "Page Not Found";
    if (path === '/500') return "Server Error";
    return "";
}
const PageTitle = () => {
    const router = useLocation();
    const pageVisible = usePageVisibility();
    const [showNewMessagesTitle, setShowNewMessagesTitle] = useState(false);
    const toggleNewMessagesTitle = useRef(null);
    const isMounted = useRef(false);
    const currentRoute = router.pathname;
    const currentTitle = getPageTitle(currentRoute);
    const { userID } = useAuth();
    const convs = useSelector((state) => state.chat.convs || []);
    const nNewMessages = useMemo(() => convs.filter(el => el.message.from !== userID && Array.isArray(el.message.seenBy) && !el.message.seenBy?.includes(userID)).length, [userID, convs]);
    const users = useSelector((state) => state.chat.users || []);
    const getUserName = useCallback((id) => {
        const _user = users.find(user => user._id === id);
        if (_user && _user.displayName) return _user.displayName;
        else return null;
    }, [users]);
    const newMessagesTitle = useMemo(() => {
        if (nNewMessages === 0) return '';
        if (nNewMessages > 1) return `(${nNewMessages})  ✉️`;
        const message = convs.find(el => !el.seen && (el.to === userID || el.from === el.to));
        const senderName = getUserName(message.from);
        return senderName ? `Chat from: ${senderName} (1) ✉️` : "(1) ✉️";
    }, [userID, convs, getUserName, nNewMessages])
    useEffect(() => {
        clearInterval(toggleNewMessagesTitle.current);
        toggleNewMessagesTitle.current = setInterval(() => {
            if (isMounted.current) setShowNewMessagesTitle((showNewMessagesTitle) => !showNewMessagesTitle);
        }, pageVisible ? 10000 : 3000);
        return () => {
            clearInterval(toggleNewMessagesTitle.current);
        }
    }, [nNewMessages, pageVisible]);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, [])
    return <>
        <Helmet
            titleTemplate="%s | Georgia SoftWorks"
            defaultTitle="Dashalytics App by GSW"
        />
        <Helmet title={(showNewMessagesTitle && !pageVisible && newMessagesTitle) || currentTitle} />
    </>

}
export default PageTitle;