import React, { useState } from "react";
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import * as Yup from "yup";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  Grid2
} from "@mui/material";
import { spacing } from "@mui/system";
import CheckIcon from '@mui/icons-material/Check';

import useAuth from "hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ResetPassword() {
  const { resetPassword } = useAuth();
  const [successMessage, setSuccessMessage] = useState('');
  const { uuid } = useParams();
  return (!successMessage ? (<React.Fragment>
    <Formik
      initialValues={{
        password: process.env.REACT_APP_DEFAULT_DEV_PASSWORD || "",
        passwordConfirm: process.env.REACT_APP_DEFAULT_DEV_PASSWORD || "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .required("Password can't be empty.")
          .min(8, 'Password too short (8 chars min).')
          .matches(/[a-z]/, 'Password must include a Lowercase letter')
          .matches(/[A-Z]/, 'Password must include a Capital letter')
          .matches(/[0-9]/, 'Password must include a number')
          .matches(/[^A-Za-z0-9]/, 'Password must include a Special character'),
        passwordConfirm: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const response = await resetPassword(values.password, uuid);
          setSuccessMessage(response);
          setStatus({ success: true });
        } catch (error) {
          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert sx={{ mt: 2, mb: 1 }} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="password"
            name="password"
            label="New Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            sx={{ my: 3 }}
          />
          <TextField
            type="password"
            name="passwordConfirm"
            label="Confirm Password"
            value={values.passwordConfirm}
            error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
            fullWidth
            helperText={touched.passwordConfirm && errors.passwordConfirm}
            onBlur={handleBlur}
            onChange={handleChange}
            sx={{ my: 3 }}
          />
          <Grid2 container>
            <Grid2 size={12}>
              <Typography component="h2" variant="body1">
                Password should be at least 8 characters long, and include:
              </Typography>
            </Grid2>
            <Grid2 size={12} >
              <CheckIcon sx={{ mx: 1 }} color={/[a-z]/.test(values.password) ? "primary" : "error"} />
              <Typography component="span" variant="body1" color={/[a-z]/.test(values.password) ? "primary" : "error"} inline>
                Lowercase letter
              </Typography>
            </Grid2>
            <Grid2 size={12} >
              <CheckIcon sx={{ mx: 1 }} color={/[A-Z]/.test(values.password) ? "primary" : "error"} />
              <Typography component="span" variant="body1" color={/[A-Z]/.test(values.password) ? "primary" : "error"} inline>
                Capital letter
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <CheckIcon sx={{ mx: 1 }} color={/[0-9]/.test(values.password) ? "primary" : "error"} />
              <Typography component="span" variant="body1" color={/[0-9]/.test(values.password) ? "primary" : "error"} inline>
                Number
              </Typography>
            </Grid2>
            <Grid2 size={12} >
              <CheckIcon sx={{ mx: 1 }} color={/[^A-Za-z0-9]/.test(values.password) ? "primary" : "error"} />
              <Typography component="span" variant="body1" color={/[^A-Za-z0-9]/.test(values.password) ? "primary" : "error"} inline>
                Special character
              </Typography>
            </Grid2>
          </Grid2>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting || !isValid || !values.password.length || values.password !== values.passwordConfirm}
            sx={{ mt: 3 }}
          >
            Reset password
          </Button>
          <Typography variant="body2" sx={{ mt: 4 }}>* You will be redirected to your account once the password is updated</Typography>
        </form>
      )}
    </Formik>
  </React.Fragment>) : (<React.Fragment >
    <Box m={3}>
      <Typography component="h2" variant="body1" align="center">
        {successMessage}
      </Typography>
    </Box>
  </React.Fragment >));
}

export default ResetPassword;
