import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import { green, blue, red } from "@mui/material/colors";

const TemperatureLevel = (props) => {
    const { level } = props;
    let color = green[800];
    if (level <= 10) color = blue[900];
    if (level >= 45) color = red[600];

    return <DeviceThermostatIcon sx={{ color }} {...props} />;
}
export default TemperatureLevel;