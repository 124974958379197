import React, { 
    useState, 
    useEffect, 
    useMemo 
} from "react";
import { styled } from '@mui/system';
import { NavLink } from "react-router-dom";
import {
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography,
    Grid2,
    Box,
    Tabs,
    Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import CompanyStats from './companyStats';
import CompanyDetails from './CompanyDetails';
import useAuth from 'hooks/useAuth';
import Billing from './billing';
import AuthentificationLog from './AuthentificationLog';
import Downloads from './downloads';
import ScanEventsPolicy from './ScanEventsPolicy';
import { getBillingStatus, getGSWBillingStatus } from 'utils/api';
import Support from "./Support";
import LADS from "./LADS";
import Authorized from "components/Authorized";
import useAuthorization from "hooks/useAuthorization";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);

function TabPanel(props) {
    const { children, tab, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={tab !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {tab === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function Company() {
    const { isAuthorized } = useAuthorization();
    const [billingStatus, setBillingStatus] = useState(null);
    useEffect(() => {
        let fetchCall = getBillingStatus({ fromCache: true }).then(billing => {
            if (billing?.status) {
                console.debug("stripe billing from cache", billing);
            }
            fetchCall = getBillingStatus({ fromCache: false }).then(billing => {
                if (billing?.status) {
                    console.debug("stripe billing from api", billing);
                }
            });

        });
        return () => {
            fetchCall.cancel();
        };
    }, []);

    useEffect(() => {
        const fetchCall = getGSWBillingStatus({ fromCache: true }).then(billing => {
            if (billing?.status) {
                console.debug("gsw billing", billing);
                setBillingStatus(billing);
            }
        });
        return () => {
            fetchCall.cancel();
        };
    }, []);
    const [tab, setTab] = useState(0);
    const { compName } = useAuth();
    const hasNotAquiredAProduct = useMemo(() => {
        return billingStatus && !/^complete|active|trialing$/.test(billingStatus.status);
    }, [billingStatus]);


    const canManageLads = isAuthorized([
        "lads.restartBiThread",
        "lads.testConnection",
        "lads.writeRegistryKeyValu",
    ],
        "some");
    return (
        <React.Fragment>
            <Typography variant="h3" gutterBottom display="inline">
                {compName || ''}
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" sx={{ mt: 2 }}>
                <Link component={NavLink} to="/">
                    Settings
                </Link>
                <Typography>Company</Typography>
            </Breadcrumbs>
            <Divider sx={{ my: 6 }} />
            <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: '100%' }}>
                <Tabs
                    value={tab}
                    onChange={(e, tab) => setTab(tab)}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="Company"
                >
                    <Tab label="Overview" />
                    <Tab label="Downloads" />
                    {canManageLads && <Tab label="LADS" />}
                </Tabs>
            </Box>
            <TabPanel tab={tab} index={0}>
                <Grid2 container spacing={6}>
                    <Grid2 size={12}>
                        <Authorized some={["payment.readStatus", "device.read", "admin.read"]}>
                            <CompanyStats billingStatus={billingStatus} />
                        </Authorized>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: hasNotAquiredAProduct ? 12 : 4}}>
                        <Authorized permission="payment.readStatus">
                            <Billing billingStatus={billingStatus} />
                        </Authorized>
                    </Grid2>
                    <Grid2  size={{ xs: 12, md: hasNotAquiredAProduct ? 12 : 8}}>
                        <Authorized permission="authLog.read">
                            <AuthentificationLog />
                        </Authorized>
                    </Grid2>
                    <Grid2 size={12}>
                        <Authorized permission="myCompany.read">
                            <CompanyDetails />
                        </Authorized>
                    </Grid2>
                    <Grid2 size={12}>
                        <Authorized permission="event.updateIgnoreScan">
                            <ScanEventsPolicy />
                        </Authorized>
                    </Grid2>
                    <Grid2 size={12}>
                        <Authorized permission="myAccount.write">
                            <Support />
                        </Authorized>
                    </Grid2>
                </Grid2>
            </TabPanel>
            <TabPanel tab={tab} index={1}>
                <Downloads />
            </TabPanel>
            <TabPanel tab={tab} index={2}>
                <LADS />
            </TabPanel>
        </React.Fragment>
    );
}

export default Company;


