import { useState } from 'react';
import {
    Grid2,
    Card,
    CardContent,
    TextField,
    CardHeader,
    IconButton
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const CreateRole = ({ fetching, handleCreateAdminRole, close }) => {
    const [roleName, setRoleName] = useState('');
    return <Card>
        <CardContent>
            <CardHeader
                title="Create New Profile"
                action={
                    <IconButton onClick={close} color="primary">
                        <CancelIcon />
                    </IconButton>
                } />
            <Grid2 container alignItems="center" justifyContent="space-between" columnSpacing={2}>
                <Grid2>
                    <TextField
                        sx={{ width: 150 }}
                        label="Profile Name"
                        variant="outlined"
                        size="small"
                        value={roleName}
                        onChange={(e) => setRoleName(e.target.value)}
                    />
                </Grid2>
                <Grid2>
                    <LoadingButton
                        disabled={fetching || !roleName}
                        loading={fetching}
                        variant="contained"
                        onClick={() => handleCreateAdminRole(roleName)}
                        loadingPosition="end"
                        endIcon={<SaveIcon />}
                        size="small"
                    >
                        Create
                    </LoadingButton>
                </Grid2>
            </Grid2>
        </CardContent>
    </Card>
}
export default CreateRole;