import React, { useState } from 'react';
import { styled } from '@mui/system';
import {
    Card as MuiCard,
    CardContent,
    Button as MuiButton,
    Grid2,
    TextField as MuiTextField,
    Typography,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { Key } from "react-feather";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import { http } from "utils/http";
import { spacing } from "@mui/system";
import useAuthorization from 'hooks/useAuthorization';

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
const GenerateNewUUID = () => {
    const { isAuthorized } = useAuthorization();
    const canResetPostEventUUID = isAuthorized("lads.resetPostEventUUID");
    const [generateConfirmation, setGenerateConfirmation] = useState(false);
    const [password, setPassword] = useState('');
    const [aggreed, setAggreed] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [UUID, setUUID] = useState(null);
    const handleCancelGenerate = () => {
        setAggreed(false);
        setPassword('');
        setGenerateConfirmation(false);
        setError('');
    }
    const handleMyAccountDelete = async () => {
        setError('');
        setFetching(true);
        try {
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/generate-uuid`, {
                password
            })
            setFetching(false);
            if (resp.data && resp.data.success) {
                setMessage(resp.data.message);
                setUUID(resp.data.uuid);
                toast.success(resp.data.message);
                setPassword('');
                setAggreed(false);
            }
        } catch (err) {
            // Handle Error Here
            setFetching(false);
            if (err && err.response && err.response.data && err.response.data.message) {
                toast.error(err.response.data.message);
                return setError(err.response.data.message);
            }
            console.error("error", err);
            toast.error("Something went wrong");
            setError("Something went wrong");
        }
    }
    return <Card sx={{ mb: 6 }}>
        <CardContent>
            <Typography variant="h6" gutterBottom>
                Generate New UUID
            </Typography>
            <Grid2 container spacing={6}>
                {!generateConfirmation ?
                    (
                        <Grid2 size={12}>
                            <Button
                                onClick={() => setGenerateConfirmation(true)}
                                variant="contained"
                                color="error"
                                endIcon={<Key />}
                                disabled={!canResetPostEventUUID}
                            >
                                Generate New UUID
                            </Button>
                        </Grid2>
                    ) :
                    (
                        <React.Fragment>
                            <Grid2 size={12} sx={{ mt: 3 }}>
                                <Typography variant="body" gutterBottom>
                                    When a new access key is generated, GSW LADS must be updated with the new key for events to be processed
                                </Typography>
                            </Grid2>
                            <Grid2 size={12}>
                                {message && <Grid2 size={12}>
                                    <Typography variant="body" gutterBottom color="#0d6efd">
                                        {message}
                                    </Typography>
                                </Grid2>}
                            </Grid2>
                            {UUID ? (
                                <>
                                    <CopyToClipboard text={UUID}
                                        onCopy={() => toast.success("Copied to clipboard")}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                        >
                                            Copy to Clipboard
                                        </Button>
                                    </CopyToClipboard>
                                    <Typography component="p" variant="body2" align="center" gutterBottom>
                                        {UUID}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Grid2 size={4}>
                                        <TextField
                                            label="Type your password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            variant="outlined"
                                            type="password"
                                            fullWidth
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={(e) => setAggreed(e.target.checked)} checked={aggreed} color="primary" />}
                                            label="I know what I am doing"
                                        />
                                        <Button onClick={handleMyAccountDelete} sx={{ mx: 1 }} disabled={!aggreed || password.length < 6 || fetching} variant="contained" color="error">
                                            Yes
                                        </Button>
                                        <Button sx={{ mx: 1 }} onClick={handleCancelGenerate} variant="contained" color="primary">
                                            No
                                        </Button>
                                    </Grid2>
                                    <Grid2 size={12}>
                                        {error && <Typography variant="body" color="error" gutterBottom>
                                            {error}
                                        </Typography>}
                                    </Grid2>
                                </>
                            )}
                        </React.Fragment>
                    )}
            </Grid2>
        </CardContent>
    </Card>
}
export default GenerateNewUUID;