const components = {
  MuiCssBaseline: {
    styleOverrides: ({ theme }) => ({
      html: {
        height: "100%",
        overflowX: "hidden"
      },
      body: {
        height: "100%",
        overflowX: "hidden",
        margin: 0,
        background: theme.palette.background.default,
      },
      "#root": {
        height: "100%",
        overflowX: "hidden"
      },
    })
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },

  MuiIconButton: {
    defaultProps: {
      disableRipple: true
    },
  },
  MuiButton: {
    defaultProps: {
      size: "small"
    }
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "h6",
      },
    },
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
        "&  .MuiIconButton-root": {
          padding: 4,
          width: 28,
          height: 28
        }
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
        boxShadow:
          "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
        backgroundImage: "none",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: "300",
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: "64px",
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`,
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px",
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiTimePicker: {
    defaultProps: {
      disableFuture: true,
      formatDensity: "dense",
      slotProps: {
        textField: {
          size: "small",
          sx: { width: 120 }
        }
      }
    }
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        color: theme.palette.background.default,
        backgroundColor: theme.palette.text.secondary,
      })
    }
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        display: "grid",
        gridTemplateRows: "36px auto 52px"
      },
    },
    defaultProps: {
      disableAutosize: true,
      disableEval: true
    },
  },
};

export default components;
