export const propertyOptions = {
    NONE: {
        name: 'None',
        value: 'NONE',
    },
    ANY: {
        name: 'Any',
        value: 'ANY',
    },
    EXACTLY: {
        name: 'Exactly',
        value: 'EXACTLY',
    },
}


export const defaultBadgeSettings = {
    badgeLength: 7,
    badgeContainsLowerCase: propertyOptions.ANY.value,
    badgeLowercaseCount: 1,
    badgeContainsUpperCase: propertyOptions.NONE.value,
    badgeUppercaseCount: 1,
    badgeContainsNumber: propertyOptions.NONE.value,
    badgeNumberCount: 1,
    badgeContainsSpecialChar: propertyOptions.NONE.value,
    badgeSpecialCharCount: 1,
    badgePasswordLength: 8,
    badgeAssignFromKeyboardEventsEnabled: false,
}

export const isValidLength = (str, badgeLength = defaultBadgeSettings.badgeLength) => {
    return str.length === parseInt(badgeLength);
}

export const isValidLowercase = (str, badgeContainsLowerCase = defaultBadgeSettings.badgeContainsLowerCase, badgeLowercaseCount = defaultBadgeSettings.badgeLowercaseCount) => {
    if (badgeContainsLowerCase === propertyOptions.ANY.value) return true;
    if (badgeContainsLowerCase === propertyOptions.NONE.value) return !/[a-z]/.test(str);
    return str?.match(/[a-z]/g)?.length === parseInt(badgeLowercaseCount);
}

export const isValidUppercase = (str, badgeContainsUpperCase = defaultBadgeSettings.badgeContainsUpperCase, badgeUppercaseCount = defaultBadgeSettings.badgeUppercaseCount) => {
    if (badgeContainsUpperCase === propertyOptions.ANY.value) return true;
    if (badgeContainsUpperCase === propertyOptions.NONE.value) return !/[A-Z]/.test(str);
    return str?.match(/[A-Z]/g)?.length === parseInt(badgeUppercaseCount);
}

export const isValidNumbers = (str, badgeContainsNumber = defaultBadgeSettings.badgeContainsNumber, badgeNumberCount = defaultBadgeSettings.badgeNumberCount) => {
    if (badgeContainsNumber === propertyOptions.ANY.value) return true;
    if (badgeContainsNumber === propertyOptions.NONE.value) return !/[0-9]/.test(str);
    return str?.match(/[0-9]/g)?.length === parseInt(badgeNumberCount);
}

export const isValidSpecialChar = (str, badgeContainsSpecialChar = defaultBadgeSettings.badgeContainsSpecialChar, badgeSpecialCharCount = defaultBadgeSettings.badgeSpecialCharCount) => {
    if (badgeContainsSpecialChar === propertyOptions.ANY.value) return true;
    if (badgeContainsSpecialChar === propertyOptions.NONE.value) return str.replace(/[a-z 0-9]/gi, "").length === 0;
    return str.replace(/[a-z 0-9]/gi, "").length === parseInt(badgeSpecialCharCount);
}

export const isValidUserBadge = ({ testCode = '',
    badgeLength = defaultBadgeSettings.badgeLength,
    badgeContainsLowerCase = defaultBadgeSettings.badgeContainsLowerCase,
    badgeLowercaseCount = defaultBadgeSettings.badgeLowercaseCount,
    badgeContainsUpperCase = defaultBadgeSettings.badgeContainsUpperCase,
    badgeUppercaseCount = defaultBadgeSettings.badgeUppercaseCount,
    badgeContainsNumber = defaultBadgeSettings.badgeContainsNumber,
    badgeNumberCount = defaultBadgeSettings.badgeNumberCount,
    badgeContainsSpecialChar = defaultBadgeSettings.badgeContainsSpecialChar,
    badgeSpecialCharCount = defaultBadgeSettings.badgeSpecialCharCount,
}) => {
    return isValidLength(testCode, badgeLength) &&
        isValidLowercase(testCode, badgeContainsLowerCase, badgeLowercaseCount) &&
        isValidUppercase(testCode, badgeContainsUpperCase, badgeUppercaseCount) &&
        isValidNumbers(testCode, badgeContainsNumber, badgeNumberCount) &&
        isValidSpecialChar(testCode, badgeContainsSpecialChar, badgeSpecialCharCount)
}
export const stringifyLogoutSequence = (sequence, KeyMap = {}) => {
    if (!sequence || !Array.isArray(sequence) || sequence.length === 0) return "";
    const strArray =  sequence.map(el => {
        const isCtrl = !!(el.modifier & 12288);
        const isShift = !!(el.modifier & 65);
        if(KeyMap[el.keyCode]){
            if(isCtrl && KeyMap[el.keyCode]?.ctrl?.length) return KeyMap[el.keyCode].ctrl;
            if(isShift && KeyMap[el.keyCode]?.shift?.length) return KeyMap[el.keyCode].shift;
            if(KeyMap[el.keyCode]?.key.length) return KeyMap[el.keyCode].key;
        }
        return null;
    });
    if(strArray.every(el => "string" === typeof el)) return strArray.join("");
    return "";
}



export const isValidLogoutSequence = (sequence) => {
    if (!sequence || !Array.isArray(sequence)) return false;
    if (sequence.length === 0) return true;
    return sequence.every(el => !isNaN(el.keyCode) &&
        (
            el.modifier === 0 ||
            el.modifier === 65 ||
            el.modifier === 12288 ||
            el.modifier === 12353
        ));
}