import React from 'react';
import { styled } from '@mui/system';
import EncryptionInline from './EncryptionInline';
import EncryptionGlobal from './EncryptionGlobal';
import { NavLink } from "react-router-dom";
import {
    Link,
    Grid2,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography,
} from '@mui/material';
import { spacing } from '@mui/system';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);
const  Encryption = () => {
    return (
        <React.Fragment>
            <Typography variant="h3" gutterBottom display="inline">
                Encryption
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" sx={{ mt: 2 }}>
                <Link component={NavLink} to="/">
                    Settings
                </Link>
                <Typography>Encryption</Typography>
            </Breadcrumbs>
            <Divider sx={{ my: 6 }} />
            <Typography variant="h3" gutterBottom>
                Encryption
            </Typography>
            <Typography variant="body" gutterBottom>
                If you enabled data encryption in the your lads server,
                you need to input your decryption key here to be able to see the conent of  5000 and 5001 events
            </Typography>
            <Grid2 container spacing={6}>
                <Grid2 size={12}>
                    <EncryptionInline />
                </Grid2>
            </Grid2>
            <Grid2 container spacing={6}>
                <Grid2 size={12}>
                    <EncryptionGlobal />
                </Grid2>
            </Grid2>
        </React.Fragment>
    );
}

export default Encryption;

