import React, {
    useMemo,
    useRef,
    useState,
    useEffect
} from 'react';
import { styled } from '@mui/system';
import { Typography, Box, Grid2 } from '@mui/material';
import { useSelector } from 'react-redux';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PermScanWifiOutlined from '@mui/icons-material/PermScanWifiOutlined';
import { rgba } from 'polished';
import codes from 'constants/codes';
import HtmlTooltip from 'components/HtmlTooltip';
const getAndroidColorFromID = (/*androidID*/) => {
    return '#2e7d32';
    /*
    // create colors depending on androidID - disable because of visibilty issues with background
    if (!androidID) return '#000000';
    let i = 0;
    `${androidID}`.split('').forEach((el) => {
        i += el.charCodeAt(0);
    })
    return colors[i % colors.length];
    */
}

const Pointer = styled(PhoneAndroidIcon)`
    color: ${({ color }) => color};
    cursor: pointer;
`;

const ScanAnimation = styled(Box)`
    position: absolute;
    background-color: ${(props) => props.theme.palette.secondary.main};
    border-radius: 100%;
    transition-delay: 0;
    animation-duration: .5s;
    transition-property: top left;
    transition-timing-function: ease-in-out;
    animation-name: breath-animation;
    z-index: 1197;
    animation-iteration-count: 4;
    opacity: 0;
    @keyframes breath-animation {
          0% {
            transform: scale(1);
            opacity: 1;
          }
          100% {
            transform: scale(3);
            opacity: 0;
          }
    }
`;



// animation sleeping here
const WifiDrop = styled(PermScanWifiOutlined)`
    position: absolute;
    padding: 0;
    margin: 0;
    animation-duration: .5s;
    animation-name: blink-animation;
    animation-iteration-count: ${(props) => props.animate ? 5 : 0};
    opacity: 1;
    @keyframes blink-animation {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        100% {
            transform: scale(3);
            opacity: 0;
        }
    }
`;

const Sleeping = styled('p')`
    position: absolute;
    padding: 0;
    margin: 0;
    z-index: 1199;
    user-select: none; 
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => rgba(props.theme.palette.primary.contrastText, 0)};
    -webkit-text-stroke: 1px ${(props) => rgba(props.theme.palette.primary.contrastText, 0.1)};
    -webkit-text-fill-color: ${(props) => props.theme.palette.primary.main};
    font-weight: bold;
    animation-duration: .5s;
    font-size: 14px;
    animation-name: blink-animation;
    animation-iteration-count: ${(props) => props.animate ? 5 : 0};
    opacity: 1;
    @keyframes blink-animation {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        100% {
            transform: scale(3);
            opacity: 0;
        }
    }
`
const CENTER_POINTER_SIZE = 8;
const DevicePointCenter = styled(Box)`
    position: absolute;
    padding: 0;
    margin: 0;   
    z-index: 1199;
    cursor: pointer;
    transitionDuration: 0;
    transition-delay: 0;
    transition-property: top, left;
    transition-timing-function ease-in-out;
    width: ${CENTER_POINTER_SIZE}px;
    height: ${CENTER_POINTER_SIZE}px;
    border-radius: ${CENTER_POINTER_SIZE}px;
    border: 1px solid black;
    background-color: ${(props) => rgba(props.theme.palette.primary.main, 0.2)};
`
const PointerContainer = styled(Grid2)`
    position: absolute;
    padding: 0;
    margin: 0;
    z-index: 1198;
    cursor: pointer;
    width: auto;
    transitionDuration: 0;
    transition-delay: 0;
    transition-property: top, left;
    transition-timing-function ease-in-out;
    transform: translate(-50%, -50%);
`;
const StatusContainer = styled(Grid2)`
    position: relative;
    padding: 0;
    margin: 0;
    width: ${(props) => props.width}px;
`
const PointerTitle = styled(Typography)`
    color: ${props => props.theme.palette.primary.main};
    background-color: ${(props) => rgba(props.theme.palette.background.default, 0.5)};
    width: 80px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align:center;
`

const DevicePointer = ({ x, y, t, smoothing, androidID, name, accessPoints, iconSize, lastContact, wifi_level, showName }) => {
    const devicesEvents = useSelector((state) => state.events.devicesEvents);
    const events = useMemo(() => devicesEvents[androidID] || [], [devicesEvents, androidID]);
    const [reRenderKey, setReRenderKey] = useState(Math.random());
    const sleeping = Date.now() - lastContact > 10000 && Date.now() - t > 10000;
    const animate = Date.now() - t < 15000 ? 1 : 0;
    useEffect(() => {
        const timer = setTimeout(() => {
            setReRenderKey(Math.random());
        }, 5000);
        return () => {
            clearTimeout(timer);
        }
    })

    //replace with find last
    const scanEvent = useMemo(() => {
        let lastEvent = {};
        for (let i = events.length - 1; i >= 0; i--) {
            if (parseInt(events[i].messageID) === codes.GSW_SCANNED) {
                lastEvent = events[i];
                break;
            }
        }
        return lastEvent;
    }, [events]);
    const iconColor = useMemo(() => {
        return getAndroidColorFromID(androidID)
    }, [androidID])
    const _x = useRef(x);
    const _y = useRef(y);
    const _t = useRef(t);
    // interpolation
    const { newX, newY, dt } = useMemo(() => {
        let dt = Math.abs(t - _t.current);
        dt = dt <= 0 ? 20 : dt;
        dt = dt >= 1000 ? 500 : dt;
        if (Math.abs(_x.current - x) < smoothing && Math.abs(_y.current - y) < smoothing) {
            return { newX: _x.current, newY: _y.current, dt };
        }
        _t.current = t;
        _x.current = x;
        _y.current = y;
        return { newX: x, newY: y, dt }
    }, [x, y, t, smoothing]);
    return <Box key={reRenderKey}>
        <HtmlTooltip maxWidth={300} title={<Box>
            <Typography sx={{ mb: 4 }} variant="h6">Device: {name || androidID || '-'}</Typography>
        </Box>}>
            <PointerContainer
                container
                alignItems="center"
                justifyContent="center"
                direction="column-reverse"
                sx={{
                    top: newY,
                    left: newX,
                    transitionDuration: `${dt}ms`,
                }}
            >
                <StatusContainer container justifyContent="space-around" alignItems="center" width={iconSize * 1.2}>
                    <Grid2>
                        {sleeping ? <Sleeping
                            animate={animate}
                            style={{
                                top: 0,
                                right: 0,
                                fontSize: iconSize / 3,
                                width: iconSize / 2,
                                height: iconSize / 2,
                            }}
                        >
                            ZZZ
                        </Sleeping> : null}
                    </Grid2>
                    <Grid2>
                        {((new Date(scanEvent.createdAt)).getTime() + 5000 > Date.now()) && <ScanAnimation key={scanEvent.createdAt} style={{
                            top: - iconSize,
                            left: 0,
                            width: iconSize,
                            height: iconSize,
                        }}
                        />}
                    </Grid2>
                    <Grid2>
                        {wifi_level === 0 && <WifiDrop
                            style={{
                                top: 0,
                                left: 0,
                                fontSize: iconSize / 3,
                                width: iconSize / 2,
                                height: iconSize / 2,
                            }}
                            color="error"
                            animate={Date.now() - t < 10000 ? 1 : 0}
                        />}
                    </Grid2>
                </StatusContainer>
                <Grid2>
                    <Pointer style={{
                        width: iconSize,
                        height: iconSize,
                    }}
                        color={iconColor}
                    />
                </Grid2>
                <Grid2>
                    {showName && <PointerTitle variant="caption">
                        {name || androidID || '-'}
                    </PointerTitle>}
                </Grid2>
            </PointerContainer>
        </HtmlTooltip>
        <DevicePointCenter
            style={{
                top: y - CENTER_POINTER_SIZE / 2,
                left: x - CENTER_POINTER_SIZE / 2,
                transitionDuration: `${dt}ms`,
            }}
        />
    </Box>
}
export default DevicePointer;