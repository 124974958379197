import {
    useState,
    useEffect
} from 'react';
import {
    TextField as MuiTextField,
    Grid2,
    Typography,
    FormControl,
    InputLabel,
    Select as MuiSelect,
    MenuItem,
    Alert,
    Card,
    CardContent,
    Switch,
    Box
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/system';
import HtmlTooltip from 'components/HtmlTooltip';
import {
    defaultBadgeSettings,
    propertyOptions,
} from 'utils/badge';
const TextField = styled(MuiTextField)`
    label.Mui-disabled {
        opacity: 0;
    }
    .Mui-disabled fieldset {
        opacity: 0;
    }
`;

const Select = styled(MuiSelect)`
    label.Mui-disabled {
        opacity: 0;
    }
    .Mui-disabled fieldset {
        opacity: 0;
    }
`;



const getSettingErrors = ({
    badgeLength,
    badgeContainsLowerCase,
    badgeLowercaseCount,
    badgeContainsUpperCase,
    badgeUppercaseCount,
    badgeContainsNumber,
    badgeNumberCount,
    badgeContainsSpecialChar,
    badgeSpecialCharCount
}) => {
    if (parseInt(badgeLength) <= 0) return 'Badge Length must be > 0';
    if (parseInt(badgeLowercaseCount) < 0 || parseInt(badgeUppercaseCount) < 0 || parseInt(badgeNumberCount) < 0 || parseInt(badgeSpecialCharCount) < 0) return 'Count must not be negative';
    if (![badgeContainsLowerCase, badgeContainsUpperCase, badgeContainsNumber, badgeContainsSpecialChar].every(el => /^((NONE)|(ANY)|(EXACTLY))$/.test(el))) return "Must select None, Any or Exactly";
    const requirements = [
        {
            contains: badgeContainsLowerCase,
            count: parseInt(badgeLowercaseCount)
        },
        {
            contains: badgeContainsUpperCase,
            count: parseInt(badgeUppercaseCount)
        },
        {
            contains: badgeContainsNumber,
            count: parseInt(badgeNumberCount)
        },
        {
            contains: badgeContainsSpecialChar,
            count: parseInt(badgeSpecialCharCount)
        }
    ];
    if (requirements.every(el => el.contains === propertyOptions.NONE.value)) return "Badge should contains at least one type that's not 'None'";
    if (requirements.filter(el => el.contains === propertyOptions.EXACTLY.value).reduce((a, c) => a + parseInt(c.count), 0) > parseInt(badgeLength)) return "Sum of required matches is greater than badge length"
    if (
        requirements.every(el => el.contains !== propertyOptions.ANY.value) &&
        requirements.reduce((p, c) => (p + (c.contains === propertyOptions.NONE.value ? 0 : parseInt(c.count))), 0) !== parseInt(badgeLength)
    ) return "The sum of fields set to exactly does not match the badge length"
    return '';
}
const BadgeRequirements = ({
    updateProperties,
    editing,
    setIsValidBadge,
    badgeLength = defaultBadgeSettings.badgeLength,
    badgeContainsLowerCase = defaultBadgeSettings.badgeContainsLowerCase,
    badgeLowercaseCount = defaultBadgeSettings.badgeLowercaseCount,
    badgeContainsUpperCase = defaultBadgeSettings.badgeContainsUpperCase,
    badgeUppercaseCount = defaultBadgeSettings.badgeUppercaseCount,
    badgeContainsNumber = defaultBadgeSettings.badgeContainsNumber,
    badgeNumberCount = defaultBadgeSettings.badgeNumberCount,
    badgeContainsSpecialChar = defaultBadgeSettings.badgeContainsSpecialChar,
    badgeSpecialCharCount = defaultBadgeSettings.badgeSpecialCharCount,
    badgeAssignFromKeyboardEventsEnabled = defaultBadgeSettings.badgeAssignFromKeyboardEventsEnabled,
    badgePasswordLength = 0
}) => {
    const [settingsError, setSettingsError] = useState('');
    useEffect(() => {
        const error = getSettingErrors({
            badgeLength,
            badgeContainsLowerCase,
            badgeLowercaseCount,
            badgeContainsUpperCase,
            badgeUppercaseCount,
            badgeContainsNumber,
            badgeNumberCount,
            badgeContainsSpecialChar,
            badgeSpecialCharCount,
        });
        setSettingsError(error);
        if (error) setIsValidBadge(false);
        else setIsValidBadge(true);
    }, [setIsValidBadge, badgeContainsLowerCase, badgeContainsNumber,
        badgeContainsSpecialChar, badgeContainsUpperCase, badgeLength,
        badgeLowercaseCount, badgeNumberCount, badgeSpecialCharCount,
        badgeUppercaseCount]);
    return <Card variant="outlined">
        <CardContent>
            <Grid2 container spacing={6}>
                {settingsError && <Grid2 size={12}>
                    <Alert severity="error">{settingsError}</Alert>
                </Grid2>}
                <Grid2 size={4}>
                    <Typography variant="body1" >
                        Badge Length
                    </Typography>
                </Grid2>
                <Grid2 size={4}>
                </Grid2>
                <Grid2 size={4}>
                    <TextField
                        value={badgeLength}
                        onChange={(e) => updateProperties({ badgeLength: e.target.value })}
                        type={editing ? "number" : "text"}
                        size="small"
                        fullWidth
                        label="Badge length"
                        disabled={!editing}
                        slotProps={{ htmlInput: { min: 1 } }}
                    />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="body1" >
                        Number of Lowercase
                    </Typography>
                </Grid2>
                <Grid2 size={4}>
                    {editing ?
                        <FormControl fullWidth>
                            <InputLabel id="lowercase-select-label">Contains</InputLabel>
                            <Select
                                labelId="lowercase-select-label"
                                id="lowercase-select"
                                value={badgeContainsLowerCase}
                                label="Contains"
                                size="small"
                                onChange={(e) => updateProperties({ badgeContainsLowerCase: e.target.value })}
                            >
                                {Object.values(propertyOptions).map(el => <MenuItem key={el.value} value={el.value}>{el.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                        :
                        <Typography>
                            {badgeContainsLowerCase}
                        </Typography>}
                </Grid2>
                <Grid2 size={4}>
                    {badgeContainsLowerCase === propertyOptions.EXACTLY.value && <TextField
                        value={badgeLowercaseCount}
                        onChange={(e) => updateProperties({ badgeLowercaseCount: e.target.value })}
                        type={editing ? "number" : "text"}
                        size="small"
                        fullWidth
                        label="Number of Lowercase"
                        disabled={!editing}
                        slotProps={{ htmlInput: { min: 0 } }}
                    />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="body1" >
                        Number of Uppercase
                    </Typography>
                </Grid2>
                <Grid2 size={4}>
                    {editing ?
                        <FormControl fullWidth>
                            <InputLabel id="upercase-select-label">Contains</InputLabel>
                            <Select
                                labelId="upercase-select-label"
                                id="upercase-select"
                                value={badgeContainsUpperCase}
                                label="Contains"
                                size="small"
                                onChange={(e) => updateProperties({ badgeContainsUpperCase: e.target.value })}
                            >
                                {Object.values(propertyOptions).map(el => <MenuItem key={el.value} value={el.value}>{el.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                        :
                        <Typography>
                            <strong>{badgeContainsUpperCase}</strong>
                        </Typography>}
                </Grid2>
                <Grid2 size={4}>
                    {badgeContainsUpperCase === propertyOptions.EXACTLY.value && <TextField
                        value={badgeUppercaseCount}
                        onChange={(e) => updateProperties({ badgeUppercaseCount: e.target.value })}
                        type={editing ? "number" : "text"}
                        size="small"
                        fullWidth
                        label="Number of Uppercase"
                        disabled={!editing}
                        slotProps={{ htmlInput: { min: 0 } }}
                    />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="body1" >
                        Number of Numbers
                    </Typography>
                </Grid2>
                <Grid2 size={4}>
                    {editing ?
                        <FormControl fullWidth>
                            <InputLabel id="numbers-select-label">Contains</InputLabel>
                            <Select
                                labelId="numbers-select-label"
                                id="numbers-select"
                                value={badgeContainsNumber}
                                label="Contains"
                                size="small"
                                onChange={(e) => updateProperties({ badgeContainsNumber: e.target.value })}
                            >
                                {Object.values(propertyOptions).map(el => <MenuItem key={el.value} value={el.value}>{el.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                        :
                        <Typography>
                            <strong>{badgeContainsNumber}</strong>
                        </Typography>}
                </Grid2>
                <Grid2 size={4}>
                    {badgeContainsNumber === propertyOptions.EXACTLY.value && <TextField
                        value={badgeNumberCount}
                        onChange={(e) => updateProperties({ badgeNumberCount: e.target.value })}
                        type={editing ? "number" : "text"}
                        size="small"
                        fullWidth
                        label="Number of Numbers"
                        disabled={!editing}
                        slotProps={{ htmlInput: { min: 0 } }}
                    />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="body1" >
                        Number of Special Characters
                    </Typography>
                </Grid2>
                <Grid2 size={4}>
                    {editing ?
                        <FormControl fullWidth>
                            <InputLabel id="badge-special-char-select-label">Contains</InputLabel>
                            <Select
                                labelId="badge-special-char-select-label"
                                id="badge-special-charselect"
                                value={badgeContainsSpecialChar}
                                label="Contains"
                                size="small"
                                onChange={(e) => updateProperties({ badgeContainsSpecialChar: e.target.value })}
                            >
                                {Object.values(propertyOptions).map(el => <MenuItem key={el.value} value={el.value}>{el.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                        :
                        <Typography>
                            <strong>{badgeContainsSpecialChar}</strong>
                        </Typography>}
                </Grid2>
                <Grid2 size={4}>
                    {badgeContainsSpecialChar === propertyOptions.EXACTLY.value && <TextField
                        value={badgeSpecialCharCount}
                        onChange={(e) => updateProperties({ badgeSpecialCharCount: e.target.value })}
                        type={editing ? "number" : "text"}
                        size="small"
                        fullWidth
                        label="Number of Special Characters"
                        disabled={!editing}
                        slotProps={{ htmlInput: { min: 0 } }}
                    />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="body1" >
                        Badge assign from Keyboard
                    </Typography>
                </Grid2>
                <Grid2 size={4}>
                    {editing ?
                        <Switch
                            checked={badgeAssignFromKeyboardEventsEnabled}
                            onChange={(e) => updateProperties({ badgeAssignFromKeyboardEventsEnabled: e.target.checked })}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        :
                        <Typography>
                            <strong>{badgeAssignFromKeyboardEventsEnabled ? "Yes" : "No"}</strong>
                        </Typography>}
                </Grid2>
                <Grid2 size={4} />
                <Grid2 size={4}>
                    <Typography variant="body1" >
                        Password Length <HtmlTooltip
                            maxWidth={300}
                            title={<Box>
                                <Typography>If password length is set, password entered after badge scan will not be saved in the database</Typography>
                            </Box>}>
                            <InfoIcon color="primary" />
                        </HtmlTooltip>
                    </Typography>
                </Grid2>
                <Grid2 size={4}>
                    {editing && <FormControl fullWidth>
                        <InputLabel id="badge-password-length-select-label">Length</InputLabel>
                        <Select
                            labelId="badge-password-length-select-label"
                            id="badge-password-select"
                            value={!!badgePasswordLength ? "fixed" : "any"}
                            label="Length"
                            size="small"
                            onChange={(e) => updateProperties({ badgePasswordLength: e.target.value === "fixed" ? defaultBadgeSettings.badgePasswordLength : 0 })}
                        >
                            <MenuItem value={"any"}>Any</MenuItem>
                            <MenuItem value={"fixed"}>Fixed</MenuItem>
                        </Select>
                    </FormControl>}
                </Grid2>
                <Grid2 size={4}>
                    <TextField
                        value={badgePasswordLength || "Any"}
                        onChange={(e) => updateProperties({ badgePasswordLength: e.target.value })}
                        type={!editing || !badgePasswordLength ? "text" : "number"}
                        size="small"
                        fullWidth
                        label="Password Length"
                        disabled={!editing}
                        slotProps={{ htmlInput: { min: 0, max: 20 } }}
                    />
                </Grid2>
            </Grid2>
        </CardContent>
    </Card>
}

export default BadgeRequirements;
