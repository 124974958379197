import { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import papa from 'papaparse';

const HelpCSVFormatPreview = ({ devices }) => {
    const [hrefRef, setHrefRef] = useState(null);
    useEffect(() => {
        if (hrefRef) {
            const data = devices.map(({ androidID, name, ipaddr }) => [androidID || "", name || "", ipaddr || ""]);
            const csv = papa.unparse([["id", "name", "ipaddr"], ...data]);
            let blob = new Blob([csv], { type: 'text/plain' });
            hrefRef.download = 'dashalytics_device_names_sample.csv';
            hrefRef.href = window.URL.createObjectURL(blob);
        }
    }, [hrefRef, devices])
    return <a href="/" ref={ref => setHrefRef(ref)}><DownloadIcon role="button" color="primary" /></a>
}

export default HelpCSVFormatPreview;