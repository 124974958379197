import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import csvFormatImage from './csv-format.png';

const HelpCSVFormatPreview = () => {
    return (
        <Card sx={{ maxWidth: 500 }}>
            <CardMedia
                component="img"
                height="194"
                image={csvFormatImage}
                alt="Csv format"
            />
        </Card>
    );
}

export default HelpCSVFormatPreview;