import { useMemo, useState } from 'react';
import {
    Card,
    CardContent,
    Grid2,
    Typography,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Alert,
    Button
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import useAuthorization from 'hooks/useAuthorization';
import { http } from 'utils/http'

const modes = {
    DEVICE: "DEVICE",
    ACCESS_POINT: "ACCESS_POINT"
}

const WifiHeatmapSettings = ({ rttSettings }) => {
    const [mode, setMode] = useState(modes.DEVICE);
    const [confirmation, setConfirmation] = useState(false);
    const { isAuthorized } = useAuthorization();
    const canResetRttWifiLevels = isAuthorized("rtt.resetWiFiLevels");
    const accessPoints = useMemo(() => (rttSettings?.rttAccessPoints || [])
        .filter(el => el.type === "RTT"),
        [rttSettings])
    const selectedGroupID = useSelector((state) => state.groups.selectedGroup);
    const groups = useSelector(state => state.groups.groups);
    const devices = useSelector(state => state.devices);
    const devicesNameMap = useMemo(() => devices.reduce((a, c) => ({ ...a, [c.androidID]: c.name || c.androidID }), {}), [devices])
    const selectedGroupDevices = useMemo(() => {
        const selectedGroup = groups.find(el => el._id === selectedGroupID);
        return selectedGroup?.devices || [];
    }, [groups, selectedGroupID])
    const [androidID, setAndroidID] = useState("-");
    const [accessPoint, setAccessPoint] = useState("-");
    const [fetching, setFetching] = useState(false);
    const handleDeleteWifiHeatmapData = async () => {
        try {
            setFetching(true);
            await new Promise((r) => setTimeout(r, 1000));
            const resp = await http.delete(`${process.env.REACT_APP_SERVER_URL}/api/v2/rtt/reset-wifi-levels`,
                {
                    data: {
                        androidID: androidID === "-" ? null : androidID,
                        accessPoint: accessPoint === "-" ? null : accessPoint,
                        groupID: selectedGroupID,
                        mode
                    }
                })
            toast.success(resp?.data?.message || "Deleted");
            setMode(modes.DEVICE);
            setAndroidID("-");
            setAccessPoint("-");
            setConfirmation(false);
        } catch (error) {
            toast.error(error?.response?.data?.message || "Something went wrong");
        }
        setFetching(false)
    }
    return <Card>
        <CardContent>
            <Grid2 container spacing={6}>
                <Grid2 size={12}>
                    <Typography variant="h6" gutterBottom>
                        Wifi Heatmap
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Moving a device or an Access point to another group will cause the heatmap to show inaccurate results
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Saved data for Device/Access Point must be deleted manually from database
                    </Typography>
                </Grid2>
                <Grid2>
                    <FormControl sx={{ width: 200 }}>
                        <InputLabel id="mode-select-label">Mode</InputLabel>
                        <Select
                            labelId="mode-select-label"
                            id="mode-select"
                            value={mode}
                            label="Mode"
                            size="small"
                            disabled={!canResetRttWifiLevels || fetching}
                            onChange={(event) => setMode(event.target.value)}
                        >
                            <MenuItem value={modes.DEVICE}>Device</MenuItem>
                            <MenuItem value={modes.ACCESS_POINT}>Access Point</MenuItem>
                        </Select>
                    </FormControl>
                </Grid2>
                {mode === modes.DEVICE ? <Grid2>
                    <FormControl sx={{ width: 200 }}>
                        <InputLabel id="android-id-select-label">Device</InputLabel>
                        <Select
                            labelId="android-id-select-label"
                            id="android-id-select"
                            value={androidID || '-'}
                            label="Device"
                            size="small"
                            disabled={!canResetRttWifiLevels || fetching}
                            onChange={(event) => setAndroidID(event.target.value)}
                        >
                            <MenuItem value={"-"}>All</MenuItem>
                            {selectedGroupDevices.map(androidID => <MenuItem key={androidID} value={androidID}>{devicesNameMap[androidID] || androidID}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid2> :
                    <Grid2>
                        <FormControl sx={{ width: 200 }}>
                            <InputLabel id="access-point-select-label">Access Point</InputLabel>
                            <Select
                                labelId="access-point-select-label"
                                id="access-point-select"
                                value={accessPoint || '-'}
                                label="Access Point"
                                size="small"
                                disabled={!canResetRttWifiLevels || fetching}
                                onChange={(event) => setAccessPoint(event.target.value)}
                            >
                                <MenuItem value={"-"}>All</MenuItem>
                                {accessPoints.map(el => <MenuItem key={el.mac} value={el.mac}>{el.mac}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid2>}
                <Grid2 size={12}>
                    <Alert severity="info">
                        <Typography>
                            <strong>Selected Mode Use Case: </strong> {mode === modes.DEVICE ?
                                <span>Device was moved to/from another group</span> :
                                <span>Access point position changed, moved to another group, deleted...</span>}
                        </Typography>
                        {mode === modes.ACCESS_POINT && <Typography variant="caption">
                            * Only Data collected from the selected Access Points in this group will be affected
                        </Typography>}
                    </Alert>
                </Grid2>
                <Grid2 container spacing={2} alignItems="center">
                    {confirmation ? <>
                        {
                            (((mode === modes.DEVICE && androidID === "-") ||
                                (mode === modes.ACCESS_POINT && accessPoint === "-"))) && (
                                <Grid2 size={12}>
                                    <Alert severity="warning">
                                        All WiFi heatmap data collected from {mode === modes.DEVICE ? <strong>All Devices</strong> : <strong>All Access Points</strong>} will be lost
                                    </Alert>
                                </Grid2>
                            )}
                        <Grid2>
                            <Typography>Are you sure ? </Typography>
                        </Grid2>
                        <Grid2>
                            <Button
                                endIcon={<CancelIcon />}
                                color="secondary"
                                variant="contained"
                                size="small"
                                disabled={fetching}
                                onClick={() => setConfirmation(false)}
                            >
                                Cancel
                            </Button>
                        </Grid2>
                        <Grid2>
                            <LoadingButton
                                variant="contained"
                                color="error"
                                size="small"
                                loadingPosition="end"
                                loading={fetching}
                                disabled={!canResetRttWifiLevels}
                                endIcon={<DeleteIcon />}
                                onClick={handleDeleteWifiHeatmapData}
                            >
                                Delete
                            </LoadingButton>
                        </Grid2>
                    </>
                        :
                        <Grid2>
                            <Button
                                endIcon={<DeleteIcon />}
                                color="warning"
                                variant="contained"
                                size="small"
                                onClick={() => setConfirmation(true)}
                            >
                                Delete
                            </Button>
                        </Grid2>}
                </Grid2>
            </Grid2>
        </CardContent>
    </Card >
}

export default WifiHeatmapSettings;