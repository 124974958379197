import SignalWifi0BarOutlined from '@mui/icons-material/SignalWifi0BarOutlined';
import SignalWifi1BarOutlined from '@mui/icons-material/SignalWifi1BarOutlined';
import SignalWifi2BarOutlined from '@mui/icons-material/SignalWifi2BarOutlined';
import SignalWifi3BarOutlined from '@mui/icons-material/SignalWifi3BarOutlined';
import SignalWifi4BarOutlined from '@mui/icons-material/SignalWifi4BarOutlined';
import PermScanWifiOutlined from '@mui/icons-material/PermScanWifiOutlined';

const WifiLevel = (props) => {
    const { level } = props;
    const levels = [
        SignalWifi0BarOutlined,
        SignalWifi1BarOutlined,
        SignalWifi2BarOutlined,
        SignalWifi3BarOutlined,
        SignalWifi4BarOutlined,
    ];
    const WifiIcon = levels[level] || PermScanWifiOutlined;
    return <WifiIcon color={Number.isFinite(level) ? (level < 2 ? "error": "primary"): ''} {...props} />;
}
export default WifiLevel;