import React, {
    useState,
    useEffect,
    useMemo
} from 'react';
import {
    Grid2,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    ClickAwayListener,
    LinearProgress,
    Divider,
    Tooltip,
} from '@mui/material';
import { DataGridPro, useGridApiRef, GridToolbar } from '@mui/x-data-grid-pro';
import { formatTimeDate } from 'utils/timeFormat';
import { getAllEventsByDate } from 'utils/api';
import { useSelector, useDispatch } from 'react-redux'
import { updateAllDevicesAllEventsAction } from 'redux/events/events.actions';
import usePreferences from 'hooks/usePreferences';
import InfoIcon from '@mui/icons-material/Info';
import HtmlTooltip from 'components/HtmlTooltip';
import RenderObject from 'components/RenderObject';
import Authorized from 'components/Authorized'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjsExt from 'utils/dayjsExt';


const preferencesID = "pages-logs-eventlogbydate";
const EventLogByDate = () => {
    const [dates, setDates] = useState([new Date(Date.now() - 1000 * 60 * 60 * 24 * 7), new Date()]);
    const [dataDates, setDataDates] = useState([new Date(Date.now() - 1000 * 60 * 60 * 24 * 7), new Date()]);
    const [totalEvents, setTotalEvents] = useState(0);
    const [fetching, setFetching] = useState(false);
    const { preferences, updatePreferences } = usePreferences();
    const [selectedGroupID, setSelectedGroupID] = useState('-');
    const initialState = useMemo(() => preferences[preferencesID] || {}, [preferences]);
    const events = useSelector((state) => state.events.allDevicesEvents || []);
    const dispatch = useDispatch();

    useEffect(() => {
        const [s, e] = dates;
        if (!s || !e || isNaN(s?.getTime()) || isNaN(e?.getTime())) return;
        const sdate = dayjsExt(s).startOf('day').toISOString();
        const edate = dayjsExt(e).endOf('day').toISOString();
        setFetching(true);
        const fetchCall = getAllEventsByDate({ sdate, edate, group: selectedGroupID === '-' ? null : selectedGroupID })
            .then(resp => {
                setFetching(false);
                if (resp.startDate && resp.endDate) {
                    if (new Date(s).getTime() !== new Date(resp.startDate).getTime() || new Date(e).getTime() !== new Date(resp.endDate).getTime()) {
                        setDataDates([new Date(resp.startDate), new Date(resp.endDate)]);
                    }
                }
                dispatch(updateAllDevicesAllEventsAction(Array.isArray(resp.events) ? resp.events : []));
                if (Number.isInteger(resp.total)) setTotalEvents(resp.total);
            });
        return () => {
            fetchCall.cancel();
        }
    }, [dispatch, dates, selectedGroupID]);
    const apiRef = useGridApiRef();
    const groups = useSelector((state) => state.groups.groups);
    const groupMap = useMemo(() => {
        const _groupMap = {};
        groups.forEach(group => {
            group.devices.forEach(el => _groupMap[el] = group.name);
        })
        return _groupMap;
    }, [groups]);

    const _columns = useMemo(() => {
        return [
            {
                field: "date",
                headerName: "Date",
                width: 175,
                valueFormatter: (value) => formatTimeDate(value),
                editable: false,
            },
            {
                field: "ipaddr",
                headerName: "IP Address",
                width: 200,
                editable: false,
                sortable: true,
            },
            {
                field: "word",
                headerName: "Message",
                width: 200,
                editable: false,
                sortable: true,
                renderCell: (params) => params.row.word ? <Tooltip title={params.row.word}><Typography>{params.row.word}</Typography></Tooltip> : '',
            },
            {
                field: 'group',
                headerName: "Group",
                width: 130,
                valueGetter: (value, row) => groupMap[row.androidID] || '',
                editable: false,
            },
            {
                field: "messageID",
                headerName: "Message ID",
                width: 200,
                editable: false,
                sortable: true,
            },
            {
                field: "level",
                headerName: "Level",
                width: 200,
                editable: false,
                sortable: true,
            },
            {
                field: "url",
                headerName: "URL",
                width: 200,
                editable: false,
                sortable: true,
            },
            {
                field: "hostname",
                headerName: "Host Name",
                width: 200,
                editable: false,
                sortable: true,
            },
            {
                field: "value",
                headerName: "License Count",
                width: 200,
                editable: false,
                sortable: true,
            },
            {
                field: "androidID",
                headerName: "Android ID",
                width: 200,
                editable: false,
                sortable: true,
            },
            {
                field: "isConnected",
                headerName: "Connected to Wifi",
                width: 200,
                editable: false,
                sortable: true,
            },
            {
                field: "id",
                headerName: "ID",
                width: 250,
                editable: false,
                sortable: true,
            },
            {
                field: "details",
                headerName: "Details",
                width: 140,
                editable: false,
                align: "center",
                valueGetter: (value, row) => row.properties ? 1 : 0,
                renderCell: (params) => params.row.properties ? <HtmlTooltip title={<RenderObject object={params.row.properties} name="Details" />}><InfoIcon color="primary" /></HtmlTooltip> : ''
            },
        ];
    }, [groupMap])

    const handleClickAway = () => {
        if (apiRef.current && apiRef.current.setRowSelectionModel) {
            apiRef.current.setRowSelectionModel([])
        }
    }

    const columns = useMemo(() => {
        let error = false;
        if (initialState &&
            initialState.columns &&
            initialState.columns.cols) {
            const cols = new Array(_columns.length);
            _columns.forEach((el) => {
                if (!initialState.columns.cols[el.field]) {
                    error = true;
                    return;
                }
                cols[initialState.columns.cols[el.field].order] = { ...el, width: initialState.columns.cols[el.field].width }
            })
            if (!error) return cols;
        }
        return _columns;
    }, [initialState, _columns]);
    const eventsInfos = useMemo(() => {
        const startDate = dayjsExt(dataDates[0]).startOf('day').toISOString();
        const endDate = dayjsExt(dataDates[1]).endOf('day').toISOString();
        const truncated = totalEvents > events.length;
        return { startDate, endDate, truncated }
    }, [dataDates, totalEvents, events.length])

    const handleDatePickerChange = (newValue) => {
        if (!Array.isArray(newValue)) return;
        if (!dayjsExt.isDayjs(newValue[0]) || !dayjsExt.isDayjs(newValue[1])) return;
        const epochStart = newValue[0].valueOf();
        const epochEnd = newValue[1].valueOf();
        if (isNaN(epochStart) || isNaN(epochEnd) || epochStart > epochEnd) return;
        const startYear = newValue[0].toDate().getFullYear();
        setDates([
            startYear < 2010 ? new Date("2010-01-01") : newValue[0].toDate(),
            epochEnd > Date.now() ? new Date() : newValue[1].toDate()
        ]);
    }

    const datepickerDates = useMemo(() => {
        return [
            dayjsExt(dates[0] || new Date("2010-01-01")),
            dayjsExt(dates[1] || new Date()),
        ]
    }, [dates])

    return (
        <Grid2 container spacing={6}>
            {eventsInfos.truncated && <Grid2 size={12}>
                <Typography variant="body2" color="warning">
                    * Data is truncated
                    <HtmlTooltip title={<Grid2 container spacing={2}>
                        <Grid2 size={12}>
                            <Typography variant="h6">
                                Data is truncated
                            </Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Divider />
                        </Grid2>
                        <Grid2 size={12}>
                            <Typography variant="body1" gutterBottom>
                                The requested data is too large and could break your browser
                            </Typography>
                            <Typography variant="body2">
                                <strong>Tip:</strong> Try to filter by group or reduce the time of the window
                            </Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Typography>
                                Total Items For selected Period: <strong>{totalEvents}</strong>
                            </Typography>
                            <Typography>
                                Loaded Items For selected Period: <strong>{events.length}</strong>
                            </Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Typography>
                                Truncated Data Period Start: <strong>{eventsInfos.startDate}</strong>
                            </Typography>
                            <Typography>
                                Truncated Data Period End: <strong>{eventsInfos.endDate}</strong>
                            </Typography>
                        </Grid2>
                    </Grid2>}>
                        <InfoIcon color="primary" />
                    </HtmlTooltip>
                </Typography>
            </Grid2>}
            <Grid2>
                <DateRangePicker
                    onChange={handleDatePickerChange}
                    disableFuture
                    format={"MM-DD-YYYY"}
                    formatDensity="dense"
                    minDate={dayjsExt("2010-01-01")}
                    maxDate={dayjsExt()}
                    localeText={{ start: "Start", end: 'End' }}
                    value={datepickerDates}
                    slots={{
                        fieldSeparator: () => <Box sx={{ mx: 2 }}> to </Box>
                    }}
                    slotProps={{
                        textField: {
                            size: "small",
                            sx: { width: 120 }
                        }
                    }}
                />
            </Grid2>
            <Grid2>
                <Authorized permission="group.read">
                    <FormControl >
                        <InputLabel id="select-group-label">Group</InputLabel>
                        <Select
                            labelId="select-group-label"
                            id="select-group"
                            value={selectedGroupID}
                            label="Group"
                            size="small"
                            onChange={(e) => setSelectedGroupID(e.target.value)}
                        >
                            <MenuItem value="-">All</MenuItem>
                            {groups.map(el => <MenuItem key={el._id} value={el._id}>{el.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Authorized>
            </Grid2>

            <Grid2 size={12}>
                <div style={{ height: 1200, width: "100%" }}>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <DataGridPro
                            onStateChange={(state) => {
                                updatePreferences(preferencesID, {
                                    density: state.density,
                                    sorting: {
                                        sortModel: state.sorting.sortModel,
                                    },
                                    filter: {
                                        filterModel: state.filter.filterModel
                                    },
                                    columns: {
                                        columnVisibilityModel: state.columns.columnVisibilityModel,
                                        cols: apiRef.current.getAllColumns().map(({ field, width }, index) => ({ field, order: index, width })).reduce((p, c) => ({ ...p, [c.field]: c }), {}),
                                    }
                                })
                            }}
                            initialState={initialState}
                            apiRef={apiRef}
                            rows={events}
                            columns={columns}
                            getRowId={(row) => row._id}
                            loading={fetching}
                            slots={{
                                toolbar: GridToolbar,
                                loadingOverlay: LinearProgress,
                            }}
                        />
                    </ClickAwayListener>
                </div>
            </Grid2>
        </Grid2>
    );
}

export default EventLogByDate;