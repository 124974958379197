import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import USER_TYPE from 'constants/userType';
import { socketEmitters } from 'utils/socket';
import clientSettingsType from 'constants/clientSettingsType';

const firstMountTs = Date.now();
const EMIT_EVENT_SOCKET_READY_TIME = 5000;
const URLMonitor = () => {
    const { type } = useAuth();
    const location = useLocation();
    const currentPath = location.pathname;
    useEffect(() => {
        if (type !== USER_TYPE.ADMIN) return;
        const ts = Date.now();
        const socketWillBeReadyInMs = Math.max(0, (firstMountTs + EMIT_EVENT_SOCKET_READY_TIME) - ts)
        setTimeout(() => {
            socketEmitters.emitUpdateClientSettings({
                [clientSettingsType.USER_CURRENT_OPEN_PAGE_PATH.name]: currentPath
            })
        }, socketWillBeReadyInMs);
    }, [currentPath, type])
    return <></>
}

export default URLMonitor;

