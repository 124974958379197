import React from 'react';
import {
  Card as MuiCard,
  CardContent,
  CardHeader
} from "@mui/material";
import { styled } from '@mui/system';
import { spacing } from "@mui/system";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import RoomIcon from '@mui/icons-material/Room';
import KeyIcon from '@mui/icons-material/Key';
import useAuth from 'hooks/useAuth';
import DashalyticsID from './DashalyticsID';
import Authorized from 'components/Authorized';

const Card = styled(MuiCard)(spacing);

const CompanyDetails = () => {
  const { compAddr, compZip, compPhone } = useAuth();
  return <Card sx={{ my: 6 }}>
    <CardHeader
      title="Company details"
    />
    <CardContent>
      <List dense>
        {compAddr && <ListItem>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            primary={compAddr}
          />
        </ListItem>}
        {compZip && <ListItem>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText
            primary={compZip}
          />
        </ListItem>}
        {compPhone && <ListItem>
          <ListItemIcon>
            <RoomIcon
            />
          </ListItemIcon>
          <ListItemText
            primary={compPhone}
          />
        </ListItem>}
        <Authorized permission="lads.readPostEventUUID"><ListItem>
          <ListItemIcon>
            <KeyIcon />
          </ListItemIcon>
          <ListItemText
            primary={<DashalyticsID />}
          />
        </ListItem>
        </Authorized>
      </List>
    </CardContent>
  </Card >
}
export default CompanyDetails;