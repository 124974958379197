import { useMemo } from 'react';
import {
    Grid2,
    FormControlLabel,
    Switch,
    Typography,
    Chip,
    Box,
    Tooltip
} from '@mui/material';
import { orange, red, blue, pink, purple, grey } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import RouterIcon from '@mui/icons-material/Router';
import { useSelector } from 'react-redux';
import Slider from 'components/Slider';
import HTMLTooltip from 'components/HtmlTooltip';
import usePreferences from 'hooks/usePreferences';

const colors = [blue[500], orange[500], red[500], grey[500], pink[500], purple[500], blue[200], orange[200], red[200], grey[200], pink[200], purple[200]];
const getColorFromMac = (mac) => {
    if (!mac) return '#000000';
    let i = 0;
    `${mac}`.split('').forEach((el) => {
        i += el.charCodeAt(0);
    })
    return colors[i % colors.length];
}

const MapOptions = ({ mapOptions, updateMapOptions, groupDevices }) => {
    const preferencesID = "rtt-settings-uisettings";
    const { updatePreferences } = usePreferences();
    const allAccessPoints = useSelector((state) => state.rtt.accessPoints || []);
    const wifiAccessPoints = useMemo(() => allAccessPoints.filter(el => el.type === "RTT").sort((a, b) => (a.y - b.y)).sort((a, b) => (a.x - b.x)), [allAccessPoints]);
    const allDevices = useSelector(state => state.devices || []);
    const devicesNamesMap = useMemo(() => allDevices.reduce((a, c) => ({ ...a, [c.androidID]: c.name || c.androidID }), {}), [allDevices]);
    const {
        showWiFiLevels,
        accessPoints = {},
        devices,
        opacity
    } = mapOptions;
    

    const handleUpdateSelectedDevices = (androidID) => {
        let _devices = { ...(devices || {}) };
        if (!_devices || Object.keys(_devices).length === 0) {
            _devices = allDevices.reduce((a, c) => ({ ...a, [c.androidID]: true }), {});
        }
        _devices[androidID] = !_devices[androidID];
        updateMapOptions({ ...mapOptions, devices: _devices })
    }
    const handleUpdateSelectedAccessPoints = (mac) => {
        let _accessPoints = { ...(accessPoints || {}) };
        if (!_accessPoints || Object.keys(_accessPoints).length === 0) {
            _accessPoints = allAccessPoints.reduce((a, c) => ({ ...a, [c.mac]: true }), {});
        }
        _accessPoints[mac] = !_accessPoints[mac];
        updateMapOptions({ ...mapOptions, accessPoints: _accessPoints })
    }
    const handleUpdateOpacity = (opacity) => {
        if (opacity < 0 || opacity > 100) return;
        updatePreferences(preferencesID, { heatMapOpacity: opacity });
        updateMapOptions({ ...mapOptions, opacity })
    }
    return <Grid2 container spacing={6}>
        <Grid2 size={{xs: 6, lg:12}}>
            <Typography variant='body2' color={opacity < 10 ? "error" : "inherit"}>Opacity {opacity < 10 && <Tooltip title="Heatmap maybe invisible (opacity too low)"><InfoIcon color="primary" size="small" /></Tooltip>}</Typography>
            <Box sx={{ width: { xs: 180, lg: '100%' } }}>
                <Slider
                    valueLabelDisplay="auto"
                    min={0}
                    max={100}
                    value={opacity}
                    onChange={(e, value) => handleUpdateOpacity(value)}
                    valueLabelFormat={(value) => `${value}%`}
                    labelStyling={{
                        width: 30,
                        height: 30
                    }}
                />
            </Box>
        </Grid2>
        <Grid2 size={{xs: 6, lg:12}}>
            <FormControlLabel
                control={
                    <Switch size="small" checked={showWiFiLevels} onChange={(e) => updateMapOptions({ ...mapOptions, showWiFiLevels: e.target.checked })} />
                }
                label="Show WiFi Levels"
            />
        </Grid2>
        <Grid2 size={12} container spacing={1}>
            <Grid2 size={12}>
                <Typography variant="body1" gutterBottom>Access Points</Typography>
            </Grid2>
            {wifiAccessPoints.map((el) => <Grid2 key={el.mac}>
                <Chip
                    size="small"
                    onClick={() => handleUpdateSelectedAccessPoints(el.mac)}
                    sx={{
                        fontFamily: 'Monospace',
                    }}
                    color="secondary"
                    variant={accessPoints[el.mac] ? "filled" : "outlined"}
                    icon={<RouterIcon
                        style={{
                            color: getColorFromMac(el.mac)
                        }}
                    />}
                    label={el.mac}
                />
            </Grid2>)}
        </Grid2>
        <Grid2 size={12} container spacing={1}>
            <Grid2 size={12}>
                <Typography variant="body1" gutterBottom>Devices</Typography>
            </Grid2>
            {groupDevices.map((androidID) => <Grid2 key={androidID}>
                <HTMLTooltip
                    title={devicesNamesMap[androidID] || androidID}>
                    <Chip
                        size="small"
                        onClick={() => handleUpdateSelectedDevices(androidID)}
                        sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            width: 100,
                            whiteSpace: 'nowrap',
                        }}
                        color="secondary"
                        variant={devices[androidID] ? "filled" : "outlined"}
                        label={devicesNamesMap[androidID] || androidID}
                    />
                </HTMLTooltip>
            </Grid2>)}
        </Grid2>
    </Grid2>
}

export default MapOptions;