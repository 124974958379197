import React from "react";
import { styled } from '@mui/system';
import {
  Box,
  Chip,
  Container,
  Grid2,
  Typography
} from "@mui/material";
import { spacing } from "@mui/system";
import THEMES from "constants/themes";
const Wrapper = styled(Box)`
  ${spacing};
  background: ${(props) => props.theme.palette.background.paper};
  text-align: center;
`;

const DemoContent = styled(Box)(spacing);
const DemoImage = styled('img')`
  max-width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 4px 12px 0 rgba(18, 38, 63, 0.125);
  transition: 0.15s ease-in-out;
  border-radius: 4px;
  &:hover {
    transform: scale(1.0325);
  }
`;

const DemoChip = styled(Chip)`
  background-color: ${(props) => props.theme.palette.secondary.main};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-top: -16px;
  padding: 3px 0;
  span {
    padding-left: ${(props) => props.theme.spacing(1.375)};
    padding-right: ${(props) => props.theme.spacing(1.375)};
  }
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const Variant = ({ theme, title, img, isNew = false }) => {
  return (
    <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 4 }} >
      <DemoContent px={2}>
        <DemoImage
          alt={`${title} - React Admin Template`}
          src={`/static/img/screenshots/${img}.jpg`}
        />
        <Box sx={{ mb: 3 }} />
        <Typography variant="h6">
          {title} {isNew && <DemoChip label="New" />}
        </Typography>
      </DemoContent>
    </Grid2>
  );
};

function Demos() {
  return (
    <Wrapper pt={16} pb={20} id="demos">
      <Container>
        <TypographyOverline variant="body2" gutterBottom>
          Benefits
        </TypographyOverline>
        <Typography variant="h2" component="h3" gutterBottom>
          Visualize your production environment in real-time.
        </Typography>
        <Box sx={{ mb: 8 }} />
        <Grid2 container spacing={10}>
          <Variant theme={THEMES.GREEN} title="Real-Time Data" img="dashalytics-home" />
          <Variant theme={THEMES.DEFAULT} title="Geo Fencing" img="geo-fencing" />
          <Variant theme={THEMES.BLUE} title="Locations" img="positioning" />
          <Variant theme={THEMES.DARK} title="Messaging" img="chat" isNew />
          <Variant theme={THEMES.INDIGO} title="Productivity" img="productivity" />
          <Variant theme={THEMES.LIGHT} title="Diagnostics" img="wi-fi-drops" />
        </Grid2>
      </Container>
    </Wrapper>
  );
}

export default Demos;