import React, { useState } from 'react';
import { styled } from '@mui/system';
import {
    Card as MuiCard,
    CardContent,
    Grid2,
    TextField as MuiTextField,
    Typography,
    CardHeader,
    Checkbox,
    FormControlLabel,
    Button,
} from "@mui/material";
import { toast } from "react-toastify";
import { spacing } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { updateGlobalDecryptionPasswordAction } from "redux/system/system.actions";
import useEncryption from 'hooks/useEncryption';

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);

const EncryptionGlobal = () => {
    const { initEncryption } = useEncryption();
    const dispatch = useDispatch();
    const globalPassword = useSelector((state) => state.system.decryptionPassword);
    const [password, setPassword] = useState(globalPassword);
    const [saveInLocalStorage, setSaveInLocalStorage] = useState(false);
    const handleSaveEncryptionPassword = async () => {
        try {
            await initEncryption({ password });
            dispatch(updateGlobalDecryptionPasswordAction({ password, saveInLocalStorage }));
        }
        catch (error) {
            if (error.message) toast.error(error.message);
            else toast.error("Error: Something went wrong");
        }
    }
    const handleClearEncryptionPassword = async () => {
        setPassword(null);
        dispatch(updateGlobalDecryptionPasswordAction({ password: null }));
    }

    return <Card sx={{ my: 2 }}>
        <CardHeader title="Global decryption password" />
        <CardContent>
            <Grid2 container sx={{ mb: 1 }}>
                <Grid2>
                    <TextField
                        label="Type your password"
                        value={password || ""}
                        onChange={(e) => setPassword(e.target.value)}
                        variant="outlined"
                        type="password"
                    />
                </Grid2>
            </Grid2>
            <FormControlLabel
                control={<Checkbox onChange={(e) => setSaveInLocalStorage(e.target.checked)} name="rememberMe" checked={saveInLocalStorage} color="primary" />}
                label="save in this browser"
            />
            <Grid2 container spacing={3}>
                <Grid2>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSaveEncryptionPassword}
                        disabled={password === globalPassword || !password}
                    >Save Password
                    </Button>
                </Grid2>
                <Grid2>
                    <Button
                        type="submit"
                        variant="contained"
                        color="error"
                        onClick={handleClearEncryptionPassword}
                        disabled={!globalPassword}
                    >Clear Password</Button>
                </Grid2>
            </Grid2>
            <Typography variant="body2" gutterBottom>
                This password is used to decrypt encrypted messages by your lads system
            </Typography>
            {password && saveInLocalStorage && <Typography variant="body2" color="error" gutterBottom>
                Password is will be saved unencrypted in the browser
            </Typography>}
        </CardContent>
    </Card>
}
export default EncryptionGlobal;