import { Slider } from '@mui/material';
import { styled } from '@mui/system';

const PrettoSlider = styled(Slider)(({ theme, $labelStyling }) => ({
    color: theme.palette.secondary.main,
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 50,
        height: 50,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: theme.palette.secondary.main,
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
        ...$labelStyling
    },
}));


const CustomSlider = ({ labelStyling, ...props }) => {
    return <PrettoSlider
        {...props}
        $labelStyling={labelStyling || {}}
    />
}

export default CustomSlider;