import React from 'react';
import { Marker, Circle, Popup as leafletPopup } from 'react-leaflet'
import { SelectMarker } from './Icons/Icons';
import { styled } from '@mui/system';
const Popup = styled(leafletPopup)`
    .leaflet-popup-content-wrapper{
        background-color: ${props => props.theme.palette.background.paper};
        color: ${props => props.theme.palette.text.primary};
    }
    .leaflet-popup-tip {
        background-color: ${props => props.theme.palette.background.paper};
        color: ${props => props.theme.palette.text.primary};
    }
    .leaflet-popup-content{ 
        color: ${props => props.theme.palette.text.primary};
    }
`;
const fillBlueOptions = { fillColor: 'blue' };

const MapSelections = ({ selections }) => {
    return selections.map((selection, index) => {
        return (
            <React.Fragment key={index}>
                <Circle center={[selection.lat, selection.lon]} radius={selection.radius} pathOptions={fillBlueOptions} />
                <Marker position={[selection.lat, selection.lon]} icon={SelectMarker}>
                    <Popup>
                        {selection.label}
                    </Popup>
                </Marker>
            </React.Fragment>
        )
    })
}
export default MapSelections;