// value should be always string or number
const clientSettingsType = {
    UTS_AGENT_LOG_PID_FILTER:{
        name: "UTS_AGENT_LOG_PID_FILTER",
        type: "number",
        nullable: true,
    },
    USER_CURRENT_OPEN_PAGE_PATH:{
        name: "USER_CURRENT_OPEN_PAGE_PATH",
        type: "string"
    }
}

export default clientSettingsType;