import React, {
    useRef,
    useState
} from "react";
import { styled } from '@mui/system';
import {
    Avatar as MuiAvatar,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover as MuiPopover,
    SvgIcon,
    Tooltip,
    Typography,
    Chip
} from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;


const Avatar = styled(MuiAvatar)`
  background: ${props => props.theme.palette.warning.main}
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;


function NavbarBetaWatermark() {
    const ref = useRef(null);

    const [isOpen, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Tooltip title="Beta Server">
                <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
                    <Chip label="Beta" color="info" />
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <NotificationHeader p={2}>
                    <Typography variant="subtitle1" color="textPrimary">
                        Beta client for testing
                    </Typography>
                </NotificationHeader>
                <React.Fragment>
                    <List disablePadding>
                        <ListItem divider>
                            <ListItemAvatar>
                                <Avatar>
                                    <SvgIcon fontSize="small">
                                        <WarningAmberIcon />
                                    </SvgIcon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={"Warning"}
                                primaryTypographyProps={{
                                    variant: "subtitle2",
                                    color: "warning",
                                }}
                                secondary={"Changes in the Beta server will affect the production website (they share same backend)"}
                            />
                        </ListItem>
                    </List>
                </React.Fragment>
            </Popover>
        </React.Fragment>
    );
}

export default NavbarBetaWatermark;
