import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import {
    Drawer,
    IconButton,
    Button,
    Badge
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import useAuth from 'hooks/useAuth';
import ChatRooms from './ChatRooms';
import ChatRoomCard from './ChatRoomCard';
import ChatRoomDetails from './ChatRoomDetails';
import Messages from './Messages';
import ChatInput from './ChatInput';
import ChatRoomsManage from './ChatRoomsManage';
import ChatUsers from './ChatUsers';
import USER_TYPE from 'constants/userType';
import { imgURL } from "utils/http"
import useAuthorization from 'hooks/useAuthorization';

const MobileChatPageContainer = styled(Box)`
    height: 100%;
`


const Wrapper = styled(Box)`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas:
    'leftHeader header rightHeader'
    'messages messages messages'
    'input input input';
    gap: 10px;
    padding: 10px;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: auto 1fr auto;
`

const ChatRoomHeaderContainer = styled(Box)`
    grid-area: header;
    background-color: ${(props) => props.theme.palette.background.paper};
`
const ChatRoomMessagesContainer = styled(Box)`
    grid-area: messages;
    background-color: ${(props) => props.theme.palette.background.paper};
`
const ChatRoomMessageInputContainer = styled(Box)`
    grid-area: input;
    background-color: ${(props) => props.theme.palette.background.paper};
`

const ChatRoomInfosContainer = styled(Box)`
    background-color: ${(props) => props.theme.palette.background.paper};
    width: 280px;
    height: 100%;
`

const LeftHeader = styled(Box)`
    grid-area: leftHeader;
    background-color: ${(props) => props.theme.palette.background.paper};
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const RightHeader = styled(Box)`
    grid-area: rightHeader;
    background-color: ${(props) => props.theme.palette.background.paper};
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const RoomSelect = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`
const LeftDrawerWrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
`;

const ChatRoomsContainer = styled(Box)`
    background-color: ${(props) => props.theme.palette.background.paper};
    width: 280px;
    flex-grow: 1;
    min-height: 0;
`
const ChatRoomsManageWrapper = styled(Box)`

`


const LeftDrawer = ({ updateSelectedRoom, usersMap, roomID, isManageChatRoomsOpen, setIsManageChatRoomsOpen, isShowUsersMode, setIsShowUsersMode, chatRooms }) => (
    <LeftDrawerWrapper role="presentation">
        <ChatRoomsContainer>
            {isShowUsersMode || chatRooms.length === 0 ? <ChatUsers
                updateSelectedRoom={updateSelectedRoom}
                closeUserMode={() => setIsShowUsersMode(false)} /> :
                <ChatRooms
                    updateSelectedRoom={updateSelectedRoom}
                    usersMap={usersMap}
                    roomID={roomID} />}
        </ChatRoomsContainer>
        <ChatRoomsManageWrapper>
            <ChatRoomsManage
                isManageChatRoomsOpen={isManageChatRoomsOpen}
                setIsManageChatRoomsOpen={setIsManageChatRoomsOpen}
                isShowUsersMode={isShowUsersMode}
                setIsShowUsersMode={setIsShowUsersMode}
                roomID={roomID}
            />
        </ChatRoomsManageWrapper>
    </LeftDrawerWrapper>
);

const RightDrawer = ({ selectedRoomParticipants, selectedRoom, showDeviceProfileLink }) => (
    <ChatRoomInfosContainer>
        {selectedRoom && <ChatRoomDetails
            participants={selectedRoomParticipants}
            room={selectedRoom}
            showDeviceProfileLink={showDeviceProfileLink}
        />}
    </ChatRoomInfosContainer>
);



const Mobile = ({ roomID, updateSelectedRoom, showRoomList = true, showRoomDetails = true, showRoomHeader = true }) => {
    const { userID, type } = useAuth();
    const { isAuthorized } = useAuthorization();
    const canWriteChatMessages = isAuthorized("chatMessage.write");
    const canViewDeviceProfile = isAuthorized("page.deviceDetails");
    const [showLeftDrawer, setShowLeftDrawer] = useState(false);
    const [isShowUsersMode, setIsShowUsersMode] = useState(false);
    const [showRightDrawer, setShowRightDrawer] = useState(false);
    const [isManageChatRoomsOpen, setIsManageChatRoomsOpen] = useState(false);
    const users = useSelector((state) => state.chat.users);
    const onlineUsers = useSelector((state) => state.chat.onlineUsers);
    const onlineUsersMap = useMemo(() => onlineUsers.reduce((a, c) => ({ ...a, [c]: true }), {}), [onlineUsers]);
    const usersMap = useMemo(() => users.reduce((a, c) => ({
        ...a,
        [c._id]: {
            ...c,
            isOnline: !!onlineUsersMap[c._id],
            profilePicture: c.type === USER_TYPE.DEVICE ? ("/static/img/avatars/android.png") :
                (c.imageUrl ? imgURL + c.imageUrl : "/static/img/avatars/admin.png")
        }
    }), {}), [users, onlineUsersMap]);
    const chatRooms = useSelector(state => state.chat.chatRooms);
    const selectedRoom = useMemo(() => chatRooms.find(el => el._id === roomID), [roomID, chatRooms]);
    const toggleShowProfile = () => {
        setShowRightDrawer(showRightDrawer => !showRightDrawer);
    }
    const selectedRoomParticipants = useMemo(() => {
        if (!selectedRoom) return []
        return selectedRoom.participants
            .filter(el => usersMap[el])
            .map(el => usersMap[el]);
    }, [usersMap, selectedRoom]);
    const convs = useSelector((state) => state.chat.convs || []);
    const nNewMessages = useMemo(() => convs.filter(el => el.message.from !== userID && Array.isArray(el.message.seenBy) && !el.message.seenBy?.includes(userID)).length, [userID, convs]);
    const selectedRoomParticipantsMap = useMemo(() => {
        if (!selectedRoom) return {}
        return selectedRoom.participants.reduce((a, c) => ({ ...a, [c]: true }), {});
    }, [selectedRoom]);
    const devices = useSelector(state => state.devices);
    const nDvicesSupportHighPriority = useMemo(() => {
        return devices.filter(device => selectedRoomParticipantsMap[device.androidID] &&
            device.gswcb_version &&
            parseInt(device.gswcb_version.split(".")[0]) >= 2 &&
            parseInt(device.gswcb_version.split(".")[1]) >= 9 &&
            parseInt(device.gswcb_version.split(".")[2]) >= 134).length;
    }, [devices, selectedRoomParticipantsMap]);


    return <MobileChatPageContainer>
        <Drawer
            anchor="left"
            open={showLeftDrawer}
            onClose={() => setShowLeftDrawer(false)}
        >
            <LeftDrawer
                updateSelectedRoom={updateSelectedRoom}
                usersMap={usersMap}
                roomID={selectedRoom?._id}
                isManageChatRoomsOpen={isManageChatRoomsOpen}
                setIsManageChatRoomsOpen={setIsManageChatRoomsOpen}
                isShowUsersMode={isShowUsersMode}
                setIsShowUsersMode={setIsShowUsersMode}
                chatRooms={chatRooms}
            />
        </Drawer>
        <Drawer
            anchor="right"
            open={showRightDrawer}
            onClose={() => setShowRightDrawer(false)}
        >
            <RightDrawer
                selectedRoomParticipants={selectedRoomParticipants}
                selectedRoom={selectedRoom}
                showDeviceProfileLink={canViewDeviceProfile && type === USER_TYPE.ADMIN}
            />
        </Drawer>
        <Wrapper>
            {showRoomList && <LeftHeader>
                {nNewMessages ?
                    <IconButton onClick={() => setShowLeftDrawer(true)}>
                        <Badge badgeContent={nNewMessages} color="primary" >
                            <EmailIcon color="action" />
                        </Badge>
                    </IconButton>
                    :
                    <IconButton color="primary" onClick={() => setShowLeftDrawer(true)}>
                        <GroupIcon />
                    </IconButton>}
            </LeftHeader>}
            {showRoomHeader && <ChatRoomHeaderContainer>
                {selectedRoom && <ChatRoomCard participants={selectedRoomParticipants} room={selectedRoom} onClick={toggleShowProfile} />}
            </ChatRoomHeaderContainer>}
            {showRoomDetails && <RightHeader>
                <IconButton disabled={!selectedRoom} color="primary" onClick={() => setShowRightDrawer(true)}>
                    <InfoIcon />
                </IconButton>
            </RightHeader>}
            <ChatRoomMessagesContainer>
                {selectedRoom ? (
                    <Messages {...selectedRoom} participants={selectedRoomParticipants} />
                ) : (<RoomSelect>
                    <Button
                        variant="contained"
                        onClick={() => setShowLeftDrawer(true)}
                        endIcon={<GroupIcon />}
                    >
                        Select a ChatRoom
                    </Button>
                </RoomSelect>)}
            </ChatRoomMessagesContainer>
            <ChatRoomMessageInputContainer>
                {canWriteChatMessages && selectedRoom && <ChatInput roomID={selectedRoom._id} nDvicesSupportHighPriority={nDvicesSupportHighPriority} />}
            </ChatRoomMessageInputContainer>
        </Wrapper>
    </MobileChatPageContainer>

}

export default Mobile;