import React, { useState, useEffect, useMemo } from "react";
import { styled } from '@mui/system';
import {
    Typography,
    Grid2,
    Card,
    CardContent,
    Tooltip,
    Grow,
    IconButton,
    Button,
    Alert,
    Box
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { useSelector, useDispatch } from "react-redux"
import { updateDevicesAction } from "redux/devices/devices.actions";
import { removeGroupAction, updateSelectedGroup } from "redux/groups/groups.actions";
import { getDevices } from "utils/api";
import { http } from 'utils/http';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import CreateGroup from './CreateGroup';
import GroupSettingTable from './GroupSettingTable';
import Scan from '../settings/Scan';
import DevicesInGroup from './DevicesInGroup';
import Keyboard from '../settings/Keybaord';
import Alerts from '../settings/Alerts';
import Geofencing from '../settings/Geofencing';
import RTTMapPreView from './RTTMapPreView';
import KeyboardEvents from './KeyboardEvents';
import Badge from '../settings/Badge';
import Tunnel from '../settings/Tunnel';
import Authorized from "components/Authorized";
import settingType from "constants/settingType";

const noop = () => { }
const settingNameMap = {
    [settingType.SCAN]: "Scan",
    [settingType.RTT]: "RTT",
    [settingType.KEYBOARD]: "Keyboard",
    [settingType.ALERTS]: "Alerts",
    [settingType.GEOFENCING]: "Geofencing",
    [settingType.BADGE]: "Badge",
    [settingType.TUNNEL]: "Tunnel",

}
const AddAssignSetting = ({ type, openSettingsTab }) => {
    const settings = useSelector((state) => state.settings);
    const isSettingAvailable = useMemo(() => {
        return !!settings.find(el => el.type === type)
    }, [settings, type]);
    let settingName = useMemo(() => settingNameMap[type] || "Setting", [type]);


    return <Grid2 container spacing={6}>
        {isSettingAvailable && <Grid2 size={12}>
            <Typography variant="body1">Assign a <strong>{settingName}</strong> from existing ones Or</Typography>
        </Grid2>}
        <Grid2>
            <Button onClick={openSettingsTab} variant="contained" size="small">
                Create New {settingName} Setting
            </Button>
        </Grid2>
    </Grid2>
}

const CardsWrapperContainer = styled(Box)`
    display: grid;
    grid-template-areas: "scan-settings     alerts-settings     rtt-settings     "
                         "keyboard-events   alerts-settings     tunnel-settings   "
                         "badge-settings    geofence-settings   keyboard-settings";
    gap: ${(props) => props.theme.spacing(6)};
    grid-template-columns: 1fr 1fr 1fr;
    ${(props) => props.theme.breakpoints.down("lg")} {
        display: flex;
        flex-direction: column;
    }
    
`
const CardWrapper = ({ gridArea, children }) => {
    return <Card sx={{ gridArea }} variant="outlined">
        <CardContent sx={{ height: '100%' }}>
            {children}
        </CardContent>
    </Card>
}

function Groups({ openSettingsTab, handleSelectSetting }) {
    const selectedGroupID = useSelector((state) => state.groups.selectedGroup || '-');
    const [creating, setCreating] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const groups = useSelector((state) => state.groups.groups || []);
    const admins = useSelector(state => state.admins.admins || []);
    const settings = useSelector((state) => state.settings);
    const settingsMap = useMemo(() => {
        const _settingsMap = {};
        settings.forEach(setting => {
            _settingsMap[setting._id] = setting;
        });
        return _settingsMap;
    }, [settings]);
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchCalls = [
            getDevices({
                androidID: true,
                name: true,
                date: true,
                ipaddr: true,
                battery_level: true,
                gswcb_version: true,
                gswcb_config_name: true,
                gswcb_config_download_date: true,
                license_type: true,
                license_process: true,
                license_expiration_date: true,
                license_subscription_until: true,
                network_mac: true,
                display_dimensions: true,
                display_density: true,
                version_security_patch: true,
                version_sdk: true,
                version_release: true,
                build_model: true,
                build_brand: true,
                battery_health: true,
                battery_technology: true,
                battery_plugged: true,
                lat: true,
                long: true,
                radius: true,
            })
                .then(devices => dispatch(updateDevicesAction(devices || [])))
        ]
        return () => {
            fetchCalls.forEach(fetchCall => fetchCall.cancel());
        };
    }, [dispatch]);

    const handleDeleteGroup = async () => {
        try {
            const resp = await http.delete(`${process.env.REACT_APP_SERVER_URL}/api/v2/group/group/${selectedGroupID}`);
            if (resp && resp.data) {
                dispatch(updateSelectedGroup(null));
                dispatch(removeGroupAction(selectedGroupID));
                setDeleting(false);
            }
        } catch (error) {
            if (error?.response?.data?.message) toast.error(error.response.data.message);
            else toast.error("Something went wrong");
        }
    }

    const selectedGroup = useMemo(() => {
        return groups.find(group => group._id === selectedGroupID);
    }, [groups, selectedGroupID]);

    const adminName = useMemo(() => {
        if (!selectedGroup) return '';
        const admin = admins.find(el => el._id === selectedGroup.createdBy);
        return admin && admin.displayName ? admin.displayName : selectedGroup.createdBy;
    }, [selectedGroup, admins]);
    const selectedGroupName = useMemo(() => {
        return selectedGroup ? (selectedGroup.name || selectedGroup.description || "") : "";
    }, [selectedGroup]);

    return (
        <Grid2 container spacing={6}>
            <Grid2 size={12}>
                <Grid2>
                    <Typography component="h2" variant="h3" gutterBottom>
                        Groups
                    </Typography>
                </Grid2>
            </Grid2>
            <Grid2 size={12}>
                <Authorized every={["group.read", "setting.read"]}>
                    <GroupSettingTable />
                </Authorized>
            </Grid2>
            <Grid2 size={12} container justifyContent="space-between" alignItems="center">
                <Grid2>
                    <Authorized permission="group.create">
                        {!deleting && <>
                            {creating ? <Tooltip title="Cancel" >
                                <HighlightOffIcon color="warning" sx={{ m: 2 }} fontSize="large" role="button" onClick={() => setCreating(false)} />
                            </Tooltip> : <LoadingButton
                                size="small"
                                endIcon={<CreateNewFolderIcon />}
                                onClick={() => setCreating(true)}
                                loadingPosition="end"
                                variant="contained">
                                Create New Group
                            </LoadingButton>}
                        </>}
                    </Authorized>
                </Grid2>
                <Grid2>
                    {deleting && <Alert severity="error">
                        The Group <strong>{selectedGroupName}</strong> Will be deleteted!
                    </Alert>}
                </Grid2>
                <Grid2>
                    <Authorized permission="group.delete">
                        {!creating && <>
                            {!deleting && <LoadingButton
                                size="small"
                                endIcon={<DeleteIcon />}
                                onClick={() => setDeleting(true)}
                                loadingPosition="end"
                                color="error"
                                variant="contained"
                                disabled={selectedGroupID === '-'}
                            >
                                Delete  Group
                            </LoadingButton>}
                            {deleting && <IconButton color="error" onClick={handleDeleteGroup} >
                                <DeleteForeverIcon fontSize="large" />
                            </IconButton>}
                            {deleting && <IconButton color="warning" onClick={() => setDeleting(false)} >
                                <HighlightOffIcon fontSize="large" />
                            </IconButton>}
                        </>}
                    </Authorized>
                </Grid2>
            </Grid2>
            {creating && <Grow in={creating}>
                <Grid2 size={12}>
                    <CreateGroup setCreating={setCreating} />
                </Grid2>
            </Grow>}
            {selectedGroupID === '-' && <Grid2 size={12}>
                <Authorized permission="device.read">
                    <DevicesInGroup
                        selectedGroupID={selectedGroupID}
                        deleting={deleting}
                        creating={creating}
                    />
                </Authorized>
            </Grid2>}
            {selectedGroupID && selectedGroupID !== '-' && (
                <React.Fragment>
                    <Grid2 size={12}>
                        <Typography variant="h4">
                            {selectedGroup ? selectedGroup.name : ''}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Created By: <strong>{adminName}</strong>
                        </Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        <Authorized permission="device.read">
                            <DevicesInGroup
                                selectedGroupID={selectedGroupID}
                                deleting={deleting}
                                creating={creating}
                            />
                        </Authorized>
                    </Grid2>
                </React.Fragment>)}
            {selectedGroupID && selectedGroupID !== '-' && (
                <Grid2 size={12}>
                    <CardsWrapperContainer>
                        <Authorized permission="setting.read">
                            <CardWrapper gridArea="scan-settings">
                                {selectedGroup && selectedGroup.scanSetting ? (
                                    <>
                                        <Typography variant="h5" gutterBottom>Scan Settings</Typography>
                                        {settingsMap[selectedGroup.scanSetting] && <Typography sx={{ mt: 3 }}
                                            variant="body2"
                                            color="primary"
                                            gutterBottom>
                                            <strong>Settings: </strong>
                                            {settingsMap[selectedGroup.scanSetting].name}
                                            <EditIcon role="button" sx={{ mx: 1 }} onClick={() => handleSelectSetting(selectedGroup.scanSetting)} />
                                        </Typography>}
                                        <Scan properties={settingsMap[selectedGroup.scanSetting] || {}} editing={false} setProperties={noop} setIsValid={noop} />
                                    </>
                                )
                                    :
                                    (
                                        <AddAssignSetting type={settingType.SCAN} openSettingsTab={openSettingsTab} />
                                    )}
                            </CardWrapper>
                        </Authorized>
                        <Authorized every={["setting.read", "event.read"]}>
                            <CardWrapper gridArea="keyboard-events">
                                {selectedGroup && settingsMap[selectedGroup.keyboardSetting] && settingsMap[selectedGroup.keyboardSetting].keys ? <>
                                    <Typography variant="h5" gutterBottom>Keyboard Events</Typography>
                                    {settingsMap[selectedGroup.keyboardSetting] && <Typography variant="body2" color="primary" gutterBottom><strong>Settings: </strong> {settingsMap[selectedGroup.keyboardSetting].name} <EditIcon role="button" sx={{ mx: 1 }} onClick={() => handleSelectSetting(selectedGroup.keyboardSetting)} /></Typography>}
                                    <KeyboardEvents
                                        keyboardKeys={settingsMap[selectedGroup.keyboardSetting].keys}
                                        groupDevices={selectedGroup.devices || []}
                                    />
                                </> :
                                    (
                                        <AddAssignSetting type={settingType.KEYBOARD} openSettingsTab={openSettingsTab} />
                                    )}
                            </CardWrapper>
                        </Authorized>
                        <Authorized permission="setting.read">
                            <CardWrapper gridArea="keyboard-settings">
                                {selectedGroup && settingsMap[selectedGroup.keyboardSetting] && settingsMap[selectedGroup.keyboardSetting].keys ? (
                                    <>
                                        <Typography variant="h5" gutterBottom>Codes</Typography>
                                        {settingsMap[selectedGroup.keyboardSetting] && <Typography variant="body2" color="primary" sx={{ mb: 6 }}><strong>Settings: </strong> {settingsMap[selectedGroup.keyboardSetting].name} <EditIcon role="button" sx={{ mx: 1 }} onClick={() => handleSelectSetting(selectedGroup.keyboardSetting)} /></Typography>}
                                        <Keyboard properties={settingsMap[selectedGroup.keyboardSetting] || { keys: [], keyList: [] }} editing={false} setProperties={noop} setIsValid={noop} />
                                    </>
                                )
                                    :
                                    (
                                        <AddAssignSetting type={settingType.KEYBOARD} openSettingsTab={openSettingsTab} />
                                    )}
                            </CardWrapper>
                        </Authorized>
                        <Authorized permission="setting.read">
                            <CardWrapper gridArea="alerts-settings">
                                {selectedGroup && selectedGroup.alertsSetting ? (
                                    <>
                                        <Typography variant="h5" gutterBottom>Alerts Settings</Typography>
                                        {settingsMap[selectedGroup.alertsSetting] && <Typography variant="body2" color="primary" sx={{ mb: 6 }}><strong>Settings: </strong> {settingsMap[selectedGroup.alertsSetting].name} <EditIcon role="button" sx={{ mx: 1 }} onClick={() => handleSelectSetting(selectedGroup.alertsSetting)} /></Typography>}
                                        <Alerts properties={settingsMap[selectedGroup.alertsSetting] || {}} editing={false} setProperties={noop} setIsValid={noop} />
                                    </>
                                )
                                    :
                                    (
                                        <AddAssignSetting type={settingType.ALERTS} openSettingsTab={openSettingsTab} />
                                    )}
                            </CardWrapper>
                        </Authorized>
                        <Authorized every={["setting.read", "device.read", "page.outdoorPositioning"]}>
                            <CardWrapper gridArea="geofence-settings">
                                {selectedGroup && selectedGroup.geofencingSetting ? (
                                    <>
                                        <Typography variant="h5" gutterBottom>GeoFence</Typography>
                                        {settingsMap[selectedGroup.geofencingSetting] && <Typography variant="body2" color="primary" sx={{ mb: 3 }}><strong>Settings: </strong> {settingsMap[selectedGroup.geofencingSetting].name} <EditIcon role="button" sx={{ mx: 1 }} onClick={() => handleSelectSetting(selectedGroup.geofencingSetting)} /></Typography>}
                                        <Geofencing properties={settingsMap[selectedGroup.geofencingSetting] || {}} editing={false} setProperties={noop} setIsValid={noop} groupID={selectedGroupID} />
                                    </>
                                )
                                    :
                                    (
                                        <AddAssignSetting type={settingType.GEOFENCING} openSettingsTab={openSettingsTab} />
                                    )}
                            </CardWrapper>
                        </Authorized>
                        <Authorized every={["setting.read", "event.read"]}>
                            <CardWrapper gridArea="badge-settings">
                                {selectedGroup && selectedGroup.badgeSetting ? (
                                    <>
                                        <Typography variant="h5" gutterBottom>Badge</Typography>
                                        {settingsMap[selectedGroup.badgeSetting] && <Typography variant="body2" color="primary" sx={{ mb: 3 }}><strong>Settings: </strong> {settingsMap[selectedGroup.badgeSetting].name} <EditIcon role="button" sx={{ mx: 1 }} onClick={() => handleSelectSetting(selectedGroup.badgeSetting)} /></Typography>}
                                        <Badge properties={settingsMap[selectedGroup.badgeSetting] || {}} editing={false} setProperties={noop} setIsValid={noop} groupID={selectedGroupID} />
                                    </>
                                )
                                    :
                                    (
                                        <AddAssignSetting type={settingType.BADGE} openSettingsTab={openSettingsTab} />
                                    )}
                            </CardWrapper>
                        </Authorized>
                        <Authorized every={["setting.read", "page.indoorPositioning"]}>
                            <CardWrapper gridArea="rtt-settings">
                                {selectedGroup && selectedGroup.rTTSetting ? (
                                    <>
                                        <Typography variant="h5" gutterBottom>Indoor Tracking</Typography>
                                        {settingsMap[selectedGroup.rTTSetting] && <Typography variant="body2" color="primary" sx={{ mb: 5 }}><strong>Settings: </strong> {settingsMap[selectedGroup.rTTSetting].name} <EditIcon role="button" sx={{ mx: 1 }} onClick={() => handleSelectSetting(selectedGroup.rTTSetting)} /></Typography>}
                                        <RTTMapPreView rttSettings={settingsMap[selectedGroup.rTTSetting] || {}} ignoredDevices={[]} groupDevices={selectedGroup.devices || []} />
                                    </>
                                )
                                    :
                                    (
                                        <AddAssignSetting type={settingType.RTT} openSettingsTab={openSettingsTab} />
                                    )}
                            </CardWrapper>
                        </Authorized>
                        <Authorized every={["setting.read"]}>
                            <CardWrapper gridArea="tunnel-settings">
                                {selectedGroup && selectedGroup.tunnelSetting ? (
                                    <>
                                        <Typography variant="h5" gutterBottom>Tunnel</Typography>
                                        {settingsMap[selectedGroup.tunnelSetting] && <Typography variant="body2" color="primary" sx={{ mb: 5 }}><strong>Settings: </strong> {settingsMap[selectedGroup.tunnelSetting].name} <EditIcon role="button" sx={{ mx: 1 }} onClick={() => handleSelectSetting(selectedGroup.tunnelSetting)} /></Typography>}
                                        <Tunnel settingID={selectedGroup.tunnelSetting} properties={settingsMap[selectedGroup.tunnelSetting] || {}} editing={false} setProperties={noop} setIsValid={noop} />
                                    </>
                                )
                                    :
                                    (
                                        <AddAssignSetting type={settingType.TUNNEL} openSettingsTab={openSettingsTab} />
                                    )}
                            </CardWrapper>
                        </Authorized>
                    </CardsWrapperContainer>
                </Grid2>)}
        </Grid2>
    )
};

export default Groups;
