import { useEffect, useState, useMemo } from 'react';
import {
    Box,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Tooltip,
    Menu,
    Badge
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedBadge } from 'redux/badges/badges.actions';
import usePreferences from 'hooks/usePreferences';
import { useLocation } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import { isValidUserBadge } from 'utils/badge';

const getNoUserTitle = (path) => {
    if (/^\/productivity$/.test(path)) return "All Users";
    return "All Users"
}
const UserBadgeSelector = () => {
    const router = useLocation();
    const currentRoute = router.pathname;
    const preferencesID = "global-user-badge-selector";
    const { preferences, updatePreferences } = usePreferences();
    const dispatch = useDispatch();
    const initialState = preferences[preferencesID];
    const selectedGroupID = useSelector((state) => state.groups.selectedGroup || null);
    const selectedBadgeID = useSelector((state) => state.badges.selectedBadge || null);

    const badges = useSelector(state => state.badges.badges);
    const groups = useSelector(state => state.groups.groups);
    const settings = useSelector(state => state.settings);
    const selectedGroupBadgeSetting = useMemo(() => {
        if (!selectedGroupID) return null;
        const selectedGroup = groups.find(group => group._id === selectedGroupID)
        if (!selectedGroup || !selectedGroup.badgeSetting) return null;
        return settings.find(setting => setting._id === selectedGroup.badgeSetting);
    }, [groups, settings, selectedGroupID]);
    const validGroupBadges = useMemo(() => {
        if (!selectedGroupBadgeSetting) return [];
        return badges.filter(badge => isValidUserBadge({ ...selectedGroupBadgeSetting, testCode: badge.badgeID }))
    }, [badges, selectedGroupBadgeSetting]);
    const badgesMap = useMemo(() => badges.reduce((a, c) => ({ ...a, [c._id]: c }), {}), [badges]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [anchorMenu, setAnchorMenu] = useState(null);
    useEffect(() => {
        // this must run only first time
        if (!firstLoad || !initialState) return;
        dispatch(updateSelectedBadge(initialState.selectedBadgeID === '-' ? null : initialState.selectedBadgeID));
        setFirstLoad(false);
    }, [dispatch, initialState, firstLoad]);

    useEffect(() => {
        if (!validGroupBadges.find(el => el._id === selectedBadgeID)) {
            dispatch(updateSelectedBadge(null));
        }
    }, [validGroupBadges, selectedBadgeID, dispatch])

    const handleChange = (selected) => {
        dispatch(updateSelectedBadge(selected === '-' ? null : selected));
        setAnchorMenu(null);
        updatePreferences(preferencesID, { selectedBadgeID: selected });
    };
    useEffect(() => {
        if ((!selectedGroupID || !/(^\/productivity$)/.test(currentRoute)) && selectedBadgeID) {
            dispatch(updateSelectedBadge(null));
        }
    }, [currentRoute, selectedBadgeID, selectedGroupID, dispatch])

    const toggleMenu = (event) => {
        setAnchorMenu(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorMenu(null);
    };

    if (validGroupBadges.length === 0 && !selectedBadgeID) return null;
    if (!selectedGroupID) return null;
    if (!/(^\/productivity$)/.test(currentRoute)) return null;

    return <>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
            <Tooltip title={selectedBadgeID ? (badgesMap[selectedBadgeID]?.userName || selectedBadgeID) : getNoUserTitle(currentRoute)}>
                <Badge
                    aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                    size="large"
                    color="secondary"
                    badgeContent={selectedBadgeID ? 1 : 0}
                >
                    <PersonIcon />
                </Badge>
            </Tooltip>
            <Menu
                id="menu-appbar"
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={closeMenu}
            >
                <MenuItem value={'-'} selected={selectedBadgeID === null} onClick={() => handleChange("-")}>{getNoUserTitle(currentRoute)}</MenuItem>
                {validGroupBadges.map(badge => <MenuItem key={badge._id} selected={selectedBadgeID === badge._id} onClick={() => handleChange(badge._id)}>{badge.userName || badge.badgeID}</MenuItem>)}
            </Menu>
        </Box>
        <Box sx={{ minWidth: 160, display: { xs: "none", md: "block" } }}>
            <FormControl fullWidth>
                <InputLabel id="select-user-badge-global-label">Selected User</InputLabel>
                <Select
                    labelId="select-user-badge-global-label"
                    id="select-user-global"
                    value={selectedBadgeID || '-'}
                    label="Selected User"
                    onChange={(e) => handleChange(e.target.value)}
                    size="small"
                >
                    <MenuItem value={'-'}>{getNoUserTitle(currentRoute)}</MenuItem>
                    {validGroupBadges.map(badge => <MenuItem key={badge._id} value={badge._id}>{badge.userName || badge.badgeID}</MenuItem>)}
                </Select>
            </FormControl>
        </Box>
    </>
}

export default UserBadgeSelector;