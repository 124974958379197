import AdminsActionTypes from "./admins.types";

const initialState = {
    admins: [],
    roles: [],
    permissions: {}
}

const adminsReducer = (state = initialState, action) => {
    switch (action.type) {
        case AdminsActionTypes.UPDATE_ADMINS_ACTION:
            return { ...state, admins: action.payload };
        case AdminsActionTypes.UPDATE_ADMIN_ACTION:
            return { ...state, admins: [action.payload, ...state.admins.filter(admin => admin._id !== action.payload._id)] };
        case AdminsActionTypes.DELETE_ADMIN_ACTION:
            return { ...state, admins: state.admins.filter(admin => admin._id !== action.payload) };
        case AdminsActionTypes.UPDATE_ROLES_ACTION:
            return { ...state, roles: action.payload };
        case AdminsActionTypes.UPDATE_ROLE_ACTION:
            return { ...state, roles: [action.payload, ...state.roles.filter(role => role._id !== action.payload._id)] };
        case AdminsActionTypes.DELETE_ROLE_ACTION:
            return { ...state, roles: state.roles.filter(role => role._id !== action.payload) };
        case AdminsActionTypes.UPDATE_PERMISSIONS_ACTION:
            return { ...state, permissions: action.payload };
        default:
            return state;
    }
}

export default adminsReducer;
