import { useState, useEffect, useRef } from 'react';
import { Box } from "@mui/material";
import { styled } from '@mui/system';
import { useLocation } from 'react-router-dom';

const Container = styled(Box)`
    position: absolute;
    z-index: 10000;
    top 0;
    left: 50%;
    background-color: black;
    color: white;
`
const PerformanceMeasurement = () => {
    const location = useLocation();
    if ("#debug" !== location.hash) return <></>
    return <Container>
        <TimerMesurement />
    </Container>
}


const arraySize = 100000;
const TimerMesurement = () => {
    const [sort, setSort] = useState(0);
    const [timer, setTimer] = useState(0);
    const mounted = useRef(null);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    });
    useEffect(() => {
        const interval = setInterval(() => {
            performance.mark('startSort');
            const array = new Array(arraySize).fill(1).map(el => Math.random());
            array.sort((a, b) => a - b);
            performance.mark('stopSort');
            const p = performance.measure('sort', 'startSort', 'stopSort');
            setSort(p.duration);
        }, 1000);
        return () => clearInterval(interval);
    },[]);


    useEffect(() => {
        let i = 0;
        const interval = setInterval(() => {
            performance.mark(`startTimeout-${i}`);
            setTimeout(() => {
                performance.mark(`stopTimeout-${i}`);
                const p = performance.measure(`timout-${i}`, `startTimeout-${i}`, `stopTimeout-${i}`);
                if(mounted.current) setTimer(p.duration);
            }, 1000)
        }, 1000);
        return () => clearInterval(interval);
    })
    return `s: ${sort} - t: ${timer}`
}

export default PerformanceMeasurement;