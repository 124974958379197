import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import {
    Card as MuiCard,
    CardHeader,
    CardContent,
    Grid2,
    Typography,
    Alert
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { toast } from 'react-toastify';
import { spacing } from '@mui/system';
import useAuth from 'hooks/useAuth';
const Card = styled(MuiCard)(spacing);

const RequestEmailVerification = () => {
    const [fetching, setFetching] = useState(false);
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    })
    const [responseMessage, setResponseMessage] = useState("");
    const [responseError, setResponseError] = useState("");
    const {
        sendRequestEmailVerification,
        email
    } = useAuth();
    const handleSendConfirmationEmail = async () => {
        setResponseMessage("");
        setResponseError("");
        setFetching(true);
        try {
            await new Promise((r) => setTimeout(r, 3000));
            const results = await sendRequestEmailVerification();
            if (!mounted.current) return;
            if (results?.message) {
                toast.success(results.message);
                if ("string" === typeof results.message) setResponseMessage(results.message);
            }
            if (results?.error) {
                if ("string" === typeof results.error) setResponseError(results.error);
            }

        } catch (error) {

            console.error("handleSendConfirmationEmail error", error);
        }
        if (!mounted.current) return;
        setFetching(false);

    }

    return <Card sx={{ mb: 6 }}>
        <CardHeader title={<Typography variant="h6">
            Email Confirmation
        </Typography>
        } />
        <CardContent>
            <Grid2 container spacing={6}>
                <Grid2 size={12}>
                    <Alert severity="warning">
                        You'll need to verify your email address to receive email notifications
                    </Alert>
                </Grid2>
                <Grid2 size={12}>
                    <Typography variant="h6" gutterBottom>Send a confirmation email link</Typography>
                    <Typography variant="body1">Email will be sent to <strong>{email}</strong></Typography>
                    <Typography variant="body2">*Please contact support if you need assistance with validation.</Typography>
                </Grid2>
                <Grid2 size={12}>
                    <LoadingButton
                        onClick={handleSendConfirmationEmail}
                        loading={fetching}
                        loadingPosition="end"
                        disabled={!!responseMessage}
                        endIcon={<SendIcon />}
                        variant="contained"
                    >
                        Send
                    </LoadingButton>
                </Grid2>
                <Grid2 size={12}>
                    {responseError && <Alert severity="error">{responseError}</Alert>}
                    {responseMessage && <Alert severity="info">{responseMessage}</Alert>}
                </Grid2>
            </Grid2>
        </CardContent>
    </Card >
}
export default RequestEmailVerification;