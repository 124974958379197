import {
  Grid2
} from '@mui/material';
import NavbarMessagesDropdown from './NavbarMessagesDropdown';
import NavbarUserDropdown from './NavbarUserDropdown';

const Navbar = () => {
  return (
    <>
      <Grid2 container alignItems="center">
        <Grid2 sx={{ display: { xs: 'none', lg: 'block' } }}>
          <NavbarMessagesDropdown />
          <NavbarUserDropdown />
        </Grid2>
        <Grid2 sx={{ display: { xs: 'block', lg: 'none' } }}>
          <NavbarUserDropdown />
        </Grid2>
      </Grid2>
    </>
  );
};

export default Navbar;
