import React, { useMemo } from 'react';
import { styled } from '@mui/system';
import { rgba } from "polished";
import { orange, red, blue, pink, purple, grey } from "@mui/material/colors";
import { Box } from '@mui/material';

const colors = [blue[500], orange[500], red[500], grey[500], pink[500], purple[500], blue[200], orange[200], red[200], grey[200], pink[200], purple[200]];
const getAndroidColorFromID = (mac) => {
    if (!mac) return '#000000';
    let i = 0;
    `${mac}`.split('').forEach((el) => {
        i += el.charCodeAt(0);
    })
    return colors[i % colors.length];
}

const Signal = styled(Box)`
    position: absolute;
    padding: 0;
    margin: 0;   
    transition-delay: 0;
    xtransition-duration: 0.5s;
    transition-property: top left;
    transition-timing-function: ease-in-out;
    z-index: 1001;
    cursor: pointer;
    background-color: #00000022;
`;

const AccessPointSignal = ({ x, y, mac, r }) => {
    const d = r > 1 ? Math.round(r) : 1;
    const fillColor = useMemo(() => {
        return getAndroidColorFromID(mac)
    }, [mac]);
    return <Signal style={{
        top: y - d,
        left: x - d,
        transitionDuration: "1ms",
        width: d * 2,
        height: d * 2,
        borderRadius: d * 2,
        backgroundColor: rgba(fillColor, 0.1),
        border: `3px solid ${fillColor}`,
    }}
    />
}
export default AccessPointSignal;
