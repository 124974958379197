import { useMemo, useState } from 'react';
import { styled } from '@mui/system';
import { Chart } from "react-chartjs-2";
import { Chart as ReactChart, registerables } from 'chart.js';
import {
    orange,
    red,
    blue,
    pink,
    purple,
    grey
} from "@mui/material/colors";
import {
    Card,
    CardContent,
    Typography,
    Box
} from '@mui/material';
import PrintButton from 'components/PrintButton';
import { spacing } from "@mui/system";

ReactChart.register(...registerables);
const ChartWrapper = styled(Box)`
  height: 280px;
  position: relative;
`;
const Spacer = styled(Box)(spacing);

const chartDefaultOptions = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
};
const chartPrintOptions = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: true,
        },
    },
};
const backgrounds = [blue[500], orange[500], red[500], grey[500], pink[500], purple[500], blue[200], orange[200], red[200], grey[200], pink[200], purple[200]];
const WifiDropPerAccessPoint = ({ rows }) => {
    const [options, setOptions] = useState(chartDefaultOptions);
    const handleOnBeforeGetContent = () => {
        setOptions(chartPrintOptions);
        // delay untill animation ends
        return new Promise(r => setTimeout(r, 500));
    }
    const handleOnAfterPrint = () => {
        setOptions(chartDefaultOptions);
    }
    const [ref, setRef] = useState(null);
    const perDevice = useMemo(() => {
        const accessPointMap = {};
        rows.forEach(el => {
            const macAdress = el && el.properties && el.properties.mac ? el.properties.mac : "Not associated to access point";
            const mac = macAdress === "02:00:00:00:00:00" ? "Permissions not granted for location" : macAdress;
            if (!accessPointMap[mac]) accessPointMap[mac] = 0;
            accessPointMap[mac]++;
        })
        return { labels: Object.keys(accessPointMap), data: Object.values(accessPointMap) }
    }, [rows])
    const data = {
        labels: perDevice.labels,
        datasets: [
            {
                data: perDevice.data,
                backgroundColor: backgrounds,
                borderColor: "transparent",
            },
        ],
    };

    return <Card sx={{ mb: 1 }}>
        <CardContent>
            <div ref={ref => setRef(ref)}>
                <Typography variant="h6" gutterBottom>
                    Per Access Point <PrintButton
                        refPrint={ref}
                        onAfterPrint={handleOnAfterPrint}
                        onBeforeGetContent={handleOnBeforeGetContent}
                    />
                </Typography>
                <Spacer sx={{ mb: 6 }} />
                <ChartWrapper>
                    <Chart type="doughnut" data={data} options={options} />
                </ChartWrapper>
            </div>
        </CardContent>
    </Card>
}
export default WifiDropPerAccessPoint;
