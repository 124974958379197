import { combineReducers } from "redux";
import chatReducer from "./chat/chat.reducer";
import alertsReducer from "./alerts/alerts.reducer";
import devicesReducer from "./devices/devices.reducer";
import eventsReducer from "./events/events.reducer";
import ladsServersReducer from './ladsServers/ladsServers.reducer';
import systemReducer from './system/system.reducer';
import rttReducer from './rtt/rtt.reducer';
import groupsReducer from './groups/groups.reducer';
import settingsReducer from './settings/settings.reducer';
import adminsReducer from "./admins/admins.reducer";
import supportReducer from "./support/support.reducer";
import articlesReducer from "./articles/articles.reducer";
import keyboardsReducer from "./keyboards/keyboards.reducer";
import badgesReducer from "./badges/badges.reducer";
import utsReducer from "./uts/uts.reducer";
import clientSettingsReducer from './clientSettings/clientSettings.reducer';

const rootReducer = combineReducers({
    chat: chatReducer,
    alerts: alertsReducer,
    devices: devicesReducer,
    events: eventsReducer,
    ladsServers: ladsServersReducer,
    system: systemReducer,
    rtt: rttReducer,
    groups: groupsReducer,
    settings: settingsReducer,
    admins: adminsReducer,
    support: supportReducer,
    articles: articlesReducer,
    keyboards: keyboardsReducer,
    badges: badgesReducer,
    uts: utsReducer,
    clientSettings: clientSettingsReducer,
});

const rootReducerWithRest = (state, action) => rootReducer(action.type === 'RESET_STORE' ? undefined : state, action);
export default rootReducerWithRest;