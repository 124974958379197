import React, {
  useState,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { styled } from '@mui/system';
import { useTheme } from "@mui/material/styles";
import { spacing } from "@mui/system";
import { Outlet } from "react-router-dom";
import {
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Box,
  Toolbar as MuiToolBar,
  CssBaseline,
  Paper as MuiPaper,
  useMediaQuery,
  Grid2,
  ListItemButton,
  IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MuiChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiPushPinIcon from '@mui/icons-material/PushPin';
import { NavLink } from "react-router-dom";
import Logo from 'components/Logo';
import Navbar from "components/navbar/Navbar";
import Footer from "components/Footer";
import dashboardItems from "components/sidebar/dashboardItems";
import SidebarNav from "components/sidebar/SidebarNav";
import SidebarNavMinimized from "components/sidebar/SidebarNavMinimized";
import SidebarFooter from "components/sidebar/SidebarFooter";
import SidebarFooterMinimized from "components/sidebar/SidebarFooterMinimized";
import LogoSmall from 'components/LogoSmall';
import GroupSelector from './GroupSelector';
import UserBadgeSelector from './UserBadgeSelector';
import usePreferences from "hooks/usePreferences";
import useAuth from "hooks/useAuth";
import pagesSettings from "constants/pagesSettings";

function isTouchDevice() {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}

const drawerWidth = 258;
const drawerWidthMinimized = 80;
const Root = styled(Box)`
  display: flex;
  min-height: 100vh;
  background: ${(props) => props.theme.palette.background.default};
  min-width: 300px;
`;
const AppContent = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;
const Paper = styled(MuiPaper)(spacing);
const MainContent = styled(Paper)`
  flex: 1;
  overflow-y:auto;
  background: ${(props) => props.theme.palette.background.default};
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Brand = styled(ListItemButton)`
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  min-height: 56px;
  justify-content: center;
  cursor: pointer;
  flex-grow: 1;
  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
    color: ${(props) => props.theme.sidebar.header.brand.color};
  }
`;
const BrandWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: ${(props) => props.$isOpen ? drawerWidth : drawerWidthMinimized}px;
  overflow: hidden;
  background-color: ${(props) => props.theme.sidebar.header.background};
  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;
const PushPinIcon = styled(MuiPushPinIcon)`
  color: ${(props) => props.theme.sidebar.color};
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`
const ChevronLeftIcon = styled(MuiChevronLeftIcon)`
  color: ${(props) => props.theme.sidebar.color};
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`
const Toolbar = styled(MuiToolBar)`
  padding-left: 0;
  min-width: 300px;
`

const BrandWrapperSmall = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  cursor: pointer;
  width: ${drawerWidthMinimized - 1}px;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.sidebar.header.background};
  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;
const ToolBarItems = styled(Grid2)`
  margin: 0;
  padding: 0;
  width: 100%;
`
const DrawerContent = styled(Box)`
  height:100vh;
  display: flex;
  flex-direction: column;
`
const SideBarNavContainer = styled(Box)`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${(props) => props.theme.sidebar.background};
`
const DrawerControls = styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  backgroundColor: theme.sidebar.header.background,
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const Drawer = styled(MuiDrawer)`  
    width: ${(props) => props.open ? drawerWidth : drawerWidthMinimized}px;
    transition: ${props => `width ${props.open ? props.theme.transitions.duration.enteringScreen : props.theme.transitions.duration.leavingScreen}ms ${props.theme.transitions.easing.sharp}`};
    overflow-x: hidden;
    flex-shrink: 0;
    white-space: nowrap;
    box-sizing: border-box;
    .MuiDrawer-paper {
      width: ${(props) => props.open ? drawerWidth : drawerWidthMinimized}px;
      transition: ${props => `width ${props.open ? props.theme.transitions.duration.enteringScreen : props.theme.transitions.duration.leavingScreen}ms ${props.theme.transitions.easing.sharp}`};
    }
`;

const Spacing = styled(Grid2)`
    width: ${(props) => props.$isOpen ? drawerWidth : drawerWidthMinimized}px;
    transition-property: width;
    transition-duration: ${(props) => props.$isOpen ? props.theme.transitions.duration.leavingScreen : props.theme.transitions.duration.enteringScreen}ms;
    transition-timing-function ${(props) => props.theme.transitions.easing.sharp}; 
`;

const Dashboard = () => {
  const preferencesID = "layouts-Dashboard";
  const { preferences, updatePreferences } = usePreferences();
  const initialState = preferences[preferencesID] || {};
  const isTouch = isTouchDevice();
  const [pinned, setPinned] = useState(initialState['pinned']);
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const [isOpen, setIsOpen] = useState(initialState['isOpen']);
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  }, [])
  const timerOpen = useRef(null);
  const handleMouseEnter = () => {
    clearTimeout(timerOpen.current);
    if (!pinned && isLgUp) {
      timerOpen.current = setTimeout(() => {
        if(isMounted.current) setIsOpen(true);
      }, 400)
    }
  }

  const handleMouseLeave = () => {
    clearTimeout(timerOpen.current);
    if (!pinned && isLgUp) {
      timerOpen.current = setTimeout(() => {
        if(isMounted.current) setIsOpen(false);
      }, 600)
    }
  }
  const handleMinimizedMouseEnter = () => {
    clearTimeout(timerOpen.current);
    timerOpen.current = setTimeout(() => {
      if(isMounted.current) setIsOpen(true);
    }, 400)
  }
  const handleTouchStart = () => {
    setIsOpen(true);
    setPinned(true);
  }
  useEffect(() => {
    updatePreferences(preferencesID, { pinned, isOpen: false });
  }, [pinned, isOpen, updatePreferences]);
  const { permissions = [] } = useAuth();
  const pagePermissionsMap = useMemo(() => {
    return permissions
      .filter(permission => /.*\.page\..*/.test(permission))
      .map(pagePermission => pagePermission.split(".").pop())
      .reduce((p, c) => ({ ...p, [c]: true }), {});
  }, [permissions]);
  const allowedPagesIDsMap = useMemo(() => {
    return pagesSettings.filter(el => pagePermissionsMap[el.requiredPermission]).reduce((p, c) => ({ ...p, [c.id]: true }), {});
  }, [pagePermissionsMap])
  const navItems = useMemo(() => {
    const _navItems = [];
    dashboardItems.forEach(item => {
      if (item.isMandatory || allowedPagesIDsMap[item.id]) {
        const _item = {
          ...item,
          pages: []
        }
        item.pages.forEach(page => {
          if (page.isMandatory || allowedPagesIDsMap[page.id]) {
            const _page = {
              ...page,
              children: null
            }
            if (page.children) {
              const _children = page.children.filter(child => child.isMandatory || allowedPagesIDsMap[child.id])
              _page.children = _children.length ? _children : null;
            }
            if (_page.href || _page?.children?.length) _item.pages.push(_page);
          }
        });
        if (_item.pages.length) _navItems.push(_item);
      }
    });
    return _navItems;
  }, [allowedPagesIDsMap])

  return (
    <Root>
      <CssBaseline />
      <AppBar>
        <Toolbar>
          <ToolBarItems container alignItems="center">
            {!isOpen && !isLgUp && <Grid2>
              <IconButton onClick={() => setIsOpen(true)}>
                <MenuIcon />
              </IconButton>
            </Grid2>}
            {isLgUp && <Spacing $isOpen={isOpen} />}
            <Grid2 sx={{ pl: 2 }}>
              <Grid2 container columnSpacing={2}>
                <Grid2>
                  <GroupSelector />
                </Grid2>
                <Grid2>
                  <UserBadgeSelector />
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2 flexGrow={1} />
            <Grid2><Navbar /></Grid2>
          </ToolBarItems>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isLgUp ? "permanent" : "temporary"}
        open={isOpen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClose={() => setIsOpen(false)}
      >
        <DrawerContent>
          <DrawerHeader>
            <BrandWrapper $isOpen={isOpen} >
              {!isOpen && isLgUp && <BrandWrapperSmall onClick={() => setIsOpen(true)}>
                <LogoSmall />
              </BrandWrapperSmall>}
              {isOpen && <Brand component={NavLink} to="/">
                <Logo />
              </Brand>}
              {isOpen && isLgUp && <DrawerControls>
                {isTouch ? (
                  <ChevronLeftIcon
                    role="button"
                    fontSize="large"
                    onClick={() => setIsOpen(false)}
                  />
                )
                  :
                  (
                    <PushPinIcon
                      role="button"
                      sx={{ transform: pinned ? '' : 'rotate(45deg)' }}
                      onClick={() => setPinned(!pinned)}
                    />
                  )
                }
              </DrawerControls>}
            </BrandWrapper>
          </DrawerHeader>
          <SideBarNavContainer>
            {isOpen ? <SidebarNav items={navItems} width={drawerWidth} /> : <SidebarNavMinimized items={navItems} />}
          </SideBarNavContainer>
          {isOpen ? <SidebarFooter /> : <SidebarFooterMinimized />}
          {!isOpen && isLgUp && <Box
            onMouseEnter={handleMinimizedMouseEnter}
            onTouchStart={handleTouchStart}
            onClick={() => setIsOpen(true)}
            sx={{
              width: drawerWidthMinimized,
              height: '100vh',
              zIndex: 'tooltip',
              top: 0,
              left: 0,
              position: 'fixed',
              cursor: 'pointer'
            }}
          >
          </Box>}
        </DrawerContent>
      </Drawer>
      <AppContent>
        <Toolbar />
        <MainContent p={isLgUp ? 12 : 5}>
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
}

export default Dashboard;