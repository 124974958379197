import React, { useState } from 'react';
import {
    Card as MuiCard,
    TextField as MuiTextField,
    CardContent,
    Box,
    Grid2,
    Typography,
    LinearProgress,
    Grow,
} from "@mui/material";
import { styled } from '@mui/system';
import { spacing } from "@mui/system";
import SettingsIcon from '@mui/icons-material/Settings';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from "react-toastify";
import { http } from "utils/http";
import Products from 'components/billing/Products';
import Authorized from 'components/Authorized';
//import PricingTable from 'components/billing/PricingTable';

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const CardFooter = styled(Box)`
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const BillingNotSet = ({ status, products }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [coupon, setCoupon] = useState('');
    const [fetching, setFetching] = useState(false);
    async function createCheckoutSession() {
        setFetching(true);
        try {
            const promise = http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/payment/create-checkout-session`,
                {
                    success_url: window.location.href,
                    cancel_url: window.location.href,
                    productID: selectedProduct ? selectedProduct.productID : '',
                    interval: selectedProduct ? selectedProduct.interval : '',
                    couponID: coupon
                })
            toast.promise(promise, {
                pending: 'Redirecting...',
                success: 'Page Ready',
            })
            const resp = await promise;
            setFetching(false);
            if (resp && resp.data && resp.data.sessionUrl) {
                setFetching(false);
                window.location.assign(resp.data.sessionUrl);
            }
            else throw new Error("sessionUrl not found");
        } catch (error) {
            setFetching(false);
            console.log("error", error);
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            }
            else toast.error("Something went wrong please try again");
        }
    }
    return <Card sx={{ mb: 6 }}>
        <CardContent>
            <Box>
                {products?.length > 0 ? (
                    <Products selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} products={products} disabled={fetching} />
                ) : (
                    <Grid2 container spacing={6}>
                        <Grid2 size={12}>
                            <Typography variant="h6" gutterBottom>Loading Available Products</Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <LinearProgress />
                        </Grid2>
                    </Grid2>)}
            </Box>
            <CardFooter>
                <Authorized permission="payment.createCheckout">
                    {selectedProduct && <>
                        <Grow
                            in={!!selectedProduct}
                            style={{ transformOrigin: '0 0 0' }}
                            {...(!!selectedProduct ? { timeout: 1000 } : {})}
                        >
                            <TextField
                                type="text"
                                name="coupon"
                                label="coupon"
                                value={coupon}
                                onChange={(e) => setCoupon(e.target.value)}
                                size="small"
                                sx={{ mx: 2 }}
                            />
                        </Grow>
                        <Grow
                            in={!!selectedProduct}
                            style={{ transformOrigin: '0 0 0' }}
                            {...(!!selectedProduct ? { timeout: 1000 } : {})}
                        >
                            <LoadingButton
                                onClick={createCheckoutSession}
                                loading={fetching}
                                color="secondary"
                                variant="contained"
                                loadingPosition="start"
                                startIcon={<SettingsIcon />}
                            >
                                Purchase
                            </LoadingButton>
                        </Grow>
                    </>}
                </Authorized>
            </CardFooter>
        </CardContent>
    </Card >
}
export default BillingNotSet;