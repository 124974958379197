import BadgesActionTypes from "./badges.types"


const updateBadgesAction = (badges) => {
    return { type: BadgesActionTypes.UPDATE_BADGES_ACTION, payload: badges }
}
const updateBadgeAction = (badge) => {
    return { type: BadgesActionTypes.UPDATE_BADGE_ACTION, payload: badge }
}

const deleteBadgeAction = (badgeID) => {
    return { type: BadgesActionTypes.DELETE_BADGE_ACTION, payload: badgeID }
}

const updateSelectedBadge = (badgeID) => {
    return { type: BadgesActionTypes.UPDATE_SELECTED_BADGE, payload: badgeID }
}



export {
    updateBadgesAction,
    updateBadgeAction,
    deleteBadgeAction,
    updateSelectedBadge
}
