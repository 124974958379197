import { useState, useMemo, useEffect } from "react";
import {
    Grid2,
    TextField,
    IconButton,
    Card,
    CardContent,
    Typography,
    CardHeader
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch } from 'react-redux';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { updateBadgeAction, deleteBadgeAction } from 'redux/badges/badges.actions';
import { http } from 'utils/http'
import { toast } from 'react-toastify';
import useAuthorization from "hooks/useAuthorization";

const EditBadge = ({ _id, badgeID, userName, close }) => {
    const { isAuthorized } = useAuthorization();
    const canUpdateBadge = isAuthorized("badge.update");
    const canDeleteBadge = isAuthorized("badge.delete");
    const [fetching, setFetching] = useState(false);
    const [newUserName, setNewUserName] = useState(userName);
    const [deleting, setDeleting] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setNewUserName(userName);
    }, [userName]);

    const handleDeleteBadge = async () => {
        setFetching(true);
        try {
            const results = await http.delete(`${process.env.REACT_APP_SERVER_URL}/api/v2/badge/badge/${_id}`);
            if (!results.data._id) throw new Error("");
            setFetching(false);
            dispatch(deleteBadgeAction(results.data._id));
            toast.success("Deleted");
            close();
        } catch (error) {
            setFetching(false);
            console.log("error", error);
            toast.error(error?.response?.data?.message || "Something went wrong");
        }
    }

    const handleUpdateBadge = async () => {
        setFetching(true);
        try {
            const results = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/badge/badge`,
                {
                    id: _id,
                    userName: newUserName
                });
            if (!results.data._id) throw new Error("");
            if (!results?.data?._id) throw new Error("Can't Update badge");
            setFetching(false);
            setNewUserName('');
            dispatch(updateBadgeAction(results.data));
            toast.success("Saved");
            close();
        } catch (error) {
            setFetching(false);
            toast.error(error?.response?.data?.message || error?.message || "Something went wrong");
            console.log("error", error);
        }
    }
    const isValidForm = useMemo(() => badgeID && newUserName, [badgeID, newUserName]);

    return <Card>
        <CardHeader
            title={<Typography variant="h6">ID: <strong>{badgeID}</strong></Typography>}
            action={
                <IconButton onClick={close}>
                    <CancelIcon />
                </IconButton>
            }
        />

        <CardContent>
            <Grid2 container spacing={2} alignItems="center">
                <Grid2>
                    <TextField
                        label="BadgeID"
                        value={badgeID || ''}
                        variant="outlined"
                        size="small"
                        disabled
                        sx={{ width: 250 }}
                    />
                </Grid2>
                <Grid2>
                    <TextField
                        label="User Name"
                        value={newUserName || ''}
                        onChange={(e) => setNewUserName(e.target.value)}
                        variant="outlined"
                        size="small"
                        placeholder='John ...'
                        required
                        sx={{ width: 250 }}
                        disabled={!canUpdateBadge}
                    />
                </Grid2>
                <Grid2 size={12} />
                <Grid2>
                    {!deleting && <LoadingButton
                        size="small"
                        endIcon={<DeleteIcon />}
                        onClick={() => setDeleting(true)}
                        loadingPosition="end"
                        color="error"
                        variant="contained"
                        disabled={!canDeleteBadge}
                    >
                        Delete
                    </LoadingButton>}
                    {deleting && <IconButton color="error" onClick={handleDeleteBadge} >
                        <DeleteForeverIcon />
                    </IconButton>}
                    {deleting && <IconButton color="warning" onClick={() => setDeleting(false)} >
                        <HighlightOffIcon />
                    </IconButton>}
                </Grid2>
                {!deleting && <Grid2>
                    <LoadingButton
                        loading={fetching}
                        loadingPosition="end"
                        endIcon={<SaveIcon />}
                        variant="contained"
                        size="small"
                        disabled={!isValidForm || !canUpdateBadge}
                        onClick={handleUpdateBadge}
                    >
                        Update
                    </LoadingButton>
                </Grid2>}
            </Grid2>
        </CardContent>
    </Card>
}
export default EditBadge;