import React, { useState } from "react";
import {
    Grid2,
    Card,
    CardContent,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
} from '@mui/material';
import useAuthorization from 'hooks/useAuthorization'
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { http } from 'utils/http';
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { newSettingAction } from "redux/settings/settings.actions";
import settingsTypesInfos from './settingsTypesInfos';
import SETTING_TYPE from "constants/settingType";

const CreateSetting = ({ setCreating }) => {
    const { isAuthorized } = useAuthorization();
    const dispatch = useDispatch();
    const [settingType, setSettingType] = useState(settingsTypesInfos.SCAN.value);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [fetching, setFetching] = useState(false);
    const canCreateTunnelSetting = isAuthorized("tunnel.createSecondary");
    const handleCreateSetting = async () => {
        setFetching(true);
        try {
            const resp = await http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/setting/setting`,
                {
                    name,
                    description,
                    type: settingType,
                });
            if (resp && resp.data) {
                dispatch(newSettingAction(resp.data));
                setFetching(false);
                setCreating(false);
            }
        } catch (error) {
            setFetching(false);
            if (error && error.response && error.response.data && error.response.data.message) {
                return toast.error(error.response.data.message);
            }
            toast.error("Something went wrong");
        }
    }

    return <Card variant="outlined">
        <CardContent>
            <Grid2 container spacing={2} alignItems="center">
                <Grid2 size={{ xs: 12, sm: 5 }}>
                    <TextField
                        label="Name"
                        value={name || ''}
                        onChange={(e) => setName(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ my: 2 }}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 7 }}>
                    <TextField
                        label="Description"
                        value={description || ''}
                        onChange={(e) => setDescription(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ my: 2 }}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <FormControl fullWidth>
                        <InputLabel id="select-setting-label">Setting</InputLabel>
                        <Select
                            labelId="select-setting-label"
                            id="select-setting"
                            value={settingType}
                            label="setting"
                            size="small"
                            onChange={(e) => setSettingType(e.target.value)}
                            disabled={fetching}
                        >
                            {Object.values(settingsTypesInfos).map(el => <MenuItem
                                key={el.value}
                                value={el.value}
                                disabled={el.value === SETTING_TYPE.TUNNEL && !canCreateTunnelSetting}
                            >{el.name}
                            </MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid2>
                <Grid2 size={12}>
                    <LoadingButton
                        size="small"
                        endIcon={<SaveIcon />}
                        onClick={handleCreateSetting}
                        loadingPosition="end"
                        variant="contained"
                        loading={fetching}
                        disabled={!name}
                    >
                        Save
                    </LoadingButton>
                </Grid2>
            </Grid2>
        </CardContent>
    </Card>
}


export default CreateSetting;