import {
    useMemo
} from 'react';
import {
    Grid2,
    Typography,
    Card,
    CardContent,
    Alert
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
    defaultBadgeSettings,
    isValidLength,
    isValidLowercase,
    isValidUppercase,
    isValidNumbers,
    isValidSpecialChar,
    stringifyLogoutSequence,
} from 'utils/badge';
import settingType from 'constants/settingType';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { styled } from '@mui/system';

const MatchedString = styled('strong')`
    color: ${(props) => props.theme.palette.error.main};
    text-decoration: underline;
`

const FullMatchWarning = ({ testCodesValidBadgeRequirements }) => {
    return <Card variant="outlined">
        <CardContent>
            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <Alert severity="error">
                        <Typography variant="h6">
                            Logout sequence / badge requirements Conflit detected
                        </Typography>
                        <Typography variant="body1">
                            Users will be logged out immediately after login in
                        </Typography>
                        <Typography variant="caption">
                            * This does not apply to login via badge scan
                        </Typography>
                    </Alert>
                </Grid2>
                {testCodesValidBadgeRequirements.map(({ testCode, settingsName }, index) => (
                    <Grid2 key={index} size={12} container>
                        <Grid2 size={12}>
                            <Typography variant="body2">
                                <WarningAmberIcon fontSize='small' /> Decoded sequence: <MatchedString>{testCode || "N/A"}</MatchedString> is a valid Badge using settings: <strong>{settingsName}</strong>
                            </Typography>
                        </Grid2>
                    </Grid2>
                ))}
            </Grid2>
        </CardContent>
    </Card>
}


const BadgeMatchedSubstring = ({ badgeLength, testCode }) => {
    if (badgeLength > testCode.length) return <>
        <strong>{new Array(badgeLength - testCode.length).fill('?').join("")}</strong>
        <MatchedString>{testCode}</MatchedString>
        <span> is a partially valid Badge</span>
    </>

    if (badgeLength < testCode.length) return <>
        <strong>{testCode.substring(0, testCode.length - badgeLength)}</strong>
        <MatchedString>{testCode.substring(testCode.length - badgeLength)}</MatchedString>
        <span> contains a valid Badge</span>
    </>
    // should not happens (to avoid app crash if component not used correctly)
    return <strong>Full Match {testCode}</strong>
}

const SubMatchWarning = ({ testCodesSubValidBadgeRequirements }) => {
    return <Card variant="outlined">
        <CardContent>
            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <Alert severity="error">
                        <Typography variant="h6">
                            Logout sequence / badge requirements Conflit detected
                        </Typography>
                        <Typography variant="body1">
                            This can cause badge unassign while typing the badge
                        </Typography>
                        <Typography variant="body1">
                            This can cause assign badge while typing the logout sequence
                        </Typography>
                        <Typography variant="caption">
                            * This does not apply to login via badge scan
                        </Typography>
                    </Alert>
                </Grid2>
                {testCodesSubValidBadgeRequirements.map(({ testCode, badgeLength, settingsName }, index) => (
                    <Grid2 key={index} size={12} container>
                        <Grid2 size={12}>
                            <Typography variant="body2">
                                <WarningAmberIcon fontSize='small' />  Decoded sequence: <BadgeMatchedSubstring badgeLength={badgeLength} testCode={testCode || ""} /> using settings: <strong>{settingsName}</strong>
                            </Typography>
                        </Grid2>
                    </Grid2>
                ))}
            </Grid2>
        </CardContent>
    </Card>
}

const TestLogoutSequence = ({
    badgeLength = defaultBadgeSettings.badgeLength,
    badgeContainsLowerCase = defaultBadgeSettings.badgeContainsLowerCase,
    badgeLowercaseCount = defaultBadgeSettings.badgeLowercaseCount,
    badgeContainsUpperCase = defaultBadgeSettings.badgeContainsUpperCase,
    badgeUppercaseCount = defaultBadgeSettings.badgeUppercaseCount,
    badgeContainsNumber = defaultBadgeSettings.badgeContainsNumber,
    badgeNumberCount = defaultBadgeSettings.badgeNumberCount,
    badgeContainsSpecialChar = defaultBadgeSettings.badgeContainsSpecialChar,
    badgeSpecialCharCount = defaultBadgeSettings.badgeSpecialCharCount,
    badgeLogoutSequence = []
}) => {
    const settings = useSelector((state) => state.settings);
    const keyboardsSettings = useMemo(() => {
        return [{
            name: "no-map",
            keyMap: {}
        }
            ,
        ...settings
            .filter(el => el.type === settingType.KEYBOARD && el.keys?.length > 0)
            .map(el => ({
                name: el.name || el._id,
                keyMap: el.keys.reduce((p, c) => ({ ...p, [`${c.code}`]: c }), {})
            }))
        ]
    }, [settings])
    const testCodes = useMemo(() => keyboardsSettings
        .map(el => ({
            settingsName: el.name,
            testCode: stringifyLogoutSequence(badgeLogoutSequence, el.keyMap)
        }))
        .filter(el => el.testCode?.length > 0)
        , [badgeLogoutSequence, keyboardsSettings]);
    const testCodesBadgeRequirementsValidation = useMemo(() => testCodes
        .map(el => {
            const isSubValid = isValidLowercase(el.testCode, badgeContainsLowerCase, badgeLowercaseCount) &&
                isValidUppercase(el.testCode, badgeContainsUpperCase, badgeUppercaseCount) &&
                isValidNumbers(el.testCode, badgeContainsNumber, badgeNumberCount) &&
                isValidSpecialChar(el.testCode, badgeContainsSpecialChar, badgeSpecialCharCount);
            const isValid = isSubValid && isValidLength(el.testCode, badgeLength);
            return ({
                ...el,
                badgeLength,
                isValid,
                isSubValid
            })
        })
        , [
            testCodes,
            badgeLength,
            badgeContainsLowerCase,
            badgeLowercaseCount,
            badgeContainsUpperCase,
            badgeUppercaseCount,
            badgeContainsNumber,
            badgeNumberCount,
            badgeContainsSpecialChar,
            badgeSpecialCharCount
        ]);
    const testCodesValidBadgeRequirements = useMemo(() => testCodesBadgeRequirementsValidation.filter(el => el.isValid), [testCodesBadgeRequirementsValidation]);
    const testCodesSubValidBadgeRequirements = useMemo(() => testCodesBadgeRequirementsValidation.filter(el => el.isSubValid), [testCodesBadgeRequirementsValidation]);
    if (testCodesValidBadgeRequirements.length > 0) return <FullMatchWarning testCodesValidBadgeRequirements={testCodesValidBadgeRequirements} />
    if (testCodesSubValidBadgeRequirements.length > 0) return <SubMatchWarning testCodesSubValidBadgeRequirements={testCodesSubValidBadgeRequirements} />
    return <></>
}
export default TestLogoutSequence;