import {
    Card as MuiCard,
    CardContent,
} from "@mui/material";
import { styled } from '@mui/system';
import { spacing } from "@mui/system";
import GSWPlans from 'components/GSWPlans';

const Card = styled(MuiCard)(spacing);

const BillingNotSetGSW = ({ plans }) => {
    return <Card sx={{ mb: 6 }}>
        <CardContent>
            <GSWPlans plans={plans}/>
        </CardContent>
    </Card >
}
export default BillingNotSetGSW;