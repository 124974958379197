import { createContext, useCallback, useEffect, useReducer, useRef } from 'react';
import axios from 'axios';
import { http } from 'utils/http'
import { updateApiPermissions } from 'utils/api';
import { setSession } from 'utils/jwt';
import { toast } from 'react-toastify';
import { connectSocket, disconnectSocket } from 'utils/socket';
import { getMyAccount } from 'utils/api';
import { useDispatch } from 'react-redux'
import USER_TYPE from 'constants/userType';


const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const UPDATE = "UPDATE";
const FINISH_SETUP_COMP = "FINISH_SETUP_COMP";
const RESET_SESSION = "RESET_SESSION";
const RESET_STORE = "RESET_STORE";

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    type: null,
    roles: [],
    permissions: [],
    namespace: null,
    subscription: null
}

const JWTReducer = (state, action) => {
    if(Array.isArray(action.payload?.permissions)) updateApiPermissions(action.payload.permissions);
    switch (action.type) {
        case INITIALIZE:
            return {
                ...initialState,
                ...action.payload
            }
        case SIGN_IN:
            if (action.payload.rememberMe && action.payload.email) {
                localStorage.setItem("email", action.payload.email);
            }
            return {
                ...state,
                isAuthenticated: true,
                isInitialized: true,
                ...action.payload
            }
        case UPDATE:
            return {
                ...state,
                ...action.payload
            }
        case SIGN_OUT:
            return {
                ...initialState,
                isInitialized: true,
            }
        case SIGN_UP:
            return {
                ...initialState,
                isAuthenticated: true,
                isInitialized: true,
                ...action.payload
            }
        case FINISH_SETUP_COMP:
            return {
                ...initialState,
                isAuthenticated: true,
                isInitialized: true,
                ...action.payload
            }
        case RESET_SESSION:
            console.error("resseting session");
            return initialState;
        default:
            return state
    }
}

const AuthContext = createContext(null)

function AuthProvider({ children }) {
    const mainStoreDispatch = useDispatch();
    const [state, dispatch] = useReducer(JWTReducer, initialState);
    window.GSWAuthProvider = state;
    const mounted = useRef(false);
    useEffect(() => {
        if (
            state.isInitialized &&
            state.isAuthenticated &&
            [USER_TYPE.ADMIN, USER_TYPE.SUPPORT, USER_TYPE.DEVICE, USER_TYPE.ADMIN_DEMO].includes(state.type) &&
            state.roles &&
            state.namespace
        ) {
            connectSocket(state.namespace);
        }
    }, [state]);
    const handleGetMyAccount = useCallback(() => {
        getMyAccount()
            .then(user => {
                if (!mounted.current) return;
                dispatch({
                    type: INITIALIZE,
                    payload: {
                        isAuthenticated: true,
                        isInitialized: true,
                        ...user,
                    },
                })
            })
            .catch(error => {
                if (!mounted.current) return;
                switch (error) {
                    case "token-expired":
                        dispatch({
                            type: INITIALIZE,
                            payload: {
                                isAuthenticated: false,
                                isInitialized: true,
                            },
                        });
                        break;
                    case "token-not-present":
                        dispatch({
                            type: INITIALIZE,
                            payload: {
                                isAuthenticated: false,
                                isInitialized: true,
                            },
                        });
                        break;
                    default:
                        // try agin in 5 seconds
                        console.log("something went wrong", error);
                        setTimeout(handleGetMyAccount, 5000);

                }
            });
    }, [])
    useEffect(() => {
        mounted.current = true;
        handleGetMyAccount();
        return () => {
            mounted.current = false;
        }
    }, [handleGetMyAccount])
    const loadSession = async (accessToken) => {
        console.error("loadsession called");
        setSession(accessToken)
        handleGetMyAccount();
    }
    const signIn = async (email, password, rememberMe) => {
        if (!rememberMe) {
            localStorage.removeItem("email");
        }
        return axios
            .post(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/sign-in`, {
                email: email,
                password: password,
            })
            .then(async function (res) {
                const response = res
                const { accessToken, user } = response.data
                setSession(accessToken)
                dispatch({
                    type: SIGN_IN,
                    payload: {
                        ...user,
                        rememberMe,
                    },
                })
            })
            .catch(function (error) {
                throw new Error(error?.response?.data?.message || "Something went wrong");
                
            })
    }

    const signInDevice = useCallback(async (androidID) => {
        return axios
            .post(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/sign-in-device`, {
                androidID
            })
            .then(async function (res) {
                const response = res;
                const { accessToken, user } = response.data
                setSession(accessToken)
                dispatch({
                    type: SIGN_IN,
                    payload: {
                        ...user
                    },
                })
            })
            .catch(function (error) {
                throw new Error(error?.response?.data?.message || "Something went wrong");
            })
    }, []);
    const signInAsAdmin = async (adminID) => {
        return http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/support/signin-as-admin`, {
            adminID
        })
            .then(async function (res) {
                const response = res
                const { accessToken, user } = response.data
                await signOut();
                setSession(accessToken)
                dispatch({
                    type: SIGN_IN,
                    payload: user
                });
            })
            .catch(function (error) {
                throw new Error(error?.response?.data?.message || "Something went wrong");
            })
    }
    //signInDevice, signOut
    const signOut = useCallback(async () => {
        try{
            await http.get(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/sign-out`)
        }catch(error){
            console.log("error", error);
        }
        setSession(null)
        disconnectSocket();
        mainStoreDispatch({ type: RESET_STORE });
        dispatch({ type: SIGN_OUT })
        
    }, [mainStoreDispatch])

    const signUp = async (firstName, lastName, email, password) => {
        const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/v2/auth/sign-up`,
            {
                firstName,
                lastName,
                email,
                password,
            }
        )
        const { accessToken, user } = response.data
        setSession(accessToken)
        dispatch({
            type: SIGN_UP,
            payload: user,
        })
    }

    //sendVerificationLink
    const requestValidationAccountEmail = async (email) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/api/v2/auth/request-validation-account-email`,
                {
                    email,
                }
            )
            return response.data
        } catch (error) {
            throw new Error(error?.response?.data?.message || "Something went wrong");
        }
    }
    // update or create
    const updateCompany = async (compName, compAddr, compZip, compPhone) => {
        try {
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/update-company`,
                {
                    compName,
                    compAddr,
                    compZip,
                    compPhone,
                });
            console.log("resp", resp.data);
            console.log("JWTContext: company.uuid", resp?.data?.uuid);
            console.log("JWTContext: company._id", resp?.data?._id);
    
            return (resp.data);
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    }
    // find usage replace with API

    const validateCompany = async () => {
        try {
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/validate-company`);
            if (resp.data && resp.data.user && resp.data.accessToken);
            setSession(resp.data.accessToken);
            dispatch({
                type: FINISH_SETUP_COMP,
                payload: resp.data.user
            })
            return (resp.data);
        } catch (error) {
            // Handle Error Here
            console.error(error);
            throw new Error(error?.response?.data?.message || "Please double check your infos");
        }
    }




    const sendRestPasswordEmail = async (email) => {
        return axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/v2/auth/forgot-password`,
            {
                email
            }
        )
            .then(resp => {
                if (resp && resp.data && resp.data.message) {
                    return resp.data.message;
                }
                throw new Error();
            })
            .catch(error => {
                throw new Error(error?.response?.data?.message || "Something went wrong");
            })
    }
    const requestDemoEmail = async (email) => {
        return axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/v2/auth/request-demo`,
            {
                email
            }
        )
            .then(resp => {
                if (resp && resp.data && resp.data.message) {
                    return resp.data.message;
                }
                throw new Error();
            })
            .catch(error => {
                throw new Error(error.response.data.message || "Something went wrong");
            })
    }
    const resetPassword = (password, token) => {
        return axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/v2/auth/reset-password`,
            {
                password
            },
            {
                headers: { 'Authorization': `bearer ${token}` }
            }
        )
            .then(response => {
                const { accessToken, user } = response.data
                setSession(accessToken)
                toast.success("Password updated, Redirecting...", { autoClose: 3000 })
                setTimeout(() => {
                    dispatch({
                        type: SIGN_IN,
                        payload: user
                    });
                }, 3000);
                return "Password updated, Redirecting...";
            })
            .catch(error => {
                console.log("catch", error.response.data);
                throw new Error(error?.response?.data?.message || "Something went wrong");
            })
    }
    const updateEmail = (token) => {
        return axios.patch(
            `${process.env.REACT_APP_SERVER_URL}/api/v2/auth/email-update`,
            {},
            {
                headers: { 'Authorization': `bearer ${token}` }
            }
        )
            .then(response => {
                const { accessToken, user } = response.data
                setSession(accessToken)
                dispatch({
                    type: SIGN_IN,
                    payload: user
                });
                return "Email updated, Redirecting...";
            })
            .catch(error => {
                console.log("catch", error.response.data);
                throw new Error(error?.response?.data?.message || "Something went wrong");
            })
    }

    const createAccount = (password, token) => {
        return axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/v2/auth/create-account`,
            {
                password
            },
            {
                headers: { 'Authorization': `bearer ${token}` }
            }
        )
            .then(response => {
                const { accessToken, user } = response.data
                setSession(accessToken)
                dispatch({
                    type: SIGN_IN,
                    payload: user
                })
                return "Thank you";
            })
            .catch(error => {
                console.log("catch", error.response.data);
                throw new Error(error?.response?.data?.message || "Something went wrong");
            })
    }

    const updateUser = async (data) => {
        const formData = new FormData()
        if (data.firstName) formData.append("firstName", data.firstName);
        if (data.lastName) formData.append("lastName", data.lastName);
        if (data.designation) formData.append("designation", data.designation);
        if (data.phone) formData.append("phone", data.phone);
        if(data.sessionDuration) formData.append("sessionDuration", data.sessionDuration);
        // changing can lock out user from his account forever
        if (data.email) formData.append("email", data.email);
        if (data.address) formData.append("address", data.address);
        if (data.fileToUpload) formData.append("image", data.fileToUpload);
        if (data.oldPassword && data.newPassword) {
            formData.append("oldPassword", data.oldPassword);
            formData.append("newPassword", data.newPassword);
        }
        if ('boolean' === typeof data.notificationOnNewChatMessage) {
            formData.append("notificationOnNewChatMessage", data.notificationOnNewChatMessage);
        }
        if ('boolean' === typeof data.notificationOnNewAlert) {
            formData.append("notificationOnNewAlert", data.notificationOnNewAlert);
        }
        if ('boolean' === typeof data.notificationOnNewAdminAdded) {
            formData.append("notificationOnNewAdminAdded", data.notificationOnNewAdminAdded);
        }
        if ('boolean' === typeof data.notificationWhileConnected) {
            formData.append("notificationWhileConnected", data.notificationWhileConnected);
        }
        if ('boolean' === typeof data.summaryReportEnabled) {
            formData.append("summaryReportEnabled", data.summaryReportEnabled);
        }
        if (data.summaryReportPeriod) formData.append("summaryReportPeriod", data.summaryReportPeriod);
        if ('boolean' === typeof data.allowSupportLogon) {
            formData.append("allowSupportLogon", data.allowSupportLogon);
        }
        try {
            const res = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/me`, formData)
            if (res && res.data && res.data.user) {
                dispatch({
                    type: UPDATE,
                    payload: res.data.user,
                })
            }
            return { message: "account updated" }

        } catch (error) {
            console.error("error in updating profile", error);
            throw new Error(error?.response?.data?.message || "Something went wrong");
        }
    }

    const sendRequestEmailVerification = async () => {
        try {
            const resp = await http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/request-email-verification`);
            return { message: resp?.data?.message || "Email Sent" }
        } catch (error) {
            console.log("error request email verification", error.response.data);
            toast.error(error?.response?.data?.message || "Something went wrong");
            if(typeof error?.response?.data?.emailVerified === 'boolean'){
                dispatch({
                    type: UPDATE,
                    payload: {
                        emailVerified: error.response.data.emailVerified
                    }
                })
            }
            if(error?.response?.data?.message) return {error: error.response.data.message}
        }
    }
    return (
        <AuthContext.Provider
            value={{
                email: localStorage.getItem("email"),
                ...state,
                method: "jwt",
                signIn,
                signInDevice,
                signInAsAdmin,
                signOut,
                signUp,
                requestValidationAccountEmail,
                updateCompany,
                resetPassword,
                sendRestPasswordEmail,
                updateUser,
                updateEmail,
                loadSession,
                validateCompany,
                createAccount,
                requestDemoEmail,
                sendRequestEmailVerification
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }
