import ChatActionTypes from "./chat.types"

const setChatUsersAction = (users) => {
    return { type: ChatActionTypes.SET_CHAT_USERS, payload: users }
}
const setChatDevicesAction = (users) => {
    return { type: ChatActionTypes.SET_CHAT_DEVICES, payload: users }
}

const setConvsationsAction = (convs) => {
    return { type: ChatActionTypes.SET_CHAT_CONVS, payload: convs }
}

// to remove
const setConversationMessages = ({ roomID, messages }) => {
    return {
        type: ChatActionTypes.SET_CHAT_CONV_MESSAGES, payload: {
            roomID, messages
        }
    }
}

const newMessageAction = (message) => {
    return { type: ChatActionTypes.NEW_CHAT_CONV_MESSAGE, payload: message }
}
const setMyChatIDAction = (myId) => {
    return { type: ChatActionTypes.SET_MY_ID, payload: myId }
}

const updateChatRoomStatusAction = (chatRoom) => ({
    type: ChatActionTypes.UPDATE_CHATROOM_STATUS,
    payload: chatRoom
})


const updateOnlineUsersAction = (users) => ({
    type: ChatActionTypes.CHAT_UPDATE_ONLINE_USERS,
    payload: users
})

 

const setOnlineUsersAction = (users) => {
    return { type: ChatActionTypes.SET_ONLINE_USERS, payload: users }
}

const setChatConnectedAction = (isConnected) => {
    return { type: ChatActionTypes.CHAT_SET_IS_CONNECTED, payload: isConnected }
}
const deleteChatUserAction = (userID) => {
    return { type: ChatActionTypes.DELETE_CHAT_USER, payload: userID }
}
const deleteChatDeviceAction = (androidID) => {
    return { type: ChatActionTypes.DELETE_CHAT_DEVICE, payload: androidID }
}
const updateChatDeviceNameAction = (event) => {
    return { type: ChatActionTypes.UPDATE_CHAT_DEVICE_NAME, payload: event }
}
const appendConversationMessages = ({ roomID, messages, offset }) => {
    return {
        type: ChatActionTypes.APPEND_CHAT_CONV_MESSAGES, payload: {
            roomID,
            messages,
            offset: offset || 0,
        }
    }
}

const updateChatRoomsAction = (rooms) => {
    return { type: ChatActionTypes.UPDATE_CHAT_ROOMS, payload: rooms }
}


const updateChatRoomAction = (room) => {
    return { type: ChatActionTypes.UPDATE_CHAT_ROOM, payload: room }
}

const deleteChatRoomAction = (roomID) => {
    return { type: ChatActionTypes.DELETE_CHAT_ROOM, payload: roomID }
}

export {
    setChatUsersAction,
    setChatDevicesAction,
    setOnlineUsersAction,
    setMyChatIDAction,
    newMessageAction,
    setConvsationsAction,
    setConversationMessages,
    updateOnlineUsersAction,
    setChatConnectedAction,
    deleteChatUserAction,
    deleteChatDeviceAction,
    updateChatDeviceNameAction,
    appendConversationMessages,
    updateChatRoomsAction,
    updateChatRoomAction,
    deleteChatRoomAction,
    updateChatRoomStatusAction
}