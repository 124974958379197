const AlertsActionTypes = {
    NEW_ALERT: 'NEW_ALERT',
    REMOVE_ALERT: 'REMOVE_ALERT',
    UPDATE_ALERT_PERIOD_COUNT_EVENTS: "UPDATE_ALERT_PERIOD_COUNT_EVENTS",
    UPDATE_ACTIVE_ALERTS: "UPDATE_ACTIVE_ALERTS",
    UPDATE_LAST_24H_DEVICE_ALERT_COUNT: "UPDATE_LAST_24H_DEVICE_ALERT_COUNT",
    UPDATE_CURRENT_ALERTS_BY_SETTINGS_ID: "UPDATE_CURRENT_ALERTS_BY_SETTINGS_ID",
    UPDATE_PREVIOUS_ALERTS_BY_SETTINGS_ID: "UPDATE_PREVIOUS_ALERTS_BY_SETTINGS_ID"
}

export default AlertsActionTypes
