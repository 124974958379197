const EventsActionTypes = {
    UPDATE_LOG_EVENT: "UPDATE_LOG_EVENT",
    UPDATE_LAST_24H_EVENTS: "UPDATE_LAST_24H_EVENTS",
    UPDATE_DEVICE_ALL_EVENTS: "UPDATE_DEVICE_ALL_EVENTS",
    UPDATE_ALL_DEVICES_ALL_EVENTS: "UPDATE_ALL_DEVICES_ALL_EVENTS",
    UPDATE_SCAN_EVENTS: "UPDATE_SCAN_EVENTS",
    NEW_USER_BADGE_EVENT: "NEW_USER_BADGE_EVENT",
    NEW_SCANS_EVENT: "NEW_SCANS_EVENT",
    UPDATE_USERS_BADGES_EVENTS: "UPDATE_USERS_BADGES_EVENTS",
    UPDATE_SCAN_PER_BDAGE_EVENTS_PERIODE: "UPDATE_SCAN_PER_BDAGE_EVENTS_PERIODE",
    UPDATE_SCAN_PERIOD_COUNT_EVENTS: "UPDATE_SCAN_PERIOD_COUNT_EVENTS",
    UPDATE_LAST_24H_DEVICE_SCAN_COUNT: "UPDATE_LAST_24H_DEVICE_SCAN_COUNT",
    NEW_KEYBOARD_EVENT: "NEW_KEYBOARD_EVENT",
    UPDATE_KEYBOARD_EVENTS: "UPDATE_KEYBOARD_EVENTS",
    UPDATE_SCAN_PERIOD_COUNT_EVENTS_DEVICE: "UPDATE_SCAN_PERIOD_COUNT_EVENTS_DEVICE",
    UPDATE_GPS_HISTORY_EVENTS: "UPDATE_GPS_HISTORY_EVENTS"
}

export default EventsActionTypes