import { useMap } from 'react-leaflet';
import { Box, Slider } from '@mui/material';
import { styled } from '@mui/system';

const PrettoSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.secondary.main,
    height: 8,
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
}));

function CustomZoomControl({ zoom }) {
    const map = useMap();
    const handleChange = (newZoom) => {
        const center = map.getCenter();
        map.flyTo(center, newZoom, {
            duration: 0.25 / Math.abs(zoom - newZoom)
        });
    }
    return (
        <Box className="leaflet-top leaflet-right">
            <Box className="leaflet-control leaflet-bar" style={{ border: 'none' }} sx={{ p: 2 }}>
                <PrettoSlider
                    valueLabelDisplay="auto"
                    max={18}
                    min={3}
                    step={1}
                    value={zoom}
                    sx={{ width: 200 }}
                    onChange={(e, val) => handleChange(val)}
                    slots={{
                        valueLabel: null,
                        markLabel: null,
                    }}
                />
            </Box>
        </Box>
    )
}

export default CustomZoomControl;