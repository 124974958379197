import { useCallback, useEffect, useRef } from 'react';
import { Input } from '@mui/material';
const InputForCustomKeyboard = ({ onChange, ...props }) => {
    const ref = useRef(null);
    const handleChange = useCallback((e) => {
        if (onChange) onChange(e)
    }, [onChange])

    useEffect(() => {
        const input = ref.current;
        if(!input) return;
        const desc = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, "value");
        Object.defineProperty(input, "value", {
            get: desc.get,
            set: function (v) {
                handleChange({ target: { value: v } });
                desc.set.call(this, v);
            }
        });
    }, [handleChange])

    return <Input
        {...props}
        inputRef={ref}
        onChange={handleChange}
    />
}
export default InputForCustomKeyboard;