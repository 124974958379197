import React from "react";
import { styled } from '@mui/system';
import { useSelector } from "react-redux";
import {
  Badge,
  Grid2,
  Avatar,
  Box
} from "@mui/material";
import useAuth from "hooks/useAuth";
import { imgURL } from "utils/http"

const Footer = styled(Box)`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-shrink: 0;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) => props.color === 'primary' ?
    props.theme.sidebar.footer.online.background :
    props.theme.sidebar.footer.offline.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const FooterMinimized = ({ ...rest }) => {
  const { userID, displayName, imageUrl } = useAuth();
  const isConnected = useSelector((state) => state.chat.isConnected);
  return (
    <Footer {...rest}>
      <Grid2 container spacing={2}>
        <Grid2>
          <FooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
            color={isConnected ? 'primary' : 'secondary'}
          >
            {userID && <Avatar
              alt={displayName}
              src={imgURL + (imageUrl || '/images/default-user.png')}
            />}
          </FooterBadge>
        </Grid2>
      </Grid2>
    </Footer>
  );
};

export default FooterMinimized;
