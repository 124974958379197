import React, {
    useState,
    useEffect,
    useMemo
} from 'react';
import {
    Grid2,
    Typography,
    Card,
    CardContent,
    Button,
    LinearProgress,
    Box,
    Alert,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CircleIcon from '@mui/icons-material/Circle';
import SquareIcon from '@mui/icons-material/Square';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import DeviceKeyboardKeyEvent from 'components/DeviceKeyboardKeyEvent';
import HTMLTooltip from 'components/HtmlTooltip';
import { isValidLogoutSequence } from 'utils/badge';
import codes from 'constants/codes'
import { getEvents } from 'utils/api';
import { updateKeyboardEventsAction } from 'redux/events/events.actions';
import settingType from 'constants/settingType';

const Key = ({ word, level, date, keyMap, deleteKey }) => {
    return <Stack direction="column" spacing={0}>
        <DeviceKeyboardKeyEvent
            word={word}
            level={level}
            date={date}
            keyMap={keyMap}
        />
        {deleteKey && <span style={{ textAlign: "center" }}>
            <IconButton
                color="error"
                size="small"
                onClick={deleteKey}
            >
                <DeleteIcon />
            </IconButton>
        </span>}
    </Stack>
}

const ShortCutRecorder = ({
    keysLogoutSequence,
    updateLogoutSequence,
    keyMap,
    selectedKeyboardSettingID,
    setSelectedKeyboardSettingID
}) => {
    const devices = useSelector(state => state.devices);
    const [androidID, setAndroidID] = useState(null);
    const [limit, setLimit] = useState(2);
    const dispatch = useDispatch();
    const settings = useSelector((state) => state.settings);
    const keyboardSetting = useMemo(() => settings.filter(el => el.type === settingType.KEYBOARD), [settings]);
    const events = useSelector((state) => state.events.keyboardEvents || []);
    useEffect(() => {
        const _limit = Math.max(2, Math.min(parseInt(limit), 100));
        const fetchCall = getEvents({
            messageID: codes.TE_KEYBOARD_EVENT,
            limit: _limit,
            androidID
        })
            .then(events => dispatch(updateKeyboardEventsAction(events.reverse())));
        return () => {
            fetchCall.cancel();
        }
    }, [androidID, limit, dispatch]);
    useEffect(() => {
        return () => {
            setSelectedKeyboardSettingID(null)
        }
    }, [setSelectedKeyboardSettingID])
    const keyboardEvents = useMemo(() => {
        const _limit = Math.max(2, Math.min(parseInt(limit), 100));
        if (!androidID) return [...events].slice(-_limit);
        return [...events.filter(el => el.androidID === androidID)].slice(-_limit);
    }, [events, limit, androidID]);
    const [isRecording, setIsRecording] = useState(false);
    const [recordedKeys, setRecordedKeys] = useState([]);
    const handleStartRecording = () => {
        setIsRecording(true);
    }
    const handleStopRecording = () => {
        setIsRecording(false);
        updateLogoutSequence(recordedKeys.map(el => ({
            modifier: el.level || 0,
            keyCode: parseInt(el.word)
        })))
    }
    useEffect(() => {
        if (isRecording) setRecordedKeys(keyboardEvents);
    }, [keyboardEvents, isRecording])
    useEffect(() => {
        if (!isRecording) setRecordedKeys(keysLogoutSequence)
    }, [keysLogoutSequence, isRecording]);

    const handleDeleteKey = (index) => {
        if (!keysLogoutSequence[index]) return;
        const _keysLogoutSequence = [...keysLogoutSequence];
        _keysLogoutSequence.splice(index, 1);
        updateLogoutSequence(_keysLogoutSequence.map(el => ({
            modifier: el.level,
            keyCode: parseInt(el.word)
        })))
    }

    return <Grid2
        container
        spacing={2}
    >
        <Grid2 size={12}>
            {isRecording ? <LinearProgress color="error" /> : <Box sx={{ width: '100%', height: 4 }} />}
        </Grid2>
        <Grid2 size={12}>
            {isRecording ?
                (
                    <Alert severity='error'>
                        <Typography variant="body1" gutterBottom>
                            Recording...
                        </Typography>
                        <Typography component="p" variant="caption">
                            * Keyboard Events from your devices will appear here
                        </Typography>
                        <Typography component="p" variant="caption">
                            * You can delete unwanted keys once you stop recording
                        </Typography>
                    </Alert>
                )
                :
                (
                    <Alert severity='info'>
                        <Typography variant="body1" gutterBottom>
                            Click Record to view events from your physical devices
                        </Typography>
                        <Typography variant="caption">
                            * You can use also use latest events from devices
                        </Typography>
                    </Alert>
                )}
        </Grid2>
        <Grid2>
            <Button
                variant="contained"
                size="small"
                color="secondary"
                disabled={isRecording}
                onClick={handleStartRecording}
                endIcon={<CircleIcon color={isRecording ? "error" : ""} />}
            >
                Record
            </Button>
        </Grid2>
        <Grid2>
            <Button
                variant="contained"
                size="small"
                color="secondary"
                disabled={!isRecording}
                onClick={handleStopRecording}
                endIcon={<SquareIcon color={isRecording ? "primary" : ""} />}
            >
                Stop
            </Button>
        </Grid2>
        <Grid2>
            <FormControl sx={{ width: 160 }}>
                <InputLabel id="keyboard-setting-select-label">Keyboard Map</InputLabel>
                <Select
                    labelId="keyboard-setting-select-label"
                    id="keyboard-setting-select"
                    value={selectedKeyboardSettingID || "-"}
                    label="Keyboard Map"
                    size="small"
                    onChange={(e) => setSelectedKeyboardSettingID(e.target.value === "-" ? null : e.target.value)}
                >
                    <MenuItem value={"-"}>From Group</MenuItem>
                    {keyboardSetting.map(el => (<MenuItem key={el._id} value={el._id} disabled={el.keys?.length === 0}> {el.keys?.length === 0 ? "(*empty)" : ""} {el.name || el._id}</MenuItem>))}
                </Select>
            </FormControl>
        </Grid2>
        {isRecording && <React.Fragment>
            <Grid2>
                <FormControl sx={{ width: 120 }}>
                    <InputLabel id="device-select-label">Device</InputLabel>
                    <Select
                        labelId="device-select-label"
                        id="device-select"
                        value={androidID || "-"}
                        label="Device"
                        size="small"
                        onChange={(e) => setAndroidID(e.target.value === "-" ? null : e.target.value)}
                    >
                        <MenuItem value={"-"}>All</MenuItem>
                        {devices.map(el => (<MenuItem key={el.androidID} value={el.androidID}>{el.name || el.androidID}</MenuItem>))}
                    </Select>
                </FormControl>
            </Grid2>
            <Grid2>
                <FormControl sx={{ width: 60 }}>
                    <InputLabel id="limit-select-label">Limit</InputLabel>
                    <Select
                        labelId="limit-select-label"
                        id="limit-select"
                        value={limit}
                        label="limit"
                        size="small"
                        onChange={(e) => setLimit(e.target.value)}
                    >
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                    </Select>
                </FormControl>
            </Grid2>
        </React.Fragment>}
        <Grid2 size={12}>
            <Card variant="outlined">
                <CardContent>
                    <Stack
                        direction="row"
                        alignItems="center"
                        flexWrap="wrap"
                        spacing={1}
                    >
                        {recordedKeys.map((el, index) => <Key
                            {...el}
                            key={`${el.word}-${el.level}-${index}`}
                            deleteKey={isRecording ? undefined : () => handleDeleteKey(index)}
                            keyMap={keyMap}
                        />)}
                    </Stack>
                </CardContent>
            </Card>
        </Grid2>
    </Grid2>
}

const LogoutSequence = ({ badgeLogoutSequence = [], editing, setIsValidLogoutSequence, updateProperties }) => {
    useEffect(() => {
        setIsValidLogoutSequence(isValidLogoutSequence(badgeLogoutSequence));
    }, [badgeLogoutSequence, setIsValidLogoutSequence]);
    const handleUpdateLogoutSequence = (badgeLogoutSequence) => {
        updateProperties({ badgeLogoutSequence });
    }
    const [selectedKeyboardSettingID, setSelectedKeyboardSettingID] = useState(null);
    const settings = useSelector((state) => state.settings);
    const selectedGroupID = useSelector((state) => state.groups.selectedGroup || null);
    const groups = useSelector(state => state.groups.groups);
    const selectedKeyboardSetting = useMemo(() => {
        if (!selectedGroupID && !selectedKeyboardSettingID) return null;
        if (selectedKeyboardSettingID) return settings.find(setting => setting._id === selectedKeyboardSettingID);
        const selectedGroup = groups.find(group => group._id === selectedGroupID)
        if (!selectedGroup?.keyboardSetting) return null;
        return settings.find(setting => setting._id === selectedGroup.keyboardSetting);
    }, [groups, settings, selectedGroupID, selectedKeyboardSettingID]);
    const keyboardKeys = useMemo(() => selectedKeyboardSetting?.keys || [], [selectedKeyboardSetting]);
    const keyMap = useMemo(() => keyboardKeys.reduce((p, c) => ({ ...p, [`${c.code}`]: c }), {}), [keyboardKeys])
    const keysLogoutSequence = useMemo(() => badgeLogoutSequence.map(el => ({
        level: el.modifier,
        word: `${el.keyCode}`
    })), [badgeLogoutSequence]);
    return <Card variant="outlined">
        <CardContent>
            <Grid2 container rowSpacing={2}>
                <Grid2 size={4}>
                    <Typography variant="h6" >
                        Logout Sequence
                    </Typography>
                </Grid2>
                <Grid2 size={8}>
                    {editing ? <ShortCutRecorder
                        keysLogoutSequence={keysLogoutSequence}
                        updateLogoutSequence={handleUpdateLogoutSequence}
                        keyMap={keyMap}
                        selectedKeyboardSettingID={selectedKeyboardSettingID}
                        setSelectedKeyboardSettingID={setSelectedKeyboardSettingID}
                    />
                        :
                        <Stack
                            direction="row"
                            alignItems="center"
                            flexWrap="wrap"
                            spacing={1}
                        >
                            {keysLogoutSequence.map((el, index) => <Key
                                {...el}
                                key={index}
                                keyMap={keyMap}
                            />)}
                        </Stack>}
                </Grid2>
                <Grid2 size={12}>
                    {Object.keys(keyMap).length === 0 && <Alert
                        severity="warning"
                        action={
                            <HTMLTooltip
                                title={<Box>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Keyboard Keys Map</strong> is used to translate keys codes to human readable key names such as
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        24 {" \u2B95 "} VOLUME UP
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>The Map</strong> can be created/edited from your <strong>Keyboard Settings</strong>
                                    </Typography>
                                    <Typography variant="caption" component="p" gutterBottom>
                                        * Key Map is applayed from current selected group (if it has one)
                                    </Typography>
                                    <Typography variant="caption" component="p" gutterBottom>
                                        * In edit mode You can disable or apply custom key map
                                    </Typography>
                                </Box>}
                            >
                                <InfoIcon color="primary" />
                            </HTMLTooltip>
                        }
                    >
                        Keyboard Keys Map is empty
                    </Alert>}
                </Grid2>
            </Grid2>
        </CardContent>
    </Card>
}

export default LogoutSequence;