import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import { NavLink } from 'react-router-dom';
import {
    Grid2,
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography,
    FormGroup,
    FormControlLabel,
    Switch
} from '@mui/material';
import { spacing } from '@mui/system';
import Profiles from './Profiles';
import {
    updateRolesAction,
    updateAdminsAction
} from 'redux/admins/admins.actions';

import {
    getRoles,
    getAdmins,
} from 'utils/api';
import AdminsMenuList from './AdminsMenuList';
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function AdminPermissions() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const admins = useSelector(state => state.admins.admins || []);
    const selectedAdmin = useMemo(() => admins.find(admin => admin._id === id), [admins, id]);
    useEffect(() => {
        const fetchCalls = [
            getAdmins().then(admins => dispatch(updateAdminsAction(admins || []))),
            getRoles().then(roles => dispatch(updateRolesAction(roles || [])))
        ];
        return () => {
            fetchCalls.forEach(fetchCall => fetchCall.cancel())
        }
    }, [dispatch])

    const [advancedMode, setAdvancedMode] = useState(false);
    return (
        <React.Fragment>
            <Typography variant="h3" gutterBottom display="inline">
                {selectedAdmin?.displayName || "Admin"} Permissions
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" sx={{ mt: 2 }}>
                <Link component={NavLink} to="/">
                    Settings
                </Link>
                <Link component={NavLink} to="/settings/admins">
                    Administrators
                </Link>
                <Typography>{selectedAdmin?.displayName || selectedAdmin?._id || "Permissions"}</Typography>
            </Breadcrumbs>
            <Divider sx={{ my: 6 }} />
            <Grid2 container spacing={6} justifyContent="space-between">
                <Grid2>
                    <AdminsMenuList selectedAdmin={selectedAdmin} />
                </Grid2>
                {selectedAdmin && <Grid2>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch
                                checked={advancedMode}
                                onChange={(e) => setAdvancedMode(e.target.checked)} />}
                            label="Advanced Mode" />
                    </FormGroup>
                </Grid2>}
                {selectedAdmin && <Grid2 size={12}>
                    <Profiles selectedAdminRoleID={selectedAdmin.roles[0]} adminID={id} advancedMode={advancedMode} />
                </Grid2>}
            </Grid2>
        </React.Fragment >
    );
}

export default AdminPermissions;
