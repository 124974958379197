import { useState, useMemo } from 'react'
import {
    Typography,
    Grid2,
    Tooltip,
    Avatar,
    Box,
    Modal,
    IconButton
} from '@mui/material';
import { styled } from '@mui/system';
import { rgba } from 'polished';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import BoltIcon from '@mui/icons-material/Bolt';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import Participant from '../Participant';
import chatMessageType from 'constants/chatMessageType';
import { baseURL } from 'utils/http'
import HtmlTooltip from 'components/HtmlTooltip';

const MessageDetailsWrapper = styled(Grid2)`
    background-color: ${props => props.theme.palette.background.paper};
    padding: 0 10px;
`

const ChatMessageContainer = styled(Box)`
    display: flex;
    margin: 8px;
    justify-content: ${props => props.$recieved ? "start" : "end"};
`

const ChatMessageWrapper = styled(Box)`
    max-width: 95%;
`

const ChatText = styled(Typography)`
    background-color: ${props => props.$highPriority ? props.theme.palette.error.main : (props.$recieved ?
        props.theme.palette.background.default : props.theme.palette.secondary.main)};
    padding: 4px 10px;
    border-radius: 10px;
    border-top-right-radius: ${props => props.$recieved ? "10px" : "0"};
    border-top-left-radius: ${props => props.$recieved ? "0" : "10px"};
`;

const MessageDetails = ({ participants, seenByMap, deliveredToMap, myID, senderID }) => {
    return <MessageDetailsWrapper container spacing={1}>
        {participants.filter(el => ![myID, senderID].includes(el._id)).map(el => <Grid2 size={12} key={el._id} container spacing={1}>
            <Grid2 flexGrow={1}><Participant {...el} /></Grid2>
            {seenByMap[el._id] && <Grid2 size={1}>
                <Tooltip title="Seen"><VisibilityIcon color="action" size="small" /></Tooltip>
            </Grid2>}
            {!seenByMap[el._id] && deliveredToMap[el._id] && <Grid2 size={1}>
                <Tooltip title="Delivered"><CheckCircleIcon color="action" size="small" /></Tooltip>
            </Grid2>}
            {!seenByMap[el._id] && !deliveredToMap[el._id] && <Grid2 size={1}>
                <Tooltip title="Sent"><CheckCircleOutlineIcon color="action" size="small" /></Tooltip>
            </Grid2>}
            <Grid2 size={1} />
        </Grid2>)}
    </MessageDetailsWrapper>
}

const MessageStatusWrapper = styled(Box)`
    display: flex;
    justify-content: end;
    margin: 4px;
`

const MessageStatus = ({ seenByMap, deliveredToMap, participants, myID, senderID }) => {
    return <MessageStatusWrapper>{participants.map(el => {
        if ([myID, senderID].includes(el._id)) return null;
        if (seenByMap[el._id]) return <Avatar
            key={el._id}
            alt={el.displayName || el._id}
            src={el.profilePicture}
            sx={{ width: 16, height: 16 }}
        />
        if (deliveredToMap[el._id]) return <CheckCircleIcon key={el._id} sx={{ width: 16, height: 16 }} />
        return <CheckCircleOutlineIcon key={el._id} sx={{ width: 16, height: 16 }} />
    })}
    </MessageStatusWrapper>
}

const MessageFilePreviewWrapper = styled(Box)`
    display: flex;
    background-color: ${props => props.$recieved ?
        props.theme.palette.background.default : props.theme.palette.secondary.main};
    margin: 2px 0 4px 0;
    padding: 4px 10px;
    border-radius: 10px;
    border-top-right-radius: ${props => props.$recieved ? "10px" : "0"};
    border-top-left-radius: ${props => props.$recieved ? "0" : "10px"};
`;



const MessageFilePreview = ({ message, recieved }) => {
    return <MessageFilePreviewWrapper $recieved={recieved}>
        <Typography variant="caption">{message.resourceName}</Typography>
        <a
            href={`${baseURL}${message.resourceUrl}?download=true`}
            download={message.resourceName}
            type="application/octet-stream"
        >
            <IconButton>
                <DownloadIcon />
            </IconButton>
        </a>
    </MessageFilePreviewWrapper>
}

const MessageImagePreviewWrapper = styled(Box)`
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${props => props.$recieved ?
        props.theme.palette.background.default : props.theme.palette.secondary.main};
    margin: 2px 0 4px 0;
    padding: 4px 10px;
    border-radius: 10px;
    border-top-right-radius: ${props => props.$recieved ? "10px" : "0"};
    border-top-left-radius: ${props => props.$recieved ? "0" : "10px"};
`;

const ChatMessageImagePreview = styled(Box)`
    width: 150px;
    height: 150px;
    background-image: url(${props => props.$imgSrc});
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
`;

const ModalContentWrapper = styled(Box)`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80vw;
    min-width: 10vh;
    color: ${props => props.theme.palette.text.primary};
    background-color: ${props => props.theme.palette.background.paper};
    border: 2px solid ${props => props.theme.palette.text.secondary};
    box-shadow: 0px 1px 10px 1px ${props => rgba(props.theme.palette.text.secondary, 0.2)};
    padding: 10px;
    border-radius: 10px;
`

const ModalImage = styled('img')`
    max-width: 100%;
    max-height: 80vh;
`

const MessageViewModal = ({ isOpen, handleClose, message }) => {
    return <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <ModalContentWrapper>
            <ModalImage
                src={`${baseURL}${message.resourceUrl}`}
                alt={message.resourceName || "image"}
            />
            <Box>
                <Typography variant="body1" display="inline" gutterBottom>
                    {message.resourceName}
                </Typography>
                <a
                    href={`${baseURL}${message.resourceUrl}?download=true`}
                    download={message.resourceName}
                >
                    <IconButton>
                        <DownloadIcon />
                    </IconButton>
                </a>
            </Box>

        </ModalContentWrapper>
    </Modal>
}
const ImageFilePreview = ({ message, recieved }) => {
    const [isOpen, setIsOpen] = useState(false);
    return <MessageImagePreviewWrapper $recieved={recieved}>
        <Typography variant="caption">{message.resourceName}</Typography>
        <ChatMessageImagePreview
            $imgSrc={baseURL + message.resourceUrl}
            onClick={() => setIsOpen(true)}
        />
        <MessageViewModal message={message} isOpen={isOpen} handleClose={() => setIsOpen(false)} />
    </MessageImagePreviewWrapper>
}


const ChatMessage = ({ participants, message, userName, myID, highPriority, recieved, seenBy = [], deliveredTo = [] }) => {
    const deliveredToMap = useMemo(() => deliveredTo.reduce((a, c) => ({ ...a, [c]: true }), {}), [deliveredTo]);
    const seenByMap = useMemo(() => seenBy.reduce((a, c) => ({ ...a, [c]: true }), {}), [seenBy]);
    return (
        (<ChatMessageContainer
            $recieved={recieved}
        >
            <ChatMessageWrapper
                $recieved={recieved}
                $highPriority={highPriority}
            >

                <Typography variant="caption">{message.from !== myID && userName ? <strong>{userName}</strong> : ""} {" "} {message.time}</Typography>
                {highPriority && (/^[0-9a-f]{16}$/i.test(message.from) ? <Tooltip title="High Priority Message Response">
                    <OfflineBoltIcon color="error" sx={{ m: 1 }} />
                </Tooltip> : <Tooltip title="High Priority Message">
                    <BoltIcon color="error" sx={{ m: 1 }} />
                </Tooltip>)}
                {message.type === chatMessageType.FILE && <MessageFilePreview message={message} recieved={recieved} />}
                {message.type === chatMessageType.IMAGE && <ImageFilePreview message={message} recieved={recieved} />}
                {message.resourceName !== message.text && <ChatText
                    variant="subtitle1"
                    color="text.primary"
                    $recieved={recieved}
                    $highPriority={highPriority}
                >
                    {message.text}
                </ChatText>}
                <HtmlTooltip title={<MessageDetails
                    participants={participants}
                    seenByMap={seenByMap}
                    deliveredToMap={deliveredToMap}
                    myID={myID}
                    senderID={message.from}
                />}
                    backgroundColor="transparent"
                    border="1px solid transparent"
                >
                    <Box>
                        <MessageStatus participants={participants} seenByMap={seenByMap} deliveredToMap={deliveredToMap} myID={myID} senderID={message.from} />
                    </Box>
                </HtmlTooltip>

            </ChatMessageWrapper>
        </ChatMessageContainer>)
    );
}

export default ChatMessage;