import settingType from "constants/settingType";
const settingsTypesInfos = {
    [settingType.SCAN]: {
        value: settingType.SCAN,
        name: 'Scan'
    },
    [settingType.RTT]: {
        value: settingType.RTT,
        name: 'RTT / BLE'
    },
    [settingType.KEYBOARD]: {
        value: settingType.KEYBOARD,
        name: 'Keyboard'
    },
    [settingType.ALERTS]: {
        value: settingType.ALERTS,
        name: 'Alerts'
    },
    [settingType.GEOFENCING]: {
        value: settingType.GEOFENCING,
        name: 'Geofencing'
    },
    [settingType.BADGE]: {
        value: settingType.BADGE,
        name: 'Badge'
    },
    [settingType.TUNNEL]: {
        value: settingType.TUNNEL,
        name: 'Tunnel'
    }
};
export default settingsTypesInfos;