import L from 'leaflet';
import markerIcon from './marker-icon.svg';
import markerGroupIcon from './marker-icon.svg';
import markerTargetIcon from './marker-target-icon.svg';
import markerResizeIcon from './marker-resize-icon.svg';
import markerSelectIcon from './marker-select-icon.svg';

const DeviceMarker = new L.Icon({
    iconUrl: markerIcon,
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [-3, -60],
    shadowUrl: null,
    shadowSize: [0, 0],
    shadowAnchor: [0, 0],
    className: 'leaflet-div-icon-device'
});

// pane > className
//      > style
const DeviceGroupMarker = new Array(11);
for (let i = 0; i <= 10; i++) {
    DeviceGroupMarker[i] = new L.divIcon({
        iconUrl: markerGroupIcon,
        iconSize: [50, 50],
        iconAnchor: [19, 50],
        popupAnchor: [-3, -60],
        shadowUrl: null,
        shadowSize: [0, 0],
        shadowAnchor: [0, 0],
        className: 'leaflet-div-icon-device-group',
        html: `
            <div class="pointer-multiple-devices-container">
                <img class="leaflet-div-icon-group-device" width="50" height="50" src="${markerIcon}"/>
                <p class="pointer-multiple-devices-number">${i > 9 ? '+9' : i}</p>
            </div>
            `
    });
}

const TargetMarker = new L.Icon({
    iconUrl: markerTargetIcon,
    iconSize: [60, 60],
    iconAnchor: [30, 30],
    popupAnchor: [0, -30],
    shadowUrl: null,
    shadowSize: [0, 0],
    shadowAnchor: [0, 0],
    className: 'leaflet-div-icon-target'
});


const ResizeMarker = new L.Icon({
    iconUrl: markerResizeIcon,
    iconSize: [30, 30],
    iconAnchor: [15, 15],
    popupAnchor: [0, -15],
    shadowUrl: null,
    shadowSize: [0, 0],
    shadowAnchor: [0, 0],
    className: 'leaflet-div-icon-resize'
});

const SelectMarker = new L.Icon({
    iconUrl: markerSelectIcon,
    iconSize: [30, 30],
    iconAnchor: [15, 15],
    popupAnchor: [0, -15],
    shadowUrl: null,
    shadowSize: [0, 0],
    shadowAnchor: [0, 0],
    className: 'leaflet-div-icon-select'
});
export { DeviceMarker, DeviceGroupMarker, TargetMarker, ResizeMarker, SelectMarker };
