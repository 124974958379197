import SupportActionTypes from "./support.types";

const initialState = {
    companies: [],
    articles: [],
    notifications: [],
    keyboards: [],
    emails: [],
    duplicatedDevices: [],
}

const supportReducer = (state = initialState, action) => {
    switch (action.type) {
        case SupportActionTypes.SUPPORT_UPDATE_ALL_COMPANIES:
            return { ...state, companies: action.payload }
        case SupportActionTypes.SUPPORT_UPDATE_ALL_ARTICLES:
            return { ...state, articles: action.payload }
        case SupportActionTypes.SUPPORT_UPDATE_ARTICLE:
            return { ...state, articles: [action.payload, ...state.articles.filter(el => el._id !== action.payload._id)] }
        case SupportActionTypes.SUPPORT_DELETE_ARTICLE:
            return { ...state, articles: state.articles.filter(el => el._id !== action.payload) }
        case SupportActionTypes.SUPPORT_UPDATE_ALL_NOTIFICATIONS:
            return { ...state, notifications: action.payload }
        case SupportActionTypes.SUPPORT_UPDATE_NOTIFICATION:
            return { ...state, notifications: [action.payload, ...state.notifications.filter(el => el._id !== action.payload._id)] }
        case SupportActionTypes.SUPPORT_DELETE_NOTIFICATION:
            return { ...state, notifications: state.notifications.filter(el => el._id !== action.payload) }
        case SupportActionTypes.SUPPORT_UPDATE_ALL_SAMPLE_KEYBOARDS:
            return { ...state, keyboards: action.payload }
        case SupportActionTypes.SUPPORT_UPDATE_SAMPLE_KEYBOARD:
            return { ...state, keyboards: [action.payload, ...state.keyboards.filter(el => el._id !== action.payload._id)] }
        case SupportActionTypes.SUPPORT_DELETE_SAMPLE_KEYBOARD:
            return { ...state, keyboards: state.keyboards.filter(el => el._id !== action.payload) }
        case SupportActionTypes.SUPPORT_UPDATE_ALL_EMAILS:
            return { ...state, emails: action.payload }
        case SupportActionTypes.SUPPORT_UPDATE_DUPLICATED_DEVICES:
            return { ...state, duplicatedDevices: action.payload }
        default:
            return state;
    }
}

export default supportReducer;
