import React, { useMemo, useCallback } from "react";
import useAuth from 'hooks/useAuth';

const AuthorizationContext = React.createContext({});

function AuthorizationProvider({ children }) {
  const { permissions = [] } = useAuth();
  const authorizations = useMemo(() => permissions
    .filter(el => /^[a-z]+\.[a-z]+\.[a-z]+/i.test(el))
    .map(el => el.split(".").slice(1).join('.'))
    .reduce((p, c) => ({ ...p, [c]: true }), {})
    , [permissions]);
  const isAuthorized  = useCallback((permission, mode = "every")=> {
    if(!permission) return false;
    if(typeof permission === "string") return !!authorizations[permission];
    if(Array.isArray(permission) && mode === "some") return permission.some(el => !!authorizations[el]);
    return permission.every(el => !!authorizations[el]);

  },[authorizations]);
  return (
    <AuthorizationContext.Provider value={{ authorizations, isAuthorized}}>
      {children}
    </AuthorizationContext.Provider>
  );
}

export { AuthorizationProvider, AuthorizationContext };

