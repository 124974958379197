const sessionDuration = {
    SESSION_8HOURS: '8h',
    SESSION_12HOURS:'12h',
    SESSION_1DAYS:'24h',
    SESSION_3DAYS:'72h',
    SESSION_5DAYS:'120h',
    SESSION_7DAYS: '168h',
};

export default sessionDuration;
