import React, { useMemo } from 'react';
import {
  Grid2,
  Tooltip,
  Card,
  CardContent
} from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { formatTimeDate } from 'utils/timeFormat';
import { useSelector } from 'react-redux';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import usePreferences from 'hooks/usePreferences';
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';
import LocationOffIcon from '@mui/icons-material/LocationOff';

const renderIsConnected = (data) => {
  const isConnected = data && data.row ? data.row.isConnected : null;
  switch (isConnected) {
    case true:
      return <SignalWifiStatusbar4BarIcon color="primary" />;
    case false:
      return <SignalWifiOffIcon color="error" />;
    default:
      return <QuestionMarkIcon />;
  }
}
const APMac = ({ mac }) => {
  if (!mac) return <Tooltip title="Not associated with an access point">
    <WifiTetheringOffIcon color="error" />
  </Tooltip>
  if (mac === "02:00:00:00:00:00") return <Tooltip title=" permissions were not granted for location tracking">
    <LocationOffIcon color="error" />
  </Tooltip>
  return mac
}

const preferencesID = "pages-logs-WiFiDropLog-WifiDropLogTable";

const WifiDropLogTable = ({ setSelectedRows, apiRef, rows }) => {
  
  const { preferences, updatePreferences } = usePreferences();
  const initialState = useMemo(() => preferences[preferencesID] || {}, [preferences]);
  const groups = useSelector((state) => state.groups.groups);
  const groupMap = useMemo(() => {
    const _groupMap = {};
    groups.forEach(group => {
      group.devices.forEach(el => _groupMap[el] = group.name);
    })
    return _groupMap;
  }, [groups])
  const devices = useSelector((state) => state.devices || []);
  const devicesNameMap = useMemo(() => devices.reduce((p, c) => ({ ...p, [c.androidID]: c.name || c.androidID }), {}), [devices]);
  const handleSelectionModelChange = () => {
    if (apiRef.current) {
      const _selectedRowsMap = apiRef.current.getSelectedRows();
      const _selectedRows = [];
      _selectedRowsMap.forEach(el => {
        if (el.properties && Number.isFinite(parseFloat(el.properties.latitude)) && Number.isFinite(parseFloat(el.properties.longitude)) && Number.isFinite(parseFloat(el.properties.radius))) {
          _selectedRows.push({
            lat: parseFloat(el.properties.latitude),
            lon: parseFloat(el.properties.longitude),
            radius: parseFloat(el.properties.radius),
            androidID: el.androidID
          });
        }
      })
      setSelectedRows(_selectedRows);
    }
  }

  const _columns = useMemo(() => {
    return [
      {
        field: "date",
        headerName: "Date",
        width: 175,
        valueFormatter: (value) => formatTimeDate(value),
        editable: false,
      },
      {
        field: "ipaddr",
        headerName: "IP Address",
        width: 140,
        editable: false,
      },
      {
        field: "mac",
        headerName: "AP MAC",
        width: 140,
        renderCell: (params) => <APMac mac={params.value} />,
        valueGetter: (value, row) => row.properties?.mac || "",
        editable: false,
      },
      {
        field: "messageID",
        headerName: "Message ID",
        width: 200,
        editable: false
      },
      {
        field: "androidID",
        headerName: "AndroidID",
        width: 170,
        editable: false
      },
      {
        field: "device",
        headerName: "Device",
        width: 170,
        editable: false,
        valueGetter: (value, row) => devicesNameMap[row.androidID] || "",
      },
      {
        field: 'group',
        headerName: "Group",
        width: 130,
        valueGetter: (value, row) => groupMap[row.androidID] || "",
        editable: false,
      },
      {
        field: "isConnected",
        headerName: "Is Connected",
        width: 130,
        editable: false,
        renderCell: (params) => renderIsConnected(params)
      },
      {
        field: "latitude",
        headerName: "Latitude",
        width: 120,
        editable: false,
        renderCell: (params) => params.row.properties?.latitude || "",
      },
      {
        field: "longitude",
        headerName: "Longitude",
        width: 120,
        editable: false,
        renderCell: (params) => params.row.properties?.longitude || "",
      },
      {
        field: "radius",
        headerName: "Horizontal Accuracy",
        width: 120,
        editable: false,
        renderCell: (params) => params.row.properties?.radius || "",
      },
      {
        field: "id",
        headerName: "ID",
        width: 250,
        editable: false
      }
    ];
  }, [groupMap, devicesNameMap])

  const columns = React.useMemo(() => {
    let error = false;
    if (initialState &&
      initialState.columns &&
      initialState.columns.cols) {
      const cols = new Array(_columns.length);
      _columns.forEach((el) => {
        if (!initialState.columns.cols[el.field]) {
          error = true;
          return;
        }
        cols[initialState.columns.cols[el.field].order] = { ...el, width: initialState.columns.cols[el.field].width }
      })
      if (!error) return cols;
    }
    return _columns;
  }, [initialState, _columns]);

  return (
    (<Card sx={{ mb: 1 }}>
      <CardContent>
        <Grid2 container justifyContent="center">
          <Grid2 size={12}>
            <div style={{ height: 350, width: "100%" }}>
              <DataGridPro
                onStateChange={(state) => {
                  updatePreferences(preferencesID, {
                    density: state.density,
                    sorting: {
                      sortModel: state.sorting.sortModel,
                    },
                    filter: {
                      filterModel: state.filter.filterModel
                    },
                    columns: {
                      columnVisibilityModel: state.columns.columnVisibilityModel,
                      cols: apiRef.current.getAllColumns().map(({ field, width }, index) => ({ field, order: index, width })).reduce((p, c) => ({ ...p, [c.field]: c }), {}),
                    }
                  })
                }}
                initialState={initialState}
                apiRef={apiRef}
                rows={rows}
                columns={columns}
                getRowId={(row) => row._id}
                slots={{
                  toolbar: GridToolbar,
                }}
                onRowSelectionModelChange={handleSelectionModelChange}
              />
            </div>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>)
  );
}

export default React.memo(WifiDropLogTable);