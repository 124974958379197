import React, { useEffect } from 'react';
import { Circle, Marker, Popup as leafletPopup } from 'react-leaflet'
import { useState, useMemo, useRef } from 'react';
import { TargetMarker, ResizeMarker } from './Icons/Icons'
import { useTheme } from "@mui/material/styles";
import { rgba } from "polished";
import { Grid2, TextField } from '@mui/material';
import { styled } from '@mui/system';
const Popup = styled(leafletPopup)`
    .leaflet-popup-content-wrapper{
        background-color: ${props => props.theme.palette.background.paper};
        color: ${props => props.theme.palette.text.primary};
    }
    .leaflet-popup-tip {
        background-color: ${props => props.theme.palette.background.paper};
        color: ${props => props.theme.palette.text.primary};
    }
    .leaflet-popup-content{ 
        color: ${props => props.theme.palette.text.primary};
    }
`;


function getRadius(lat1, lon1, lat2, lon2) {
    var R = 6378.137; // Radius of earth in KM
    var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
    var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return parseInt(d * 1000);
}
function getNewLongitude(position, radius) {
    return position.lng + (radius / 1000 / (111.320 * Math.cos(position.lat * (Math.PI / 180))));
}
const RadiusInfos = ({ radius, setRadius }) => {
    const [r, setR] = useState(parseInt(radius));
    useEffect(() => {
        setR(radius)
    }, [radius])
    const handleRadiusUpdate = (e) => {
        const _r = e.target.value;
        setR(_r);
        if (Number.isInteger(parseInt(_r)) && parseInt(_r) > 0) setRadius(parseInt(_r));
    }
    return <TextField
        type="number"
        name="radius"
        label="Radius"
        size="small"
        value={r}
        error={!Number.isInteger(parseInt(r)) || parseInt(r) <= 0}
        sx={{ minWidth: 150 }}
        helperText={!Number.isInteger(parseInt(r)) || parseInt(r) <= 0 ? "Radius must be a positive" : ""}
        onChange={handleRadiusUpdate}
    />
}

const CenterInfos = ({ radius, setRadius, circlePosition, setCirclePosition }) => {
    const [lat, setLat] = useState(circlePosition.lat);
    const [lng, setLng] = useState(circlePosition.lng);
    useEffect(() => {
        if (parseFloat(lat) !== circlePosition.lat) setLat(circlePosition.lat);
        if (parseFloat(lng) !== circlePosition.lng) setLat(circlePosition.lng);
    }, [circlePosition, lat, lng])
    const handleLatUpdate = (e) => {
        const _lat = e.target.value;
        setLat(_lat);
        if (parseFloat(_lat) > -90 && parseFloat(_lat) < 90) {
            setCirclePosition({ lng: circlePosition.lng, lat: parseFloat(_lat) })
        }
    }
    const handleLngUpdate = (e) => {
        const _lng = e.target.value;
        setLng(_lng);
        if (parseFloat(_lng) > -180 && parseFloat(_lng) < 180) {
            setCirclePosition({ lng: parseFloat(_lng), lat: circlePosition.lat });
        }
    }
    return <Grid2 container spacing={4}>
        <Grid2 size={12}>
            <TextField
                type="number"
                name="latitude"
                label="Latitude"
                size="small"
                value={lat}
                error={!Number.isFinite(parseFloat(lat)) || parseFloat(lat) < -90 || parseFloat(lat) > 90}
                sx={{ minWidth: 150 }}
                helperText={!Number.isFinite(parseFloat(lat)) || parseFloat(lat) < -90 || parseFloat(lat) > 90 ? "Must be between -90 and 90" : ""}
                onChange={handleLatUpdate}
            />
        </Grid2>
        <Grid2 size={12}>
            <TextField
                type="number"
                name="longitude"
                label="Longitude"
                size="small"
                value={lng}
                error={!Number.isFinite(parseFloat(lng)) || parseFloat(lng) < -180 || parseFloat(lng) > 180}
                sx={{ minWidth: 150 }}
                helperText={!Number.isFinite(parseFloat(lng)) || parseFloat(lng) < -180 || parseFloat(lng) > 180 ? "Must be between -180 and 180" : ""}
                onChange={handleLngUpdate}
            />
        </Grid2>
        <Grid2 size={12}>
            <RadiusInfos radius={radius} setRadius={setRadius} />
        </Grid2>
    </Grid2>
}

const DraggableCircle = ({ geoFencing, geoFencingEdit }) => {
    const theme = useTheme();
    const [circlePosition, setCirclePosition] = useState({ lat: geoFencing.lat, lng: geoFencing.lng });
    const [radiusMarkerPosition, setRadiusMarkerPosition] = useState({ lat: geoFencing.lat, lng: geoFencing.lng });
    const [radius, setRadius] = useState(geoFencing.radius);
    useEffect(() => {
        setCirclePosition({ lat: geoFencing.lat, lng: geoFencing.lng });
        setRadiusMarkerPosition({ lat: geoFencing.lat, lng: geoFencing.lng });
        setRadius(geoFencing.radius);
    }, [geoFencing])

    const markerCenterRef = useRef(null);
    const markerradiusRef = useRef(null);
    const centerEventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerCenterRef.current
                if (marker != null) {
                    setCirclePosition(marker.getLatLng())
                }
            }
        }),
        [],
    );
    const radiusEventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerradiusRef.current
                if (marker != null) {
                    const _radiusMarkerPosition = marker.getLatLng();
                    setRadiusMarkerPosition(_radiusMarkerPosition);
                    const r = getRadius(_radiusMarkerPosition.lat, _radiusMarkerPosition.lng, circlePosition.lat, circlePosition.lng);
                    setRadius(r);
                }
            }
        }),
        [circlePosition],
    );
    useEffect(() => {
        const r = getRadius(radiusMarkerPosition.lat, radiusMarkerPosition.lng, circlePosition.lat, circlePosition.lng);
        if (Math.abs(radius - r) > 1) {
            const lng = getNewLongitude(circlePosition, radius);
            setRadiusMarkerPosition({ lat: circlePosition.lat, lng });
        }
    }, [radius, circlePosition, radiusMarkerPosition.lat, radiusMarkerPosition.lng])
    useEffect(() => {
        const lng = getNewLongitude(circlePosition, radius);
        setRadiusMarkerPosition({ lat: circlePosition.lat, lng });
    }, [circlePosition, radius])
    useEffect(() => {
        if (geoFencing && geoFencing.updateGeoFencing) {
            geoFencing.updateGeoFencing({ ...circlePosition, radius });
        }
    }, [circlePosition, radius, geoFencing])
    return <React.Fragment>
        <Marker
            draggable={geoFencingEdit}
            eventHandlers={centerEventHandlers}
            position={circlePosition}
            icon={TargetMarker}
            ref={markerCenterRef}
            zIndexOffset={10000}
        >
            {geoFencingEdit && <Popup>
                <CenterInfos
                    radius={radius}
                    setRadius={setRadius}
                    circlePosition={circlePosition}
                    setCirclePosition={setCirclePosition}
                />
            </Popup>}
            <Circle
                center={circlePosition}
                interactive={false}
                fillColor={theme.palette.primary.main}
                color={rgba(theme.palette.primary.main, 0.4)}
                radius={radius} />
        </Marker>
        {geoFencingEdit && <Marker
            draggable={geoFencingEdit}
            eventHandlers={radiusEventHandlers}
            position={radiusMarkerPosition}
            icon={ResizeMarker}
            ref={markerradiusRef}
            zIndexOffset={10001}
        >
            <Popup>
                <RadiusInfos radius={radius} setRadius={setRadius} />
            </Popup>
        </Marker>}
    </React.Fragment>
}
export default DraggableCircle;
