import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import {
    Card as MuiCard,
    CardHeader,
    CardContent,
    Grid2,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Alert
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import { spacing } from '@mui/system';
import session_duration from 'constants/sessionDuration';
import useAuth from 'hooks/useAuth';
import Authorized from 'components/Authorized';


const sessionDurationLabels = {
    '8h': '8 hours',
    '12h': '12 hours',
    '24h': 'One day',
    '72h': 'Three days',
    '120h': 'Five Days',
    '168h': 'One Week'
};



const Card = styled(MuiCard)(spacing);
const SessionDuration = () => {
    const [sessionDuration, setSessionDuration] = useState(session_duration.SESSION_12HOURS);
    const [fetching, setFetching] = useState(false);
    const {
        sessionDuration: userSessionDuration,
        userID,
        updateUser
    } = useAuth();
    useEffect(() => {
        if (userID && userSessionDuration) {
            setSessionDuration(userSessionDuration);
        }
    }, [userID, userSessionDuration]);

    async function handleUpdateSessionDurationSettings() {
        try {
            const userUpdateData = {
                sessionDuration
            }
            const results = await updateUser(userUpdateData);
            if (results && results.message) {
                toast.success(results.message);
            }
            setFetching(false);
        } catch (err) {
            setFetching(false);
            if (err && err.message) {
                toast.error(err.message);
            }
            else toast.error("Could not update settings");
        }
    }
    return <Card sx={{ mb: 6 }}>
        <CardHeader title={<Typography variant="h6">
            Session Duration Settings
        </Typography>
        } />
        <CardContent>
            <Grid2 container alignItems="center" spacing={6}>
                <Grid2>
                    <Authorized permission="myAccount.read">
                        <FormControl sx={{ width: 200 }}>
                            <InputLabel id="session-duration-select-label">Session Duration</InputLabel>
                            <Select
                                labelId="session-duration-select-label"
                                id="session-duration-select"
                                disabled={fetching || !userID}
                                value={sessionDuration}
                                label="Session Duration"
                                size="small"
                                onChange={(e) => setSessionDuration(e.target.value)}
                            >
                                {Object.values(session_duration).map(el => <MenuItem key={el} value={el}>{sessionDurationLabels[el] || el}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Authorized>
                </Grid2>

                <Grid2>
                    <Authorized permission="myAccount.write">
                        <LoadingButton
                            onClick={handleUpdateSessionDurationSettings}
                            endIcon={<SaveIcon />}
                            loading={fetching}
                            loadingPosition="end"
                            variant="contained"
                            color="primary"
                            disabled={userSessionDuration === sessionDuration}
                        >
                            Update
                        </LoadingButton>
                    </Authorized>
                </Grid2>
                {userSessionDuration !== sessionDuration && <Grid2 size={12}>
                    <Alert severity='warning'> * The changes will take effect the next time you log in</Alert>
                </Grid2>}
            </Grid2>
        </CardContent>
    </Card >
}
export default SessionDuration;