import React, { useEffect, useState, Suspense, useRef } from 'react';

import Loader from "components/Loader";

export default function asyncComponent(importComponent) {
  const Component = React.lazy(importComponent);
  const AsyncComponent = (props) => {
    const [deboucing, setDebouncing] = useState(true);
    const isMounted = useRef(false);
    useEffect(() => {
      isMounted.current = true;
      setTimeout(() => {
        if (isMounted.current) setDebouncing(false)
      }, 300);
      return () => {
        isMounted.current = false;
      }
    }, []);
    return deboucing ? (
      <Loader />
    ) :
      (
        <Suspense fallback={<Loader />}>
          <Component {...props} />
        </Suspense>
      )
  }
  return AsyncComponent;
}
