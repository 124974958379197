import { useState, useMemo, useEffect } from 'react';
import {
    Grid2,
    Typography,
    Card,
    CardContent,
    IconButton,
    TextField,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip,
    Chip
} from '@mui/material';
import { removeSettingAction, updateSettingAction } from 'redux/settings/settings.actions';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationsIcon from '@mui/icons-material/Notifications';
import WifiIcon from '@mui/icons-material/Wifi';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import FenceIcon from '@mui/icons-material/Fence';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import BadgeIcon from '@mui/icons-material/Badge';
import LanIcon from '@mui/icons-material/Lan';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { http } from 'utils/http';
import { toast } from 'react-toastify';
import settingsTypesInfos from './settingsTypesInfos';
import Scan from './Scan';
import Alerts from './Alerts';
import Geofencing from './Geofencing';
import Keybaord from './Keybaord';
import Badge from './Badge';
import RTT from './RTT';
import Tunnel from './Tunnel';
import HtmlTooltip from 'components/HtmlTooltip';
import Authorized from 'components/Authorized';
import settingType from 'constants/settingType';

const SettingUsedBy = ({ groups, nDevices }) => {
    return <Grid2 container spacing={1}>
        <Grid2>
            <PhoneAndroidIcon fontSize="small" color="primary" /><Typography variant="body2" align="center">{nDevices}</Typography>
        </Grid2>
        {groups.map(group => <Grid2 key={group._id}>
            <HtmlTooltip title={<Grid2 container>
                <Grid2 size={12}>
                    <Typography variant="body2">#Devices: <strong>{group && group.devices ? group.devices.length : 0}</strong></Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Typography variant="body2">Name: <strong>{group.name || '-'}</strong></Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Typography variant="body2">Description: <strong>{group.description || '-'}</strong></Typography>
                </Grid2>
            </Grid2>
            }>
                <Chip label={group.name || '-'} color="primary" variant="outlined" size="small" />
            </HtmlTooltip>
        </Grid2>)}
    </Grid2>
}

const Setting = ({ _id, name, description, type, createdBy, groups, nDevices, selectedSetting, setSelectedSetting, ...others }) => {
    const [accordionExpanded, setAccordionExpanded] = useState(false);
    const [ref, setRef] = useState(null);
    const [editing, setEditing] = useState(false);
    const [settingName, setSettingName] = useState(name);
    const [settingDescription, setSettingDescription] = useState(description);
    const admins = useSelector(state => state.admins.admins || []);
    const adminName = useMemo(() => {
        const admin = admins.find(el => el._id === createdBy);
        return admin && admin.displayName ? admin.displayName : createdBy;
    }, [createdBy, admins]);
    const dispatch = useDispatch();
    const [deleting, setDeleting] = useState(false);
    const [properties, setProperties] = useState(others);
    const [isValid, setIsValid] = useState(false);
    const handleEnableEditing = () => {
        // create a copy of the original data (keep redux untouched);
        setProperties(structuredClone(others));
        setEditing(true);
    }
    const handleDisableEditing = () => {
        // pull again original copy of data (in case of state changed)
        // user canceled need to cancel all changes;
        setProperties(structuredClone(others));
        setEditing(false);
    }
    const handleDeleteSetting = async () => {
        try {
            const resp = await http.delete(`${process.env.REACT_APP_SERVER_URL}/api/v2/setting/setting/${_id}`);
            if (resp && resp.data) {
                dispatch(removeSettingAction(_id));
            }
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                return toast.error(error.response.data.message);
            }
            toast.error("Something went wrong");
        }
    }
    const handleUpdateSetting = async () => {
        try {
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/setting/setting`,
                {
                    settingID: _id,
                    name: settingName,
                    description: settingDescription,
                    ...properties,
                }
            );
            if (resp && resp.data) {
                setEditing(false);
                dispatch(updateSettingAction(resp.data));
                setProperties(structuredClone(resp.data));
            }
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                return toast.error(error.response.data.message);
            }
            toast.error("Something went wrong");
        }
    }
    const formValid = useMemo(() => {
        return isValid && (settingName !== name ||
            settingDescription !== description ||
            JSON.stringify(others) !== JSON.stringify(properties))
    }, [others, properties, description, isValid, name, settingDescription, settingName])
    useEffect(() => {
        if (_id === selectedSetting && ref) {
            ref.scrollIntoView({ block: "center" });
        }
    }, [ref, _id, selectedSetting]);
    const handleAccordionChange = (expanded) => {
        setSelectedSetting(null);
        setAccordionExpanded(expanded);
    }

    return <Card variant="outlined" ref={(ref) => setRef(ref)} sx={_id === selectedSetting ? { borderColor: 'red' } : {}}>
        <CardContent>
            <Accordion onChange={(e, expanded) => handleAccordionChange(expanded)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Grid2 container>
                        <Grid2 size={2}>
                            {type === settingType.ALERTS && <Tooltip title={settingsTypesInfos.ALERTS.name}><NotificationsIcon fontSize="large" color="primary" /></Tooltip>}
                            {type === settingType.RTT && <Tooltip title={settingsTypesInfos.RTT.name}><WifiIcon fontSize="large" color="primary" /></Tooltip>}
                            {type === settingType.KEYBOARD && <Tooltip title={settingsTypesInfos.KEYBOARD.name}><KeyboardIcon fontSize="large" color="primary" /></Tooltip>}
                            {type === settingType.GEOFENCING && <Tooltip title={settingsTypesInfos.GEOFENCING.name}><FenceIcon fontSize="large" color="primary" /></Tooltip>}
                            {type === settingType.SCAN && <Tooltip title={settingsTypesInfos.SCAN.name}><QrCodeScannerIcon fontSize="large" color="primary" /></Tooltip>}
                            {type === settingType.BADGE && <Tooltip title={settingsTypesInfos.BADGE.name}><BadgeIcon fontSize="large" color="primary" /></Tooltip>}
                            {type === settingType.TUNNEL && <Tooltip title={settingsTypesInfos.TUNNEL.name}><LanIcon fontSize="large" color="primary" /></Tooltip>}
                        </Grid2>
                        <Grid2 size={10}>
                            <Typography variant="h4">
                                {name || '-'}
                            </Typography>
                        </Grid2>
                        <Grid2 size={2} />
                        <Grid2 size={10}>
                            <Typography variant="body1">
                                {description || '-'}
                            </Typography>
                        </Grid2>
                        <Grid2 size={12} sx={{ mt: 3 }}>
                            <SettingUsedBy groups={groups} nDevices={nDevices} />
                        </Grid2>
                    </Grid2>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid2 container>
                        <Grid2 size={10}>
                            {editing ? <TextField
                                label="Name"
                                value={settingName || ''}
                                onChange={(e) => setSettingName(e.target.value)}
                                variant="outlined"
                                size="small"
                                sx={{ my: 2 }}
                            /> :
                                <>
                                    <Typography variant="h5" ><strong>Name:</strong> {name || '-'}</Typography>
                                    <Typography variant="body2"><strong>Created By:</strong> {adminName || '-'}</Typography>
                                </>}
                        </Grid2>
                        <Grid2 size={1}>
                            <Authorized permission="setting.update">
                                {!editing && !deleting && <IconButton color="primary" onClick={handleEnableEditing} >
                                    <EditIcon fontSize="small" />
                                </IconButton>}
                            </Authorized>
                            {editing && !deleting && <IconButton color="warning" onClick={handleDisableEditing} >
                                <HighlightOffIcon fontSize="small" />
                            </IconButton>}
                            {deleting && <IconButton color="error" onClick={handleDeleteSetting} >
                                <DeleteForeverIcon fontSize="small" />
                            </IconButton>}
                        </Grid2>
                        <Grid2 size={1}>
                            <Authorized permission="setting.delete">
                                {!editing && !deleting && <IconButton color="error" onClick={() => setDeleting(true)} >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>}
                            </Authorized>
                            {deleting && <IconButton color="warning" onClick={() => setDeleting(false)} >
                                <HighlightOffIcon fontSize="small" />
                            </IconButton>}
                            {editing && !deleting && <IconButton color="primary" onClick={handleUpdateSetting} disabled={!formValid} >
                                <SaveIcon fontSize="small" />
                            </IconButton>}
                        </Grid2>
                    </Grid2>
                    <Grid2 size={12}>
                        {!editing && <Typography variant="body2"><strong>Type:</strong> {settingsTypesInfos[type]?.name || '-'}</Typography>}
                    </Grid2>
                    <Grid2 size={12}>
                        {editing ? <TextField
                            label="Description"
                            value={settingDescription || ''}
                            onChange={(e) => setSettingDescription(e.target.value)}
                            fullWidth
                            variant="outlined"
                            size="small"
                            sx={{ my: 2 }}
                        />
                            :
                            <Typography variant="body2" gutterBottom><strong>Description:</strong> {description || '-'}</Typography>}
                    </Grid2>
                    <Divider />
                    <Grid2 size={12} sx={{ my: 5 }} />
                    <Grid2 size={12} >
                        {type === settingType.SCAN && <Scan
                            settingID={_id}
                            editing={editing}
                            properties={properties}
                            setProperties={editing ? setProperties : () => { }}
                            setIsValid={setIsValid}
                        />}
                        {type === settingType.BADGE && <Badge
                            settingID={_id}
                            editing={editing}
                            properties={properties}
                            setProperties={editing ? setProperties : () => { }}
                            setIsValid={setIsValid}
                            accordionExpanded={accordionExpanded}
                        />}
                        {type === settingType.ALERTS && <Alerts
                            settingID={_id}
                            editing={editing}
                            properties={properties}
                            setProperties={editing ? setProperties : () => { }}
                            setIsValid={setIsValid}
                        />}
                        {type === settingType.GEOFENCING && <Authorized every={["page.outdoorPositioning", "device.read"]}>
                            <Geofencing
                                settingID={_id}
                                editing={editing}
                                properties={properties}
                                setProperties={editing ? setProperties : () => { }}
                                setIsValid={setIsValid}
                            />
                        </Authorized>}
                        {type === settingType.KEYBOARD && <Keybaord
                            settingID={_id}
                            editing={editing}
                            properties={properties}
                            setProperties={editing ? setProperties : () => { }}
                            setIsValid={setIsValid}
                        />}
                        {type === settingType.RTT && <Authorized every={["page.indoorPositioning"]}>
                            <RTT
                                settingID={_id}
                                editing={editing}
                                properties={properties}
                                setProperties={editing ? setProperties : () => { }}
                                setIsValid={setIsValid}
                            />
                        </Authorized>}
                        {type === settingType.TUNNEL && <Tunnel
                            settingID={_id}
                            editing={editing}
                            properties={properties}
                            setProperties={editing ? setProperties : () => { }}
                            setIsValid={setIsValid}
                        />}
                    </Grid2>
                </AccordionDetails>
            </Accordion>
        </CardContent>
    </Card>
}

export default Setting;