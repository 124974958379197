import { useState, useMemo, useRef, useEffect } from 'react';
import { styled } from '@mui/system';
import { toast } from 'react-toastify'
import {
    Button,
    Card,
    CardContent,
    Grid2,
    TextField,
    Typography,
    Alert,
    Box
} from '@mui/material';
import { rgba } from 'polished';
import Slider from 'components/Slider';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDispatch } from 'react-redux';
import { updateRttAPsAction } from 'redux/rtt/rtt.actions';
import { http, baseURL } from 'utils/http';
import { updateSettingAction } from 'redux/settings/settings.actions';
import UISettings from './UISettings';

const CenteredContent = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 5px;
`;
const MapBackgroundImagePreview = styled('img')`
    outline: 1px solid ${props => rgba(props.theme.palette.primary.main, 0.3)};
    line-height: 100px;
    text-align: center;
    width: 100%;
    height: auto;
    flex-grow: 1;
`;

const GeneralMapSettings = ({ rttSettings, readOnly }) => {
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false);
    const [width, setWidth] = useState(rttSettings?.rttMapWidth || 0);
    const [height, setHeight] = useState(rttSettings?.rttMapHeight || 0);
    const [offsetX, setOffsetX] = useState(rttSettings?.rttOffsetX || 0);
    const [offsetY, setOffsetY] = useState(rttSettings?.rttOffsetY || 0);
    const [smoothness, setSmoothness] = useState(rttSettings?.rttSmoothness || 0);
    const [wifiLevelInterpolation, setWifiLevelInterpolation] = useState(rttSettings?.rttWifiLevelInterpolation || 0);
    const [imageUrl, setImageUrl] = useState(rttSettings?.rttImageUrl ? `${baseURL}${rttSettings.rttImageUrl}` : "");

    useEffect(() => {
        setWidth(rttSettings?.rttMapWidth || 0);
        setHeight(rttSettings?.rttMapHeight || 0);
        setOffsetX(rttSettings?.rttOffsetX || 0);
        setOffsetY(rttSettings?.rttOffsetY || 0);
        setSmoothness(rttSettings?.rttSmoothness || 0);
        setWifiLevelInterpolation(rttSettings?.rttWifiLevelInterpolation || 0);
        setImageUrl(rttSettings?.rttImageUrl ? `${baseURL}${rttSettings.rttImageUrl}` : "");
    }, [rttSettings])

    const [fileToUpload, setFileToUpload] = useState(null);
    const fileRef = useRef();
    const handleUpdateRTTSettings = async () => {
        try {
            setFetching(true);
            let imageUrl = null;
            if (fileToUpload) {
                try {
                    const res = await uploadFile(fileToUpload);
                    if (!res?.data?.result?.resourceUrl) return;
                    imageUrl = res.data.result.resourceUrl;
                } catch (err) {
                    toast.error("Could not upload file!")
                    console.log("file upload some error occured", err)
                }
            } else {
                removeFile()
            }
            const updates = {}
            if (width) updates['rttMapWidth'] = width;
            if (height) updates['rttMapHeight'] = height;
            if (Number.isInteger(parseInt(smoothness))) updates['rttSmoothness'] = smoothness;
            if (Number.isInteger(parseInt(wifiLevelInterpolation))) updates['rttWifiLevelInterpolation'] = wifiLevelInterpolation;
            if (Number.isInteger(parseInt(offsetX))) updates['rttOffsetX'] = offsetX;
            if (Number.isInteger(parseInt(offsetY))) updates['rttOffsetY'] = offsetY;

            if (imageUrl) updates['rttImageUrl'] = imageUrl;
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/setting/setting`,
                {
                    settingID: rttSettings._id,
                    ...updates
                }
            );

            dispatch(updateSettingAction(resp.data));
            if (
                resp.data.rttAccessPoints &&
                Array.isArray(resp.data.rttAccessPoints)
            ) {
                dispatch(updateRttAPsAction(resp.data.rttAccessPoints));
            }
            setFetching(false);
            removeFile();
            toast.success("rtt settings updated");
        } catch (error) {
            console.log("error", error);
            setFetching(false);
            toast.error(error?.response?.data?.message || "Could not update rtt settings");
        }
    }
    function openFilePicker() {
        if (fileRef && fileRef.current) fileRef.current.click()
    }
    async function uploadFile(file) {
        const formData = new FormData()
        formData.append("image", file, file.name);
        try {
            const results = await http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/file/upload-image`,
                formData
            )
            return results;
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                return toast.error(error.response.data && error.response.data.message);
            }
            else toast.error("Error: can't upload the file");
        }
    }
    function previewFile(e) {
        const file = e.target.files[0]
        const oneMb = 1000000
        const maxSizeInMb = 20
        const maxSize = maxSizeInMb * oneMb;
        // [start] file validations
        if (!file) {
            removeFile()
            return
        }
        if (file.size > maxSize) {
            return toast.error(`Max file size is ${maxSizeInMb}mb`)
        }
        // [end] file validations

        setFileToUpload(file)

        if (file.type.startsWith("image")) {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = function () {
                setImageUrl(reader.result)
            }
        } else {
            // some other document file...
        }
    }

    function removeFile() {
        setFileToUpload(null)
        //setImageUrl(initialImageUrlState)
    }
    const formValid = useMemo(() => {
        if (!rttSettings) return true;
        if (parseInt(width) === parseInt(rttSettings.rttMapWidth) &&
            parseInt(height) === parseInt(rttSettings.rttMapHeight) &&
            parseInt(offsetX) === parseInt(rttSettings.rttOffsetX) &&
            parseInt(offsetY) === parseInt(rttSettings.rttOffsetY) &&
            parseInt(smoothness) === parseInt(rttSettings.rttSmoothness) &&
            parseInt(wifiLevelInterpolation) === parseInt(rttSettings.rttWifiLevelInterpolation) &&
            !fileToUpload
        ) return false;
        if (parseInt(width) <= 0 ||
            parseInt(height) <= 0 ||
            parseInt(smoothness) < 0 ||
            parseInt(wifiLevelInterpolation) < 0
        ) return false;
        if (!Number.isInteger(parseInt(width)) ||
            !Number.isInteger(parseInt(height)) ||
            !Number.isInteger(parseInt(offsetX)) ||
            !Number.isInteger(parseInt(offsetY)) ||
            !Number.isInteger(parseInt(smoothness)) ||
            !Number.isInteger(parseInt(wifiLevelInterpolation))
        ) return false;
        return true;
    }, [rttSettings, width, height, offsetX, offsetY, smoothness, wifiLevelInterpolation, fileToUpload]);


    return (<Card>
        <CardContent>
            <Grid2 container spacing={6}>
                <Grid2 size={12}>
                    <Typography variant="h6" gutterBottom>
                        Indoor Tracking Settings
                    </Typography>
                    <Typography variant="body2">
                        <strong>Name: </strong> {rttSettings.name || '-'}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        <strong>Description: </strong> {rttSettings.description || '-'}
                    </Typography>
                </Grid2>
                <Grid2 size={{ md: 6, xs: 12 }} order={{ xs: 2, md: 1 }} container rowSpacing={3}>
                    <Grid2 size={12}>
                        <TextField
                            type="number"
                            label="Map Width in millimeters"
                            value={width || 0}
                            onChange={(e) => setWidth(e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{ my: 4 }}
                            disabled={readOnly}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <TextField
                            type="number"
                            label="Map Height in millimeters"
                            value={height || 0}
                            onChange={(e) => setHeight(e.target.value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                            sx={{ my: 2 }}
                            disabled={readOnly}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <TextField
                            type="number"
                            label="Offset X in millimeters"
                            value={offsetX || 0}
                            onChange={(e) => setOffsetX(e.target.value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                            sx={{ my: 2 }}
                            disabled={readOnly}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <TextField
                            type="number"
                            label="Offset Y in millimeters"
                            value={offsetY || 0}
                            onChange={(e) => setOffsetY(e.target.value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                            sx={{ my: 2 }}
                            disabled={readOnly}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <TextField
                            type="number"
                            label="Wifi Level interpolation"
                            value={wifiLevelInterpolation || 0}
                            onChange={(e) => setWifiLevelInterpolation(e.target.value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                            sx={{ my: 2 }}
                            slotProps={{
                                htmlInput: {
                                    min: 0,
                                    max: 10
                                }
                            }}
                            disabled={readOnly}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Typography>
                            Smoothness
                        </Typography>
                        <Slider
                            valueLabelDisplay="auto"
                            max={2000}
                            min={0}
                            value={Number.isInteger(smoothness) ? smoothness : 5}
                            onChange={(e) => setSmoothness(e.target.value)}
                            disableSwap
                            disabled={readOnly}
                            labelStyling={{
                                width: 32,
                                height: 32
                            }}
                            sx={{
                                mx: 2
                            }}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <UISettings />
                    </Grid2>
                </Grid2>
                <Grid2 size={{ md: 6, xs: 12 }} order={{ xs: 1, md: 2 }}>
                    <CenteredContent>
                        {imageUrl && <MapBackgroundImagePreview
                            alt="map preview"
                            src={imageUrl}
                            width="100%"
                            height="auto"
                        />
                        }
                        <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="raised-button-file"
                            multiple
                            type="file"
                            onChange={previewFile}
                            ref={fileRef}
                        />
                        <Button
                            onClick={openFilePicker}
                            variant="contained"
                            color="primary"
                            startIcon={<CloudUploadIcon />}
                            disabled={readOnly}>
                            Upload Map Image
                        </Button>
                        {width > 0 && height > 0 && <Alert severity="info" size="small">
                            For best results the physical location and the image aspect ratio need to match <strong>(width/height: {Math.round((width / height) * 100)}/100)</strong>
                        </Alert>}
                    </CenteredContent>
                </Grid2>
            </Grid2>
            <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateRTTSettings}
                disabled={!formValid || fetching || readOnly}>
                Save changes
            </Button>
        </CardContent>
    </Card >)
}
export default GeneralMapSettings;
