import { useState, useMemo } from 'react';
import {
    Grid2,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Alert
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import chatRoomType from 'constants/chatRoomType';
import { formatTimeDateFromNow } from 'utils/timeFormat';
import { useSelector } from 'react-redux';
import useAuth from 'hooks/useAuth';
import USER_TYPE from 'constants/userType';
import useAuthorization from 'hooks/useAuthorization';

const getDefaultChatRoomType = (currentUserType) => {
    switch (currentUserType) {
        case USER_TYPE.ADMIN:
            return chatRoomType.PRIVATE;
        case USER_TYPE.SUPPORT:
            return chatRoomType.SUPPORT;
        case USER_TYPE.DEVICE:
            return chatRoomType.DEVICE;
        default:
            return chatRoomType.GROUP;
    }
}


const Infos = ({
    name,
    setName,
    type,
    setType,
    isCreateMode,
    fetching,
    handleCreateRoom,
    handleUpdateRoom,
    handleDeleteRoom,
    updateFormValidationError,
    createFormValidationError,
    updated,
    createdAt,
    createdBy
}) => {
    const { type: currentUserType } = useAuth();
    const { isAuthorized } = useAuthorization();
    const canUpdateChatRoom = isAuthorized("chatRoom.update");
    const canCreateChatRoom = isAuthorized("chatRoom.create");
    const canDeleteChatRoom = isAuthorized("chatRoom.delete");
    const [deleting, setDeleting] = useState(false);
    const deleteRoom = () => {
        setDeleting(false);
        handleDeleteRoom()
    }
    const chatUsers = useSelector(state => state.chat.users);
    const createdByName = useMemo(() => {
        if (!createdBy) return "";
        const user = chatUsers.find(el => el._id === createdBy);
        return user?.displayName || user?.androidID || user?._id || "";
    }, [createdBy, chatUsers]);

    return <Grid2 container spacing={2}>
        {!isCreateMode && updateFormValidationError && <Grid2 size={12}>
            <Alert severity='warning'>{updateFormValidationError}</Alert>
        </Grid2>}
        {isCreateMode && createFormValidationError && <Grid2 size={12}>
            <Alert severity='warning'>{createFormValidationError}</Alert>
        </Grid2>}
        <Grid2 size={12}>
            <Typography variant="h6">{name || "-"}</Typography>
        </Grid2>
        <Grid2 size={12}>
            <TextField
                label="Room Name"
                variant="outlined"
                size="small"
                fullWidth
                value={name || ""}
                onChange={(e) => setName(e.target.value)}
                disabled={(!isCreateMode && !canUpdateChatRoom) || (isCreateMode && !canCreateChatRoom)}
            />
        </Grid2>
        <Grid2 size={12}>
            <FormControl fullWidth>
                <InputLabel id="type-select-label">Type</InputLabel>
                <Select
                    labelId="type-select-label"
                    id="type-select"
                    value={type || getDefaultChatRoomType(currentUserType)}
                    label="Type"
                    size="small"
                    onChange={(e) => setType(e.target.value)}
                    disabled={!isCreateMode || !canUpdateChatRoom || !canCreateChatRoom}
                >
                    {currentUserType === USER_TYPE.SUPPORT ? (
                        <MenuItem value={chatRoomType.SUPPORT}>{chatRoomType.SUPPORT}</MenuItem>
                    ) : (
                        Object.values(chatRoomType).map(el => <MenuItem key={el} value={el}>{el}</MenuItem>)
                    )}

                </Select>
            </FormControl>
        </Grid2>
        {!isCreateMode && <Grid2 size={12}>
            <LoadingButton
                size="small"
                endIcon={<SaveIcon />}
                onClick={handleUpdateRoom}
                loadingPosition="end"
                variant="contained"
                loading={fetching}
                disabled={!updated || !!updateFormValidationError || !canUpdateChatRoom}
            >
                Update
            </LoadingButton>
        </Grid2>}
        {isCreateMode && <Grid2 size={12}>
            <LoadingButton
                size="small"
                endIcon={<SaveIcon />}
                onClick={handleCreateRoom}
                loadingPosition="end"
                variant="contained"
                loading={fetching}
                disabled={!!createFormValidationError || !canCreateChatRoom}
            >
                Save
            </LoadingButton>
        </Grid2>}
        {!isCreateMode && <Grid2>
            {deleting ? <>
                <Button
                    color="primary"
                    endIcon={<CancelIcon />}
                    onClick={() => setDeleting(false)}
                    variant="contained"
                    size="small"
                    sx={{ mx: 1 }}
                >
                    Cancel
                </Button>
                <LoadingButton
                    size="small"
                    endIcon={<CancelIcon />}
                    onClick={deleteRoom}
                    loadingPosition="end"
                    variant="contained"
                    color="error"
                    disabled={fetching}
                >
                    Delete Room
                </LoadingButton>
            </> : <Button
                size="small"
                endIcon={<DeleteIcon />}
                onClick={() => setDeleting(true)}
                variant="contained"
                color="warning"
                disabled={!canDeleteChatRoom}
            >
                Delete
            </Button>}
        </Grid2>}
        {createdAt && <Grid2 size={12}>
            <Typography variant="body1">Created: <strong>{formatTimeDateFromNow(createdAt)}</strong></Typography>
        </Grid2>}
        {createdByName && <Grid2 size={12}>
            <Typography variant="body1">Created by <strong>{createdByName}</strong></Typography>
        </Grid2>}
    </Grid2>
}
export default Infos;