import React, {
    useMemo
} from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Grid2,
    Typography,
    Chip,
    Divider,
    Box,
    LinearProgress
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const featuresNamesMap = {
    "real-time-data": "Real-Time Data",
    "system-overview": "System Overview",
    "keyboard-creator": "Keyboard Creator",
    "device-profile": "Device Profile",
    "productivity-dashboard": "Productivity Dashboard",
    "alerts-dashboards": "Alerts Dashboard",
    "uts-dashboards": "UTS Dashboard",
    "outdoor-positioning": "Outdoor Positioning",
    "indoor-positioning": "Indoor Positioning",
    "chat": "Chat",
    "number-of-device-groups": "Number of device Groups",
    "data-retention": "Data Retention"
}


const Feature = ({ value, highlight }) => {
    if (`${value}` === "true") return <CheckIcon color="primary" />
    if (`${value}` === "false" || value === undefined) return <CloseIcon color="error" />
    if ("string" === typeof value && highlight) return <Typography>
        <strong>{value}</strong>
    </Typography>;
    if ("string" === typeof value && !highlight) return <Typography>
        {value}
    </Typography>;
    return "-";
}


const Plans = ({ plans }) => {
    const allFeatures = useMemo(() => Array
        .from(
            new Set(plans
                .map(el => Object.keys(el.features || []))
                .flat()
            )
        ), [plans]);

    return <Grid2
        container
        columns={plans.length + 2}
        spacing={0}
    >
        <Grid2 size={2}>
            <Box>
                <Grid2
                    container
                    columns={12}
                    justifyContent="center"
                    alignItems="start"
                    flexDirection="column"
                    columnSpacing={0}
                    rowSpacing={2}>
                    <Grid2>
                        <Typography variant="body1">
                            <strong>Feature</strong>
                        </Typography>
                    </Grid2>
                    <Grid2>
                        <Chip
                            label="Most Popular"
                            variant="contained"
                            size="small"
                            color="primary"
                            sx={{ visibility: "hidden" }}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Divider />
                    </Grid2>
                    {allFeatures.map(feature => <React.Fragment key={feature}>
                        <Grid2 size={12} sx={{ height: 25 }}>
                            <Typography variant="body1">
                                <strong>{featuresNamesMap[feature] || feature}</strong>
                            </Typography>
                        </Grid2>
                        <Grid2 size={12} sx={{ opacity: 0.2 }}>
                            <Divider />
                        </Grid2>
                    </React.Fragment>)}
                </Grid2>
            </Box>
        </Grid2>
        {plans.map(plan => <Grid2
            key={plan._id}
            size={1}
        >
            <Box>
                <Grid2
                    columns={12}
                    container
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    columnSpacing={0}
                    rowSpacing={2}
                >
                    <Grid2>
                        <Typography variant="body1">
                            <strong>
                                {plan.name}
                            </strong>
                        </Typography>
                    </Grid2>
                    <Grid2>
                        <Chip
                            label="Most Popular"
                            variant="contained"
                            size="small"
                            color="primary"
                            sx={{ visibility: plan.recommended ? "visible" : "hidden" }}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Divider />
                    </Grid2>
                    {allFeatures.map(feature => <React.Fragment key={feature}>
                        <Grid2 sx={{ height: 25 }}>
                            <Feature highlight={plan.recommended} value={plan.features[feature]} />
                        </Grid2>
                        <Grid2 size={12} sx={{ opacity: 0.2 }}>
                            <Divider />
                        </Grid2>
                    </React.Fragment>)}
                </Grid2>
            </Box>
        </Grid2>)}
    </Grid2>
}

const GSWPlans = ({ plans = [] }) => {
    return <Card>
        <CardHeader
            title="Available plans"
        />
        <CardContent>
            {plans?.length > 0 ? (
                <Plans plans={plans} />
            ) : (
                <Grid2 container spacing={6}>
                    <Grid2 size={12}>
                        <Typography variant="h6" gutterBottom>Loading...</Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        <LinearProgress />
                    </Grid2>
                </Grid2>
            )}
        </CardContent>
    </Card>
}

export default GSWPlans;