import React, { useEffect } from 'react';
import {
    Box,
    Typography as MuiTypography,
    Breadcrumbs,
    Divider as MuiDivider,
    Link,
    useMediaQuery
} from '@mui/material';
import { spacing } from "@mui/system";
import Mobile from 'components/Chat/indexMobile';
import Desktop from 'components/Chat/indexDesktop';
import { NavLink } from "react-router-dom";
import { styled } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getDevices } from 'utils/api';
import { updateDevicesAction } from 'redux/devices/devices.actions';
import { useDispatch } from 'react-redux';
import { useTheme } from "@mui/material/styles";

const PageContainer = styled(Box)`
    height: calc(100vh - 328px);
    ${props => props.theme.breakpoints.down("md")} {
        height: calc(100vh - 264px);
    }
`
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Chat = () => {
    const navigate = useNavigate();
    const { roomID } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchCall = getDevices({
            androidID: true,
            name: true,
            date: true,
            ipaddr: true,
            gswcb_version: true
        }).then(devices => dispatch(updateDevicesAction(devices || [])))
        return () => {
            fetchCall.cancel();
        };
    }, [dispatch]);

    const updateSelectedRoom = (id) => {
        navigate(`/chat/${id}`);
    }
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
    return <React.Fragment>
        <Typography variant="h3" gutterBottom display="inline">
            Chat
        </Typography>
        <Breadcrumbs aria-label="Breadcrumb" sx={{ mt: 2 }}>
            <Link component={NavLink} to="/">
                Dashboard
            </Link>
            <Typography>Chat</Typography>
        </Breadcrumbs>
        <Divider sx={{ my: 6 }} />
        <PageContainer>
            {isMdDown ? <Mobile roomID={roomID} updateSelectedRoom={updateSelectedRoom} /> : <Desktop roomID={roomID} updateSelectedRoom={updateSelectedRoom} />}
        </PageContainer>
    </React.Fragment>
}
export default Chat;
