import RttActionTypes from "./rtt.types"
/*
import { RTT_HEATMAP_ZONE_SIZE_IN_MM } from 'constants/rtt';
const mapWidth = 16462;
const mapHeight = 35146;
const offsetX = 1800;
const offsetY = 4400;
console.error("save access point per group (each location has its own AP)")
const data = [];
const inc = RTT_HEATMAP_ZONE_SIZE_IN_MM; // one Meter
for (let i = +1800; i < mapHeight - 800; i = i + 2 * inc) {
    for (let j = +1800; j < mapWidth - 800; j = j + 2 * inc) {
        data.push({
            x: Math.round((i - offsetX) / inc) * inc,
            y: Math.round((j - offsetY) / inc) * inc,
            z: 0,
            aID: "05f57ad5bada77b2",
            t: 1707914424809,
            maxWifi: Math.round(4 * (i * j) / (mapHeight * mapHeight)),
            wifi: []
        }
        )
    }
}

// router:
const _data = [
    {
        x: 3 * RTT_HEATMAP_ZONE_SIZE_IN_MM,
        y: 1 * RTT_HEATMAP_ZONE_SIZE_IN_MM,
        z: 0,
        aID: "05f57ad5bada77b2",
        t: 1707914424809,
        maxWifi: 4,
        wifi: []
    },
    {
        x: 7 * RTT_HEATMAP_ZONE_SIZE_IN_MM,
        y: 1 * RTT_HEATMAP_ZONE_SIZE_IN_MM,
        z: 0,
        aID: "05f57ad5bada77b2",
        t: 1707914424809,
        maxWifi: 0,
        wifi: []
    },
    {
        x: 6 * RTT_HEATMAP_ZONE_SIZE_IN_MM,
        y: 6 * RTT_HEATMAP_ZONE_SIZE_IN_MM,
        z: 0,
        aID: "05f57ad5bada77b2",
        t: 1707914424809,
        maxWifi: 0,
        wifi: []
    },
    { // same location not same wifi level
        x: 6 * RTT_HEATMAP_ZONE_SIZE_IN_MM,
        y: 6 * RTT_HEATMAP_ZONE_SIZE_IN_MM,
        z: 0,
        aID: "05f57ad5bada77b2",
        t: 1707914425809,
        maxWifi: 2,
        wifi: []
    },
    

]
*/
const initialState = {
    devices: {},
    accessPoints: [],
    wifiLevelsLocations: [], // by AP, By device, Max
};
const rttReducer = (state = initialState, action) => {
    switch (action.type) {
        case RttActionTypes.UPDATE_RTT_DEVICES_LOCATION:
            return { ...state, devices: action.payload };
        case RttActionTypes.UPDATE_RTT_DEVICE_LOCATION:
            const aps = state.accessPoints.map(el => action.payload.aps[el.mac] ? ({ ...el, ...action.payload.aps[el.mac], }) : el);
            return { ...state, devices: { ...state.devices, [action.payload.androidID]: action.payload }, accessPoints: aps };
        case RttActionTypes.UPDATE_RTT_APS:
            return { ...state, accessPoints: action.payload };
        case RttActionTypes.UPDATE_RTT_WIFI_LEVELS_LOCATIONS: {
            action.payload.forEach(el => {
                const apWifiLevel = {};
                el.wifi.forEach(el => {
                    apWifiLevel[el.mac] = el.level;
                })
                el.apWifiLevel = apWifiLevel;
            })
            return {
                ...state,
                wifiLevelsLocations: action.payload
            }
        }
        case RttActionTypes.UPDATE_RTT_WIFI_LEVELS_LOCATION: {
            const rttWifiLevelEvent = action.payload;
            const apWifiLevel = {};
            rttWifiLevelEvent.wifi.forEach(el => {
                apWifiLevel[el.mac] = el.level;
            })
            rttWifiLevelEvent.apWifiLevel = apWifiLevel;
            return {
                ...state,
                wifiLevelsLocations: [...state.wifiLevelsLocations, rttWifiLevelEvent]
            }
        }
        default:
            return state;
    }
}
export default rttReducer;
