import {
    useState,
    useEffect,
    useMemo,
    useRef
} from 'react';
import {
    Grid2,
    Box,
    Tabs,
    Tab,
    IconButton
} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { styled } from '@mui/system';
import Tracking from './Tracking';
import HeatMap from './HeatMap';
import Settings from './Settings';
import { updateRttAPsAction } from 'redux/rtt/rtt.actions';
import { useDispatch, useSelector } from 'react-redux';
import Authorized from 'components/Authorized';

const PageWrapper = styled(Box)`
    position: ${(props) => props.$fullScreenModeEnabled ? 'absolute' : 'unset'};
    top: ${(props) => props.$fullScreenModeEnabled ? '0' : 'unset'};
    right: ${(props) => props.$fullScreenModeEnabled ? '0' : 'unset'};
    bottom: ${(props) => props.$fullScreenModeEnabled ? '0' : 'unset'};
    left: ${(props) => props.$fullScreenModeEnabled ? '0' : 'unset'};
    z-index: ${(props) => props.$fullScreenModeEnabled ? '10000' : 'unset'};
    background-color: ${(props) => props.$fullScreenModeEnabled ? props.theme.palette.background.default : 'unset'};
    width: ${(props) => props.$fullScreenModeEnabled ? '100vw' : 'unset'};
    height: ${(props) => props.$fullScreenModeEnabled ? '100vh' : 'unset'};
    overflow: auto;
`

function TabPanel(props) {
    const { children, tab, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={tab !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {tab === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


function RTT({ groupRTT }) {
    const wrapperRef = useRef(null)
    const [tab, setTab] = useState(0);
    const [fetching, setFetching] = useState(false);
    const [fullScreenModeEnabled, setFullScreenModeEnabled] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (groupRTT && groupRTT.rtt && groupRTT.rtt.rttAccessPoints && Array.isArray(groupRTT.rtt.rttAccessPoints)) {
            dispatch(updateRttAPsAction(groupRTT.rtt.rttAccessPoints));
        }
        else {
            console.error("no valid accessPoints config found");
        }
    }, [dispatch, groupRTT]);
    useEffect(() => {
        const _wrapperRef = wrapperRef.current;
        const handleFullScreenChangeEvent = () => {
            setFullScreenModeEnabled(!!document.fullscreenElement);
        }
        if (!_wrapperRef) return;
        _wrapperRef.addEventListener("fullscreenchange", handleFullScreenChangeEvent)
        return () => {
            if (_wrapperRef) _wrapperRef.removeEventListener("fullscreenchange", handleFullScreenChangeEvent)
        }
    }, [])
    const devices = useSelector(state => state.devices || []);
    const lastContactDeviceMap = useMemo(() => devices.reduce((a, c) => ({ ...a, [c.androidID]: new Date(c.date).getTime() || 0 }), {}), [devices]);
    const groupDevices = useMemo(() => [...(groupRTT.devices || [])].sort((a, b) => lastContactDeviceMap[b] - lastContactDeviceMap[a]), [lastContactDeviceMap, groupRTT]);
    const handleEnterFullScreenMode = () => {
        wrapperRef.current?.requestFullscreen()
            .catch((error) => {
                console.error("enable fullscreen error", error)
            })
    }
    const handleExitFullScreenMode = () => {
        document?.exitFullscreen()
            .catch((error) => console.error("disable fullscreen error", error))
    }
    return (
        <PageWrapper
            $fullScreenModeEnabled={fullScreenModeEnabled}
            ref={wrapperRef}
        >
            <Box sx={{
                width: '100%',
                display: 'flex'
            }}>
                <Box sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    maxWidth: '100%',
                    flexGrow: 1
                }}>
                    <Tabs
                        value={tab}
                        onChange={(e, tab) => setTab(tab)}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="RTT Map"
                    >
                        <Tab label="Tracking" />
                        <Tab label="Heatmap" />
                        <Tab label="Settings" />
                    </Tabs>
                </Box>
                {fullScreenModeEnabled ? <IconButton color="primary" onClick={handleExitFullScreenMode}>
                    <FullscreenExitIcon />
                </IconButton> : <IconButton color="primary" onClick={handleEnterFullScreenMode}>
                    <FullscreenIcon />
                </IconButton>}
            </Box>
            <TabPanel tab={tab} index={0}>
                <Grid2 container spacing={12}>
                    <Grid2 size={12}>
                        <Authorized permission="setting.read">
                            <Tracking
                                rttSettings={groupRTT.rtt || {}}
                                groupDevices={groupRTT.devices || []}
                                fullScreenModeEnabled={fullScreenModeEnabled}
                            />
                        </Authorized>
                    </Grid2>
                </Grid2>
            </TabPanel>
            <TabPanel tab={tab} index={1}>
                <Authorized permission="setting.read">
                    <HeatMap
                        rttSettings={groupRTT.rtt || {}}
                        groupDevices={groupDevices}
                        fullScreenModeEnabled={fullScreenModeEnabled}
                    />
                </Authorized>
            </TabPanel>
            <TabPanel tab={tab} index={2}>
                <Authorized permission="setting.read">
                    <Settings
                        rttSettings={groupRTT.rtt || {}}
                        fetching={fetching} // to remove 
                        setFetching={setFetching} // to remove
                    />
                </Authorized>
            </TabPanel>
        </PageWrapper>
    );
}

export default RTT;
