const USER_TYPE = {
  SUPPORT: 'SUPPORT',
  ADMIN: 'ADMIN',
  ADMIN_INVITED: 'ADMIN_INVITED',
  ADMIN_ACCOUNT_SETUP: 'ADMIN_ACCOUNT_SETUP',
  ADMIN_CREATE_ACCOUNT_VALIDATE_EMAIL: 'ADMIN_CREATE_ACCOUNT_VALIDATE_EMAIL',
  ADMIN_RESETING_PASSWORD: 'ADMIN_RESETING_PASSWORD',
  ADMIN_UPDATING_EMAIL: 'ADMIN_UPDATING_EMAIL',
  ADMIN_DEMO: 'ADMIN_DEMO',
  DEVICE: 'DEVICE',
  MACHINE: 'MACHINE',
  GUEST: 'GUEST',
  UNKNOWN: 'UNKNOWN',
};

export default USER_TYPE;
