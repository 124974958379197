import dayjsExt from 'utils/dayjsExt';

export function formatTimeDate(date) {
    if (date === undefined || date === null) return "-";
    if (isNaN(new Date(date).getTime())) return "-"
    return dayjsExt(new Date(date)).format("YYYY-MM-DD HH:mm:ss");
}


export function formatTimeDateFromNow(date) {
    if (date === undefined || date === null) return "-";
    if (isNaN(new Date(date).getTime())) return "-"
    return dayjsExt(new Date(date)).fromNow();
}

export function formatToDayMonthYear(date) {
    if (date === undefined || date === null) return "-";
    if (isNaN(new Date(date).getTime())) return "-"
    return dayjsExt(new Date(date)).format("YYYY-MM-DD");
}

const epochStart = dayjsExt().startOf('day').valueOf();
export const roundTime = (date, granularityMinutes = 30, epochStartOfDay = epochStart) => {
    return epochStartOfDay + (granularityMinutes * 60 * 1000) * Math.round((new Date(date).getTime() - epochStartOfDay) / (granularityMinutes * 60 * 1000))
}

export function formatDuration(startDate, endDate) {
    if (startDate === null || startDate === null || endDate === null || endDate === null) return "-";
    if (isNaN(new Date(startDate).getTime()) || isNaN(new Date(endDate).getTime())) return "-"
    const duration = dayjsExt.duration(dayjsExt(new Date(endDate)).diff(new Date(startDate)))
    const days = duration.days();
    const hours = duration.hours() % 24;
    const minutes = duration.minutes() % 60;
    const seconds = duration.seconds() % 60
    if (!days && !hours && !minutes && !seconds) return "0 seconds";
    return `${days ? `${days} days` : ""}:${hours ? `${hours} hours` : ""}:${minutes && !days ? `${minutes} minutes` : ""}:${seconds && !hours && !days ? `${seconds} seconds` : ""}`
        .split(":")
        .filter(el => el)
        .join(" and ");
}


export function formatDurationMs(durationMs) {
    if (isNaN(durationMs)) return "-"
    return `${durationMs < 0 ? "-" : "+"}${dayjsExt.duration(Math.abs(durationMs)).format('HH:mm:ss')}`
}
