import React, { useState, useMemo, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { spacing } from '@mui/system';
import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    CardHeader,
    TextField,
    Switch,
    Grid2,
    Typography,
    CircularProgress,
    Tooltip
} from '@mui/material';
import SetRegistryString from 'components/LadsConfig/SetRegistryString';
import SetRegistryDWORD from 'components/LadsConfig/SetRegistryDWORD';
import RestartLADSBIThread from 'components/LadsConfig/RestartLADSBIThread';
import TestLadsConnection from 'components/LadsConfig/TestLadsConnection';
import { getCompany, getMainTunnelStatus, getSecondaryTunnelStatus } from 'utils/api';
import { formatTimeDate, formatTimeDateFromNow } from 'utils/timeFormat';
import Authorized from 'components/Authorized';

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;
function LADS() {
    const mounted = useRef(false);
    const [ladsIP, setLadsIP] = useState(null);
    const [ladsPort, setLadsPort] = useState(null);
    const [useLegacyLadsProtocol, setUseLegacyLadsProtocol] = useState(false);
    const [detectAutomticaly, setDetectAutomaticaly] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [company, setCompany] = useState(null);
    const [isTunnelConnected, setIsTunnelConnected] = useState(false);
    useEffect(() => {
        getSecondaryTunnelStatus({ id: "66a91b35423ce6b7e70138c8" })
            .then(res => console.log("secondary status:", res))
            .catch(error => console.error("error secondary tunnel", error))
    }, [])
    useEffect(() => {
        mounted.current = true;
        setFetching(true);
        const fetchCall = getCompany().then(cmp => {
            setFetching(false);
            setCompany(cmp)
        });
        return () => {
            mounted.current = false;
            fetchCall.cancel();
        }
    }, []);
    const handleSuccess = () => {
        getCompany().then(cmp => {
            if (mounted.current) {
                setCompany(cmp)
            }
        });
    }
    useEffect(() => {
        const fetchCall = getMainTunnelStatus().then(status => {
            setIsTunnelConnected(!!status?.isConnected);
        });
        return () => {
            fetchCall.cancel();
        }
    }, [])

    const isValidIP = useMemo(() => {
        return /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(ladsIP);
    }, [ladsIP]);
    const isValidPort = useMemo(() => {
        return !isNaN(ladsPort) && parseInt(ladsPort) > 1024;
    }, [ladsPort])
    const isValid = useMemo(() => {
        return detectAutomticaly || (isValidPort && isValidIP)
    }, [detectAutomticaly, isValidPort, isValidIP]);
    return (<Card sx={{
        width: {
            xs: "100%",
            md: 420,
        }
    }}>
        <CardHeader title={
            <Typography variant='h3' gutterBottom>
                LADS
            </Typography>
        } />
        <CardContent>
            <Grid2 container spacing={2} alignItems="center" justifyContent="space-between">
                <Grid2>
                    <Typography variant="body1">
                        Tunnel:
                    </Typography>
                </Grid2>
                <Grid2>
                    <Typography variant="body1" color={isTunnelConnected ? "primary" : "error"}>
                        {isTunnelConnected ? <strong>Connected</strong> : <strong>Not Connected</strong>}
                    </Typography>
                </Grid2>
                <Grid2 size={12} />

                <Grid2>
                    <Typography variant="body1">
                        Last Contact (via tunnel):
                    </Typography>
                </Grid2>
                <Grid2>
                    {!fetching && <Typography variant="body1">
                        {company && company.ladsLastContact ? <Tooltip title={formatTimeDate(company.ladsLastContact)}><strong>{formatTimeDateFromNow(company.ladsLastContact)}</strong></Tooltip> : <strong>Never</strong>}
                    </Typography>}
                </Grid2>
                <Grid2 size={12} />
                <Grid2>
                    <Typography variant="body1">
                        Detect LADS IP/Port automaticaly
                    </Typography>
                </Grid2>
                <Grid2>
                    <Switch checked={detectAutomticaly} onChange={(e) => setDetectAutomaticaly(e.target.checked)} color="primary" />
                </Grid2>
                <Grid2 size={12} />
                <Grid2>
                    <Tooltip title="Some LADS support only old (less secure) protocol">
                        <Typography variant="body1">
                            Use Legacy Protocol
                        </Typography>
                    </Tooltip>
                </Grid2>
                <Grid2>
                    <Switch checked={useLegacyLadsProtocol} onChange={(e) => setUseLegacyLadsProtocol(e.target.checked)} color="primary" />
                </Grid2>
                {!detectAutomticaly && <><Grid2 size={12} />
                    <Grid2>
                        <TextField
                            required
                            error={!!(ladsIP && !isValidIP)}
                            label="LADS IP"
                            size="small"
                            value={ladsIP || ""}
                            placeholder='127.0.0.1'
                            onChange={(e) => setLadsIP(e.target.value)}
                            helperText={(ladsIP && !isValidIP) ? 'Invalid IP (shoud be like 192.168.1.2)' : ''}
                        />
                    </Grid2>
                    <Grid2>
                        <TextField
                            required
                            error={!!(ladsPort && !isValidPort)}
                            label="LADS Port"
                            size="small"
                            value={ladsPort || ""}
                            placeholder='12443'
                            onChange={(e) => setLadsPort(e.target.value)}
                            helperText={(ladsPort && !isValidPort) ? 'Invalid Port Number (shoud be > 1024)' : ''}
                            sx={{ width: 100 }}
                        />
                    </Grid2>
                </>}
                {company ? <>
                    <Grid2 size={12}/>
                    <Grid2 size={12}>
                        <Authorized permission="lads.writeRegistryKeyValue">
                            <SetRegistryString ip={ladsIP} port={ladsPort} isValid={isValid} isSzDashalyticsXUIDSet={company.szDashalyticsXUID && company.szDashalyticsXUID !== "0"} legacyLads={useLegacyLadsProtocol} />
                        </Authorized>
                    </Grid2>
                    <Grid2 size={12}>
                        <Authorized permission="lads.writeRegistryKeyValue">
                            <SetRegistryDWORD ip={ladsIP} port={ladsPort} isValid={isValid} isBBIUseDashalyticsByCurlSet={!!company.bBIUseDashalyticsByCurl} legacyLads={useLegacyLadsProtocol} />
                        </Authorized>
                    </Grid2>
                    <Grid2>
                        <Authorized permission="lads.restartBiThread">
                            <RestartLADSBIThread ip={ladsIP} port={ladsPort} isValid={isValid} legacyLads={useLegacyLadsProtocol} />
                        </Authorized>
                    </Grid2>
                    <Grid2>
                        <Authorized permission="lads.testConnection">
                            <TestLadsConnection ip={ladsIP} port={ladsPort} isValid={isValid} legacyLads={useLegacyLadsProtocol} onSuccess={handleSuccess} />
                        </Authorized>
                    </Grid2>
                </> : <Grid2 size={12} container justifyContent="center" alignItems="center" sx={{ height: 180 }}><CircularProgress color="secondary" /></Grid2>}
            </Grid2>
        </CardContent>
    </Card>
    );
}

export default LADS;
