import React, { useState } from 'react';
import {
    Box,
    Grid2,
    Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import Slider from 'components/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import usePreferences from 'hooks/usePreferences';

const Pointer = styled(PhoneAndroidIcon)`
    color: ${(props) => props.theme.palette.secondary.main};
    width: ${(props) => props.size || 32}px;
    height: ${(props) => props.size || 32}px;
`;


const preferencesID = "rtt-settings-uisettings";

const UISettings = () => {
    const { preferences, updatePreferences } = usePreferences();
    const settings = React.useMemo(() => preferences[preferencesID] || {}, [preferences]);
    const [iconSize, setIconSize] = useState(settings.iconSize || 32);
    const handleIconSizeChange = (e) => {
        setIconSize(e.target.value);
        updatePreferences(preferencesID, { iconSize: e.target.value });
    }
    return <Box>
        <Typography variant="body2" gutterBottom>
            Icon size {!settings.iconSize || settings.iconSize === iconSize ? '' : <CircularProgress color="primary" size={14} />}
        </Typography>
        <Grid2 container spacing={6}>
            <Grid2 flexGrow={1}>
                <Slider
                    valueLabelDisplay="auto"
                    max={200}
                    min={10}
                    value={Number.isInteger(iconSize) ? iconSize : 32}
                    onChange={handleIconSizeChange}
                    disableSwap
                    labelStyling={{
                        width: 32,
                        height: 32
                    }}
                />
            </Grid2>
            <Grid2>
                <Pointer size={iconSize} />
            </Grid2>
        </Grid2>
    </Box>
}

export default UISettings;