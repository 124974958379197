import {
    useState,
    useEffect,
    useMemo
} from "react";
import LeafletMap from "components/LeafletMap/LeafletMap";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Typography,
    Grid2,
    FormGroup,
    FormControlLabel,
    Switch
} from '@mui/material';
import { useSelector } from 'react-redux';
import WifiLevel from "components/WifiLevel";
import { getWifiLevels } from 'utils/api';
import { formatTimeDate, formatTimeDateFromNow } from 'utils/timeFormat';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjsExt from 'utils/dayjsExt';


const WifiLevelEventInfos = ({ date, androidID, name, level, mac }) => {
    return <Card variant="outlined">
        <CardContent>
            {name && <Typography variant="caption" display="block" align="center" gutterBottom>
                <strong>{name}</strong>
            </Typography>}
            <Typography variant="caption" display="block">
                AndroidID: <strong>{androidID}</strong>
            </Typography>
            {mac && <Typography variant="caption" display="block">
                AP: <strong>{mac}</strong>
            </Typography>}
            <Typography variant="caption" display="block">
                {formatTimeDateFromNow(date)}
            </Typography>
            <Typography variant="caption" display="block">
                {formatTimeDate(date)}
            </Typography>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                m: 2
            }}>
                <WifiLevel level={level} />
                <Box sx={{ px: 1 }} />
                <Typography variant="caption" display="block">
                    Level: <strong>{level}</strong>
                </Typography>
            </Box>
            <Typography variant="caption" display="block">
                <strong>* Details displayed are for the most recent even</strong>
            </Typography>
        </CardContent>
    </Card>
}

const Map = ({ dates }) => {
    const [wifiLevels, setWifiLevels] = useState([]);
    const selectedGroupID = useSelector((state) => state.groups.selectedGroup || '-');
    const devices = useSelector((state) => state.devices || []);
    const devicesNameMap = useMemo(() => {
        return devices.reduce((p, c) => ({ ...p, [c.androidID]: c.name || '' }), {});
    }, [devices]);


    useEffect(() => {
        if (!dates?.length) return;
        const [s, e] = dates;
        if (!s || !e || isNaN(s?.getTime()) || isNaN(e?.getTime())) return;
        const sdate = dayjsExt(dates[0]).startOf('day').toISOString();
        const edate = dayjsExt(dates[1]).endOf('day').toISOString();
        const fetchCall = getWifiLevels({
            sdate,
            edate,
            group: (selectedGroupID && selectedGroupID !== "-") ? selectedGroupID : undefined
        })
            .then((resp) => setWifiLevels(resp || []));
        return () => fetchCall.cancel();
    }, [dates, selectedGroupID]);

    const eventsLevels = useMemo(() => wifiLevels.map(el => {
        const wifiEventInfos = {
            ...el,
            intensity: el.level / 4,
            name: devicesNameMap[el.androidID]
        }
        return ({ ...wifiEventInfos, label: <WifiLevelEventInfos {...wifiEventInfos} /> })
    }), [wifiLevels, devicesNameMap]);

    return <div
        style={{
            width: '100%',
            height: 400,
        }}
    >
        <LeafletMap
            heatPoints={eventsLevels}
            zoomSlider={true}
        />
    </div>
}

const WifiHeatmap = () => {
    const [dates, setDates] = useState([dayjsExt().subtract(6, 'month').startOf('day').toDate(), new Date()]);
    const [showHeatMap, setShowHeatMap] = useState(false);
    const groups = useSelector((state) => state.groups.groups);
    const groupNamesMap = useMemo(() => {
        return groups.reduce((p, c) => ({ ...p, [c._id]: c.name || c._id }), {});
    }, [groups]);
    const selectedGroupID = useSelector((state) => state.groups.selectedGroup || '-');
    const handleDatePickerChange = (newValue) => {
        if (!Array.isArray(newValue)) return;
        if (!dayjsExt.isDayjs(newValue[0]) || !dayjsExt.isDayjs(newValue[1])) return;
        const epochStart = newValue[0].valueOf();
        const epochEnd = newValue[1].valueOf();
        if (isNaN(epochStart) || isNaN(epochEnd) || epochStart > epochEnd) return;
        const startYear = newValue[0].toDate().getFullYear();
        setDates([
            startYear < 2010 ? new Date("2010-01-01") : newValue[0].toDate(),
            epochEnd > Date.now() ? new Date() : newValue[1].toDate()
        ]);
    }

    const datepickerDates = useMemo(() => {
        return [
            dayjsExt(dates[0] || new Date("2010-01-01")),
            dayjsExt(dates[1] || new Date()),
        ]
    }, [dates])

    return (
        <Card sx={{ mb: 1 }}>
            <CardHeader title="Wifi outage heatmap">
            </CardHeader>
            <CardContent>
                <Grid2 container spacing={6} alignItems="center">
                    <Grid2>
                        <FormGroup>
                            <FormControlLabel control={<Switch checked={showHeatMap} size="small" onChange={(e) => setShowHeatMap(e.target.checked)} />} label="Show heatmap" />
                        </FormGroup>
                    </Grid2>
                    {showHeatMap && <>
                        <Grid2>
                            <DateRangePicker
                                onChange={handleDatePickerChange}
                                disableFuture
                                format={"MM-DD-YYYY"}
                                formatDensity="dense"
                                minDate={dayjsExt("2010-01-01")}
                                maxDate={dayjsExt()}
                                localeText={{ start: "Start", end: 'End' }}
                                value={datepickerDates}
                                slots={{
                                    fieldSeparator: () => <Box sx={{ mx: 2 }}> to </Box>
                                }}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        sx: { width: 120 }
                                    }
                                }}
                            />
                        </Grid2>
                        <Grid2>
                            {selectedGroupID && groupNamesMap[selectedGroupID] && <Typography variant="body2">Group: <strong>{groupNamesMap[selectedGroupID]}</strong></Typography>}
                        </Grid2>
                        <Grid2 size={12}>
                            <Map dates={dates} />
                        </Grid2>
                    </>}
                </Grid2>
            </CardContent>
        </Card>
    );
}
export default WifiHeatmap;
