import React, { useMemo } from 'react';
import { styled } from '@mui/system';
import { spacing } from "@mui/system";
import { Outlet } from "react-router-dom";
import {
  AppBar as MuiAppBar,
  Toolbar as MuiToolBar,
  CssBaseline,
  Paper as MuiPaper,
  Grid2,
  Button,
  Badge,
  Box
} from '@mui/material';
import { useLocation, Link } from "react-router-dom";
import {
  IconButton as MuiIconButton,
} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import GroupIcon from '@mui/icons-material/Group';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import NotificationsIcon from '@mui/icons-material/Notifications';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import RuleIcon from '@mui/icons-material/Rule';
import ArticleIcon from '@mui/icons-material/Article';
import PaidIcon from '@mui/icons-material/Paid';
import NavbarSupport from "components/navbarSupport/Navbar";
import { useSelector } from 'react-redux';
import useAuth from 'hooks/useAuth';


const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Root = styled(Box)`
  display: flex;
  min-height: 100vh;
  background: ${(props) => props.theme.palette.background.default};
  min-width: 300px;
`;
const AppContent = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Toolbar = styled(MuiToolBar)`
  padding-left: 0;
  min-width: 300px;
`
const ToolBarItems = styled(Grid2)`
  align-items: center;
  width: 100%;
`
const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  padding: 0px 32px;
`;

const Paper = styled(MuiPaper)(spacing);
const MainContent = styled(Paper)`
  flex: 1;
  overflow-y:auto;
  background: ${(props) => props.theme.palette.background.default};
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;


const Support = () => {
  const location = useLocation();
  const { userID } = useAuth();
  const convs = useSelector((state) => state.chat.convs || []);
  const nNewMessages = useMemo(() => convs.filter(el => el.message.from !== userID && Array.isArray(el.message.seenBy) && !el.message.seenBy?.includes(userID)).length, [userID, convs]);
  const duplicatedDevices = useSelector(state => state.support.duplicatedDevices);
  const nDuplicatedDevices = useMemo(() => duplicatedDevices.length, [duplicatedDevices]);
  return (
    (<Root>
      <CssBaseline />
      <AppBar>
        <Toolbar>
          <ToolBarItems container spacing={2}>
            <Grid2 sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Link
                to={`/support`}
                style={{ textDecoration: "none" }}
              >
                <Button variant={/^\/support\/?$/.test(location.pathname) ? "contained" : "outlined"} startIcon={<HomeIcon />}>
                  Stats
                </Button>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Link
                to={`/support/companies`}
                style={{ textDecoration: "none" }}
              >
                <Button variant={/^\/support\/(companies)|(company.*)$/.test(location.pathname) ? "contained" : "outlined"} startIcon={<MapsHomeWorkIcon />}>
                  Companies
                </Button>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Link
                to={`/support/admins`}
                style={{ textDecoration: "none" }}
              >
                <Button variant={/^\/support\/(admins)|(admin.*)$/.test(location.pathname) ? "contained" : "outlined"} startIcon={<GroupIcon />}>
                  Admins
                </Button>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Link
                to={`/support/chat`}
                style={{ textDecoration: "none" }}
              >
                <Button variant={/^\/support\/chat/.test(location.pathname) ? "contained" : "outlined"} startIcon={<ChatBubbleIcon />}>
                  Chat{nNewMessages ? ` (${nNewMessages})` : ""}
                </Button>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Link
                to={`/support/keyboards`}
                style={{ textDecoration: "none" }}
              >
                <Button variant={/^\/support\/keyboards/.test(location.pathname) ? "contained" : "outlined"} startIcon={<KeyboardIcon />}>
                  Keyboards
                </Button>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Link
                to={`/support/notifications`}
                style={{ textDecoration: "none" }}
              >
                <Button variant={/^\/support\/notifications/.test(location.pathname) ? "contained" : "outlined"} startIcon={<NotificationsIcon />}>
                  Notifications
                </Button>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Link
                to={`/support/diagnostics`}
                style={{ textDecoration: "none" }}
              >
                <Button variant={/^\/support\/diagnostics$/.test(location.pathname) ? "contained" : "outlined"} startIcon={<RuleIcon />}>
                  Diagnostics{nDuplicatedDevices ? ` (${nDuplicatedDevices})` : ""}
                </Button>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Link
                to={`/support/billing`}
                style={{ textDecoration: "none" }}
              >
                <Button variant={/^\/support\/billing$/.test(location.pathname) ? "contained" : "outlined"} startIcon={<PaidIcon />}>
                  Billing
                </Button>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Link
                to={`/support/documentations`}
                style={{ textDecoration: "none" }}
              >
                <Button variant={/^\/support\/documentations/.test(location.pathname) ? "contained" : "outlined"} startIcon={<ArticleIcon />}>
                  Documentations
                </Button>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'block', lg: 'none' } }}>
              <Link
                to={`/support`}
                style={{ textDecoration: "none" }}
              >
                <IconButton color={/^\/support\/?$/.test(location.pathname) ? "primary" : "secondary"}>
                  <HomeIcon />
                </IconButton>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'block', lg: 'none' } }}>
              <Link
                to={`/support/companies`}
                style={{ textDecoration: "none" }}
              >
                <IconButton color={/^\/support\/(companies)|(company.*)$/.test(location.pathname) ? "primary" : "secondary"}>
                  <MapsHomeWorkIcon />
                </IconButton>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'block', lg: 'none' } }}>
              <Link
                to={`/support/admins`}
                style={{ textDecoration: "none" }}
              >
                <IconButton color={/^\/support\/(admins)|(user.*)$/.test(location.pathname) ? "primary" : "secondary"}>
                  <GroupIcon />
                </IconButton>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'block', lg: 'none' } }}>
              <Link
                to={`/support/chat`}
                style={{ textDecoration: "none" }}
              >
                <IconButton color={/^\/support\/chat/.test(location.pathname) ? "primary" : "secondary"}>
                  <Badge badgeContent={nNewMessages} color={/^\/support\/chat/.test(location.pathname) ? "secondary" : "primary"}>
                    <ChatBubbleIcon />
                  </Badge>
                </IconButton>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'block', lg: 'none' } }}>
              <Link
                to={`/support/keyboards`}
                style={{ textDecoration: "none" }}
              >
                <IconButton color={/^\/support\/keyboards/.test(location.pathname) ? "primary" : "secondary"}>
                  <KeyboardIcon />
                </IconButton>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'block', lg: 'none' } }}>
              <Link
                to={`/support/notifications`}
                style={{ textDecoration: "none" }}
              >
                <IconButton color={/^\/support\/notifications/.test(location.pathname) ? "primary" : "secondary"}>
                  <NotificationsIcon />
                </IconButton>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'block', lg: 'none' } }}>
              <Link
                to={`/support/diagnostics`}
                style={{ textDecoration: "none" }}
              >
                <IconButton color={/^\/support\/diagnostics$/.test(location.pathname) ? "primary" : "secondary"}>
                  <Badge badgeContent={nDuplicatedDevices} color={/^\/support\/diagnostics$/.test(location.pathname) ? "warning" : "primary"}>
                    <RuleIcon />
                  </Badge>
                </IconButton>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'block', lg: 'none' } }}>
              <Link
                to={`/support/billing`}
                style={{ textDecoration: "none" }}
              >
                <IconButton color={/^\/support\/billing/.test(location.pathname) ? "primary" : "secondary"}>
                  <PaidIcon />
                </IconButton>
              </Link>
            </Grid2>
            <Grid2 sx={{ display: { xs: 'block', lg: 'none' } }}>
              <Link
                to={`/support/documentations`}
                style={{ textDecoration: "none" }}
              >
                <IconButton color={/^\/support\/documentations/.test(location.pathname) ? "primary" : "secondary"}>
                  <ArticleIcon />
                </IconButton>
              </Link>
            </Grid2>
            <Grid2 sx={{ flexGrow: 1 }} />
            <Grid2 ><NavbarSupport /></Grid2>
          </ToolBarItems>
        </Toolbar>
      </AppBar>
      <AppContent>
        <Toolbar />
        <MainContent sx={{ p: { xs: 5, lg: 12 } }}>
          <Outlet />
        </MainContent>
      </AppContent>
    </Root>)
  );
}

export default Support;