import { useMemo } from 'react';
import {
    Card as MuiCard,
    CardContent,
    CardHeader,
    Badge,
    Avatar,
    Typography,
    Grid2,
    Divider,
    IconButton
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import USER_TYPE from 'constants/userType';
import chatRoomType from 'constants/chatRoomType';
import { imgURL } from "utils/http"
import { styled } from '@mui/system';
import { formatTimeDateFromNow } from 'utils/timeFormat';
import Participant from '../Participant';
import useAuth from 'hooks/useAuth';


const OnlineBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) => props.color === 'primary' ?
        props.theme.sidebar.footer.online.background :
        props.theme.sidebar.footer.offline.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width:  12px;
    border-radius: 50%;
  }
`;

const Card = styled(MuiCard)`
    height: 100%;
    max-width:400px; 
    overflow: auto;
`


const ChatRoomDetails = ({ room, participants, close, showDeviceProfileLink = false }) => {
    const { userID } = useAuth();
    const { name, profilePicture, isOnline } = useMemo(() => {
        switch (room.type) {
            case chatRoomType.DEVICE: {
                const device = participants.find(el => /^[0-9a-f]{16}$/i.test(el._id))
                return {
                    name: device?.displayName || "Device",
                    profilePicture: "/static/img/avatars/android.png",
                    isOnline: !!device?.isOnline
                }
            }
            case chatRoomType.GROUP:
                return {
                    name: room.name || "Group",
                    profilePicture: "/static/img/avatars/group.png",
                    isOnline: "N/A"
                }
            case chatRoomType.PRIVATE: {
                let profilePicture = "/static/img/avatars/admin.png";
                const roomParticipants = participants.filter(el => el._id !== userID)
                if (roomParticipants[0]?.type === USER_TYPE.DEVICE) profilePicture = "/static/img/avatars/android.png";
                else profilePicture = roomParticipants[0]?.imageUrl ? imgURL + roomParticipants[0]?.imageUrl : "/static/img/avatars/admin.png";
                return {
                    name: roomParticipants[0]?.displayName || roomParticipants[0]?._id || "-",
                    profilePicture,
                    isOnline: !!roomParticipants[0]?.isOnline
                }
            }

            case chatRoomType.SUPPORT: {
                let profilePicture = "/static/img/avatars/admin.png";
                const roomParticipants = participants.filter(el => el._id !== userID)
                if (roomParticipants[0]?.type === USER_TYPE.DEVICE) profilePicture = "/static/img/avatars/android.png";
                else profilePicture = roomParticipants[0]?.imageUrl ? imgURL + roomParticipants[0]?.imageUrl : "/static/img/avatars/admin.png";
                return {
                    name: roomParticipants[0]?.displayName || roomParticipants[0]?._id || "-",
                    profilePicture,
                    isOnline: !!roomParticipants[0]?.isOnline
                }
            }

            default:
                return {
                    name: room.name || "*Group",
                    profilePicture: "/static/img/avatars/group.png",
                    isOnline: "N/A"
                }
        }
    }, [participants, room, userID])

    return <Card>
        <CardHeader avatar={
            <OnlineBadge
                overlap="circular"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                variant="dot"
                invisible={isOnline === "N/A"}
                color={isOnline ? 'primary' : 'secondary'}
            >
                <Avatar
                    alt={name}
                    src={profilePicture}
                />
            </OnlineBadge>
        }
            title={name}
            action={close ? <IconButton aria-label="close" color="primary" onClick={close}>
                <CancelIcon />
            </IconButton> : undefined}
        />
        <CardContent>
            <Grid2 container spacing={1}>
                <Grid2 size={12}>
                    <Typography variant="body2">Name: <strong>{room.name || ""}</strong></Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Typography variant="body2">Type: <strong>{room.type || ""}</strong></Typography>
                </Grid2>
                <Grid2 size={12}>
                    {room.createdAt && <Typography variant="body2">Created: <strong>{formatTimeDateFromNow(room.createdAt)}</strong></Typography>}
                </Grid2>
                <Grid2 size={12}>
                    <Divider />
                </Grid2>
                <Grid2 size={12}>
                    <Typography variant="body1">Members</Typography>
                </Grid2>
                {participants.map(el => <Grid2 key={el._id} size={12}>
                    <Participant 
                        {...el} 
                        showDeviceProfileLink={showDeviceProfileLink} 
                    />
                </Grid2>)}
            </Grid2>
        </CardContent>
    </Card>
}

export default ChatRoomDetails;