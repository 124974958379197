import React, {
  useState,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { Box } from '@mui/material';
import { baseURL } from "utils/http";
import { useSelector } from 'react-redux';
import DevicePointer from '../Components/DevicePointer';
import AccessPointGroup from '../Components/AccessPointGroup';
import AccessPointSignal from './AccessPointSignal';
import usePreferences from "hooks/usePreferences";

const preferencesID = "rtt-settings-uisettings";

function RTTMap({ rttSettings, mapDevices, groupDevices }) {
  const { preferences } = usePreferences();
  const settings = React.useMemo(() => preferences[preferencesID] || { iconSize: 32 }, [preferences]);
  const [mapWidth, setMapWidth] = useState(0);
  const mapRef = useRef();
  const resizingTimer = useRef(null);
  useEffect(() => {
    const mapRefCurrent = mapRef.current;
    if (!ResizeObserver || !mapRefCurrent) return;
    const observer = new ResizeObserver(() => {
      clearTimeout(resizingTimer.current);
      resizingTimer.current = setTimeout(() => {
        if (mapRefCurrent?.clientWidth) {
          setMapWidth(mapRefCurrent.clientWidth)
        }
      }, 100)

    });
    observer.observe(mapRefCurrent);
    return () => {
      if (!observer) return;
      if (mapRefCurrent) observer.unobserve(mapRefCurrent);
      observer.disconnect();
    }
  }, []);


  useEffect(() => {
    if (ResizeObserver) return;
    const mapRefCurrent = mapRef.current;
    if (mapRefCurrent && mapRefCurrent.clientWidth && mapRefCurrent.clientWidth !== mapWidth) {
      setMapWidth(mapRefCurrent.clientWidth);
    }
  }, [mapRef, mapWidth]);
  const devices = useSelector((state) => state.rtt.devices || {});
  const devicesInfos = useSelector((state) => state.devices || {});
  const accessPointsList = useSelector((state) => state.rtt.accessPoints || []);
  const accessPoints = useMemo(() => [...accessPointsList]
    .sort((a, b) => `${b.mac}`.localeCompare(`${a.mac}`))
    .sort((a, b) => `${b.type}`.localeCompare(`${a.type}`))
    , [accessPointsList]);
  const mapHeight = useMemo(() => {
    if (rttSettings && rttSettings.rttMapWidth && rttSettings.rttMapHeight) {
      return mapWidth * parseInt(rttSettings.rttMapHeight) / parseInt(rttSettings.rttMapWidth);
    }
    return mapWidth;
  }, [mapWidth, rttSettings]);

  const devicesMap = useMemo(() => {
    return devicesInfos.reduce((p, c) => ({ ...p, [c.androidID]: c }), {});
  }, [devicesInfos]);

  const groupDevicesMap = useMemo(() => {
    return groupDevices.reduce((p, c) => ({ ...p, [c]: c }), {});
  }, [groupDevices]);

  const devicesLocation = useMemo(() => {
    const _devices = Object.values(devices);
    const offsetX = rttSettings.rttOffsetX || 0;
    const offsetY = rttSettings.rttOffsetY || 0;
    if (!mapWidth || !mapHeight) return [];
    if (_devices.length === 0) return [];
    if (!rttSettings.rttMapWidth || !rttSettings.rttMapHeight) return [];

    // filter if they are outside the boundries
    return _devices
      .filter(el => groupDevicesMap[el.androidID] &&
        (mapDevices[el.androidID] && mapDevices[el.androidID].showPointer) &&
        el.x + offsetX >= -2000 &&
        el.x + offsetX <= rttSettings.rttMapWidth + 2000 &&
        el.y + offsetY >= -2000 &&
        el.y + offsetY <= rttSettings.rttMapHeight + 2000
      )
      .map(el => ({
        x: Math.round(mapWidth * (el.x + offsetX) / rttSettings.rttMapWidth),
        y: Math.round(mapHeight * (el.y + offsetY) / rttSettings.rttMapHeight),
        t: el.t,
        id: el.androidID,
        lastContact: (devicesMap[el.androidID] && devicesMap[el.androidID].date) ? (new Date(devicesMap[el.androidID].date)).getTime() : 0,
        wifi_level: (devicesMap[el.androidID] && Number.isInteger(devicesMap[el.androidID].wifi_level)) ? devicesMap[el.androidID].wifi_level : '-',
        androidID: el.androidID,
        ipaddr: devicesMap[el.androidID] ? devicesMap[el.androidID].ipaddr : '',
        name: devicesMap[el.androidID] ? (devicesMap[el.androidID].name || devicesMap[el.androidID].ipaddr || el.androidID) : el.androidID,
        accessPoints: el.accessPoints,
      }));
  }, [devices, rttSettings, mapWidth, mapHeight, mapDevices, devicesMap, groupDevicesMap]);
  const apInfos = useMemo(() => {
    if (!accessPoints) return [];
    if (!accessPoints.length) return [];
    const offsetX = rttSettings.rttOffsetX || 0;
    const offsetY = rttSettings.rttOffsetY || 0;
    if (!rttSettings.rttMapWidth || !rttSettings.rttMapHeight) return [];
    return accessPoints
      .map(el => ({
        x: Math.round(mapWidth * (el.x + offsetX) / rttSettings.rttMapWidth),
        y: Math.round(mapHeight * (el.y + offsetY) / rttSettings.rttMapHeight),
        mac: el.mac,
        id: el.mac,
        disabled: el.disabled,
        type: el.type,
        temperature: el.temperature,
        battery_percentage_left: el.battery_percentage_left
      }));

  }, [rttSettings, mapWidth, mapHeight, accessPoints]);
  const apSignal = useMemo(() => {
    if (!accessPoints?.length || !mapWidth || !mapHeight) return [];
    const offsetX = rttSettings.rttOffsetX || 0;
    const offsetY = rttSettings.rttOffsetY || 0;
    if (!rttSettings.rttMapWidth || !rttSettings.rttMapHeight) return [];
    const _devices = Object.values(devices).filter(el => groupDevicesMap[el.androidID] && mapDevices[el.androidID] && mapDevices[el.androidID].showAPSignals);
    if (!_devices.length) return [];
    const accessPointsMap = accessPoints
      .map(el => ({
        x: Math.round(mapWidth * (el.x + offsetX) / rttSettings.rttMapWidth),
        y: Math.round(mapHeight * (el.y + offsetY) / rttSettings.rttMapHeight),
        mac: el.mac,
        id: el.mac,
        disabled: el.disabled,
      })).reduce((p, c) => ({ ...p, [c.mac]: c }), {});
    const _apSignals = [];
    _devices.forEach(device => {
      if (device.accessPoints && Array.isArray(device.accessPoints)) {
        device.accessPoints.forEach(el => {
          const radiusProjectedAtZDevice = Math.sqrt(Math.pow(el.distance, 2) - Math.pow((el.z - device.z), 2)) || 0;
          console.log({
            r: Math.round(radiusProjectedAtZDevice),
            d: Math.round(el.distance)
          })
          if (!el.distance || !radiusProjectedAtZDevice) return;
          if (accessPointsMap[el.mac]) {
            _apSignals.push({
              ...el,
              ...accessPointsMap[el.mac],
              distance: el.distance * mapWidth / rttSettings.rttMapWidth,
              radius: radiusProjectedAtZDevice * mapWidth / rttSettings.rttMapWidth,
              id: `${el.mac}-${device.androidID}`
            });
          }
        })

      }
    })
    return _apSignals;
  }, [rttSettings, mapWidth, mapHeight, accessPoints, mapDevices, devices, groupDevicesMap]);
  return (
    <Box style={{
      width: '100%'
    }}>
      <Box
        ref={mapRef}
        sx={{
          position: 'relative',
          border: 1,
          borderColor: 'grey.500',
          backgroundSize: '100% auto',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top left',
          backgroundImage: rttSettings && rttSettings.rttImageUrl ? `url(${baseURL}${rttSettings.rttImageUrl})` : '',
          height: mapHeight,
          width: 'calc(100% - 10px)',
          m: 2,
          overflow: 'hidden'
        }}
      >
        <AccessPointGroup apInfos={apInfos} accessPointsInfosMap={{}} />
        {apSignal.map((el) => <AccessPointSignal
          key={el.id}
          x={el.x}
          y={el.y}
          r={el.radius}
          distance={el.distance}
          mac={el.mac}
          disabled={el.disabled}
        />)}
        {devicesLocation.map((el) => <DevicePointer
          key={el.id}
          x={el.x}
          y={el.y}
          t={el.t}
          showName={mapDevices[el.androidID] && mapDevices[el.androidID].showName}
          smoothing={rttSettings.smoothness}
          androidID={el.androidID}
          name={el.name}
          accessPoints={el.accessPoints || []}
          iconSize={settings.iconSize}
          lastContact={el.lastContact}
          wifi_level={el.wifi_level}
        />)}
      </Box>
    </Box>
  );
}

export default RTTMap;
