import { useMemo } from 'react';
import {
    Grid2,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Typography,
    IconButton
} from '@mui/material';
import { useSelector } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import useAuthorization from 'hooks/useAuthorization';
import useAuth from 'hooks/useAuth';
import USER_TYPE from 'constants/userType';
import chatRoomType from 'constants/chatRoomType';

const RoomSelect = ({ roomID, setRoomID, resetForm, defaultRoom, isCreateMode, setIsCreateMode, handleClose }) => {
    const { type, userID } = useAuth();
    const { isAuthorized } = useAuthorization();
    const canCreateChatRoom = isAuthorized("chatRoom.create");
    const canReadChatRoom = isAuthorized("chatRoom.read");
    const chatRooms = useSelector(state => state.chat.chatRooms);
    const handleEnableCreateMode = () => {
        setIsCreateMode(true);
        resetForm();
    }
    const handleDisableCreateMode = () => {
        setIsCreateMode(false);
        setRoomID(defaultRoom || chatRooms[0]?._id);
    }

    const isDeviceAndHasAlreadyADeviceRoom = useMemo(() => {
        if(type !== USER_TYPE.DEVICE) return false;
        const hasDeviceRoom = chatRooms.some(room => room.type === chatRoomType.DEVICE && room.participants.includes(userID));
        return hasDeviceRoom;
    }, [type, chatRooms, userID]);
    return <Grid2 container spacing={2} alignItems="center" justifyContent="space-between">
        {isCreateMode ? (
            <Grid2>
                <Typography variant="h6">Creating new Chat Room</Typography>
            </Grid2>
        ) : (<Grid2>
            <FormControl sx={{ width: 200 }}>
                <InputLabel id="roomid-select-label">Room</InputLabel>
                <Select
                    labelId="roomid-select-label"
                    id="roomid-select"
                    value={roomID || ''}
                    label="Room"
                    size="small"
                    disabled={!canReadChatRoom}
                    onChange={(e) => setRoomID(e.target.value)}
                >
                    {chatRooms.map(el => <MenuItem key={el._id} value={el._id}>{el.name || el._id}</MenuItem>)}
                </Select>
            </FormControl>
        </Grid2>)}
        <Grid2 flexGrow={1} />
        <Grid2>
            {!isCreateMode && <Button
                variant="contained"
                endIcon={<AddCircleIcon />}
                size="small"
                onClick={handleEnableCreateMode}
                sx={{ mx: 1 }}
                disabled={!canCreateChatRoom || isDeviceAndHasAlreadyADeviceRoom}
            >
                Create
            </Button>}
            {isCreateMode && <Button
                variant="contained"
                endIcon={<CancelIcon />}
                size="small"
                onClick={handleDisableCreateMode}
                sx={{ mx: 1 }}
                disabled={chatRooms.length === 0}
            >
                Cancel
            </Button>}
        </Grid2>
        <Grid2>
            <IconButton color="primary" onClick={handleClose}>
                <CancelIcon />
            </IconButton>
        </Grid2>
    </Grid2>
}
export default RoomSelect;