import React from "react";
import THEMES from "constants/themes";
import { ThemeProvider } from '@mui/material/styles';
import createTheme from "theme";


function LandingPageTheme({ children }) {
  return (
    <ThemeProvider theme={createTheme(THEMES.DEFAULT)}>
      {children}
    </ThemeProvider>
  );
}

export default LandingPageTheme;
