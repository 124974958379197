import React, { useState } from "react";
import { styled } from '@mui/system';
import { spacing } from "@mui/system";
import { Link } from "react-router-dom";
import {
  AppBar,
  Button as MuiButton,
  Container,
  Grid2,
  Toolbar,
  Box
} from "@mui/material";
import Logo from 'components/Logo'
import useAuth from "hooks/useAuth";
import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";
import { Power } from "react-feather";
import CustomLink from 'components/Link';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;


const Button = styled(MuiButton)(spacing);

const Brand = styled(Box)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  margin-top: ${(props) => props.theme.spacing(2)};
  padding: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 300px;
  vertical-align: middle;
  display: inline;
  box-sizing: content-box;
`;

const AppBarComponent = () => {
  const { userID, isInitialized, signOut, setupStep } = useAuth();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await signOut();
  };
  return (
    <React.Fragment>
      <AppBar position="relative" color="transparent" elevation={0}>
        <Toolbar>
          <Container>
            <Grid2 container alignItems="center">
              <Grid2 size={{ xs: 12, sm: "auto" }}>
                <Grid2 container alignItems="center" justifyContent="center">
                  <Grid2>
                    <Brand>
                      <BrandIcon />
                    </Brand>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 sx={{ flexGrow: 1 }} />
              {!userID && <Grid2 sx={{ display: { xs: "none", md: "inline-block" } }}>
                <Button
                  sx={{ ml: 2 }}
                  component={CustomLink}
                  color="inherit"
                  to="https://dashalytics.app/auth/request-demo"
                  target="_blank"
                  variant="text"
                  size="normal"
                >
                  Live Preview
                </Button>
              </Grid2>}
              <Grid2 sx={{ display: { xs: "none", md: "inline-block" } }}>
                <Button
                  component={CustomLink}
                  sx={{ ml: 2 }}
                  color="inherit"
                  to="https://www.georgiasoftworks.net/support_gsw/"
                  target="_blank"
                  variant="text"
                  size="normal"
                >
                  Support
                </Button>
              </Grid2>
              {isInitialized && !userID && <Grid2 size={{ xs: 12, sm: 'auto' }}>
                <Grid2 container alignItems="center" sx={{ justifyContent: { xs: "space-around", sm: "start" } }}>
                  <Grid2>
                    <Button
                      sx={{ ml: 2 }}
                      color="inherit"
                      to="/auth/sign-in"
                      target="_self"
                      component={Link}
                      variant="text"
                      size="normal"
                    >
                      Log-In
                    </Button>
                  </Grid2>
                  <Grid2>
                    <Button
                      sx={{ ml: 2 }}
                      color="secondary"
                      variant="outlined"
                      to="/auth/sign-up"
                      target="_self"
                      component={Link}
                    >
                      Sign Up
                    </Button>
                  </Grid2>
                </Grid2>
              </Grid2>}
              {userID && <Grid2 size={{ xs: 12, sm: "auto" }}>
                <Grid2 container alignItems="center">
                  <Grid2 sx={{ flexGrow: 1 }} />
                  <Grid2>
                    <Button
                      sx={{ ml: 2 }}
                      color="secondary"
                      variant="contained"
                      to={setupStep ? `/setup?step=${setupStep}` : "/setup"}
                      target="_self"
                      component={Link}
                    >
                      {setupStep ? "Finish Setup" : "Get Started"}
                    </Button>
                  </Grid2>
                  <Grid2>
                    <Tooltip title="Account">
                      <IconButton
                        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                        aria-haspopup="true"
                        onClick={toggleMenu}
                        color="inherit"
                        size="large"
                      >
                        <Power />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorMenu}
                      open={Boolean(anchorMenu)}
                      onClose={closeMenu}
                    >
                      <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
                    </Menu>
                  </Grid2>
                </Grid2>
              </Grid2>}
            </Grid2>
          </Container>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default AppBarComponent;
