import {
  Typography,
  Box,
  Chip
} from '@mui/material';
import {
  // Calendar,
  List,
  Map,
  Sliders,
  Smartphone,
  //Settings,
  MessageSquare,
  //Tool,
  Globe,
} from "react-feather";
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const pagesSection = [
  {
    id: "1ee3b79f326e",
    isMandatory: true,
    icon: Sliders,
    title: "Dashboard",
    children: [
      {
        id: "67863d74183a",
        //isMandatory: true,
        href: "/",
        title: "System Overview",
      },
      {
        id: "923ca6296209",
        //isMandatory: true,
        href: "/productivity",
        title: "Productivity",
      },
      {
        id: "e07610cbdace",
        //isMandatory: true,
        href: "/alerts",
        title: "Alerts",
      },
      {
        id: "4e054e0f424a",
        //isMandatory: true,
        href: "/uts",
        title: "UTS",
      },
      {
        id: "02c19d326c61",
        //isMandatory: true,
        href: "/users",
        title: "Users",
      }

    ],
  },
  {
    id: "18fd267bd7b1",
    //isMandatory: true,
    href: "/devices",
    icon: Smartphone,
    title: "Device List",
  },
  {
    id: "35fc9f82eeac",
    isMandatory: true,
    icon: Map,
    title: "Positioning",
    children: [
      {
        id: "10a7f92d8a0c",
        //isMandatory: true,
        href: "/pages/gps",
        title: "Outdoor",
      },
      {
        id: "1206ba417de4",
        //isMandatory: true,
        href: "/pages/map",
        title: "Indoor",
      },
    ]
  },
  {
    id: "9f40b43b6193",
    //isMandatory: true,
    href: "/chat",
    title: "Chat",
    icon: MessageSquare,
  },
  {
    id: "245330620e23",
    isMandatory: true,
    icon: List,
    title: "Logs",
    children: [
      {
        id: "1cd0dcc262a1",
        //isMandatory: true,
        href: "/logs/alertlog",
        title: "Alert Log",
      },
      {
        id: "70c76fe4c817",
        //isMandatory: true,
        href: "/logs/eventlog",
        title: "Event Log",
      },
      {
        id: "2a81ffcf7962",
        //isMandatory: true,
        href: "/logs/scanlog",
        title: "Scan Log",
      },
      {
        id: "95c7daa52409",
        //isMandatory: true,
        href: "/logs/wifidroplog",
        title: "Wi-Fi Drop Log",
      }
    ],
  },

  // {
  //   href: "/calendar",
  //   icon: Calendar,
  //   title: "Calendar",
  // },
];

const administrationSection = [
  /*
  {
    href: "/device-config",
    icon: Tool,
    title: "Device Config",
  },
  */
  {
    id: "8c9bbf748ae5",
    //isMandatory: true,
    icon: KeyboardAltOutlinedIcon,
    title: <Box sx={{
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      overflow: "hidden"
    }}>
      <Typography>Keyboards</Typography>
      <Chip label="beta" size="small" color="info" />
    </Box>,
    href: "/settings/keyboard"
  },
  {
    id: "630194a9d0ce",
    //isMandatory: true,
    icon: PhoneAndroidOutlinedIcon,
    title: "Devices",
    href: "/settings/devices"
  },
  {
    id: "23089a9c58a7",
    //isMandatory: true,
    icon: GroupOutlinedIcon,
    title: "Admins",
    href: "/settings/admins"
  },
  {
    id: "96345a22a261",
    //isMandatory: true,
    icon: Globe,
    title: "Company",
    href: "/company"
  },
  /*
    {
      icon: Settings,
      title: "Settings",
      children: [
        {
          href: "/settings/account",
          title: "My Account",
        },
        {
          href: "/settings/notifications",
          title: "Notifications",
        },
        {
          href: "/settings/users",
          title: "Users",
        },
        {
          href: "/settings/devices",
          title: "Devices",
        },
        {
          href: "/settings/downloads",
          title: "Downloads",
        },
        {
          href: "/settings/manage-data",
          title: "My Data",
        },
        {
          href: "/settings/security",
          title: "Security",
        },
        /*
        {
          href: "/settings/encryption",
          title: "Encryption",
        },
        {
          href: "/settings/generate-new-uid",
          title: "Generate new UID",
        },
        */
  /*
 ],
},
{
 icon: Globe,
 title: "Company",
 children: [
   {
     href: "/company/billing",
     title: "Billing",
   },
 ]
}
*/
];

const helpCenterSection = [
  {
    id: "f3131fd01e8b",
    isMandatory: true,
    icon: HelpOutlineIcon,
    title: "Help Center",
    children: [
      {
        id: "3e2632b45b7",
        isMandatory: true,
        href: "/docs",
        title: "Documentation",
      }
    ]
  }
]
const navItems = [
  {
    id: "2f6ef2ab460f",
    isMandatory: true,
    title: "Manage",
    pages: pagesSection,
  },
  {
    id: "62ee9ba7adcc",
    isMandatory: true,
    title: "Administration",
    pages: administrationSection,
  },
  {
    id: "8314bbb4cef",
    isMandatory: true,
    title: "Help Center",
    pages: helpCenterSection,
  }
];
export default navItems;
