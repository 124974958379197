import React, { useState, useEffect } from "react"
import { styled } from '@mui/system';
import { toast } from "react-toastify"
import {
    Card as MuiCard,
    CardHeader,
    CardContent,
    Grid2,
    TextField as MuiTextField,
    Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { http } from "utils/http";
import { isValidEmail } from "utils/validation";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);

const isValidPassword = (password) => {
    return password && password.length > 8;
}
function UpdateMyEmail() {
    const [saveFormEnabled, setSaveFormEnabled] = useState(false);
    const [newEmail1, setNewEmail1] = useState('');
    const [newEmail2, setNewEmail2] = useState('');
    const [password, setPassword] = useState(process.env.REACT_APP_DEFAULT_DEV_PASSWORD || '');
    const [fetching, setFetching] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (isValidEmail(newEmail1) && newEmail1 === newEmail2 && isValidPassword(password)) {
            setSaveFormEnabled(true);
        }
        else setSaveFormEnabled(false);
    }, [newEmail1, newEmail2, password])
    const handleEmailUpdate = async () => {
        setMessage("");
        try {
            setFetching(true);
            const resp = await http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/request-email-update`, {
                newEmail: newEmail1,
                password
            })
            setFetching(false);
            if (resp.data && resp.data.message) {
                if (resp.data.message) toast.success(resp.data.message);
                setMessage(resp.data.message);
                setPassword('');
            }
        } catch (err) {
            setFetching(false);
            if (err && err.response && err.response.data && err.response.data.message) {
                return toast.error(err.response.data.message);
            }
            toast.error("Something went wrong");
        }
    }

    return (
        <Card sx={{ mb: 6 }}>
            <CardHeader title={<Typography variant="h6">
                Update My Email
            </Typography>
            } />
            <CardContent>
                {message && <Typography variant="body1" color="primary" gutterBottom>
                    {message}
                </Typography>}
                <Grid2 container spacing={6}>
                    <Grid2 size={{ xs: 12, md: 6 }} order={{ xs: 2, md: 1 }}>
                        <TextField
                            error={!!newEmail1 && !isValidEmail(newEmail1)}
                            label={newEmail1.length ? "New Email" : "Change Email"}
                            value={newEmail1}
                            onChange={(e) => setNewEmail1(e.target.value)}
                            variant="outlined"
                            type="email"
                            size="small"
                            fullWidth
                            sx={{ my: 2 }}
                            helperText={newEmail1 && !isValidEmail(newEmail1) ? "Invalid Email" : ""}
                        />
                        {isValidEmail(newEmail1) && (
                            <>
                                <TextField
                                    error={!!newEmail2 && newEmail2 !== newEmail1}
                                    label="Confirm Email"
                                    value={newEmail2}
                                    onChange={(e) => setNewEmail2(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                    size="small"
                                    sx={{ my: 2 }}
                                    helperText={newEmail2 && newEmail2 !== newEmail1 ? "Email must match" : ""}
                                />
                                <TextField
                                    error={!!password && !isValidPassword(password)}
                                    label="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    type="password"
                                    size="small"
                                    sx={{ my: 2 }}
                                    helperText={password && !isValidPassword(password) ? "Password too short (8 chars min)." : ""}
                                />
                            </>
                        )}

                    </Grid2>
                </Grid2>
                {saveFormEnabled && <Typography sx={{ my: 3 }} variant="body1" color="error" gutterBottom>
                    * You will no longer be able to access your account using your old email.
                </Typography>}
                <LoadingButton
                    onClick={handleEmailUpdate}
                    endIcon={<SaveIcon />}
                    loading={fetching}
                    loadingPosition="end"
                    variant="contained"
                    color="primary"
                    disabled={!saveFormEnabled}
                >
                    Update
                </LoadingButton>
            </CardContent>
        </Card >
    );
}
export default UpdateMyEmail;