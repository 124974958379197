import adminPermissions from './adminPermissions';

const permissionsDocs = {
    [adminPermissions.page.myAccount]: "View Page",
    [adminPermissions.page.systemOverview]: "View Page",
    [adminPermissions.page.productivity]: "View Page",
    [adminPermissions.page.alerts]: "View Page",
    [adminPermissions.page.uts]: "View Page",
    [adminPermissions.page.chat]: "View Page",
    [adminPermissions.page.deviceList]: "View Page",
    [adminPermissions.page.outdoorPositioning]: "View Page",
    [adminPermissions.page.indoorPositioning]: "View Page",
    [adminPermissions.page.alertLog]: "View Page",
    [adminPermissions.page.eventLog]: "View Page",
    [adminPermissions.page.scanLog]: "View Page",
    [adminPermissions.page.wiFiDropLog]: "View Page",
    [adminPermissions.page.groupsSettings]: "View Page",
    [adminPermissions.page.admins]: "View Page",
    [adminPermissions.page.company]: "View Page",
    [adminPermissions.page.deviceDetails]: "View Page",
    [adminPermissions.page.mergeDevices]: "View Page",
    [adminPermissions.page.users]: "View Page",
    [adminPermissions.page.userDetails]: "View Page",
    [adminPermissions.page.keyboard]: "xView Page",
    [adminPermissions.page.keyboardCreator]: "xView Page",
    [adminPermissions.page.keyboardAdvanced]: "xView Page",
    [adminPermissions.page.keyboardsManagement]: "xView Page",
    [adminPermissions.page.keyboardLadsManagement]: "xView Page",
    [adminPermissions.page.adminsPermissions]: "View Page",
    [adminPermissions.page.badges]: "View Page",
    [adminPermissions.page.remoteManagement]: "View Page",
    [adminPermissions.page.generateNewUUID]: "View Page",
    [adminPermissions.page.encryption]: "View Page",
    [adminPermissions.admin.read]: "View Admins",
    [adminPermissions.admin.invite]: "Invite Admin",
    [adminPermissions.role.read]: "View Profiles",
    [adminPermissions.role.create]: "Create Profile",
    [adminPermissions.role.update]: "Update Profile",
    [adminPermissions.role.delete]: "Delete Profile",
    [adminPermissions.event.read]: "Read Events",
    [adminPermissions.event.updateIgnoreScan]: "Change Scan Ingore Policy",
    [adminPermissions.alert.update]: "Clear Alert",
    [adminPermissions.alert.read]: "View Alerts",
    [adminPermissions.chatMessage.read]: "View Chat Message",
    [adminPermissions.chatMessage.write]: "Send Chat Message",
    [adminPermissions.chatConversationsList.read]: "View Conversations",
    [adminPermissions.chatUser.read]: "View Chat Users",
    [adminPermissions.chatRoom.read]: "Chat Room view",
    [adminPermissions.chatRoom.create]: "Chat Room Create",
    [adminPermissions.chatRoom.update]: "Chat Room Update",
    [adminPermissions.chatRoom.delete]: "Chat Room Delete",
    [adminPermissions.permission.read]: "View Permissions",
    [adminPermissions.device.read]: "View Devices Infos",
    [adminPermissions.device.delete]: "Delete Device",
    [adminPermissions.device.renameDevice]: "Rename Device",
    [adminPermissions.device.mergeDevices]: "Merge Devices",
    [adminPermissions.device.reloadVars]: "Reload Device Infos",
    [adminPermissions.device.ping]: "Ping Device",
    [adminPermissions.device.releaseLicense]: "Release Device License",
    // remote management
    [adminPermissions.remoteManagement.read]: "Read Device infos",
    [adminPermissions.remoteManagement.downloadFile]: "Download files",
    [adminPermissions.remoteManagement.downloadLogFile]: "Download Log File",
    [adminPermissions.remoteManagement.uploadFile]: "Upload files",
    [adminPermissions.remoteManagement.listFiles]: "List files",
    [adminPermissions.remoteManagement.pathKitting]: "Access Kitting",
    [adminPermissions.remoteManagement.pathSDCard]: "Access SDCard",
    [adminPermissions.remoteManagement.createFolder]: "Create Folder",
    [adminPermissions.remoteManagement.deleteFile]: "Delete File",
    [adminPermissions.remoteManagement.viewScreen]: "View Screen",
    [adminPermissions.remoteManagement.controlScreen]: "Control Screen",
    [adminPermissions.remoteManagement.hardwareKeys]: "Hardware Keys / Keyboard",
    [adminPermissions.remoteManagement.rebootDevice]: "Reboot Device",
    [adminPermissions.remoteManagement.clearProxyCache]: "Clear Proxy Cache",
    [adminPermissions.payment.readStatus]: "View Payments Status",
    [adminPermissions.payment.readRportedUsage]: "View Payments Reported Usage",
    [adminPermissions.payment.createCheckout]: "Purchase Paid Services",
    [adminPermissions.payment.createPortal]: "Update Payment Subscription",
    [adminPermissions.payment.readClientReferenceId]: "View Payment Infos",
    [adminPermissions.payment.downloadInvoice]: "Download Invoice",
    [adminPermissions.group.read]: "View Groups",
    [adminPermissions.group.moveDevice]: "Change Device's Group",
    [adminPermissions.group.create]: "Create Group",
    [adminPermissions.group.update]: "Update Group",
    [adminPermissions.group.delete]: "Delete Group",
    [adminPermissions.setting.read]: "View Settings",
    [adminPermissions.setting.create]: "Create Settings",
    [adminPermissions.setting.resetKeyboard]: "Reset Keyboard",
    [adminPermissions.setting.update]: "Update Settings",
    [adminPermissions.setting.delete]: "Delete Settings",
    [adminPermissions.notification.read]: "View System Notifications",
    [adminPermissions.notification.markAsRead]: "Clear System Notifications",
    [adminPermissions.keyboard.read]: "View Keyboards",
    [adminPermissions.keyboard.update]: "Update Keyboard",
    [adminPermissions.keyboard.delete]: "Delete Keyboard",
    [adminPermissions.file.uploadFile]: "Upload Files",
    [adminPermissions.file.uploadImage]: "Upload Images",
    [adminPermissions.authLog.read]: "View Auth Log",
    [adminPermissions.myAccount.read]: "View My Account",
    [adminPermissions.myAccount.write]: "Update My Account",
    [adminPermissions.myAccount.requestEmailUpdate]: "Update My Email",
    [adminPermissions.myAccount.verifyMyEmail]: "Verify My Email",
    [adminPermissions.myAccount.deleteMyAccount]: "Delete My Account",
    [adminPermissions.myCompany.read]: "View Company Infos",
    [adminPermissions.myCompany.updateInfos]: "Update Company Infos",
    [adminPermissions.tunnel.readStatus]: "Tunnel Status",
    [adminPermissions.tunnel.download]: "Download Tunnel",
    [adminPermissions.lads.readConfig]: "View Lads Config",
    [adminPermissions.lads.readPostEventUUID]: "View LADS Post Event UUID",
    [adminPermissions.lads.writeRegistryKeyValue]: "Update LADS Registry",
    [adminPermissions.lads.testConnection]: "Test LADS Connection",
    [adminPermissions.lads.restartBiThread]: "Restart BI Thread",
    [adminPermissions.lads.resetPostEventUUID]: "Change Post Event UUID",
    [adminPermissions.lads.uploadFile]: "Upload Files to LADS",
    [adminPermissions.lads.downloadFile]: "Download Files From LADS",
    [adminPermissions.lads.readDirectory]: "View LADS Files",
    [adminPermissions.lads.readRegistryKeyValue]: "View LADS Registry",
    [adminPermissions.badge.read]: "View Badges",
    [adminPermissions.badge.create]: "Create Badge",
    [adminPermissions.badge.delete]: "Delete Badge",
    [adminPermissions.badge.update]: "Update Badge",
    [adminPermissions.tunnel.createSecondary]: "Create Secondary Tunnel"
}

export default permissionsDocs;