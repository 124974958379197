import React,
{
    useState,
    useEffect
} from 'react';
import { styled } from '@mui/system';
import { spacing } from '@mui/system';
import { NavLink } from 'react-router-dom';
import {
    Link,
    Grid2,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography,
    Box,
    Tabs,
    Tab,
} from '@mui/material';
import DevicesNames from './DevicesNames';
import Groups from './groups';
import Settings from './settings';
import Badges from './badges';
import Authorized from 'components/Authorized';
import useAuthorization from 'hooks/useAuthorization';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing)


function TabPanel(props) {
    const { children, tab, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={tab !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {tab === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function Devices() {
    const [tab, setTab] = useState(0);
    const [selectedSetting, setSelectedSetting] = useState(null);
    const { isAuthorized } = useAuthorization();
    const handleSelectSetting = (settingID) => {
        setSelectedSetting(settingID);
        setTab(1);
    };
    useEffect(() => {
        if (tab !== 1) setSelectedSetting(null);
    }, [tab])
    return (
        <React.Fragment>
            <Typography variant="h3" gutterBottom display="inline">
                Devices
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" sx={{ mt: 2 }}>
                <Link component={NavLink} to="/">
                    Settings
                </Link>
                <Typography>Devices</Typography>
            </Breadcrumbs>
            <Divider sx={{ my: 6 }} />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={tab}
                    onChange={(e, tab) => setTab(tab)}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="Group"
                >
                    {isAuthorized("group.read") && <Tab label="Groups" />}
                    {isAuthorized("setting.read") && <Tab label="Settings" />}
                    {isAuthorized("device.read") && <Tab label="Names" />}
                    {isAuthorized("badge.read") && <Tab label="Badges" />}
                </Tabs>
            </Box>
            <TabPanel tab={tab} index={0}>
                <Grid2 container spacing={12}>
                    <Grid2 size={12}>
                        <Authorized permission="group.read">
                            <Groups openSettingsTab={() => setTab(1)} handleSelectSetting={handleSelectSetting} />
                        </Authorized>
                    </Grid2>
                </Grid2>
            </TabPanel>
            <TabPanel tab={tab} index={1}>
                <Grid2 container spacing={12}>
                    <Grid2 size={12}>
                        <Authorized permission="setting.read">
                            <Settings selectedSetting={selectedSetting} setSelectedSetting={setSelectedSetting} />
                        </Authorized>
                    </Grid2>
                </Grid2>
            </TabPanel>
            <TabPanel tab={tab} index={2}>
                <Grid2 container spacing={12}>
                    <Grid2 size={12}>
                        <Authorized permission="device.read">
                            <DevicesNames />
                        </Authorized>
                    </Grid2>
                </Grid2>
            </TabPanel>
            <TabPanel tab={tab} index={3}>
                <Grid2 container spacing={12}>
                    <Grid2 size={12}>
                        <Authorized permission="badge.read">
                            <Badges />
                        </Authorized>
                    </Grid2>
                </Grid2>
            </TabPanel>
        </React.Fragment>
    );
}

export default Devices;