import React, { useState, useRef, useEffect } from 'react'
import { styled } from '@mui/system';
import { imgURL } from 'utils/http'
import { toast } from 'react-toastify'
import {
    Avatar,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    Grid2,
    TextField as MuiTextField,
    Typography,
    CardHeader,
    Box
} from '@mui/material';
import { CloudUpload as MuiCloudUpload } from '@mui/icons-material';
import { spacing } from '@mui/system';
import Authorized from 'components/Authorized';
import useAuth from 'hooks/useAuth';
import useAuthorization from 'hooks/useAuthorization';
import MuiPhoneNumber from 'mui-phone-number';

const VALID_PHONE_REGEX = /^\+{0,1}([0-9\s\-()]){5,20}$/;

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
const CloudUpload = styled(MuiCloudUpload)(spacing);
const CenteredContent = styled(Box)`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 100%;
`;

const BigAvatar = styled(Avatar)`
   width: 120px;
   height: 120px;
   margin: 0 auto ${(props) => props.theme.spacing(2)};
   outline:1px solid #00000022;
`;

const isValidPassword = (password) => {
    if (!password) return false;
    if (password.length < 8) return false;
    if (!/[a-z]/.test(password)) return false;
    if (!/[A-Z]/.test(password)) return false;
    if (!/[0-9]/.test(password)) return false;
    if (!/[^A-Za-z0-9]/.test(password)) return false;
    return true;
}
const getPasswordValidationHelperText = (password) => {
    if (!password) return 'Invalid password'
    if (password.length < 8) return 'Password too short (8 chars min).';
    if (!/[a-z]/.test(password)) return 'Password must include a Lowercase letter'
    if (!/[A-Z]/.test(password)) return 'Password must include a Capital letter'
    if (!/[0-9]/.test(password)) return 'Password must include a number';
    if (!/[^A-Za-z0-9]/.test(password)) return 'Password must include a Special character';
    return "";
}
function MyAccount() {
    const fileRef = useRef()
    const {
        userID,
        displayName,
        firstName,
        lastName,
        designation,
        phone,
        address,
        imageUrl,
        updateUser
    } = useAuth();
    const { isAuthorized } = useAuthorization();
    const canUpdateAccount = isAuthorized('myAccount.write');
    const [fileToUpload, setFileToUpload] = useState(null);
    const [saveFormEnabled, setSaveFormEnabled] = useState(false);
    const initialImageUrlState = imageUrl ? `${imgURL}${imageUrl}` : `${imgURL}/images/default-user.png`;
    const [userImageUrl, setUserImageUrl] = useState(initialImageUrlState);
    const [userFirstName, setUserFirstName] = useState(firstName || "");
    const [userLastName, setUserLastName] = useState(lastName || "");
    const [userDesignation, setUserDesignation] = useState(designation || "");
    const [userPhone, setUserPhone] = useState(phone || "");
    const [userAddress, setUserAddress] = useState(address || "");
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    function openFilePicker() {
        if (fileRef && fileRef.current) fileRef.current.click()
    }

    function previewFile(e) {
        const file = e.target.files[0]
        const oneMb = 1000000
        const maxSizeInMb = 5
        const maxSize = maxSizeInMb * oneMb;
        console.log("preview file running", e.target.files[0])
        // [start] file validations
        if (!file) {
            removeFile()
            return
        }
        if (file.size > maxSize) {
            return toast.error(`Max file size is ${maxSizeInMb}mb`)
        }
        // [end] file validations

        setFileToUpload(file)

        if (file.type.startsWith("image")) {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = function () {
                setUserImageUrl(reader.result)
            }
        } else {
            // some other document file...
        }
    }

    function removeFile() {
        setFileToUpload(null)
        setUserImageUrl(initialImageUrlState)
    }
    async function handleDataUpdate() {
        const dataToUpdate = {}
        if (userFirstName && firstName !== userFirstName) dataToUpdate.firstName = userFirstName;
        if (userLastName && lastName !== userLastName) dataToUpdate.lastName = userLastName;
        if (userDesignation && designation !== userDesignation) dataToUpdate.designation = userDesignation;
        if (userPhone && phone !== userPhone) dataToUpdate.phone = userPhone;
        if (userAddress && address !== userAddress) dataToUpdate.address = userAddress;
        if (oldPassword && newPassword1) {
            dataToUpdate.oldPassword = oldPassword;
            dataToUpdate.newPassword = newPassword1;
        }
        if (fileToUpload) dataToUpdate.fileToUpload = fileToUpload;
        try {
            const results = await updateUser(dataToUpdate);
            if (results && results.message) toast.success(results.message);
        } catch (error) {
            if (error && error.message) toast.error(error.message);
            else toast.error("Something went wrong");
        }

    }

    useEffect(() => {
        setUserFirstName(firstName || "");
        setUserLastName(lastName || "");
        setUserDesignation(designation || "");
        if (imageUrl) setUserImageUrl(`${imgURL}${imageUrl}`);
    }, [firstName, lastName, designation, imageUrl]);
    useEffect(() => {
        if (userID &&
            (
                (!oldPassword &&
                    (fileToUpload ||
                        userFirstName !== firstName ||
                        userLastName !== lastName ||
                        userDesignation !== designation ||
                        userAddress !== address ||
                        userPhone !== phone
                    )) ||
                (
                    oldPassword &&
                    isValidPassword(oldPassword) &&
                    isValidPassword(newPassword1) &&
                    newPassword1 === newPassword2
                )
            )
        ) {
            setSaveFormEnabled(true);
        }
        else setSaveFormEnabled(false);
    }, [
        userFirstName,
        userLastName,
        userDesignation,
        userAddress,
        userPhone,
        fileToUpload,
        oldPassword,
        newPassword1,
        newPassword2,
        address,
        designation,
        firstName,
        lastName,
        phone,
        userID
    ])

    return (
        <Card sx={{ mb: 6 }}>
            <CardHeader title={<Typography variant="h6">
                Informations
            </Typography>
            } />
            <CardContent>
                <Grid2 container spacing={6}>
                    <Grid2 size={{ xs: 12, md: 6 }} order={{ xs: 2, md: 1 }}>
                        <TextField
                            label="First Name"
                            value={userFirstName}
                            onChange={(e) => setUserFirstName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            sx={{ my: 2 }}
                            disabled={!canUpdateAccount}
                        />
                        <TextField
                            label="Last Name"
                            value={userLastName}
                            onChange={(e) => setUserLastName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            sx={{ my: 2 }}
                            disabled={!canUpdateAccount}
                        />
                        <TextField
                            label="Address"
                            value={userAddress}
                            onChange={(e) => setUserAddress(e.target.value)}
                            variant="outlined"
                            fullWidth
                            sx={{ my: 2 }}
                            disabled={!canUpdateAccount}
                        />
                        <MuiPhoneNumber
                            defaultCountry='us'
                            label="Company Phone"
                            value={userPhone}
                            variant='outlined'
                            error={userPhone && !VALID_PHONE_REGEX.test(userPhone)}
                            fullWidth
                            helperText={userPhone && !VALID_PHONE_REGEX.test(userPhone) ? "invalid phone number" : ""}
                            onChange={(value) => setUserPhone(value)}
                            disabled={!canUpdateAccount}
                        />
                        <TextField
                            label="Designation"
                            value={userDesignation}
                            onChange={(e) => setUserDesignation(e.target.value)}
                            variant="outlined"
                            fullWidth
                            sx={{ my: 2 }}
                            disabled={!canUpdateAccount}
                        />
                        <TextField
                            error={!!oldPassword && !isValidPassword(oldPassword)}
                            label={oldPassword.length ? "Old Password" : "Change password"}
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            variant="outlined"
                            type="password"
                            fullWidth
                            sx={{ my: 2 }}
                            helperText={oldPassword && !isValidPassword(oldPassword) ? getPasswordValidationHelperText(oldPassword) : ""}
                            disabled={!canUpdateAccount}
                        />
                        {isValidPassword(oldPassword) && (
                            <React.Fragment>
                                <TextField
                                    error={!!newPassword1 && !isValidPassword(newPassword1)}
                                    label="New password"
                                    value={newPassword1}
                                    onChange={(e) => setNewPassword1(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    type="password"
                                    sx={{ my: 2 }}
                                    helperText={newPassword1 && !isValidPassword(newPassword1) ? getPasswordValidationHelperText(newPassword1) : ""}
                                />
                                <TextField
                                    error={!!newPassword1 && newPassword1 !== newPassword2}
                                    label="Confirm password"
                                    value={newPassword2}
                                    onChange={(e) => setNewPassword2(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    type="password"
                                    sx={{ my: 2 }}
                                    helperText={newPassword1 && newPassword1 !== newPassword2 ? "Passwords does not match" : ""}
                                />
                            </React.Fragment>
                        )}
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }} order={{ xs: 1, md: 2 }}>
                        <CenteredContent>
                            <BigAvatar
                                alt={displayName || "User"}
                                src={userImageUrl}
                            />
                            <Authorized permission="myAccount.write">
                                <input
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id="raised-button-file"
                                    multiple
                                    type="file"
                                    onChange={previewFile}
                                    ref={fileRef}
                                />
                                <Button
                                    onClick={openFilePicker}
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    disabled={!canUpdateAccount}
                                >
                                    <CloudUpload sx={{ mr: 2 }} /> Upload
                                </Button>

                                <Typography variant="caption" align="center" display="block" gutterBottom sx={{ px: 3 }}>
                                    For best results, use an image at least 128px by 128px in .jpg
                                    format
                                </Typography>
                            </Authorized>
                        </CenteredContent>
                    </Grid2>
                </Grid2>
                <Authorized permission="myAccount.write">
                    <Grid2 container spacing={6}>
                        <Grid2 size={12}>
                            <Button variant="contained" color="primary" onClick={handleDataUpdate} disabled={!saveFormEnabled}>
                                Save changes
                            </Button>
                        </Grid2>
                    </Grid2>
                </Authorized>
            </CardContent>
        </Card>
    );
}
export default MyAccount;