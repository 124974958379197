import React from "react";

const PREFERENCES = "prefs";

const initialState = {
  preferences: {},
  updatePreferences() { },
  clearPreferences() { }
};
const PreferencesContext = React.createContext(initialState);
const initializeState = () => {
  const storedPreferences = localStorage.getItem(PREFERENCES);
  let preferences = null;
  try {
    preferences = JSON.parse(storedPreferences);
    if (!preferences || !Object.keys(preferences).length) throw new Error("invalid parsed JSON");
  } catch (error) {
    preferences = null;
  }
  return preferences ? preferences : {
    "pages-dashboards-Default-DeviceList": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          _id: false,
          ipaddr: false,
          androidID: false,
          createdAt: false,
          license_process: false,
          license_subscription_until: false,
          version_sdk: false,
          version_security_patch: false,
          id: false,
        },
      },
      sorting: {
        sortModel: [
          {
            field: "date",
            sort: "desc"
          },
        ]
      }
    },
    "pages-deviceList-DeviceList": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          _id: false,
          ipaddr: false,
          license_subscription_until: false,
          id: false
        },
      },
      sorting: {
        sortModel: [
          {
            field: "date",
            sort: "desc"
          }
        ]
      }
    },
    "pages-logs-Alertlog": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          id: false
        },
      },
      sorting: {
        sortModel: [
          {
            field: "date",
            sort: "desc"
          }
        ]
      }
    },
    "pages-logs-EventLogPagination": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          messageID: false,
          isConnected: false,
          id: false
        },
      }

    },
    "pages-logs-Scanlog": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          androidID: false,
          id: false
        },
      },
      sorting: {
        sortModel: [
          {
            field: "date",
            sort: "desc"
          }
        ]
      }
    },
    "pages-logs-WiFiDropLog-WifiDropLogTable": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          messageID: false,
          androidID: false,
          id: false
        },
      },
      sorting: {
        sortModel: [
          {
            field: "date",
            sort: "desc"
          }
        ]
      }
    },
    "pages-dashboards-uts-utslogtable": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          timeStamp: false
        },
      },
      sorting: {
        sortModel: [
          {
            field: "date",
            sort: "desc"
          }
        ]
      }
    },
    "pages-dashboards-utsevents2": {
      density: "compact",
      sorting: {
        sortModel: [
          {
            field: "date",
            sort: "desc"
          }
        ]
      }
    },
    "pages-logs-eventlogbydate": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          messageID: false,
          isConnected: false,
          id: false
        },
      },
      sorting: {
        sortModel: [
          {
            field: "date",
            sort: "desc"
          }
        ]
      }
    },
    "pages-support-notifications-notificationlist": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          _id: false,
          date: false,
          closable: false,
          href: false,
          hrefText: false,
          priority: false,
          hideDate: false,
          scope: false,
          details: false,
        },
      },
      sorting: {
        sortModel: [
          {
            field: "date",
            sort: "desc"
          }
        ]
      }
    },
    "pages-support-notifications-emaillist": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          _id: false,
          from: false,
          messageId: false,
          bounced: false
        },
      },
      sorting: {
        sortModel: [
          {
            field: "date",
            sort: "desc"
          }
        ]
      }
    },
    "pages-dashboards-default-userslist": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          _id: false,
          badgeID: false,
          createdAt: false,
          updatedAt: false
        },
      },
      sorting: {
        sortModel: [
          {
            field: "updatedAt",
            sort: "desc"
          }
        ]
      }
    },
    "pages-dashboards-uts-agent-log-events": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          agent: false,
          createdAt: false,
        },
      },
      sorting: {
        sortModel: [
          {
            field: "date",
            sort: "desc"
          }
        ]
      }
    },
    "pages-logs-EventLogNoPagination": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          messageID: false,
          isConnected: false,
          id: false
        },
      }
    },
    "pages-settings-devices-in-group-table": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          _id: false,
          ipaddr: false,
          license_subscription_until: false,
          id: false
        },
      }
    },
    "pages-settings-devices-settings-alerts": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          property: false,
          messageID: false
        },
      }
    },
    "pages-support-admins-adminlist-d1": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          _id: false,
          firstName: false,
          lastName: false,
          phone: false,
          address: false,
          designation: false,
          role: false,
          sessionDuration: false,
          notificationOnNewAlert: false,
          notificationOnNewChatMessage: false,
          notificationOnNewAdminAdded: false,
          notificationWhileConnected: false
        },
      }
    },
    "pages-support-admins-companylist-d1": {
      density: "compact",
      columns: {
        columnVisibilityModel: {
          _id: false,
          uuid: false,
          databaseName: false,
          socketNamespace: false,
          postEventsUUID: false,
          compAddr: false,
          compZip: false,
          compPhone: false,
          mainTunnelPort: false,
          deviceGroupTunnelPorts: false,
          ignoreScanHeaders: false,
          ignoreScanContent: false,
          stripe_customer_id: false,
          stripe_subscription_id: false,
          stripe_subscription_start_date: false,
          stripe_subscription_end_date: false,
          stripe_payment_status: false,
          stripe_latest_invoice_status: false,
          stripe: false,
          stripe_subscription_status: false,
          stripe_subscription_plan_name: false,
          stripe_product_id: false,
          stripe_latest_invoice_pdf: false,
          stripe_last_report_usage_value: false,
        },
      }
    }
  };
};

function PreferencesProvider({ children }) {
  const preferencesRef = React.useRef(initializeState());
  const debounceUpdateTimer = React.useRef(null);
  const debouncedUpdateState = (oldState, newState) => {
    if (debounceUpdateTimer.current) clearTimeout(debounceUpdateTimer.current);
    debounceUpdateTimer.current = setTimeout(() => {
      const _old = JSON.stringify(oldState);
      const _new = JSON.stringify(newState);
      if (_old !== _new) {
        setPreferences(newState);
      }
    }, 5000)
  };

  const highPrioritydUpdateState = (oldState, newState) => {
    if (debounceUpdateTimer.current) clearTimeout(debounceUpdateTimer.current);
    const _old = JSON.stringify(oldState);
    const _new = JSON.stringify(newState);
    if (_old !== _new) {
      setPreferences(newState);
    }
  };

  const [preferences, setPreferences] = React.useState(preferencesRef.current);
  const updatePreferences = (preferencesID, itemPreferences, isHighPriority = false) => {
    if (!preferencesID || !itemPreferences || typeof itemPreferences !== "object") return;
    const _oldPrefrences = preferences[preferencesID] || {};
    const updatedPreferences = {
      ...preferencesRef.current,
      [preferencesID]: { ..._oldPrefrences, ...itemPreferences },
    };
    try {
      localStorage.setItem(PREFERENCES, JSON.stringify(updatedPreferences));
    } catch (error) {
      console.error("can't stringify object", error, updatedPreferences);
    }

    preferencesRef.current = updatedPreferences;
    const updateStateFun = isHighPriority ? highPrioritydUpdateState : debouncedUpdateState;
    updateStateFun(preferences, preferencesRef.current);
  };
  const clearPreferences = () => {
    clearTimeout(debounceUpdateTimer.current)
    localStorage.removeItem(PREFERENCES);
    preferencesRef.current = initializeState();
    setPreferences(initializeState());
  }
  
  return (
    <PreferencesContext.Provider value={{
      preferences,
      updatePreferences,
      clearPreferences
    }}>
      {children}
    </PreferencesContext.Provider>
  );
}

export { PreferencesProvider, PreferencesContext };

