import ArticlesActionTypes from "./articles.types"

const updateArticlesAction = (articles) => {
    return { type: ArticlesActionTypes.UPDATE_ALL_ARTICLES, payload: articles }
}
const updateArticleAction = (article) => {
    return { type: ArticlesActionTypes.UPDATE_ARTICLE, payload: article }
}

export {
    updateArticlesAction,
    updateArticleAction
}
