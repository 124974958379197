import { useMemo, useRef, useEffect } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Switch,
    FormControlLabel,
    Grid2,
    Badge,
    Tooltip,
    IconButton,
    FormGroup
} from '@mui/material';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from "react-router-dom";

const PageAdvanced = ({ id, name, href, pageFullAccessRequiredPermissions, updateRolePermissions, readOnly, permissionsMap, permissionsDocs, setPageHeight }) => {
    const ref = useRef()
    const { nPermissions, nTotalPermissions } = useMemo(() => {
        return {
            nTotalPermissions: pageFullAccessRequiredPermissions.length,
            nPermissions: pageFullAccessRequiredPermissions.reduce((p, c) => p + (permissionsMap[c] ? 1 : 0), 0),
        }
    }, [pageFullAccessRequiredPermissions, permissionsMap]);
    useEffect(() => {
        if (ref.current?.clientHeight) {
            setPageHeight(id, ref.current?.clientHeight);
        }
    }, [id, setPageHeight])
    return <Card ref={ref}>
        <CardContent>
            <Grid2 container spacing={1} alignItems="center" justifyContent="space-between">
                <Grid2>
                    <Tooltip title="Open">
                        <Link
                            to={`${href || '/'}`}
                            target="_blank"
                            style={{ textDecoration: "none" }}
                        >
                            <IconButton color="primary">
                                <OpenInNewIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </Grid2>
                <Grid2 flexGrow={1}>
                    <Typography gutterBottom variant="body1">
                        Page:<strong> {name}</strong>
                    </Typography>
                </Grid2>
                <Grid2>
                    <Tooltip title={`Page has ${nPermissions} of ${nTotalPermissions} for full access`}>
                        <Badge badgeContent={`${nPermissions}/${nTotalPermissions}`} showZero color="secondary">
                            <AddModeratorIcon color="primary" />
                        </Badge>
                    </Tooltip>
                </Grid2>
                <Grid2 size={12} />
                <FormGroup>
                    {pageFullAccessRequiredPermissions.map(permission => <FormControlLabel
                        key={permission}
                        size="small"
                        control={<Switch
                            size="small"
                            disabled={readOnly}
                            checked={!!permissionsMap[permission]}
                            onChange={(e) => updateRolePermissions(permission, e.target.checked)}
                        />}
                        label={permissionsDocs[permission] || permission || ''}
                    />)}
                </FormGroup>
            </Grid2>
        </CardContent>
    </Card>
}
export default PageAdvanced;