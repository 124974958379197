import React, {
    useState,
    useEffect,
    useMemo
} from "react"
import { styled } from '@mui/system';
import { toast } from "react-toastify"
import {
    Button,
    Card,
    CardContent,
    Grid2,
    TextField,
    Typography,
    Checkbox,
    Box
} from "@mui/material";
import { useDispatch } from 'react-redux';
import { updateRttAPsAction } from 'redux/rtt/rtt.actions';
import { http } from "utils/http";
import { updateSettingAction } from 'redux/settings/settings.actions';


const LimitItem = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Limit = ({ label, val, setVal, enabled, setEnabled, readOnly }) => {
    return <LimitItem>
        <Checkbox
            checked={enabled}
            onChange={e => setEnabled(e.target.checked)}
            disabled={readOnly}
        />
        <TextField
            type="number"
            label={label}
            value={Number.isInteger(parseInt(val)) ? val : ''}
            onChange={(e) => setVal(e.target.value)}
            variant="outlined"
            size="small"
            fullWidth
            sx={{ my: 2 }}
            disabled={!enabled || readOnly}
        />
    </LimitItem>
}


const BoundariesConfig = ({ rttSettings, readOnly }) => {
    const dispatch = useDispatch();
    const [xMin, setXMin] = useState(rttSettings.rttLimitsXMin || '');
    const [xMinEnabled, setXMinEnabled] = useState(rttSettings.rttLimitsXMinEnabled || false);
    const [xMax, setXMax] = useState(rttSettings.rttLimitsXMax || '');
    const [xMaxEnabled, setXMaxEnabled] = useState(rttSettings.rttLimitsXMaxEnabled || false);
    const [yMin, setYMin] = useState(rttSettings.rttLimitsYMin || '');
    const [yMinEnabled, setYMinEnabled] = useState(rttSettings.rttLimitsYMinEnabled || false);
    const [yMax, setYMax] = useState(rttSettings.rttLimitsYMax || '');
    const [yMaxEnabled, setYMaxEnabled] = useState(rttSettings.rttLimitsYMaxEnabled || false);
    const [zMin, setZMin] = useState(rttSettings.rttLimitsZMin || '');
    const [zMinEnabled, setZMinEnabled] = useState(rttSettings.rttLimitsZMinEnabled || false);
    const [zMax, setZMax] = useState(rttSettings.rttLimitsZMax || '');
    const [zMaxEnabled, setZMaxEnabled] = useState(rttSettings.rttLimitsZMaxEnabled || false);
    useEffect(() => {
        setXMin(rttSettings.rttLimitsXMin || '');
        setXMinEnabled(rttSettings.rttLimitsXMinEnabled || false);
        setXMax(rttSettings.rttLimitsXMax || '');
        setXMaxEnabled(rttSettings.rttLimitsXMaxEnabled || false);
        setYMin(rttSettings.rttLimitsYMin || '');
        setYMinEnabled(rttSettings.rttLimitsYMinEnabled || false);
        setYMax(rttSettings.rttLimitsYMax || '');
        setYMaxEnabled(rttSettings.rttLimitsYMaxEnabled || false);
        setZMin(rttSettings.rttLimitsZMin || '');
        setZMinEnabled(rttSettings.rttLimitsZMinEnabled || false);
        setZMax(rttSettings.rttLimitsZMax || '');
        setZMaxEnabled(rttSettings.rttLimitsZMaxEnabled || false);
    }, [rttSettings]);

    const isFormValid = useMemo(() => {
        if (
            rttSettings.rttLimitsXMin === xMin &&
            rttSettings.rttLimitsXMinEnabled === xMinEnabled &&
            rttSettings.rttLimitsXMax === xMax &&
            rttSettings.rttLimitsXMaxEnabled === xMaxEnabled &&
            rttSettings.rttLimitsYMin === yMin &&
            rttSettings.rttLimitsYMinEnabled === yMinEnabled &&
            rttSettings.rttLimitsYMax === yMax &&
            rttSettings.rttLimitsYMaxEnabled === yMaxEnabled &&
            rttSettings.rttLimitsZMin === zMin &&
            rttSettings.rttLimitsZMinEnabled === zMinEnabled &&
            rttSettings.rttLimitsZMax === zMax &&
            rttSettings.rttLimitsZMaxEnabled === zMaxEnabled
        ) return false;
        if (
            (Number.isFinite(parseInt(xMin)) && Number.isFinite(parseInt(xMax)) && parseInt(xMax) <= parseInt(xMin)) ||
            (Number.isFinite(parseInt(yMin)) && Number.isFinite(parseInt(yMax)) && parseInt(yMax) <= parseInt(yMin)) ||
            (Number.isFinite(parseInt(zMin)) && Number.isFinite(parseInt(zMax)) && parseInt(zMax) <= parseInt(zMin))
        ) return false;
        if (
            (!Number.isFinite(parseInt(xMin)) && xMinEnabled) ||
            (!Number.isFinite(parseInt(xMax)) && xMaxEnabled) ||
            (!Number.isFinite(parseInt(yMin)) && yMinEnabled) ||
            (!Number.isFinite(parseInt(yMax)) && yMaxEnabled) ||
            (!Number.isFinite(parseInt(zMin)) && zMinEnabled) ||
            (!Number.isFinite(parseInt(zMax)) && zMaxEnabled)
        ) return false;

        return true;
    }, [rttSettings, xMin, xMax, yMin, yMax, zMin, zMax, xMinEnabled, xMaxEnabled, yMinEnabled, yMaxEnabled, zMinEnabled, zMaxEnabled])

    const handleUpdateLimits = async () => {
        try {
            const updates = {};
            if (Number.isFinite(parseInt(xMin))) updates['rttLimitsXMin'] = Math.round(xMin);
            updates['rttLimitsXMinEnabled'] = !!xMinEnabled;
            if (Number.isFinite(parseInt(xMax))) updates['rttLimitsXMax'] = Math.round(xMax);
            updates['rttLimitsXMaxEnabled'] = !!xMaxEnabled;
            if (Number.isFinite(parseInt(yMin))) updates['rttLimitsYMin'] = Math.round(yMin);
            updates['rttLimitsYMinEnabled'] = !!yMinEnabled;
            if (Number.isFinite(parseInt(yMax))) updates['rttLimitsYMax'] = Math.round(yMax);
            updates['rttLimitsYMaxEnabled'] = !!yMaxEnabled;
            if (Number.isFinite(parseInt(zMin))) updates['rttLimitsZMin'] = Math.round(zMin);
            updates['rttLimitsZMinEnabled'] = !!zMinEnabled;
            if (Number.isFinite(parseInt(zMax))) updates['rttLimitsZMax'] = Math.round(zMax);
            updates['rttLimitsZMaxEnabled'] = !!zMaxEnabled;
            const resp = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/setting/setting`,
                {
                    settingID: rttSettings._id,
                    ...updates
                }
            );
            console.log("resp.data", resp.data);
            dispatch(updateSettingAction(resp.data));
            if (
                resp.data.rttAccessPoints &&
                Array.isArray(resp.data.rttAccessPoints)
            ) {
                dispatch(updateRttAPsAction(resp.data.rttAccessPoints));
            }
            toast.success("rtt settings updated");
        } catch (error) {
            console.log("error", error);
            if (error &&
                error.response &&
                error.response.data &&
                error.response.data.message) {
                toast.error(error.response.data.message)
            }
            else toast.error("Could not update Limits");
        }
    }

    return <Card>
        <CardContent>
            <Grid2 container spacing={3}>
                <Grid2 size={12}>
                    <Typography variant="h6" gutterBottom>
                        Boundaries (locations that are not possible ex. building edges)
                    </Typography>
                </Grid2>
                <Grid2 size={12} spacing={2} container columns={{ sm: 12, lg: 14 }} alignItems="center">
                    <Grid2 size={{ xs: 12, sm: 6, lg: 2 }}>
                        <Limit label={"X Min"} val={xMin} setVal={setXMin} enabled={xMinEnabled} setEnabled={setXMinEnabled} readOnly={readOnly} />
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 6, lg: 2 }}>
                        <Limit label={"X Max"} val={xMax} setVal={setXMax} enabled={xMaxEnabled} setEnabled={setXMaxEnabled} readOnly={readOnly} />
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 6, lg: 2 }}>
                        <Limit label={"Y Min"} val={yMin} setVal={setYMin} enabled={yMinEnabled} setEnabled={setYMinEnabled} readOnly={readOnly} />
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 6, lg: 2 }}>
                        <Limit label={"Y Max"} val={yMax} setVal={setYMax} enabled={yMaxEnabled} setEnabled={setYMaxEnabled} readOnly={readOnly} />
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 6, lg: 2 }}>
                        <Limit label={"Z Min"} val={zMin} setVal={setZMin} enabled={zMinEnabled} setEnabled={setZMinEnabled} readOnly={readOnly} />
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 6, lg: 2 }}>
                        <Limit label={"Z Max"} val={zMax} setVal={setZMax} enabled={zMaxEnabled} setEnabled={setZMaxEnabled} readOnly={readOnly} />
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 12, lg: 2 }}>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            fullWidth
                            disabled={!isFormValid || readOnly}
                            onClick={handleUpdateLimits}>
                            Save changes
                        </Button>
                    </Grid2>
                </Grid2>
            </Grid2>
        </CardContent>
    </Card>
}
export default BoundariesConfig;