import { useState, useMemo } from 'react';
import {
    Box,
    TextField,
    IconButton,
    Typography
} from '@mui/material';
import { styled } from '@mui/system';
import { rgba } from 'polished';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import WarningIcon from '@mui/icons-material/Warning';
import USER_TYPE from 'constants/userType';
import Participant from "components/Chat/Participant";

const UserType = ({ type }) => {
    switch (type) {
        case USER_TYPE.ADMIN:
            return <PersonIcon color="primary" size="small" />
        case USER_TYPE.DEVICE:
            return <PhoneAndroidIcon color="primary" size="small" />
        case USER_TYPE.SUPPORT:
            return <AdminPanelSettingsIcon color="primary" size="small" />
        default:
            return <WarningIcon color="warning" size="small" />
    }

}

const Wrapper = styled(Box)`
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color ${(props) => props.theme.palette.background.paper};
`
const UsersWrapper = styled(Box)`
    overflow: auto;
    height: 100%;
    padding: 0 10px;
    ${({ theme }) => theme.breakpoints.down('md')} {
        padding: 0 0px;
    }
    
`

const UserWrapper = styled(Box)`
    border: 1px solid ${(props) => rgba(props.theme.palette.text.secondary, 0.2)};
    margin: 2px;
    padding: 2px;
    ${({ theme }) => theme.breakpoints.down('md')} {
        margin: 0px;
        padding: 0px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
`;

const ParticipantWrapper = styled(Box)`
    flex-grow: 1;
    min-width: 0;
`

const ChatUsers = ({ users, addUser, removeUser, title, canAddAdmin, canAddDevice, canAddSupport }) => {
    const [searchStr, setSearchStr] = useState("")
    const filtredUsers = useMemo(() => {
        const str = searchStr.trim().toLocaleLowerCase();
        if (searchStr.length > 0) {
            return users
                .filter(user => user.displayName?.toLocaleLowerCase().includes(str) || user._id?.toLocaleLowerCase().includes(str))
        }
        return users;
    }, [searchStr, users]);

    return <Wrapper p={{ xs: 0, md: 1 }}>
        {title && <Typography variant="h6" sx={{ mb: 2 }}>{title}</Typography>}
        <TextField
            label="Search contacts..."
            variant="outlined"
            size="small"
            fullWidth
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value)}
            sx={{ my: 1 }}
        />
        <UsersWrapper>
            {filtredUsers.map(user => <UserWrapper key={user._id}>
                <ParticipantWrapper>
                    <Participant
                        {...user}
                        showBadge={false}
                    />
                </ParticipantWrapper>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <UserType type={user.type} />
                </Box>
                {addUser && <IconButton
                    color="primary"
                    size="small"
                    onClick={() => addUser(user._id)}
                    disabled={
                        (user.type === USER_TYPE.SUPPORT && !canAddSupport) ||
                        (user.type === USER_TYPE.ADMIN && !canAddAdmin) ||
                        (user.type === USER_TYPE.DEVICE && !canAddDevice)
                    }
                >
                    <AddCircleIcon />
                </IconButton>}
                {removeUser && <IconButton
                    color="warning"
                    size="small"
                    onClick={() => removeUser(user._id)}>
                    <RemoveCircleIcon />
                </IconButton>}
            </UserWrapper>)}
        </UsersWrapper>
    </Wrapper>
}

export default ChatUsers;