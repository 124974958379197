import React, { useState } from 'react';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import {
  Badge,
  Grid2,
  Avatar,
  Typography,
  Divider as MuiDivider,
  Box
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useAuth from 'hooks/useAuth';
import { imgURL } from 'utils/http'
import { spacing } from '@mui/system';
import { Link } from 'react-router-dom';
import Authorized from 'components/Authorized';
import useAuthorization from 'hooks/useAuthorization';

const Divider = styled(MuiDivider)(spacing);
const Footer = styled(Box)`
  background-color: ${(props) => props.theme.sidebar.footer.background}!important;
  padding: ${(props) => props.theme.spacing(2.75)} ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) => props.color === 'primary' ?
    props.theme.sidebar.footer.online.background :
    props.theme.sidebar.footer.offline.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter = () => {
  const { isAuthorized } = useAuthorization();
  const canViewProfilePage = isAuthorized("page.myAccount");
  const { userID, compName, compAddr, compPhone, compZip, displayName, imageUrl, designation } = useAuth();
  const isConnected = useSelector((state) => state.chat.isConnected);
  const [showMore, setShowMore] = useState(false);
  return (
    <Footer>
      {userID && showMore &&
        <>
          <Grid2 container sx={{ my: 1 }}>
            <Grid2 size={1} />
            <Authorized permission="myCompany.read">
              <Grid2 size={11}>
                {compName && <FooterText variant="body2" noWrap>{compName}</FooterText>}
                {compAddr && <FooterSubText variant="caption" noWrap>{compAddr}</FooterSubText>}
                {compZip && <FooterSubText variant="caption" noWrap>{compZip}</FooterSubText>}
                {compPhone && <FooterSubText variant="caption" noWrap>{compPhone}</FooterSubText>}
              </Grid2>
            </Authorized>
          </Grid2>
          <Divider sx={{ my: 2 }} />
        </>
      }
      <Grid2 container spacing={1} columns={24}>
        <Grid2 size={6}>
          <FooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
            color={isConnected ? 'primary' : 'secondary'}
          >
            {!!userID && (canViewProfilePage ? <Link to="/settings/account" style={{ textDecoration: 'none' }}><Avatar
              alt={displayName}
              src={imgURL + (imageUrl || '/images/default-user.png')}
            /></Link> : <Avatar
              alt={displayName}
              src={imgURL + (imageUrl || '/images/default-user.png')}
            />)}
          </FooterBadge>
        </Grid2>
        <Grid2 size={13}>
          {!!userID && (
            <>
              <FooterText variant="body2" noWrap>{displayName}</FooterText>
              <FooterSubText variant="caption">{designation}</FooterSubText>
            </>
          )}
        </Grid2>
        <Grid2 size={5}>
          {!!userID && (
            <Box sx={{ textAlign: 'center' }}>
              {showMore ?
                <ArrowDropDownIcon role="button" color="action" fontSize="large" onClick={() => setShowMore(false)} /> :
                <ArrowDropUpIcon role="button" color="action" fontSize="large" onClick={() => setShowMore(true)} />
              }
            </Box>
          )}
        </Grid2>
      </Grid2>
    </Footer>
  );
};

export default SidebarFooter;
