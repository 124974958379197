import { useEffect, useState } from 'react';
import { Grid2, CircularProgress } from '@mui/material'
import GeneralMapSettings from './GeneralMapSettings';
import AccessPointsConfig from './AccessPointsConfig';
import BoundariesConfig from './BoundariesConfig';
import useAuthorization from 'hooks/useAuthorization';
import WifiHeatmapSettings from './WifiHeatmapSettings';

const Settings = ({ rttSettings }) => {
    const [mountAccessPointsConfig, setMountAccessPointsConfig] = useState(false);
    const { isAuthorized } = useAuthorization();
    const canUpdateSettings = isAuthorized("setting.update");
    useEffect(() => {
        const timer = setTimeout(() => {
            setMountAccessPointsConfig(true);
        }, 200);
        return () => {
            clearTimeout(timer);
        }
    }, [])
    return <Grid2 container rowSpacing={6}>
        <Grid2 size={12}>
            <GeneralMapSettings rttSettings={rttSettings} readOnly={!canUpdateSettings} />
        </Grid2>
        <Grid2 size={12}>
            {mountAccessPointsConfig ? <AccessPointsConfig rttSettings={rttSettings} readOnly={!canUpdateSettings} /> : <CircularProgress color="primary" />}
        </Grid2>
        <Grid2 size={12}>
            <BoundariesConfig rttSettings={rttSettings} readOnly={!canUpdateSettings} />
        </Grid2>
        <Grid2 size={12}>
            <WifiHeatmapSettings rttSettings={rttSettings} readOnly={!canUpdateSettings} />
        </Grid2>
    </Grid2>
}
export default Settings;