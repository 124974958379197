import React, { useMemo, useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Grid2,
    Box,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import HtmlTooltip from 'components/HtmlTooltip';
import DeviceKeyboardKeyEvent from 'components/DeviceKeyboardKeyEvent';


const KeysContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    .key-enter {
        opacity: 0;
    }
    .key-enter-active {
        opacity: 1;
        transition: opacity 1s;
    }
    .key-exit {
        opacity: 1;
    }
    .key-exit-active {
        opacity: 0;
        transition: opacity 0.1s;
    }
`
const DeviceContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: start;
`
const KEY_SHOW_DURATION = 10 * 1000;

const DeviceInfos = ({ name, ipaddr }) => <HtmlTooltip
    title={<>
        <Grid2 container spacing={2}>
            <Grid2 size={12}>
                <Typography variant='h5'>{name}</Typography>
            </Grid2>
            {ipaddr && <Grid2 size={12}>
                <Typography variant='body1'>IP: {ipaddr}</Typography>
            </Grid2>}
        </Grid2>
    </>}
>
    <PhoneAndroidIcon color="primary" />
</HtmlTooltip>
const KeyboardEvents = ({ keyboardKeys, groupDevices }) => {
    const keyMap = useMemo(() => {
        return keyboardKeys.reduce((p, c) => ({ ...p, [`${c.code}`]: c }), {});
    }, [keyboardKeys]);
    const [timer, setTimer] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => setTimer(Date.now()), 1000);
        return () => {
            clearInterval(interval);
        }
    }, []);
    const events = useSelector((state) => state.events.keyboardEvents || []);
    const devices = useSelector((state) => state.devices || []);
    const keys = useMemo(() => {
        return events.filter(el => (new Date(el.date).getTime()) + KEY_SHOW_DURATION > Date.now()).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events, timer])
    const groupDevicesMap = useMemo(() => {
        return groupDevices.reduce((p, c) => ({ ...p, [c]: c }), {});
    }, [groupDevices]);
    const keysPerDevice = useMemo(() => {
        const deviceMap = {};
        keys.forEach(key => {
            if (!key) return;
            if (keyMap && keyMap[key.word] && keyMap[key.word].hide) return;
            if (!groupDevicesMap[key.androidID]) return;
            if (!deviceMap[key.androidID]) {
                const device = devices.find(el => el.androidID === key.androidID);
                const name = device && device.name ? device.name : key.androidID;
                deviceMap[key.androidID] = {
                    name,
                    ipaddr: device ? device.ipaddr : null,
                    keys: []
                }
            }
            deviceMap[key.androidID].keys.push(key);
        })
        return Object.values(deviceMap).map(el => ({ ...el, keys: el.keys.slice(-10) }));
    }, [keys, devices, groupDevicesMap, keyMap]);
    return <Card>
        <CardContent>
            <Grid2 container spacing={6}>
                {keysPerDevice.length === 0 && <Grid2 size={12}><Typography variant='body1'>Press any key on a device inside GSW ConnectBot, it will be displayed here</Typography></Grid2>}
                <Grid2 size={12}>
                    {keysPerDevice.map((device, index) => <Grid2 size={12} key={`${device.name}-${index}`}>
                        <DeviceContainer>
                            <Box sx={{ p: 1 }}><DeviceInfos {...device} /></Box>
                            <TransitionGroup component={KeysContainer}>
                                {device.keys.map((key) => <CSSTransition
                                    key={key._id}
                                    timeout={{
                                        appear: 2000,
                                        enter: 2000,
                                        exit: 100
                                    }}
                                    classNames="key"
                                >
                                    <Box sx={{ p: 1 }}>
                                        <DeviceKeyboardKeyEvent {...key} keyMap={keyMap} />
                                    </Box>
                                </CSSTransition>)}
                            </TransitionGroup>
                        </DeviceContainer>
                    </Grid2>)}
                </Grid2>
            </Grid2>
        </CardContent>
    </Card>
}
export default KeyboardEvents;