import empty_keyboard_data from './keyboardSamples/empty_keyboard';

export const EMPTY_KEYBOARD_ID = "629667000021680530046473";

const keyboardSamples = [
    {
        _id: EMPTY_KEYBOARD_ID,
        name: "New keyboard",
        keyboard: empty_keyboard_data,
        orientation: "landscape",
        updatedAt: "2023-04-03T13:54:07.473Z",
        createdAt: "2023-04-03T13:54:07.473Z",
        fileName: "qwerty_landscape.xml",
        isSample: true
    }
]

export default keyboardSamples;
