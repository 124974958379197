import { getAuthToken, clearAuthToken } from './dataStorage';
const DELAY_DEV_ONLY = process.env.NODE_ENV === "development" ? 0 : 0; //ms
if (DELAY_DEV_ONLY > 0) console.error("delay for deve is set")
const cacheInstance = (function () {
    let instance;
    function createInstance() {
        let object = {
            req: {}
        };
        return object;
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();
const apiStore = (function () {
    let instance;
    function createInstance() {
        let object = {
            permissionsMap: {}
        };
        return object;
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

export const updateApiPermissions = (permissions = []) => {
    const instance = apiStore.getInstance();
    instance.permissionsMap = permissions
        .filter(el => /^[a-z]+\.[a-z]+\.[a-z]+/i.test(el))
        .map(el => el.replace(/.*?(\.)/, ""))
        .reduce((a, c) => ({ ...a, [c]: true }), {});
    if (Object.keys(instance.permissionsMap).length !== permissions.length) {
        console.error("----some permissions are invalid-------", permissions.filter(el => !instance.permissionsMap[el.replace(/.*?(\.)/, "")]));
    }
}


class CustomPromise extends Promise {
    cancel(msg) {
        this.cancelRequest(msg);
        return this;
    }
    catch() {
        const promise = super.catch.apply(this, arguments);
        promise.cancelRequest = this.cancelRequest;
        return promise;
    }
    then() {
        const promise = super.then.apply(this, arguments);
        promise.cancelRequest = this.cancelRequest;
        return promise;
    }
    finally() {
        const promise = super.finally.apply(this, arguments);
        return promise;
    }

}
const handleFetchError = (error, url) => {
    //console.log("fetch Error", error, url);
    if (typeof error?.cause?.then === "function") {
        error.cause.then(res => {
            if (res && res.message && res.message === 'token-expired') {
                if (getAuthToken()) setTimeout(() => {
                    window.location.reload();
                }, 500);
                clearAuthToken();
            }
        })
    }
}

const cache = () => {
    return cacheInstance.getInstance().req;
}
//const CACHE_LIFETIME = 1000 * 60 * 2;// 2 minutes
const CACHE_LIFETIME = 0;
const get = ({ url, permission }) => {
    const permissionsMap = apiStore.getInstance().permissionsMap;
    const controller = new AbortController();
    let promise;
    if (permission && Object.keys(permissionsMap).length) {
        if (!permissionsMap[permission]) {
            promise = new CustomPromise((resolve, reject) => {
                console.error(`Missing required permissions: ${permission} to access url:"${url}"`)
            });
            promise.cancelRequest = (msg) => {
                if (msg) console.log("cancelRequet called", msg);
            };
            return promise;
        }
    }
    if (cache()[url] && cache()[url].ts + CACHE_LIFETIME > new Date().getTime()) {
        promise = new CustomPromise((resolve, reject) => {
            resolve(cache()[url].res);
        });
    }
    else {
        promise = new CustomPromise((resolve, reject) => {
            setTimeout(() => {
                fetch(url, {
                    headers: {
                        'Authorization': `Bearer ${getAuthToken()}`,
                    },
                    signal: controller.signal
                })
                    .then(res => res.status !== 200 ? (function () { throw new Error(res.status, { cause: res }) }()) : res)
                    .then(res => res.json())
                    .then(res => {
                        cache()[url] = {
                            res,
                            ts: new Date().getTime()
                        }
                        resolve(res)
                    })
                    .catch((error) => handleFetchError(error, url));
            }, DELAY_DEV_ONLY)
        });
    }
    promise.cancelRequest = (msg) => {
        if (msg) console.log("cancelRequet called", msg);
        controller.abort();
    };
    return promise;
}

export const getEvents = ({ messageID, androidID, sdate, edate, count, limit, group, badge }) => {
    if (edate && !sdate) console.error("getEvents end date is present but start is missing", sdate, edate, androidID, messageID)
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/event/event/?${messageID ? `messageID=${messageID}` : ''}${androidID ? `&androidID=${androidID}` : ''}${sdate ? `&sdate=${sdate}` : ''}${edate ? `&edate=${edate}` : ''}${count ? '&count=true' : ''}${limit ? `&limit=${limit}` : ''}${group ? `&group=${group}` : ''}${badge ? `&badge=${badge}` : ''}`;
    return get({ url, permission: 'event.read' });
}
export const getWifiDrop = ({ androidID, sdate, edate, count, group, badge }) => {
    if (edate && !sdate) console.error("Wifi drop end date is present but start is missing")
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/event/wifi-drop/?${androidID ? `androidID=${androidID}` : ''}${sdate ? `&sdate=${sdate}` : ''}${edate ? `&edate=${edate}` : ''}${count ? '&count=true' : ''}${group ? `&group=${group}` : ''}${badge ? `&badge=${badge}` : ''}`;
    return get({ url, permission: 'event.read' });
}

export const getDeviceDrop = ({ androidID, sdate, edate, count, group, badge }) => {
    if (edate && !sdate) console.error("Device drop end date is present but start is missing")
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/event/device-drop/?${androidID ? `androidID=${androidID}` : ''}${sdate ? `&sdate=${sdate}` : ''}${edate ? `&edate=${edate}` : ''}${count ? '&count=true' : ''}${group ? `&group=${group}` : ''}${badge ? `&badge=${badge}` : ''}`;
    return get({ url, permission: 'event.read' });
}

export const getWifiLevels = ({ sdate, edate, group }) => {
    if (edate && !sdate) console.error("Wifi drop end date is present but start is missing")
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/event/wifi-levels/?${sdate ? `sdate=${sdate}` : ''}${edate ? `&edate=${edate}` : ''}${group ? `&group=${group}` : ''}`;
    return get({ url, permission: 'event.read' });
}


export const getRttWifiLevels = ({ group }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/rtt/wifi-levels/?group=${group}`;
    return get({ url, permission: 'event.read' });
}


export const getEventsCountPerBadge = ({ messageID, sdate, edate }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/event/event-count-per-badge/?${messageID ? `messageID=${messageID}` : ''}${sdate ? `&sdate=${sdate}` : ''}${edate ? `&edate=${edate}` : ''}`;
    return get({ url, permission: 'event.read' });
}

export const getScans = ({ sdate, edate, hours, sum, group, badge, byUser }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/event/scans?${sdate ? `sdate=${sdate}` : ''}${edate ? `&edate=${edate}` : ''}${hours ? '&hours=true' : ''}${sum ? '&sum=true' : ''}${group ? `&group=${group}` : ''}${badge ? `&badge=${badge}` : ''}${byUser ? '&byuser=true' : ''}`;
    return get({ url, permission: 'event.read' });
}

export const getUtsAgentLogs = ({ limit }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/event/uts-agent-logs/?${limit ? `&limit=${limit}` : ''}`;
    return get({ url, permission: 'event.read' });
}

export const getUtsStats = ({ sdate, timeUnit }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/event/uts-stats/?sdate=${sdate}&timeunit=${timeUnit}`;
    return get({ url, permission: 'event.read' });
}

export const getUtsAgentLogAnalysis = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/event/uts-agent-log-analysis`;
    return get({ url, permission: 'event.read' });
}


export const getUsersBadgeEvents = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/event/user-badge-events`;
    return get({ url, permission: 'event.read' });
}

export const getSettings = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/setting/settings`;
    return get({ url, permission: 'setting.read' });
}

export const getAlerts = ({ alertIDPrefix, messageID, active, count, sdate, edate, androidID, groupCountByDevice, group, badge }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/alert/alert?${sdate ? `&sdate=${sdate}` : ''}${edate ? `&edate=${edate}` : ''}${messageID ? `&messageID=${messageID}` : ''}${alertIDPrefix ? `&alertIDPrefix=${alertIDPrefix}` : ''}${androidID ? `&androidID=${androidID}` : ''}${count ? '&count=true' : ''}${groupCountByDevice ? '&groupCountByDevice=true' : ''}${active ? '&active=true' : ''}${group ? `&group=${group}` : ''}${badge ? `&badge=${badge}` : ''}`;
    return get({ url, permission: 'alert.read' });
}
export const getGroups = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/group/groups`;
    return get({ url, permission: 'group.read' });
}

export const getAllEventsByPage = ({ page, limit }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/event/all-events${Number.isInteger(page) && Number.isInteger(limit) ? `?page=${page}&limit=${limit}` : ''}`
    return get({ url, permission: 'event.read' });
}
export const getAllEventsByDate = ({ sdate, edate, group, androidID }) => {
    const q = { sdate, edate, group, androidID };
    if (androidID) delete q.group;
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/event/all-events-by-date?${Object.keys(q).filter(key => q[key]).map(key => `${key}=${q[key]}`).join('&')}`
    return get({ url, permission: 'event.read' });
}
export const getGPSHistory = ({ sdate, edate, group, androidID, epsilon, showImpossibleCases }) => {
    const q = { sdate, edate, group, androidID, epsilon, showImpossibleCases };
    if (androidID) delete q.group;
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/event/gps-history?${Object.keys(q).filter(key => q[key]).map(key => `${key}=${q[key]}`).join('&')}`
    return get({ url, permission: 'event.read' });
}
export const getAllEventsCount = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/event/event-count`;
    return get({ url, permission: 'event.read' });
}

export const getDevices = (select, filter) => {
    const q = [...Object.keys(select || {}).map(el => `s${el}=1`), ...Object.keys(filter || {}).map(el => `f${el}=${(filter || {})[el]}`)].join('&');
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/device/q/?${q}`;
    return get({ url, permission: 'device.read' });
}

export const getAdmins = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/admins`;
    return get({ url, permission: 'admin.read' });
}

export const getChatUsers = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/chat/users`;
    return get({ url, permission: 'chatUser.read' });
}

export const getRoles = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/roles`;
    return get({ url, permission: 'role.read' });
}

export const getRoleByID = ({ id }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/role/${id}`;
    return get({ url, permission: 'role.read' });
}

export const getBadges = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/badge/badges`;
    return get({ url, permission: 'badge.read' });
}

export const getPermissions = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/permissions`;
    return get({ url, permission: 'permission.read' });
}


export const getConversationsList = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/chat/conversations-list`;
    return get({ url, permission: 'chatConversationsList.read' });
}
export const getConversationMessages = ({ roomID, offset, pageSize }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/chat/room/?roomID=${roomID}${Number.isInteger(offset) ? `&offset=${offset}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}`;
    return get({ url, permission: 'chatMessage.read' });
}

export const getChatRooms = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/chat/rooms`;
    return get({ url, permission: 'chatRoom.read' });
}


export const getAuthLogs = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/system/auth-logs`;
    return get({ url, permission: 'authLog.read' });
}
export const getSystemNotifications = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/system/notifications`;
    return get({ url, permission: 'notification.read' });
}
export const getBillingStatus = ({ fromCache }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/payment/status${fromCache ? "?from-cache=true" : ""}`;
    return get({ url, permission: 'payment.readStatus' });
}

export const getGSWBillingStatus = ({ fromCache }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/payment/gsw-status`;
    return get({ url, permission: 'payment.readStatus' });
}



export const getCompany = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/auth/company`;
    return get({ url, permission: 'myCompany.read' });
}
export const getAllKeyboards = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/keyboard/keyboards`;
    return get({ url, permission: 'keyboard.read' });
}

export const getDocsArticles = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/article/articles`;
    return get({ url });
}

export const getDocsArticleByTitle = ({ href }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/article/article-by-href?href=${href}`;
    return get({ url });
}

export const getMainTunnelStatus = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/setup/main-tunnel-status`;
    return get({ url, permission: 'tunnel.readStatus' });
}

export const getSecondaryTunnelStatus = ({ id }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/setup/secondary-tunnel-status/${id}`;
    return get({ url, permission: 'tunnel.readStatus' });
}


export const getMyAccount = () => {
    let controller = new AbortController();
    const promise = new CustomPromise((resolve, reject) => {
        const token = getAuthToken();
        if (token) {
            setTimeout(() => {
                fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/my-account`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    signal: controller.signal
                })
                    .then(res => res.status !== 200 ? (function () { throw new Error(res.status, { cause: res }) }()) : res)
                    .then(res => res.json())
                    .then(res => {
                        if (res && res.user) {
                            resolve(res.user)
                        }
                        else reject("can't find user");
                    })
                    .catch(error => {
                        // update 401 vs 403
                        switch (error.message) {
                            case "401":
                                if (getAuthToken()) setTimeout(() => {
                                    window.location.reload();
                                }, 500);
                                clearAuthToken();
                                return reject("token-expired");
                            case "403":
                                return reject("forbidden");
                            default:
                                return reject("unknown-error");
                        }
                    });
            }, DELAY_DEV_ONLY)
        } else reject("token-not-present");
    });
    promise.cancelRequest = () => {
        console.log("getMyAccount aborting...");
        controller.abort()
    }
    return promise;
}


// support API
export const getAllAdmins = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/all-admins`;
    return get({ url });
}

export const getAllRoles = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/all-roles`;
    return get({ url });
}

export const getAllCompanies = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/all-companies`;
    return get({ url });
}

export const getAllTunnelsStatus = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/all-tunnels-status`;
    return get({ url });
}


export const getAllNotifications = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/all-notifications`;
    return get({ url });
}

export const getSampleKeyboards = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/sample-keyboards`;
    return get({ url });
}

export const getCompanyByID = (companyID) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/company-by-id/${companyID}`;
    return get({ url });
}

export const getAdminByID = (adminID) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/admin-by-id/${adminID}`;
    return get({ url });
}

export const getAllLogs = ({ sdate }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/all-log?${sdate ? `sdate=${sdate}` : ''}`;
    return get({ url });
}

export const getAllAuthLogs = ({ sdate }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/auth-logs?${sdate ? `sdate=${sdate}` : ''}`;
    return get({ url });
}

export const getAllArticles = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/all-articles`;
    return get({ url });
}

export const getAllArticleByID = ({ id }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/article-by-id/${id}`;
    return get({ url });
}

export const getAllArticleByURL = ({ url: pageUrl }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/article/${pageUrl}`;
    return get({ url });
}


export const getEmails = ({ sdate, type, status }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/emails?${sdate ? `sdate=${sdate}` : ''}&${type ? `type=${type}` : ''}&${status ? `status=${status}` : ''}`;
    return get({ url });
}

export const getDuplicatedDevices = () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/duplicated-devices`;
    return get({ url });
}

export const getGSWWebhookEvents =  ({ sdate }) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/gsw-webhook-events?${sdate ? `sdate=${sdate}` : ''}`;
    return get({ url });
}

export const getGSWBillingPlans =  () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v2/support/gsw-billing-plans`;
    return get({ url });
}

