import React, {
  useState,
  useEffect,
  useMemo
} from 'react';
import { styled } from '@mui/system';
import { NavLink } from 'react-router-dom';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  ClickAwayListener,
  Box,
  Grid2
} from '@mui/material';
import { spacing } from '@mui/system';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import WifiDropLogTable from './WifiDropLogTable';
import WifiDropMap from './WifiDropMap';
import { updateDevicesAction } from 'redux/devices/devices.actions';
import { getWifiDrop, getDevices } from 'utils/api';
import { useSelector, useDispatch } from 'react-redux';
import WifiDropPerDevice from './WifiDropPerDevice';
import WifiDropPerAccessPoint from './WifiDropPerAccessPoint';
import WifiHeatmap from './WifiHeatmap';
import Authorized from 'components/Authorized';
import dayjsExt from 'utils/dayjsExt';
//import ErrorBoundary from 'components/ErrorBoundary';

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const WifiDropLog = () => {
  const dispatch = useDispatch();
  const selectedGroupID = useSelector((state) => state.groups.selectedGroup || '-');
  const [selectedRows, setSelectedRows] = useState([]);
  const groups = useSelector((state) => state.groups.groups);
  // last week
  const [dates, setDates] = useState([dayjsExt().subtract(6, 'day').startOf('day'), dayjsExt()])
  const [wifiDrop, setWifiDrop] = useState([]);
  const apiRef = useGridApiRef();
  const handleClickAway = () => {
    if (apiRef.current && apiRef.current.setRowSelectionModel) {
      apiRef.current.setRowSelectionModel([])
    }
  }
  useEffect(() => {
    const fetchCall = getDevices({
      androidID: true,
      date: true,
      lat: true,
      long: true,
      radius: true,
      ipaddr: true,
      battery_level: true,
      wifi_level: true,
      name: true,
    })
      .then((devices) => dispatch(updateDevicesAction(devices || [])));
    return () => {
      fetchCall.cancel()
    };
  }, [dispatch]);

  useEffect(() => {
    if (!Array.isArray(dates)) return;
    const s = dayjsExt(dates[0]);
    const e = dayjsExt(dates[1]);
    if (!s.isValid() || !e.isValid()) return;
    const sdate = s.startOf('day').toISOString();
    const edate = e.endOf('day').toISOString();
    const fetchCall = getWifiDrop({ sdate, edate })
      .then((resp) => setWifiDrop(resp || []));
    return () => fetchCall.cancel();
  }, [dates]);


  const { groupMapIDs, groupMapNames } = useMemo(() => {
    const _groupMapIDs = {};
    const _groupMapNames = {};
    groups.forEach(group => {
      _groupMapNames[group._id] = group.name || '';
      group.devices.forEach(el => _groupMapIDs[el] = group._id);
    });

    return { groupMapIDs: _groupMapIDs, groupMapNames: _groupMapNames };
  }, [groups]);

  const wifiDropByGroups = useMemo(() => {
    const all = [];
    const groupWifiDrops = {}
    wifiDrop.forEach(el => {
      all.push(el);
      const groupID = groupMapIDs[el.androidID];
      if (groupID) {
        if (!groupWifiDrops[groupID]) groupWifiDrops[groupID] = [];
        groupWifiDrops[groupID].push(el);
      }
    });
    return { all, ...groupWifiDrops };
  }, [wifiDrop, groupMapIDs]);

  const wifiDropGroup = useMemo(() => {
    if (selectedGroupID === '-') return wifiDropByGroups.all || [];
    return wifiDropByGroups[selectedGroupID] || [];
  }, [selectedGroupID, wifiDropByGroups]);

  const handleDatePickerChange = (newValue) => {
    if (!Array.isArray(newValue)) return;
    if (!dayjsExt.isDayjs(newValue[0]) || !dayjsExt.isDayjs(newValue[1])) return;
    const epochStart = newValue[0].valueOf();
    const epochEnd = newValue[1].valueOf();
    if (isNaN(epochStart) || isNaN(epochEnd) || epochStart > epochEnd) return;
    const startYear = newValue[0].toDate().getFullYear();
    setDates([
      startYear < 2010 ? new Date("2010-01-01") : newValue[0].toDate(),
      epochEnd > Date.now() ? new Date() : newValue[1].toDate()
    ]);
  }

  const datePickerDates = useMemo(() => {
    return [
      dayjsExt(dates[0] || new Date("2010-01-01")),
      dayjsExt(dates[1] || new Date()),
    ]
  }, [dates])



  return (
    (<React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        Wi-Fi Drop Log
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" sx={{ mt: 2 }}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Wi-Fi Drop Log</Typography>
      </Breadcrumbs>
      <Box>
        <Divider sx={{ my: 6 }} />
        <Box sx={{ mb: 6 }} />
        <Grid2 container spacing={6} alignItems="center">
          <Grid2>
            <DateRangePicker
              onChange={handleDatePickerChange}
              disableFuture
              format={"MM-DD-YYYY"}
              formatDensity="dense"
              minDate={dayjsExt("2010-01-01")}
              maxDate={dayjsExt()}
              localeText={{ start: "Start", end: 'End' }}
              value={datePickerDates}
              slots={{
                fieldSeparator: () => <Box sx={{ mx: 2 }}> to </Box>
              }}
              slotProps={{
                textField: {
                  size: "small",
                  sx: { width: 120 }
                }
              }}
            />
          </Grid2>
          <Grid2>
            {selectedGroupID && groupMapNames[selectedGroupID] && <Typography variant="body2">Group: <strong>{groupMapNames[selectedGroupID]}</strong></Typography>}
          </Grid2>
          <Grid2 size={12} />
          <Grid2 size={{ xs: 12, xl: 6 }}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Grid2 container spacing={12}>
                <Grid2 size={12}>
                  <Authorized permission="event.read">
                    <WifiDropLogTable
                      setSelectedRows={setSelectedRows}
                      apiRef={apiRef}
                      dates={dates}
                      rows={wifiDropGroup}
                    />
                  </Authorized>
                </Grid2>
                <Grid2 size={12}>
                  <Authorized every={["event.read", "device.read"]}>
                    <WifiDropMap
                      selections={selectedRows}
                      selectedGroupID={selectedGroupID}
                    />
                  </Authorized>
                </Grid2>
              </Grid2>
            </ClickAwayListener>
          </Grid2>
          <Grid2 container size={{ xs: 12, xl: 6 }} spacing={6}>
            <Grid2 size={{ sm: 6, xs: 12 }}>
              <Authorized permission="event.read">
                <WifiDropPerDevice rows={wifiDropGroup} />
              </Authorized>
            </Grid2>
            <Grid2 size={{ sm: 6, xs: 12 }}>
              <Authorized permission="event.read">
                <WifiDropPerAccessPoint rows={wifiDropGroup} />
              </Authorized>
            </Grid2>
            <Grid2 size={12}>
              <Authorized permission="event.read">
                <WifiHeatmap />
              </Authorized>
            </Grid2>
          </Grid2>
        </Grid2>
      </Box>
    </React.Fragment>)
  );
}

export default WifiDropLog