import RttActionTypes from "./rtt.types"

const updateRttDevicesLocationAction = (devicesLocation) => {
    return { type: RttActionTypes.UPDATE_RTT_DEVICES_LOCATION, payload: devicesLocation }
}

const updateRttDeviceLocationAction = (deviceLocation) => {
    return { type: RttActionTypes.UPDATE_RTT_DEVICE_LOCATION, payload: deviceLocation }
}

const updateRttAPsAction = (routers) => {
    return { type: RttActionTypes.UPDATE_RTT_APS, payload: routers }
}

const updateRttWifiLevelsLocationsAction = (locations) => {
    return { type: RttActionTypes.UPDATE_RTT_WIFI_LEVELS_LOCATIONS, payload: locations }
}

const updateRttWifiLevelsLocationAction = (location) => {
    return { type: RttActionTypes.UPDATE_RTT_WIFI_LEVELS_LOCATION, payload: location }
}

export {
    updateRttDevicesLocationAction,
    updateRttDeviceLocationAction,
    updateRttAPsAction,
    updateRttWifiLevelsLocationsAction,
    updateRttWifiLevelsLocationAction
}
