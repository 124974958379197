import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { styled } from '@mui/system';
import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { AlertCircle, Battery, WifiOff, MapPin, AlertTriangle, Radio } from "react-feather";
import { useNavigate } from 'react-router-dom';
import { getAlerts } from 'utils/api';
import { useSelector, useDispatch } from "react-redux"
import { updateActiveAlertsAction, removeAlertAction } from 'redux/alerts/alerts.actions';
import codes from 'constants/codes';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { http } from "utils/http";
import { toast } from "react-toastify";
import { formatTimeDate, formatTimeDateFromNow } from "utils/timeFormat";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;
function Notification({ title, description, Icon, href, alertID, date }) {
  const [removing, setRemoving] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRemoveAlert = async (alertID) => {
    try {
      setRemoving(true);
      await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/alert/alert`,
        {
          alertID
        });
      setRemoving(false);
      dispatch(removeAlertAction(alertID));
    } catch (error) {
      setRemoving(false);
      if (error &&
        error.response &&
        error.response.data &&
        error.response.data.message) {
        toast.error(error.response.data.message)
      }
      else toast.error("Can't remove alert!");
    }
  }
  return (
    <ListItem divider>
      <ListItemAvatar onClick={() => navigate(href)} role="button" title="open">
        <Avatar>
          <SvgIcon fontSize="small">
            <Icon />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        component="div"
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={
          <React.Fragment>
            <Tooltip title={description}>
              <Typography
                variant="body1"
                component="span"
                display="block"
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: '100%',
                  whiteSpace: 'nowrap'
                }}>
                {description}
              </Typography>
            </Tooltip>
            <Tooltip title={formatTimeDate(date)}>
              <Typography display="block" component="span" variant="body2" align="right">
                {formatTimeDateFromNow(date)}
              </Typography>
            </Tooltip>
          </React.Fragment>
        }
      />
      {removing ? <CircularProgress size={18} color="secondary" /> : <HighlightOffIcon color="warning" role="button" onClick={() => handleRemoveAlert(alertID)} />}
    </ListItem>
  );
}

const getIcon = (messageID) => {
  switch (messageID) {
    case codes.WIFI_LEVEL:
      return WifiOff;
    case codes.BATT_LEVEL:
      return Battery;
    case codes.DEVICE_GEOFENCE_CROSSING:
      return MapPin;
    case codes.SCAN_SUMMARY:
      return Radio;
    default:
      return AlertTriangle;
  }
}
function NavbarNotificationsDropdown() {
  const activeAlerts = useSelector((state) => state.alerts.activeAlerts || []);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchCall = getAlerts({ active: true }).then(alerts => {
      dispatch(updateActiveAlertsAction(alerts || []))
    });
    return () => {
      fetchCall.cancel();
    }
  }, [dispatch])

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title="Alerts">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={activeAlerts.length}>
            <AlertCircle />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {activeAlerts.length} New Alerts
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            {activeAlerts.slice(0, 3).map((item, index) => (
              <Notification
                key={index}
                title={item.title}
                description={item.description}
                Icon={getIcon(item.messID)}
                href={item.href}
                alertID={item.alertID}
                date={item.date}
              ></Notification>
            ))}
          </List>
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" component={Link} to="/logs/alertlog">
              Show all alerts
            </Button>
          </Box>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
