import React from "react";
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { Paper } from "@mui/material";
import Logo from 'components/Logo';
import CreatePasswordComponent from "components/auth/CreatePassword";

const Brand = styled(Logo)`
  width: 300px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function CreatePassword() {
  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Create Your Password
        </Typography>
        <CreatePasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default CreatePassword;
