import { useEffect } from 'react';
import { Button } from '@mui/material';
import { Link } from "react-router-dom";
const RTT = ({ editing, setIsValid }) => {
    useEffect(() => {
        setIsValid(true);
    }, [setIsValid])
    return editing ? <Link
        to="/pages/map"
        style={{ textDecoration: "none" }}
    >
        <Button variant="contained" color="primary">
            Indoor Tracking Page
        </Button>
    </Link>: null;
}

export default RTT;