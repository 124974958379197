import React, { 
  useState, 
  useMemo, 
  useEffect 
} from 'react';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { spacing } from '@mui/system';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Box,
  Toolbar as MuiToolBar,
  CssBaseline,
  Paper as MuiPaper,
  useMediaQuery,
  Grid2,
  ListItemButton,
  IconButton,
  CircularProgress
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from 'react-router-dom';
import Logo from 'components/Logo';
import Footer from 'components/Footer';
import SidebarNav from 'components/sidebar/SidebarNav';
import AsyncMuiIcon from 'components/AsyncMuiIcon';
import { updateArticlesAction, updateArticleAction } from 'redux/articles/articles.actions';
import { getDocsArticles, getDocsArticleByTitle } from 'utils/api';

const drawerWidth = 258;
const Root = styled(Box)`
  display: flex;
  min-height: 100vh;
  background: ${(props) => props.theme.palette.background.default};
  min-width: 300px;
  `;
const LoaderWrapper = styled(Box)`
  display: flex;
  min-height: 100vh;
  background: ${(props) => props.theme.palette.background.default};
  min-width: 300px;
  align-items: center;
  justify-content: center;
`;

const AppContent = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;
const Paper = styled(MuiPaper)(spacing);
const MainContent = styled(Paper)`
  flex: 1;
  overflow-y:auto;
  background: ${(props) => props.theme.palette.background.default};
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Brand = styled(ListItemButton)`
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  min-height: 56px;
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;
  width: ${drawerWidth}px;
  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
    color: ${(props) => props.theme.sidebar.header.brand.color};
  }
`;
const BrandWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: ${drawerWidth}px;
  background-color: ${(props) => props.theme.sidebar.header.background};
  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;
const Toolbar = styled(MuiToolBar)`
  padding-left: 0;
  min-width: 300px;
`

const ToolBarItems = styled(Grid2)`
  margin: 0;
  padding: 0;
`
const DrawerContent = styled(Box)`
  height:100vh;
  display: flex;
  flex-direction: column;
`
const SideBarNavContainer = styled(Box)`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${(props) => props.theme.sidebar.background};
`

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  backgroundColor: theme.sidebar.header.background,
  ...theme.mixins.toolbar,
}));

const AppBar = styled(({ noSideMenu, ...props }) => <MuiAppBar {...props} />)`
  background: ${(props) => props.noSideMenu ? props.theme.palette.primary.main : props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const Drawer = styled(MuiDrawer)`  
    width: ${drawerWidth}px;
    transition: ${props => `width ${props.open ? props.theme.transitions.duration.enteringScreen : props.theme.transitions.duration.leavingScreen}ms ${props.theme.transitions.easing.sharp}`};
    overflow-x: hidden;
    flex-shrink: 0;
    white-space: nowrap;
    box-sizing: border-box;
    .MuiDrawer-paper {
      width: ${drawerWidth}px;
      transition: ${props => `width ${props.open ? props.theme.transitions.duration.enteringScreen : props.theme.transitions.duration.leavingScreen}ms ${props.theme.transitions.easing.sharp}`};
    }
`;

const Spacing = styled(Grid2)`
    width: ${drawerWidth}px;
    transition-property: width;
    transition-duration: ${(props) => props.open ? props.theme.transitions.duration.leavingScreen : props.theme.transitions.duration.enteringScreen}ms;
    transition-timing-function ${(props) => props.theme.transitions.easing.sharp}; 
`;

const Docs = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathName = location.pathname;
  const href = useMemo(() => {
    if (pathName === "/docs") return '/'
    return pathName.replace("/docs", '');
  }, [pathName]);
  const articles = useSelector(state => state.articles.articles);
  const navItems = useMemo(() => [{
    title: "",
    pages: [...articles]
      .filter(el => !el.hide)
      .sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
      .sort((a, b) => a.order - b.order)
      .map(article => ({
        icon: () => <AsyncMuiIcon icon={article.icon || "Article"} />,
        title: article.title,
        href: `/docs${article.href}`
      })
      )
  }
  ], [articles]);
  const isArticleHidden = useMemo(() => {
    return !!articles.find(el => el.href === href)?.hide;
  }, [href, articles]);
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(true);
  useEffect(() => {
    const fetchCall = getDocsArticles().then(articles => dispatch(updateArticlesAction(articles)));
    return () => {
      fetchCall.cancel();
    }
  }, [dispatch])

  useEffect(() => {
    let fetchCall = null;
    if (href) {
      getDocsArticleByTitle({ href }).then(article => dispatch(updateArticleAction(article)));
    }
    return () => {
      if (fetchCall) fetchCall.cancel();
    }
  }, [href, dispatch]);

  if (articles.length === 0) return <LoaderWrapper>
    <CircularProgress color="secondary" />
  </LoaderWrapper>
  return (
    <Root>
      <CssBaseline />
      <AppBar noSideMenu={isArticleHidden}>
        <Toolbar>
          <ToolBarItems container alignItems="center">
            {isArticleHidden && <BrandWrapper>
              <Brand component={NavLink} to="/">
                <Logo />
              </Brand>
            </BrandWrapper>}
            {!open && !isLgUp && !isArticleHidden && <Grid2><IconButton onClick={() => setOpen(true)}>
              <MenuIcon />
            </IconButton></Grid2>}
            {isLgUp && <Spacing open={open} />}
            <Grid2 sx={{ flexGrow: 1 }} />
          </ToolBarItems>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isLgUp && !isArticleHidden ? "permanent" : "temporary"}
        open={open && !isArticleHidden}
        onClose={() => setOpen(false)}
      >
        <DrawerContent>
          <DrawerHeader>
            <BrandWrapper>
              <Brand component={NavLink} to="/">
                <Logo />
              </Brand>
            </BrandWrapper>
          </DrawerHeader>
          <SideBarNavContainer>
            <SidebarNav items={navItems} width={drawerWidth} />
          </SideBarNavContainer>
        </DrawerContent>
      </Drawer>
      <AppContent>
        <Toolbar />
        <MainContent p={isLgUp && !isArticleHidden ? 12 : 5}>
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
}

export default Docs;