import React from 'react';
import { styled } from '@mui/system';
import TunnelConfig from './Tunnel';
import Cbot from './Cbot';
import LADS from './LADS';
import { spacing } from '@mui/system';
import {
    Grid2,
    Typography,
} from '@mui/material';
import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    CardHeader,
} from '@mui/material';
import Authorized from 'components/Authorized';


const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;


function Downloads() {
    return (<Card sx={{
        width: {
            xs: "100%",
            md: 350,
        }
    }}>
        <CardHeader title={
            <Typography variant="h3" gutterBottom>
                Downloads
            </Typography>
        } />
        <CardContent>
            <Grid2 container spacing={6}>
                <Grid2 size={12}>
                    <LADS />
                </Grid2>
                <Grid2 size={12}>
                    <Cbot />
                </Grid2>
                <Grid2 size={12}>
                    <Authorized permission="tunnel.download">
                        <TunnelConfig />
                    </Authorized>
                </Grid2>
            </Grid2>
        </CardContent>
    </Card>
    );
}

export default Downloads;
