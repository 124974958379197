import React, { useMemo } from 'react';
import {
    Typography,
    Grid2,
    Paper,
    Box,
} from '@mui/material';
import { formatTimeDate, formatTimeDateFromNow } from 'utils/timeFormat';
import { green, red, blue } from "@mui/material/colors";
import HtmlTooltip from 'components/HtmlTooltip';
const isString = (str) => {
    return typeof str === 'string' || str instanceof String;
}
const getString = (code) => {
    if (code >= 33 && code <= 126) {
        return String.fromCharCode(code);
    } else {
        return code;
    }
}

const DeviceKeyboardKeyEvent = ({ word, keyMap, date, level }) => {
    const modifiers = useMemo(() => {
        const _modifiers = [];
        [
            { value: 65, key: 'SHIFT' },
            { value: 12288, key: 'CTRL' },
        ].forEach(code => {
            if (code.value & level) _modifiers.push(code.key);
        })
        return _modifiers.join(' ');
    }, [level])

    const code = Number.isInteger(parseInt(word)) ? `${parseInt(word)}` : '-';
    const color = useMemo(() => {
        if (keyMap[code] && keyMap[code].key !== undefined) return blue[500];
        if (code >= 33 && code <= 126) return green[500];
        if (code < 33 || code > 126) return red[500];
        return 'transparent';
    }, [code, keyMap]);
    const key = useMemo(() => {
        if (!keyMap[code]) return getString(code);
        switch (level) {
            case 0:
                if (isString(keyMap[code].key)) return keyMap[code].key;
                break;
            case 65:
                if (isString(keyMap[code].shift)) return keyMap[code].shift;
                break;
            case 12288:
                if (isString(keyMap[code].ctrl)) return keyMap[code].ctrl;
                break;
            case 12353: // shift + ctrl return alt for now
                if (isString(keyMap[code].alt)) return keyMap[code].alt;
                break;
            default:
                if (isString(keyMap[code].key)) return keyMap[code].key; // this updated to show value if there is no modifer (could cause a bug)
        }
        return getString(code);
    }, [keyMap, code, level]);
    
    return <HtmlTooltip title={
        <Grid2 container spacing={6}>
            <Grid2 size={12}>
                <Typography variant="body1">
                    Decimal: {word}
                </Typography>
                <Typography variant="body1">
                    Hex: {parseInt(code).toString(16)}
                </Typography>
                <Typography variant="body1">
                    Description: {keyMap[code] && keyMap[code].description ? keyMap[code].description : ''}
                </Typography>
                <Typography variant="body1">
                    ASCII: {String.fromCharCode(code)}
                </Typography>
                <Typography variant="body1">
                    Modifiers: {modifiers}
                </Typography>
                <Typography variant="body1">
                    Customized: {keyMap[code] && keyMap[code].key !== undefined ? keyMap[code].key : ''}
                </Typography>
                {date && <Typography variant="body2">
                    {formatTimeDateFromNow(date)}
                </Typography>}
                {date && <Typography variant="body2">
                    {formatTimeDate(date)}
                </Typography>}
            </Grid2>
        </Grid2>
    }>
        <Paper variant="outlined" sx={{ py: 1, px: 2 }} >
            <Box sx={{ height: 4, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ backgroundColor: level & 65 ? green[500] : 'transparent', height: '100%', width: 10 }} />
                <Box sx={{ backgroundColor: level & 12288 ? red[500] : 'transparent', height: '100%', width: 10 }} />
                <Box sx={{ backgroundColor: level & 0 ? blue[500] : 'transparent', height: '100%', width: 10 }} />
            </Box>
            <Typography align="center" variant="body1">
                {key}
            </Typography>
            <Box sx={{ backgroundColor: color, height: 2, width: '100%' }} />
        </Paper >
    </HtmlTooltip>
}
export default DeviceKeyboardKeyEvent;
