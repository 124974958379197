import React, {
    useEffect,
    useState
} from "react";
import {
    Typography,
    Grid2
} from "@mui/material";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { http } from "utils/http";
import { toast } from "react-toastify";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HtmlTooltip from "components/HtmlTooltip";


const DashalyticsID = () => {
    const [UUID, setUUID] = useState(null);
    useEffect(() => {
        http.get(`${process.env.REACT_APP_SERVER_URL}/api/v2/setup/post-event-uuid`)
            .then(resp => {
                if (resp && resp.data && resp.data.UUID) {
                    setUUID(resp.data.UUID)
                }
            })
            .catch(err => {
                console.error("error", err);
                toast.error("Error Can't load Dashalytics ID");
            })
    }, [])
    return (
        <Grid2 size={12} container alignItems="center">
            <Grid2>
                <Typography variant="body1">
                    Dashalytics ID:
                </Typography>
            </Grid2>
            <Grid2>
                <HtmlTooltip
                    placement="top"
                    maxWidth={500}
                    title={<>
                        <CopyToClipboard text={UUID}
                            onCopy={() => toast.success("Copied to clipboard")}>
                            <Grid2 container spacing={2}>
                                <Grid2>
                                    <ContentCopyIcon color="primary" />
                                </Grid2>
                                <Grid2>
                                    <Typography component="p" variant="body2" align="center" gutterBottom>
                                        {UUID}
                                    </Typography>
                                </Grid2>
                            </Grid2>
                        </CopyToClipboard>
                    </>}
                ><VisibilityIcon sx={{ mx: 1 }} role="button" color="primary" /></HtmlTooltip>
            </Grid2>
            <Grid2>
                <CopyToClipboard text={UUID}
                    onCopy={() => toast.success("Copied to clipboard")}>
                    <ContentCopyIcon color="primary" role="button" sx={{ mx: 1 }} />
                </CopyToClipboard>
            </Grid2>

        </Grid2>
    );
};

export default DashalyticsID;
