import React, {
    useMemo,
    useCallback,
    useState,
    useRef,
    useEffect
} from 'react';
import { useDropzone } from 'react-dropzone';
import {
    Grid2,
    Typography,
    Card,
    CardContent,
    CardHeader,
    ClickAwayListener,
    Box
} from '@mui/material';
import papa from 'papaparse';
import { toast } from 'react-toastify';
import { DataGridPro, useGridApiRef, GridToolbar } from '@mui/x-data-grid-pro';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import { updateDevicesAction } from 'redux/devices/devices.actions';
import { getDevices } from 'utils/api';
import { http } from 'utils/http';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HelpCSVFormatPreview from './HelpCSVFormatPreview';
import HelpCSVFormatSample from './HelpCSVFormatSample';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import HtmlTooltip from 'components/HtmlTooltip';
import { socketEmitters } from 'utils/socket';
import Authorized from 'components/Authorized';
import useAuthorization from 'hooks/useAuthorization';

const DropzoneWrapper = styled(Box)`
    background-color: ${(props) => props.theme.palette.background.default};
    color: ${(props) => props.theme.palette.text.primary};
    border-color: ${(props) => props.theme.palette.text.secondary};
`;

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

var reader = new FileReader();

function ImportDevicesName() {
    const [isDevicePingProcessing, setIsDevicePingProcessing] = useState({});
    const { isAuthorized } = useAuthorization();
    const canRenameDevice = isAuthorized("device.renameDevice")
    const canPingDevice = isAuthorized("device.ping")
    const apiRef = useGridApiRef();
    const dispatch = useDispatch();
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    })
    const devices = useSelector(state => state.devices || []);
    const [fetching, setFetching] = useState(false);
    useEffect(() => {
        const fetchCall = getDevices({
            androidID: true,
            name: true,
            date: true,
            ipaddr: true,
            gswcb_version: true,
        }).then(devices => dispatch(updateDevicesAction(devices || [])))
        return () => {
            fetchCall.cancel();
        };
    }, [dispatch]);

    const [newDevicesName, setNewDevicesName] = useState([]);
    const handleCancelNameEdit = (androidID) => {
        setNewDevicesName(newDevicesName.filter(el => el.androidID !== androidID));
    }
    const handleUpdateDevicesNames = async () => {
        try {
            setFetching(true);
            await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/device/multi-rename`,
                {
                    names: newDevicesName
                });
            setFetching(false);
            setNewDevicesName([]);
            toast.success("Names updated, Changes will take effect in a moment!");
        } catch (error) {
            setFetching(false);
            if (error &&
                error.response &&
                error.response.data &&
                error.response.data.message) {
                toast.error(error.response.data.message)
            }
            else toast.error("Could not update names");
        }
    }

    const onDrop = useCallback((acceptedFiles) => {
        var output = "";
        if (acceptedFiles[0]) {
            reader.onload = function (e) {
                output = e.target.result;
                displayContents(output);
            };
            reader.readAsText(acceptedFiles[0]);
        }
    }, [])

    function displayContents(payload) {
        const results = papa.parse(payload);
        if (!results || !results.data || !results.data.length) return toast.error("No data found");
        if (!results.data[0].includes("name")) return toast.error("'name' header not found!");
        if (!results.data[0].includes("id")) return toast.error("'id' header not found! (device id)");
        const nameIndex = results.data[0].findIndex(el => el === "name");
        const deviceIdIndex = results.data[0].findIndex(el => el === "id");
        const devicesMap = {}
        results.data
            .slice(1)
            .forEach(el => {
                if (el[deviceIdIndex]) {
                    devicesMap[el[deviceIdIndex]] = {
                        androidID: (el[deviceIdIndex] || "").trim(),
                        name: (el[nameIndex] || "").trim(),
                    }
                }
            })
        setNewDevicesName(Object.values(devicesMap).filter(el => el.androidID));
    }

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: ['text/plain'], onDrop });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);
    const handleDevicePing = async (deviceIP, androidID) => {
        socketEmitters.emitDeviceDevicePing({ deviceIP, androidID });
        setIsDevicePingProcessing({
            ...isDevicePingProcessing,
            [androidID]: true
        });
        await new Promise(r => setTimeout(r, 5000));
        if (mounted.current) setIsDevicePingProcessing({
            ...isDevicePingProcessing,
            [androidID]: false
        });
    };
    const isDeviceSupportPing = (gswcb_version) => {
        let supportPing = false;
        if (
            gswcb_version &&
            parseInt(gswcb_version.split(".")[0]) >= 2 &&
            parseInt(gswcb_version.split(".")[1]) >= 9 &&
            parseInt(gswcb_version.split(".")[2]) >= 88) {
            supportPing = true;
        }
        return supportPing;
    }

    const columns = [
        {
            field: "Ping Device",
            renderCell: (params) => {
                return (
                    <LoadingButton
                        onClick={() => handleDevicePing(params.row.ipaddr, params.row.androidID)}
                        size="small"
                        disabled={!canPingDevice || !isDeviceSupportPing(params.row.gswcb_version)}
                        loading={isDevicePingProcessing[params.row.androidID]}
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        <NotificationsActiveIcon />
                    </LoadingButton>
                )
            },
            width: 100,
        },
        {
            field: "androidID",
            headerName: "androidID",
            width: 160,
            editable: false,
        },
        {
            field: "ipaddr",
            headerName: "IP Address",
            width: 110,
            editable: false,
        },
        {
            field: "old_name",
            headerName: "Current Name",
            width: 200,
            editable: false,
        },
        {
            field: "name",
            headerName: "New Name",
            width: 200,
            editable: canRenameDevice
        },
        {
            field: "edited",
            headerName: "",
            width: 50,
            editable: false,
            renderCell: (params) => params.row.edited ? <CancelIcon role="button" onClick={() => handleCancelNameEdit(params.row.androidID)} color="primary" /> : "",
        }

    ];
    const devicesName = useMemo(() => {
        if (newDevicesName.length === 0)
            return devices.map(el => ({
                androidID: el.androidID,
                old_name: el.name,
                gswcb_version: el.gswcb_version,
                ipaddr: el.ipaddr
            }));
        if (devices.length === 0) return newDevicesName;
        const devicesNameMap = {};
        devices.forEach(el => {
            devicesNameMap[el.androidID] = {
                androidID: el.androidID,
                old_name: el.name,
                gswcb_version: el.gswcb_version,
                ipaddr: el.ipaddr
            }
        });
        newDevicesName.forEach(el => {
            if (!devicesNameMap[el.androidID]) return;
            devicesNameMap[el.androidID].name = el.name;
            devicesNameMap[el.androidID].edited = true;
        })
        return Object.values(devicesNameMap);
    }, [devices, newDevicesName])


    const handleClickAway = () => {
        if (apiRef.current && apiRef.current.setRowSelectionModel) {
            apiRef.current.setRowSelectionModel([])
        }
    }
    const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 50 });
    const handleOnCellEditStop = (params) => {
        const androidID = params.id;//device id
        const field = params.field;
        const oldName = params.row.old_name;
        const oldValue = params.row.name;
        setTimeout(() => {
            const newValue = apiRef.current.getCellValue(androidID, field);
            if (oldValue === newValue || oldName === newValue) return;
            const _newDevicesName = newDevicesName.filter(el => el.androidID !== androidID);
            _newDevicesName.push({ androidID, name: newValue });
            setNewDevicesName(_newDevicesName);
        }, 50);
    }

    return (
        (<Card>
            <CardHeader title={
                <Typography component="h2" variant="h3" gutterBottom>
                    Device Names
                </Typography>
            }
            />
            <CardContent>
                <Grid2 container spacing={6}>
                    <Grid2 size={12}>
                        <Authorized permission="device.renameDevice">
                            <div>
                                <Typography variant="body" gutterBottom>
                                    Import CSV file that contains the device names (Or you can use the table directly by typing in the New Name column)
                                    <HtmlTooltip
                                        title={<HelpCSVFormatPreview />}
                                    ><VisibilityIcon sx={{ mx: 1 }} role="button" color="primary" /></HtmlTooltip>that contains names (Or you can use the table directly)
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 3 }}>
                                    Click here to download a pre-built template with your device Android IDs:
                                    <HelpCSVFormatSample devices={devices} />
                                </Typography>
                            </div>
                        </Authorized>
                    </Grid2>
                    <Grid2 size={12}>
                        <Authorized permission="device.renameDevice">
                            <DropzoneWrapper {...getRootProps({ style })} >
                                <input {...getInputProps()} />
                                <Typography>Drag and Drop your completed CSV file here. (Or click and navigate to the file)</Typography>
                            </DropzoneWrapper>
                        </Authorized>
                    </Grid2>
                    <Grid2 size={12}>
                        <div style={{ width: "100%" }}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <DataGridPro
                                    apiRef={apiRef}
                                    rows={devicesName}
                                    getRowId={(row) => row.androidID}
                                    columns={columns}
                                    slots={{
                                        toolbar: GridToolbar,
                                    }}
                                    pagination
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={(model => setPaginationModel(model))}
                                    pageSizeOptions={[20, 50, 100, 200, 500]}
                                    autoHeight
                                    onCellEditStop={handleOnCellEditStop}
                                    initialState={{
                                        density: "compact"
                                    }}
                                />
                            </ClickAwayListener>
                        </div>
                    </Grid2>
                    <Grid2 size={12}>
                        <LoadingButton
                            onClick={handleUpdateDevicesNames}
                            size="small"
                            endIcon={<SaveIcon />}
                            loading={fetching}
                            loadingPosition="end"
                            variant="contained"
                            disabled={newDevicesName.length === 0}
                        >
                            Save Changes
                        </LoadingButton>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>)
    );
}

export default ImportDevicesName;