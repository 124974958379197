import { settings as defaultSettings } from 'constants/keyboard/defaultKeyboardValues';

const keyboard = {
    settings: {
        alignment: 'center',
        horizontalScale: 1,
        keyBackgroundAlpha: 255,
        keyHeight: 10,
        keyHeightUnit: '%ph',
        keyTextFontFlags: 'bold',
        keyTextFontName: defaultSettings.keyTextFontName,
        keyTextSize: defaultSettings.keyTextSize,
        keyTextSizeUnit: defaultSettings.keyTextSizeUnit,
        keyWidth: 10,
        keyWidthUnit: '%pw',
        keyBackground: defaultSettings.keyBackground,
        labelTextFontFlags: 'bold',
        labelTextFontName: defaultSettings.labelTextFontName,
        keyTextColor: defaultSettings.keyTextColor,
        labelTextSize: defaultSettings.labelTextSize,
        labelTextSizeUnit: defaultSettings.labelTextSizeUnit,
        playClickSounds: true,
        rightAlign: false,
        verticalScale: 1,
        keyboardAlpha: 255,
        skinnable: false,
    },
    rows: []
}
export default keyboard;
