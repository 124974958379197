import moment from 'moment';
import EventsActionTypes from './events.types';
import codes from 'constants/codes';
const initialState = {
    devicesEvents: {},
    last24hDevicesScanCount: {},
    last24hEvents: [],
    allDevicesEvents: [],
    scanEvents: [],
    userBadgeEvents: [],
    scanEventsPeriodCount: {},
    scanEventsPeriodGroupCount: {},
    scanEventsPeriodBadgeCount: {},
    scanEventsDevicePeriodCount: {},
    keyboardEvents: [],
    gpsHistoryEvents: []
}

const eventsReducer = (state = initialState, action) => {
    let _s = null;
    let scanEventsPeriodCount;
    let scanEventsPeriodGroupCount;
    let scanEventsPeriodBadgeCount;
    let scanEventsDevicePeriodCount;
    let last24hDevicesScanCount;
    let gpsHistoryEvents;
    switch (action.type) {
        case EventsActionTypes.UPDATE_LAST_24H_EVENTS:
            return { ...state, last24hEvents: action.payload.map(el => ({ ...el, date: moment(new Date(el.date)).format('YYYY-MM-DD HH:mm:ss') })) }
        case EventsActionTypes.UPDATE_LOG_EVENT:
            last24hDevicesScanCount = { ...state.last24hDevicesScanCount };
            if (action.payload.androidID && action.payload.messageID === codes.GSW_SCANNED) {
                if (!last24hDevicesScanCount[action.payload.androidID]) last24hDevicesScanCount[action.payload.androidID] = 0;
                else last24hDevicesScanCount[action.payload.androidID]++;
            }
            _s = { ...state.devicesEvents };
            if (!_s[action.payload.androidID]) _s[action.payload.androidID] = [];
            _s[action.payload.androidID] = [..._s[action.payload.androidID], action.payload];
            gpsHistoryEvents = [...state.gpsHistoryEvents];
            if (action.payload.androidID && action.payload.date && action.payload.properties && action.payload.properties.latitude && action.payload.properties.longitude && action.payload.properties.radius) {
                gpsHistoryEvents.push({
                    androidID: action.payload.androidID,
                    date: action.payload.date,
                    lat: action.payload.properties.latitude,
                    lon: action.payload.properties.longitude,
                    radius: action.payload.properties.radius
                })
            }

            return {
                ...state,
                allDevicesEvents: [...state.allDevicesEvents, action.payload],
                devicesEvents: _s,
                last24hEvents: [...state.last24hEvents, action.payload],
                last24hDevicesScanCount,
                gpsHistoryEvents
            }
        case EventsActionTypes.UPDATE_DEVICE_ALL_EVENTS:
            _s = { ...state.devicesEvents };
            _s[action.payload.androidID] = action.payload.events || [];
            return { ...state, devicesEvents: _s };
        case EventsActionTypes.UPDATE_ALL_DEVICES_ALL_EVENTS:
            return { ...state, allDevicesEvents: action.payload }
        case EventsActionTypes.UPDATE_SCAN_EVENTS:
            return { ...state, scanEvents: action.payload }
        case EventsActionTypes.UPDATE_USERS_BADGES_EVENTS:
            return { ...state, userBadgeEvents: action.payload }
        case EventsActionTypes.NEW_SCANS_EVENT:
            scanEventsPeriodCount = { ...state.scanEventsPeriodCount };
            Object.keys(scanEventsPeriodCount).forEach(key => {
                const today = moment().format('YYYY-MM-DD');
                if (`${key}`.endsWith(`_${today}`)) {
                    scanEventsPeriodCount[key] = scanEventsPeriodCount[key] + action.payload.length;
                }
            });
            scanEventsDevicePeriodCount = { ...state.scanEventsDevicePeriodCount };
            Object.keys(scanEventsDevicePeriodCount).forEach(key => {
                const today = moment().format('YYYY-MM-DD');
                if (`${key}`.endsWith(`_${today}`)) {
                    action.payload.forEach(el => {
                        if (!scanEventsDevicePeriodCount[key][el.androidID]) {
                            scanEventsDevicePeriodCount[key][el.androidID] = 0;
                        }
                        scanEventsDevicePeriodCount[key][el.androidID]++;
                    });
                }
            });
            scanEventsPeriodGroupCount = { ...state.scanEventsPeriodGroupCount };
            Object.keys(scanEventsPeriodGroupCount).forEach(key => {
                const today = moment().format('YYYY-MM-DD');
                if (`${key}`.endsWith(`_${today}`)) {
                    action.payload.forEach(el => {
                        if (!el.groupID) return;
                        if (!scanEventsPeriodGroupCount[key][el.groupID]) {
                            scanEventsPeriodGroupCount[key][el.groupID] = 0;
                        }
                        scanEventsPeriodGroupCount[key][el.groupID]++;
                    });
                }
            });
            scanEventsPeriodBadgeCount = { ...state.scanEventsPeriodBadgeCount };
            Object.keys(scanEventsPeriodBadgeCount).forEach(key => {
                const today = moment().format('YYYY-MM-DD');
                if (`${key}`.endsWith(`_${today}`)) {
                    action.payload.forEach(el => {
                        if (!el.badge_id) return;
                        if (!scanEventsPeriodBadgeCount[key][el.badge_id]) {
                            scanEventsPeriodBadgeCount[key][el.badge_id] = 0;
                        }
                        scanEventsPeriodBadgeCount[key][el.badge_id]++;
                    });
                }
            });
            return {
                ...state,
                scanEvents:
                    [...action.payload, ...state.scanEvents],
                last24hEvents: [...action.payload, ...state.last24hEvents],
                scanEventsPeriodCount,
                scanEventsPeriodGroupCount,
                scanEventsDevicePeriodCount,
                scanEventsPeriodBadgeCount
            }
        case EventsActionTypes.NEW_USER_BADGE_EVENT:
            return { ...state, userBadgeEvents: [...state.userBadgeEvents, action.payload] }
        case EventsActionTypes.UPDATE_SCAN_PERIOD_COUNT_EVENTS:
            scanEventsPeriodCount = { ...state.scanEventsPeriodCount };
            scanEventsPeriodGroupCount = { ...state.scanEventsPeriodGroupCount };
            scanEventsPeriodBadgeCount = { ...state.scanEventsPeriodBadgeCount };
            scanEventsPeriodCount[`${action.payload.periode.start}_${action.payload.periode.end}`] = action.payload.nEvents;
            if (action.payload.group) {
                if (!scanEventsPeriodGroupCount[`${action.payload.periode.start}_${action.payload.periode.end}`]) scanEventsPeriodGroupCount[`${action.payload.periode.start}_${action.payload.periode.end}`] = {};
                scanEventsPeriodGroupCount[`${action.payload.periode.start}_${action.payload.periode.end}`][action.payload.group] = action.payload.nEvents;
            }
            if (action.payload.badge) {
                if (!scanEventsPeriodBadgeCount[`${action.payload.periode.start}_${action.payload.periode.end}`]) scanEventsPeriodBadgeCount[`${action.payload.periode.start}_${action.payload.periode.end}`] = {};
                scanEventsPeriodBadgeCount[`${action.payload.periode.start}_${action.payload.periode.end}`][action.payload.badge] = action.payload.nEvents;
            }
            return { ...state, scanEventsPeriodCount, scanEventsPeriodGroupCount, scanEventsPeriodBadgeCount }
        case EventsActionTypes.UPDATE_SCAN_PERIOD_COUNT_EVENTS_DEVICE:
            scanEventsDevicePeriodCount = { ...state.scanEventsDevicePeriodCount };
            scanEventsDevicePeriodCount[`${action.payload.periode.start}_${action.payload.periode.end}`] = action.payload.devicesScanCount;
            return { ...state, scanEventsDevicePeriodCount }
        case EventsActionTypes.UPDATE_SCAN_PER_BDAGE_EVENTS_PERIODE:
            scanEventsPeriodBadgeCount = { ...state.scanEventsPeriodBadgeCount };
            scanEventsPeriodBadgeCount[`${action.payload.periode.start}_${action.payload.periode.end}`] = action.payload.scanCountPerBadge.reduce((a, c) => ({ ...a, [c.badge_id]: c.n }), {});
            return { ...state, scanEventsPeriodBadgeCount };
        case EventsActionTypes.UPDATE_LAST_24H_DEVICE_SCAN_COUNT:
            return { ...state, last24hDevicesScanCount: { ...state.last24hDevicesScanCount, [action.payload.androidID]: action.payload.count } }
        case EventsActionTypes.UPDATE_KEYBOARD_EVENTS:
            return { ...state, keyboardEvents: action.payload }
        case EventsActionTypes.NEW_KEYBOARD_EVENT:
            return { ...state, keyboardEvents: [...state.keyboardEvents, action.payload] }
        case EventsActionTypes.UPDATE_GPS_HISTORY_EVENTS:
            return { ...state, gpsHistoryEvents: action.payload }
        default:
            return state;
    }
}
export default eventsReducer;
