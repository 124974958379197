import BadgesActionTypes from "./badges.types";

const initialState = {
    badges: [],
    badgesIDMap: {},
    selectedBadge: null,
    selectedBadgeID: null,
}

const badgesReducer = (state = initialState, action) => {
    switch (action.type) {
        case BadgesActionTypes.UPDATE_BADGES_ACTION:
            return { 
                ...state, 
                badges: action.payload,
                badgesIDMap: action.payload.reduce((a, c) => ({...a, [c.badgeID]: c._id}), {})
            };
        case BadgesActionTypes.UPDATE_BADGE_ACTION:
            return { 
                ...state, 
                badges: [action.payload, ...state.badges.filter(badge => badge._id !== action.payload._id)],
                badgesIDMap: [action.payload, ...state.badges.filter(badge => badge._id !== action.payload._id)].reduce((a, c) => ({...a, [c.badgeID]: c._id}), {})
            };
        case BadgesActionTypes.DELETE_BADGE_ACTION:
            return { 
                ...state, 
                badges: state.badges.filter(badge => badge._id !== action.payload),
                badgesIDMap: state.badges.filter(badge => badge._id !== action.payload).reduce((a, c) => ({...a, [c.badgeID]: c._id}), {})
             };
        case BadgesActionTypes.UPDATE_SELECTED_BADGE:
            return {
                ...state, 
                selectedBadge: action.payload,
                selectedBadgeID: action.payload ? state.badges.find(el => el._id === action.payload)?.badgeID : null,
            }
        default:
            return state;
    }
}

export default badgesReducer;
