import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
    Grid2,
    Typography,
    Card,
    CardContent,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Tooltip,
    Badge,
    Button,
    Alert,
    IconButton,
    TextField
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import SaveIcon from '@mui/icons-material/Save';
import PersonIcon from '@mui/icons-material/Person';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { http } from 'utils/http';
import { deleteRoleAction, updateRoleAction, updateAdminAction } from 'redux/admins/admins.actions'
import pagesSettings from 'constants/pagesSettings';
import EditIcon from '@mui/icons-material/Edit';
import Page from './Page';
import CreateRole from './CreateRole';
import PageAdvanced from './PageAdvanced';
import useAuthorization from 'hooks/useAuthorization';
import permissionsDocs from 'constants/permissionsDocs';
const allPermissions = [...new Set(pagesSettings.reduce((a, c) => [...a, ...c.pageFullAccessRequiredPermissions], []))];

const Profiles = ({ selectedAdminRoleID, adminID, advancedMode }) => {
    const { isAuthorized } = useAuthorization();
    const canCreateProfile = isAuthorized("role.create");
    const canUpdateAdminProfile = isAuthorized("admin.updateRoles");
    const canUpdateProfile = isAuthorized("role.update");
    const canDeleteProfile = isAuthorized("role.delete");
    const dispatch = useDispatch();
    const [permissions, setPermissions] = useState([]);
    const [renaming, setRenaming] = useState(false);
    const [creating, setCreating] = useState(false);
    const [newName, setNewName] = useState('');
    const [pagesHeight, setPagesHeight] = useState({});
    const [fetching, setFetching] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const allRoles = useSelector(state => state.admins.roles || []);
    const allAdmins = useSelector(state => state.admins.admins || []);
    const [selectedRoleID, setSelectedRoleID] = useState('');
    useEffect(() => {
        setSelectedRoleID(selectedAdminRoleID || '');
    }, [selectedAdminRoleID])
    const selectedRole = useMemo(() => {
        return allRoles.find(role => role._id === selectedRoleID);
    }, [allRoles, selectedRoleID]);
    useEffect(() => {
        if (selectedRole) setPermissions(selectedRole.permissions);
        else setPermissions([]);
    }, [selectedRole])
    const permissionsMap = useMemo(() => {
        return permissions
            .reduce((p, c) => ({ ...p, [c]: true }), {});
    }, [permissions]);
    const rolesAdminsMap = useMemo(() => {
        const _rolesAdminsMap = {};
        allAdmins.forEach(admin => {
            if (Array.isArray(admin.roles)) {
                admin.roles.forEach(role => {
                    if (!_rolesAdminsMap[role]) _rolesAdminsMap[role] = [];
                    _rolesAdminsMap[role].push(admin._id);
                })
            }
        });
        return _rolesAdminsMap;
    }, [allAdmins]);
    const nAdmins = useMemo(() => {
        return rolesAdminsMap[selectedRoleID]?.length || 0;
    }, [selectedRoleID, rolesAdminsMap]);
    const handleDeleteRole = async () => {
        setFetching(true);
        try {
            const results = await http.delete(`${process.env.REACT_APP_SERVER_URL}/api/v2/admin/role/${selectedRoleID}`);
            if (!results.data._id) throw new Error("");
            setFetching(false);
            setSelectedRoleID(selectedAdminRoleID || "");
            setDeleting(false);
            dispatch(deleteRoleAction(results.data._id));
            toast.success("Deleted");
        } catch (error) {
            setFetching(false);
            console.log("error", error);
            if (error?.response?.data?.message) {
                toast.error(error.response.data.message);
            } else toast.error("Something went wrong");
        }
    }
    const canDelete = useMemo(() => {
        return !!(canDeleteProfile && selectedRole && !selectedRole.readOnly && nAdmins === 0)
    }, [nAdmins, selectedRole, canDeleteProfile]);

    const handleUpdateRolePermissions = async () => {
        setFetching(true);
        try {
            const results = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/admin/role`, {
                id: selectedRoleID,
                permissions,
            });
            if (!results.data._id) throw new Error("");
            setFetching(false);
            dispatch(updateRoleAction(results.data));
            toast.success("Saved");
        } catch (error) {
            setFetching(false);
            console.log("error", error);
            if (error && error.response && error.response.data && error.response.data.message) {
                return toast.error(error.response.data.message);
            }
            toast.error("Something went wrong");
        }
    }

    const handleUpdateAdminRoles = async () => {
        setFetching(true);
        try {
            const results = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/admin/admin-roles`,
                {
                    adminID,
                    roles: [selectedRoleID]
                });
            if (!results.data._id) throw new Error("");
            setFetching(false);
            dispatch(updateAdminAction(results.data));
            toast.success("Updated");
        } catch (error) {
            setFetching(false);
            console.log("error", error);
            if (error && error.response && error.response.data && error.response.data.message) {
                return toast.error(error.response.data.message);
            }
            toast.error("Something went wrong");
        }
    }
    const pagesPermissions = useMemo(() => {
        return pagesSettings.reduce((p, c) => (
            {
                ...p,
                [c.id]: permissionsMap[`admin.page.${c.requiredPermission}`] ? c.pageFullAccessRequiredPermissions
                    .filter(el => permissionsMap[el]) : [],
            }),
            {});
    }, [permissionsMap]);
    const updatePagePermissions = (pageID, pageRequiredPermissions, enabled) => {
        const _pagesPermissions = { ...pagesPermissions, [pageID]: enabled ? pageRequiredPermissions : [] };
        setPermissions([...new Set(Object.values(_pagesPermissions).flat())]);
    }
    const updateRolePermissions = (permission, enabled) => {
        setPermissions(enabled ? [...new Set([...permissions, permission])] : permissions.filter(el => el !== permission));
    }
    const adminCantAccessHisAccount = useMemo(() => {
        return !permissions.includes("admin.myAccount.read");
    }, [permissions]);
    const permissionsChanged = useMemo(() => {
        if (!selectedRole || selectedRole.permissions.length !== permissions.length) return true;
        const sortedRolePermissions = [...selectedRole.permissions];
        sortedRolePermissions.sort((a, b) => a.localeCompare(b));
        const sortedCurrentPermissions = [...permissions];
        sortedCurrentPermissions.sort((a, b) => a.localeCompare(b));
        return sortedCurrentPermissions.some((el, index) => el !== sortedRolePermissions[index]);
    }, [selectedRole, permissions]);
    const handleRenaming = () => {
        if (!selectedRole) return;
        setNewName(selectedRole?.name?.length ? selectedRole.name : '');
        setRenaming(true);
    }
    const handleRenameRole = async () => {
        try {
            const results = await http.patch(`${process.env.REACT_APP_SERVER_URL}/api/v2/admin/role`, {
                id: selectedRoleID,
                name: newName,
            });
            if (!results.data._id) throw new Error("");
            setFetching(false);
            dispatch(updateRoleAction(results.data));
            toast.success("Saved");
            setRenaming(false);
        } catch (error) {
            setFetching(false);
            console.log("error", error);
            if (error && error.response && error.response.data && error.response.data.message) {
                return toast.error(error.response.data.message);
            }
            toast.error("Something went wrong");
        }
    }
    const handleSelectedRoleChange = (e) => {
        setRenaming(false);
        setSelectedRoleID(e.target.value);
    }
    const handleCreateAdminRole = async (name) => {
        setFetching(true);
        try {
            const results = await http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/admin/role`, {
                name
            });
            if (!results.data._id) throw new Error("");
            setFetching(false);
            setCreating(false);
            dispatch(updateRoleAction(results.data));
            toast.success("Created");
            setTimeout(() => {
                setSelectedRoleID(results.data._id);
            }, 0)
        } catch (error) {
            setFetching(false);
            console.log("error", error);
            if (error?.response?.data?.message) {
                return toast.error(error.response.data.message);
            }
            toast.error("Something went wrong");
        }
    }
    const updatePageHeight = useCallback((id, height) => {
        setPagesHeight(oldState => ({ ...oldState, [id]: height }));
    }, []);

    const sortedPagesSettings = useMemo(() => {
        if (!advancedMode) return pagesSettings;
        return [...pagesSettings].sort((a, b) => (pagesHeight[a.id] || 0) - (pagesHeight[b.id] || 0));
    }, [pagesHeight, advancedMode]);


    return <Grid2 container spacing={2}>
        <Grid2 size={12}>
            <Card>
                <CardContent>
                    <Grid2 container alignItems="center" justifyContent="space-between" columnSpacing={2}>
                        <Grid2>
                            <FormControl sx={{ width: 200 }}>
                                <InputLabel id="role-select-label">Profile</InputLabel>
                                <Select
                                    labelId="role-select-label"
                                    id="role-select"
                                    value={selectedRoleID || ''}
                                    label="Profile"
                                    size="small"
                                    disabled={!canUpdateAdminProfile || fetching || creating || deleting || renaming}
                                    onChange={handleSelectedRoleChange}
                                >
                                    {allRoles.map(el => <MenuItem key={el._id} value={el._id}>{el.name || "-"}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 flexGrow={1} />
                        <Grid2>
                            <Button
                                onClick={() => setCreating(true)}
                                size="small"
                                disabled={!canCreateProfile || fetching || creating || deleting || renaming || permissionsChanged}
                                variant="contained"
                                endIcon={<AddCircleIcon />}
                            >
                                Create new Profile
                            </Button>
                        </Grid2>
                        <Grid2>
                            <LoadingButton
                                disabled={!canUpdateAdminProfile || selectedAdminRoleID === selectedRoleID || permissionsChanged || deleting}
                                loading={fetching}
                                variant="contained"
                                onClick={handleUpdateAdminRoles}
                                loadingPosition="end"
                                endIcon={<SaveIcon />}
                                size="small"
                            >
                                Change Profile
                            </LoadingButton>
                        </Grid2>
                    </Grid2>
                </CardContent>
            </Card>
        </Grid2>
        {creating && <Grid2 size={12}>
            <CreateRole fetching={fetching} handleCreateAdminRole={handleCreateAdminRole} close={() => setCreating(false)} />
        </Grid2>}
        {selectedRole && <React.Fragment>
            <Grid2 size={12}>
                <Card>
                    <CardContent>
                        <Grid2 container alignItems="center" justifyContent="space-between" spacing={3} >
                            <Grid2>
                                <Tooltip title={`Assigned to: ${nAdmins} Admins`}><Badge badgeContent={nAdmins} showZero color="secondary">
                                    <PersonIcon color="primary" />
                                </Badge>
                                </Tooltip>
                            </Grid2>
                            {renaming ? <Grid2>
                                <TextField
                                    sx={{ width: 150 }}
                                    label="New Name"
                                    variant="outlined"
                                    size="small"
                                    value={newName}
                                    onChange={(e) => setNewName(e.target.value)}
                                />
                            </Grid2> :
                                <Grid2>
                                    <Typography variant="h6">{selectedRole.name}</Typography>
                                </Grid2>}
                            {!selectedRole.readOnly && !deleting && <React.Fragment>
                                {renaming ? <>
                                    <Grid2>
                                        <IconButton color="primary" onClick={() => setRenaming(false)}>
                                            <CancelIcon />
                                        </IconButton>
                                    </Grid2>
                                    <Grid2>
                                        <IconButton color="primary" onClick={handleRenameRole} disabled={!newName || fetching}>
                                            <SaveIcon />
                                        </IconButton>
                                    </Grid2>
                                </> : <Grid2>
                                    {canUpdateProfile && <IconButton
                                        disabled={permissionsChanged || creating || deleting || fetching}
                                        color="primary"
                                        onClick={handleRenaming}
                                    >
                                        <EditIcon />
                                    </IconButton>}
                                </Grid2>}
                            </React.Fragment>}
                            <Grid2 flexGrow={1} />
                            {!selectedRole.readOnly &&
                                permissions.length > 0 &&
                                !deleting &&
                                <Grid2>
                                    <Button
                                        variant="contained"
                                        onClick={() => setPermissions([])}
                                        color="warning"
                                        endIcon={<CancelIcon />}
                                        size="small"
                                        disabled={!canUpdateProfile}
                                    >
                                        Clear All Permissions
                                    </Button>
                                </Grid2>}
                            {!selectedRole.readOnly &&
                                permissions.length < allPermissions.length &&
                                !deleting &&
                                <Grid2>
                                    <Button
                                        variant="contained"
                                        onClick={() => setPermissions(allPermissions)}
                                        color="secondary"
                                        endIcon={<AddCircleIcon />}
                                        size="small"
                                        disabled={!canUpdateProfile}
                                    >
                                        Add All Permissions
                                    </Button>
                                </Grid2>}

                            {canDelete && !deleting && <Grid2>
                                <Button
                                    endIcon={<DeleteIcon />}
                                    variant="contained"
                                    size="small"
                                    color="error"
                                    onClick={() => setDeleting(true)}
                                >
                                    Delete
                                </Button>
                            </Grid2>}
                            {canDelete && deleting && <>
                                <Grid2>
                                    <LoadingButton
                                        loading={fetching}
                                        endIcon={<DeleteForeverIcon />}
                                        variant="contained"
                                        size="small"
                                        color="error"
                                        onClick={handleDeleteRole}
                                    >
                                        Delete
                                    </LoadingButton>
                                </Grid2>
                                <Grid2>
                                    <Button
                                        endIcon={<CancelIcon />}
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        onClick={() => setDeleting(false)}
                                    >
                                        Cancel
                                    </Button>
                                </Grid2>
                            </>}
                            {!selectedRole.readOnly && canUpdateProfile && <Grid2>
                                <LoadingButton
                                    disabled={!permissionsChanged}
                                    loading={fetching}
                                    variant="contained"
                                    onClick={handleUpdateRolePermissions}
                                    loadingPosition="end"
                                    endIcon={<SaveIcon />}
                                    size="small"
                                >
                                    Update Profile
                                </LoadingButton>
                            </Grid2>}
                            {adminCantAccessHisAccount && <Grid2 size={12}>
                                <Alert severity="warning">This profile does not have the "Access to my account" authorization, the administrators associated with this profile will not be able to connect to their accounts.</Alert>
                            </Grid2>}
                        </Grid2>
                    </CardContent>
                </Card>
            </Grid2>
            {sortedPagesSettings.map(el => <Grid2 key={el.id} size={{ xs: 12, sm: 6, md: 4 }}>
                {advancedMode ? <PageAdvanced
                    {...el}
                    updateRolePermissions={updateRolePermissions}
                    readOnly={!canUpdateProfile || selectedRole.readOnly || creating || renaming || fetching}
                    permissionsMap={permissionsMap}
                    permissionsDocs={permissionsDocs}
                    setPageHeight={updatePageHeight}
                /> : <Page
                    {...el}
                    updatePagePermissions={updatePagePermissions}
                    readOnly={!canUpdateProfile || selectedRole.readOnly || creating || renaming || fetching}
                    permissionsMap={permissionsMap}
                />}
            </Grid2>)}
        </React.Fragment>}
    </Grid2>
}
export default Profiles;