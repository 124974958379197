import ChatActionTypes from './chat.types';
import { formatTimeDateFromNow } from 'utils/timeFormat';
import USER_TYPE from 'constants/userType';

const initialState = {
    isConnected: false,
    myID: null,
    convs: [],
    users: [],
    devices: [],
    admins: [],
    convMessages: {},
    onlineUsers: [],
    chatRooms: []
}

const chatReducer = (state = initialState, action) => {
    let messages;
    let message;
    let lastMessages;
    let convMessages;
    let roomID;
    let convs;
    let devices;
    let device;
    switch (action.type) {
        case ChatActionTypes.CHAT_SET_IS_CONNECTED:
            return { ...state, isConnected: action.payload }
        case ChatActionTypes.SET_CHAT_USERS:
            return { ...state, admins: action.payload, users: [...action.payload, ...state.devices] }
        case ChatActionTypes.DELETE_CHAT_USER:
            return { ...state, admins: state.admins.filter(el => el._id !== action.payload), users: state.users.filter(el => el._id !== action.payload) }
        case ChatActionTypes.DELETE_CHAT_DEVICE:
            return { ...state, devices: state.devices.filter(el => el._id !== action.payload), users: state.users.filter(el => el._id !== action.payload) }
        case ChatActionTypes.SET_CHAT_DEVICES:
            const _devices = action.payload.map(device => ({
                _id: device.androidID,
                firstName: device.name || device.androidID,
                displayName: device.name || device.androidID,
                lastName: '',
                type: USER_TYPE.DEVICE
            }));
            return { ...state, devices: _devices, users: [...state.admins, ..._devices] }
        case ChatActionTypes.SET_CHAT_CONVS:
            return { ...state, convs: action.payload }
        case ChatActionTypes.SET_CHAT_CONV_MESSAGES:
            return {
                ...state, convMessages: {
                    ...state.convMessages,
                    [action.payload.roomID]:
                        action.payload.messages
                            .map(el => ({
                                ...el,
                                time: formatTimeDateFromNow(el.createdAt)
                            }))
                }
            }
        case ChatActionTypes.APPEND_CHAT_CONV_MESSAGES:
            messages = [...(state.convMessages[action.payload.roomID] || [])];
            messages.splice(0, messages.length - action.payload.offset, ...action.payload.messages);
            return {
                ...state, convMessages: {
                    ...state.convMessages,
                    [action.payload.roomID]: messages
                        .map(el => ({
                            ...el,
                            time: formatTimeDateFromNow(el.createdAt)
                        }))
                }
            }
        case ChatActionTypes.NEW_CHAT_CONV_MESSAGE:
            message = action.payload.message
            message.time = formatTimeDateFromNow(message.createdAt);
            roomID = action.payload._id;
            convMessages = { ...state.convMessages };
            const chatRoomUpdates = {};
            if (!convMessages[roomID]) {
                chatRoomUpdates.chatRooms = state.chatRooms.filter(el => el._id !== action.payload._id);
                chatRoomUpdates.chatRooms.push({
                    _id: action.payload._id,
                    name: action.payload.name,
                    participants: action.payload.participants,
                    type: action.payload.type,
                    createdBy: action.payload.createdBy,
                    updatedAt: action.payload.updatedAt,
                    createdAt: action.payload.createdAt,
                })
            }
            convMessages[roomID] = convMessages[roomID] ? [...convMessages[roomID]] : [];
            convMessages[roomID].push(message);
            convs = state.convs.filter(el => el._id !== roomID);
            convs.unshift(action.payload);
            return {
                ...state,
                convMessages,
                convs,
                ...chatRoomUpdates
            };
        case ChatActionTypes.SET_MY_ID:
            return { ...state, myID: action.payload }
        case ChatActionTypes.UPDATE_CHATROOM_STATUS: {
            convs = [...state.convs];
            const conv = convs.find(el => el._id === action.payload._id);
            if (conv?.message && action?.payload?.message) conv.message = action.payload.message;
            const deliveredTo = action.payload?.message?.deliveredTo || [];
            const seenBy = action.payload?.message?.seenBy || [];
            convMessages = { ...state.convMessages };
            convMessages[action.payload._id] = convMessages[action.payload._id] ? [...convMessages[action.payload._id]] : [];
            convMessages[action.payload._id].forEach(el => {
                el.seenBy = Array.isArray(el.seenBy) && el.seenBy.length > seenBy.length ? el.seenBy : [...seenBy];
                el.deliveredTo = Array.isArray(el.deliveredTo) && el.deliveredTo.length > deliveredTo.length ? el.deliveredTo : [...deliveredTo];
            });
            return { ...state, convs, convMessages };
        }
        case ChatActionTypes.CHAT_UPDATE_ONLINE_USERS:
            return { ...state, onlineUsers: action.payload }
        case ChatActionTypes.SET_MESSAGES:
            messages = action.payload || [];
            lastMessages = {}
            messages.forEach(msg => {
                const id = msg.from === state.myId ? msg.to : msg.from;
                lastMessages[id] = ({
                    id: msg._id,
                    convId: id,
                    recieved: msg.to === state.myId,
                    message: msg.text,
                    seen: msg.seen,
                    type: msg.type,
                })
            })
            return { ...state, messages, lastMessages }
        case ChatActionTypes.SET_ONLINE_USERS:
            return { ...state, onlineUsers: action.payload }
        case ChatActionTypes.UPDATE_CHAT_DEVICE_NAME:
            devices = [...state.devices];
            device = devices.find(el => el._id === action.payload.androidID);
            if (device) device.firstName = action.payload.name;
            return { ...state, devices, users: [...state.admins, ...devices] }
        case ChatActionTypes.UPDATE_CHAT_ROOMS:
            return { ...state, chatRooms: action.payload }
        case ChatActionTypes.UPDATE_CHAT_ROOM:
            return { ...state, chatRooms: [...state.chatRooms.filter(el => el._id !== action.payload._id), action.payload] }
        case ChatActionTypes.DELETE_CHAT_ROOM:
            return { ...state, chatRooms: state.chatRooms.filter(el => el._id !== action.payload) }

        default:
            return state;
    }
}

export default chatReducer;