import React, { useMemo } from 'react';
import useAuthorization from "hooks/useAuthorization";
import { Box, Alert } from '@mui/material';
import { styled } from '@mui/system';
import { rgba } from 'polished';

const AlertWrapper = styled(Box)`
    position: relative;
`;
const ComponentWrapper = styled(Box)`
    opacity: 0.9;
    outline:  1px solid ${(props) => rgba(props.theme.palette.error.main, 0.5)};
    display: inline;
`;
const Overlay = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => rgba(props.theme.palette.background.paper, 0.7)};
`;
const Warning = styled(Alert)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;


const Authorized = ({ permission, every, some, action = "hide", children }) => {
    const { isAuthorized } = useAuthorization();
    const hasPermission = useMemo(() => {
        if (typeof permission === "string") return isAuthorized(permission);
        if (Array.isArray(every)) return every.every(permission => isAuthorized(permission));
        if (Array.isArray(some)) return some.some(permission => isAuthorized(permission));
        console.error("Authorized: invalid required permissions")
        return false;
    }, [permission, every, some, isAuthorized])

    if (!children) return null;
    if (action === "debug") {
        const updatedChildren = React.Children.map(children, (child) =>{
            if(!child.props) {
                console.error("child have no props! - check wrapped component render method", child)
                return <Alert severity='error'>
                    Check render method for wrapped component
                </Alert>
            }
            
            return React.cloneElement(
                child,
                {
                    style: {
                        ...child.props.style,
                        outline: `1px solid ${hasPermission ? '#00ff00' : '#00ffff'}`,
                        backgroundColor: `${hasPermission ? '#00ff0040' : '#00ffff40'}`,
                        filter: hasPermission ? 'invert(0%)' : 'invert(100%)',
                    }
                }
            )
        });
        return <span
            style={{
                outline: `2px solid ${hasPermission ? '#00ff00' : '#00ffff'}`,
                margin: 0,
                padding: 0,
                height: '100%',
                width: '100%',
                opacity: 1,
                backgroundColor: `${hasPermission ? '#00ff0040' : '#00ffff40'}`,
                filter: hasPermission ? 'invert(0%)' : 'invert(100%)',
            }}>
                {updatedChildren}
        </span>
    }
    if (hasPermission) return children;
    if (!permission) console.error("invalid permission");
    switch (action) {
        case 'hide':
            return null;
        case 'alert':
            return <Alert severity="error">You do not have permission to view this!</Alert>
        case "warn":
            return <AlertWrapper>
                <ComponentWrapper>
                    {children}
                </ComponentWrapper>
                <Overlay />
                <Warning severity="error">Permissions Required!</Warning>
            </AlertWrapper>
        default:
            return null;
    }
}

export default Authorized;