import React from 'react';
import BillingNotSet from './BillingNotSet';
import BillingActive from './BillingActive'


const BillingStatus = ({ billingStatus }) => {
    if (/^suspended|past_due$/.test(billingStatus?.status)) {
        return <BillingNotSet {...billingStatus} />
    }
    if (/^active|canceled|trialing$/.test(billingStatus?.status)) {
        return <BillingActive {...billingStatus} />
    }
    return <span></span>
}
export default BillingStatus;
