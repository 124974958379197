import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import {
    Card as MuiCard,
    CardContent,
    Button as MuiButton,
    Grid2,
    TextField as MuiTextField,
    Typography,
    Checkbox,
    FormControlLabel,
    Box
} from "@mui/material";
import { toast } from "react-toastify";
import { http } from "utils/http";
import { spacing } from "@mui/system";
import useAuth from "hooks/useAuth";
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
const DeleteMyCompany = () => {
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [password, setPassword] = useState('');
    const [agreed, setAgreed] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [deleteUsers, setDeleteUsers] = useState(false);
    const [deleteChat, setDeleteChat] = useState(false);
    const [deleteAlerts, setDeleteAlerts] = useState(false);
    const [deleteDevices, setDeleteDevices] = useState(false);
    const [deleteEvents, setDeleteEvents] = useState(false);
    const [message, setMessage] = useState('');

    const [error, setError] = useState('');
    const { signOut } = useAuth();
    const handleCancelDelete = () => {
        setAgreed(false);
        setPassword('');
        setDeleteConfirmation(false);
        setError('');
        setDeleteChat(false);
        setDeleteAlerts(false);
        setDeleteDevices(false);
        setDeleteEvents(false);
        setDeleteUsers(false);
    }
    const handleCheckDeleteAll = (e) => {
        const checked = e.target.checked;
        if (checked) {
            setDeleteChat(true);
            setDeleteAlerts(true);
            setDeleteDevices(true);
            setDeleteEvents(true);
            setDeleteUsers(true);
        } else {
            setDeleteUsers(false);
        }
    }
    useEffect(() => {
        if (!(deleteChat && deleteUsers && deleteEvents && deleteAlerts && deleteDevices)) {
            setDeleteUsers(false)
        }
        setAgreed(false);
    }, [deleteChat, deleteUsers, deleteEvents, deleteAlerts, deleteDevices])
    const handleMyAccountDelete = async () => {
        setError('');
        setFetching(true);
        try {
            const resp = await http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/auth/delete-data`, {
                password,
                deleteUsers,
                deleteChat,
                deleteAlerts,
                deleteDevices,
                deleteEvents,
            })
            setFetching(false);
            if (resp.data && resp.data.success) {
                if (resp.data.logout) return signOut();
                setMessage("Success!");
                setAgreed(false);
                setPassword('');
                setError('');
                setDeleteChat(false);
                setDeleteAlerts(false);
                setDeleteDevices(false);
                setDeleteEvents(false);
                setDeleteUsers(false);

            }
        } catch (err) {
            // Handle Error Here
            setFetching(false);
            if (err && err.response && err.response.data && err.response.data.message) {
                toast.error(err.response.data.message);
                return setError(err.response.data.message);
            }
            console.error("error", err);
            setError("Something went wrong");
            toast.error("Something went wrong");
        }
    }
    return <Card sx={{ mb: 6 }}>
        <CardContent>
            <Typography variant="h6" gutterBottom>
                Delete DataDatabase
            </Typography>
            <Grid2 container spacing={6}>
                {!deleteConfirmation ?
                    (
                        <Grid2 size={12}>
                            <Button onClick={() => setDeleteConfirmation(true)} variant="contained" color="error">
                                Show more
                            </Button>
                        </Grid2>
                    ) :
                    (
                        <React.Fragment>
                            <Grid2 size={12} sx={{ mt: 3 }}>
                                <Typography variant="body" gutterBottom>
                                    You can delete part of the entire data stored about you or your compnay
                                </Typography>
                            </Grid2>
                            {message && <Grid2 size={12}>
                                <Typography variant="body" gutterBottom color="#0d6efd">
                                    {message}
                                </Typography>
                            </Grid2>}
                            <Box sx={{ mx: 8, width: '100%' }}>
                                <Grid2 container>
                                    <Grid2 size={12}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={handleCheckDeleteAll} checked={deleteChat && deleteUsers && deleteEvents && deleteAlerts && deleteDevices} color="error" />}
                                            label="Delete everything"
                                            sx={{ color: '#d32f2f' }}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={(e) => setDeleteChat(e.target.checked)} checked={deleteChat} color="primary" />}
                                            label="Delete All chat conversations"
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={(e) => setDeleteAlerts(e.target.checked)} checked={deleteAlerts} color="primary" />}
                                            label="Delete All Alerts"
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={(e) => setDeleteDevices(e.target.checked)} checked={deleteDevices} color="primary" />}
                                            label="Delete All Devices Data"
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={(e) => setDeleteEvents(e.target.checked)} checked={deleteEvents} color="primary" />}
                                            label="Delete All Events"
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <FormControlLabel
                                            sx={{ color: '#d32f2f' }}
                                            control={<Checkbox onChange={null} disabled={!(deleteChat && deleteUsers && deleteEvents && deleteAlerts && deleteDevices)} checked={deleteUsers} color="error" />}
                                            label="Delete All Users including my account"
                                            color="error"
                                        />
                                    </Grid2>
                                </Grid2>
                            </Box>
                            <Grid2 size={4}>
                                <TextField
                                    label="Type your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <FormControlLabel
                                    control={<Checkbox onChange={(e) => setAgreed(e.target.checked)} checked={agreed} color="primary" />}
                                    label="I understand and want to delete selected fields for ever"
                                />
                                <Button onClick={handleMyAccountDelete} sx={{ mx: 1 }} disabled={!agreed || password.length < 6 || fetching || !(deleteChat || deleteUsers || deleteEvents || deleteAlerts || deleteDevices)} variant="contained" color="error">
                                    Yes
                                </Button>
                                <Button sx={{ mx: 1 }} onClick={handleCancelDelete} variant="contained" color="primary">
                                    No
                                </Button>
                            </Grid2>
                            <Grid2 size={12}>
                                {error && <Typography variant="body" color="error" gutterBottom>
                                    {error}
                                </Typography>}
                            </Grid2>
                        </React.Fragment>
                    )}
            </Grid2>
        </CardContent>
    </Card >
}
export default DeleteMyCompany;