import React from "react";
import { Outlet, Link } from 'react-router-dom'
import async from "components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
import ClientLayout from './layouts/Client';
import AccountSetupLayout from './layouts/AccountSetup';
import SupportLayout from './layouts/Support';
import DocsLayout from './layouts/Docs';
// Guards
import AuthGuard from 'components/guards/AuthGuard';

// Auth components
import ResetPassword from 'pages/auth/ResetPassword';
import RequestDemo from 'pages/auth/RequestDemo';
import LaunchDemo from 'pages/auth/LaunchDemo';
import VerifyEmail from "pages/auth/VerifyEmail";
import ForgotPassword from 'pages/auth/ForgotPassword';
import CreatePassword from 'pages/auth/CreatePassword';
import UpdateEmail from 'pages/auth/UpdateEmail';
import Page404 from 'pages/auth/Page404';
import Page500 from 'pages/auth/Page500';

// Settings
import MyAccount from 'pages/settings/MyAccount';
import Database from 'pages/settings/Database';
import Admins from 'pages/settings/admins';
import Admin from 'pages/settings/admin';
import Devices from 'pages/settings/devices';
import GenerateNewUUID from 'pages/settings/GenerateNewUUID';
import Company from 'pages/company';
import Encryption from 'pages/settings/encryption';
// Landing Page
import Landing from 'pages/Landing';
// Landing Page

// use async : size of code is too big!
// Page components
import Map from 'pages/rtt';

import Gps from 'pages/Gps';
import Debug from 'pages/Debug';

import Chat from 'pages/Chat';
//LOGS
import EventLog from 'pages/logs/EventLog';
import Alertlog from 'pages/logs/Alertlog';
import Scanlog from 'pages/logs/Scanlog';
import WiFiDropLog from 'pages/logs/WiFiDropLog';
import { Typography } from "@mui/material";
// auth contains 
const SignIn = async(() => import('pages/auth/SignIn'));
// Dashboard components
const Default = async(() => import('pages/dashboards/Default'));
const Productivity = async(() => import('pages/dashboards/Productivity'));
const Alerts = async(() => import('pages/dashboards/Alerts'));
const UTS = async(() => import('pages/dashboards/UTS'));
const Users = async(() => import('pages/dashboards/Users'));
// Icon components
const DeviceList = async(() => import('pages/DeviceList'));
const RemoteManagement = async(() => import('pages/RemoteManagement'));
const DeviceDetails = async(() => import('pages/DeviceDetails'));
const UserDetails = async(() => import('pages/UserDetails'));
const MergeDevices = async(() => import('pages/MergeDevices'));
const KeyboardContextProviderWrapper = async(() => import("pages/Keyboard/ContextProviderWrapper"));
const Keyboard = async(() => import('pages/Keyboard'));
const KeyboardAdvancedModePage = async(() => import('pages/Keyboard/KeyboardAdvancedModePage'));
const KeyboardExpressModePage = async(() => import('pages/Keyboard/KeyboardExpressModePage'));
const KeyboardExpressEditorModePage = async(() => import('pages/Keyboard/KeyboardExpressEditorModePage'));
const KeyboardXMLEditorModePage = async(() => import('pages/Keyboard/KeyboardXMLEditorModePage'));
const KeyboardAllProjectsPage = async(() => import('pages/Keyboard/KeyboardAllProjectsPage'));
const KeyboardLADSPage = async(() => import('pages/Keyboard/KeyboardLADSPage'));
const ClientChat = async(() => import('pages/ClientChat'));
const MapCanvas = async(() => import('pages/rtt-canvas'));
const Setup = async(() => import('pages/Setup'));
const CreateAccount = async(() => import('pages/CreateAccount'));
const Support = async(() => import('pages/Support'));
const AllAdmins = async(() => import('pages/Support/Admins'));
const AllCompanies = async(() => import('pages/Support/Companies'));
const CompanyDetails = async(() => import('pages/Support/CompanyDetails'));
const AdminDetails = async(() => import('pages/Support/AdminDetails'));
const Diagnostics = async(() => import('pages/Support/Diagnostics'));
const SupportChat = async(() => import('pages/Support/Chat'));
const Documentations = async(() => import('pages/Support/Documentations'));
const DocumentationsCreate = async(() => import('pages/Support/Documentations/Create'));
const DocumentationsEdit = async(() => import('pages/Support/Documentations/Edit'));
const Docs = async(() => import('pages/Docs'));
const SupportNotifications = async(() => import('pages/Support/Notifications'));
const SupportKeyboards = async(() => import('pages/Support/Keyboards'));
const SupportEditKeyboard = async(() => import('pages/Support/Keyboards/EditKeyboard'));
const SupportBilling = async(() => import('pages/Support/Billing'));
const KeyboardMovedToRemove = () => {

  return <Typography variant="h6">Keyboard was moved <Link to="/settings/keyboard">Open</Link></Typography>
}
const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Default />
      },
    ],
  },
  {
    path: "productivity",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Productivity />
      },
    ],
  },
  {
    path: "devices",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DeviceList />
      },
    ],
  },
  {
    path: "alerts",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Alerts />
      },
    ],
  },
  {
    path: "uts",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <UTS />
      },
    ],
  },
  {
    path: "users",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Users />
      },
    ],
  },
  {
    path: "devices/:androidID",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DeviceDetails />
      },
    ],
  },
  {
    path: "users/:badge_id",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <UserDetails />
      },
    ],
  },
  {
    path: "logs",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "scanlog",
        element: <Scanlog />
      },
      {
        path: "alertlog",
        element: <Alertlog />
      },
      {
        path: "eventlog",
        element: <EventLog />
      },
      {
        path: "wifidroplog",
        element: <WiFiDropLog />
      }
    ],
  },
  {
    path: "chat",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      { element: <Chat />, index: true },
      { path: ":roomID", element: <Chat /> },
    ],
  },
  {
    path: "client/chat",
    element: (
      <ClientLayout />
    ),
    children: [
      {
        path: "",
        element: <ClientChat />
      }
    ],
  },
  {
    path: "overview",
    element: (
      <Landing />
    ),
    children: [],
  },
  {
    path: "setup",
    element: (
      <AuthGuard>
        <AccountSetupLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Setup />
      },
    ],
  },
  {
    path: "settings",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "account",
        element: <MyAccount />
      },
      {
        path: "manage-data",
        element: <Database />
      },
      {
        path: "admins",
        element: <Admins />
      },
      {
        path: "admin/:id",
        element: <Admin />
      },
      {
        path: "devices",
        element: <Devices />
      },
      {
        path: "generate-new-uuid",
        element: <GenerateNewUUID />
      },
      {
        path: "encryption",
        element: <Encryption />
      },
      {
        path: "keyboard",
        element: (
          <KeyboardContextProviderWrapper>
            <Outlet />
          </KeyboardContextProviderWrapper>
        ),
        children: [
          {
            path: "",
            element: <Keyboard />
          },
          {
            path: "advanced/:id",
            element: <KeyboardAdvancedModePage />
          },
          {
            path: "express",
            element: <KeyboardExpressModePage />
          },
          {
            path: "express/:id",
            element: <KeyboardExpressModePage />
          },
          {
            path: "express-editor",
            element: <KeyboardExpressEditorModePage />
          },
          {
            path: "express-editor/:id",
            element: <KeyboardExpressEditorModePage />
          },
          {
            path: "xml-editor/:id",
            element: <KeyboardXMLEditorModePage />
          },
          {
            path: "projects",
            element: <KeyboardAllProjectsPage />
          },
          {
            path: "lads",
            element: <KeyboardLADSPage />
          },
          {
            path: "lads/:id",
            element: <KeyboardLADSPage />
          }
        ],
      }
    ],
  },
  {
    path: "company",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Company />
      }
    ],
  },
  {
    path: "pages",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "map",
        element: <Map />
      },
      {
        path: "map-canvas",
        element: <MapCanvas />
      },
      {
        path: "gps",
        element: <Gps />
      },
      {
        path: "debug",
        element: <Debug />
      },
      {
        path: "merge-devices",
        element: <MergeDevices />
      },
      {
        path: "merge-devices/:androidID",
        element: <MergeDevices />
      },
      {
        path: "remote-management",
        element: <RemoteManagement />
      },
      {
        path: "keyboard",
        element: <KeyboardMovedToRemove />
      }
    ],
  },
  {
    path: "support",
    element: (
      <AuthGuard>
        <SupportLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Support />
      },
      {
        path: "companies",
        element: <AllCompanies />
      },
      {
        path: "admins",
        element: <AllAdmins />
      },
      {
        path: "admin/:id",
        element: <AdminDetails />
      },
      {
        path: "company/:id",
        element: <CompanyDetails />
      },
      {
        path: "chat",
        element: <SupportChat />
      },
      {
        path: "chat/:roomID",
        element: <SupportChat />
      },
      {
        path: "keyboards",
        element: <KeyboardContextProviderWrapper>
          <SupportKeyboards />
        </KeyboardContextProviderWrapper>
      },
      {
        path: "keyboards/:id",
        element: <KeyboardContextProviderWrapper>
          <SupportEditKeyboard />
        </KeyboardContextProviderWrapper>
      },
      {
        path: "billing",
        element: <SupportBilling />
      },
      {
        path: "notifications",
        element: <SupportNotifications />
      },
      {
        path: "diagnostics",
        element: <Diagnostics />
      },
      {
        path: "documentations",
        element: <Documentations />
      },
      {
        path: "documentations/create",
        element: <DocumentationsCreate />
      },
      {
        path: "documentations/:id",
        element: <DocumentationsEdit />
      }
    ],
  },
  {
    path: "docs",
    element: (
      <DocsLayout />
    ),
    children: [
      {
        path: "",
        element: <Docs />
      },
      {
        path: "*",
        element: <Docs />
      }
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />
      },
      {
        path: "sign-up",
        element: <CreateAccount />
      },
      {
        path: "reset-password/:uuid",
        element: <ResetPassword />
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />
      },
      {
        path: "validate-account/:uuid",
        element: <CreatePassword />
      },
      {
        path: "update-email/:uuid",
        element: <UpdateEmail />
      },
      {
        path: "request-demo",
        element: <RequestDemo />
      },
      {
        path: "request-demo/:token",
        element: <LaunchDemo />
      },
      {
        path: "verify-email/:token",
        element: <VerifyEmail />
      }
    ],
  },
  {
    path: "",
    element: <AuthLayout />,
    children: [
      {
        path: "404",
        element: <Page404 />
      },
      {
        path: "500",
        element: <Page500 />
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />
      },
    ],
  },
];

export default routes;
