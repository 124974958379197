const SOCKET_EVENT = {
    SOCKET_SERVER_UPDATE_ONLINE_USERS: 'SOCKET_SERVER_UPDATE_ONLINE_USERS',
    SOCKET_CLIENT_EMIT_MESSAGE: 'SOCKET_CLIENT_EMIT_MESSAGE',
    SOCKET_SERVER_EMIT_MESSAGE: 'SOCKET_SERVER_EMIT_MESSAGE',
    SOCKET_CLIENT_MARK_CONV_SEEN: 'SOCKET_CLIENT_MARK_CONV_SEEN',
    SOCKET_CLIENT_MARK_CONV_UNSEEN: 'SOCKET_CLIENT_MARK_CONV_UNSEEN',
    SOCKET_SERVER_UPDATE_CHATROOM_STATUS: 'SOCKET_SERVER_UPDATE_CHATROOM_STATUS',
    SOCKET_CLIENT_DEVICE_RELEASE_LICENSE: 'SOCKET_CLIENT_DEVICE_RELEASE_LICENSE',
    SOCKET_SERVER_NOT_READY: 'SOCKET_SERVER_NOT_READY',
    SOCKET_SERVER_DISCONNECT_DEVICE: 'SOCKET_SERVER_DISCONNECT_DEVICE',
    SOCKET_SERVER_DEVICE_UPDATE: 'SOCKET_SERVER_DEVICE_UPDATE',
    SOCKET_SERVER_DEVICE_DELETE: 'SOCKET_SERVER_DEVICE_DELETE',
    SOCKET_SERVER_NEW_RTT_LOCATION: "SS_N_RTT",
    SOCKET_SERVER_NEW_RTT_WIFI_LEVELS: "SS_N_RTT_WL",
    SOCKET_SERVER_UPDATE_LICENSE_COUNT: "SOCKET_SERVER_UPDATE_LICENSE_COUNT",
    SOCKET_SERVER_UPDATE_DEVICE_NAME: "SOCKET_SERVER_UPDATE_DEVICE_NAME",
    SOCKET_CLIENT_DEVICE_PING: "SOCKET_CLIENT_DEVICE_PING",
    SOCKET_SERVER_NEW_SYSTEM_NOTIFICATION: "SOCKET_SERVER_NEW_SYSTEM_NOTIFICATION",
    SOCKET_SERVER_NEW_KEYBOARD_EVENT: "SOCKET_SERVER_NEW_KEYBOARD_EVENT",
    SOCKET_SERVER_NEW_UTS_EVENT: "SS_N_U",
    SOCKET_SERVER_NEW_UTS_AGENT_LOG_EVENT: "SS_N_UA",
    SOCKET_SERVER_NEW_ALERT: "SOCKET_SERVER_NEW_ALERT",
    SOCKET_SERVER_REMOVE_ALERT: "SOCKET_SERVER_REMOVE_ALERT",
    SOCKET_SERVER_UPDATE_LOG_EVENT: "SOCKET_SERVER_UPDATE_LOG_EVENT",
    SOCKET_SERVER_NEW_SCAN_EVENT: "SOCKET_SERVER_NEW_SCAN_EVENT",
    SOCKET_CLIENT_READY: "SOCKET_CLIENT_READY",
    SOCKET_SERVER_USER_BADGE_EVENT: "SOCKET_SERVER_USER_BADGE_EVENT",
    SOCKET_SERVER_UPDATE_BADGE: "SOCKET_SERVER_UPDATE_BADGE",
    SOCKET_CLIENT_GET_CLIENT_SETTINGS: "SOCKET_CLIENT_GET_CLIENT_SETTINGS",
    SOCKET_CLIENT_UPDATE_CLIENT_SETTINGS: "SOCKET_CLIENT_UPDATE_CLIENT_SETTINGS",
    SOCKET_SERVER_UPDATE_CLIENT_SETTINGS: "SOCKET_SERVER_UPDATE_CLIENT_SETTINGS",
    SOCKET_DEBUG: "SOCKET_DEBUG",
};

export default SOCKET_EVENT;
