import { useEffect, useState, useMemo } from 'react';
import {
    Box,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Tooltip,
    Badge,
    Menu
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedGroup } from 'redux/groups/groups.actions';
import usePreferences from 'hooks/usePreferences';
import { useLocation } from "react-router-dom";
const getNoGroupTitle = (path) => {
    if (/(^\/$)|(^\/alerts$)|(^\/pages\/gps$)|(^\/logs\/wifidroplog)/.test(path)) return "All";
    if (['/productivity'].includes(path)) return "All Groups";
    if (['/settings/devices'].includes(path)) return "Ungrouped devices"
    return "Ungrouped"
}
const GroupSelector = () => {
    const router = useLocation();
    const preferencesID = "global-group-selector";
    const { preferences, updatePreferences } = usePreferences();
    const dispatch = useDispatch();
    const initialState = preferences[preferencesID];
    const selectedGroupID = useSelector((state) => state.groups.selectedGroup || null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [anchorMenu, setAnchorMenu] = useState(null);
    useEffect(() => {
        // this must run only first time
        if (!firstLoad || !initialState) return;
        dispatch(updateSelectedGroup(initialState.selectedGroupID === '-' ? null : initialState.selectedGroupID));
        setFirstLoad(false);
    }, [dispatch, initialState, firstLoad]);

    const allGroups = useSelector((state) => state.groups.groups || [])
    const currentRoute = router.pathname;
    const groups = useMemo(() => {
        if (currentRoute === "/settings/devices") return allGroups
            .sort((a, b) => b.devices.length - a.devices.length);
        return allGroups.filter(el => el.devices.length > 0).sort((a, b) => b.devices.length - a.devices.length)
    }, [allGroups, currentRoute]);
    const groupsMap = useMemo(() => groups.reduce((a, c) => ({ ...a, [c._id]: c }), {}), [groups]);
    const handleChange = (selected) => {
        dispatch(updateSelectedGroup(selected === '-' ? null : selected));
        setAnchorMenu(null);
        updatePreferences(preferencesID, { selectedGroupID: selected });
    };
    const toggleMenu = (event) => {
        setAnchorMenu(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorMenu(null);
    };

    if (groups.length === 0 && !selectedGroupID) return null;

    if (!/(^\/$)|(^\/alerts$)|(^\/productivity$)|(^\/pages\/gps$)|(^\/pages\/map$)|(^\/pages\/map-canvas$)|(^\/logs\/wifidroplog$)|(^\/settings\/devices$)/.test(currentRoute)) return null;
    return (<>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
            <Tooltip title={selectedGroupID ? (groupsMap[selectedGroupID]?.name || selectedGroupID) : getNoGroupTitle(currentRoute)}>
                <Badge
                    aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                    size="large"
                    color="secondary"
                    badgeContent={selectedGroupID ? 1 : 0}
                >
                    <GroupIcon />
                </Badge>
            </Tooltip>
            <Menu
                id="menu-appbar"
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={closeMenu}
            >
                <MenuItem value={'-'} selected={selectedGroupID === null} onClick={() => handleChange("-")}>{getNoGroupTitle(currentRoute)}</MenuItem>
                {groups.map(group => <MenuItem key={group._id} selected={selectedGroupID === group._id} onClick={() => handleChange(group._id)}>{group.name || group._id}</MenuItem>)}
            </Menu>
        </Box>
        <Box sx={{ minWidth: 160, display: { xs: "none", md: "block" } }}>
            <FormControl fullWidth>
                <InputLabel id="select-group-global-label">Selected Group</InputLabel>
                <Select
                    labelId="select-group-global-label"
                    id="select-group-global"
                    value={selectedGroupID || '-'}
                    label="Selected Group"
                    onChange={(e) => handleChange(e.target.value)}
                    size="small"
                >
                    <MenuItem value={'-'}>{getNoGroupTitle(currentRoute)}</MenuItem>
                    {groups.map(group => <MenuItem key={group._id} value={group._id}>{group.name || ''}</MenuItem>)}
                </Select>
            </FormControl>
        </Box>
    </>);
}

export default GroupSelector;