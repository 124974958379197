import SupportActionTypes from "./support.types"

const supportUpdateCompaniesAction = (companies) => {
    return { type: SupportActionTypes.SUPPORT_UPDATE_ALL_COMPANIES, payload: companies }
}
const supportUpdateArticlesAction = (articles) => {
    return { type: SupportActionTypes.SUPPORT_UPDATE_ALL_ARTICLES, payload: articles }
}
const supportUpdateArticleAction = (article) => {
    return { type: SupportActionTypes.SUPPORT_UPDATE_ARTICLE, payload: article }
}
const supportDeleteArticleAction = (articleID) => {
    return { type: SupportActionTypes.SUPPORT_DELETE_ARTICLE, payload: articleID }
}

const supportUpdateSampleKeyboardsAction = (Keyboards) => {
    return { type: SupportActionTypes.SUPPORT_UPDATE_ALL_SAMPLE_KEYBOARDS, payload: Keyboards }
}

const supportUpdateSampleKeyboardAction = (Keyboard) => {
    return { type: SupportActionTypes.SUPPORT_UPDATE_SAMPLE_KEYBOARD, payload: Keyboard }
}

const supportDleteSampleKeyboardAction = (keyboardID) => {
    return { type: SupportActionTypes.SUPPORT_DELETE_SAMPLE_KEYBOARD, payload: keyboardID }
}

const supportUpdateNotificationsAction = (notifications) => {
    return { type: SupportActionTypes.SUPPORT_UPDATE_ALL_NOTIFICATIONS, payload: notifications }
}

const supportUpdateNotificationAction = (notification) => {
    return { type: SupportActionTypes.SUPPORT_UPDATE_NOTIFICATION, payload: notification }
}


const supportDeleteNotificationAction = (notificationID) => {
    return { type: SupportActionTypes.SUPPORT_DELETE_NOTIFICATION, payload: notificationID }
}
const supportUpdateEmailsAction = (emails) => {
    return { type: SupportActionTypes.SUPPORT_UPDATE_ALL_EMAILS, payload: emails }
}
const supportUpdateDuplicatedDevices = (devices) => {
    return { type: SupportActionTypes.SUPPORT_UPDATE_DUPLICATED_DEVICES, payload: devices }
}
export {
    supportUpdateCompaniesAction,
    supportUpdateArticlesAction,
    supportUpdateArticleAction,
    supportDeleteArticleAction,
    supportUpdateSampleKeyboardsAction,
    supportUpdateSampleKeyboardAction,
    supportDleteSampleKeyboardAction,
    supportUpdateNotificationsAction,
    supportUpdateNotificationAction,
    supportDeleteNotificationAction,
    supportUpdateEmailsAction,
    supportUpdateDuplicatedDevices
}
