import React, { useState, useMemo } from 'react';
import {
    Card as MuiCard,
    CardContent,
    Typography,
    Grid2,
    CircularProgress
} from "@mui/material";
import { styled } from '@mui/system';
import { spacing } from "@mui/system";
import SettingsIcon from '@mui/icons-material/Settings';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from "react-toastify";
import { http } from "utils/http";
import { formatTimeDate } from "utils/timeFormat";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjsExt from 'utils/dayjsExt';
import Authorized from 'components/Authorized';


const Card = styled(MuiCard)(spacing);

const isStripe = false;

const BillingActive = ({
    productID,
    start,
    end,
    status,
    cancelAt,
    cancelAtPeriodEnd,
    canceledAt,
    trialStart,
    trialEnd,
    invoiceAvailable,
    fromCache
}) => {
    const [fetchingPortal, setFetchingPortal] = useState(false);
    const [fetchingInvoice, setFetchingInvoice] = useState(false);
    const handleCreatePortalSession = async () => {
        setFetchingPortal(true);
        try {
            const promise = http.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/payment/create-portal-session`,
                {
                    return_url: window.location.href,
                })
            toast.promise(promise, {
                pending: 'Generating a link...',
                success: 'Page ready',
            })
            const resp = await promise;
            setFetchingPortal(false);
            if (resp && resp.data && resp.data.portalSessionUrl) {
                window.location.assign(resp.data.portalSessionUrl);
            }
            else throw new Error("sessionUrl not found");
        } catch (error) {
            console.log("Error", error);
            setFetchingPortal(false);
            console.log("error", error);
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            }
            else toast.error("Something went wrong please try again");
        }
    }


    const remaining = useMemo(() => {
        const epchTrial = new Date(trialEnd).getTime();
        if (!trialEnd || !Number.isInteger(epchTrial)) return null;
        const fromNow = dayjsExt(trialEnd).fromNow();
        return `${(epchTrial > Date.now()) ? "Ends" : "Ended"} ${fromNow}`;
    }, [trialEnd])


    const handleDownloadInvoice = () => {
        setFetchingInvoice(true);
        http.get(`${process.env.REACT_APP_SERVER_URL}/api/v2/payment/download-invoice`,
            { responseType: 'blob' }
        )
            .then(res => {
                setFetchingInvoice(false);
                const link = document.createElement("a");
                link.download = 'invoice.pdf';
                link.href = window.URL.createObjectURL(res.data);
                link.dataset.downloadurl = ["pdf", link.download, link.href].join(":");
                const evt = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                });
                link.dispatchEvent(evt);
                link.remove()
            })
            .catch(error => {
                setFetchingInvoice(false);
                if ("function" === typeof error?.response?.data?.text) {
                    error.response.data.text().then(results => {
                        const resp = JSON.parse(results);
                        toast.error(resp?.message || "Something went wrong");
                    })
                        .catch(error => {
                            console.error("error", error);
                            toast.error("Something went wrong");
                        });
                    return;
                }
                toast.error("Something went wrong");
            })
    };

    return <Card sx={{ minHeight: 500 }}>
        <CardContent>
            <Grid2 container justifyContent="space-between" sx={{ minHeight: 400 }}>
                <Grid2 size={12}>
                    <Grid2 container justifyContent="space-between" alignItems="center">
                        <Grid2>
                            <Typography component="h3" variant="h3">
                                Subscription
                            </Typography>
                            {status === "trialing" && <Typography component="h4" variant="h4">
                                Trial period
                            </Typography>}
                            {status === "trialing" && <Typography color="primary" variant="body2">
                                ({remaining})
                            </Typography>}
                            {status === "past_due" && <Typography color="error" variant="body2">
                                Payments is overdue
                            </Typography>}
                        </Grid2>
                        <Grid2>
                            <Tooltip title={status}>
                                {fromCache ? <CircularProgress color="primary" size={32} /> : <CheckCircleIcon color={cancelAtPeriodEnd ? "warning" : "primary"} fontSize="large" />}
                            </Tooltip>
                        </Grid2>
                    </Grid2>
                </Grid2>
                {invoiceAvailable && isStripe && <Authorized permission="payment.downloadInvoice">
                    <Grid2 size={12} container alignItems="center" justifyContent="space-around">
                        <Grid2>
                            <Typography>Latest Invoice: </Typography>
                        </Grid2>
                        <Grid2>
                            <LoadingButton
                                onClick={handleDownloadInvoice}
                                loading={fetchingInvoice}
                                color="secondary"
                                variant="contained"
                                loadingPosition="end"
                                size="small"
                                disabled={fetchingPortal || fromCache}
                                endIcon={<DownloadIcon />}
                            >
                                Download
                            </LoadingButton>
                        </Grid2>
                    </Grid2>
                </Authorized>}
                {status === "trialing" && trialStart && trialEnd && <Grid2 size={12}>
                    <Grid2 container sx={{ mt: 2 }} alignItems="center">
                        <Grid2 size={{ xs: 4, md: 2, lg: 2 }}>
                            <Tooltip title="Trial Start">
                                <IconButton color="warning" >
                                    <EventAvailableIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid2>
                        <Grid2>
                            <Typography component="span" variant="body2" >
                                {formatTimeDate(trialStart)}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 container sx={{ mt: 2 }} alignItems="center">
                        <Grid2 size={{ xs: 4, md: 2, lg: 2 }}>
                            <Tooltip title="Trial End">
                                <IconButton color="warning" >
                                    <EventBusyIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid2>
                        <Grid2>
                            <Typography component="span" variant="body2" >
                                {formatTimeDate(trialEnd)}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>}
                {status !== "trialing" && start && end && <Grid2 size={12}>
                    <Grid2 container sx={{ mt: 2 }} alignItems="center">
                        <Grid2 size={{ xs: 4, md: 2, lg: 2 }}>
                            <Tooltip title="Start">
                                <IconButton color="primary" >
                                    <EventAvailableIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid2>
                        <Grid2>
                            <Typography component="h2" variant="body1" >
                                {formatTimeDate(start)}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 container sx={{ mt: 2 }} alignItems="center">
                        <Grid2 size={{ xs: 4, md: 2, lg: 2 }}>
                            <Tooltip title="End">
                                <IconButton color="primary" >
                                    <EventBusyIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid2>
                        <Grid2>
                            <Typography component="span" variant="body2" >
                                {formatTimeDate(end)}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>}
                {cancelAtPeriodEnd && <React.Fragment>
                    <Grid2 container sx={{ mt: 2 }} alignItems="center">
                        <Grid2 size={{ xs: 4, md: 2, lg: 2 }}>
                            <Tooltip title="Canceled">
                                <IconButton color="error" >
                                    <EventBusyIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid2>
                        <Grid2>
                            <Typography component="span" variant="body2" >
                                {formatTimeDate(canceledAt)}
                            </Typography>
                        </Grid2>
                    </Grid2>

                    <Grid2 container sx={{ mt: 2 }} alignItems="center">
                        <Grid2 size={{ xs: 4, md: 2, lg: 2 }}>
                            <Tooltip title="cancelAt">
                                <IconButton color="primary" >
                                    <EventBusyIcon color="error" />
                                </IconButton>
                            </Tooltip>
                        </Grid2>
                        <Grid2>
                            <Typography component="span" variant="body2" >
                                {formatTimeDate(cancelAt)}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </React.Fragment>}
            </Grid2>
            {productID && isStripe && <Authorized permission="payment.createCheckout">
                <Grid2 container justifyContent="space-around" sx={{ mt: 2 }} alignItems="center">
                    <LoadingButton
                        onClick={handleCreatePortalSession}
                        loading={fetchingPortal}
                        color="secondary"
                        variant="contained"
                        loadingPosition="end"
                        disabled={fetchingInvoice || fromCache}
                        endIcon={<SettingsIcon />}
                    >
                        Update
                    </LoadingButton>
                    {!cancelAtPeriodEnd && <LoadingButton
                        onClick={handleCreatePortalSession}
                        loading={fetchingPortal}
                        color="error"
                        variant="contained"
                        loadingPosition="end"
                        disabled={fetchingInvoice || fromCache}
                        endIcon={<DeleteIcon />}
                    >
                        Cancel
                    </LoadingButton>}
                </Grid2>
            </Authorized>}
        </CardContent>
    </Card >
}
export default BillingActive;