import { useEffect, useState } from "react";
import { DataGridPro, useGridApiRef, GridToolbar } from "@mui/x-data-grid-pro";
import { useSelector, useDispatch } from "react-redux"
import DangerousIcon from '@mui/icons-material/Dangerous';
import GppGoodIcon from '@mui/icons-material/GppGood';
import CachedIcon from '@mui/icons-material/Cached';
import { getAuthLogs } from "utils/api";
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Typography,
    Box,
    Card,
    CardHeader,
    CardContent,
    ClickAwayListener
} from "@mui/material";
import { styled } from '@mui/system';
import { updateAuthLogsAction } from "redux/system/system.actions";
import { formatTimeDate } from 'utils/timeFormat';

const Wrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

function AuthentificationLog() {
    const authLogs = useSelector((state) => state.system.authLogs);
    const [fetching, setFetching] = useState(true);
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchCall = getAuthLogs().then(logs => {
            setFetching(false);
            dispatch(updateAuthLogsAction(logs || []))
        });
        return () => {
            fetchCall.cancel();
        };
    }, [dispatch]);
    const loadLogs = () => {
        setFetching(true);
        getAuthLogs().then(logs => {
            setFetching(false);
            dispatch(updateAuthLogsAction(logs || []))
        })
    }
    const apiRef = useGridApiRef();
    const columns = [
        {
            field: "_id",
            headerName: "REAL_ID",
            width: 250,
            editable: false
        },
        {
            field: "date",
            headerName: "Date",
            width: 180,
            editable: false,
            valueGetter: (value) => formatTimeDate(value)
        },
        {
            field: "email",
            headerName: "Account",
            width: 220,
            valueGetter: (value, row) => row.details ? row.details.email : '',
            editable: false,
        },
        {
            field: "ipaddr",
            headerName: "IP Address",
            width: 140,
            editable: false,
        },
        {
            field: "successful",
            headerName: "Successful",
            renderCell: (params) => params?.row?.details?.successful ? <GppGoodIcon color="primary" /> : <DangerousIcon color="error" />,
            width: 100,
            align: 'center',
            valueGetter: (value, row) => !!row?.details?.successful
        },
    ];
    const [sortModel, setSortModel] = useState([
        {
            field: "date",
            sort: "desc",
        },
    ]);
    const handleClickAway = () => {
        if (apiRef.current && apiRef.current.setRowSelectionModel) {
            apiRef.current.setRowSelectionModel([])
        }
    }
    return (
        <Card sx={{ height: 500 }}>
            <CardHeader title={
                <Wrapper>
                    <Typography variant="h3" gutterBottom display="inline">
                        Access Log
                    </Typography>
                    <LoadingButton
                        onClick={loadLogs}
                        loading={fetching}
                        loadingPosition="start"
                        startIcon={<CachedIcon />}
                        variant="contained"
                    >
                        Reload
                    </LoadingButton>
                </Wrapper>
            }
            />
            <CardContent>
                <div style={{ height: 400 }}>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <DataGridPro
                            sortingOrder={["desc", "asc"]}
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}
                            apiRef={apiRef}
                            rows={authLogs}
                            getRowId={(row) => row._id}
                            columns={columns}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            initialState={{
                                density: "compact",
                                columns: {
                                    columnVisibilityModel: {
                                        _id: false
                                    }
                                }
                            }}
                        />
                    </ClickAwayListener>
                </div>
            </CardContent>
        </Card>
    )
};

export default AuthentificationLog;
