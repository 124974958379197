import LadsServersActionTypes from './ladsServers.types';
import codes from 'constants/codes';
const updateLadsServersAction = (events) => {
    const ladsServers = {};
    events.forEach(el => {
        if (el.properties) {
            const key = el.messageID === codes.LICENSE_COUNT ? el.ipaddr : `GSW LADS ${el.properties.hostName}`;
            if (!ladsServers[key]) ladsServers[key] = { name: key };
            ladsServers[key] = { ...ladsServers[key], name: key };
            ladsServers[key].available = el.properties.avail;
            ladsServers[key].total = el.properties.total;
            ladsServers[key].used = ladsServers[key].total - ladsServers[key].available;
            ladsServers[key].appN = el.properties.appN;
            ladsServers[key].appV = el.properties.appV;
            if (el.messageID === codes.LICENSE_COUNT_IP_HOSTNAME) {
                ladsServers[key].ipaddr = el.ipaddr;
            }
        }
    });
    return { type: LadsServersActionTypes.UPDATE_LADS_SERVERS, payload: Object.values(ladsServers) }
}

const updateLadsServerAction = (event) => {
    const server = {};
    if (event.properties) {
        const key = event.messageID === codes.LICENSE_COUNT ? event.ipaddr : `GSW LADS ${event.properties.hostName}`;
        server.name = key;
        server.available = event.properties.avail;
        server.total = event.properties.total;
        server.used = server.total - server.available;
        server.appN = event.properties.appN;
        server.appV = event.properties.appV;
        if (event.messageID === codes.LICENSE_COUNT_IP_HOSTNAME) {
            server.ipaddr = event.ipaddr;
        }
    }
    return { type: LadsServersActionTypes.UPDATE_LADS_SERVER, payload: server }
}
export {
    updateLadsServersAction,
    updateLadsServerAction
}
